// @flow
import Helmet from 'react-helmet';
import { Box, Container, Heading, HideVisually, TextLoader } from '@getatomi/neon';
import pluralize from 'pluralize';

import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import { getLtiClassModuleUrl } from 'src/utils/routes';
import DividerStack, { DividerStackLoader } from 'src/components/DividerStack/DividerStack';

import useGetClassModules from './useGetClassModules';

type Params = {
  classId: string,
  subscriptionId: string,
};

type Props = {
  params: Params,
};

export default function SelectModule(props: Props) {
  const {
    params: { classId, subscriptionId },
  } = props;

  const { className, error, loading, modules } = useGetClassModules({ classId, subscriptionId });

  return (
    <>
      <Helmet>
        <title>Select module</title>
      </Helmet>
      <Container paddingInline="spacingLarge">
        {loading ? (
          <>
            <HideVisually>Loading modules for class.</HideVisually>
            <TextLoader height={35} marginBottom="spacingRoot" width={230} />
          </>
        ) : (
          <Heading as="h1" variant="large" marginBottom="spacingSmall">
            {className}
          </Heading>
        )}
      </Container>

      {error && (
        <Box marginTop="spacingRoot">
          <GraphQLError error={error} description="We couldn’t load the modules for this class." />
        </Box>
      )}

      {loading && <DividerStackLoader />}

      {modules && (
        <DividerStack
          items={modules.map((module) => ({
            heading: module.name,
            subheading: `${module.lessonCount} ${pluralize('Lesson', module.lessonCount)}`,
            url: getLtiClassModuleUrl(subscriptionId, classId, module.id),
          }))}
        />
      )}
    </>
  );
}
