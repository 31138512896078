// @flow
import { EXTENDED_RESPONSE_MIN_MARKS } from 'src/constants/questionMarks';
import challengeQuestionTypes, { type QuestionType } from 'src/constants/questionTypes';

type Question = {|
  +marks: number,
  +type: QuestionType,
|};

export function isExtendedResponse(question: Question): boolean {
  const { type, marks } = question;

  return type === challengeQuestionTypes.SELF_MARKED && marks >= EXTENDED_RESPONSE_MIN_MARKS;
}

export function isShortAnswer(question: Question): boolean {
  const { type, marks } = question;

  return (
    type === challengeQuestionTypes.SELF_MARKED_LITE ||
    (type === challengeQuestionTypes.SELF_MARKED && marks < EXTENDED_RESPONSE_MIN_MARKS)
  );
}
