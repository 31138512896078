// @flow
import { Box, Breadcrumbs, BreadcrumbsItem } from '@getatomi/neon';

import { UnstyledLink } from 'src/components/Link/Link';
import SubjectBadge from 'src/components/SubjectBadge/SubjectBadge';
import { getClassUrl, getClassModuleUrl } from 'src/utils/routes';
import { trackingLocations } from 'src/constants/tracking';

import type { Ancestor } from '../Post';
import type { CurrentClass } from '../useGetLesson';

const trackingData = { location: trackingLocations.postBreadcrumb };

export type PostBreadcrumbsProps = {|
  ancestors: $ReadOnlyArray<Ancestor>,
  currentClass: CurrentClass,
  isRetailPlan: boolean,
  subscriptionId: number,
|};

export default function PostBreadcrumbs(props: PostBreadcrumbsProps) {
  const { ancestors, currentClass, isRetailPlan, subscriptionId } = props;
  const { subject } = currentClass;

  const makeUrl = (ancestor: Ancestor, depth: number) => {
    let { parentId } = ancestor;
    const { id: sectionId } = ancestor;
    const { id: classId } = currentClass;

    // if sub-section URL, use section id to make the URL.
    if (depth > 1) {
      parentId = ancestors[1].parentId;
    }

    if (parentId) {
      return `${getClassModuleUrl(subscriptionId, classId, parentId)}#section${sectionId}`;
    }

    return getClassModuleUrl(subscriptionId, classId, sectionId);
  };

  const classUrl = getClassUrl(subscriptionId, currentClass.id);

  const subjectTag = (
    <Box as="span" marginRight="spacingSmall1X">
      <SubjectBadge color={subject.color} groupCode={subject.groupCode}>
        {subject.name}
      </SubjectBadge>
    </Box>
  );

  return (
    <Breadcrumbs>
      {!isRetailPlan ? (
        <BreadcrumbsItem
          insertBefore={subjectTag}
          linkComponent={UnstyledLink}
          // Providing both 'href' and 'to' props as the neon component uses href to decide whether
          // the item is the current one or not.
          href={classUrl}
          to={classUrl}
          trackingData={trackingData}
        >
          {currentClass.name}
        </BreadcrumbsItem>
      ) : undefined}
      {ancestors.map((ancestor: Ancestor, i: number) => {
        const href = makeUrl(ancestor, i);
        return (
          <BreadcrumbsItem
            key={ancestor.id}
            insertBefore={isRetailPlan && i === 0 ? subjectTag : undefined}
            linkComponent={UnstyledLink}
            href={href}
            to={href}
            trackingData={trackingData}
          >
            {ancestor.name}
          </BreadcrumbsItem>
        );
      })}
    </Breadcrumbs>
  );
}
