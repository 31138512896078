// @flow
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@getatomi/neon';

import { parseValidationErrors } from 'src/api/validation';

type OutboundFields = {|
  password: string,
  userId: string,
|};

const labels = {
  userId: 'New account owner',
  password: 'Password',
};

const resolver = yupResolver(
  yup.object().shape({
    userId: yup.string().required().label(labels.userId),
    password: yup.string().required().label(labels.password),
  })
);

export default function useTransferOwnershipForm({
  onSubmitSuccess,
}: {
  onSubmitSuccess: (data: OutboundFields) => void | Promise<void>,
}) {
  const { clearErrors, control, formState, handleSubmit, setError } = useForm({
    resolver,
    defaultValues: {
      password: '',
    },
  });
  const toast = useToast();

  const onSubmit = async (event: Event) => {
    event.preventDefault();
    clearErrors('serverError');
    handleSubmit(async (data) => {
      try {
        await onSubmitSuccess(data);
      } catch (error) {
        const validationErrors = parseValidationErrors(error);
        setError('serverError', { type: 'manual', message: validationErrors });
        if (validationErrors.length === 0) {
          toast.error('There was an error transferring ownership. Please try again.');
        }
      }
    })(event);
  };

  const passwordMessage = formState.errors.password?.message ?? null;
  const userIdMessage = formState.errors.userId?.message ?? null;
  const { serverError } = formState.errors;

  return {
    clearErrors,
    control,
    onSubmit,
    serverError,
    fields: {
      userId: {
        label: labels.userId,
        validationText: userIdMessage,
        errorVariant: userIdMessage && 'error',
      },
      password: {
        label: labels.password,
        validationText: passwordMessage,
        errorVariant: passwordMessage && 'error',
        isRequired: true,
      },
    },
  };
}
