// @flow
import { Box, Text, useHover } from '@getatomi/neon';

import CompletionIcon from './CompletionIcon';

export type CompletionCountProps = {|
  children: React.Node,
  color: string,
  onClick?: () => mixed,
  testHook?: string,
|};

export default function CompletionCount(props: CompletionCountProps) {
  const { children, color, onClick, testHook } = props;

  const { hoverProps, isHovered } = useHover({});

  return (
    <Box
      as="button"
      type="button"
      onClick={onClick}
      display="flex"
      alignItems="center"
      gap="spacingSmall1X"
      testHook={testHook}
      {...hoverProps}
    >
      <CompletionIcon color={color} isHovered={isHovered} assistiveText="View progress report." />
      <Text
        variant="bodySmall2X"
        lineHeight="lineHeightNone"
        color={isHovered ? 'colorText' : 'colorTextSubtle'}
        textDecoration={isHovered ? 'underline' : undefined}
      >
        {children}
      </Text>
    </Box>
  );
}
