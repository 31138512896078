// @flow
import { useState } from 'react';

import DeleteTaskAlertDialog from './DeleteTaskAlertDialog';

type Props = ?{
  onConfirm: () => void,
};

export default function useDeleteTaskAlertDialog(props: Props) {
  const { onConfirm } = props ?? {};
  const [taskId, setTaskId] = useState<?string>(null);
  const [taskStatus, setTaskStatus] = useState<?string>(null);

  const open = (newTaskId: string, newTaskStatus: ?string) => {
    setTaskId(newTaskId);
    setTaskStatus(newTaskStatus);
  };

  const close = () => {
    setTaskId(null);
  };

  const confirm = () => {
    setTaskId(null);
    onConfirm && onConfirm();
  };

  const isTaskDraft = taskStatus === 'draft';

  const deleteTaskDialog = taskId ? (
    <DeleteTaskAlertDialog isDraft={isTaskDraft} onConfirm={confirm} onDismiss={close} taskId={taskId} />
  ) : null;

  return [deleteTaskDialog, { open }];
}
