// @flow
import { useEffect, useRef } from 'react';

import { subscribe } from 'src/utils/pusher';
import client from 'src/graphql/client';

type Subscriptions = $ReadOnlyArray<{
  channel: string,
  event: string,
}>;

type UnsubscribeHandles = $ReadOnlyArray<() => void>;

export default function useSubscriptions(subscriptions: Subscriptions) {
  const pusherSubscriptionsRef = useRef<UnsubscribeHandles>([]);

  useEffect(() => {
    pusherSubscriptionsRef.current = subscriptions.map(({ channel, event }) => {
      return subscribe(channel, event, async (result) => {
        if (!result) {
          return;
        }
        await client.reFetchObservableQueries();
      });
    });

    return () => {
      // unsubscribe from any active subscriptions
      pusherSubscriptionsRef.current && pusherSubscriptionsRef.current.forEach((unsubscribe) => unsubscribe());
      pusherSubscriptionsRef.current = [];
    };
  }, [subscriptions]);
}
