import moment from 'moment';

export function isWeeksBefore(date, weeks) {
  return moment(date).isBefore(moment().subtract(weeks, 'week').endOf('isoWeek'));
}

export function isLastWeek(date) {
  return moment().subtract(1, 'week').isSame(date, 'isoWeek');
}

export function isEarlierThisWeek(date) {
  return moment().isSame(date, 'isoWeek') && moment().isAfter(date);
}

export function isBeforeNow(date) {
  return moment().isAfter(date);
}

export function isToday(date) {
  return moment().isSame(date, 'day');
}

export function isTomorrow(date) {
  return moment().add(1, 'day').isSame(date, 'day');
}

export function isLaterThisWeek(date) {
  return moment().isSame(date, 'isoWeek') && moment().isBefore(date);
}

export function isNextWeek(date) {
  return moment().add(1, 'week').isSame(date, 'isoWeek');
}

export function isWeeksAfter(date, weeks) {
  return moment(date).isAfter(moment().add(weeks, 'week').startOf('isoWeek'));
}
