// @flow
import _ from 'lodash';

import userStatusOnTask from 'src/constants/userStatusOnTask';
import {
  isBeforeNow,
  isEarlierThisWeek,
  isLastWeek,
  isLaterThisWeek,
  isNextWeek,
  isToday,
  isTomorrow,
  isWeeksAfter,
  isWeeksBefore,
} from 'src/utils/timeline';

import type { ClassTask } from '../ClassTasks/useGetClassTasks';
import type { Task as StudentTask } from '../StudentTasks/useGetStudentTasks';

const commonIntervals = Object.freeze({
  Today: (task: ClassTask | StudentTask) => isToday(task.dueDate),
  Tomorrow: (task: ClassTask | StudentTask) => isTomorrow(task.dueDate),
  'Later this week': (task: ClassTask | StudentTask) =>
    isLaterThisWeek(task.dueDate) && !isToday(task.dueDate) && !isTomorrow(task.dueDate),
  'Next week': (task: ClassTask | StudentTask) => isNextWeek(task.dueDate),
  'Everything else': (task: ClassTask | StudentTask) => isWeeksAfter(task.dueDate, 2),
});

const teacherViewIntervals = Object.freeze({
  'Prior to last week': (task: ClassTask) => isWeeksBefore(task.dueDate, 2),
  'Last week': (task: ClassTask) => isLastWeek(task.dueDate),
  'Earlier this week': (task: ClassTask) => isEarlierThisWeek(task.dueDate) && !isToday(task.dueDate),
  ...commonIntervals,
});

export function isCompletedTask(task: StudentTask) {
  return task.userStatus === userStatusOnTask.COMPLETE;
}

const studentViewIntervals = Object.freeze({
  Overdue: (task: StudentTask) => isBeforeNow(task.dueDate) && !isCompletedTask(task),
  'Prior to last week': (task: StudentTask) => isWeeksBefore(task.dueDate, 2) && isCompletedTask(task),
  'Last week': (task: StudentTask) => isLastWeek(task.dueDate) && isCompletedTask(task),
  'Earlier this week': (task: StudentTask) =>
    isEarlierThisWeek(task.dueDate) && !isToday(task.dueDate) && isCompletedTask(task),
  ...commonIntervals,
});

export function groupStudentTasks(tasks: $ReadOnlyArray<StudentTask>): { [key: string]: Array<StudentTask> } {
  return _.groupBy(tasks, (task: StudentTask) =>
    Object.keys(studentViewIntervals).find((interval) => studentViewIntervals[interval](task))
  );
}

export function groupClassTasks(tasks: $ReadOnlyArray<ClassTask>): { [key: string]: Array<ClassTask> } {
  return _.groupBy(tasks, (task: ClassTask) =>
    Object.keys(teacherViewIntervals).find((interval) => teacherViewIntervals[interval](task))
  );
}
