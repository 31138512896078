import { Box, Text } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import links from 'src/constants/links';

export default function ForgotPasswordHelp() {
  return (
    <Box
      position={{ tablet: 'absolute' }}
      right={0}
      bottom={0}
      left={0}
      marginBottom={{ base: 'spacingNone', tablet: 'spacingLarge6X' }}
      marginTop={{ base: 'spacingLarge7X', tablet: 'spacingNone' }}
    >
      <Text as="p" variant="bodyRoot" color="colorTextInverted">
        If you still need help,{' '}
        <Link href={links.support.home} isExternal variant="inverted">
          contact support
        </Link>
        .<br />
        <Link to="/login" variant="inverted">
          Back to login
        </Link>
        .
      </Text>
    </Box>
  );
}
