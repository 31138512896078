// @flow
import { type DurationFormat, formatPostDuration } from 'src/utils/duration';

type Props = {
  children?: (duration: string) => string,
  duration: number,
  format?: DurationFormat,
};

export default function Duration(props: Props) {
  const { children, duration, format } = props;
  const formattedDuration = formatPostDuration(duration, format);
  return children ? children(formattedDuration) : formattedDuration;
}
