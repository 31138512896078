// @flow
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Button, Stack, Text, TextField, useToast } from '@getatomi/neon';

import FormMessage from 'src/components/FormMessage/FormMessage';
import validators from 'src/utils/validators';
import type { PromoteUserAdmin, PromoteUserAdminVariables } from 'src/graphql/types/generated/PromoteUserAdmin';

import PROMOTE_USER_ADMIN from './PromoteUserAdmin.graphql';

export default function PromoteUser() {
  const toast = useToast();

  const [userEmail, setUserEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [showServerError, setShowServerError] = useState<boolean>(false);

  const [showInvalidEmailError, setShowInvalidEmailError] = useState<boolean>(false);
  const invalidEmailErrorProps = showInvalidEmailError
    ? {
        errorVariant: 'error',
        validationText: validators.email.patternMismatchErrorMessage,
      }
    : {};

  const [showInvalidPasswordError, setShowInvalidPasswordError] = useState<boolean>(false);
  const invalidPasswordErrorProps = showInvalidPasswordError
    ? {
        errorVariant: 'error',
        validationText: 'Your password is required',
      }
    : {};

  const [promoteUserAdmin] = useMutation<PromoteUserAdmin, PromoteUserAdminVariables>(PROMOTE_USER_ADMIN);

  const clearErrors = () => {
    setShowServerError(false);
    setShowInvalidEmailError(false);
    setShowInvalidPasswordError(false);
  };

  const resetForm = () => {
    clearErrors();
    setUserEmail('');
    setPassword('');
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    clearErrors();

    const isValidEmail = validators.email.pattern.test(userEmail);
    if (!isValidEmail) {
      setShowInvalidEmailError(true);
      return;
    }

    if (!password.length) {
      setShowInvalidPasswordError(true);
      return;
    }

    setSubmitInProgress(true);
    try {
      await promoteUserAdmin({
        variables: {
          input: {
            email: userEmail,
            password,
          },
        },
      });
      toast.success('You have successfully promoted this user to super admin.');
      resetForm();
    } catch {
      setShowServerError(true);
    } finally {
      setSubmitInProgress(false);
    }
  };

  return (
    // Autocomplete is disabled as we don't require it fro these fields, and native
    // form validation is disabled as we want to validate these inputs manually.
    <form onSubmit={onSubmit} autoComplete="off" noValidate>
      <Stack as="fieldset">
        <Text as="legend" variant="bodyLarge">
          Grant Atomi staff member super admin rights
        </Text>

        <Box>
          <Box as="p">Promoting a user will allow them to:</Box>
          <Box as="ul" paddingLeft="spacingLarge" style={{ listStyleType: 'disc' }}>
            <li>view unpublished content when using the site in preview mode</li>
            <li>see a class’ created date</li>
            <li>toggle the insights feature on and off</li>
            <li>configure Wonde and SSO</li>
            <li>edit the name, email and role of a user (teacher or student)</li>
            <li>join any subscription on the site as a user (as long as you have their Chargebee ID)</li>
            <li>and, view insights for any school or user.</li>
          </Box>
        </Box>

        {showServerError && (
          <FormMessage>
            There was a problem promoting this user. Please check that you have entered the correct email and password
            and try again.
          </FormMessage>
        )}

        <TextField
          {...invalidEmailErrorProps}
          label="User email"
          onChange={(value) => setUserEmail(value)}
          name="userEmail"
          placeholder="e.g. firstname.lastname@getatomi.com"
          type="email"
          value={userEmail}
          isRequired
          autoComplete="off"
        />

        <TextField
          {...invalidPasswordErrorProps}
          label="Confirm with your password"
          onChange={(value) => setPassword(value)}
          name="password"
          type="password"
          value={password}
          isRequired
        />

        <Button isLoading={submitInProgress} type="submit">
          Promote
        </Button>
      </Stack>
    </form>
  );
}
