// @flow
import { Box, Card, Divider } from '@getatomi/neon';

import draftColorRange from 'src/domains/Tasks/draftColorRange';
import getStudentCountText from 'src/utils/getStudentCountText';
import TaskDateTime from 'src/components/TaskDateTime/TaskDateTime';
import type { TaskActions } from 'src/domains/Tasks/ClassTasks/utils/getTaskActionsById';
import { type SubjectColors } from 'src/utils/getSubjectColors';

import type { ClassTask, ClassTaskContent } from './useGetClassTasks';
import TaskCardHeader from '../components/TaskCardHeader/TaskCardHeader';
import TaskCardBody from '../components/TaskCardBody/TaskCardBody';
import TaskAttachmentList from '../components/TaskAttachmentList/TaskAttachmentList';
import { ClassTaskCardContentList } from '../components/TaskCardContentList/TaskCardContentList';
import TaskProgressLink from '../components/TaskProgressLink/TaskProgressLink';

type ClassTasksListCardProps = {|
  colors: SubjectColors,
  getTaskActions: (id: string) => TaskActions,
  onStudentCountClick: (content: ClassTaskContent) => mixed,
  studentCount: number,
  task: ClassTask,
  taskProgressReportUrlFactory: (taskId: string) => string,
|};

export default function ClassTasksListCard(props: ClassTasksListCardProps) {
  const {
    colors: subjectColorRange,
    getTaskActions,
    onStudentCountClick,
    studentCount,
    task,
    taskProgressReportUrlFactory,
  } = props;

  const colors = task.status === 'draft' ? draftColorRange : subjectColorRange;

  const assignedTo = getStudentCountText({
    count: task.countStudents,
    total: studentCount,
    totalText: 'All students',
  });

  const statusLabel = task.status !== 'published' ? task.status : undefined;

  const taskActions = getTaskActions(task.id);
  const shouldShowProgressLink = task.status === 'published';
  const taskProgressReportUrl = taskProgressReportUrlFactory(task.id);

  return (
    <Card
      as="article"
      paddingInline="spacingRoot"
      paddingTop="spacingRoot"
      paddingBottom={shouldShowProgressLink ? 'spacingNone' : 'spacingRoot'}
      variant="elevated"
      withHover={false}
      testHook="task-list-card"
    >
      <Box marginBottom="spacingSmall">
        <TaskCardHeader
          actions={taskActions}
          assignedTo={assignedTo}
          dueDate={task.dueDate}
          label={
            statusLabel
              ? {
                  color: colors.background,
                  text: statusLabel,
                  tooltip: task.scheduledFor ? <TaskDateTime value={task.scheduledFor} /> : undefined,
                }
              : undefined
          }
        />
      </Box>

      <TaskCardBody title={task.title} body={task.body} taskUrl={task.url} />

      {task.attachments.length > 0 && (
        <Box marginBlock="spacingRoot">
          <TaskAttachmentList attachments={task.attachments} />
        </Box>
      )}

      {task.contents.length > 0 && (
        <ClassTaskCardContentList
          contents={task.contents}
          studentCount={studentCount}
          onStudentCountClick={onStudentCountClick}
          subjectRootColor={colors.icon}
          taskStatus={task.status}
        />
      )}

      {shouldShowProgressLink && (
        <>
          <Divider />
          <TaskProgressLink
            completionCount={task.countComplete}
            taskProgressReportUrl={taskProgressReportUrl}
            totalStudents={studentCount}
          />
        </>
      )}
    </Card>
  );
}
