// @flow
import { browserHistory } from 'react-router';
import { get, identity, partialRight, pickBy, size } from 'lodash';

import Logger from 'src/utils/Logger';
import type { APIDispatch, Dispatch, GetState, UserType, UserAccountStatusType } from 'src/types';
import { SUBSCRIPTIONS_ENDPOINT, USER_ENDPOINT, USERS_ENDPOINT } from 'src/api/endpoints';
import { usersResponseSchema, subscriptionSchema } from 'src/api/schema';
import { getActiveSubscriptionId, isLoggedInAsAccountManager } from 'src/reducers/subscriptions';
import { logout } from 'src/actions/auth';
import { getUserId } from 'src/reducers/auth';
import { getUsersPagination, getPageSize, getStatusFilter, getSearchKeywords, isPageLoaded } from 'src/reducers/users';
import { buildURLWithPaginationAndSearch } from 'src/utils/pagination';
import { getSubscriptionUrl, getSubscriptionUsersUrl } from 'src/utils/routes';
import { trackingEvents } from 'src/constants/tracking';
import roles from 'src/constants/userRoles';

import * as paginationActions from './pagination';
import { loadSubscription } from './subscriptions';

const log = new Logger('actions/users');

export const LOAD_ACTIVE_USERS_BY_ROLE = 'LOAD_ACTIVE_USERS_BY_ROLE';
export const LOAD_ACTIVE_USERS_BY_ROLE_FAIL = 'LOAD_ACTIVE_USERS_BY_ROLE_FAIL';
export const LOAD_ACTIVE_USERS_BY_ROLE_SUCCESS = 'LOAD_ACTIVE_USERS_BY_ROLE_SUCCESS';
export const CHANGE_USERS_STATUS_FILTER = 'CHANGE_USERS_STATUS_FILTER';
export const ARCHIVE_USERS = 'ARCHIVE_USERS';
export const ARCHIVE_USERS_FAIL = 'ARCHIVE_USERS_FAIL';
export const ARCHIVE_USERS_SUCCESS = 'ARCHIVE_USERS_SUCCESS';
export const RESTORE_USERS = 'RESTORE_USERS';
export const RESTORE_USERS_FAIL = 'RESTORE_USERS_FAIL';
export const RESTORE_USERS_SUCCESS = 'RESTORE_USERS_SUCCESS';
export const MAKE_USER_ADMIN = 'MAKE_USER_ADMIN';
export const MAKE_USER_ADMIN_FAIL = 'MAKE_USER_ADMIN_FAIL';
export const MAKE_USER_ADMIN_SUCCESS = 'MAKE_USER_ADMIN_SUCCESS';
export const UNMAKE_USER_ADMIN = 'UNMAKE_USER_ADMIN';
export const UNMAKE_USER_ADMIN_FAIL = 'UNMAKE_USER_ADMIN_FAIL';
export const UNMAKE_USER_ADMIN_SUCCESS = 'UNMAKE_USER_ADMIN_SUCCESS';
export const TRANSFER_ACCOUNT_OWNERSHIP = 'TRANSFER_ACCOUNT_OWNERSHIP';
export const TRANSFER_ACCOUNT_OWNERSHIP_FAIL = 'TRANSFER_ACCOUNT_OWNERSHIP_FAIL';
export const TRANSFER_ACCOUNT_OWNERSHIP_SUCCESS = 'TRANSFER_ACCOUNT_OWNERSHIP_SUCCESS';
export const IS_USERS_FOR_OWNERSHIP_TRANSFER_LOADED = 'IS_USERS_FOR_OWNERSHIP_TRANSFER_LOADED';
export const RESEND_USER_INVITES = 'RESEND_USER_INVITES';
export const RESEND_USER_INVITES_FAIL = 'RESEND_USER_INVITES_FAIL';
export const RESEND_USER_INVITES_SUCCESS = 'RESEND_USER_INVITES_SUCCESS';
export const INVITE_USER = 'INVITE_USER';
export const INVITE_USER_FAIL = 'INVITE_USER_FAIL';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const BULK_INVITE_USERS = 'BULK_INVITE_USERS';
export const BULK_INVITE_USERS_FAIL = 'BULK_INVITE_USERS_FAIL';
export const BULK_INVITE_USERS_SUCCESS = 'BULK_INVITE_USERS_SUCCESS';
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAIL = 'LOAD_USERS_FAIL';
export const DOWNLOAD_USER_DATA = 'DOWNLOAD_USER_DATA';
export const DOWNLOAD_USER_DATA_SUCCESS = 'DOWNLOAD_USER_DATA_SUCCESS';
export const DOWNLOAD_USER_DATA_FAIL = 'DOWNLOAD_USER_DATA_FAIL';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAIL = 'DELETE_ACCOUNT_FAIL';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

// Pagination base actions
const NAME = 'users';
export const updateCurrentPage = partialRight(paginationActions.updateCurrentPage, NAME);
export const clearPagination = partialRight(paginationActions.clearPagination, NAME);
export const updateSearchKeywords = partialRight(paginationActions.updateSearchKeywords, NAME);

type InvitedUserType = {
  classes: Array<string>,
  email: string,
  firstName?: string,
  lastName?: string,
  role: string,
};

type UpdatedUserType = {
  email?: string,
  firstName?: string,
  lastName?: string,
  levelId?: number | string,
};

// Custom actions
export function changeStatusFilter(statusFilter: UserAccountStatusType) {
  return {
    type: CHANGE_USERS_STATUS_FILTER,
    statusFilter,
  };
}

export function loadCurrentSubscriptionUsers({
  page,
  status,
  keywords,
}: {
  keywords?: string,
  page?: number,
  status?: UserAccountStatusType,
} = {}) {
  return (dispatch: APIDispatch<Array<UserType>>, getState: GetState) => {
    const state = getState();
    const pageSize = getPageSize(state);
    const subscriptionId = getActiveSubscriptionId(state);
    const url = buildURLWithPaginationAndSearch({
      url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/users`,
      page,
      pageSize,
      keywords,
      status,
    });

    return dispatch({
      name: 'pagination',
      request: {
        types: [LOAD_USERS, LOAD_USERS_SUCCESS, LOAD_USERS_FAIL],
        schema: usersResponseSchema,
        endpoint: {
          url,
        },
      },
    });
  };
}

export function loadUsersWithFilters(role: string, status?: string) {
  return (dispatch: APIDispatch<Array<UserType>>, getState: GetState) => {
    const state = getState();
    const subscriptionId = getActiveSubscriptionId(state);

    const url = buildURLWithPaginationAndSearch({
      url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/users/${role}`,
      status,
    });

    return dispatch({
      request: {
        types: [LOAD_ACTIVE_USERS_BY_ROLE, LOAD_ACTIVE_USERS_BY_ROLE_SUCCESS, LOAD_ACTIVE_USERS_BY_ROLE_FAIL],
        schema: usersResponseSchema,
        endpoint: {
          url,
        },
        throwErrors: true,
      },
    });
  };
}

export function loadUsersForOwnershipTransfer() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const status = !isLoggedInAsAccountManager(getState()) ? 'active' : undefined;

    await dispatch(loadUsersWithFilters(roles.accountAdmin, status));
    return dispatch({
      type: IS_USERS_FOR_OWNERSHIP_TRANSFER_LOADED,
    });
  };
}

export function loadUsers({
  page = 1,
  status,
  keywords = '',
  forceReload = false,
}: { forceReload?: boolean, keywords?: string, page?: number, status?: UserAccountStatusType } = {}) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    let pageNum = page;
    const isNewSearch = keywords !== getSearchKeywords(state);
    const statusFilterHasChanged = status !== getStatusFilter(state);

    // get the current source
    // const currentSource = source || state.classes.source;
    const currentSubscriptionId = getActiveSubscriptionId(state);

    // check that the page exist
    const { totalPages } = getUsersPagination(state);

    // the page doesn't exist then redirect to the last known page
    if (totalPages && totalPages > 0 && page > totalPages) {
      pageNum = totalPages;
      browserHistory.replace(
        getSubscriptionUsersUrl({ subscriptionId: currentSubscriptionId, page: pageNum, status, keywords })
      );
    }

    // save new keyword in state
    if (isNewSearch && !statusFilterHasChanged) {
      dispatch(updateSearchKeywords(keywords));
    }

    // we already have the results in the state
    if (isPageLoaded(getState(), pageNum) && !forceReload && !isNewSearch) {
      return dispatch(updateCurrentPage(pageNum));
    }

    await dispatch(loadCurrentSubscriptionUsers({ page: pageNum, status, keywords }));

    // save updated filter in state
    if (status && statusFilterHasChanged) {
      dispatch(updateSearchKeywords(''));
      dispatch(changeStatusFilter(status));
    }

    if (isNewSearch || statusFilterHasChanged) {
      return dispatch(clearPagination(pageNum));
    }
  };
}

export function resetSearchAndPagination(status: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const subscriptionId = getActiveSubscriptionId(state);

    await dispatch(updateSearchKeywords('')); // reset search
    await dispatch(clearPagination(1)); // clear pagination
    return browserHistory.replace(
      getSubscriptionUsersUrl({ subscriptionId, status: status || getStatusFilter(state), page: 1 })
    );
  };
}

export function reloadUsersPage(numUsersEdited: number) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const { searchKeywords: keywords, statusFilter: status } = state.users;
    const pagination = getUsersPagination(state);
    const pageSize = getPageSize(state);
    const currentSubscriptionId = getActiveSubscriptionId(state);
    let page = pagination.currentPage;

    // if we deleted users from the last page and the page doesn't exist anymore then load the previous page
    if (numUsersEdited && page > 1 && (pagination.totalItems - numUsersEdited) % pageSize === 0) {
      page--;
      browserHistory.replace(getSubscriptionUsersUrl({ subscriptionId: currentSubscriptionId, page, status }));
      log.info(`Previously loaded page doesn't exist anymore loading page ${page} instead`);
    }

    await dispatch(loadUsers({ page, status, keywords, forceReload: true }));
    await dispatch(clearPagination(page));
  };
}

export function archiveUsers(users: Array<number>) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const subscriptionId = getActiveSubscriptionId(state);
    const loggedInUserId = getUserId(state);
    const numUsers = users.length;

    await dispatch({
      data: { ids: users },
      request: {
        types: [ARCHIVE_USERS, ARCHIVE_USERS_SUCCESS, ARCHIVE_USERS_FAIL],
        endpoint: {
          method: 'delete',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/users`,
          body: { users },
        },
        schema: subscriptionSchema,
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.subscriptionArchiveUsersRequested,
        data: {
          subscriptionId,
          users,
        },
      },
    });

    // if a user archives himself then log him out
    if (users.includes(loggedInUserId)) {
      return dispatch(logout());
    }

    dispatch(reloadUsersPage(numUsers));
  };
}

export function restoreUsers(users: Array<number>) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const subscriptionId = getActiveSubscriptionId(state);
    const numUsers = users.length;

    await dispatch({
      data: { ids: users },
      request: {
        types: [RESTORE_USERS, RESTORE_USERS_SUCCESS, RESTORE_USERS_FAIL],
        endpoint: {
          method: 'put',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/restore-users`,
          body: { users },
        },
        schema: subscriptionSchema,
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.subscriptionRestoreUsersRequested,
        data: {
          subscriptionId,
          users,
        },
      },
    });

    dispatch(reloadUsersPage(numUsers));
  };
}

export function makeUserAdmin(userId: number) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const subscriptionId = getActiveSubscriptionId(state);

    await dispatch({
      data: { ids: [userId] },
      request: {
        types: [MAKE_USER_ADMIN, MAKE_USER_ADMIN_SUCCESS, MAKE_USER_ADMIN_FAIL],
        endpoint: {
          method: 'put',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/users/${userId}`,
          body: { role: roles.accountAdmin },
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.subscriptionMakeUserAdminRequested,
        data: {
          subscriptionId,
          userId,
        },
      },
    });

    dispatch(reloadUsersPage(1));
  };
}

export function unmakeUserAdmin(userId: number) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const subscriptionId = getActiveSubscriptionId(state);
    const isLoggedInUser = userId === getUserId(state);

    await dispatch({
      data: { ids: [userId] },
      request: {
        types: [UNMAKE_USER_ADMIN, UNMAKE_USER_ADMIN_SUCCESS, UNMAKE_USER_ADMIN_FAIL],
        endpoint: {
          method: 'put',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/users/${userId}?include=user`,
          body: { role: roles.teacher },
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.subscriptionRemoveAdminRequested,
        data: {
          subscriptionId,
          userId,
        },
      },
    });

    // if the user removed the admin role from himself then redirect to their dashboard
    if (isLoggedInUser) {
      return browserHistory.replace(getSubscriptionUrl(subscriptionId));
    }

    dispatch(reloadUsersPage(1));
  };
}

export function transferAccountOwnership(newUserId: number, password: string) {
  return (dispatch: APIDispatch<UserType>, getState: GetState) => {
    const state = getState();
    const subscriptionId = getActiveSubscriptionId(state);

    return dispatch({
      data: { ids: [newUserId] },
      request: {
        types: [TRANSFER_ACCOUNT_OWNERSHIP, TRANSFER_ACCOUNT_OWNERSHIP_SUCCESS, TRANSFER_ACCOUNT_OWNERSHIP_FAIL],
        endpoint: {
          method: 'put',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/transfer-ownership?include=user`,
          body: {
            user_id: newUserId,
            password,
          },
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.subscriptionTransferAccountOwnershipRequested,
        data: {
          subscriptionId,
          newOwnerId: newUserId,
        },
      },
    });
  };
}

export function resendInvites(users: Array<number>) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const subscriptionId = getActiveSubscriptionId(state);

    return dispatch({
      data: { ids: users },
      request: {
        types: [RESEND_USER_INVITES, RESEND_USER_INVITES_SUCCESS, RESEND_USER_INVITES_FAIL],
        endpoint: {
          method: 'post',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/bulk-send-invitations`,
          body: { users },
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.subscriptionResendUsersInviteRequested,
        data: {
          subscriptionId,
          users,
        },
      },
    });
  };
}

export function bulkInviteUsers(role: string, users: Array<string>) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const subscriptionId = getActiveSubscriptionId(state);

    const body = {
      users: users.map((email) => ({
        email,
        role,
      })),
    };

    const res = await dispatch({
      request: {
        types: [BULK_INVITE_USERS, BULK_INVITE_USERS_SUCCESS, BULK_INVITE_USERS_FAIL],
        endpoint: {
          method: 'post',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/bulk-add-users`,
          body,
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.subscriptionInviteUsersRequested,
        data: {
          role,
          numUsers: users.length,
        },
      },
    });

    const errors = get(res, 'meta.errors');
    const numErrors = size(errors);

    // if some users were added then reload pending users
    if (numErrors === 0 || numErrors < users.length) {
      dispatch(loadUsers({ page: 1, status: 'invited', forceReload: true }));
    }

    return errors;
  };
}

export function bulkInviteUsersAndReloadSubscription(role: string, users: Array<string>) {
  return async (dispatch: Dispatch) => {
    const res = await dispatch(bulkInviteUsers((role: string), (users: Array<string>)));
    dispatch(loadSubscription());
    return res;
  };
}

export function inviteUser({ email, role, firstName, lastName, classes }: InvitedUserType = {}) {
  return (dispatch: APIDispatch<UserType>, getState: GetState) => {
    const state = getState();
    const subscriptionId = getActiveSubscriptionId(state);

    const body = pickBy(
      {
        email,
        role,
        first_name: firstName,
        last_name: lastName,
        classes,
      },
      identity
    );

    return dispatch({
      request: {
        types: [INVITE_USER, INVITE_USER_SUCCESS, INVITE_USER_FAIL],
        endpoint: {
          method: 'post',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/users`,
          body,
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.subscriptionInviteUserRequested,
        data: {
          subscriptionId,
          user: body,
        },
      },
    });
  };
}

export function inviteUserAndReloadSubscription({ email, role, firstName, lastName, classes }: InvitedUserType = {}) {
  return async (dispatch: Dispatch) => {
    await dispatch(inviteUser({ email, role, firstName, lastName, classes }));
    return dispatch(loadSubscription());
  };
}

export function downloadData(userId: number, password: ?string) {
  return {
    request: {
      types: [DOWNLOAD_USER_DATA, DOWNLOAD_USER_DATA_SUCCESS, DOWNLOAD_USER_DATA_FAIL],
      endpoint: {
        method: 'post',
        url: `${USER_ENDPOINT}/request-data`,
        body: {
          password,
        },
      },
      throwErrors: true,
    },
    tracking: {
      event: trackingEvents.userDownloadDataRequested,
      data: {
        userId,
      },
    },
  };
}

export function deleteAccount(userId: number, password: ?string) {
  return {
    request: {
      types: [DELETE_ACCOUNT, DELETE_ACCOUNT_SUCCESS, DELETE_ACCOUNT_FAIL],
      endpoint: {
        method: 'delete',
        url: `${USER_ENDPOINT}/erase-data`,
        body: {
          password,
        },
      },
      throwErrors: true,
    },
    tracking: {
      event: trackingEvents.userEraseDataRequested,
      data: {
        userId,
      },
    },
  };
}

export function updateUser(userId: number, { email, firstName, lastName, levelId }: UpdatedUserType) {
  const userDetails = pickBy(
    {
      email,
      first_name: firstName,
      last_name: lastName,
      level_id: levelId,
    },
    identity
  );

  return {
    data: { userId, userDetails },
    request: {
      types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL],
      endpoint: {
        method: 'put',
        url: `${USERS_ENDPOINT}/${userId}`,
        body: userDetails,
      },
      throwErrors: true,
    },
    tracking: {
      event: trackingEvents.userUpdateRequested,
      data: {
        userId,
        userDetails,
      },
    },
  };
}
