// @flow
//TODO: make component accessible, see https://hschub.atlassian.net/browse/PROD-578
import typeof Zxcvbn from 'zxcvbn';
import _ from 'lodash';
import { Text } from '@getatomi/neon';
import classnames from 'classnames/bind';

import errorMessageVariants from 'src/constants/errorMessageVariants';
import Logger from 'src/utils/Logger';
import Input from 'src/components/Input/Input';

import styles from './PasswordStrength.module.scss';

const log = new Logger('components/PasswordStrength');

const cx = classnames.bind(styles);
const SCORE_WORDS = ['weak', 'weak', 'okay', 'good', 'strong'];
const CHANGE_THROTTLE = 500;
const MIN_SCORE = 2;

function StrengthMeter({ score }: { score: number }) {
  return (
    <div className={styles.score} data-test="hide-in-percy">
      <span className={cx('rank1', { isActive: score >= 0 })} />
      <span className={cx('rank2', { isActive: score >= 2 })} />
      <span className={cx('rank3', { isActive: score >= 3 })} />
      <span className={cx('rank4', { isActive: score === 4 })} />
    </div>
  );
}

type Props = {
  className?: string,
  initialValue?: ?string,
  inputInfo?: React.Element<'span'> | string,
  isInfoInverseColor: boolean,
  minLength: number,
  name: string,
  onBlur?: (name: string, value: string) => mixed,
  onChange?: (name: string, value: string) => mixed,
};

type State = {
  errorMessage: ?string,
  score: number,
  value: string,
};

export default class PasswordStrength extends React.Component<Props, State> {
  static defaultProps = {
    isInfoInverseColor: false,
    minLength: 6,
    name: 'password',
  };

  state = {
    errorMessage: null,
    score: -1,
    value: this.props.initialValue || '',
  };

  // input ref
  input: ?Input;

  zxcvbn: ?Zxcvbn;

  // eslint-disable-next-line react/no-unused-class-component-methods
  validate = () => {
    if (this.input) {
      const { minLength } = this.props;
      const { value: password } = this.state;

      if (minLength && password.length < minLength) {
        this.setState({ errorMessage: `Your password must contain at least ${minLength} characters.` });
        return false;
      }

      // we need to calculate the score here because we need it now, not after debounce we also need
      // to make sure the library we are using is loaded, fallback to score = MIN_SCORE if not
      const score: number = this.zxcvbn ? this.zxcvbn(password).score : MIN_SCORE;

      // a score of 2 is okay, less is weak
      if (score < MIN_SCORE) {
        this.setState({
          errorMessage: `The password you’ve submitted is too easy to guess and would risk the security of your account. Try creating a longer password and/or avoid using common words or sequences.`,
        });
        return false;
      }

      return true;
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  clear = () => {
    this.setState({
      score: -1,
      errorMessage: null,
      value: '',
    });

    if (this.input) {
      this.input.clear();
    }
  };

  onChange = (name: string, value: string) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(name, value);
    }

    this.setState({ errorMessage: null, value });
    this.checkStrength(value);
  };

  checkStrength = _.debounce((value: string) => {
    if (!value) {
      return this.setState({ score: -1 });
    }

    if (this.zxcvbn) {
      const { score } = this.zxcvbn(value);
      this.setState({ score });
    }
  }, CHANGE_THROTTLE);

  async componentDidMount() {
    try {
      this.zxcvbn = (await import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn')).default;
    } catch (err) {
      log.warn(err);
    }
  }

  render() {
    const { name, minLength, className, isInfoInverseColor, ...otherProps } = this.props;
    const { score, errorMessage } = this.state;

    const inputInfo = (
      <span className={cx({ [styles.isInverseColor]: isInfoInverseColor })} data-test="password-strength-help-text">
        {score >= 0 ? (
          <>
            Your password is{' '}
            <Text as="span" color="inherit" fontSize="inherit" fontWeight="fontWeightBold">
              {SCORE_WORDS[score]}
            </Text>
            .
          </>
        ) : (
          this.props.inputInfo
        )}
      </span>
    );

    return (
      <div className={cx('root', className)}>
        <Input
          ref={(el) => {
            this.input = el;
          }}
          type="password"
          {...otherProps}
          name={name}
          minLength={minLength}
          inputInfo={inputInfo}
          onChange={this.onChange}
          inputOverlayInfo={<StrengthMeter score={score} />}
          customError={
            errorMessage
              ? {
                  message: errorMessage,
                  variant: errorMessageVariants.error,
                }
              : undefined
          }
        />
      </div>
    );
  }
}
