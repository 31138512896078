// @flow
import { useCallback, useRef, useState } from 'react';
import {
  Box,
  Divider,
  Flex,
  IconArrowBack,
  IconArrowFront,
  MobileStepper,
  Step,
  Stepper,
  useId,
  useMediaQuery,
} from '@getatomi/neon';
import pluralize from 'pluralize';

import type { NewAttachedContent } from 'src/domains/Tasks/types';
import Button from 'src/components/Button/Button';
import { getAllTopics, getAllTopicsWithQuestions } from 'src/components/RevisionDialog/utils';
import useRevisionDialogState, { type Module } from 'src/components/RevisionDialog/useRevisionDialogState';
import useRevisionDialogValidation from 'src/components/RevisionDialog/useRevisionDialogValidation';
import RevisionSelectors from 'src/components/RevisionDialog/RevisionSelectors';

import AddToTaskButton from './AddToTaskButton';
import TeacherRevisionBaseDialog from './TeacherRevisionBaseDialog';
import QuestionPreview from './QuestionPreview/QuestionPreview';
import useDraftRevision from './QuestionPreview/useDraftRevision';

type TeacherRevisionDialogProps = {|
  accountId: string,
  classId: string,
  defaultTopicIds?: ?Array<string>,
  isOpen: boolean,
  onClose: () => void,
  onCreate: (newRevision: NewAttachedContent) => void,
  subjectCode: string,
  subjectName: string,
  subjectTree: Array<Module>,
|};

export function autoGenerateTitle(selectedTopics: Array<string>, subjectTree: Array<Module>): string {
  const allTopics = getAllTopics(subjectTree);

  if (selectedTopics.length === allTopics.length) {
    return 'Revision: all topics';
  }

  const allTopicsWithQuestions = getAllTopicsWithQuestions(subjectTree);
  const selectedTopicsWithQuestions = selectedTopics.filter((topicId) => allTopicsWithQuestions.includes(topicId));
  const topicCount = selectedTopicsWithQuestions.length;
  return `Revision: ${topicCount} ${pluralize('topic', topicCount)}`;
}

export default function TeacherRevisionDialog(props: TeacherRevisionDialogProps) {
  const { accountId, classId, defaultTopicIds, isOpen, onClose, onCreate, subjectCode, subjectName, subjectTree } =
    props;

  const steps = { selector: 0, preview: 1 };
  const [currentStep, setCurrentStep] = useState<number>(steps.selector);

  const scrollRef = useRef();
  const scrollToTop = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [scrollRef]);

  const formId = useId();

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const {
    contentType,
    questionType,
    selectAllTopics,
    selectedTopics,
    sessionLength,
    setContentType,
    setQuestionType,
    setSelectedTopics,
    setSessionLength,
    questions,
    availableDurations,
    availableQuestionTypes,
    isTimeLimitEnabled,
    toggleIsTimeLimitEnabled,
    setTimeLimitMinutes,
    timeLimitMinutes,
  } = useRevisionDialogState({
    defaultTopicIds,
    isTeacher: true,
    subjectTree,
  });

  const {
    clearValidationErrors,
    contentError,
    contentFieldRef,
    questionTypeError,
    questionTypesFieldRef,
    timeLimitError,
    timeLimitFieldRef,
    validateOnSubmit,
  } = useRevisionDialogValidation();

  const { draftRevision, initDraftRevision, discardDraftRevision, replaceRevisionQuestion } = useDraftRevision({
    questions,
    questionTypeFilters: questionType,
    sessionLengthFilter: sessionLength,
  });

  const handleStepChange = (step: number) => {
    scrollToTop();
    setCurrentStep(step);
  };

  const handleProceedToPreview = () => {
    const hasValidationError = validateOnSubmit({
      hasQuestionTypes: questionType.length > 0,
      hasTopics: questions.length > 0,
      isTimeLimitEnabled,
      timeLimitMinutes,
    });
    if (hasValidationError) {
      return;
    }

    handleStepChange(steps.preview);
  };

  const addToTaskButton = (
    <AddToTaskButton
      accountId={accountId}
      classId={classId}
      contentType={contentType}
      draftRevision={draftRevision}
      formId={formId}
      isTimeLimitEnabled={isTimeLimitEnabled}
      onCreate={onCreate}
      questions={questions}
      questionType={questionType}
      selectedTopics={selectedTopics}
      sessionLength={sessionLength}
      subjectCode={subjectCode}
      subjectTree={subjectTree}
      timeLimitMinutes={timeLimitMinutes}
      validateOnSubmit={validateOnSubmit}
    />
  );

  const isCompleted = currentStep === steps.preview;

  return (
    <TeacherRevisionBaseDialog
      isOpen={isOpen}
      scrollRef={scrollRef}
      modalActions={addToTaskButton}
      onClose={onClose}
      sidebar={
        <Stepper ariaLabel="Revision creation steps" currentStep={currentStep} onChange={handleStepChange}>
          <Step title="Details" subtitle="Content and time" isCompleted={isCompleted} />
          <Step title="Questions" subtitle="Preview and edit" />
        </Stepper>
      }
    >
      <form id={formId}>
        {currentStep === steps.selector && (
          <>
            <RevisionSelectors
              clearValidationErrors={clearValidationErrors}
              isTeacher
              selectContentProps={{
                contentType,
                contentError,
                setContentType,
                contentFieldRef,
              }}
              selectTopicsProps={{
                subjectName,
                subjectTree,
                selectAllTopics,
                selectedTopics,
                setSelectedTopics,
              }}
              selectQuestionTypesProps={{
                availableQuestionTypes,
                questionType,
                setQuestionType,
                questionTypesFieldRef,
                questionTypeError,
              }}
              selectSessionLengthProps={{
                availableDurations,
                sessionLength,
                setSessionLength,
              }}
              timeLimitProps={{
                isTimeLimitEnabled,
                toggleIsTimeLimitEnabled,
                setTimeLimitMinutes,
                timeLimitError,
                timeLimitFieldRef,
                timeLimitMinutes,
              }}
            />

            {!isMobile && (
              <>
                <Box marginBlock="spacingLarge5X">
                  <Divider />
                </Box>

                <Flex justifyContent="center">
                  <Button iconAfter={<IconArrowFront />} onClick={handleProceedToPreview}>
                    Next: Preview questions
                  </Button>
                </Flex>
              </>
            )}
          </>
        )}

        {currentStep === steps.preview && (
          <QuestionPreview
            discardDraftRevision={discardDraftRevision}
            draftRevision={draftRevision}
            initDraftRevision={initDraftRevision}
            replaceRevisionQuestion={replaceRevisionQuestion}
            selectedTopics={selectedTopics}
            sessionLength={sessionLength}
          />
        )}
      </form>

      {isMobile && (
        <MobileStepper
          ariaLabel="Revision creation steps"
          backButton={
            <Button
              iconBefore={<IconArrowBack size="sizeIconSmall" />}
              isDisabled={currentStep === steps.selector}
              onClick={() => {
                handleStepChange(steps.selector);
              }}
              size="small"
              variant="tertiary"
            >
              Back
            </Button>
          }
          currentStep={currentStep}
          nextButton={
            isCompleted ? (
              addToTaskButton
            ) : (
              <Button
                iconAfter={<IconArrowFront color="colorIconInverted" size="sizeIconSmall" />}
                onClick={handleProceedToPreview}
                size="small"
              >
                Next
              </Button>
            )
          }
          steps={Object.keys(steps).length}
        />
      )}
    </TeacherRevisionBaseDialog>
  );
}
