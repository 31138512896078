// @flow
import { TextLoader as NeonTextLoader } from '@getatomi/neon';
import _ from 'lodash';

type Props = {
  testHook?: string,
  width?: number | string,
};
export default function TextLoader(props: Props) {
  const { testHook, width = `${_.random(40, 90)}%` } = props;

  return <NeonTextLoader width={width} testHook={testHook} />;
}
