// @flow
import { Box, Card, Heading, Stack, Text } from '@getatomi/neon';

import AvatarGroup from 'src/components/AvatarGroup/AvatarGroup';
import Avatar from 'src/components/Avatar/Avatar';
import { UnstyledLink } from 'src/components/Link/Link';
import EmptyAvatarGroup from 'src/components/EmptyAvatarGroup/EmptyAvatarGroup';
import type { OverviewStudent } from 'src/domains/ViewingQuizResults/types';

type StudentRevisionPanelProps = {
  students: $ReadOnlyArray<OverviewStudent>,
  to: string,
};

export default function StudentRevisionPanel(props: StudentRevisionPanelProps) {
  const { students = [], to } = props;

  const count = students.length;
  const formattedStudent = `${count} student${count === 1 ? ' needs' : 's need'}`;
  const isEmpty = count === 0;

  return (
    <Card
      as={UnstyledLink}
      to={to}
      aria-label="View students that need to revise"
      variant="rounded"
      padding={{ base: 'spacingRoot', tablet: 'spacingLarge' }}
      testHook="student-revision-panel"
    >
      <Stack>
        <Box>
          <Heading as="h2" variant="small">
            Our AI has detected that {formattedStudent} to revise
          </Heading>
          <Text variant="bodySmall1X" color="colorTextSubtle">
            They haven't completed the quiz recently or have low marks
          </Text>
        </Box>
        {isEmpty ? (
          <EmptyAvatarGroup />
        ) : (
          <AvatarGroup>
            {students.map((user, index) => (
              <Avatar key={index} user={user} size="sizeAvatarSmall" withTooltip isDecorative={false} />
            ))}
          </AvatarGroup>
        )}
        <Text color="colorTextLink">View group</Text>
      </Stack>
    </Card>
  );
}
