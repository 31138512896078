// @flow
import { get, values } from 'lodash';

import type { Plan, ReduxState } from 'src/types';
import { LOAD_PLANS, LOAD_PLANS_SUCCESS, LOAD_PLANS_FAIL } from 'src/actions/plans';
import { LOAD_SUBSCRIPTION_SUCCESS } from 'src/actions/subscriptions';
import { getActiveSubscriptionPlanCode } from 'src/reducers/subscriptions';
import planTypes from 'src/constants/planTypes';

type PlansStateType = {
  entities: { [id: string]: Plan },
  isLoaded: boolean,
  isLoading: boolean,
  routeError: ?Object,
};

const initialState = {
  entities: {},
  isLoaded: false,
  isLoading: false,
  routeError: null,
};

export default function reducer(state: PlansStateType = initialState, action: Object = {}) {
  switch (action.type) {
    case LOAD_PLANS:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_PLANS_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.result.entities.plans,
        },
        isLoading: false,
        isLoaded: true,
      };
    case LOAD_PLANS_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        routeError: action.routeError,
      };

    case LOAD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.result.entities.plans,
        },
      };

    default:
      return state;
  }
}

// Selectors
export const isPlansLoaded = (state: ReduxState) => state.plans.isLoaded;
export const getAllPlans = (state: ReduxState) => values(state.plans.entities);

export const getPaidPlansByCustomerType = (state: ReduxState, customerType: string): $ReadOnlyArray<Plan> =>
  getAllPlans(state).filter((plan) => plan.customer_type === customerType && plan.plan_type !== 'free');
export const getPlanById = (state: ReduxState, id: string) => state.plans.entities[id];
export const getActiveSubscriptionPlan = (state: ReduxState) =>
  getPlanById(state, getActiveSubscriptionPlanCode(state));
export const getPlanCurrency = (state: ReduxState) => {
  const plan = getActiveSubscriptionPlan(state);
  return get(plan, 'pricing.currency');
};
export const getPlanPaymentFrequency = (state: ReduxState) => get(getActiveSubscriptionPlan(state), 'billing_period');
export const isPerSubjectPlan = (state: ReduxState) =>
  get(getActiveSubscriptionPlan(state), 'plan_type') === planTypes.per_subject;
