// @flow
import Cookies from 'universal-cookie';
import type { ContextSetter } from '@apollo/client/link/context';

type PreviewConfig = {
  headerName: 'x-preview-mode',
  headerValue: 'true',
};

const config: PreviewConfig = {
  headerName: 'x-preview-mode',
  headerValue: 'true',
};

/**
 * This "preview_mode" is set by @learn/app when accessed using a hostname starting with "preview."
 * Here we just read this cookie to determine if we should use the preview mode.
 * @see https://github.com/hschub/platform/frontend/apps/learn/src/features/common/previewing/config.ts#L15
 */
function isPreviewModeEnabled(): boolean {
  const cookie = new Cookies();
  return cookie.get('preview_mode') === 'on';
}

export const makePreviewLink =
  (): ContextSetter =>
  (_, { headers }) => {
    if (!isPreviewModeEnabled()) {
      return { headers };
    }

    return {
      headers: {
        ...headers,
        [config.headerName]: config.headerValue,
      },
    };
  };
