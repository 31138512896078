// @flow
import { Container } from '@getatomi/neon';
import { useQuery } from '@apollo/client';
import Helmet from 'react-helmet';

import type { GetChallengeMarks, GetChallengeMarksVariables } from 'src/graphql/types/generated/GetChallengeMarks';
import { useNavigationContext } from 'src/hooks/useNavigationContext';
import { getChallengeMarksUrl, getChallengeProgressReportUrl } from 'src/utils/routes';
import { prepareNavigation } from 'src/utils/prepareNavigation';
import BackLink from 'src/components/BackLink/BackLink';
import { type NavigationContext } from 'src/utils/navigationContext';

import GET_CHALLENGE_MARKS from './GetChallengeMarks.graphql';
import { getChallengeQuestionUrlFactory } from './utilities/getQuestionUrlFactory';
import { prepareChallengeMarksData } from './utilities/prepareMarksData/prepareMarksData';
import { getIsTeacherReviewFeatureEnabled } from './utilities/teacherReviewFeature';
import Marks from './Marks/Marks';

export type Params = {|
  classId: string,
  moduleId: string,
  postId: string,
  subscriptionId: string,
|};

export type ChallengeMarksContainerProps = {|
  params: Params,
|};

export function ChallengeMarksContainer(props: ChallengeMarksContainerProps) {
  const { params } = props;
  const { classId, postId: lessonId, moduleId, subscriptionId: accountId } = params;

  const [navigationContext] = useNavigationContext();

  const modulePageFallback: NavigationContext = {
    location: 'module',
    metadata: {
      accountId,
      classId,
      moduleId,
      title: 'Class',
    },
  };

  const { backUrl, backLinkUrl, backLinkLabel } = prepareNavigation(navigationContext ?? modulePageFallback);

  const marksUrl = getChallengeMarksUrl(accountId, classId, moduleId, lessonId);
  const overviewUrl = getChallengeProgressReportUrl(accountId, classId, moduleId, lessonId);

  const { data: marksData }: { data: ?GetChallengeMarks } = useQuery<GetChallengeMarks, GetChallengeMarksVariables>(
    GET_CHALLENGE_MARKS,
    {
      variables: {
        accountId,
        classId,
        lessonId,
        filters: {
          roles: ['STUDENT'],
        },
      },
    }
  );

  const accountData = marksData?.me?.account;

  const isTeacherReviewFeatureEnabled = accountData
    ? getIsTeacherReviewFeatureEnabled({ accountFeatures: accountData.enabledFeatures })
    : false;

  const getChallengeQuestionUrl = getChallengeQuestionUrlFactory({
    accountId,
    classId,
    moduleId,
    postId: lessonId,
    returnTo: backUrl,
    isTeacherReviewFeatureEnabled,
  });

  const preparedData = marksData ? prepareChallengeMarksData(marksData, getChallengeQuestionUrl) : null;

  return (
    <>
      <Helmet>
        <title>{preparedData?.title ?? ''}</title>
      </Helmet>
      <Container>
        <BackLink to={backLinkUrl}>{`Back to ${backLinkLabel}`}</BackLink>
      </Container>
      <Marks
        attachedContent={preparedData?.attachedContent}
        averageMarkMetrics={preparedData?.averageMarkMetrics}
        challengeTier={preparedData?.challengeTier}
        classId={classId}
        isLoading={!preparedData}
        marksUrl={marksUrl}
        overviewUrl={overviewUrl}
        sequence={preparedData?.sequence}
        students={preparedData?.students}
        subheading={preparedData?.subheading}
        subscriptionId={accountId}
        isTeacherReviewFeatureEnabled={isTeacherReviewFeatureEnabled}
      />
    </>
  );
}

export default ChallengeMarksContainer;
