// @flow
import cx from 'classnames';
import { Box, useMediaQuery } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import logoImg from 'src/assets/images/atomi-logo.svg';
import logoWhiteImg from 'src/assets/images/atomi-logo-white.svg';
import { trackingCtas, trackingLocations } from 'src/constants/tracking';
import { LoadingBar } from 'src/components/LoadingBar/LoadingBar';

import styles from './LogoOnlyLayout.module.scss';

type Props = {
  children: React.Node,
  hideLogoOnMobile?: boolean,
  withContainer?: boolean,
  withGreyBg?: boolean,
  withWhiteBg?: boolean,
};

export default function LogoOnlyLayout(props: Props) {
  const { children, hideLogoOnMobile, withContainer, withGreyBg, withWhiteBg } = props;
  const hasBlueBackground = !withGreyBg && !withWhiteBg;
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  return (
    <div className={cx(styles.root, { [styles.withGreyBg]: withGreyBg, [styles.withWhiteBg]: withWhiteBg })}>
      {hideLogoOnMobile && isMobile ? null : (
        <Box as="header" marginBottom="spacingLarge3X">
          <LoadingBar isInverted />
          <Link to="/" trackingData={{ cta: trackingCtas.navigateToHome, location: trackingLocations.header }}>
            <img
              src={hasBlueBackground ? logoWhiteImg : logoImg}
              alt="Atomi home"
              width={hasBlueBackground ? 92 : 110}
            />
          </Link>
        </Box>
      )}
      <main
        id="main"
        role="main"
        className={cx({ [styles.withContainer]: withContainer, [styles.withMainSpacingTop]: hasBlueBackground })}
      >
        {children}
      </main>
    </div>
  );
}
