// @flow
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';

type TimePickerProps = {|
  label?: string,
  onSelectionChange: (key: string) => mixed,
  selectedKey?: string,
|};

export default function TimePicker(props: TimePickerProps) {
  const { label, onSelectionChange, selectedKey } = props;

  return (
    <Dropdown label={label} onSelectionChange={onSelectionChange} selectedKey={selectedKey}>
      <Item key="7:00">7 am</Item>
      <Item key="8:00">8 am</Item>
      <Item key="9:00">9 am</Item>
      <Item key="10:00">10 am</Item>
      <Item key="11:00">11 am</Item>
      <Item key="12:00">12 pm</Item>
      <Item key="13:00">1 pm</Item>
      <Item key="14:00">2 pm</Item>
      <Item key="15:00">3 pm</Item>
      <Item key="16:00">4 pm</Item>
      <Item key="17:00">5 pm</Item>
      <Item key="18:00">6 pm</Item>
      <Item key="19:00">7 pm</Item>
      <Item key="20:00">8 pm</Item>
    </Dropdown>
  );
}
