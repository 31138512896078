/**
 * Blackbox the Logger.js file in the Chrome Dev Tools
 * to get the correct line number in the console out when developing locally
 * More info at: https://gist.github.com/paulirish/c307a5a585ddbcc17242
 */
import Cookies from 'universal-cookie';
import _ from 'lodash';
import yn from 'yn';

import Sentry from 'src/utils/sentry';
import { ACCESS_TOKEN_COOKIE } from 'src/constants/cookies';

// The list of of connection errors we don't want to send to Sentry: these occur when a user loses
// their network connection and would just spam Sentry. These errors can't be part of the Sentry
// ignoreErrors array in utils/Sentry as we are directly using the `captureMessage` method here.
const ignoredSentryErrors = ['Failed to fetch', 'NetworkError when attempting to fetch resource'];

const getHeaders = (res) =>
  res.headers &&
  (_.isPlainObject(res.headers)
    ? res.headers
    : Object.assign({}, ...Array.from(res.headers, ([key, value]) => ({ [key]: value }))));

export default class Logger {
  static LOG_LEVELS = {
    error: 10,
    warn: 20,
    info: 30,
    log: 40,
  };

  cookies = new Cookies();

  constructor(moduleName) {
    this.moduleName = moduleName;
  }

  consoleWriter(level, message, error, ...others) {
    const envLogLevel = Logger.LOG_LEVELS[process.env.LOG_LEVEL];
    const envReportLevel = Logger.LOG_LEVELS[process.env.ERROR_REPORT_LEVEL];
    const currentLogLevel = Logger.LOG_LEVELS[level];

    if (yn(process.env.SENTRY_ENABLED) && envReportLevel >= currentLogLevel && !ignoredSentryErrors.includes(message)) {
      Sentry.withScope((scope) => {
        scope.setTag('logger', __CLIENT__ ? 'javascript' : 'node');
        scope.setLevel(level === 'warn' ? 'warning' : level);
        scope.setExtras({
          module: this.moduleName,
          cookies: {
            accessToken: this.cookies.get(ACCESS_TOKEN_COOKIE),
          },
          ...(error && {
            error,
            responseHeaders: getHeaders(
              // wrapped error || fetch Response
              (error.output && error.output.response) || error
            ),
          }),
        });
        Sentry.captureMessage(`[${this.moduleName}]: ${message}`);
      });
    }

    // only console out if the configured env log level allows it
    if (envLogLevel >= currentLogLevel) {
      const moduleName = this.moduleName ? `[${this.moduleName}]` : '';
      const args = error ? [error, ...others] : others;
      console[level](`${level.toUpperCase()} ${moduleName}`, message, ...args);
    }
  }

  log(...args) {
    this.consoleWriter('log', ...args);
  }

  info(...args) {
    this.consoleWriter('info', ...args);
  }

  warn(...args) {
    this.consoleWriter('warn', ...args);
  }

  error(...args) {
    this.consoleWriter('error', ...args);
  }
}
