// @flow
import classnames from 'classnames/bind';

import styles from './Loader.module.scss';

type Props = {
  className?: string,
  isFullHeight?: boolean,
};

const cx = classnames.bind(styles);

export default function Loader(props: Props) {
  return (
    <div className={cx('root', props.className, { isFullHeight: props.isFullHeight })}>
      <div>
        <span className={styles.ballOne} />
        <span className={styles.ballTwo} />
        <span className={styles.ballThree} />
      </div>
    </div>
  );
}
