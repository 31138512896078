// @flow

type Props = {
  children: React.Node,
  fallbackComponent: React.Element<any>,
};

type State = {
  eventId?: string,
  hasError: boolean,
};

export default class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  componentDidCatch(error: Error, info: { componentStack: string }) {
    this.setState({ hasError: true });
    // https://docs.sentry.io/platforms/javascript/react/
    if (window.Sentry) {
      window.Sentry.withScope((scope) => {
        scope.setExtras(info);
        const eventId = window.Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return React.cloneElement(this.props.fallbackComponent, { eventId: this.state.eventId });
    }
    return this.props.children;
  }
}
