import { Box, Card, Flex, IconActionFlip, IconLink, Stack, Text, Truncate } from '@getatomi/neon';

import { ActionMenu, Item } from 'src/components/ActionMenu/ActionMenu';
import Date from 'src/components/Date/Date';
import Link from 'src/components/Link/Link';
import useAlertDialog from 'src/hooks/components/useAlertDialog';

export default function CardMappingDetails(props) {
  const { sheetLink, updatedAt, onConfirm } = props;

  const [newExportPrompt, toggleNewExportPrompt] = useAlertDialog({
    onConfirmLabel: 'Export',
    heading: 'This will override the existing mapping',
    children: 'Creating a new export will erase the current mapping and create a new Google Sheet.',
    onConfirm,
  });

  return (
    <Card padding="spacingSmall" variant="elevated">
      <Box display="grid" gridGap="spacingRoot" gridTemplateColumns="repeat(2, auto)" alignItems="baseline">
        <Box minWidth="0">
          <Stack>
            <Flex gap="spacingSmall1X" alignItems="center">
              <IconActionFlip size="sizeIconSmall" />
              <Text color="colorTextSubtle" fontSize="fontSizeSmall" maxWidth="sizeFull">
                <Truncate>
                  Last updated: <Date format="DD MMMM, YYYY [at] h:mm a" value={updatedAt} />
                </Truncate>
              </Text>
            </Flex>
            <Flex gap="spacingSmall1X" alignItems="center">
              <IconLink size="sizeIconSmall" />
              <Text color="colorTextSubtle" fontSize="fontSizeSmall" maxWidth="sizeFull">
                <Truncate>
                  Google Sheet URL:{' '}
                  <Link href={sheetLink} isExternal>
                    {sheetLink}
                  </Link>
                </Truncate>
              </Text>
            </Flex>
          </Stack>
        </Box>
        <ActionMenu orientation="vertical" onAction={() => toggleNewExportPrompt()}>
          <Item key="new-export">New export</Item>
        </ActionMenu>
      </Box>
      {newExportPrompt}
    </Card>
  );
}
