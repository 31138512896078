// @flow
import { Box, Container, Heading, HideVisually, TextLoader } from '@getatomi/neon';
import Helmet from 'react-helmet';
import invariant from 'invariant';

import { trackingCtas } from 'src/constants/tracking';
import ActionBar from 'src/components/ActionBar/ActionBar';
import Button from 'src/components/Button/Button';
import Duration from 'src/components/Duration/Duration';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import LessonList, { LessonListLoader } from 'src/components/LessonList/LessonList';
import useSelectedLessons from 'src/components/LessonList/useSelectedLessons';
import { getPostUniversalUrl } from 'src/utils/routes';

import useGetModuleLessons from './useGetModuleLessons';
import useEmbedContent, { getPostData } from './useEmbedContent';

type SelectLessonsProps = {|
  params: {
    classId: string,
    moduleId: string,
    subscriptionId: string,
  },
|};

export default function SelectLessons(props: SelectLessonsProps) {
  const { classId, moduleId, subscriptionId } = props.params;

  const allowMultiSelect = __CLIENT__ ? getPostData().allowMultiSelect : false;

  const { embedContent, embedForm } = useEmbedContent();

  const { module, error, loading } = useGetModuleLessons({
    accountId: subscriptionId,
    classId,
    moduleId,
  });

  const { Selecting, selectedLessons, selectedLessonsDuration } = useSelectedLessons({
    topics: module?.topics ?? [],
  });

  if (error) {
    return (
      <Box marginTop="spacingRoot">
        <GraphQLError error={error} description="We couldn’t load your lessons." />
      </Box>
    );
  }

  if (loading) {
    return (
      <Container paddingInline="spacingNone">
        <HideVisually>Loading lessons for module.</HideVisually>
        <TextLoader
          height={50}
          width={300}
          marginTop="spacingSmall"
          marginBottom="spacingLarge2X"
          marginLeft="spacingLarge"
        />
        <LessonListLoader />
      </Container>
    );
  }

  invariant(module, 'Module should be defined');

  const { name, topics } = module;

  return (
    <>
      <Helmet>
        <title>Select lessons</title>
      </Helmet>
      <Container paddingInline="spacingRoot">
        <Heading as="h1" marginTop="spacingSmall1X" variant="large">
          {name}
        </Heading>
      </Container>

      <LessonList
        topics={topics}
        checkboxProps={
          allowMultiSelect
            ? {
                isSelected: Selecting.isSelected,
                onSelect: Selecting.select,
              }
            : undefined
        }
        renderAction={(lesson) => {
          const embedUrl = getPostUniversalUrl(lesson.id, true);
          return (
            <Button
              size="small"
              variant="secondary"
              onClick={() => {
                embedContent([
                  {
                    id: lesson.id,
                    name: lesson.name,
                    embedUrl,
                  },
                ]);
              }}
              trackingData={{
                cta: trackingCtas.ltiEmbedSingleLesson,
              }}
            >
              Embed
            </Button>
          );
        }}
      />

      <ActionBar
        selectedItemCount={Selecting.itemIds.length}
        onClearSelection={Selecting.clear}
        meta={<Duration duration={selectedLessonsDuration} format="minutes" />}
      >
        <Button
          onClick={() => {
            embedContent(
              selectedLessons.map((lesson) => {
                const embedUrl = getPostUniversalUrl(lesson.id, true);
                return {
                  id: lesson.id,
                  name: lesson.name,
                  embedUrl,
                };
              })
            );
          }}
          trackingData={{
            cta: trackingCtas.ltiBulkEmbedLessons,
          }}
        >
          Embed
        </Button>
      </ActionBar>

      {embedForm}
    </>
  );
}
