// @flow
import { connect } from 'react-redux';

import type { ReduxState, UserStatusType } from 'src/types';
import { NavTabs, NavTabsLink } from 'src/components/NavTabs/NavTabs';
import { getActiveSubscription, isFamilyPlan as isFamilyPlanSelector } from 'src/reducers/subscriptions';
import { getSubscriptionUsersUrl } from 'src/utils/routes';

export const mapStateToProps = (state: ReduxState) => {
  const subscription = getActiveSubscription(state);
  const countActive = subscription.active_students_count + subscription.active_teachers_count;

  return {
    isFamilyPlan: isFamilyPlanSelector(state),
    countActive,
    countInvited: subscription.total_users_count - countActive,
  };
};

function getFilterUrl(subscriptionId: number, filter: UserStatusType) {
  return {
    pathname: getSubscriptionUsersUrl({ subscriptionId }),
    query: { status: filter },
  };
}

type Props = {
  subscriptionId: number,
};

type InjectedProps = Props & {
  countActive: number,
  countInvited: number,
  isFamilyPlan: boolean,
};

function UserStatusFilters(props: InjectedProps) {
  const { countActive, countInvited, isFamilyPlan, subscriptionId } = props;

  return (
    <NavTabs variant="secondary" ariaLabel="User status navigation">
      <NavTabsLink
        to={getFilterUrl(subscriptionId, 'active')}
        activeMatcher={({ search }) => search === '' || search.includes('active')}
      >
        Active ({countActive})
      </NavTabsLink>
      <NavTabsLink
        to={getFilterUrl(subscriptionId, 'invited')}
        activeMatcher={({ search }) => search.includes('invited')}
      >
        Pending ({countInvited})
      </NavTabsLink>
      {!isFamilyPlan && (
        <NavTabsLink
          to={getFilterUrl(subscriptionId, 'archived')}
          activeMatcher={({ search }) => search.includes('archived')}
        >
          Archived
        </NavTabsLink>
      )}
    </NavTabs>
  );
}

export default (connect(mapStateToProps)(UserStatusFilters): React.AbstractComponent<Props>);
