// @flow
import { useEffect, useRef, useState } from 'react';
import invariant from 'invariant';

import getEmbedCode from 'src/utils/getEmbedCode';

type PostData = {|
  allowMultiSelect: boolean,
  formAction: string,
  originalPostData: string,
  retrieveResourceUrl: string,
|};

type ContentItems = {|
  embedHtml: string,
  id: string,
  title: string,
  url: string,
|};

export type EmbeddedContent = {|
  embedUrl: string,
  id: string,
  name: string,
|};

type EmbedContentData = {|
  embedContent: (lessons: $ReadOnlyArray<EmbeddedContent>) => void,
  embedForm: React.Node,
|};

type EmbedFormProps = {|
  embeddedContent: string,
|};

export function getPostData(): PostData {
  const postData = sessionStorage.getItem('postData');
  invariant(postData, 'postData should be set in sessionStorage');

  const deserializedPostData = JSON.parse(decodeURIComponent(atob(postData)));

  const {
    accept_multiple: acceptMultiple,
    share_url: formAction,
    retrieve_resource_url: retrieveResourceUrl,
  } = deserializedPostData;

  return {
    allowMultiSelect: acceptMultiple === 'true',
    formAction,
    originalPostData: postData,
    retrieveResourceUrl,
  };
}

export function getEmbeddedContent(contents: $ReadOnlyArray<EmbeddedContent>): Array<ContentItems> {
  const { retrieveResourceUrl } = getPostData();
  return contents.map((item) => ({
    embedHtml: getEmbedCode(retrieveResourceUrl + item.embedUrl),
    id: item.id,
    title: item.name,
    url: retrieveResourceUrl + item.embedUrl,
  }));
}

function EmbedForm(props: EmbedFormProps) {
  const { embeddedContent } = props;

  const formRef = useRef<null | HTMLFormElement>(null);

  useEffect(() => {
    // Submit the form once it is mounted to ensure the
    // `content_items` field is populated before submitting
    if (formRef.current) {
      formRef.current.submit();
    }
  }, []);
  const { originalPostData, formAction } = getPostData();
  return (
    <form
      aria-hidden
      aria-label="Embed Atomi content"
      action={formAction}
      method="post"
      encType="application/x-www-form-urlencoded"
      ref={formRef}
    >
      <input aria-label="Original Data" type="hidden" name="original_post_data" value={originalPostData} />
      <input aria-label="Content Items" type="hidden" name="content_items" value={embeddedContent} />
    </form>
  );
}

export default function useEmbedContent(): EmbedContentData {
  const [embeddedContent, setEmbeddedContent] = useState<?string>(null);

  const embedContent = (contents: $ReadOnlyArray<EmbeddedContent>) => {
    if (embeddedContent) {
      return;
    }
    setEmbeddedContent(JSON.stringify(getEmbeddedContent(contents)));
  };

  const embedForm = embeddedContent ? <EmbedForm embeddedContent={embeddedContent} /> : null;

  return {
    embedContent,
    embedForm,
  };
}
