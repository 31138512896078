// @flow
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Container, Divider, HideVisually, Stack } from '@getatomi/neon';

import type { ReduxState, GetState, Dispatch } from 'src/types';
import connectData from 'src/decorators/connectData';
import { loadCredits } from 'src/actions/credits';
import { loadCurrentPlanRenewalEstimate, loadCurrentPlanReactivateEstimate } from 'src/actions/estimates';
import { loadInvoices } from 'src/actions/invoices';
import { loadSubscription } from 'src/actions/subscriptions';
import { loadPaymentSources } from 'src/actions/paymentSources';
import { getActiveSubscriptionPlan } from 'src/reducers/plans';
import { isLoggedInAsSuperAdmin } from 'src/reducers/auth';
import { isInvoicesLoaded } from 'src/reducers/invoices';
import {
  getActiveSubscription,
  getTrialEndDate,
  isPaidSchoolPlan as isPaidSchoolPlanSelector,
  isFreeSchoolPlan as isFreeSchoolPlanSelector,
  isFreeStudentPlan as isFreeStudentPlanSelector,
  isPaidRetailPlan as isPaidRetailPlanSelector,
  isRetailPlan as isRetailPlanSelector,
  isFamilyPlan as isFamilyPlanSelector,
  isSubscriptionCancelled,
  isTrialSubscription as isTrialSubscriptionSelector,
  isAccountOverdueSince as isAccountOverdueSinceSelector,
} from 'src/reducers/subscriptions';
import { isPaymentSourcesLoaded } from 'src/reducers/paymentSources';

import SubscriptionInvoices from './SubscriptionInvoices/SubscriptionInvoices';
import PaymentDetails from './PaymentDetails/PaymentDetails';
import SchoolBillingEstimate from './SchoolBillingEstimate/SchoolBillingEstimate';
import PlanDetails from './PlanDetails/PlanDetails';
import AccountUsage from './AccountUsage/AccountUsage';

export const fetchData = (getState: GetState, dispatch: Dispatch) => {
  const promises = [];
  promises.push(dispatch(loadSubscription()));

  const state = getState();

  if (!isInvoicesLoaded(state)) {
    promises.push(dispatch(loadInvoices()));
  }

  // load payment sources for retail plans (card details)
  if (isRetailPlanSelector(state) && !isPaymentSourcesLoaded(state)) {
    promises.push(dispatch(loadPaymentSources()));
  }

  if (!isFreeSchoolPlanSelector(state) && !isFreeStudentPlanSelector(state)) {
    if (isSubscriptionCancelled(state)) {
      promises.push(dispatch(loadCurrentPlanReactivateEstimate()));
    } else {
      promises.push(dispatch(loadCurrentPlanRenewalEstimate()));
    }
  }

  promises.push(dispatch(loadCredits()));

  return Promise.all(promises);
};

const mapStateToProps = (state: ReduxState) => ({
  subscription: getActiveSubscription(state),
  plan: getActiveSubscriptionPlan(state),
  isSuperAdmin: isLoggedInAsSuperAdmin(state),
  isPaidSchoolPlan: isPaidSchoolPlanSelector(state),
  isFreeSchoolPlan: isFreeSchoolPlanSelector(state),
  isFamilyPlan: isFamilyPlanSelector(state),
  isPaidRetailPlan: isPaidRetailPlanSelector(state),
  isFreeStudentPlan: isFreeStudentPlanSelector(state),
  isTrialSubscription: isTrialSubscriptionSelector(state),
  trialEndDate: getTrialEndDate(state),
  isAccountOverdueSince: isAccountOverdueSinceSelector(state),
});

type Props = {
  ...$Call<typeof mapStateToProps, ReduxState>,
};

function Billing(props: Props) {
  const {
    isAccountOverdueSince,
    isFamilyPlan,
    isFreeSchoolPlan,
    isFreeStudentPlan,
    isPaidRetailPlan,
    isPaidSchoolPlan,
    isSuperAdmin,
    isTrialSubscription,
    plan,
    subscription,
    trialEndDate,
  } = props;
  const isStudentPlan = isFreeStudentPlan || isPaidRetailPlan;
  const pageTitle = 'Billing';

  return (
    <Container>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <HideVisually>
        <h2>{pageTitle}</h2>
      </HideVisually>
      <Stack spacing="spacingLarge5X">
        <PlanDetails
          plan={plan}
          isFamilyPlan={isFamilyPlan}
          isPaidSchool={isPaidSchoolPlan}
          isFreeSchoolPlan={isFreeSchoolPlan}
          isPaidRetailPlan={isPaidRetailPlan}
          isFreeStudentPlan={isFreeStudentPlan}
          isTrialSubscription={isTrialSubscription}
          expiryDate={subscription.ends_at}
          numSeats={subscription.quantity}
          numSeatsUsed={subscription.quantity_used}
          trialEndDate={trialEndDate}
        />
        {isPaidSchoolPlan && <SchoolBillingEstimate />}
        <Divider />
        {(isStudentPlan || isFamilyPlan) && (
          <>
            <PaymentDetails />
            <Divider />
          </>
        )}
        <SubscriptionInvoices isAccountOverdueSince={isAccountOverdueSince} />
        {isSuperAdmin && (
          <>
            <Divider />
            <AccountUsage accountId={String(subscription.id)} />
          </>
        )}
      </Stack>
    </Container>
  );
}

export default connectData(fetchData)(connect(mapStateToProps)(Billing));
