// @flow
import { Box, Container, Skeleton } from '@getatomi/neon';

import BackLink from 'src/components/BackLink/BackLink';

type TaskContainerLoaderProps = {
  backLinkLabel: string,
  backLinkUrl: string,
};
function TaskContainerLoader(props: TaskContainerLoaderProps) {
  const { backLinkLabel, backLinkUrl } = props;

  return (
    <>
      <Box display={{ base: 'none', tablet: 'block' }} paddingBottom={{ base: 'none', tablet: 'spacingLarge5X' }}>
        <Container>
          <BackLink to={backLinkUrl}>{backLinkLabel}</BackLink>
        </Container>
      </Box>
      <Container maxWidth="sizeContainerRoot">
        <Skeleton animation="wave" width={700} height={150} testHook="task-card-loader" />
      </Container>
    </>
  );
}

export default TaskContainerLoader;
