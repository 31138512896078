// @flow

import { type ContextRouter, withRouter } from 'react-router';

import { strengthThresholds } from 'src/utils/config';
import useUpgradePrompt from 'src/hooks/components/useUpgradePrompt';
import { ChallengeResults } from 'src/components/ChallengeResults/ChallengeResults';
import { parseChallengeResults, type ChallengeResults as ChallengeResultsType } from 'src/utils/parseChallengeResults';

export type NextLessonProps = {|
  isLocked: boolean,
  url: string,
|};

export type PostChallengeResultsContainerProps = {|
  accountId: string,
  challengeFirstItemUrl: string,
  isLoggedInAsChild: boolean,
  isSchoolPlan: boolean,
  nextLessonProps: NextLessonProps | null,
  router: ContextRouter,
|};

export function PostChallengeResultsContainer({
  accountId,
  challengeFirstItemUrl,
  isLoggedInAsChild,
  isSchoolPlan,
  nextLessonProps,
  router,
}: PostChallengeResultsContainerProps) {
  const { query } = router.location;

  const results = query.data ? parseChallengeResults(query.data) : null;
  const marks: ChallengeResultsType = results ?? { scored: 0, missed: 0, skipped: 0, strength: 0 };

  const [modal, createShowModal] = useUpgradePrompt({
    shouldRequestUpgrade: isSchoolPlan || isLoggedInAsChild,
    subscriptionId: Number(accountId),
  });

  const showModal = createShowModal();

  const onContinue = nextLessonProps
    ? () => {
        if (nextLessonProps.isLocked) {
          showModal();
        } else {
          router.push(nextLessonProps.url);
        }
      }
    : null;

  return (
    <>
      <ChallengeResults
        challengeFirstItemUrl={challengeFirstItemUrl}
        scoredMarks={marks.scored}
        missedMarks={marks.missed}
        skippedMarks={marks.skipped}
        strengthPercentage={marks.strength}
        strengthThresholds={strengthThresholds}
        onContinue={onContinue}
      />
      {nextLessonProps && nextLessonProps.isLocked ? modal : null}
    </>
  );
}

export default withRouter(PostChallengeResultsContainer);
