// @flow
import { Container, EmptyState, Illustration } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import links from 'src/constants/links';

type TaskDoesNotExistProps = {
  tasksPageRedirectUrl: string,
};

function TaskDoesNotExist(props: TaskDoesNotExistProps) {
  return (
    <Container>
      <EmptyState
        media={<Illustration name="main-browser" />}
        heading="Oops, sorry!"
        headingProps={{ as: 'h1' }}
        description="We can’t seem to find the task you’re looking for."
        primaryAction={<Link href={props.tasksPageRedirectUrl}>Take me back to the tasks page</Link>}
        helpInfo={
          <>
            Having trouble?{' '}
            <Link href={links.support.home} isExternal variant="monochrome">
              Find some useful information in our Help Centre
            </Link>
            .
          </>
        }
      />
    </Container>
  );
}

export default TaskDoesNotExist;
