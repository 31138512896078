// @flow
import { ApolloError, useQuery } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetClass,
  GetClassVariables,
  GetClass_me_account_class as GqlClass,
} from 'src/graphql/types/generated/GetClass';

import GET_CLASS from './GetClass.graphql';

type Props = {|
  classId: string,
  isLoggedInAsStudent: boolean,
  subscriptionId: string,
|};

export type ClassData = {|
  id: string,
  levels: $ReadOnlyArray<{|
    id: string,
    name: string,
  |}>,
  name: string,
  studentCount?: number,
  studentProgress?: {
    completedContent: number,
    totalContent: number,
  },
  subject: {|
    code: string,
    color: string,
    groupCode: string,
    icon: string,
    name: string,
  |},
  teacherCount: number,
|};

type Output = {|
  class: ?ClassData,
  error: ?ApolloError,
  loading: boolean,
|};

function transformClass(gqlClass: GqlClass): ClassData {
  const schoolAccountLevel = gqlClass.level;
  const retailAccountLevels = gqlClass.subject.levels.map((level) => ({ id: level.id, name: level.name }));

  const studentCount = gqlClass.students ? gqlClass.students.length : undefined;

  return {
    id: gqlClass.id,
    name: gqlClass.name,
    levels: schoolAccountLevel ? [{ id: schoolAccountLevel.id, name: schoolAccountLevel.name }] : retailAccountLevels,
    studentCount,
    subject: {
      code: gqlClass.subject.code,
      color: gqlClass.subject.color,
      groupCode: gqlClass.subject.groupCode,
      icon: gqlClass.subject.icon,
      name: gqlClass.subject.name,
    },
    teacherCount: gqlClass.teachers?.length ?? undefined,
  };
}

export default function useGetClass(props: Props): Output {
  const { classId, isLoggedInAsStudent, subscriptionId } = props;

  const { data, error, loading } = useQuery<GetClass, GetClassVariables>(GET_CLASS, {
    variables: {
      accountId: subscriptionId,
      classId,
      isStudent: isLoggedInAsStudent,
    },
  });

  if (error) {
    return {
      error,
      loading: false,
      class: null,
    };
  }

  if (loading && !data) {
    return {
      loading: true,
      error: null,
      class: null,
    };
  }

  const classData = data?.me?.account?.class;
  invariant(classData, 'Class data should be defined');

  return {
    class: transformClass(classData),
    loading: false,
    error: null,
  };
}
