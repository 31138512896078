// @flow
import { useState, useRef } from 'react';

export type AsyncValidation = {|
  clear: () => void,
  isLoading: boolean,
  isValid: {
    current: string | null,
  },
  validate: (value: string) => Promise<void>,
|};

export default function useAsyncValidation(
  validationCallback: (value: string) => Promise<string | null>
): AsyncValidation {
  const isValid = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const validate = async (value: string) => {
    setIsLoading(true);
    isValid.current = await validationCallback(value);
    setIsLoading(false);
  };

  const clear = () => {
    isValid.current = null;
  };

  return { isLoading, isValid, validate, clear };
}
