// @flow
import _ from 'lodash';

import type { UserRoles, UserType } from 'src/types';
import roles, { rolesWithRank, rolesLabels } from 'src/constants/userRoles';

const getUserRank = (userRole: UserRoles): number => _.get(_.find(rolesWithRank, { role: userRole }), 'rank');

export const getUsersWithHigherRole = (userRole: UserRoles, users: Array<UserType>): Array<UserType> => {
  const rank = getUserRank(userRole);
  return users.filter((u) => getUserRank(u.role) > rank);
};

export const getUsersWithHigherRoleIds = (userRole: UserRoles, users: Array<UserType>): Array<UserType> => {
  return _.map(getUsersWithHigherRole(userRole, users), 'id');
};

export const getEqualOrHigherRoles = (userRole: UserRoles): $ReadOnlyArray<UserRoles> => {
  const userRank = getUserRank(userRole);
  return _.map(
    _.filter(rolesWithRank, (role) => role.rank <= userRank),
    (role) => role.role
  );
};

export const getRolesForInvite = (userRole: UserRoles): $ReadOnlyArray<{ label: string, value: UserRoles }> => {
  const excludedRoles = [roles.accountAdmin, roles.accountOwner, roles.familyAccountOwner, roles.accountManager];

  return _.map(
    _.filter(getEqualOrHigherRoles(userRole), (role) => !excludedRoles.includes(role)),
    (role) => ({
      label: rolesLabels[role],
      value: role,
    })
  );
};
