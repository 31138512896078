// @flow
import { AttachmentList, HelpText, PostSummary, Flex } from '@getatomi/neon';
import React from 'react';

import PostProgress from 'src/components/PostProgress/PostProgress';
import type { AttachedContent, TaskAttachment } from 'src/domains/Tasks/types';
import type { TaskContent } from 'src/domains/Tasks/Task/taskTransformer';
import type { ClassTaskContent } from 'src/domains/Tasks/ClassTasks/classTasksTransformer';
import type { TaskAttachmentWithFreshness } from 'src/components/useTaskDialog/hooks/useAttachments';
import type { OpenProgressReportDialogParams } from 'src/hooks/components/useProgressReportDialog';
import PostDuration from 'src/components/PostDuration/PostDuration';
import PostIcon from 'src/components/PostIcon/PostIcon';
import { UnstyledLink } from 'src/components/Link/Link';
import { externalContent } from 'src/domains/Tasks/components/TaskAttachmentList/TaskAttachmentList';
import postTypes from 'src/constants/postTypes';

export function renderFileAttachment(
  getAttachment: (id: string) => ?TaskAttachment | ?TaskAttachmentWithFreshness,
  showInfectedPrompt: () => mixed
) {
  return function renderContent(readOnly: boolean = false) {
    return function render({ id, onRemove }: { id: string, onRemove: () => mixed }) {
      const attachment = getAttachment(id);
      if (!attachment) return null;
      const { fileName = '', isInfected, isUploading } = attachment;
      const helpTextId = `${id}-help-text`;

      return (
        <>
          <AttachmentList.Attachment
            removeAssistiveText="Remove file"
            onRemove={readOnly ? undefined : onRemove}
            validationTextId={isInfected ? helpTextId : undefined}
          >
            {showInfectedPrompt &&
              (isUploading ? (
                <AttachmentList.Loader />
              ) : (
                // second param is the onClick event to trigger if the file is infected
                externalContent(attachment, showInfectedPrompt)
              ))}
          </AttachmentList.Attachment>
          {isInfected && (
            <HelpText
              id={helpTextId}
              variant="error"
            >{`The file '${fileName}' did not pass the virus security scan and can’t be downloaded`}</HelpText>
          )}
        </>
      );
    };
  };
}

export function renderReadOnlyAtomiContent({
  getAttachedContent,
  isDraft,
  openProgressReportDialog,
  subjectColor,
}: {
  getAttachedContent: (id: string) => ?ClassTaskContent | ?TaskContent,
  isDraft: boolean,
  openProgressReportDialog: (OpenProgressReportDialogParams) => mixed,
  subjectColor: string,
}) {
  return function ({ id }: { id: string }) {
    const content = getAttachedContent(id);
    if (!content) return null;

    const { name, type, duration, tier, moduleId } = content;

    let progress;
    let isCompleted = false;
    if (content.viewer === 'teacher') {
      progress = isDraft ? null : (
        <PostProgress
          progressCount={content.countInProgressStudents}
          color={subjectColor}
          studentsCount={content.countAssignedStudents}
          onClick={() => {
            openProgressReportDialog({
              post: { id, type, name },
              moduleId,
            });
          }}
          progress={null}
        />
      );
    } else {
      isCompleted = Boolean(content.progress);
      progress = (
        <PostProgress
          color={subjectColor}
          progress={content.progress}
          withStrengthIndicator={type === postTypes.challenge}
        />
      );
    }

    return (
      <AttachmentList.Attachment isFullWidth isTruncated={false} testHook="post-attachment">
        <PostSummary
          name={name}
          meta={<PostDuration duration={duration} type={type} challengeTier={tier} />}
          icon={
            <PostIcon
              postType={type}
              challengeTier={tier}
              isCompleted={isCompleted}
              color={isDraft ? 'colorIcon' : subjectColor}
            />
          }
          isCompleted={isCompleted}
          wrapper={<Flex grow={1} as={UnstyledLink} testHook="post-attachment-link" to={content.url} isExternal />}
          progress={progress}
        />
      </AttachmentList.Attachment>
    );
  };
}

export function renderEditableAtomiContent({
  getAttachedContent,
  subjectColor,
}: {
  getAttachedContent: (id: string) => ?AttachedContent,
  subjectColor: string,
}) {
  return function ({ id, onRemove }: { id: string, onRemove: () => mixed }) {
    const content = getAttachedContent(id);
    if (!content) return null;

    const { name, type, duration, url, tier } = content;

    return (
      <AttachmentList.Attachment
        isFullWidth
        removeAssistiveText="Remove Atomi content"
        onRemove={onRemove}
        testHook="post-attachment"
      >
        <PostSummary
          name={name}
          meta={<PostDuration duration={duration} type={type} challengeTier={tier} />}
          icon={<PostIcon postType={type} challengeTier={tier} color={subjectColor} />}
          wrapper={<Flex grow={1} as={UnstyledLink} href={url} isExternal />}
        />
      </AttachmentList.Attachment>
    );
  };
}
