// @flow
import _ from 'lodash';

// Format entities for use with the Select component
export default function formatForSelect<Entity: Object>(
  entities: Array<Entity>,
  otherProps?: Array<$Keys<Entity>>
): Array<*> {
  return entities.map((entity) => {
    const item = {
      label: entity.name,
      value: entity.id,
    };

    if (otherProps) {
      Object.assign(item, _.pick(entity, otherProps));
    }

    return item;
  });
}
