// @flow
import { Badge, Box } from '@getatomi/neon';

import DaysFromNow from 'src/components/DaysFromNow/DaysFromNow';

export type TrialExpiryBadgeProps = {|
  textAlign?: 'center',
  trialEndDate: string,
|};

export default function TrialExpiryBadge(props: TrialExpiryBadgeProps) {
  const { trialEndDate, textAlign } = props;

  return (
    <Box textAlign={textAlign} testHook="hide-in-percy">
      <Badge color="colorTextInfo">
        Your trial expires <DaysFromNow date={trialEndDate} />
      </Badge>
    </Box>
  );
}
