// @flow
import _ from 'lodash';

import type { ClassTask } from '../useGetClassTasks';

type Props = {
  onCopyLinkSelect: (id: string) => mixed,
  onDeleteTaskSelect: (task: ClassTask) => mixed,
  onDuplicateTaskSelect: (task: ClassTask) => mixed,
  onEditTaskSelect: (task: ClassTask) => mixed,
  onProgressSelect: (task: ClassTask) => mixed,
  onTaskSelect: (id: string) => mixed,
  tasks: $ReadOnlyArray<ClassTask>,
};

export type TaskActions = $ReadOnlyArray<{|
  key: string,
  label: string,
  onSelect: () => mixed,
|}>;

export default function getTaskActionsById(props: Props) {
  const {
    tasks,
    onProgressSelect,
    onEditTaskSelect,
    onCopyLinkSelect,
    onDuplicateTaskSelect,
    onDeleteTaskSelect,
    onTaskSelect,
  } = props;

  return (taskId: string): TaskActions => {
    const task = _.find(tasks, { id: taskId });

    if (!task) {
      return [];
    }

    const isDraft = task.status === 'draft';
    const isPublished = task.status === 'published';
    const taskOrDraftLabel = isDraft ? 'draft' : 'task';

    return [
      {
        key: 'view',
        label: `View ${taskOrDraftLabel}`,
        onSelect: () => onTaskSelect(taskId),
      },
      isPublished
        ? {
            key: 'view-progress',
            label: 'View task progress',
            onSelect: () => onProgressSelect(task),
          }
        : null,
      { key: 'edit', label: `Edit ${taskOrDraftLabel}`, onSelect: () => onEditTaskSelect(task) },
      { key: 'duplicate', label: `Duplicate ${taskOrDraftLabel}`, onSelect: () => onDuplicateTaskSelect(task) },
      !isDraft ? { key: 'copy-link', label: 'Copy link', onSelect: () => onCopyLinkSelect(taskId) } : null,
      { key: 'delete', label: `Delete ${taskOrDraftLabel}`, onSelect: () => onDeleteTaskSelect(task) },
    ].filter(Boolean);
  };
}
