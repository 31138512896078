// @flow
import { connect } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Container, Heading, PhoneField, Stack, Text, TextField } from '@getatomi/neon';
import { useEffect } from 'react';
import { type ContextRouter, type Location, withRouter } from 'react-router';
import Helmet from 'react-helmet';

import { getRegionCountryISO, isRegionsLoaded } from 'src/reducers/regions';
import { isRegistering as isRegisteringSelector, type RegistrationState } from 'src/reducers/registration';
import { loadRegion } from 'src/actions/regions';
import { preferredCountries } from 'src/constants/regions';
import { updateRegistrationDetails } from 'src/actions/registration';
import ComboboxSchool from 'src/components/ComboboxSchool/ComboboxSchool';
import Button from 'src/components/Button/Button';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import connectData from 'src/decorators/connectData';
import NonAuthenticatedLayout from 'src/layouts/NonAuthenticatedLayout/NonAuthenticatedLayout';
import getPositionsByRegion from 'src/utils/getPositionsByRegion';
import scrollToActiveElement from 'src/utils/scrollToActiveElement';
import useIsClientLoaded, { clientRequiredAttribute, loadingMessage } from 'src/hooks/useIsClientLoaded';
import useTeacherRegistrationFormStep2 from 'src/hooks/forms/useTeacherRegistrationFormStep2';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import type { BoundAction, Dispatch, GetState, ReduxState, RegistrationRegionCode } from 'src/types';

import MarketingPanel from '../MarketingPanel/MarketingPanel';

type Params = {
  region: RegistrationRegionCode,
};

type Props = {
  params: Params,
};

type InjectedProps = Props & {
  countryCode: string,
  isRegistering: boolean,
  registrationDetails: RegistrationState,
  router: ContextRouter,
  updateRegistrationDetailsAction: BoundAction<typeof updateRegistrationDetails>,
};

export const fetchData = (getState: GetState, dispatch: Dispatch, location: Location, params: Params) => {
  const state = getState();

  if (!isRegionsLoaded(state)) {
    return dispatch(loadRegion({ region: params.region }));
  }
};

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
  countryCode: getRegionCountryISO(state, ownProps.params.region),
  registrationDetails: state.registration,
  isRegistering: isRegisteringSelector(state),
});

function RegistrationTeacherStep2(props: InjectedProps) {
  const {
    countryCode,
    isRegistering,
    params: { region },
    registrationDetails,
    router,
    updateRegistrationDetailsAction,
  } = props;
  const isClientLoaded = useIsClientLoaded();

  const { control, fields, form, serverError, tracking } = useTeacherRegistrationFormStep2({
    defaultValues: {
      schoolId: registrationDetails.schoolId,
      position: registrationDetails.position,
      accountName: registrationDetails.accountName,
      // When phoneNumber validation library loads it will trigger a change and populate attributes
      // below. See useTeacherRegistrationFormStep2 validation for more info
      phoneNumber: {
        isValid: null,
        number: '',
        value: registrationDetails.phoneNumber ?? '',
      },
    },
    onSubmitSuccess: (data) => {
      updateRegistrationDetailsAction(data);
      router.push(`/register/teacher/step-3/${region}`);
    },
    onSubmitFail: () => {
      scrollToActiveElement();
    },
  });

  useEffect(() => {
    if (!registrationDetails?.firstName) {
      router.push(`/register/teacher/${region}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NonAuthenticatedLayout sidePanel={<MarketingPanel />}>
      <Helmet>
        <title>Teacher registration</title>
      </Helmet>
      <Stack spacing="spacingLarge1X">
        <Container textAlign="center" maxWidth="sizeContainerSmall">
          <form {...form} {...clientRequiredAttribute}>
            <Stack spacing="spacingLarge5X">
              <div>
                <Heading as="h1">Let’s set up your account</Heading>
                <Text as="p" color="colorTextSubtler" fontSize="fontSizeLarge1X" fontWeight="fontWeightLight">
                  You’ll be the only one on your account for now, but you’ll be able to invite students and colleagues
                  later.
                </Text>
              </div>
              <Stack spacing="spacingLarge1X">
                {serverError && <ValidationErrors errors={serverError.message} />}
                <div data-test="hide-in-percy">
                  <Controller
                    control={control}
                    name="schoolId"
                    render={({ field }) => (
                      <ComboboxSchool
                        {...field}
                        {...fields.schoolId}
                        region={region}
                        schoolId={field.value}
                        onSelectionChange={(option) => {
                          field.onChange(option.value ? parseInt(option.value, 10) : option.label);
                          tracking.trackSchoolId();
                        }}
                      />
                    )}
                  />
                </div>
                <Controller
                  control={control}
                  name="position"
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      {...fields.position}
                      autoComplete="organization-title"
                      items={getPositionsByRegion(region)}
                      selectedKey={field.value}
                      onSelectionChange={(event) => {
                        field.onChange(event);
                        tracking.trackPosition();
                      }}
                    >
                      {(item) => <Item>{item.label}</Item>}
                    </Dropdown>
                  )}
                />
                <Controller
                  control={control}
                  name="accountName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      {...fields.accountName}
                      onBlur={(e) => {
                        field.onBlur(e);
                        tracking.trackAccountName();
                      }}
                      autoCorrect="off"
                      helpText="You can always change this later!"
                      placeholder="e.g. Atomi College Maths Department"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <PhoneField
                      {...field}
                      {...fields.phoneNumber}
                      onBlur={(e) => {
                        field.onBlur(e);
                        tracking.trackPhoneNumber();
                      }}
                      autoComplete="tel"
                      helpText="We’ll only use your phone number for setup assistance and support."
                      initialCountry={countryCode}
                      preferredCountries={preferredCountries}
                      value={field.value.value}
                    />
                  )}
                />
              </Stack>
              <Button
                variant="legacy"
                type="submit"
                isLoading={isRegistering}
                isDisabled={!isClientLoaded}
                title={isClientLoaded ? undefined : loadingMessage}
              >
                Continue
              </Button>
            </Stack>
          </form>
        </Container>
      </Stack>
    </NonAuthenticatedLayout>
  );
}

export default connectData(fetchData)(
  withRouter(
    connect(mapStateToProps, {
      updateRegistrationDetailsAction: updateRegistrationDetails,
    })(RegistrationTeacherStep2)
  )
);
