// @flow
import { useState } from 'react';
import { Markdown, Modal } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import { trackingCtas } from 'src/constants/tracking';

export function CurriculumDescriptorDialog(props: {| description: string, isOpen: boolean, onClose: () => void |}) {
  const { description, isOpen, onClose } = props;

  return (
    <Modal
      actions={
        <Button onClick={onClose} variant="text">
          Close
        </Button>
      }
      heading="Curriculum descriptor"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Markdown>{description}</Markdown>
    </Modal>
  );
}

export default function CurriculumDescriptorTrigger(props: {|
  description: ?string,
  isInteractiveLesson: boolean,
  isLoggedInAsStudent: boolean,
|}) {
  const { description, isInteractiveLesson, isLoggedInAsStudent } = props;

  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);

  if (!description || !isInteractiveLesson || isLoggedInAsStudent) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => setShouldShowModal(true)}
        trackingData={{
          cta: trackingCtas.curriculumDescriptor,
        }}
        variant="text"
      >
        Curriculum descriptor
      </Button>

      <CurriculumDescriptorDialog
        description={description}
        isOpen={shouldShowModal}
        onClose={() => setShouldShowModal(false)}
      />
    </>
  );
}
