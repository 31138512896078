// @flow
import isoCodes from './isoCodes';

const regions = Object.freeze({
  nsw: { code: 'nsw', countryCode: isoCodes.au, name: 'New South Wales' },
  au_qld: { code: 'au_qld', countryCode: isoCodes.au, name: 'Queensland' },
  au_sa: { code: 'au_sa', countryCode: isoCodes.au, name: 'South Australia' },
  au_vic: { code: 'au_vic', countryCode: isoCodes.au, name: 'Victoria' },
  au_tas: { code: 'au_tas', countryCode: isoCodes.au, name: 'Tasmania' },
  au_wa: { code: 'au_wa', countryCode: isoCodes.au, name: 'Western Australia' },
  sg: { code: 'sg', countryCode: isoCodes.sg, name: 'Singapore' },
  uk: { code: 'uk', countryCode: isoCodes.gb, name: 'UK' },
  ca_on: { code: 'ca_on', countryCode: isoCodes.ca, name: 'Ontario' },
});

export default regions;

// Used to short list dropdown fields such as with the phone input
export const preferredCountries = [isoCodes.au, isoCodes.ca];

export const registrationRegions = Object.freeze({
  nsw: regions.nsw,
  au_qld: regions.au_qld,
  au_sa: regions.au_sa,
  au_vic: regions.au_vic,
  au_tas: regions.au_tas,
  au_wa: regions.au_wa,
  ca_on: regions.ca_on,
});
