// @flow
import PostBackLink from 'src/components/PostBackLink/PostBackLink';

import MobileBackLinkLayout from '../MobileBackLinkLayout/MobileBackLinkLayout';

export default function ContextualMobilePostBackLink() {
  return (
    <MobileBackLinkLayout>
      {/* $FlowIgnore https://stackoverflow.com/questions/50219814/flow-error-props-is-incompatible-with-empty-when-instantiating-a-component-wit */}
      <PostBackLink renderText={(text) => text} />
    </MobileBackLinkLayout>
  );
}
