// @flow
import invariant from 'invariant';

import challengeTiers from 'src/constants/challengeTiers';
import type { ChallengeTier } from 'src/types';
import type {
  GetClassMarks_me_account as QueryAccountData,
  GetClassMarks_me_account_class as QueryClassData,
  GetClassMarks_me_account_class_modules as MarksModules,
} from 'src/graphql/types/generated/GetClassMarks';
import type { GetClassTopicsMarks_me_account_class_topics as MarksTopics } from 'src/graphql/types/generated/GetClassTopicsMarks';
import type { GetClassAssessmentMarks_me_account_class_assessments as MarksAssessments } from 'src/graphql/types/generated/GetClassAssessmentMarks';
import createCellValue, { type CellValue } from 'src/utils/createCellValue';
import { getClassModuleUrl, getClassTopicInsightsUrl, getPostUrl, getChallengeMarksUrl } from 'src/utils/routes';

import getPercentageValue from '../utils/getPercentageValue';
import prepareStudentData, { type Student } from '../utils/prepareStudentData';

export type MarkCellValue = CellValue<number, number>;

export type ClassAverage = {
  type: 'ClassAverage',
};

export type Assessment = {
  id: string,
  lessonUrl: string,
  name: string,
  progressReportUrl: string,
  tier: ChallengeTier,
};

export type Topic = {
  assessments?: $ReadOnlyArray<Assessment>,
  id: string,
  insightsUrl: string,
  lessonCount: number,
  name: string,
};

export type Module = {
  categoryCount: number,
  id: string,
  lessonCount: number,
  moduleUrl?: string, // not required for overall modules
  name: string,
  topics?: $ReadOnlyArray<Topic>,
};

export type MarksData = {
  'column-overall': ?MarkCellValue,
  // `column-${category.id}`
  [CategoryKey: string]: MarkCellValue,
  isInactive: boolean,
  rowMeta: Student,
};

export type ClassAverageRow = {
  'column-overall': ?MarkCellValue,
  isInactive: boolean,
  rowMeta: ClassAverage,
  [CategoryKey: string]: MarkCellValue,
};

export type TransformedMarksData = {
  classAverageRow: ClassAverageRow,
  className: string,
  columnList: $ReadOnlyArray<Module>,
  isClassEmpty: boolean,
  isDataExportEnabled: boolean,
  isFreePlan: boolean,
  lessonCount: number,
  marksData: $ReadOnlyArray<MarksData>,
  region: string,
};

type LoadingTopic = {
  id: string,
  insightsUrl: string,
  metrics: {
    assessmentCount: number,
  },
  name: string,
};

type LoadingAssessment = {
  id: string,
  lessonUrl: string,
  name: string,
  progressReportUrl: string,
  tier: ChallengeTier,
};

function getLoadingTopics(count: number): $ReadOnlyArray<LoadingTopic> {
  return Array.from({ length: count }).map((v, i) => ({
    id: `loading-topic-${i}`,
    insightsUrl: '',
    name: '',
    metrics: {
      assessmentCount: 0,
    },
  }));
}

function getLoadingAssessments(count: number): $ReadOnlyArray<LoadingAssessment> {
  return Array.from({ length: count }).map((v, i) => ({
    id: `loading-assessment-${i}`,
    lessonUrl: '',
    progressReportUrl: '',
    name: '',
    tier: challengeTiers.TIER_1_QUIZ,
  }));
}

function processAssessments(
  assessments: $ReadOnlyArray<MarksAssessments>,
  accountId: string,
  classId: string,
  moduleId: string
): $ReadOnlyArray<Assessment> {
  return assessments.map((assessment) => {
    invariant(
      assessment.__typename === 'ClassChallengeLesson',
      'Assessment should have a typename of ClassChallengeLesson'
    );

    const { id, name, tier } = assessment;
    return {
      id,
      name,
      tier,
      lessonUrl: getPostUrl(accountId, classId, moduleId, id),
      progressReportUrl: getChallengeMarksUrl(accountId, classId, moduleId, id),
    };
  });
}

function processTopics(
  topics: $ReadOnlyArray<MarksTopics | LoadingTopic>,
  assessments: ?$ReadOnlyArray<MarksAssessments>,
  expandedTopicId: ?string,
  accountId: string,
  classId: string,
  moduleId: string
): $ReadOnlyArray<Topic> {
  return topics.map((topic) => {
    const isExpanded = expandedTopicId && expandedTopicId === topic.id;
    let assessmentColumns;
    if (isExpanded) {
      if (assessments) {
        assessmentColumns = processAssessments(assessments, accountId, classId, moduleId);
      } else {
        invariant(topic.__typename === 'ClassCategory', 'Topic with metrics should have a typename of ClassCategory');
        assessmentColumns = getLoadingAssessments(topic.metrics.assessmentCount);
      }
    }

    return {
      id: topic.id,
      lessonCount: topic.__typename === 'ClassCategory' ? topic.metrics.assessmentCount : 0,
      name: topic.name,
      assessments: assessmentColumns,
      insightsUrl: getClassTopicInsightsUrl(accountId, classId, topic.id),
    };
  });
}

function prepareColumnList(
  assessments: ?$ReadOnlyArray<MarksAssessments>,
  modules: $ReadOnlyArray<MarksModules>,
  topics: ?$ReadOnlyArray<MarksTopics>,
  expandedModuleId: ?string,
  expandedTopicId: ?string,
  accountId: string,
  classId: string
): $ReadOnlyArray<Module> {
  const columns = [];

  for (const module of modules) {
    if (module.__typename === 'ClassCategory') {
      const { childCategoryCount: categoryCount } = module.metrics;
      const lessonCount = module.metrics.assessmentCount;
      columns.push({
        id: module.id,
        categoryCount,
        lessonCount,
        moduleUrl: getClassModuleUrl(accountId, classId, module.id),
        name: module.name,
        topics:
          module.id === expandedModuleId
            ? processTopics(
                topics ?? getLoadingTopics(categoryCount),
                assessments,
                expandedTopicId,
                accountId,
                classId,
                module.id
              )
            : undefined,
      });
    }
  }

  return columns;
}

function getMarkPercentage(mark: ?number): ?MarkCellValue {
  if (mark == null) {
    return null;
  }

  return createCellValue(getPercentageValue(mark, 1));
}

function transformCategories(
  categories: $ReadOnlyArray<MarksModules | MarksTopics>,
  studentId?: string
): ?{ [CategoryKey: string]: MarkCellValue } {
  const transformedCategories = {};

  for (const category of categories) {
    if (category.__typename === 'ClassCategory') {
      const categoryMetrics = category.metrics.assessment;
      if (studentId) {
        const { students } = categoryMetrics;
        const studentMetrics = students.find((s) => s.studentId === studentId);
        if (studentMetrics) {
          transformedCategories[`column-${category.id}`] = getMarkPercentage(studentMetrics.averageMark);
        }
      } else {
        transformedCategories[`column-${category.id}`] = getMarkPercentage(categoryMetrics.averageMark);
      }
    }
  }

  return transformedCategories;
}

function transformAssessments(
  assessments: ?$ReadOnlyArray<MarksAssessments>,
  studentId?: string
): ?{ [AssessmentKey: string]: ?MarkCellValue } {
  if (!assessments) {
    return {};
  }

  const transformedAssessments = {};

  for (const assessment of assessments) {
    invariant(assessment.__typename === 'ClassChallengeLesson', 'Assessments must have a ClassChallengeLesson type');

    const assessmentMetrics = assessment.metrics.assessment;
    const assessmentColumnKey = `column-assessment-${assessment.id}`;

    if (studentId) {
      const studentMetrics = assessmentMetrics.students.find((s) => s.studentId === studentId);
      if (studentMetrics) {
        transformedAssessments[assessmentColumnKey] = getMarkPercentage(studentMetrics.mark);
      }
    } else {
      transformedAssessments[assessmentColumnKey] = getMarkPercentage(assessmentMetrics.classAverageMark);
    }
  }

  return transformedAssessments;
}

export function transformMarksData({
  accountData,
  assessments,
  classData,
  expandedModuleId,
  expandedTopicId,
  topics,
}: {
  accountData: QueryAccountData,
  assessments: ?$ReadOnlyArray<MarksAssessments>,
  classData: QueryClassData,
  expandedModuleId: ?string,
  expandedTopicId: ?string,
  topics: ?$ReadOnlyArray<MarksTopics>,
}): TransformedMarksData {
  const { id: accountId, enabledFeatures, plan } = accountData;
  const { id: classId, metrics, modules, name: className, students } = classData;

  invariant(metrics.__typename === 'ClassMetrics', 'Marks class metrics type should be ClassMetrics');
  const { assessment, assessmentCount, studentCount } = metrics;
  const categories = [...modules];
  if (topics) {
    categories.push(...topics);
  }

  const classAverageRow = {
    rowMeta: {
      type: 'ClassAverage',
    },
    isInactive: assessment.completedCount === 0,
    'column-overall': getMarkPercentage(assessment.averageMark),
    ...transformCategories(categories),
    ...transformAssessments(assessments),
  };

  const isDataExportEnabled = !enabledFeatures.includes('DENY_STUDENT_DATA_EXPORT');
  const isFreePlan = plan.isFree;
  const isClassEmpty = studentCount === 0;

  const marksData = assessment.students
    .map((student) => {
      const isInactive = student.completedCount === 0;

      const rowMeta = prepareStudentData(student.studentId, students, isInactive);
      if (!rowMeta) return null;

      return {
        rowMeta,
        isInactive,
        'column-overall': getMarkPercentage(student.averageMark),
        ...transformCategories(categories, student.studentId),
        ...transformAssessments(assessments, student.studentId),
      };
    })
    .filter(Boolean);

  const columnList = prepareColumnList(
    assessments,
    modules,
    topics,
    expandedModuleId,
    expandedTopicId,
    accountId,
    classId
  );
  return {
    marksData,
    classAverageRow,
    className,
    columnList,
    isClassEmpty,
    isDataExportEnabled,
    isFreePlan,
    lessonCount: assessmentCount,
    region: accountData.region.code,
  };
}
