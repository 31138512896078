// @flow
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { type ContextRouter, withRouter } from 'react-router';
import { useToast } from '@getatomi/neon';

import type { ReduxState, RouteError } from 'src/types';
import { getInviteError } from 'src/reducers/auth';
import Auth from 'src/utils/Auth';
import errorTypes from 'src/constants/errorTypes';

const mapStateToProps = (state: ReduxState) => ({
  inviteError: getInviteError(state),
});

type RedirectInvalidInviteProps = {
  children: React.Node,
  inviteError: ?RouteError,
  router: ContextRouter,
};

function RedirectInvalidInvite(props: RedirectInvalidInviteProps) {
  const { children, inviteError, router } = props;
  const toast = useToast();

  useEffect(() => {
    if (inviteError) {
      const auth = new Auth();
      const isLoggedIn = !!auth.getAccessToken();
      let errorMessage = 'This invite code is invalid.';

      if (inviteError.exception.output.errorType === errorTypes.AcceptedInviteException) {
        errorMessage = `You’ve already accepted your account invitation.${isLoggedIn ? '' : ' Please log in instead.'}`;
      }

      // Trigger the toast at the end of the event loop so that it is properly handled by the
      // toaster. Without this, the toast would not be displayed when called immediately on mount.
      setTimeout(() => {
        toast.error(errorMessage);
        router.replace('/login');
      }, 0);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return children;
}

export default withRouter(connect(mapStateToProps, {})(RedirectInvalidInvite));
