// @flow
import { useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router';
import { useQuery } from '@apollo/client';
import { Container, EmptyState, Flex, Heading, Illustration } from '@getatomi/neon';
import invariant from 'invariant';

import Button from 'src/components/Button/Button';
import ButtonAdd from 'src/components/ButtonAdd/ButtonAdd';
import type { GetUserClasses, GetUserClassesVariables } from 'src/graphql/types/generated/GetUserClasses';
import UpgradeAccountPanel from 'src/domains/Class/UpgradeAccountPanel/UpgradeAccountPanel';
import Link from 'src/components/Link/Link';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import {
  getActiveSubscriptionId,
  hasClasses as hasClassesSelector,
  isFreePlan as isFreePlanSelector,
} from 'src/reducers/subscriptions';
import links from 'src/constants/links';
import { getRevisionUrl } from 'src/utils/routes';
import { useNavigationContext } from 'src/hooks/useNavigationContext';

import GET_USER_CLASSES from './GetUserClasses.graphql';
import transformUserClasses from './transformUserClasses';
import StudentRevisionDialog from './RevisionDialog/StudentRevisionDialog';

type InjectedProps = {
  hasClasses: boolean,
  isFreePlan: boolean,
  router: ContextRouter,
  subscriptionId: string,
};

export function StudentRevisionPageQuery(props: $Diff<InjectedProps, { isFreePlan: boolean }>) {
  const { hasClasses, router, subscriptionId } = props;
  const [isStarted, setIsStarted] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // load the user classes here so that they are preloaded when the user opens the dialog
  const { data, loading, error } = useQuery<GetUserClasses, GetUserClassesVariables>(GET_USER_CLASSES, {
    variables: {
      accountId: subscriptionId,
      filters: {
        types: ['MODULE'],
      },
    },
    onCompleted: () => {
      if (isStarted) {
        setIsDialogOpen(true);
        setIsStarted(false);
      }
    },
    skip: !hasClasses,
  });

  const onCreateSessionClick = useCallback(() => {
    // The subjects data is still loading: set the button loading state. The dialog will be opened
    // by the useQuery onCompleted callback.
    if (loading) {
      setIsStarted(true);
    } else {
      setIsDialogOpen(true);
    }
  }, [loading]);
  const redirectToNewRevision = ({ classId, revisionId }) => {
    const newRevisionsUrl = getRevisionUrl(subscriptionId, classId, revisionId);
    router.push(newRevisionsUrl);
  };

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load your revisions." />;
  }

  const heading = (
    <Heading as="h1" marginBottom="spacingLarge6X">
      Revision
    </Heading>
  );
  const helpInfo = (
    <>
      Learn more about{' '}
      <Link href={links.support.creatingRevision} isExternal variant="monochrome">
        creating revision sessions
      </Link>{' '}
      in the Help Centre.
    </>
  );

  if (!hasClasses) {
    return (
      <>
        {heading}
        <Container maxWidth="sizeContainerRoot">
          <EmptyState
            media={<Illustration name="emptystate-revisions" />}
            heading="You’ll need to be added to classes first to revise content."
            description="Once your school has added you to your classes, you can revise content you’ve covered or select from particular topics to practice. You can even get exam-ready by doing a range of question types on all the topics for a subject."
            helpInfo={helpInfo}
          />
        </Container>
      </>
    );
  }

  let classes = [];
  if (!loading) {
    const classesData = data?.me?.account?.classes.edges;
    invariant(classesData, 'Classes data should be defined');
    classes = transformUserClasses(classesData);
  }

  return (
    <>
      <Flex alignItems="baseline" justifyContent="space-between">
        {heading}
        <ButtonAdd
          onClick={onCreateSessionClick}
          assistiveText="Create revision"
          testHook="revision-create-button"
          isLoading={isStarted}
        >
          Create revision
        </ButtonAdd>
      </Flex>
      <Container maxWidth="sizeContainerRoot">
        <EmptyState
          media={<Illustration name="emptystate-revisions" />}
          description="Create a session to revise content you’ve covered or select from individual topics to practice. You can even get exam-ready by doing a range of question types on all the topics for a subject."
          helpInfo={helpInfo}
          primaryAction={
            <Button onClick={onCreateSessionClick} isLoading={isStarted}>
              Create a revision session
            </Button>
          }
        />
      </Container>

      <StudentRevisionDialog
        subscriptionId={subscriptionId}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        redirectToNewRevision={redirectToNewRevision}
        // at this point we should have data since the dialog doesn't open if the data is still
        // loading or if the query errored
        classes={classes}
      />
    </>
  );
}

function StudentRevisionPage(props: InjectedProps) {
  const { hasClasses, isFreePlan, router, subscriptionId } = props;
  const [, setNavigationContext] = useNavigationContext();

  useEffect(() => {
    setNavigationContext({
      location: 'studentRevisionPage',
      metadata: {
        accountId: subscriptionId,
        title: `Revision`,
      },
    });
  }, [subscriptionId, setNavigationContext]);

  return (
    <>
      <Helmet title="Revision" />
      {isFreePlan ? (
        <UpgradeAccountPanel
          videoId="6uc9jgedx0"
          heading="Want to create a revision session?"
          description="When you upgrade to unlimited access you can create sessions to revise content or get exam-ready by doing practice questions."
        />
      ) : (
        <Container>
          <StudentRevisionPageQuery hasClasses={hasClasses} router={router} subscriptionId={subscriptionId} />
        </Container>
      )}
    </>
  );
}

export default (connect((state) => ({
  isFreePlan: isFreePlanSelector(state),
  hasClasses: hasClassesSelector(state),
  subscriptionId: getActiveSubscriptionId(state).toString(),
}))(StudentRevisionPage): React.AbstractComponent<{}>);
