// @flow
import { useForm } from 'react-hook-form';
import { useCallback, useState, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@getatomi/neon';

import { parseValidationErrors } from 'src/api/validation';
import useAsyncValidation, { type AsyncValidation } from 'src/hooks/useAsyncValidation';

import emailValidator from './validators/emailValidator';

export type Fields = {
  email?: string,
  firstName?: string,
  lastName?: string,
  levelId?: number | string,
};

const labels = {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  levelId: 'Year level',
};

const createResolver = (emailAsyncValidation: AsyncValidation) =>
  yupResolver(
    yup.object().shape({
      firstName: yup
        .string()
        .trim()
        .label(labels.firstName)
        .required()
        .max(process.env.VALIDATION_MAX_LENGTH_FIRSTNAME),
      lastName: yup.string().trim().label(labels.lastName).required().max(process.env.VALIDATION_MAX_LENGTH_LASTNAME),
      email: yup
        .string()
        .trim()
        .label(labels.email)
        .test('email', '', function test() {
          return emailAsyncValidation.isValid.current === null
            ? true
            : this.createError({
                message: emailAsyncValidation.isValid.current,
              });
        })
        .required(),
      levelId: yup.mixed().when('$isLevelIdFieldPresent', {
        is: true,
        then: yup.number().nullable().required().label(labels.levelId),
      }),
    })
  );

export default function useEditUserDialogForm({
  context,
  defaultValues,
  onSubmitSuccess,
}: {
  context: {
    isLevelIdFieldPresent?: boolean,
  },
  defaultValues: Fields,
  onSubmitSuccess: (data: Fields) => void | Promise<void>,
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailWarning, setEmailWarning] = useState<?string>(null);
  const toast = useToast();

  const emailAsyncValidation = useAsyncValidation(async (email) => {
    return emailValidator({
      email,
      setEmailWarning,
    });
  });

  const resolver = useMemo(() => createResolver(emailAsyncValidation), [emailAsyncValidation]);
  const { clearErrors, formState, control, handleSubmit, getValues, trigger, setError, setValue } = useForm({
    resolver,
    defaultValues,
    context,
  });

  const onSubmit = useCallback(
    async (e: Event) => {
      e.preventDefault();
      clearErrors('serverError');

      setIsLoading(true);

      const email = getValues('email');
      if (email !== '') {
        await emailAsyncValidation.validate(email);
      }

      handleSubmit(async (data) => {
        try {
          await onSubmitSuccess(data);
        } catch (error) {
          const validationErrors = parseValidationErrors(error);
          setError('serverError', { type: 'manual', message: validationErrors });
          if (validationErrors.length === 0) {
            toast.error('There was an error updating the user. Please try again.');
          }
        }
      })(e);
      setIsLoading(false);
    },
    [clearErrors, handleSubmit, onSubmitSuccess, setError, getValues, emailAsyncValidation, toast]
  );

  const firstNameMessage = formState.errors.firstName?.message ?? null;
  const lastNameMessage = formState.errors.lastName?.message ?? null;
  const emailMessage = formState.errors.email?.message ?? null;
  const levelIdMessage = formState.errors.levelId?.message ?? null;

  const { serverError } = formState.errors;

  return {
    control,
    isLoading,
    serverError,
    setValue,
    form: {
      onSubmit,
    },
    fields: {
      firstName: {
        label: labels.firstName,
        validationText: firstNameMessage,
        errorVariant: firstNameMessage && 'error',
        isRequired: true,
      },
      lastName: {
        label: labels.lastName,
        validationText: lastNameMessage,
        errorVariant: lastNameMessage && 'error',
        isRequired: true,
      },
      email: {
        validationText: emailMessage || emailWarning,
        errorVariant: (emailMessage && 'error') || (emailWarning && 'warning'),
        label: labels.email,
        onChange: () => {
          if (formState.errors.email) {
            emailAsyncValidation.clear();
            trigger('email');
          }
        },
        onBlur: async (event: Object) => {
          const { value } = event.target;
          // do not validate the email is an empty string, e.g. if the user only tabbed through the
          // field and didn't fill anything
          if (value !== '') {
            await emailAsyncValidation.validate(value);
            trigger('email');
          }
        },
        isRequired: true,
      },
      levelId: {
        label: labels.levelId,
        validationText: levelIdMessage,
        errorVariant: levelIdMessage && 'error',
      },
    },
  };
}
