// @flow
import { useState } from 'react';

import type { NewAttachedContent } from 'src/domains/Tasks/types';
import { trackingCtas, trackingCtaTypes, trackingEvents } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';

import type { EditableTask } from './TaskDialog';
import TaskDialogContainer from './TaskDialogContainer';

type Props = {|
  classId: string,
  onSubmit?: () => mixed,
  subscriptionId: string,
|};

export type OpenTaskDialogParams = {|
  classId?: string,
  contents?: Array<NewAttachedContent>,
  students?: Array<string>,
  task?: EditableTask,
|};

export default function useTaskDialog(props: Props) {
  const { subscriptionId, classId: defaultClassId, onSubmit } = props;
  const [contents, setContents] = useState<Array<NewAttachedContent>>([]);
  const [task, setTask] = useState<?EditableTask>(null);
  const [classId, setClassId] = useState<string>(defaultClassId);
  const [students, setStudents] = useState<?Array<string>>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const open = ({
    classId: newClassId = defaultClassId,
    contents: newContents = [],
    students: newStudents,
    task: newTask,
  }: OpenTaskDialogParams = {}) => {
    setContents(newContents);
    setTask(newTask);
    setStudents(newStudents);
    setClassId(newClassId);
    trackEvent(trackingEvents.ctaClicked, {
      cta: newTask ? trackingCtas.classTaskEditDialog : trackingCtas.classTaskAddDialog,
      type: trackingCtaTypes.button,
      classId: newClassId,
      postCount: newContents.length,
    });
    setIsDialogOpen(true);
  };

  const close = () => {
    setIsDialogOpen(false);
  };

  const dialog = isDialogOpen ? (
    <TaskDialogContainer
      isOpen
      classId={classId}
      initialContents={contents}
      initialStudents={students ?? undefined}
      subscriptionId={subscriptionId}
      task={task}
      onClose={close}
      onSubmit={onSubmit}
    />
  ) : null;

  return [dialog, { openTaskDialog: open, close }];
}
