// @flow
import { useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Container, useToast, Heading } from '@getatomi/neon';
import _ from 'lodash';

import Button from 'src/components/Button/Button';
import type { BoundAction, ReduxState } from 'src/types';
import Input from 'src/components/Input/Input';
import PasswordStrength from 'src/components/PasswordStrength/PasswordStrength';
import Form from 'src/components/Form/Form';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout/LogoOnlyLayout';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import {
  getResetPasswordError,
  getResetPasswordValidationErrors,
  isResetingPassword as isResetingPasswordSelector,
} from 'src/reducers/auth';
import { redirectToDashboard, resetPassword } from 'src/actions/auth';
import { escapeStringForRegex } from 'src/utils/string';
import scrollTo from 'src/utils/scrollTo';
import { parseValidationErrors } from 'src/api/validation';

import ForgotPasswordHelp from '../ForgotPasswordHelp/ForgotPasswordHelp';

const mapStateToProps = (state: ReduxState) => ({
  isResetingPassword: isResetingPasswordSelector(state),
  error: getResetPasswordError(state),
  serverErrors: getResetPasswordValidationErrors(state),
});

type Props = {
  location: {
    query: {
      user_id: number,
    },
  },
  params: {
    token: string,
  },
};

export type InjectedProps = Props & {
  isResetingPassword: boolean,
  redirectToDashboardAction: BoundAction<typeof redirectToDashboard>,
  resetPasswordAction: BoundAction<typeof resetPassword>,
};

function ResetPassword(props: InjectedProps) {
  const { isResetingPassword, location, params, redirectToDashboardAction, resetPasswordAction } = props;

  const inputs = useRef({});
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [serverErrors, setServerErrors] = useState<Array<string>>([]);
  const toast = useToast();

  const onSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const invalidInput = _.find(
      _.map(inputs.current, (value, key: string) => {
        return { key, isValid: value.validate() };
      }),
      { isValid: false }
    );

    if (invalidInput) {
      return setTimeout(() => inputs.current && scrollTo(inputs.current[invalidInput.key], -100), 0);
    }

    try {
      await resetPasswordAction(params.token, location.query.user_id, password, passwordConfirmation);
      redirectToDashboardAction();
      toast.success('Your password has been reset successfully.');
    } catch (err) {
      const errors = parseValidationErrors(err);
      setServerErrors(errors);

      if (errors.length === 0) {
        toast.error('There was an error resetting your password. Please try again.');
      }
    }
  };

  return (
    <LogoOnlyLayout>
      <Helmet>
        <title>Reset your password</title>
      </Helmet>
      <Container maxWidth="sizeContainerSmall1X">
        <Heading as="h1" variant="large" color="colorTextInverted" marginBottom="spacingLarge6X">
          Please enter your new password
        </Heading>
        {serverErrors && <ValidationErrors errors={serverErrors} />}
        <Form onSubmit={onSubmit}>
          <PasswordStrength
            ref={(el) => {
              if (inputs.current) inputs.current.password = el;
            }}
            placeholder="New password"
            ariaLabel="New password"
            inputInfo="Your password must contain at least 6 characters."
            autoComplete="new-password"
            isMandatory
            onChange={(name, value) => setPassword(value)}
            isInfoInverseColor
            testHook="password"
          />
          <Input
            ref={(el) => {
              if (inputs.current) inputs.current.passwordConfirmation = el;
            }}
            type="password"
            name="passwordConfirmation"
            placeholder="Confirm new password"
            ariaLabel="Confirm new password"
            autoComplete="new-password"
            isMandatory={password !== ''}
            pattern={escapeStringForRegex(password)}
            patternMismatchErrorMessage="Your passwords must match"
            withErrorIcon={false}
            onChange={(name, value) => setPasswordConfirmation(value)}
            testHook="password-confirmation"
          />
          <Button isFullWidth isLoading={isResetingPassword} variant="ghost" type="submit">
            Set new password
          </Button>
        </Form>
      </Container>
      <ForgotPasswordHelp />
    </LogoOnlyLayout>
  );
}

export default (connect(mapStateToProps, {
  redirectToDashboardAction: redirectToDashboard,
  resetPasswordAction: resetPassword,
})(ResetPassword): React.AbstractComponent<Props>);
