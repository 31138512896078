// @flow
import type { ContextSetter } from '@apollo/client/link/context';

import Auth from 'src/utils/Auth';

export const makeAuthLink =
  (auth: Auth): ContextSetter =>
  (_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${auth.getAccessToken()}`,
      },
    };
  };
