// @flow
import { forwardRef, useState } from 'react';
import { Box, tokens } from '@getatomi/neon';
import _ from 'lodash';

type Props = {|
  children: React.Node,
  containerProps?: any,
|};

// TODO (PROD-7333) - replace with Neon component
export const ScrollWithFade = forwardRef<Props, HTMLDivElement>(function ScrollWithFade(props, ref: any) {
  const { children, containerProps } = props;

  const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(false);

  const handleScroll = _.throttle((event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    setIsScrolledToBottom(Math.ceil(clientHeight) + Math.ceil(scrollTop) >= scrollHeight);
  }, 150);

  return (
    <Box
      position="relative"
      height="sizeFull"
      overflow="hidden"
      target={0}
      _after={{
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: 50,
        pointerEvents: 'none',
        backgroundImage: `linear-gradient(
              to bottom,
              transparent,
              ${tokens.colorBackground}
            )`,
        zIndex: 'zIndex1',
        opacity: isScrolledToBottom ? 0 : 1,
      }}
    >
      <Box {...containerProps} ref={ref} height="sizeFull" overflow="hidden auto" onScroll={handleScroll}>
        {children}
      </Box>
    </Box>
  );
});

export default ScrollWithFade;
