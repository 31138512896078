// @flow
import { Badge, Box, IconArrowFront, Spinner, Text } from '@getatomi/neon';

import { UnstyledLink } from 'src/components/Link/Link';

type Props = {|
  avatar?: React.Node,
  description?: React.Node,
  href?: string,
  isLoading?: boolean,
  onClick?: () => void | Promise<void>,
  secondaryAction?: React.Node,
  tags: $ReadOnlyArray<{ children: string, color?: string }>,
  testHook?: string,
  title: React.Node,
  to?: string,
|};

export default function Choice(props: Props) {
  const { avatar, description, href, isLoading, onClick, secondaryAction, tags, testHook, title, to } = props;
  const isCta = href || to || onClick;

  let elementProps = { as: 'div' };
  if (onClick) {
    elementProps = {
      as: 'button',
      type: 'button',
      onClick,
    };
  } else if (href || to) {
    elementProps = {
      as: UnstyledLink,
      href,
      to,
    };
  }

  const elementStyles = {
    alignItems: 'center',
    backgroundColor: 'colorBackground',
    color: 'colorText',
    display: 'flex',
    paddingBlock: 'spacingLarge',
    paddingInline: 'spacingLarge1X',
    textAlign: 'left',
    width: 'sizeFull',
    _hover: { backgroundColor: 'colorBackgroundHovered' },
  };

  return (
    <Box
      as="li"
      borderColor="colorBorder"
      borderStyle="solid"
      borderWidth="borderWidthRoot"
      transition="transitionRoot"
      _notLast={{ borderBottomWidth: 'borderWidthNone' }}
    >
      <Box {...elementProps} {...elementStyles} testHook={testHook}>
        {avatar}
        <Box marginLeft={avatar ? 'spacingLarge1X' : 'spacingNone'} marginRight="spacingRoot" wordBreak="break-word">
          <Text>{title}</Text>
          <Text variant="bodySmall1X" color="colorTextSubtler">
            {description}
          </Text>
          {tags && Boolean(tags.length) && (
            <Box as="ul" marginTop="spacingSmall2X">
              {tags.map((tag) => (
                <Box as="li" display="inline-block" key={tag.children} marginRight="spacingSmall1X">
                  <Badge {...tag} />
                </Box>
              ))}
            </Box>
          )}
          {secondaryAction}
        </Box>
        <Box marginLeft="auto">
          {isCta && !isLoading && <IconArrowFront color="colorIconBrand" strokeWidth="2" />}
          {isLoading && <Spinner size="sizeIconSmall" />}
        </Box>
      </Box>
    </Box>
  );
}
