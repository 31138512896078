// @flow
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Box, Container, EmptyState, Illustration, Stack } from '@getatomi/neon';
import invariant from 'invariant';
import Helmet from 'react-helmet';

import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import links from 'src/constants/links';
import { trackingCtas } from 'src/constants/tracking';
import FeedbackBanner from 'src/components/FeedbackBanner/FeedbackBanner';
import Link from 'src/components/Link/Link';
import UpgradeAccountPanel from 'src/domains/Class/UpgradeAccountPanel/UpgradeAccountPanel';
import { isFreePlan as isFreePlanSelector } from 'src/reducers/subscriptions';
import type { ReduxState } from 'src/types';

import GET_CLASS_INSIGHTS from './GetClassInsights.graphql';
import classInsightsTransformer from './classInsightsTransformer';
import InsightsView, { InsightsHeading, InsightsViewLoader } from './InsightsView';

type Params = {
  classId: string,
  subscriptionId: string,
};

type Props = {
  params: Params,
};

type InjectedProps = Props & {
  isFreePlan: boolean,
};

function mapStateToProps(state: ReduxState) {
  return {
    isFreePlan: isFreePlanSelector(state),
  };
}

export function InsightsQuery(props: Props) {
  const { classId, subscriptionId: accountId } = props.params;

  const { data, loading, error } = useQuery(GET_CLASS_INSIGHTS, {
    variables: {
      accountId,
      classId,
      contentFilters: {
        types: ['TOPIC'],
      },
      studentFilters: {
        roles: ['STUDENT'],
        userAccountStatuses: ['ACTIVE'],
      },
    },
  });

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load the class insights page." />;
  }

  if (loading && !data) {
    return <InsightsViewLoader />;
  }

  const classData = data?.me?.account?.class;
  invariant(classData, 'Class data should be defined');

  if (classData.metrics.progress.completedCount === 0) {
    return (
      <Stack spacing="spacingLarge7X">
        <InsightsHeading />
        <EmptyState
          media={<Illustration name="emptystate-markbook" />}
          description={
            <>
              There is not enough data to show insights yet.
              <br />
              As soon as students complete lessons, we’ll be able to show you more information.
            </>
          }
        />
      </Stack>
    );
  }

  const preparedData = classInsightsTransformer(classData);

  return <InsightsView accountId={accountId} classId={classId} preparedData={preparedData} />;
}

function InsightsContainer(props: InjectedProps) {
  const { isFreePlan } = props;

  return (
    <>
      <Helmet>
        <title>Class insights</title>
      </Helmet>

      {isFreePlan ? (
        <UpgradeAccountPanel
          videoId="6zo55cyyys"
          heading="Want to see how your students are progressing?"
          description="When you upgrade to a school account you can see how and when students complete lessons on Atomi, who’s doing well and who needs help. You can use this formative assessment data to inform your teaching and learning in the classroom."
          descriptionLink={
            <Link href={links.support.progress} isExternal>
              See how progress reports work in more detail
            </Link>
          }
          trackingData={{
            ref: trackingCtas.lockedFeatureDialog,
          }}
        />
      ) : (
        <>
          <Box marginBottom="spacingLarge7X">
            <FeedbackBanner id="class-insights-feedback-banner" url={links.feedback.classInsights}>
              Enjoying class insights? Let us know if there’s anything we can improve.
            </FeedbackBanner>
          </Box>

          <Container maxWidth="sizeContainerLarge" marginTop="spacingLarge">
            <InsightsQuery {...props} />
          </Container>
        </>
      )}
    </>
  );
}

export default (connect(mapStateToProps)(InsightsContainer): React.AbstractComponent<Props>);
