// @flow

import _ from 'lodash';

import challengeQuestionTypes from 'src/constants/questionTypes';
import { isExtendedResponse, isShortAnswer } from 'src/utils/question';

import { questionTypes } from './constants';
import type { Question, QuestionTypeFilter, SessionDuration, SessionLengthFilter } from './types';
import { getSessionDuration } from './utils';

function getQuestionMarks(question: Question): number {
  return question.marks || 1;
}

function calculateTotalDurationInSeconds(questions: Array<Question>): number {
  return _.sumBy(questions, 'duration');
}

function ensureUniqueQuestions(questions: Array<Question>): Array<Question> {
  return _.uniqBy(questions, (question: Question) => question.id);
}

function applyQuestionTypeFilters(
  questions: Array<Question>,
  questionTypeFilters: QuestionTypeFilter[]
): Array<Question> {
  const questionTypeFiltersSet = new Set<QuestionTypeFilter>(questionTypeFilters);
  return questions.filter((question) => {
    if (
      question.type === challengeQuestionTypes.MULTIPLE_CHOICE &&
      questionTypeFiltersSet.has(questionTypes.multiChoice)
    ) {
      return true;
    }
    if (
      question.type === challengeQuestionTypes.DRAG_AND_DROP &&
      questionTypeFiltersSet.has(questionTypes.dragAndDrop)
    ) {
      return true;
    }
    if (
      question.type === challengeQuestionTypes.EXACT_ANSWER &&
      questionTypeFiltersSet.has(questionTypes.exactAnswer)
    ) {
      return true;
    }
    if (
      isShortAnswer({ type: question.type, marks: getQuestionMarks(question) }) &&
      questionTypeFiltersSet.has(questionTypes.shortAnswer)
    ) {
      return true;
    }
    if (
      isExtendedResponse({ type: question.type, marks: getQuestionMarks(question) }) &&
      questionTypeFiltersSet.has(questionTypes.extendedResponse)
    ) {
      return true;
    }
    return false;
  });
}

function createSessionWithDurationLimit(questions: Array<Question>, sessionDuration: SessionDuration): Array<Question> {
  const { minDuration, maxDuration } = sessionDuration;
  const shuffledQuestions = _.shuffle(questions);
  const sessionQuestions = shuffledQuestions.reduce((acc: Array<Question>, question: Question) => {
    const currentDuration = calculateTotalDurationInSeconds(acc);
    if (currentDuration < minDuration) {
      if (currentDuration + question.duration <= maxDuration) {
        acc.push(question);
      }
    }
    return acc;
  }, []);
  return sessionQuestions;
}

export type RevisionSession = {|
  duration: number,
  filteredQuestions: Array<Question>,
  questions: Array<Question>,
|};

export function generateSession({
  isTeacher,
  questions,
  questionTypeFilters,
  sessionLengthFilter,
}: {|
  isTeacher: boolean,
  questions: Array<Question>,
  questionTypeFilters: Array<QuestionTypeFilter>,
  sessionLengthFilter: SessionLengthFilter,
|}): RevisionSession {
  const sessionDuration = getSessionDuration(isTeacher, sessionLengthFilter);
  const uniqueQuestions = ensureUniqueQuestions(questions);
  const filteredQuestions = applyQuestionTypeFilters(uniqueQuestions, questionTypeFilters);
  const sessionQuestions = createSessionWithDurationLimit(filteredQuestions, sessionDuration);
  return {
    questions: sessionQuestions,
    duration: calculateTotalDurationInSeconds(sessionQuestions),
    filteredQuestions,
  };
}
