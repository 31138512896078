// @flow

import { Divider, Text, Stack } from '@getatomi/neon';

import FormattedDate from 'src/components/Date/Date';
import type { DateString } from 'src/types';

import SamlSsoDelete from './SamlSsoDelete/SamlSsoDelete';

type Props = {|
  date: DateString,
  name: string,
  url?: ?string,
|};

function ConfigRow({ label, value }: { label: string, value: React.Node }) {
  return (
    <Stack spacing="spacingSmall">
      <Text as="p" variant="bodySmall" color="colorTextSubtle">
        {label}
      </Text>
      <Text as="p" variant="bodyRoot">
        {value}
      </Text>
    </Stack>
  );
}

export default function SamlSsoConfiguredView(props: Props) {
  const { name, date, url } = props;
  const formattedDate = <FormattedDate format="ddd D MMM, YYYY [at] h:mm a" value={date} />;
  return (
    <Stack spacing="spacingLarge1X">
      <Divider />
      <ConfigRow label="Configured by" value={name} />
      <ConfigRow label="Date" value={formattedDate} />
      {url && <ConfigRow label="URL" value={url} />}
      <Divider />
      <Text as="p" color="colorTextSubtler" variant="bodySmall1X">
        To update the SAML SSO settings delete the existing configuration and start again.
      </Text>
      <SamlSsoDelete />
    </Stack>
  );
}
