// @flow
import { useState } from 'react';
import { connect } from 'react-redux';
import { Controller } from 'react-hook-form';
import {
  Box,
  Container,
  Modal,
  PhoneField,
  TextField,
  Stack,
  Heading,
  HelpInfo,
  IconUnlock,
  Text,
  useId,
  useMediaQuery,
} from '@getatomi/neon';

import useRequestCallbackDialogForm from 'src/hooks/forms/useRequestCallbackDialogForm';
import type { BoundAction, ReduxState, OwnerDetails } from 'src/types';
import ActionResult from 'src/components/ActionResult/ActionResult';
import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';
import ModalActions from 'src/components/deprecated/ModalActions/ModalActions';
import { requestUpgrade } from 'src/actions/subscriptions';
import {
  isLoggedInAsChild as isLoggedInAsChildSelector,
  getOwnerDetails,
  hasRequestedUpgrade,
} from 'src/reducers/subscriptions';
import { getUser } from 'src/reducers/auth';
import { preferredCountries } from 'src/constants/regions';
import { getAllocatedCountryCode } from 'src/reducers/regions';

function mapStateToProps(state: ReduxState) {
  const user = getUser(state);
  return {
    countryCode: getAllocatedCountryCode(state),
    defaultPhoneNumber: user.phone_number,
    isLoggedInAsChild: isLoggedInAsChildSelector(state),
    isRequestingUpgrade: hasRequestedUpgrade(state),
    ownerDetails: getOwnerDetails(state),
  };
}
type Props = {
  isGeneralRequest?: boolean,
  isOpen: boolean,
  onClose: () => mixed,
};

type InjectedProps = Props & {
  countryCode: string,
  defaultPhoneNumber: string,
  isLoggedInAsChild: boolean,
  isRequestingUpgrade: boolean,
  ownerDetails?: OwnerDetails,
  requestUpgradeAction: BoundAction<typeof requestUpgrade>,
};

function RequestCallbackDialog(props: InjectedProps) {
  const {
    countryCode,
    defaultPhoneNumber,
    isGeneralRequest = false,
    isOpen,
    isRequestingUpgrade,
    onClose,
    requestUpgradeAction,
    isLoggedInAsChild,
    ownerDetails,
  } = props;

  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });
  const headingId = useId();

  const handleClose = () => {
    onClose();
    setShowSuccessMessage(false);
  };

  const { control, fields, form } = useRequestCallbackDialogForm({
    defaultValues: {
      email: ownerDetails?.email,
      phoneNumber: {
        isValid: null,
        number: '',
        value: defaultPhoneNumber ?? '',
      },
    },
    onSubmitSuccess: async (phoneNumber) => {
      await requestUpgradeAction({ isLoggedInAsChild, phoneNumber });
      setShowSuccessMessage(true);
    },
    onSubmitFail: () => {
      onClose();
    },
    context: {
      isEmailFieldPresent: isLoggedInAsChild,
    },
  });

  const successContent = (
    <ActionResult
      cta={
        <Button onClick={handleClose} testHook="request-callback-dialog-success-button">
          Ok, thanks
        </Button>
      }
      description={
        isLoggedInAsChild
          ? 'We’ve sent the email reminder successfully. If you want unlimited access right now, you can ask your parent to log in to their Atomi account and upgrade you on the spot.'
          : 'What happens next? We’ll give you a quick call shortly to get you started.'
      }
      heading={isLoggedInAsChild ? 'Thanks, that’s done!' : 'Thanks for getting in touch!'}
      headingId={headingId}
    />
  );

  return (
    <Modal ariaLabelledBy={headingId} size="fullscreen" variant="view" isOpen={isOpen} onClose={handleClose}>
      {showSuccessMessage ? (
        successContent
      ) : (
        <form {...form} data-test="request-callback-dialog">
          <Stack spacing="spacingLarge5X">
            <Box textAlign="center">
              <Stack spacing="spacingLarge">
                {isLoggedInAsChild && (
                  <IconUnlock display="inline-block" size="sizeIconLarge2X" strokeWidth="2" color="colorIconBrand" />
                )}
                {isGeneralRequest ? (
                  <Heading as="h1" id={headingId}>
                    {isMobile
                      ? 'Confirm your phone number below.'
                      : 'Just confirm your phone number below and we’ll get back to you shortly!'}
                  </Heading>
                ) : (
                  <>
                    <Heading as="h1" id={headingId}>
                      {isLoggedInAsChild ? 'Ask your parent to upgrade' : 'Request an upgrade'}
                    </Heading>
                    <Text as="p" color="colorTextSubtler" fontSize="fontSizeLarge" fontWeight="fontWeightLight">
                      {isLoggedInAsChild ? (
                        <>
                          You’re on a family account, so your parent needs to upgrade you to unlimited access.
                          <br />
                          Let’s send them an email reminder with a quick link to get started on the upgrade.
                        </>
                      ) : (
                        'Confirm your number below and our school partnerships team will call you back.'
                      )}
                    </Text>
                  </>
                )}
              </Stack>
            </Box>
            <Stack spacing="spacingLarge6X">
              <Container maxWidth="sizeContainerSmall">
                {isLoggedInAsChild ? (
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => <TextField {...field} {...fields.email} />}
                  />
                ) : (
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <PhoneField
                        {...field}
                        {...fields.phoneNumber}
                        initialCountry={countryCode}
                        preferredCountries={preferredCountries}
                        value={field.value.value}
                      />
                    )}
                  />
                )}
              </Container>
              <ModalActions
                isStacked
                submitLabel={isLoggedInAsChild ? 'Send upgrade request' : 'Request callback'}
                isLoading={!!isRequestingUpgrade}
                onCancel={handleClose}
              />
              <HelpInfo>
                {isLoggedInAsChild ? (
                  'They can also log into their Atomi account at any time to upgrade your family account!'
                ) : (
                  <>
                    You can also get in touch anytime through our support chat or email{' '}
                    <Link href="mailto:schools@getatomi.com" variant="monochrome">
                      schools@getatomi.com
                    </Link>
                    .
                  </>
                )}
              </HelpInfo>
            </Stack>
          </Stack>
        </form>
      )}
    </Modal>
  );
}

export default (connect(mapStateToProps, { requestUpgradeAction: requestUpgrade })(
  RequestCallbackDialog
): React.AbstractComponent<Props>);
