// @flow
import _ from 'lodash';
import { useState, useMemo } from 'react';
import { Box, HideVisually, Modal, Table, TextLoader } from '@getatomi/neon';

import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import { generatePlaceholderRows } from 'src/utils/generateTableLoadingData';

import useGetEmailLogs, { type EmailLog } from './useGetEmailLogs';

type Props = {|
  onClose: () => void,
  showModal: boolean,
  userEmail: string,
|};

type SortBy = {
  desc: boolean,
  id: string,
};

const defaultSortBy = { id: 'timestamp', desc: true };

function useSortedLogs(unsortedLogs: $ReadOnlyArray<EmailLog>): {
  setSortBy: (value: Array<SortBy>) => void,
  sortedLogs: $ReadOnlyArray<EmailLog>,
} {
  const [sortBy, setSortBy] = useState<SortBy>(defaultSortBy);
  const direction = sortBy.desc ? 'desc' : 'asc';

  const sortedLogs = useMemo(() => {
    const sortByKey = `${sortBy.id}.sortByValue`;
    return _.orderBy(unsortedLogs, [sortByKey], [direction]);
  }, [unsortedLogs, sortBy.id, direction]);

  return {
    setSortBy: (args: Array<SortBy>) => {
      const [newSortBy] = args;
      if (newSortBy) {
        setSortBy(newSortBy);
      }
    },
    sortedLogs,
  };
}

function EmailLogsTable(props: {| userEmail: string |}) {
  const { userEmail } = props;

  const { emailLogs, loading, error } = useGetEmailLogs({ userEmail });

  const { sortedLogs, setSortBy } = useSortedLogs(emailLogs ?? []);

  if (error) {
    return (
      <Box marginTop="spacingLarge">
        <GraphQLError error={error} description="We couldn’t load your email logs." />
      </Box>
    );
  }

  if (loading) {
    return (
      <>
        <HideVisually>{`Loading email logs for user with email: ${userEmail}`}</HideVisually>
        <Table
          isLoading
          columns={[
            {
              header: 'Event',
              cell: <TextLoader width="60%" />,
            },
            {
              header: 'Description',
              cell: <TextLoader />,
            },
            {
              header: 'Delivery status',
              cell: <TextLoader width="20%" />,
            },
            {
              header: 'Subject',
              cell: <TextLoader />,
            },
            {
              header: 'Timestamp',
              cell: <TextLoader />,
            },
          ]}
          data={generatePlaceholderRows(5)}
        />
      </>
    );
  }

  return (
    <Table
      columns={[
        {
          header: 'Event',
          accessorKey: 'event',
          cell: ({ getValue }) => getValue().displayValue,
        },
        {
          header: 'Description',
          accessorKey: 'description',
          cell: ({ getValue }) => getValue().displayValue,
          enableSorting: false,
        },
        {
          header: 'Delivery status',
          accessorKey: 'deliveryStatus',
          cell: ({ getValue }) => getValue().displayValue,
        },
        {
          header: 'Subject',
          accessorKey: 'subject',
          cell: ({ getValue }) => getValue().displayValue,
        },
        {
          header: 'Timestamp',
          accessorKey: 'timestamp',
          cell: ({ getValue }) => getValue().displayValue,
          sortDescFirst: false,
        },
      ]}
      data={sortedLogs}
      isSortable
      toggleSortBy={setSortBy}
      initialTableState={{
        sortBy: [defaultSortBy],
      }}
    >
      <caption>
        <HideVisually>{`Email logs for user with email: ${userEmail}`}</HideVisually>
      </caption>
    </Table>
  );
}

function ViewEmailLogsDialog(props: Props) {
  const { showModal, onClose, userEmail } = props;

  return (
    <Modal heading="Email logs" isOpen={showModal} onClose={onClose} size="fullscreen">
      {showModal && <EmailLogsTable userEmail={userEmail} />}
    </Modal>
  );
}

export default ViewEmailLogsDialog;
