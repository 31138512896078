// @flow
import { useReducer } from 'react';

import type { TaskAttachment } from 'src/domains/Tasks/types';

export type TaskAttachmentWithFreshness = {| ...$Exact<TaskAttachment>, isFresh: boolean |};

// Transform GraphQL Task.attachments to match the type consumed by the attachmentsReducer and to
// strip out __typename and other null values which would fail when sending the attachments to the
// editTask mutation
const transformGqlAttachments = (
  attachments: $ReadOnlyArray<TaskAttachment>
): $ReadOnlyArray<TaskAttachmentWithFreshness> => {
  return attachments.map(({ id, externalId, fileName, type, isInfected, url }) => ({
    id,
    externalId,
    isInfected,
    ...(fileName ? { fileName } : {}),
    type,
    url,
    isFresh: false,
  }));
};

function attachmentsReducer(attachments: $ReadOnlyArray<TaskAttachmentWithFreshness>, action) {
  switch (action.type) {
    case 'add':
      return [...attachments, { ...action.attachment, isFresh: true }];
    case 'update':
      return attachments.map((attachment) =>
        attachment.externalId === action.attachment.externalId ? { ...attachment, ...action.attachment } : attachment
      );
    case 'remove':
      return attachments.filter((attachment) => attachment.externalId !== action.id);
    default:
      return attachments;
  }
}

export default function useAttachments(
  taskAttachments: ?$ReadOnlyArray<TaskAttachment | TaskAttachmentWithFreshness>
): [$ReadOnlyArray<TaskAttachmentWithFreshness>, Function] {
  return useReducer(attachmentsReducer, transformGqlAttachments(taskAttachments ?? []));
}
