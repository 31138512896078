// @flow
import { Popover, Strength, Text } from '@getatomi/neon';
import { connect } from 'react-redux';

import type { ReduxState, LessonProgress } from 'src/types';
import StrengthInfo from 'src/components/StrengthInfo/StrengthInfo';
import { isFreeStudentPlan as isFreeStudentPlanSelector } from 'src/reducers/subscriptions';
import { strengthThresholds } from 'src/utils/config';

type Props = {
  color: string,
  progress: ?LessonProgress,
};

export type InjectedProps = Props & {
  isFreeStudentPlan: boolean,
};

const getComparisonRate = (studentMark, baseMark) =>
  studentMark == null || baseMark == null ? null : studentMark - baseMark;

function PostStrength(props: InjectedProps) {
  const { color, isFreeStudentPlan, progress } = props;
  const strengthProps = {
    color,
    value: (progress && progress.strength) || 0,
    limits: strengthThresholds,
  };
  const strength = <Strength {...strengthProps} assistiveText="Your strength:" testHook="post-strength" />;

  if (!progress) return strength;

  const studentMark = progress.mark;
  const classAvgMark = progress.classAverageMark;
  const schoolAvgMark = progress.subscriptionAverageMark;
  const stateAvgMark = progress.postAverageMark;

  const classComparisonRate: number | null = getComparisonRate(studentMark, classAvgMark);
  const schoolComparisonRate: number | null = getComparisonRate(studentMark, schoolAvgMark);
  const stateComparisonRate: number | null = getComparisonRate(studentMark, stateAvgMark);

  const popoverTrigger = <button type="button">{strength}</button>;
  const popoverContent = isFreeStudentPlan ? (
    <Text variant="bodySmall1X" textAlign="center">
      Upgrade to unlimited access to view how your score compares to other students on Atomi.
    </Text>
  ) : (
    <StrengthInfo
      markLabel="Your latest mark:"
      mark={studentMark}
      deltas={{
        class: classComparisonRate,
        school: schoolComparisonRate,
        state: stateComparisonRate,
      }}
      strengthLabel="Your current strength:"
      strengthProps={strengthProps}
    />
  );

  return (
    <Popover ariaLabel="Mark comparison" trigger={popoverTrigger}>
      {popoverContent}
    </Popover>
  );
}

export default (connect((state: ReduxState) => ({ isFreeStudentPlan: isFreeStudentPlanSelector(state) }))(
  PostStrength
): React.AbstractComponent<Props>);
