// @flow
import yn from 'yn';

type Props = {
  children: React.Node,
  name: string,
};

export default function FeatureFlag(props: Props) {
  const feature = config[`RUNTIME_FEATURE_${props.name.toUpperCase()}`];

  if (!yn(feature)) {
    return null;
  }

  return <div>{props.children}</div>;
}
