// @flow
import invariant from 'invariant';
import { useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useMutation } from '@apollo/client';
import { HideVisually, useToast } from '@getatomi/neon';

import type { SetTaskCompletion, SetTaskCompletionVariables } from 'src/graphql/types/generated/SetTaskCompletion';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import { useNavigationContext } from 'src/hooks/useNavigationContext';
import useSubscriptions from 'src/hooks/useSubscriptions';
import Logger from 'src/utils/Logger';
import { trackEvent, type TrackEventFn } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';
import broadcasting from 'src/constants/broadcasting';

import SET_TASK_COMPLETION from './SetTaskCompletion.graphql';
import StudentTaskList from './StudentTaskList';
import useGetStudentTasks from './useGetStudentTasks';
import TaskListLoader from '../components/TaskListLoader';

type Props = {
  params: {
    subscriptionId: string,
  },
};

const log = new Logger('src/domains/Tasks');

export function useTaskCompletion(trackEventFn: TrackEventFn) {
  const toast = useToast();
  const [setTaskCompletion] = useMutation<SetTaskCompletion, SetTaskCompletionVariables>(SET_TASK_COMPLETION, {
    refetchQueries: ['GetStudentTasks'],
  });

  const toggleTaskCompletion = useCallback(
    async (id: string, isCompleted: boolean) => {
      try {
        trackEventFn(trackingEvents.taskUpdateCompletionRequested, { id, isCompleted });
        await setTaskCompletion({
          variables: ({ input: { id, isCompleted } }: $Exact<SetTaskCompletionVariables>),
        });
      } catch (err) {
        log.error('Error setting the task completion status', err);
        toast.error('There was an error updating the task completion. Please try again.');
      }
    },
    [setTaskCompletion, trackEventFn, toast]
  );

  return toggleTaskCompletion;
}

export function StudentTasksQuery(props: Props) {
  const {
    params: { subscriptionId },
  } = props;
  const [classId, setClassId] = useState(null);
  const [completionFilter, setCompletionFilter] = useState(null);

  const { data, error, loading, refetch } = useGetStudentTasks({ subscriptionId, completionFilter, classId });

  useEffect(() => {
    refetch();
  }, [completionFilter, classId, refetch]);

  const toggleTaskCompletion = useTaskCompletion(trackEvent);

  useSubscriptions(
    data && data.userId
      ? [
          { channel: `user.${data.userId}`, event: broadcasting.events.USER_PROGRESS.UPDATED },
          { channel: `user.${data.userId}`, event: broadcasting.events.TASKS.CREATED },
          { channel: `user.${data.userId}`, event: broadcasting.events.TASKS.DELETED },
          { channel: `user.${data.userId}`, event: broadcasting.events.TASKS.UPDATED },
        ]
      : []
  );

  if (loading) return <TaskListLoader numFilters={2} />;

  if (error) return <GraphQLError error={error} description="We couldn’t load your tasks." />;

  invariant(data, 'Student tasks data should not be null');

  return (
    <StudentTaskList
      data={data}
      filters={{ classId, completionFilter }}
      onClassIdChange={setClassId}
      onCompletionFilterChange={setCompletionFilter}
      onTaskCompletionChange={toggleTaskCompletion}
    />
  );
}

export default function StudentTasksContainer(props: Props) {
  const {
    params: { subscriptionId: accountId },
  } = props;
  const [, setNavigationContext] = useNavigationContext();

  useEffect(() => {
    setNavigationContext({
      location: 'studentTasks',
      metadata: {
        accountId,
        title: 'Tasks',
      },
    });
  }, [accountId, setNavigationContext]);

  return (
    <>
      <Helmet title="Tasks" />
      <HideVisually>
        <h1>Tasks</h1>
      </HideVisually>
      <StudentTasksQuery {...props} />
    </>
  );
}
