// @flow
import { useQuery } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetStudentTasks,
  GetStudentTasks_me_account_tasks as InputTask,
  GetStudentTasks_me_account_tasks_attachments as InputAttachment,
  GetStudentTasksVariables,
} from 'src/graphql/types/generated/GetStudentTasks';
import { getTaskUrl } from 'src/utils/routes';
import taskStatuses from 'src/constants/taskStatuses';
import userStatusOnTask from 'src/constants/userStatusOnTask';

import GET_STUDENT_TASKS from './GetStudentTasks.graphql';
import { contentTransformer, prepareAuthorData, type Author, type TaskContent } from '../Task/taskTransformer';
import type { UserStatusOnTask } from '../types';
import { createStudentTaskFilterInput } from '../createStudentTaskFilterInput';

type TaskAttachment = {|
  fileName: ?string,
  id: string,
  isInfected: boolean,
  title: ?string,
  type: 'FILE' | 'LINK',
  url: ?string,
|};

type TaskSubject = {|
  color: string,
  groupCode: string,
  name: string,
|};

export type Task = {|
  attachments: $ReadOnlyArray<TaskAttachment>,
  author: Author,
  autoComplete: boolean,
  body: ?string,
  contents: $ReadOnlyArray<TaskContent>,
  dueDate: string,
  id: string,
  subject: TaskSubject,
  title: string,
  url: string,
  userStatus: UserStatusOnTask,
|};

export type TaskFilterClasses = $ReadOnlyArray<{|
  id: string,
  subject: {|
    color: string,
    shortName: string,
  |},
|}>;

export type GetTasks = {|
  classes: TaskFilterClasses,
  tasks: $ReadOnlyArray<Task>,
  userId: string,
|};

function attachmentTransformer(attachment: InputAttachment): TaskAttachment {
  return {
    id: attachment.id,
    type: attachment.type,
    title: attachment.title,
    url: attachment.url,
    fileName: attachment.fileName,
    isInfected: attachment.isInfected ?? false,
  };
}

function taskTransformer(subscriptionId: string, task: InputTask): Task {
  return {
    id: task.id,
    // $FlowIgnore - task titles are always defined
    title: task.title,
    body: task.body,
    author: prepareAuthorData(task.author),
    dueDate: task.dueAt,
    autoComplete: task.isAutocomplete,
    userStatus: userStatusOnTask[task.progress.status],
    subject: {
      color: task.class.subject.color,
      groupCode: task.class.subject.groupCode,
      name: task.class.subject.shortName,
    },
    attachments: task.attachments.map(attachmentTransformer),
    contents: task.contents.map((content) => contentTransformer({ classId: task.class.id, content, subscriptionId })),
    url: getTaskUrl(subscriptionId, task.class.id, task.id),
  };
}

export default function useGetStudentTasks({
  classId,
  completionFilter,
  subscriptionId,
}: {
  classId: ?string,
  completionFilter: ?$Keys<typeof taskStatuses>,
  subscriptionId: string,
}) {
  const { data, error, loading, refetch } = useQuery<GetStudentTasks, GetStudentTasksVariables>(GET_STUDENT_TASKS, {
    variables: createStudentTaskFilterInput(subscriptionId, {
      status: completionFilter,
      classId,
    }),
  });

  if (error) {
    return {
      data: null,
      error,
      loading: false,
      refetch,
    };
  }

  if (loading && !data) {
    return {
      data: null,
      error: null,
      loading: true,
      refetch,
    };
  }

  const accountData = data?.me?.account;
  const {
    classes: { edges: classes },
    tasks,
  } = accountData;

  invariant(accountData && tasks && classes, 'Tasks and classes must be defined');

  return {
    data: {
      tasks: tasks.map((task) => taskTransformer(subscriptionId, task)),
      classes: classes.map(({ node }) => node),
      userId: data.me?.id,
    },
    error: null,
    loading: false,
    refetch,
  };
}
