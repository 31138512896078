// @flow
import positions, { canadianPositions } from 'src/constants/positions';
import type { RegistrationRegionCode } from 'src/types';

export default function getPositionsByRegion(
  region: RegistrationRegionCode
): $ReadOnlyArray<{ key: string, label: string }> {
  if (region === 'ca_on') {
    return canadianPositions;
  }
  return positions;
}
