// @flow
import moment from 'moment';
import { Text } from '@getatomi/neon';

import type { DateString } from 'src/types';
import Date from 'src/components/Date/Date';
import paymentFrequencies from 'src/constants/paymentFrequencies';

type Props = {
  interval: number,
  nextStartDate: DateString,
  period: String,
};

export default function BillingDatePeriod(props: Props) {
  const { interval, period, nextStartDate } = props;

  // calculate the next billing period duration
  const periodInMonths = (period === paymentFrequencies.year ? 12 : 1) * interval;
  // calculate the next billing period end date
  const nextBillingEndDate = moment.utc(nextStartDate).add(periodInMonths, 'month').toDate();

  return (
    <Text as="span" fontSize="fontSizeSmall1X" color="colorTextSubtler" testHook="hide-in-percy">
      <Date value={nextStartDate} /> - <Date value={nextBillingEndDate} />
    </Text>
  );
}
