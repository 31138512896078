// @flow
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@getatomi/neon';

import type { FormPhoneNumber } from 'src/types';
import phoneNumberValidator from 'src/hooks/forms/validators/phoneNumberValidator';

type Fields = {
  email?: string,
  phoneNumber?: FormPhoneNumber,
};

const labels = {
  email: 'Their email address',
  phoneNumber: 'Phone number',
};

const resolver = yupResolver(
  yup.object().shape({
    phoneNumber: yup.mixed().when('$isEmailFieldPresent', {
      is: false,
      then: yup.mixed().test('isValid', '', function test(phoneNumber) {
        return phoneNumberValidator({ phoneNumber, createError: this.createError });
      }),
    }),
  })
);

export default function useRequestCallbackDialogForm({
  context,
  defaultValues,
  onSubmitSuccess,
  onSubmitFail,
}: {
  context: {
    isEmailFieldPresent?: boolean,
  },
  defaultValues: Fields,
  onSubmitFail: () => void,
  onSubmitSuccess: (phoneNumber: string) => Promise<void>,
}) {
  const toast = useToast();
  const { control, formState, handleSubmit, setValue } = useForm({
    context,
    defaultValues,
    resolver,
  });

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    handleSubmit(async (data) => {
      try {
        await onSubmitSuccess(data.phoneNumber?.number);
      } catch (error) {
        toast.error('There was an error with your request. Please try again.');
        onSubmitFail();
      }
    })(e);
  };

  const phoneNumberMessage = formState.errors.phoneNumber?.message ?? null;

  return {
    control,
    form: {
      onSubmit,
    },
    fields: {
      email: {
        label: labels.email,
        isReadOnly: true,
      },
      phoneNumber: {
        label: labels.phoneNumber,
        validationText: phoneNumberMessage,
        errorVariant: phoneNumberMessage && 'error',
        isRequired: true,
      },
    },
    setValue,
  };
}
