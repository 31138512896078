// @flow

export default {
  events: {
    CLASS_TASKS: {
      CREATED: 'class_allocation.created',
      UPDATED: 'class_allocation.updated',
      DELETED: 'class_allocation.deleted',
    },
    CLASS_USER_PROGRESS: {
      UPDATED: 'class_user_progress.updated',
    },
    TASKS: {
      CREATED: 'allocation.created',
      UPDATED: 'allocation.updated',
      DELETED: 'allocation.deleted',
    },
    USER_PROGRESS: {
      UPDATED: 'progress.updated',
    },
  },
};
