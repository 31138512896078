// @flow
import { NavTabs, NavTabsLink } from 'src/components/NavTabs/NavTabs';

type TabsProps = {
  marksUrl: string,
  overviewUrl: string,
};

export default function Tabs(props: TabsProps) {
  const { overviewUrl, marksUrl } = props;

  return (
    <NavTabs ariaLabel="Progress navigation" variant="centered">
      <NavTabsLink to={overviewUrl}>Overview</NavTabsLink>
      <NavTabsLink to={marksUrl}>Marks</NavTabsLink>
    </NavTabs>
  );
}
