// @flow
import { useCallback, useEffect, useRef } from 'react';
import { VideoPlayer as NeonVideoPlayer } from '@getatomi/neon';

import { trackEvent } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';

export type Player = {
  percentWatched(): number,
  time(): number,
};
export type TrackingData = { [key: string]: any };
export type OnVideoCompletionType = (player: Player) => Promise<void>;
type Props = {
  onComplete?: OnVideoCompletionType,
  onStart?: () => void,
  testHook?: string,
  trackingData?: TrackingData,
  videoId: string,
};

export default function VideoPlayer(props: Props) {
  const { testHook, trackingData, videoId, onComplete, onStart } = props;
  const trackedProgress = useRef<number>(0);
  const isCompleted = useRef<boolean>(false);

  useEffect(() => {
    // This component's parent manages what video is shown through a `videoId` prop. This means it
    // is never unmounted and so we need to manually reset any refs whenever the `videoId` changes.
    trackedProgress.current = 0;
    isCompleted.current = false;
  }, [videoId]);

  const track = useCallback(
    (eventName: string, player: Player, playerData: TrackingData = {}) => {
      trackEvent(
        eventName,
        {
          data: trackingData,
          playerData: {
            currentTime: player.time(),
            percentWatched: player.percentWatched() * 100,
            ...playerData,
          },
        },
        { verifyUser: true }
      );
    },
    [trackingData]
  );

  const trackCompletion = useCallback(
    (player) => {
      if (!onComplete) return;
      if (isCompleted.current === true) return;

      isCompleted.current = true;
      onComplete(player);
    },
    [onComplete, isCompleted]
  );

  const onPlay = (player) => {
    if (onStart) onStart();
    track(trackingEvents.videoPlayed, player);
  };

  const onPause = (player) => {
    track(trackingEvents.videoPaused, player);
  };

  const onEnd = (player) => {
    track(trackingEvents.videoEnded, player);
    trackCompletion(player);
  };

  const onPlaybackRateChange = (player, playbackRate) => {
    track(trackingEvents.videoPlaybackRateChanged, player, { playbackRate });
  };

  const onSecondChange = useCallback(
    (player) => {
      const playerProgress = player.percentWatched();
      if (!playerProgress || playerProgress < 0.25) return;

      if (playerProgress < 0.5) {
        // $FlowFixMe can't protect against current being undefined without rewriting unit tests
        if (trackedProgress.current < 0.25) {
          trackedProgress.current = 0.25;
          track(trackingEvents.video25PercentWatched, player);
        }
        return;
      }

      if (playerProgress < 0.75) {
        // $FlowFixMe can't protect against current being undefined without rewriting unit tests
        if (trackedProgress.current < 0.5) {
          trackedProgress.current = 0.5;
          track(trackingEvents.video50PercentWatched, player);
        }
        return;
      }

      // $FlowFixMe can't protect against current being undefined without rewriting unit tests
      if (trackedProgress.current < 0.75) {
        trackedProgress.current = 0.75;
        track(trackingEvents.video75PercentWatched, player);
        trackCompletion(player);
      }
    },
    [track, trackCompletion]
  );

  const onSeek = useCallback(
    (player, currentTime, lastTime) => {
      const timeDiff = currentTime - lastTime;
      const trackingEvent = timeDiff > 1 ? trackingEvents.videoSoughtAhead : trackingEvents.videoSoughtBackwards;
      track(trackingEvent, player, { timeDiff });
    },
    [track]
  );

  return (
    <NeonVideoPlayer
      videoId={videoId}
      onPlay={onPlay}
      onPause={onPause}
      onEnd={onEnd}
      onPlaybackRateChange={onPlaybackRateChange}
      onSecondChange={onSecondChange}
      onSeek={onSeek}
      testHook={testHook}
    />
  );
}
