// @flow
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@getatomi/neon';

import { parseValidationErrors } from 'src/api/validation';

export type Fields = {|
  name?: string,
  schoolId?: number,
  schoolName?: string,
|};

export type OutboundFields = {|
  name?: string,
  school_id?: number,
  school_name?: string,
|};

function transformOutboundData({
  data,
  canUpdateSchoolName,
}: {
  canUpdateSchoolName: boolean,
  data: Fields,
}): OutboundFields {
  const { name, schoolId, schoolName } = data;
  const isNewSchool = schoolId === null;

  if (!canUpdateSchoolName) {
    return {
      name,
    };
  }

  if (isNewSchool) {
    return {
      name,
      school_name: schoolName,
    };
  }

  return {
    name,
    school_id: schoolId,
  };
}

const labels = {
  name: 'Account name',
  schoolName: 'School',
};

const resolver = yupResolver(
  yup.object().shape({
    name: yup.string().trim().required().label(labels.name),
    schoolName: yup.mixed().when('$canUpdateSchoolName', {
      is: true,
      then: yup.string().label(labels.schoolName).required(),
    }),
  })
);

export default function useSchoolAccountForm({
  context,
  defaultValues,
  isReadOnly,
  onSubmitFail,
  onSubmitSuccess,
}: {
  context: {
    canUpdateSchoolName: boolean,
  },
  defaultValues: Fields,
  isReadOnly: boolean,
  onSubmitFail: () => void,
  onSubmitSuccess: (data: OutboundFields) => void | Promise<void>,
}) {
  const { clearErrors, control, formState, handleSubmit, setError, setValue } = useForm({
    resolver,
    defaultValues,
    context,
  });
  const { isSubmitted } = formState;
  const { canUpdateSchoolName } = context;
  const toast = useToast();

  const handleSchoolChange = useCallback(
    (schoolOption: { label: string, value: string }) => {
      const schoolId = schoolOption.value
        ? // the Combobox always returns the selected key as a string
          parseInt(schoolOption.value, 10)
        : null;
      setValue('schoolName', schoolOption.label, { shouldValidate: isSubmitted });
      setValue('schoolId', schoolId);
    },
    [isSubmitted, setValue]
  );

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    clearErrors('serverError');
    handleSubmit(async (data) => {
      try {
        const requestData = transformOutboundData({ data, canUpdateSchoolName });
        await onSubmitSuccess(requestData);
      } catch (error) {
        const validationErrors = parseValidationErrors(error);
        setError('serverError', { type: 'manual', message: validationErrors });
        if (validationErrors.length === 0) {
          toast.error('There was an error updating the school account details. Please try again.');
        }
      }
    }, onSubmitFail)(e);
  };

  const nameMessage = formState.errors.name?.message ?? null;
  const schoolNameMessage = formState.errors.schoolName?.message ?? null;

  const { serverError } = formState.errors;

  return {
    control,
    serverError,
    form: {
      onSubmit,
    },
    fields: {
      name: {
        label: labels.name,
        validationText: nameMessage,
        errorVariant: nameMessage && 'error',
        isRequired: true,
        isReadOnly,
      },
      schoolName: {
        label: labels.schoolName,
        validationText: schoolNameMessage,
        errorVariant: schoolNameMessage && 'error',
        onSelectionChange: handleSchoolChange,
        ...(isReadOnly || !canUpdateSchoolName ? { isReadOnly: true } : {}),
      },
    },
  };
}
