// @flow
import { Box, Skeleton } from '@getatomi/neon';

import type { TaskAttachment } from 'src/domains/Tasks/types';
import type { TaskContent } from 'src/domains/Tasks/Task/taskTransformer';
import type { ClassTaskContent } from 'src/domains/Tasks/ClassTasks/classTasksTransformer';
import Editor from 'src/components/Editor/Editor';
import { renderFileAttachment, renderReadOnlyAtomiContent } from 'src/utils/tasks';
import useProgressReportDialog from 'src/hooks/components/useProgressReportDialog';

type TaskMarkdownProps = {
  attachments: $ReadOnlyArray<TaskAttachment>,
  body: ?string,
  classId: string,
  contents: $ReadOnlyArray<TaskContent | ClassTaskContent>,
  isDraft?: boolean,
  isFreePlan?: boolean,
  region?: string,
  showInfectedPrompt: () => void,
  subjectColor: string,
  subscriptionId: string,
  updatedAt: ?string,
};
function TaskMarkdown(props: TaskMarkdownProps) {
  const {
    attachments,
    body,
    classId,
    contents,
    isDraft = false,
    isFreePlan,
    region,
    showInfectedPrompt,
    subjectColor,
    subscriptionId,
    updatedAt,
  } = props;

  const [progressReportDialog, { openProgressReportDialog }] = useProgressReportDialog({
    classId,
    isFreePlan,
    region,
    subscriptionId,
  });

  const getAttachment = (id) => attachments.filter(Boolean).find((attachment) => attachment.externalId === id);
  const fileAttachmentRenderer = renderFileAttachment(getAttachment, showInfectedPrompt);

  const getAttachedContent = (id) => contents.find((content) => content.id === id);
  const atomiContentRenderer = renderReadOnlyAtomiContent({
    getAttachedContent,
    isDraft,
    openProgressReportDialog,
    subjectColor,
  });

  return (
    <>
      <Editor
        // Editor should re-render if the task is updated
        key={updatedAt}
        fileAttachmentRenderer={fileAttachmentRenderer}
        readOnly
        renderAtomiContent={atomiContentRenderer}
        loader={<Skeleton animation="wave" width={400} height={36} />}
        renderEditor={(editor) => <Box height="sizeFull">{editor}</Box>}
      >
        {body}
      </Editor>
      {isFreePlan && region && progressReportDialog}
    </>
  );
}

export default TaskMarkdown;
