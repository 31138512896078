// @flow

import { Heading, Stack, Container } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';

import { ChallengeSummary } from './ChallengeSummary';

export type OnContinue = () => void;

export type ChallengeResultsProps = {|
  challengeFirstItemUrl: string,
  missedMarks: number,
  onContinue: OnContinue | null,
  scoredMarks: number,
  skippedMarks: number,
  strengthPercentage: number,
  strengthThresholds: [number, number, number, number],
|};

export function ChallengeResults(props: ChallengeResultsProps): React.Node {
  const {
    challengeFirstItemUrl,
    onContinue,
    scoredMarks,
    missedMarks,
    skippedMarks,
    strengthPercentage,
    strengthThresholds,
  } = props;

  return (
    <Container marginTop="spacingLarge3X" marginBottom="spacingRoot" textAlign="center">
      <Stack spacing="spacingLarge6X">
        <Heading as="h1" variant="large">
          Let’s see how you went!
        </Heading>
        <ChallengeSummary
          scoredMarks={scoredMarks}
          missedMarks={missedMarks}
          skippedMarks={skippedMarks}
          strengthPercentage={strengthPercentage}
          strengthThresholds={strengthThresholds}
        />
        <Stack>
          {onContinue && (
            <Button
              size="large"
              onClick={() => {
                onContinue();
              }}
            >
              Continue
            </Button>
          )}
          {/*
           * Note that we use `href` rather than `to` here so we get a non-router link
           * which is needed to navigate to a Learn App page.
           */}
          <Link size="large" href={challengeFirstItemUrl}>
            Restart
          </Link>
        </Stack>
      </Stack>
    </Container>
  );
}
