// @flow
import { useState } from 'react';
import { connect } from 'react-redux';
import { upperFirst } from 'lodash';
import { Button, Modal, useId, useMediaQuery, useToast } from '@getatomi/neon';

import type { ReduxState, ApiError, BoundAction, PaymentSourceActionLabel } from 'src/types';
import Logger from 'src/utils/Logger';
import CardDetails from 'src/components/CardDetails/CardDetails';
import { createSetupIntent, addPaymentSource, setAddPaymentSourceStatus } from 'src/actions/paymentSources';
import { isAddingPaymentSource as isAddingPaymentSourceSelector } from 'src/reducers/paymentSources';

const log = new Logger('components/AddPaymentSourceDialog');

const mapStateToProps = (state: ReduxState) => {
  return {
    isAddingPaymentSource: isAddingPaymentSourceSelector(state),
  };
};

type Props = {
  actionLabel: PaymentSourceActionLabel,
  isVisible: boolean,
  onClose: () => mixed,
};

export type InjectedProps = Props & {
  addPaymentSourceAction: BoundAction<typeof addPaymentSource>,
  createSetupIntentAction: BoundAction<typeof createSetupIntent>,
  isAddingPaymentSource: boolean,
  setAddPaymentSourceStatusAction: BoundAction<typeof setAddPaymentSourceStatus>,
};

function AddPaymentSourceDialog(props: InjectedProps) {
  const {
    actionLabel: actionLabelProp,
    addPaymentSourceAction,
    createSetupIntentAction,
    isAddingPaymentSource,
    isVisible,
    onClose,
    setAddPaymentSourceStatusAction,
  } = props;

  const [serverError, setServerError] = useState<?ApiError>(null);
  const toast = useToast();
  const formId = useId();
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const onCardSuccess = async (setupIntentId: string) => {
    try {
      await addPaymentSourceAction(setupIntentId);
      onClose();
      toast.success(`Your card has been ${actionLabelProp === 'add' ? 'added' : 'updated'} successfully.`);
    } catch (error) {
      log.error('Form submission: add payment source failed', error);
      if (!error.output) {
        return toast.error(
          `There was an error ${actionLabelProp === 'add' ? 'adding' : 'updating'} your card. Please try again.`
        );
      }
      setServerError(error.output);
    }
  };

  const onCancel = (e: SyntheticEvent<>) => {
    e.preventDefault();
    onClose();
  };

  const actionLabel = upperFirst(actionLabelProp);

  return (
    <Modal
      actions={
        <>
          <Button
            isFullWidth={isMobile}
            size={isMobile ? 'small' : undefined}
            variant={isMobile ? 'tertiary' : 'text'}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button form={formId} isFullWidth={isMobile} isLoading={isAddingPaymentSource} size="small" type="submit">
            {`${actionLabel} card`}
          </Button>
        </>
      }
      heading={`${actionLabel} card details`}
      isOpen={isVisible}
      onClose={onClose}
    >
      <CardDetails
        serverError={serverError}
        formId={formId}
        createIntent={(paymentMethodId) => createSetupIntentAction(paymentMethodId)}
        onRequest={() => setAddPaymentSourceStatusAction(true)}
        onError={() => setAddPaymentSourceStatusAction(false)}
        onSuccess={onCardSuccess}
      />
    </Modal>
  );
}

export default (connect(mapStateToProps, {
  createSetupIntentAction: createSetupIntent,
  addPaymentSourceAction: addPaymentSource,
  setAddPaymentSourceStatusAction: setAddPaymentSourceStatus,
})(AddPaymentSourceDialog): React.AbstractComponent<Props>);
