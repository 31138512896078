// @flow
import { useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { type ContextRouter, withRouter } from 'react-router';
import _ from 'lodash';
import { Box, Container, Heading, useToast } from '@getatomi/neon';

import type { BoundAction, ApiError } from 'src/types/index';
import Button from 'src/components/Button/Button';
import ErrorTypeMessage from 'src/components/ErrorTypeMessage/ErrorTypeMessage';
import FormMessage from 'src/components/FormMessage/FormMessage';
import Input from 'src/components/Input/Input';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout/LogoOnlyLayout';
import { requestResetPasswordEmail } from 'src/actions/auth';
import { getLoginUrl } from 'src/utils/routes';
import scrollTo from 'src/utils/scrollTo';

import ForgotPasswordHelp from './ForgotPasswordHelp/ForgotPasswordHelp';

export type Props = {
  requestResetPasswordEmailAction: BoundAction<typeof requestResetPasswordEmail>,
  router: ContextRouter,
};

function ForgotPassword(props: Props) {
  const { requestResetPasswordEmailAction, router } = props;

  const inputs = useRef({});
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<?ApiError>(null);
  const toast = useToast();

  const onSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const invalidInput = _.find(
      _.map(inputs.current, (value, key: string) => {
        return { key, isValid: value.validate() };
      }),
      { isValid: false }
    );

    if (invalidInput) {
      return setTimeout(() => inputs.current && scrollTo(inputs.current[invalidInput.key], -100), 0);
    }
    setIsLoading(true);
    try {
      setServerError(null);
      await requestResetPasswordEmailAction(email);
      toast.success('Your password reset email has been sent.');
      router.push(getLoginUrl());
    } catch (error) {
      setIsLoading(false);
      if (!error.output) {
        return toast.error('There was an error resetting your email. Please try again.');
      }
      setServerError(error.output);
    }
  };

  return (
    <LogoOnlyLayout>
      <Box marginBottom="spacingLarge3X">
        <Helmet>
          <title>Forgot password?</title>
        </Helmet>

        <Container maxWidth="sizeContainerSmall">
          <Heading as="h1" variant="large" color="colorTextInverted" marginBottom="spacingLarge6X">
            Enter the email address that you registered with and we'll get you back on track.
          </Heading>
        </Container>

        <Container maxWidth="sizeContainerSmall2X">
          <form>
            {serverError && (
              <FormMessage>
                <ErrorTypeMessage error={serverError} />
              </FormMessage>
            )}
            <Input
              ref={(el) => {
                if (inputs.current) inputs.current.email = el;
              }}
              type="email"
              name="email"
              placeholder="Email address"
              ariaLabel="Email address"
              autoComplete="username"
              autoCorrect="off"
              isMandatory
              withErrorIcon={false}
              onChange={(name, value) => setEmail(value)}
            />
            <Button isFullWidth isLoading={isLoading} variant="ghost" type="submit" onClick={onSubmit}>
              Request password reset link
            </Button>
          </form>
        </Container>
        <ForgotPasswordHelp />
      </Box>
    </LogoOnlyLayout>
  );
}

export default withRouter(
  (connect(null, { requestResetPasswordEmailAction: requestResetPasswordEmail })(
    ForgotPassword
  ): React.AbstractComponent<Props>)
);
