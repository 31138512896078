import UAParser from 'ua-parser-js';

const parser = new UAParser();

const isMobileDevice = () => __CLIENT__ && parser.getDevice().type === 'mobile';
const isTabletDevice = () => __CLIENT__ && parser.getDevice().type === 'tablet';
const isIos = () => __CLIENT__ && parser.getOS().name === 'iOS';

const getUserAgent = () => parser.getResult().ua;

export const isMobile = isMobileDevice();
export const isTablet = isTabletDevice();
export const isMobileOrTablet = isMobileDevice() || isTabletDevice();
export const isIosDevice = isIos();
export const userAgent = getUserAgent();

export const getOrientation = () => (window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
