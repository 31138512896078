// @flow
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@getatomi/neon';

import { parseValidationErrors } from 'src/api/validation';

export type Fields = {
  allowedEmailDomains?: string,
  providerAccessToken?: string,
  providerId?: string,
  providerRegion?: string,
};

const labels = {
  providerId: 'Provider ID',
  providerAccessToken: 'Provider Access Token',
  providerRegion: 'Provider Region',
  allowedEmailDomains: 'Allowed Email Domains',
};

const resolver = yupResolver(
  yup.object().shape({
    providerId: yup.string().trim().required().label(labels.providerId),
    providerAccessToken: yup.string().trim().label(labels.providerAccessToken).required(),
    providerRegion: yup.string().trim().label(labels.providerRegion),
    allowedEmailDomains: yup.string().trim().label(labels.allowedEmailDomains),
  })
);

export default function useWondeConfigurationForm({
  defaultValues,
  onSubmitFail,
  onSubmitSuccess,
}: {
  defaultValues: Fields,
  onSubmitFail: () => void,
  onSubmitSuccess: (data: Fields) => void | Promise<void>,
}) {
  const toast = useToast();
  const { clearErrors, control, formState, handleSubmit, setError, setValue } = useForm({
    resolver,
    defaultValues,
  });

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    clearErrors('serverError');
    handleSubmit(async (data) => {
      // See "Redux in form hooks" in Forms - The Basics (README.md#redux-and-createresolver)
      try {
        await onSubmitSuccess(data);
      } catch (error) {
        const validationErrors = parseValidationErrors(error);
        setError('serverError', { type: 'manual', message: validationErrors });

        if (validationErrors.length === 0) {
          toast.error('There was an error saving the configuration. Please try again.');
        }
      }
    }, onSubmitFail)(e);
  };

  const providerIdMessage = formState.errors.providerId?.message ?? null;
  const providerAccessTokenMessage = formState.errors.providerAccessToken?.message ?? null;
  const providerRegionMessage = formState.errors.providerRegion?.message ?? null;
  const allowedEmailDomainsMessage = formState.errors.allowedEmailDomains?.message ?? null;

  const { serverError } = formState.errors;

  return {
    control,
    setValue,
    serverError,
    form: {
      onSubmit,
    },
    fields: {
      providerId: {
        label: labels.providerId,
        validationText: providerIdMessage,
        errorVariant: providerIdMessage && 'error',
      },
      providerAccessToken: {
        label: labels.providerAccessToken,
        validationText: providerAccessTokenMessage,
        errorVariant: providerAccessTokenMessage && 'error',
      },
      providerRegion: {
        label: labels.providerRegion,
        validationText: providerRegionMessage,
        errorVariant: providerRegionMessage && 'error',
      },
      allowedEmailDomains: {
        label: labels.allowedEmailDomains,
        validationText: allowedEmailDomainsMessage,
        errorVariant: allowedEmailDomainsMessage && 'error',
      },
    },
  };
}
