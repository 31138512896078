// @flow
import { get } from 'lodash';

import {
  LOAD_SUCCESS as GET_AUTH_USER_SUCCESS,
  LOAD_INVITED_USER_SUCCESS,
  LOGIN_SUCCESS,
  UPDATE_USER_AND_SUBJECTS_SUCCESS,
} from 'src/actions/auth';
import { REGISTRATION_SUCCESS } from 'src/actions/registration';
import { SOCIAL_LOGIN_SUCCESS } from 'src/actions/socialLogins';
import { UPDATE_SUBSCRIPTION_SUCCESS } from 'src/actions/subscriptions';

export type SchoolsStateType = {
  entities: Object,
};

type StateType = {
  schools: SchoolsStateType,
};

const initialState = {
  entities: {},
};

export default function reducer(state: SchoolsStateType = initialState, action: Object = {}): SchoolsStateType {
  switch (action.type) {
    case REGISTRATION_SUCCESS: {
      const { school } = action.result.user;

      // family account owners (parents) don't have an associated school
      if (!school) return state;

      return {
        ...state,
        entities: {
          ...state.entities,
          [school.id]: {
            ...get(state.entities, school.id, {}),
            ...school,
          },
        },
      };
    }

    case UPDATE_SUBSCRIPTION_SUCCESS:
    case UPDATE_USER_AND_SUBJECTS_SUCCESS:
    case GET_AUTH_USER_SUCCESS:
    case LOAD_INVITED_USER_SUCCESS:
    case LOGIN_SUCCESS:
    case SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.result.entities.schools,
        },
      };

    default:
      return state;
  }
}

// Selectors
export const getSchoolById = (state: StateType, id: number) => get(state, `schools.entities.${id}`);
export const isSchoolLoaded = (state: StateType, id: number) => !!getSchoolById(state, id);
