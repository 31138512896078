// @flow
import { Badge, Flex } from '@getatomi/neon';

import SubjectBadge from 'src/components/SubjectBadge/SubjectBadge';

type LevelData =
  | {
      id: string,
      name: string,
    }
  | {
      id: string,
      name: React.Node,
    };

type SubjectLevelBadgesProps = {|
  isCentered?: boolean,
  isTwoLines?: boolean,
  levelsData: $ReadOnlyArray<LevelData>,
  subjectData?: {
    color: string,
    groupCode: string,
    name: string,
  },
|};

export default function SubjectLevelBadges(props: SubjectLevelBadgesProps) {
  const { levelsData, isCentered = false, isTwoLines = false, subjectData } = props;

  return (
    <Flex
      as="ul"
      display="inline-flex"
      direction={isTwoLines ? 'column' : 'row'}
      gap="spacingSmall1X"
      alignItems="center"
      justifyContent={isCentered || isTwoLines ? 'center' : undefined}
      wrap
    >
      {subjectData && (
        // Badge is an inline-block element, so we need to wrap it in a flex context to "deactivate"
        // the vertical-align baseline value.
        <Flex as="li">
          <SubjectBadge color={subjectData.color} groupCode={subjectData.groupCode}>
            {subjectData.name}
          </SubjectBadge>
        </Flex>
      )}
      {levelsData.map(({ id, name }) => (
        <Flex as="li" key={id}>
          <Badge>{name}</Badge>
        </Flex>
      ))}
    </Flex>
  );
}
