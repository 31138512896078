// @flow
import { useCallback, useMemo, useReducer } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { withRouter, type ContextRouter } from 'react-router';
import { ActionBar, Box, Button, Container, Heading, HelpInfo, Text, useMediaQuery, useToast } from '@getatomi/neon';

import type { BoundAction, Dispatch, GetState, ReduxState, RegistrationRegionCode, Subject } from 'src/types';
import Link from 'src/components/Link/Link';
import FindSubjectHelpLink from 'src/components/FindSubjectHelpLink/FindSubjectHelpLink';
import { getUserLevel, getUserRegion } from 'src/reducers/auth';
import { isSubjectsLoaded, getAllSubjectsOrderedByGroupCode } from 'src/reducers/subjects';
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import { loadLevelById } from 'src/actions/levels';
import { saveFreeRetailStudentSubjects } from 'src/actions/registration';
import connectData from 'src/decorators/connectData';
import { getSubjectLimit } from 'src/utils/config';
import Logger from 'src/utils/Logger';
import { getDashboardUrl } from 'src/utils/routes';
import { trackEvent } from 'src/utils/tracking';
import links from 'src/constants/links';
import { trackingCtas, trackingCtaTypes, trackingEvents } from 'src/constants/tracking';

import SubjectGroup from './SubjectGroup';

const SUBJECT_GROUPS = ['english', 'maths', 'science', 'hsie', 'pdhpe', 'creative_arts', 'technologies', 'generic'];
const log = new Logger('domains/Registration/RegistrationStudentSubjects');

const fetchData = (getState: GetState, dispatch: Dispatch) => {
  const state = getState();
  const { id: levelId } = getUserLevel(state);

  if (!isSubjectsLoaded(state)) {
    return dispatch(loadLevelById(levelId));
  }
};

const mapStateToProps = (state: ReduxState) => ({
  subscriptionId: getActiveSubscriptionId(state),
  subjects: getAllSubjectsOrderedByGroupCode(state),
  isSavingSubjects: state.registration.isSavingSubjects,
  regionCode: getUserRegion(state),
});

type Props = {
  isSavingSubjects: boolean,
  regionCode: RegistrationRegionCode,
  router: ContextRouter,
  saveFreeRetailStudentSubjectsAction: BoundAction<typeof saveFreeRetailStudentSubjects>,
  subjects: {
    [subjectCode: string]: Array<Subject>,
  },
  subscriptionId: number,
};

function subjectsReducer(subjects: Array<string>, action) {
  switch (action.type) {
    case 'add': {
      const selectedSubjects = [...subjects, action.subject];
      return selectedSubjects;
    }
    case 'remove': {
      const selectedSubjects = subjects.filter((subject) => subject !== action.subject);
      return selectedSubjects;
    }
    case 'clear': {
      return [];
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function RegistrationStudentSubjects(props: Props) {
  const { isSavingSubjects, regionCode, router, saveFreeRetailStudentSubjectsAction, subjects, subscriptionId } = props;
  const [selectedSubjects, dispatch] = useReducer(subjectsReducer, []);
  const subjectLimit = useMemo(() => getSubjectLimit(regionCode), [regionCode]);
  const toast = useToast();
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const onSelect = useCallback(
    (subjectCode: string) => {
      trackEvent(trackingEvents.ctaClicked, {
        cta: trackingCtas.userSubjectsSelect,
        type: trackingCtaTypes.button,
        subjectCode,
      });
      const isRemoving = selectedSubjects.includes(subjectCode);
      if (selectedSubjects.length === subjectLimit && !isRemoving) {
        return toast.warning(
          <p>
            You can only add {subjectLimit} subjects to your account. Why you ask?{' '}
            <Link href={links.support.subjectsLimit} isExternal>
              Read more about it
            </Link>{' '}
            in our Help Center.
          </p>,
          { id: 'subjects-limit' }
        );
      }
      if (isRemoving) {
        dispatch({ type: 'remove', subject: subjectCode });
      } else {
        dispatch({ type: 'add', subject: subjectCode });
      }
    },
    [selectedSubjects, subjectLimit, toast]
  );

  const onClearSelection = () => {
    dispatch({ type: 'clear' });
  };

  const onSubmit = async () => {
    try {
      await saveFreeRetailStudentSubjectsAction(selectedSubjects);
      router.push(getDashboardUrl(subscriptionId));
    } catch (err) {
      log.warn('saving retail student subjects selection failed', err);
      toast.error('There was an error saving your subjects. Please try again.');
    }
  };

  return (
    <Container textAlign="center">
      <Helmet>
        <title>Pick your subjects | Student Registration</title>
      </Helmet>
      <Box marginBottom="spacingSmall1X">
        <Heading as="h1" variant={isMobile ? 'large' : 'large2X'}>
          What do you want to learn?
        </Heading>
      </Box>
      <Text as="p" variant="bodySmall1X" color="colorTextSubtler">
        Select up to {subjectLimit} subjects. You can always change these later in your account settings.
      </Text>
      <Box marginBottom="spacingLarge">
        {SUBJECT_GROUPS.map((groupCode) => (
          <SubjectGroup
            key={groupCode}
            subjects={subjects[groupCode]}
            selectedSubjects={selectedSubjects}
            onSelect={onSelect}
          />
        ))}
        <ActionBar selectedItemCount={selectedSubjects.length} onClearSelection={onClearSelection}>
          <Button onClick={() => onSubmit()} isLoading={isSavingSubjects}>
            I’m done!
          </Button>
        </ActionBar>
      </Box>
      <HelpInfo>
        <FindSubjectHelpLink variant="monochrome" />
      </HelpInfo>
    </Container>
  );
}

export default withRouter(
  connectData(fetchData)(
    connect(mapStateToProps, {
      saveFreeRetailStudentSubjectsAction: saveFreeRetailStudentSubjects,
    })(RegistrationStudentSubjects)
  )
);
