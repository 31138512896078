// @flow
import { useMemo } from 'react';
import { Flex, HideVisually, InfoTip, StatusLight, Table, useMediaQuery } from '@getatomi/neon';

import type { Subject, YearLevel } from './useGetAccountUsage';
import useSortedData, { initialTableState } from './useSortedData';

type ColumnMeta = {|
  headerTemplate?: (headerContent: React.Node) => React.Node,
  isHeading?: boolean,
  isMobile?: boolean,
|};

type Props = {|
  data: $ReadOnlyArray<YearLevel> | $ReadOnlyArray<Subject>,
  subjectFilter?: string,
  type: 'level' | 'subject',
|};

export default function UsageTable(props: Props) {
  const { data, subjectFilter, type } = props;
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const filteredData = useMemo(() => {
    const filterSubjectsByLevel = (subjectData: $ReadOnlyArray<Subject>): $ReadOnlyArray<Subject> => {
      if (subjectFilter && subjectFilter !== 'all') {
        return subjectData.filter((subject) => subject.levelName === subjectFilter);
      }
      return subjectData;
    };

    if (type === 'subject') {
      // $FlowIgnore - we know that this only contains subject data
      return filterSubjectsByLevel(data);
    }

    return data;
  }, [data, subjectFilter, type]);

  const { sortedData, setSortBy } = useSortedData(filteredData);

  const columns = useMemo(
    () => [
      {
        header: type === 'level' ? 'Level' : 'Subject',
        accessorKey: 'name',
        cell: ({ getValue }) => getValue().displayValue,
        meta: {
          isHeading: true,
          isMobile: true,
        },
      },
      {
        header: 'Purchased seats',
        accessorKey: 'purchasedSeats',
        cell: ({ getValue }) => getValue().displayValue,
      },
      {
        header: 'Used seats',
        accessorKey: 'usedSeats',
        cell: ({ getValue }) => getValue().displayValue,
        meta: {
          headerTemplate: (headerContent: React.Node) => {
            return type === 'subject' ? (
              <Flex alignItems="center" gap="spacingSmall1X">
                <InfoTip
                  content="Used seats per subject may include the same user multiple times."
                  iconSize="sizeIconSmall2X"
                />
                {headerContent}
              </Flex>
            ) : (
              headerContent
            );
          },
        },
      },
      {
        header: 'Utilisation',
        accessorKey: 'utilisation',
        cell: ({ getValue }) => {
          const { displayValue, sortByValue } = getValue();
          if (displayValue === '-') {
            return displayValue;
          }
          return (
            <StatusLight
              color={sortByValue <= 100 ? 'colorIconSuccess' : 'colorIconDanger'}
              testHook="utilisation-icon"
            >
              {displayValue}
            </StatusLight>
          );
        },
        meta: {
          isMobile: true,
        },
      },
    ],
    [type]
  );

  return (
    <Table
      columns={isMobile ? columns.filter(({ meta }: { meta?: ColumnMeta }) => meta?.isMobile) : columns}
      data={sortedData}
      initialState={initialTableState}
      isSortable
      toggleSortBy={setSortBy}
    >
      <caption>
        <HideVisually>{type === 'level' ? 'Per year level usage' : 'Per subject usage'}</HideVisually>
      </caption>
    </Table>
  );
}
