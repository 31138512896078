// @flow
import { Box, IconSearch, useHover } from '@getatomi/neon';

type Props = {|
  onClick: () => void,
|};

export default function FindContentDialogTrigger(props: Props) {
  const { onClick } = props;

  const { hoverProps, isHovered } = useHover({});

  return (
    <Box
      as="button"
      onClick={onClick}
      type="button"
      display="inline-flex"
      alignItems="center"
      gap="spacingSmall"
      paddingBlock="spacingSmall1X"
      paddingInline="spacingRoot"
      width="sizeFull"
      color="colorTextSubtle"
      borderRadius="borderRadiusRoot"
      fontFamily="fontFamilySystem"
      fontSize="fontSizeSmall"
      lineHeight="lineHeightLarge"
      transition="transitionRoot"
      backgroundColor={isHovered ? 'colorBackgroundNeutralHovered' : 'colorBackgroundNeutral'}
      {...hoverProps}
    >
      <IconSearch size="sizeIconSmall" />
      <Box as="span">Search videos, lessons, and quizzes</Box>
    </Box>
  );
}
