// @flow
import { Box, Card, Flex, Heading, ProgressCircle, Text, useMediaQuery } from '@getatomi/neon';

import { formatPercentageAsString } from 'src/utils/formatPercentage';

import type { TopicProgressStats } from './topicInsightsTransformer';

function MosaicItem(props: {
  average: string | number,
  highest: ?string | ?number,
  lowest: ?string | ?number,
  progressValue: number,
  title: string,
}) {
  const { title, average, progressValue, highest, lowest } = props;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const highestAndLowest = [
    highest !== null && (
      <Flex gap="spacingSmall" justifyContent="space-between" testHook="highest">
        <Box as="span">Highest</Box> {highest}
      </Flex>
    ),
    lowest !== null && (
      <Flex gap="spacingSmall" justifyContent="space-between" testHook="lowest">
        <Box as="span">Lowest</Box> {lowest}
      </Flex>
    ),
  ];

  return (
    <Card
      variant="rounded"
      padding={{ base: 'spacingRoot', tablet: 'spacingLarge' }}
      height="sizeFull"
      withHover={false}
    >
      <Flex direction="column" gap="spacingLarge2X" height="sizeFull">
        <Heading as="h2" variant="small">
          {title}
        </Heading>
        <Flex grow={1} justifyContent="center" alignItems="flex-start">
          <ProgressCircle value={progressValue} testHook="progress-circle" size={isMobile ? undefined : 'large1X'}>
            {average}
          </ProgressCircle>
        </Flex>
        {highestAndLowest.length ? (
          <Text color="colorTextSubtler" fontSize="fontSizeSmall">
            {highestAndLowest}
          </Text>
        ) : null}
      </Flex>
    </Card>
  );
}

export default function Mosaic(props: TopicProgressStats) {
  const { marks, lessonsCompleted, assessmentsCompleted } = props;
  const averageMark = marks.average;

  return (
    <Box
      display="grid"
      columnGap="spacingRoot"
      gridRowGap="spacingRoot"
      gridAutoRows="max-content"
      gridTemplateColumns={{ base: 'repeat(2, 1fr)', tablet: 'repeat(3, 1fr)' }}
    >
      {lessonsCompleted.average != null ? (
        <MosaicItem
          title="Average lessons completed"
          average={Math.round(lessonsCompleted.average)}
          // $FlowIgnore
          progressValue={lessonsCompleted.average / lessonsCompleted.ofTotal}
          highest={lessonsCompleted.max}
          lowest={lessonsCompleted.min}
        />
      ) : null}
      {assessmentsCompleted.average != null ? (
        <MosaicItem
          title="Average quizzes completed"
          average={Math.round(assessmentsCompleted.average)}
          // $FlowIgnore
          progressValue={assessmentsCompleted.average / assessmentsCompleted.ofTotal}
          highest={assessmentsCompleted.max}
          lowest={assessmentsCompleted.min}
        />
      ) : null}
      {averageMark != null ? (
        <MosaicItem
          title="Average mark"
          average={formatPercentageAsString(averageMark)}
          progressValue={averageMark}
          highest={marks.max ? formatPercentageAsString(marks.max) : null}
          lowest={marks.min ? formatPercentageAsString(marks.min) : null}
        />
      ) : null}
    </Box>
  );
}
