// @flow
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import { IconChevron } from '@getatomi/neon';

import type { SelectOption } from 'src/types';

import styles from './NativeSelect.module.scss';

const cx = classnames.bind(styles);

type Props = {
  className?: string,
  disabled: boolean,
  hasError?: boolean,
  name?: string,
  onChange: Function,
  options: Array<SelectOption>,
  placeholder: string,
  simpleValue: boolean,
  value?: string | number,
  withPlaceholder: boolean,
};

export default class NativeSelect extends React.Component<Props> {
  static defaultProps = {
    onChange: noop,
    disabled: false,
    simpleValue: false,
    withPlaceholder: true,
    placeholder: 'Please select...',
  };

  onChange = (e: SyntheticEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const { onChange, simpleValue } = this.props;
    const selectedValue = simpleValue ? value : { value };
    if (onChange) {
      onChange(selectedValue);
    }
  };

  render() {
    const { className, disabled, hasError, name, options, placeholder, withPlaceholder, value } = this.props;

    return (
      <div className={styles.root}>
        <select
          disabled={disabled}
          name={name}
          value={value}
          onChange={this.onChange}
          className={cx('select', className, { hasError })}
        >
          {withPlaceholder && <option value="">{placeholder}</option>}
          {options.map((opt) => (
            <option key={`opt-${opt.label || ''}`} value={opt.value} disabled={opt.disabled}>
              {opt.label}
            </option>
          ))}
        </select>
        <IconChevron size="sizeIconSmall3X" color="colorIconBrandBold" strokeWidth={2} className={styles.icon} />
      </div>
    );
  }
}
