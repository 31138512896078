// @flow
import { forwardRef } from 'react';
import { Button as NeonButton } from '@getatomi/neon';
import { Link } from 'react-router';

import type { TrackingDataType } from 'src/types';
import { trackingCtaTypes, trackingEvents } from 'src/constants/tracking';
import { extractLabelFromChildren, trackEvent } from 'src/utils/tracking';
import { toProperCase } from 'src/utils/string';

export type Size = 'small1X' | 'small' | 'root' | 'large';
export type Variant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'legacy'
  | 'ghost'
  | 'tag'
  | 'text';

type ButtonProps = {
  as?: any,
  children?: React.Node,
  iconAfter?: React.Node,
  iconBefore?: React.Node,
  isDisabled?: boolean,
  isFullWidth?: boolean,
  isLoading?: boolean,
  isUnstyled?: boolean,
  onClick?: Function,
  size?: Size,
  target?: string,
  title?: string,
  to?: string,
  trackingData?: TrackingDataType,
  type?: string,
  variant?: Variant,
};

export function trackButtonClick(props: ButtonProps) {
  const { children, size, target, to, trackingData, type, variant } = props;
  const text = children ? extractLabelFromChildren(children) : null;

  trackEvent(trackingEvents.ctaClicked, {
    buttonType: type,
    cta: text && toProperCase(text),
    href: to,
    isExternal: target === '_blank' || undefined,
    size,
    text,
    type: trackingCtaTypes.button,
    variant: variant || 'default',
    ...trackingData,
  });
}

export default function Button(props: ButtonProps) {
  const {
    as,
    children,
    onClick,
    to,
    trackingData,
    variant,
    size,
    target,
    type = 'button',
    isUnstyled,
    ...otherProps
  } = props;

  const onButtonClick = (e: SyntheticEvent<*>) => {
    trackButtonClick(props);
    onClick && onClick(e);
  };

  if (isUnstyled) {
    return (
      // eslint-disable-next-line react/button-has-type
      <button onClick={onButtonClick} type={type} target={target} {...otherProps}>
        {children}
      </button>
    );
  }

  return (
    <NeonButton
      as={to ? Link : as}
      onClick={onButtonClick}
      to={to}
      type={type}
      variant={variant}
      size={size}
      target={target}
      {...otherProps}
    >
      {children}
    </NeonButton>
  );
}

export const UnstyledButton = forwardRef<ButtonProps, React.ElementRef<typeof Button>>(function UnstyledButton(
  props,
  ref
) {
  return <Button ref={ref} {...props} isUnstyled />;
});
