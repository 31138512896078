// @flow
import _ from 'lodash';

import useSelectedItems, { type SelectedItems } from 'src/hooks/useSelectedItems';

import type { TopicType, LessonType } from './LessonList';

type Props = {|
  topics: $ReadOnlyArray<TopicType>,
|};

type Output = {|
  selectedLessons: $ReadOnlyArray<LessonType>,
  selectedLessonsDuration: number,
  Selecting: SelectedItems,
|};

function extractLessons(topics: $ReadOnlyArray<TopicType>): $ReadOnlyArray<LessonType> {
  const lessons = [];
  topics.forEach((topic) => {
    topic.lessons.forEach((topicLesson) => {
      lessons.push(topicLesson);
    });
    topic.subtopics.forEach((subtopic) => {
      subtopic.lessons.forEach((subtopicLesson) => {
        lessons.push(subtopicLesson);
      });
    });
  });
  return lessons;
}

export default function useSelectedLessons(props: Props): Output {
  const { topics } = props;

  const lessons = extractLessons(topics);
  const Selecting = useSelectedItems(lessons.map((lesson) => lesson.id));
  const selectedLessons = lessons.filter((lesson) => Selecting.isSelected(lesson.id));
  const selectedLessonsDuration = _.sumBy(selectedLessons, 'duration');

  return {
    Selecting,
    selectedLessons,
    selectedLessonsDuration,
  };
}
