// @flow

import type { MasterLayoutSpacingBetweenHeaderAndMainContent, RegistrationRegionCode } from 'src/types';

import MasterLayout from '../shared/MasterLayout/MasterLayout';
import Header from './Header/Header';

type Props = {
  children: React.Node,
  isMainContentFlexContainer?: boolean,
  isMainContentVerticallyCentered?: boolean,
  isRegistration?: boolean,
  regionCode?: RegistrationRegionCode,
  removeSpacingBetweenHeaderAndMainContent?: MasterLayoutSpacingBetweenHeaderAndMainContent,
  showSwitcher?: boolean,
  sidePanel?: React.Node,
};

export default function NonAuthenticatedLayout(props: Props) {
  const {
    children,
    isMainContentFlexContainer = false,
    isMainContentVerticallyCentered = false,
    showSwitcher = true,
    isRegistration = true,
    regionCode,
    removeSpacingBetweenHeaderAndMainContent,
    sidePanel,
  } = props;

  if (!children) return null;

  return (
    <MasterLayout
      header={<Header isRegistration={isRegistration} regionCode={regionCode} showSwitcher={showSwitcher} />}
      sidePanel={sidePanel}
      withBrandBackgroundColor={!isRegistration}
      withFixedHeader={false}
      withFooter={false}
      isMainContentFlexContainer={isMainContentFlexContainer}
      isMainContentVerticallyCentered={isMainContentVerticallyCentered}
      removeSpacingBetweenHeaderAndMainContent={removeSpacingBetweenHeaderAndMainContent}
    >
      {children}
    </MasterLayout>
  );
}
