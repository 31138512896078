// @flow
import { difference, filter, partialRight, size, union, values } from 'lodash';

import type { ReduxState, UserType } from 'src/types';
import { processErrors } from 'src/api/validation';
import roles from 'src/constants/userRoles';
import userAccountStatuses from 'src/constants/userAccountStatuses';
import {
  CHANGE_USERS_STATUS_FILTER,
  ARCHIVE_USERS,
  ARCHIVE_USERS_FAIL,
  ARCHIVE_USERS_SUCCESS,
  RESTORE_USERS,
  RESTORE_USERS_FAIL,
  RESTORE_USERS_SUCCESS,
  MAKE_USER_ADMIN,
  MAKE_USER_ADMIN_FAIL,
  MAKE_USER_ADMIN_SUCCESS,
  UNMAKE_USER_ADMIN,
  UNMAKE_USER_ADMIN_FAIL,
  UNMAKE_USER_ADMIN_SUCCESS,
  TRANSFER_ACCOUNT_OWNERSHIP,
  TRANSFER_ACCOUNT_OWNERSHIP_FAIL,
  TRANSFER_ACCOUNT_OWNERSHIP_SUCCESS,
  LOAD_ACTIVE_USERS_BY_ROLE_SUCCESS,
  IS_USERS_FOR_OWNERSHIP_TRANSFER_LOADED,
  RESEND_USER_INVITES,
  RESEND_USER_INVITES_FAIL,
  RESEND_USER_INVITES_SUCCESS,
  INVITE_USER,
  INVITE_USER_FAIL,
  INVITE_USER_SUCCESS,
  BULK_INVITE_USERS,
  BULK_INVITE_USERS_FAIL,
  BULK_INVITE_USERS_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
} from 'src/actions/users';
import { LOAD_CLASSES_SUCCESS, LOAD_SUBSCRIPTION_CLASSES_SUCCESS } from 'src/actions/classes';
import { DELETE_AVATAR_SUCCESS } from 'src/actions/avatars';
import { isLoggedInAsParent } from 'src/reducers/subscriptions';

import type { PaginationStateType } from './pagination';
import * as pagination from './pagination';

export type UsersStateType = PaginationStateType & {
  isInvitingUsers: boolean,
  isProcessingUsers: boolean,
  isUsersForOwnershipTransferLoaded: boolean,
  processingIds: Array<string>, // e.g used for the users select options in the transfer ownership modal
  statusFilter: string,
  usersForOwnershipTransfer: Array<string>,
};

type StateType = {
  users: UsersStateType,
};

const NAME = 'users';

export default pagination.reducer(NAME, (state: UsersStateType, action: Object = {}): UsersStateType => {
  switch (action.type) {
    case CHANGE_USERS_STATUS_FILTER:
      return {
        ...state,
        statusFilter: action.statusFilter,
      };

    case LOAD_ACTIVE_USERS_BY_ROLE_SUCCESS:
      return {
        ...state,
        usersForOwnershipTransfer: union(state.usersForOwnershipTransfer, action.result.result.items),
        entities: {
          ...state.entities,
          ...action.result.entities.users,
        },
      };

    case IS_USERS_FOR_OWNERSHIP_TRANSFER_LOADED:
      return {
        ...state,
        isUsersForOwnershipTransferLoaded: true,
      };

    case LOAD_CLASSES_SUCCESS:
    case LOAD_SUBSCRIPTION_CLASSES_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.result.entities.students,
          ...action.result.entities.teachers,
        },
      };

    case ARCHIVE_USERS:
    case RESTORE_USERS:
    case MAKE_USER_ADMIN:
    case UNMAKE_USER_ADMIN:
    case TRANSFER_ACCOUNT_OWNERSHIP:
    case RESEND_USER_INVITES:
      // the loading state is handled client side for bulk actions, only set the loading state for
      // single user actions
      return action.data?.ids?.length === 1
        ? {
            ...state,
            isProcessingUsers: true,
            processingIds: union(state.processingIds, action.data.ids),
          }
        : state;

    case ARCHIVE_USERS_FAIL:
    case RESTORE_USERS_SUCCESS:
    case RESTORE_USERS_FAIL:
    case MAKE_USER_ADMIN_SUCCESS:
    case MAKE_USER_ADMIN_FAIL:
    case UNMAKE_USER_ADMIN_SUCCESS:
    case UNMAKE_USER_ADMIN_FAIL:
    case RESEND_USER_INVITES_SUCCESS:
    case RESEND_USER_INVITES_FAIL: {
      return {
        ...state,
        isProcessingUsers: false,
        processingIds: difference(state.processingIds, action.data.ids),
      };
    }

    case ARCHIVE_USERS_SUCCESS:
    case TRANSFER_ACCOUNT_OWNERSHIP_SUCCESS:
      return {
        ...state,
        isProcessingUsers: false,
        processingIds: difference(state.processingIds, action.data.ids),
        usersForOwnershipTransfer: [],
        isUsersForOwnershipTransferLoaded: false,
      };

    case TRANSFER_ACCOUNT_OWNERSHIP_FAIL: {
      return {
        ...state,
        isProcessingUsers: false,
        processingIds: [],
      };
    }

    case DELETE_AVATAR_SUCCESS: {
      const { userId } = action.data;
      return {
        ...state,
        entities: {
          ...state.entities,
          [userId]: {
            ...state.entities[userId],
            avatar_mime_type: null,
            avatar_url: null,
          },
        },
      };
    }

    case INVITE_USER:
    case BULK_INVITE_USERS:
      return {
        ...state,
        isInvitingUsers: true,
      };

    case INVITE_USER_SUCCESS:
    case BULK_INVITE_USERS_SUCCESS:
      return {
        ...state,
        isInvitingUsers: false,
      };

    case INVITE_USER_FAIL:
    case BULK_INVITE_USERS_FAIL:
      return {
        ...state,
        isInvitingUsers: false,
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        ...processErrors(action.routeError),
      };

    case UPDATE_USER_SUCCESS:
      const { userId, userDetails } = action.data;
      return {
        ...state,
        entities: {
          ...state.entities,
          [userId]: {
            ...state.entities[userId],
            ...userDetails,
            level: {
              ...action.result.level,
            },
          },
        },
      };

    default:
      return state;
  }
});

// pagination and entities selectors
export const isUsersLoading = partialRight(pagination.isLoading, NAME);
export const isAllUsersLoaded = partialRight(pagination.isAllEntitiesLoaded, NAME);
export const getUserById = partialRight(pagination.getEntityById, NAME);
export const getUsersByIds = partialRight(pagination.getEntitiesByIds, NAME);
export const getAllUsersForCurrentPage = partialRight(pagination.getAllEntitiesForCurrentPage, NAME);
export const getUsersPagination = partialRight(pagination.getPagination, NAME);
export const getPageSize = partialRight(pagination.getPageSize, NAME);
export const isPageLoaded = partialRight(pagination.isPageLoaded, NAME);
export const getSearchKeywords = partialRight(pagination.getSearchKeywords, NAME);

// users selector by role
export const getAllUsersByRole = (state: StateType, role: string): Array<UserType> =>
  filter(values(state.users.entities), { role });
export const getAllUsersByRoles = (state: StateType, userRoles: Array<string>): Array<UserType> =>
  filter(values(state.users.entities), (user) => userRoles.includes(user.role));
export const getAllUsersWhoCanBeAddedOwners = (state: StateType) =>
  getUsersByIds(state, state.users.usersForOwnershipTransfer);
export const hasUsersWhoCanBeAddedOwners = (state: StateType) => size(state.users.usersForOwnershipTransfer) > 0;
export const getAllStudents = (state: StateType) => getAllUsersByRole(state, roles.student);
export const getPendingStudents = (state: StateType): Array<UserType> =>
  filter(values(state.users.entities), {
    role: roles.student,
    subscription_status: userAccountStatuses.legacy.invited,
  });

// user selectors for class
const isNotArchived = (users: Array<UserType>): Array<UserType> =>
  users.filter((user) => user.subscription_status !== userAccountStatuses.legacy.archived);
export const getAllPotentialClassTeachers = (state: StateType) =>
  isNotArchived(getAllUsersByRoles(state, [roles.teacher, roles.accountOwner, roles.accountAdmin]));
export const getAllPotentialClassStudents = (state: StateType) =>
  isNotArchived(getAllUsersByRole(state, roles.student));

// individual user role selectors
export const getUserRole = (state: StateType, userId: number) => getUserById(state, userId).role;
export const isAccountManager = (state: StateType, userId: number) =>
  getUserRole(state, userId) === roles.accountManager;
export const isAccountOwner = (state: StateType, userId: number) => getUserRole(state, userId) === roles.accountOwner;
export const isAccountAdmin = (state: StateType, userId: number) => getUserRole(state, userId) === roles.accountAdmin;
export const isTeacher = (state: StateType, userId: number) => getUserRole(state, userId) === roles.teacher;
export const isStudent = (state: StateType, userId: number) => getUserRole(state, userId) === roles.student;
export const isPending = (state: StateType, userId: number) =>
  getUserById(state, userId).subscription_status === userAccountStatuses.legacy.invited;

// filters/search selectors
export const getStatusFilter = (state: StateType) => state.users.statusFilter;

// user action related selectors
export const isProcessingUsers = (state: StateType) => state.users.isProcessingUsers;
export const isProcessingUser = (state: StateType, id: number) =>
  state.users.processingIds && state.users.processingIds.includes(id);
export const isUsersForOwnershipTransferLoaded = (state: StateType) => state.users.isUsersForOwnershipTransferLoaded;
export const isInvitingUsers = (state: StateType) => state.users.isInvitingUsers;

export const hasPendingChild = (state: ReduxState) => {
  if (!isLoggedInAsParent(state)) {
    return false;
  }

  return getPendingStudents(state).length > 0;
};
