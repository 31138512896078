// @flow
import { connect } from 'react-redux';
import { InfoTip, Switch, useToast } from '@getatomi/neon';

import type { BoundAction, ReduxState } from 'src/types';
import { toggleFeatureFlag } from 'src/actions/subscriptions';
import { isFeatureFlagEnabled } from 'src/reducers/subscriptions';
import featureFlags from 'src/constants/featureFlags';

type Props = {
  flag: $Keys<typeof featureFlags>,
  inverseFlag?: boolean,
  label: string, // eslint-disable-line react/no-unused-prop-types
  tooltip: string,
};

export const mapStateToProps = (state: ReduxState, props: Props) => ({
  isEnabled: isFeatureFlagEnabled(state, props.flag),
});

type InjectedProps = Props & {
  isEnabled: boolean,
  toggleFeatureFlagAction: BoundAction<typeof toggleFeatureFlag>,
};

function ToggleFeatureFlag(props: InjectedProps) {
  const { flag, isEnabled, label, tooltip, toggleFeatureFlagAction, inverseFlag } = props;
  const toast = useToast();

  const toggle = async () => {
    try {
      await toggleFeatureFlagAction(flag, !isEnabled);
    } catch (error) {
      toast.error(`There was an error toggling the ${label} feature. Please try again.`);
    }
  };

  return (
    <Switch
      label={
        <>
          {label}
          {tooltip && <InfoTip content={tooltip} />}
        </>
      }
      onChange={toggle}
      isSelected={inverseFlag ? !isEnabled : isEnabled}
    />
  );
}

export default (connect(mapStateToProps, { toggleFeatureFlagAction: toggleFeatureFlag })(
  ToggleFeatureFlag
): React.AbstractComponent<Props>);
