// @flow
import { Card, Flex, Heading, ProgressCircle, Text, useMediaQuery } from '@getatomi/neon';

import { getFraction, formatPercentageAsString } from 'src/utils/formatPercentage';
import { UnstyledLink } from 'src/components/Link/Link';

type CompletionRateMetricProps = {
  to: string,
  total: number,
  value: number,
};

const label = 'View all students';

export default function CompletionRateMetric(props: CompletionRateMetricProps) {
  const { to, total, value } = props;

  const fractionValue = getFraction(value, total) ?? 0;
  const formattedPercentage = formatPercentageAsString(fractionValue);

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  return (
    <Card
      as={UnstyledLink}
      to={to}
      variant="rounded"
      padding={{ base: 'spacingRoot', tablet: 'spacingLarge' }}
      aria-label={label}
      height="sizeFull"
      testHook="completion-rate-metric"
    >
      <Flex direction="column" gap="spacingLarge2X" height="sizeFull">
        <div>
          <Heading as="h2" variant="small" marginBottom="spacingSmall3X">
            Completion rate
          </Heading>
          <Text variant="bodySmall1X" color="colorTextSubtle">
            {value} of {total} Students completed
          </Text>
        </div>
        <Flex grow="1" justifyContent="center" alignItems="flex-end" aria-hidden>
          <ProgressCircle
            value={fractionValue}
            size={isMobile ? undefined : 'large1X'}
            testHook="completion-rate-metric-progress-circle"
          >
            {formattedPercentage}
          </ProgressCircle>
        </Flex>
        <Text color="colorTextLink">{label}</Text>
      </Flex>
    </Card>
  );
}
