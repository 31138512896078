// @flow
import { ProgressBar } from '@getatomi/neon';

type Props = {|
  progress: number,
  subjectColor: string,
|};

function ClassProgress(props: Props) {
  const { progress, subjectColor } = props;

  return (
    <ProgressBar
      ariaLabel="Progress on this subject"
      color={subjectColor}
      max={1}
      value={progress}
      variant="chunky"
      withLabel
    />
  );
}

export default ClassProgress;
