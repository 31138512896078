// @flow

import { getChallengeFirstItemUrl } from 'src/utils/routes';

import type { Lesson } from '../useGetLesson';
import PostChallengeStartContainer from '../PostChallengeStart/PostChallengeStartContainer';
import PostChallengeResultsContainer from '../PostChallengeResults/PostChallengeResultsContainer';

export type NextLessonProps = {|
  isLocked: boolean,
  url: string,
|};

export type PostChallengeProps = {|
  accountId: string,
  actions: React.Node,
  classId: string,
  isEmbedded: boolean,
  isLoggedInAsChild: boolean,
  isLti: boolean,
  isSchoolPlan: boolean,
  labels: React.Node,
  lesson: Lesson,
  moduleId: string,
  nextLessonProps: NextLessonProps | null,
  view: 'Start' | 'Results',
|};

export default function PostChallenge({
  accountId,
  classId,
  moduleId,
  actions,
  isLoggedInAsChild,
  isSchoolPlan,
  lesson,
  labels,
  nextLessonProps,
  view,
  isEmbedded,
  isLti,
}: PostChallengeProps): React.Node {
  const challengeFirstItemUrl = getChallengeFirstItemUrl({
    accountId,
    classId,
    moduleId,
    postId: String(lesson.id),
    isLti,
    isEmbedded,
  });

  if (view === 'Start') {
    return (
      <PostChallengeStartContainer
        secondaryActions={actions}
        challengeFirstItemUrl={challengeFirstItemUrl}
        badges={labels}
        lesson={lesson}
      />
    );
  }

  return (
    <PostChallengeResultsContainer
      accountId={accountId}
      challengeFirstItemUrl={challengeFirstItemUrl}
      isLoggedInAsChild={isLoggedInAsChild}
      isSchoolPlan={isSchoolPlan}
      nextLessonProps={nextLessonProps}
    />
  );
}
