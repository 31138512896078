// @flow
import { useCallback, useState, useMemo } from 'react';
import { Avatar as NeonAvatar, Box, IconUsers, Truncate, SelectTrigger } from '@getatomi/neon';

import Avatar, { type User as AvatarUser } from 'src/components/Avatar/Avatar';
import userFullName from 'src/utils/userFullName';
import AvatarWithText from 'src/components/AvatarWithText/AvatarWithText';
import MultiSelect from 'src/components/MultiSelect/MultiSelect';

function OptionLabel(props: { isArchived?: boolean, label: string }) {
  const { label, isArchived = false } = props;

  return (
    <Box
      color={isArchived ? 'colorTextSubtler' : undefined}
      display="inline-flex"
      gap="spacingSmall2X"
      alignItems="center"
      minWidth="sizeNone"
    >
      <Truncate>{label}</Truncate>
      {isArchived && '(Archived)'}
    </Box>
  );
}

export type StudentProp = AvatarUser & {
  id: string,
};

function StudentOptionLabel(props: { label: string, student: StudentProp }) {
  const { label, student } = props;
  const isArchived = Boolean(student.deletedAt);

  return (
    <AvatarWithText
      avatar={
        student ? (
          <Avatar size="sizeAvatarSmall1X" user={student} />
        ) : (
          <NeonAvatar
            size="sizeAvatarSmall1X"
            backgroundColor="colorBackgroundBrand"
            icon={<IconUsers size="sizeIconSmall1X" color="colorIconInverted" />}
          />
        )
      }
      text={<OptionLabel label={label} isArchived={isArchived} />}
    />
  );
}

export type Option = {|
  isSelected: boolean,
  label: React.Element<typeof StudentOptionLabel>,
  studentId: string,
  triggerLabel: string,
  value: string,
|};

type ExpectedTriggerType = React.Element<typeof MultiSelect.Trigger | typeof SelectTrigger>;
type RenderTriggerFunction = (
  studentOptions: $ReadOnlyArray<Option>,
  selectedStudentIds: $ReadOnlyArray<string>
) => ExpectedTriggerType;

export type Props = {
  initialStudentIds?: ?$ReadOnlyArray<string>,
  onChange: (values: Array<string>) => void,
  onClose?: (values: Array<string>) => void,
  renderTrigger?: RenderTriggerFunction,
  searchEmptyState?: string,
  students: $ReadOnlyArray<StudentProp>,
  testHook: string,
  variant?: 'compact',
};

export default function StudentPicker(props: Props) {
  const defaultRenderTrigger = (studentOptions, selectedStudentIds) => {
    let label;
    switch (selectedStudentIds.length) {
      case studentOptions.length:
        label = 'All students';
        break;
      case 0:
        label = 'Select students';
        break;
      case 1:
        const selectedStudent = studentOptions.find((option) => option.studentId === selectedStudentIds[0]);
        // $FlowIgnore - student triggerLabel is defined
        label = selectedStudent.triggerLabel;
        break;
      default:
        label = `${selectedStudentIds.length} students`;
        break;
    }

    return <MultiSelect.Trigger>{label}</MultiSelect.Trigger>;
  };

  const {
    initialStudentIds,
    renderTrigger = defaultRenderTrigger,
    searchEmptyState = 'No students found.',
    students,
    onChange,
    onClose,
    testHook,
    ...otherProps
  } = props;

  const options: $ReadOnlyArray<Option> = useMemo<$ReadOnlyArray<Option>>(
    () =>
      students.map((student) => {
        const { id: studentId, firstName, lastName, email } = student;

        const studentFullName = userFullName(firstName, lastName);
        const studentDisplayName = studentFullName ?? email;
        const studentIsSelected = initialStudentIds ? initialStudentIds.includes(studentId) : true;
        // this allows us to search by name AND email
        const studentValue = `${studentFullName ?? ''} ${email}`;

        return {
          isSelected: studentIsSelected,
          label: <StudentOptionLabel label={studentDisplayName} student={student} />,
          studentId,
          triggerLabel: studentDisplayName,
          value: studentValue,
        };
      }),
    [students, initialStudentIds]
  );

  const [selectedStudentIds, setSelectedStudentIds] = useState<$ReadOnlyArray<string>>(
    options.filter((o) => o.isSelected).map((o) => o.studentId)
  );

  const renderTriggerCallback = useCallback<RenderTriggerFunction>(renderTrigger, [
    students,
    renderTrigger,
    selectedStudentIds,
  ]);

  const trigger = useMemo<ExpectedTriggerType>(
    () => renderTriggerCallback(options, selectedStudentIds),
    [renderTriggerCallback, options, selectedStudentIds]
  );

  const multiSelectOnChange = useCallback<(selectedValues: $ReadOnlyArray<Option>) => void>(
    (selectedValues) => {
      const studentIds = selectedValues.map((option) => option.studentId);
      setSelectedStudentIds(studentIds);
      onChange(studentIds);
    },
    [onChange, setSelectedStudentIds]
  );

  const multiSelectOnClose = useCallback<(selectedValues: $ReadOnlyArray<Option>) => void>(
    (selectedValues) => {
      if (onClose) {
        onClose(selectedValues.map((option) => option.studentId));
      }
    },
    [onClose]
  );

  return (
    <MultiSelect
      {...otherProps}
      ariaLabel="Select students"
      doneLabel="Done"
      trigger={trigger}
      checkAllLabel={<OptionLabel label="All students" />}
      searchPlaceholder="Search students"
      searchEmptyState={searchEmptyState}
      uncheckAllLabel="Clear all"
      options={options}
      onChange={multiSelectOnChange}
      onClose={multiSelectOnClose}
      testHook={testHook}
    />
  );
}
