// @flow
import _ from 'lodash';
import { Box, Heading, Skeleton, Stack, Text, useMediaQuery } from '@getatomi/neon';

type HeaderProps = {
  heading: string,
  subheading: string,
};

function HeaderLayout(props: { heading: string, subheading: React.Node }) {
  const { heading, subheading } = props;
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  return (
    <Box as="header" textAlign="center">
      <Stack spacing="spacingSmall1X">
        <Heading as="h1" variant={isMobile ? 'large' : 'large1X'}>
          {heading}
        </Heading>
        {subheading}
      </Stack>
    </Box>
  );
}

export function HeaderLoader(props: { heading: string }) {
  const { heading } = props;

  return (
    <HeaderLayout
      heading={heading}
      subheading={
        <Skeleton
          height={29}
          width={`${_.random(20, 30)}%`}
          animation="wave"
          marginInline="auto"
          testHook="loader-subheading"
        />
      }
    />
  );
}

export default function Header(props: HeaderProps) {
  const { heading, subheading } = props;

  return (
    <HeaderLayout
      heading={heading}
      subheading={
        <Text as="p" variant="lead">
          {subheading}
        </Text>
      }
    />
  );
}
