// @flow
import { ActionBar as NeonActionBar } from '@getatomi/neon';

import useIntercomWidget from 'src/hooks/useIntercomWidget';

type ActionBarProps = {|
  children: React.Node,
  meta?: React.Node,
  onClearSelection: () => void,
  onOpenChange?: (isOpen: boolean) => void,
  selectedItemCount: number,
  variant?: string,
|};

export default function ActionBar(props: ActionBarProps) {
  const { onOpenChange, ...otherProps } = props;
  const [showIntercom, hideIntercom] = useIntercomWidget({ showOnUnmount: true });

  return (
    <NeonActionBar
      {...otherProps}
      onOpenChange={(isOpen) => {
        if (isOpen) {
          hideIntercom();
        } else {
          showIntercom();
        }
        if (onOpenChange) {
          onOpenChange(isOpen);
        }
      }}
    />
  );
}
