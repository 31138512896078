// @flow
import { useMediaQuery } from '@getatomi/neon';

import type { ButtonSizesType } from 'src/types';
import RequestCallback from 'src/components/RequestCallback/RequestCallback';
import SubscriptionLink from 'src/components/SubscriptionLink/SubscriptionLink';
import UpgradeCtaComponent from 'src/components/UpgradeCta/UpgradeCta';

import styles from './UpgradeCta.module.scss';

export type UpgradeCtaProps = {
  insertAfter?: React.Node,
  isChild?: boolean,
  isRetail?: boolean,
  onNavigate?: () => mixed,
  size?: ButtonSizesType,
  testHook?: string,
};

export default function UpgradeCta(props: UpgradeCtaProps) {
  const { isRetail = true, isChild = false, onNavigate, insertAfter, size, testHook } = props;

  const isWideScreen = useMediaQuery({ minWidth: 'breakpointLarge1XFrom' });

  return isRetail && !isChild ? (
    <SubscriptionLink upgrade>
      {(to) => (
        <UpgradeCtaComponent
          to={to}
          onClick={onNavigate}
          buttonLabel="Upgrade"
          buttonSize={size}
          insertAfter={isWideScreen ? insertAfter : undefined}
          testHook={testHook}
        />
      )}
    </SubscriptionLink>
  ) : (
    <RequestCallback className={styles.root}>
      {(onClick) => (
        <UpgradeCtaComponent
          onClick={onClick}
          buttonLabel={isChild ? 'Ask to upgrade' : 'Upgrade'}
          buttonSize={size}
          insertAfter={insertAfter}
          testHook={testHook}
        />
      )}
    </RequestCallback>
  );
}
