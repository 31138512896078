// @flow
import { useMemo } from 'react';
import { Heading, HideVisually, Skeleton, Stack, TextLoader, useMediaQuery } from '@getatomi/neon';

import useSortedStudents from 'src/hooks/useSortedStudents';

import type { ClassInsights } from './classInsightsTransformer';
import prepareStudentTableColumns from './prepareStudentTableColumns';
import Highlights from './Highlights';
import RankedTopics from './RankedTopics';
import TasksInsights from './TasksInsights';
import StudentTable from './StudentTable';

type Props = {|
  accountId: string,
  classId: string,
  preparedData: ClassInsights,
|};

export function InsightsHeading() {
  return (
    <HideVisually>
      <Heading as="h2">Class insights</Heading>
    </HideVisually>
  );
}

export function InsightsViewLoader() {
  return (
    <Stack spacing="spacingLarge7X" testHook="class-insights-loader">
      <InsightsHeading />

      <Stack spacing="spacingLarge5X">
        <TextLoader height="30px" width="160px" />
        <Skeleton animation="wave" width="sizefull" height="200px" />
      </Stack>

      <Stack spacing="spacingLarge5X">
        <TextLoader height="30px" width="160px" />
        <Skeleton animation="wave" width="sizefull" height="200px" />
      </Stack>
    </Stack>
  );
}

export default function InsightsView(props: Props) {
  const { accountId, classId, preparedData } = props;
  const { highlights, students, rankedTopics, taskProgress } = preparedData;

  const taskDialogProps = { accountId, classId };

  const isMobile = useMediaQuery({ maxWidth: 'breakpointSmall' });
  const { sortedData, setSortBy } = useSortedStudents(students);
  const columns = useMemo(() => prepareStudentTableColumns(isMobile), [isMobile]);

  return (
    <Stack spacing="spacingLarge7X">
      <InsightsHeading />

      {highlights.length > 0 && <Highlights heading="Highlights" highlights={highlights} />}

      {taskProgress && <TasksInsights taskProgress={taskProgress} />}
      <RankedTopics
        accountId={accountId}
        classId={classId}
        rankedTopics={rankedTopics}
        totalStudents={students.length}
      />
      <StudentTable columns={columns} data={sortedData} taskDialogProps={taskDialogProps} toggleSortBy={setSortBy} />
    </Stack>
  );
}
