// @flow
import { forwardRef } from 'react';
import { OptionGroup, OptionGroupItem } from '@getatomi/neon';

import type { ContentTypeFilter } from './types';
import { contentTypes } from './constants';
import { disabledProps } from './utils';

type SelectContentProps = {
  contentError?: ?string,
  hasCovered: boolean,
  isTeacher?: boolean,
  onChange: (value: ContentTypeFilter) => mixed,
  value: ContentTypeFilter,
};

export default forwardRef<SelectContentProps, React.Node>(function SelectContent(props: SelectContentProps, ref) {
  const { contentError, value, onChange, hasCovered, isTeacher = false } = props;

  const errorProps = contentError
    ? {
        errorVariant: 'error',
        validationText: contentError,
      }
    : undefined;

  return (
    <OptionGroup
      selectionMode="single"
      label="Content"
      value={value}
      onChange={(newValue) => onChange(newValue)}
      ref={ref}
      testHook="revision-dialog-content-type-group"
      {...errorProps}
    >
      <OptionGroupItem value={contentTypes.all} testHook="revision-dialog-content-type-all">
        All the topics in the subject
      </OptionGroupItem>
      {!isTeacher && (
        <OptionGroupItem
          value={contentTypes.covered}
          {...disabledProps(!hasCovered, `You’ve not completed any lessons on Atomi in this subject yet.`)}
          testHook="revision-dialog-content-type-covered"
        >
          Only the topics I’ve covered
        </OptionGroupItem>
      )}
      <OptionGroupItem value={contentTypes.custom} testHook="revision-dialog-content-type-custom">
        Select the topics myself
      </OptionGroupItem>
    </OptionGroup>
  );
});
