// @flow
import { useState } from 'react';

import RequestCallbackDialog from 'src/components/RequestCallbackDialog/RequestCallbackDialog';
import UpgradeStudentDialog from 'src/components/UpgradeStudentDialog/UpgradeStudentDialog';
import { getUpgradeUrl } from 'src/utils/routes';

type Props = {
  shouldRequestUpgrade?: boolean,
  subscriptionId?: number,
};

export default function useUpgradePrompt(props: Props = {}) {
  const [isRequestCallbackOpen, setIsRequestCallbackOpen] = useState(false);
  const [upgradeStudentDialogId, setUpgradeStudentDialogId] = useState(null);

  const upgradeUrl =
    props.shouldRequestUpgrade || !props.subscriptionId ? undefined : getUpgradeUrl(props.subscriptionId);

  const onClose = () => {
    setIsRequestCallbackOpen(false);
    setUpgradeStudentDialogId(null);
  };
  const modal = (
    <>
      {isRequestCallbackOpen && <RequestCallbackDialog isOpen onClose={onClose} />}
      {upgradeStudentDialogId && (
        <UpgradeStudentDialog subscriptionId={upgradeStudentDialogId} isOpen onClose={onClose} />
      )}
    </>
  );
  const createShowModal =
    ({ shouldRequestUpgrade, subscriptionId }: Props = props) =>
    () =>
      shouldRequestUpgrade ? setIsRequestCallbackOpen(true) : setUpgradeStudentDialogId(subscriptionId);

  return [modal, createShowModal, upgradeUrl];
}
