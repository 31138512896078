// @flow
import { useEffect, useRef } from 'react';
import { EmptyState, Illustration } from '@getatomi/neon';
import type { ApolloError } from '@apollo/client';
import keyMirror from 'keymirror';
import _ from 'lodash';

import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';

// http status code for which we allow custom message
const errorCodes = keyMirror({
  '403': null,
  '404': null,
  NOT_FOUND: null,
});

type Props = {
  customMessages?: {
    [errorCode: $Keys<typeof errorCodes>]: {
      description: string,
      heading?: string,
    },
  },
  description?: string, // eslint-disable-line react/no-unused-prop-types
  error: ApolloError,
  heading?: string, // eslint-disable-line react/no-unused-prop-types
  isValidation?: boolean,
  withIllustration?: boolean,
};

const findFirstHandledError = ({ graphQLErrors }: ApolloError) => {
  return graphQLErrors.find(({ extensions }) => {
    const status = extensions?.response?.status ?? extensions?.code;
    return status && Object.keys(errorCodes).includes(status.toString());
  });
};

const findValidationErrors = ({ graphQLErrors }: ApolloError): Array<string> => {
  const NETWORK_ERROR = 'A network error occurred. Please try again.';
  const UNKNOWN_ERROR = 'An unknown error occurred. Please try again.';

  if (graphQLErrors.length === 0) return [NETWORK_ERROR];

  const validationErrors = graphQLErrors.reduce((acc, error) => {
    const status = error.extensions?.response?.status;
    const e = error.extensions?.response?.body?.errors ?? [];

    if (+status === 422) {
      return [...acc, ..._.flatten(_.values(e))];
    }

    return acc;
  }, []);

  return validationErrors.length > 0 ? validationErrors : [UNKNOWN_ERROR];
};

export default function GraphQLError(props: Props) {
  const { error, customMessages, withIllustration = true, isValidation } = props;
  const validationRef = useRef<null | HTMLDivElement>(null);
  const scrollOffset = 20; // this allows some spacing above the error when it scrolls to it

  useEffect(() => {
    if (validationRef.current) {
      validationRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [error]);

  if (isValidation) {
    return (
      <div ref={validationRef} style={{ marginTop: -scrollOffset, paddingTop: scrollOffset }}>
        <ValidationErrors errors={findValidationErrors(error)} />
      </div>
    );
  }

  const graphQLError = findFirstHandledError(error);
  const { description = 'Something went wrong on our end.', heading = `We’re sorry!` } =
    (graphQLError &&
      customMessages &&
      customMessages[graphQLError.extensions?.response?.status ?? graphQLError.extensions.code]) ||
    props;

  return (
    <EmptyState
      media={withIllustration ? <Illustration name="main-browser" /> : undefined}
      heading={heading}
      headingProps={{ as: 'h1' }}
      description={description}
      primaryAction={
        graphQLError ? (
          <Link href="/">Take me back to the home page</Link>
        ) : (
          <Button onClick={() => window.location.reload()}>Retry</Button>
        )
      }
    />
  );
}
