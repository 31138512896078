// @flow
import keyMirror from 'keymirror';

// List of all lesson types
export default keyMirror({
  CHALLENGE: null,
  TEXT: null,
  VIDEO: null,
  REVISION: null,
});
