// @flow
import Helmet from 'react-helmet';
import { Box, Container, Divider, Heading, Stack } from '@getatomi/neon';

import DeletedClasses from './DeletedClasses/DeletedClasses';
import PromoteUser from './PromoteUser/PromoteUser';

export default function Admin() {
  return (
    <Container>
      <Helmet>
        <title>Admin</title>
      </Helmet>

      <Stack spacing="spacingLarge3X">
        <Box textAlign="center">
          <Heading as="h1">Atomi admin</Heading>
        </Box>

        <Divider />
        <DeletedClasses />
        <Divider />
        <PromoteUser />
      </Stack>
    </Container>
  );
}
