// @flow
import keyMirror from 'keymirror';

// subscriptionStatuses represents the status of the entire subscription

// it's returned from the subscription.status field in the redux store and
// isn't currently available in the federated graph
export default keyMirror({
  in_trial: null,
  active: null,
  non_renewing: null,
  future: null,
  cancelled: null,
});
