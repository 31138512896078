// @flow
import { useMutation } from '@apollo/client';
import moment from 'moment';

import type {
  CompleteAssessmentLesson,
  CompleteAssessmentLessonVariables,
} from 'src/graphql/types/generated/CompleteAssessmentLesson';
import type { CompleteLesson, CompleteLessonVariables } from 'src/graphql/types/generated/CompleteLesson';
import COMPLETE_ASSESSMENT_LESSON from 'src/graphql/mutations/CompleteAssessmentLesson.graphql';
import COMPLETE_LESSON from 'src/graphql/mutations/CompleteLesson.graphql';
import Logger from 'src/utils/Logger';
import contextTypes from 'src/constants/contextTypes';
import lessonTypes from 'src/constants/lessonTypes';

type UseCompletionProps = {|
  accountId: number,
  classId: number,
  lessonId: string,
  lessonType: $Keys<typeof lessonTypes>,
  subjectCode: string,
|};

type UseCompletionType = {|
  completeAssessment: (mark: number) => Promise<any>,
  completeLesson: () => Promise<any>,
  restartAssessment: () => void,
  start: () => void,
|};

const log = new Logger('src/hooks/completion/useCompletion.js');

export default function useCompletion({
  accountId,
  classId,
  lessonId,
  lessonType,
  subjectCode,
}: UseCompletionProps): UseCompletionType {
  const [completeLessonMutation] = useMutation<CompleteLesson, CompleteLessonVariables>(COMPLETE_LESSON, {
    ignoreResults: true,
  });
  const [completeAssessmentLessonMutation] = useMutation<CompleteAssessmentLesson, CompleteAssessmentLessonVariables>(
    COMPLETE_ASSESSMENT_LESSON,
    { ignoreResults: true }
  );
  let startedAt: moment$Moment | null = null;

  const start = () => {
    if (startedAt != null) {
      log.warn(`lesson #${lessonId} has already been started at ${startedAt.toISOString()}`);
      return;
    }

    startedAt = moment.utc();
    log.info(`lesson has been started at ${startedAt.toISOString()}`);
  };

  const restartAssessment = () => {
    startedAt = moment.utc();
    log.info(`Assessment lesson has been re-started at ${startedAt.toISOString()}`);
  };

  const completeLesson = async () => {
    if (startedAt == null) {
      throw Error(`"startLesson" needs to be called before "completeLesson" for lesson #${lessonId}`);
    }

    const startedAtTime = startedAt.toISOString();
    const completedAtTime = moment.utc().toISOString();

    const variables: CompleteLessonVariables = {
      input: {
        accountId: accountId.toString(),
        completedAt: completedAtTime,
        contextId: classId.toString(),
        contextType: contextTypes.CLASS,
        lessonId: lessonId.toString(),
        lessonType,
        startedAt: startedAtTime,
        subjectCode,
      },
    };

    try {
      await completeLessonMutation({ variables });
    } catch (error) {
      log.error('Error setting the lesson as completed', error);
    }
  };

  const completeAssessment = async (mark: number) => {
    if (startedAt == null) {
      throw Error(`"startLesson" needs to be called before "completeAssessment" for lesson #${lessonId}`);
    }

    if (mark == null) {
      throw Error(`"mark" is required to complete an assessment #${lessonId}`);
    }

    const startedAtTime = startedAt.toISOString();
    const completedAtTime = moment.utc().toISOString();

    const variables: CompleteAssessmentLessonVariables = {
      input: {
        accountId: accountId.toString(),
        completedAt: completedAtTime,
        contextId: classId.toString(),
        contextType: contextTypes.CLASS,
        lessonId: lessonId.toString(),
        lessonType,
        startedAt: startedAtTime,
        subjectCode,
        mark,
      },
    };

    try {
      await completeAssessmentLessonMutation({ variables });
    } catch (error) {
      log.error('Error setting the assessment as completed', error);
    }
  };

  return { completeLesson, completeAssessment, restartAssessment, start };
}
