// @flow
import Logger from 'src/utils/Logger';

const log = new Logger('components/PaymentTypeIcon');

export type PaymentTypeIconProps = {|
  name: string,
|};

export default function PaymentTypeIcon(props: PaymentTypeIconProps) {
  const { name } = props;

  try {
    const src = require(`src/assets/images/payment-icons/${name}.svg`);
    return <img src={src} width="48" alt={`${name} logo`} />;
  } catch (e) {
    log.warn(e);
    return null;
  }
}
