// @flow
import { Box, HelpInfo, KebabList } from '@getatomi/neon';
import _ from 'lodash';

import ButtonAdd from 'src/components/ButtonAdd/ButtonAdd';
import Link from 'src/components/Link/Link';
import links from 'src/constants/links';
import type { TaskActions } from 'src/domains/Tasks/ClassTasks/utils/getTaskActionsById';
import getSubjectColors from 'src/utils/getSubjectColors';

import type { ClassTask, ClassTaskContent } from './useGetClassTasks';
import { groupClassTasks } from '../utils/groupTasks';
import useTaskListAlerts from './useTaskListAlerts';
import useClassTaskListFilters, { type TaskFilterStatus, type TaskFilters } from './useClassTaskListFilters';
import ClassTasksListEmptyState from './ClassTasksListEmptyState';
import ClassTasksListCard from './ClassTasksListCard';

type ClassTasksListPops = {|
  filters: TaskFilters,
  getTaskActions: (id: string) => TaskActions,
  onStatusFilterChange: (status: TaskFilterStatus) => mixed,
  onStudentCountClick: (content: ClassTaskContent) => mixed,
  openTaskDialog: () => void,
  pathname: string,
  studentCount: number,
  taskProgressReportUrlFactory: (taskId: string) => string,
  tasksData: $ReadOnlyArray<ClassTask>,
  totalTaskCount: number,
|};

export default function ClassTasksList(props: ClassTasksListPops) {
  const {
    tasksData,
    getTaskActions,
    openTaskDialog,
    studentCount,
    onStudentCountClick,
    pathname,
    onStatusFilterChange,
    filters,
    taskProgressReportUrlFactory,
    totalTaskCount,
  } = props;

  const alert = useTaskListAlerts({
    filteredTaskCount: tasksData.length,
    filters,
    pathname,
  });
  const [taskListFilters, clearFilters] = useClassTaskListFilters({
    filters,
    onStatusFilterChange,
  });

  const sections = groupClassTasks(tasksData);

  const helpInfo = (
    <Link href={links.support.creatingTasks} isExternal variant="monochrome">
      Need help creating tasks?
    </Link>
  );

  const buttonAddTask = (
    <ButtonAdd onClick={openTaskDialog} assistiveText="Create task" testHook="task-list-create-task-button">
      Create task
    </ButtonAdd>
  );

  if (alert && _.isEmpty(tasksData)) return <KebabList alert={alert} filters={taskListFilters} cta={buttonAddTask} />;

  if (_.isEmpty(sections))
    return (
      <ClassTasksListEmptyState
        filters={taskListFilters}
        buttonAddTask={buttonAddTask}
        totalTaskCount={totalTaskCount}
        onClearFilters={clearFilters}
        openTaskDialog={openTaskDialog}
        helpInfo={helpInfo}
      />
    );

  return (
    <>
      <KebabList alert={alert} filters={taskListFilters} cta={buttonAddTask}>
        {Object.keys(sections).map((sectionName) => {
          return (
            <KebabList.Section key={sectionName} title={sectionName} testHook={`task-list-section:${sectionName}`}>
              {sections[sectionName].map((task) => {
                const subjectColorRange = getSubjectColors(task.subject.groupCode, task.subject.color);

                return (
                  <ClassTasksListCard
                    key={task.id}
                    // All KebabList.Section children must have a `colors` prop,
                    // so we have to define this on the ClassTasksListCard
                    colors={subjectColorRange}
                    getTaskActions={getTaskActions}
                    onStudentCountClick={onStudentCountClick}
                    studentCount={studentCount}
                    task={task}
                    taskProgressReportUrlFactory={taskProgressReportUrlFactory}
                  />
                );
              })}
            </KebabList.Section>
          );
        })}
      </KebabList>
      <Box marginTop="spacingLarge4X">
        <HelpInfo>{helpInfo}</HelpInfo>
      </Box>
    </>
  );
}
