// @flow
import { useMemo } from 'react';
import { Box, Flex, Heading, Skeleton, Stack, Text, TextLoader, useMediaQuery } from '@getatomi/neon';
import Helmet from 'react-helmet';

import type { NewAttachedContent } from 'src/domains//Tasks/types';
import BackLink from 'src/components/BackLink/BackLink';
import ButtonAdd from 'src/components/ButtonAdd/ButtonAdd';
import useSortedStudents from 'src/hooks/useSortedStudents';
import useTaskDialog from 'src/components/useTaskDialog/useTaskDialog';
import useTeacherRevisionDialog from 'src/components/useTeacherRevisionDialog/useTeacherRevisionDialog';
import { getClassInsightsUrl } from 'src/utils/routes';

import prepareStudentTableColumns from '../prepareStudentTableColumns';
import Highlights from '../Highlights';
import StudentTable from '../StudentTable';
import type { TopicInsightsData } from './topicInsightsTransformer';
import Mosaic from './Mosaic';
import MostEngagedContent from './MostEngagedContent';
import ContentSpotlight from '../ContentSpotlight';

type Props = {
  accountId: string,
  classId: string,
  preparedData: TopicInsightsData,
  topicId: string,
};

export function TopicInsightsViewLoader() {
  return (
    <Box testHook="topic-insights-loader">
      <Box marginBottom="spacingLarge1X">
        <TextLoader height="30px" width="120px" />
      </Box>
      <Stack spacing="spacingLarge7X">
        <TextLoader height="30px" width="250px" />

        <Stack spacing="spacingLarge5X">
          <Skeleton animation="wave" width="sizefull" height="200px" />
        </Stack>

        <Stack spacing="spacingLarge5X">
          <TextLoader height="30px" width="160px" />
          <Skeleton animation="wave" width="sizefull" height="200px" />
        </Stack>
      </Stack>
    </Box>
  );
}

export default function TopicInsightsView(props: Props) {
  const {
    accountId,
    classId,
    preparedData: {
      topicName,
      moduleName,
      students,
      marks,
      lessonsCompleted,
      assessmentsCompleted,
      mostChallenging,
      studentHighlights,
      mostEngagedContent,
    },
    topicId,
  } = props;
  const backLink = `${getClassInsightsUrl(accountId, classId)}`;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointSmall' });
  const { sortedData, setSortBy } = useSortedStudents(students);
  const columns = useMemo(() => prepareStudentTableColumns(isMobile), [isMobile]);

  const taskDialogProps = { accountId, classId };

  const [taskDialog, { openTaskDialog }] = useTaskDialog({
    subscriptionId: accountId,
    classId,
  });

  const { dialog: teacherRevisionDialog, open: openTeacherRevisionDialog } = useTeacherRevisionDialog({
    onCreate: (newRevision: NewAttachedContent) =>
      openTaskDialog({
        contents: [newRevision],
      }),
  });

  return (
    <>
      <Helmet>
        <title>{topicName} | Class insights</title>
      </Helmet>

      <Flex justifyContent="space-between" marginBottom="spacingLarge1X">
        <BackLink to={backLink}>Back to insights</BackLink>

        <ButtonAdd
          assistiveText="Create revision"
          onClick={() => openTeacherRevisionDialog({ selectedTopicIds: [topicId] })}
        >
          Create revision
        </ButtonAdd>
      </Flex>

      <Stack spacing="spacingLarge7X">
        <Heading as="h2" variant="large">
          {topicName}{' '}
          <Text fontWeight="fontWeightRegular" marginTop="spacingSmall1X" variant="bodySmall1X">
            in {moduleName}
          </Text>
        </Heading>

        <Mosaic marks={marks} lessonsCompleted={lessonsCompleted} assessmentsCompleted={assessmentsCompleted} />

        {studentHighlights.length > 0 && <Highlights heading="Student spotlight" highlights={studentHighlights} />}

        <MostEngagedContent accountId={accountId} classId={classId} mostEngagedContent={mostEngagedContent} />

        <ContentSpotlight
          heading="Most challenging quizzes"
          accountId={accountId}
          classId={classId}
          items={mostChallenging}
        />

        <StudentTable columns={columns} data={sortedData} taskDialogProps={taskDialogProps} toggleSortBy={setSortBy} />
      </Stack>

      {teacherRevisionDialog}

      {taskDialog}
    </>
  );
}
