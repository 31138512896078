// @flow
import keyMirror from 'keymirror';

export const roleTypes = keyMirror({
  student: null,
  teacher: null,
  account_administrator: null,
  family_account_owner: null,
  account_owner: null,
  account_manager: null,
});

const roles = Object.freeze({
  student: roleTypes.student,
  teacher: roleTypes.teacher,
  accountAdmin: roleTypes.account_administrator,
  familyAccountOwner: roleTypes.family_account_owner,
  accountOwner: roleTypes.account_owner,
  accountManager: roleTypes.account_manager,
});

export default roles;

export const rolesWithRank = [
  { role: roles.student, rank: 0 },
  { role: roles.teacher, rank: 1 },
  { role: roles.accountAdmin, rank: 2 },
  { role: roles.familyAccountOwner, rank: 3 },
  { role: roles.accountOwner, rank: 3 },
  { role: roles.accountManager, rank: 4 },
];

export const rolesLabels = Object.freeze({
  [roles.student]: 'Student',
  [roles.teacher]: 'Teacher',
  [roles.accountAdmin]: 'Admin',
  [roles.familyAccountOwner]: 'Parent',
  [roles.accountOwner]: 'Owner',
  [roles.accountManager]: 'Account Manager',
});

export const indefiniteRoleLabels = Object.freeze({
  [roles.teacher]: `a ${rolesLabels[roles.teacher]}`,
  [roles.student]: `a ${rolesLabels[roles.student]}`,
  [roles.accountAdmin]: `an ${rolesLabels[roles.accountAdmin]}`,
  [roles.familyAccountOwner]: `an ${rolesLabels[roles.familyAccountOwner]}`,
  [roles.accountOwner]: `an ${rolesLabels[roles.accountOwner]}`,
  [roles.accountManager]: `an ${rolesLabels[roles.accountManager]}`,
});

export const rolesPaths = Object.freeze({
  [roles.student]: 'student',
  [roles.teacher]: 'teacher',
  [roles.familyAccountOwner]: 'parent',
});
