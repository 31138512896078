// @flow

import Helmet from 'react-helmet';
import { Container } from '@getatomi/neon';
import invariant from 'invariant';

import { ChallengeStartLoader } from 'src/components/ChallengeStart/ChallengeStart';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';

import RevisionChallenge from './RevisionChallenge/RevisionChallenge';
import { useGetRevision } from './useGetRevision';

export type Params = {
  classId: string,
  revisionId: string,
  splat: string | void,
  subscriptionId: string,
};

export type Props = {|
  params: Params,
|};

export function RevisionQuery(props: Props) {
  const { params } = props;
  const { classId, revisionId, subscriptionId } = params;

  const { data, loading, error } = useGetRevision({
    accountId: subscriptionId,
    classId,
    revisionId,
  });

  if (loading) {
    return <ChallengeStartLoader />;
  }

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load your revision." />;
  }

  invariant(data, 'Revision data should be defined.');

  const { isTeacher, title, userClass, duration, questionsCount, totalMarks, timeLimitInSeconds } = data;

  // Our current route structure includes an optional "splat" parameter on the end:
  // classes/:classId/revisions/:revisionId(/**)
  // When viewing a revision we use a splat value of "results" to indicate
  // that we want to show the revision results page rather than the revision start page.
  const hasResultsSplat = params.splat === 'results';

  return (
    <Container>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <RevisionChallenge
        accountId={subscriptionId}
        challengeId={revisionId}
        class={userClass}
        duration={duration}
        isTeacher={isTeacher}
        questionsCount={questionsCount}
        timeLimitInSeconds={timeLimitInSeconds}
        title={title}
        totalMarks={totalMarks}
        view={hasResultsSplat ? 'Results' : 'Start'}
      />
    </Container>
  );
}

export function RevisionContainer(props: Props) {
  return (
    <>
      <Helmet>
        <title>Revision</title>
      </Helmet>
      <RevisionQuery {...props} />
    </>
  );
}

export default RevisionContainer;
