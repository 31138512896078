// @flow
import { useRef, useState } from 'react';

import useScrollAndFocus from 'src/hooks/useScrollAndFocus';

export type ValidationParams = {
  hasQuestionTypes: boolean,
  hasTopics: boolean,
  isTimeLimitEnabled: boolean,
  timeLimitMinutes: string,
};

function isValidMinutes(value: string): boolean {
  const positiveInteger = /^[1-9][0-9]*$/;
  return positiveInteger.test(value.trim());
}

export default function useRevisionDialogValidation(): {
  clearValidationErrors: () => void,
  contentError: ?string,
  contentFieldRef: any,
  questionTypeError: ?string,
  questionTypesFieldRef: any,
  timeLimitError: ?string,
  timeLimitFieldRef: any,
  validateOnSubmit: (validationParams: ValidationParams) => boolean,
} {
  const [contentError, setContentError] = useState<?string>(null);
  const [questionTypeError, setQuestionTypeError] = useState<?string>(null);
  const [timeLimitError, setTimeLimitError] = useState<?string>(null);

  const contentFieldRef = useRef();
  useScrollAndFocus({
    shouldTrigger: contentError != null,
    ref: contentFieldRef,
  });

  const questionTypesFieldRef = useRef();
  useScrollAndFocus({
    shouldTrigger: questionTypeError != null && contentError == null,
    ref: questionTypesFieldRef,
  });

  const timeLimitFieldRef = useRef();
  useScrollAndFocus({
    shouldTrigger: timeLimitError != null && questionTypeError == null && contentError == null,
    ref: timeLimitFieldRef,
  });

  const clearValidationErrors = () => {
    setContentError(null);
    setQuestionTypeError(null);
    setTimeLimitError(null);
  };

  const validateOnSubmit = (validationParams: ValidationParams) => {
    const { hasQuestionTypes, hasTopics, isTimeLimitEnabled, timeLimitMinutes } = validationParams;

    clearValidationErrors();

    const hasValidTimeLimit = !isTimeLimitEnabled || (isTimeLimitEnabled && isValidMinutes(timeLimitMinutes));

    if (hasTopics && hasQuestionTypes && hasValidTimeLimit) {
      return false;
    }

    if (!hasTopics) {
      setContentError('You need to select at least one topic to create a revision.');
    }
    if (!hasQuestionTypes) {
      setQuestionTypeError('You need to select at least one question type to create a revision.');
    }
    if (!hasValidTimeLimit) {
      setTimeLimitError('Please nominate a time limit in minutes.');
    }
    return true;
  };

  return {
    clearValidationErrors,
    contentError,
    contentFieldRef,
    questionTypeError,
    questionTypesFieldRef,
    timeLimitError,
    timeLimitFieldRef,
    validateOnSubmit,
  };
}
