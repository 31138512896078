// @flow
import { Box, Flex, Text, Truncate } from '@getatomi/neon';

import ReduxAvatarContainer from 'src/components/ReduxAvatarContainer/ReduxAvatarContainer';
import UserName from 'src/components/UserName/UserName';
import type { UserType } from 'src/types';

export type ActionMenuUserHeaderProps = {|
  user: UserType,
|};

/**
 * A header to display user information in the ActionMenu header.
 */
export default function ActionMenuUserHeader(props: ActionMenuUserHeaderProps) {
  const { user } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="spacingRoot"
      padding="spacingRoot"
      borderBottomWidth="borderWidthRoot"
      borderBottomStyle="solid"
      borderBottomColor="colorBorder"
    >
      <ReduxAvatarContainer user={user} />
      <Flex direction="column" grow={1} minWidth="0">
        <Text as="span" variant="bodySmall" lineHeight="lineHeightSmall2X">
          <UserName firstName={user.first_name} lastName={user.last_name} />
        </Text>
        <Text as="span" variant="bodySmall1X" color="colorTextSubtler">
          <Truncate>{user.email}</Truncate>
        </Text>
      </Flex>
    </Box>
  );
}
