// @flow
import { useMemo, useState } from 'react';
import { useMediaQuery } from '@getatomi/neon';

import useSortedStudents from 'src/hooks/useSortedStudents';

import type { ClassAverageRow, MarksData, TransformedMarksData, Module as MarksColumn } from './marksTransformer';
import MarkbookTable from '../components/MarkbookTable';
import prepareMarksColumns from './prepareMarksColumns';
import MarkbookControls from '../components/MarkbookControls';

type Props = {
  expandedModuleState: $Call<typeof useState>,
  expandedTopicState: $Call<typeof useState>,
  params: { classId: string, subscriptionId: string },
  preparedData: TransformedMarksData,
};

export default function MarksView(props: Props) {
  const { preparedData, params, expandedModuleState, expandedTopicState } = props;
  const { classAverageRow, marksData, className, columnList, lessonCount, isDataExportEnabled } = preparedData;
  const { classId, subscriptionId } = params;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointSmall' });

  const { sortedData: sortedMarksData, setSortBy } = useSortedStudents<MarksData>(marksData);

  const marksColumns = useMemo(
    () =>
      prepareMarksColumns({
        isMobile,
        columnList,
        lessonCount,
        expandedModuleState,
        expandedTopicState,
        classId,
      }),
    [isMobile, columnList, lessonCount, expandedModuleState, expandedTopicState, classId]
  );

  const rowData = useMemo(
    (): $ReadOnlyArray<ClassAverageRow | MarksData> => [classAverageRow, ...sortedMarksData],
    [classAverageRow, sortedMarksData]
  );

  const downloadColumns: $ReadOnlyArray<MarksColumn> = [
    {
      id: 'overall',
      categoryCount: 0,
      lessonCount,
      name: 'Overall completion',
    },
    ...columnList,
  ];

  return (
    <>
      <MarkbookControls
        activeLink="marks"
        className={className}
        params={params}
        columnList={downloadColumns}
        tableData={rowData}
        isDataExportEnabled={isDataExportEnabled}
      />
      <MarkbookTable
        isMobile={isMobile}
        caption="The list of students and their marks."
        columns={marksColumns}
        subscriptionId={subscriptionId}
        classId={classId}
        data={rowData}
        isSortable
        toggleSortBy={setSortBy}
      />
    </>
  );
}
