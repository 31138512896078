// @flow
import { useCallback, useState, useMemo } from 'react';
import { Box, Flex, SelectTrigger, StatusLight, Truncate, tokens } from '@getatomi/neon';

import type { GetInsightsFilters_me_account_metrics_insights_Insight_classes as ClassInfo } from 'src/graphql/types/generated/GetInsightsFilters';
import MultiSelect from 'src/components/MultiSelect/MultiSelect';

function ClassLabel(props: { color: string, isDeleted?: boolean, label: string }) {
  const { color, isDeleted, label } = props;

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      gap="spacingSmall5X"
      maxWidth="sizeFull"
      color={isDeleted ? 'colorTextSubtler' : 'colorText'}
    >
      <StatusLight color={isDeleted ? 'colorIconDisabled' : color} />
      <Flex marginRight="spacingLarge2X" minWidth="sizeNone">
        <Truncate>{label}</Truncate>
        {isDeleted && (
          <Box as="span" marginLeft="spacingSmall2X">
            (Deleted)
          </Box>
        )}
      </Flex>
    </Box>
  );
}

type Props = {
  classes: $ReadOnlyArray<ClassInfo>,
  onChange: (values: Array<ClassInfo>) => mixed,
  testHook: string,
};

export default function ClassPicker(props: Props) {
  const { classes, onChange, testHook, ...otherProps } = props;
  const getTrigger = useCallback(
    (values) => {
      const defaultText = 'All classes';
      if (values.length === 0 || values.length === classes.length) return defaultText;
      if (values.length === 1) return values[0].class.label;
      return `${values.length} classes`;
    },
    [classes]
  );
  const classesWithLabels = useMemo(
    () =>
      classes.map((c) => ({
        ...c,
        label: c.name,
        // this allows us to search by class name and subject name
        value: `${c.name} ${c.subject.name}`,
      })),
    [classes]
  );
  const options = classesWithLabels.map((c) => ({
    isSelected: true,
    label: <ClassLabel label={c.label} color={c.subject.color} isDeleted={!!c.deletedAt} />,
    value: c.value,
    class: c,
  }));
  // used as a key on the MultiSelect, see setKey usage for explanation
  const [key, setKey] = useState(0);
  const [trigger, setTrigger] = useState(getTrigger(options.filter((o) => o.isSelected)));
  const multiSelectOnChange = useCallback(
    (values) => {
      setTrigger(getTrigger(values));
      // when using the picker as a filter, filtering by 0 class is the same as filtering by all
      // the classes
      const selectedClasses = values.length === classes.length ? [] : values;
      onChange(selectedClasses.map((option) => option.class));
    },
    [classes.length, getTrigger, onChange]
  );
  const multiSelectOnClose = useCallback(
    (selectedOptions) => {
      // if all the options are unchecked, and you close the multiselect, all the options
      // should be selected the next time you open the multiselect
      if (selectedOptions.length === 0) {
        setKey(key + 1);
      }
    },
    [key]
  );

  return (
    <MultiSelect
      {...otherProps}
      key={key}
      ariaLabel="Select classes"
      doneLabel="Done"
      trigger={
        <SelectTrigger variant="filter">
          <Truncate>{trigger}</Truncate>
        </SelectTrigger>
      }
      checkAllLabel={<ClassLabel color={tokens.colorBackgroundBrand} label="All classes" />}
      searchPlaceholder="Search classes"
      searchEmptyState="No classes found."
      uncheckAllLabel="Clear all"
      options={options}
      onChange={multiSelectOnChange}
      onClose={multiSelectOnClose}
      testHook={testHook}
    />
  );
}
