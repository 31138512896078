// @flow

import { getChallengeQuestionUrl, getRevisionQuestionUrl } from 'src/utils/routes';

export type GetQuestionUrl = ({ itemId: string | null, userId: string | null }) => string;

export function getChallengeQuestionUrlFactory({
  accountId,
  classId,
  moduleId,
  postId,
  returnTo,
  isTeacherReviewFeatureEnabled,
}: {|
  accountId: string,
  classId: string,
  isTeacherReviewFeatureEnabled: boolean,
  moduleId: string,
  postId: string,
  returnTo: string,
|}): GetQuestionUrl {
  return ({ itemId, userId }) => {
    return getChallengeQuestionUrl({
      accountId,
      classId,
      moduleId,
      postId,
      itemId,
      userId,
      returnTo,
      isTeacherReviewFeatureEnabled,
    });
  };
}

export function getRevisionQuestionUrlFactory({
  accountId,
  challengeId,
  classId,
  returnTo,
  isTeacherReviewFeatureEnabled,
}: {
  accountId: string,
  challengeId: string,
  classId: string,
  isTeacherReviewFeatureEnabled: boolean,
  returnTo: string,
}): GetQuestionUrl {
  return ({ itemId, userId }) => {
    return getRevisionQuestionUrl({
      accountId,
      classId,
      challengeId,
      itemId,
      userId,
      returnTo,
      isTeacherReviewFeatureEnabled,
    });
  };
}
