// @flow
import { Flex, Text, useHover } from '@getatomi/neon';
import pluralize from 'pluralize';

import { UnstyledLink } from 'src/components/Link/Link';

type TaskProgressLinkProps = {|
  completionCount: number,
  taskProgressReportUrl: string,
  totalStudents: number,
|};

export default function TaskProgressLink(props: TaskProgressLinkProps) {
  const { completionCount, totalStudents, taskProgressReportUrl } = props;

  const { hoverProps, isHovered } = useHover({});

  const completionLabel =
    completionCount === totalStudents
      ? 'All students complete'
      : `${completionCount} ${pluralize('Student', completionCount)} complete`;

  return (
    <Flex
      to={taskProgressReportUrl}
      as={UnstyledLink}
      backgroundColor={isHovered ? 'colorBackgroundHovered' : undefined}
      alignItems="center"
      borderBottomLeftRadius="borderRadiusRoot"
      borderBottomRightRadius="borderRadiusRoot"
      justifyContent="space-between"
      marginInline="spacingNegativeRoot"
      paddingInline="spacingRoot"
      paddingBlock="spacingRoot"
      {...hoverProps}
    >
      <Text variant="bodySmall1X" color="colorTextLink">
        View task progress
      </Text>

      {completionCount > 0 && (
        <Text variant="bodySmall1X" color="colorTextSubtler">
          {completionLabel}
        </Text>
      )}
    </Flex>
  );
}
