// @flow
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Box } from '@getatomi/neon';

import { loginWithSaml, loadAuthStrategies } from 'src/actions/auth';
import { UnstyledLink } from 'src/components/Link/Link';

import { useAccountMenuItems, type AccountMenuItemsProps } from '../AccountMenu/useAccountMenuItems';
import styles from './SubscriptionsList.module.scss';

type SubscriptionActionProps = {|
  children: React.Node,
  className: string,
  href?: string,
  onClick?: () => mixed,
|};

function SubscriptionAction(props: SubscriptionActionProps) {
  const { children, className, href, onClick } = props;
  const testHook = 'subscriptions-list-target';

  if (href) {
    return (
      // using `href` as we want a full page refresh when clicking the link so we can clear the subscription in the state
      <UnstyledLink href={href} className={className} testHook={testHook}>
        {children}
      </UnstyledLink>
    );
  }

  return (
    <Box as="button" type="button" onClick={onClick} className={className} testHook={testHook}>
      {children}
    </Box>
  );
}

function SubscriptionsList(props: AccountMenuItemsProps) {
  const { accountSection, handleAction } = useAccountMenuItems(props);

  if (!accountSection) {
    return null;
  }

  const accounts = accountSection.children;

  return (
    <ul className={styles.root} aria-label="Accounts">
      {accounts.map((account) => {
        return (
          <li key={account.key}>
            <SubscriptionAction onClick={() => handleAction(account.key)} href={account.href} className={styles.target}>
              {account.label}
            </SubscriptionAction>
          </li>
        );
      })}
    </ul>
  );
}

export default withCookies(
  connect(null, {
    loginWithSamlAction: loginWithSaml,
    loadAuthStrategiesAction: loadAuthStrategies,
  })(SubscriptionsList)
);
