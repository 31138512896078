// @flow
import * as React from 'react';
import {
  Box,
  Checkbox,
  Container,
  Divider,
  Flex,
  IconLock,
  Skeleton,
  Stack,
  Text,
  TextLoader,
  useHover,
} from '@getatomi/neon';

import type { ChallengeTier } from 'src/types';
import postTypes from 'src/constants/postTypes';
import { UnstyledLink } from 'src/components/Link/Link';
import PostDuration from 'src/components/PostDuration/PostDuration';
import type { EmbeddedContent } from 'src/domains/LTI/SelectLessons/useEmbedContent';

import { UnstyledButton } from '../Button/Button';

export type LessonType = {|
  challengeTier: ?ChallengeTier,
  duration: number,
  id: string,
  isLocked?: boolean,
  name: string,
  previewUrl: string,
  type: $Keys<typeof postTypes>,
|};

export type SubtopicType = {|
  lessons: $ReadOnlyArray<LessonType>,
  name: string,
|};

export type TopicType = {|
  lessons: $ReadOnlyArray<LessonType>,
  name: string,
  subtopics: $ReadOnlyArray<SubtopicType>,
|};

type CheckboxProps = {|
  isSelected: (lessonId: string) => boolean,
  onSelect: (lessonId: string) => void,
|};

type OptionalProps = {|
  checkboxProps?: CheckboxProps,
  embedContent?: (lessons: $ReadOnlyArray<EmbeddedContent>) => void,
  previewInNewTab?: boolean,
  renderAction?: (lesson: LessonType) => React.Node,
  showUpgradeModal?: () => void,
|};

type LessonProps = {|
  ...OptionalProps,
  lesson: LessonType,
|};

type LessonGroupProps = {|
  ...OptionalProps,
  topic: TopicType,
|};

type LessonListProps = {|
  ...OptionalProps,
  topics: $ReadOnlyArray<TopicType>,
|};

function Lesson(props: LessonProps) {
  const { lesson, checkboxProps, previewInNewTab = false, renderAction, showUpgradeModal } = props;
  const { id: lessonId, isLocked = false, name, duration, type, challengeTier, previewUrl } = lesson;
  const { hoverProps, isHovered } = useHover({});

  let anchorProps = {
    to: previewUrl,
  };

  if (previewInNewTab) {
    anchorProps = { href: previewUrl, isExternal: true };
  }

  if (isLocked && showUpgradeModal) {
    anchorProps = {
      as: UnstyledButton,
      onClick: () => {
        showUpgradeModal();
      },
    };
  }

  return (
    <>
      <Flex alignItems="center" gap="spacingRoot" padding="spacingLarge">
        {checkboxProps && (
          <Checkbox
            ariaLabel={`Select ${name}`}
            isSelected={checkboxProps.isSelected(lessonId)}
            isDisabled={isLocked}
            onChange={() => checkboxProps.onSelect(lessonId)}
          />
        )}

        <Flex
          as={UnstyledLink}
          direction="column"
          flex={1}
          gap="spacingSmall2X"
          justifyContent="center"
          alignItems="flex-start"
          {...anchorProps}
          {...hoverProps}
        >
          <Text
            as="span"
            lineHeight="lineHeightSmall2X"
            fontFamily="fontFamilySystem"
            fontSize="fontSizeSmall"
            fontWeight="fontWeightMedium"
            textAlign="left"
          >
            <Box as="span" textDecoration={isHovered ? 'underline' : undefined}>
              {name}
            </Box>{' '}
            {isLocked && (
              <IconLock
                color="colorIconSubtle"
                size="sizeIconSmall1X"
                testHook="lock-icon"
                display="inline-block"
                verticalAlign="bottom"
              />
            )}
          </Text>

          <Text
            as="span"
            color="colorTextSubtle"
            fontFamily="fontFamilySystem"
            fontSize="fontSizeSmall1X"
            lineHeight="lineHeightSmall2X"
          >
            <PostDuration type={type} duration={duration} {...(challengeTier && { challengeTier })} />
          </Text>
        </Flex>

        {renderAction && renderAction(lesson)}
      </Flex>
      <Divider />
    </>
  );
}

function LessonGroup(props: LessonGroupProps) {
  const { topic, ...commonLessonProps } = props;

  const hasLessonsUnderTopic = topic.lessons.length > 0;
  const hasLessonsUnderSubtopic = topic.subtopics.some((subtopic) => subtopic.lessons.length > 0);

  if (!hasLessonsUnderTopic && !hasLessonsUnderSubtopic) {
    return null;
  }

  return (
    <Box marginBlock="spacingLarge" _notFirst={{ marginTop: 'spacingLarge5X' }}>
      <Text
        as="h2"
        color="colorTextSubtler"
        fontFamily="fontFamilySystem"
        fontWeight="fontWeightSemiBold"
        fontSize="fontSizeSmall1X"
        textTransform="uppercase"
        marginInline="spacingLarge"
      >
        {topic.name}
      </Text>

      {hasLessonsUnderTopic && (
        <Stack as="ol" spacing="spacingNone">
          {topic.lessons.map((lesson) => (
            <Lesson {...commonLessonProps} key={lesson.id} lesson={lesson} />
          ))}
        </Stack>
      )}

      {topic.subtopics.map((subtopic, subtopicIndex) => {
        if (subtopic.lessons.length === 0) {
          return null;
        }
        return (
          <React.Fragment key={subtopicIndex}>
            <Text
              as="h3"
              color="colorTextSubtle"
              fontFamily="fontFamilySystem"
              fontWeight="fontWeightSemiBold"
              fontSize="fontSizeSmall1X"
              marginTop="spacingLarge2X"
              marginInline="spacingLarge"
            >
              {subtopic.name}
            </Text>

            <Stack as="ol" spacing="spacingNone">
              {subtopic.lessons.map((lesson) => (
                <Lesson {...commonLessonProps} key={lesson.id} lesson={lesson} />
              ))}
            </Stack>
          </React.Fragment>
        );
      })}
    </Box>
  );
}

export default function LessonList(props: LessonListProps) {
  const { topics, ...commonLessonProps } = props;

  return (
    <Container paddingInline="spacingNone">
      {topics.map((topic, topicIndex) => (
        <LessonGroup key={topicIndex} topic={topic} {...commonLessonProps} />
      ))}
    </Container>
  );
}

export function LessonListLoader() {
  return (
    <>
      <Flex alignItems="center" gap="spacingLarge" marginBlock="spacingLarge" marginLeft="spacingLarge">
        <Skeleton animation="wave" width={15} height={15} borderRadius="borderRadiusCircle" />

        <TextLoader height={30} width={180} />
      </Flex>

      <Stack as="ol" spacing="spacingNone">
        {Array.from({ length: 5 }).map((_loaderValue, loaderIndex) => (
          <React.Fragment key={loaderIndex}>
            <Flex alignItems="center" gap="spacingRoot" padding="spacingRoot" testHook="loading-lesson">
              <Skeleton animation="wave" width={20} height={20} />

              <Box flex={1}>
                <TextLoader width={130} height={15} marginBottom="spacingSmall2X" />
                <TextLoader width={100} height={13} />
              </Box>
            </Flex>
            <Divider />
          </React.Fragment>
        ))}
      </Stack>
    </>
  );
}
