// @flow
import { StatusLight } from '@getatomi/neon';

import taskStatuses from 'src/constants/taskStatuses';
import { Dropdown, Item, ItemLabel } from 'src/components/Dropdown/Dropdown';
import FilterGroup from 'src/components/FilterGroup/FilterGroup';

import type { TaskFilterClasses } from '../StudentTasks/useGetStudentTasks';

type CompletionStatus = $Keys<typeof taskStatuses>;
export type TaskStatus = 'current' | 'all' | 'upcoming' | 'past' | 'draft';
export type TaskFilters = {
  classId?: ?string,
  completionFilter?: ?CompletionStatus,
};

type Props = {
  classes: TaskFilterClasses,
  filters: TaskFilters,
  onClassIdChange: (classId: ?string) => mixed,
  onCompletionFilterChange: (status: ?CompletionStatus) => mixed,
};

const statusLabels = {
  [taskStatuses.incomplete]: 'Incomplete tasks',
  [taskStatuses.completed]: 'Completed tasks',
};

export default function useStudentTaskListFilters(props: Props) {
  const {
    classes,
    filters: { classId, completionFilter },
    onClassIdChange,
    onCompletionFilterChange,
  } = props;
  const isFiltering = Boolean(classId || completionFilter);

  const filters = (
    <FilterGroup>
      <Dropdown
        variant="filter"
        ariaLabel="Filter by completion status"
        items={[
          { label: 'Active tasks', value: null },
          ...Object.keys(statusLabels).map((value) => ({ label: statusLabels[value], value })),
        ]}
        selectedKey={String(completionFilter)}
        // The Dropdown is immediately unmounted when the user clicks on a filter (the loading
        // state is displayed), which causes an error when the component tries to reset the
        // focus on the trigger. This ONLY happens in Cypress. The workaround is to trigger the
        // callback at the end of the event loop:
        // https://github.com/adobe/react-spectrum/discussions/1937.
        onSelectionChange={(key) => setTimeout(() => onCompletionFilterChange(key === 'null' ? null : key), 0)}
      >
        {(item) => <Item key={item.value}>{item.label}</Item>}
      </Dropdown>
      <Dropdown
        variant="filter"
        ariaLabel="Filter by class"
        items={[
          { color: null, label: 'All classes', value: null },
          ...classes.map(({ id, subject }) => ({
            color: subject.color,
            label: subject.shortName,
            value: id,
          })),
        ]}
        selectedKey={String(classId)}
        // Trigger the callback at the end of the event loop to prevent DOM errors in e2e tests:
        // https://github.com/adobe/react-spectrum/discussions/1937.
        onSelectionChange={(key) => setTimeout(() => onClassIdChange(key === 'null' ? null : key), 0)}
      >
        {(item) => (
          <Item key={item.value} textValue={item.label}>
            {item.color && <StatusLight color={item.color} />}
            <ItemLabel>{item.label}</ItemLabel>
          </Item>
        )}
      </Dropdown>
    </FilterGroup>
  );
  const clearFilters = () => {
    onCompletionFilterChange(null);
    onClassIdChange(null);
  };

  return [isFiltering, filters, clearFilters];
}
