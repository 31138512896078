// @flow
import { useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Box, Heading } from '@getatomi/neon';

import type { ReduxState } from 'src/types';
import AddPaymentSourceDialog from 'src/components/AddPaymentSourceDialog/AddPaymentSourceDialog';
import { getAllPaymentSources } from 'src/reducers/paymentSources';
import {
  isPaymentSourceRequired as isPaymentSourceRequiredSelector,
  isPaymentOverdueAlertVisible as isPaymentOverdueAlertVisibleSelector,
} from 'src/reducers/subscriptions';
import anchors from 'src/constants/billingAnchors';
import Button from 'src/components/Button/Button';

import PaymentSourceWithActions from '../PaymentSourceWithActions/PaymentSourceWithActions';
import CollectPaymentDialog from '../CollectPaymentDialog/CollectPaymentDialog';

const mapStateToProps = (state: ReduxState) => ({
  isPaymentSourceRequired: isPaymentSourceRequiredSelector(state),
  paymentSources: getAllPaymentSources(state),
  isPaymentOverdueAlertVisible: isPaymentOverdueAlertVisibleSelector(state),
});

type Props = {
  ...$Call<typeof mapStateToProps, ReduxState>,
};

function PaymentDetails(props: Props) {
  const { isPaymentSourceRequired, paymentSources, isPaymentOverdueAlertVisible } = props;
  const hasPaymentSources = !!paymentSources && paymentSources.length > 0;
  const [isAddPaymentSourceDialogVisible, setIsAddPaymentSourceDialogVisible] = useState(false);
  const [isCollectPaymentDialogVisible, setIsCollectPaymentDialogVisible] = useState(false);

  const onToggleAddPaymentSourceDialog = () => {
    setIsAddPaymentSourceDialogVisible((isVisible) => !isVisible);
  };

  return (
    <section>
      <Heading as="h3" id={anchors.paymentDetails} variant="small1X" marginBottom="spacingLarge1X">
        Payment details
      </Heading>
      {isPaymentOverdueAlertVisible && (
        <>
          <Box marginBottom="spacingLarge">
            <Alert
              buttonProps={{
                children: 'Retry payment',
                onClick: () => setIsCollectPaymentDialogVisible(true),
                testHook: 'button-retry-payment',
              }}
              variant="danger"
              testHook="alert-overdue-invoice"
            >
              There was a problem charging your card and your payment is overdue.
            </Alert>
          </Box>

          <CollectPaymentDialog
            isOpen={isCollectPaymentDialogVisible}
            onClose={() => setIsCollectPaymentDialogVisible(false)}
          />
        </>
      )}
      {hasPaymentSources &&
        paymentSources.map((source) => <PaymentSourceWithActions key={source.id} source={source} />)}
      {!hasPaymentSources && (
        <>
          {isPaymentSourceRequired && (
            <Box marginBottom="spacingLarge">
              <Alert
                buttonProps={{
                  children: 'Add debit or credit card',
                  onClick: onToggleAddPaymentSourceDialog,
                }}
                variant="danger"
              >
                You have no payment method on your account.
              </Alert>
            </Box>
          )}

          <Button size="small" variant="tertiary" onClick={onToggleAddPaymentSourceDialog} testHook="button-add-card">
            Add credit/debit card
          </Button>

          <AddPaymentSourceDialog
            actionLabel="add"
            isVisible={isAddPaymentSourceDialogVisible}
            onClose={onToggleAddPaymentSourceDialog}
          />
        </>
      )}
    </section>
  );
}

export default (connect(mapStateToProps)(PaymentDetails): React.AbstractComponent<{}>);
