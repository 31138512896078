// @flow
import { Container, Heading, Text, useMediaQuery } from '@getatomi/neon';

import paymentFrequencies from 'src/constants/paymentFrequencies';
import type { PaymentFrequencyType } from 'src/types';

export type UpgradeHeaderProps = {|
  isExistingPaidSubscription: boolean,
  isLoggedInAsParent?: boolean,
  paymentFrequency: PaymentFrequencyType,
  yearlyDiscount: number,
|};

export default function UpgradeHeader(props: UpgradeHeaderProps) {
  const { isExistingPaidSubscription, isLoggedInAsParent = false, paymentFrequency, yearlyDiscount } = props;
  const isSwitchingToYearly = isExistingPaidSubscription && paymentFrequency === paymentFrequencies.year;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  return (
    <Container as="header" maxWidth="sizeContainerRoot" textAlign="center">
      <Heading as="h1" variant={isMobile ? 'small' : 'large2X'} marginBottom={isMobile ? 'spacingRoot' : undefined}>
        {
          // eslint-disable-next-line no-nested-ternary
          isExistingPaidSubscription
            ? `Switch to paying ${paymentFrequency === 'month' ? 'yearly and save!' : 'monthly'}`
            : isLoggedInAsParent
            ? 'Let’s get unlimited access for your child!'
            : 'Sweet, let’s get you unlimited access!'
        }
      </Heading>
      {!isMobile && (
        <Text variant="lead" marginBottom="spacingLarge4X">
          {
            // eslint-disable-next-line no-nested-ternary
            isSwitchingToYearly
              ? isLoggedInAsParent
                ? `That’s full access to all content and features, anytime, and on any device for you and one student with a saving of ${yearlyDiscount}% compared to paying monthly.`
                : `That’s full access to all content and features, anytime, and on any device with a saving of ${yearlyDiscount}% compared to paying monthly, for you and one student.`
              : `That’s full access to all content and features, anytime, and on any device${
                  isLoggedInAsParent ? ' for you and one student.' : '.'
                }`
          }
        </Text>
      )}
    </Container>
  );
}
