// @flow
import { Box, IconViewGrid, IconViewList } from '@getatomi/neon';

import { trackEvent } from 'src/utils/tracking';
import { trackingCtas, trackingEvents } from 'src/constants/tracking';

const viewModes = {
  grid: 'grid',
  list: 'list',
};

export type ViewMode = $Keys<typeof viewModes>;

export type ViewModeToggleProps = {|
  onChange: (viewMode: ViewMode) => void,
  selectedMode: ViewMode,
|};

export default function ViewModeToggle(props: ViewModeToggleProps) {
  const { onChange, selectedMode } = props;

  const onChangeHandler = (mode: ViewMode) => {
    trackEvent(trackingEvents.ctaClicked, {
      cta: trackingCtas.classListChangeViewType,
      data: mode,
    });
    onChange(mode);
  };

  return (
    <Box as="ul" display={{ base: 'none', tablet: 'flex' }} alignItems="center" gap="spacingRoot">
      {Object.keys(viewModes).map((mode) => (
        <li key={mode}>
          <Box
            as="button"
            type="button"
            aria-pressed={selectedMode === mode}
            onClick={() => onChangeHandler(mode)}
            color={selectedMode === mode ? 'colorIconSelected' : 'colorIcon'}
            _hoverAndFocus={{ color: 'colorIconHovered' }}
            transition="transitionRoot"
            testHook={`dashboard-${mode}-view-switch`}
          >
            {mode === 'grid' ? (
              <IconViewGrid isDecorative={false} title="View classes in a grid" size="sizeIconSmall" />
            ) : (
              <IconViewList isDecorative={false} title="View classes in a list" size="sizeIconSmall" />
            )}
          </Box>
        </li>
      ))}
    </Box>
  );
}
