// @flow
import _ from 'lodash';
import { Badge, Spinner, Table, useMediaQuery } from '@getatomi/neon';

import type { CurrencyType, Invoice } from 'src/types';
import Date from 'src/components/Date/Date';
import Link from 'src/components/Link/Link';
import Price from 'src/components/Price/Price';
import invoiceStatuses from 'src/constants/invoiceStatuses';

type Props = {
  currency: CurrencyType,
  invoices: ?Array<Invoice>,
  isSchoolPlan?: boolean,
};

const statusColors = {
  [invoiceStatuses.paid]: 'colorTextSuccess',
  [invoiceStatuses.payment_due]: 'colorTextWarning',
};

export default function SubscriptionInvoicesTable(props: Props) {
  const { currency, invoices, isSchoolPlan } = props;
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  if (!invoices || invoices.length === 0) {
    return null;
  }

  const isInvoiceVisible = isSchoolPlan && !isMobile;
  const isInvoiceLoading = (invoice) => !invoice.isLoaded || invoice.isLoadingDownloadUrl;
  const columnWidth = isInvoiceVisible ? '25%' : '33.33%';

  const allColumns = [
    {
      header: 'Date created',
      accessorKey: 'createdAt',
      cell: ({ getValue }) => <Date value={getValue()} testHook="hide-in-percy" />,
      meta: {
        isVisible: true,
        width: columnWidth,
      },
    },
    {
      header: 'Invoice number',
      accessorKey: 'invoiceNumber',
      cell: ({ getValue }) => <span data-test="hide-in-percy">{getValue()}</span>,
      meta: {
        isVisible: isInvoiceVisible,
        width: columnWidth,
      },
    },
    {
      header: isMobile ? <>Amount</> : <>Amount ({currency})</>,
      accessorKey: 'price',
      cell: ({ getValue }) => <Price value={getValue()} currency={currency} />,
      meta: {
        isVisible: true,
        width: columnWidth,
      },
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ getValue }) => <Badge color={statusColors[getValue()]}>{_.startCase(getValue())}</Badge>,
      meta: {
        isVisible: true,
        width: columnWidth,
      },
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      cell: (cell) =>
        cell.row.original.isInvoiceLoading ? (
          <Spinner size="sizeIconSmall" testHook="hide-in-percy" />
        ) : (
          <Link href={cell.row.original.download_url} isExternal testHook="hide-in-percy">
            View
          </Link>
        ),
      meta: {
        isVisible: true,
        width: '65rem',
      },
      isEndAligned: true,
    },
  ];

  const data = invoices.map((invoice) => ({
    createdAt: invoice.created_at,
    invoiceNumber: invoice.id,
    price: invoice.total,
    status: invoice.status,
    download_url: invoice.download_url,
    isInvoiceLoading: isInvoiceLoading(invoice),
  }));

  const columns = allColumns.filter(({ meta }) => meta.isVisible);
  const children = columns.map(({ meta }, i) => <col key={i} width={meta.width} />);

  return (
    <Table columns={columns} data={data}>
      <colgroup>{children}</colgroup>
    </Table>
  );
}
