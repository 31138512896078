// @flow
import { CSSTransition } from 'react-transition-group';

import styles from './UITransition.module.scss';

export { TransitionGroup } from 'react-transition-group';

export function FadeTransition(props: React.ElementConfig<typeof CSSTransition>) {
  return (
    <CSSTransition
      classNames={{
        enter: styles.fadeEnter,
        enterActive: styles.fadeEnterActive,
        exit: styles.fadeExit,
        exitActive: styles.fadeExitActive,
      }}
      {...props}
      timeout={300}
    />
  );
}

export function DropTransition(props: React.ElementConfig<typeof CSSTransition>) {
  return (
    <CSSTransition
      classNames={{
        enter: styles.dropEnter,
        enterActive: styles.dropEnterActive,
        exit: styles.dropExit,
        exitActive: styles.dropExitActive,
      }}
      {...props}
      timeout={300}
    />
  );
}
