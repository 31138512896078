// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetTeacherRevisionDialog,
  GetTeacherRevisionDialogVariables,
  GetTeacherRevisionDialog_me_account_class_modules as GqlModule,
  GetTeacherRevisionDialog_me_account_class_modules_ClassCategory_topics as GqlTopic,
  GetTeacherRevisionDialog_me_account_class_modules_ClassCategory_topics_ClassCategory_challenges as GqlChallenge,
  GetTeacherRevisionDialog_me_account_class_modules_ClassCategory_topics_ClassCategory_challenges_ClassChallengeLesson_challenge_items as GqlItem,
} from 'src/graphql/types/generated/GetTeacherRevisionDialog';
import type { Challenge, Module, Question, Topic } from 'src/components/RevisionDialog/useRevisionDialogState';
import { getChallengeItemType } from 'src/utils/getChallengeItemType';

import GET_TEACHER_REVISION_DIALOG from './GetTeacherRevisionDialog.graphql';

type Props = {|
  accountId: string,
  classId: string,
|};

type TeacherRevisionData = {|
  subjectCode: string,
  subjectName: string,
  subjectTree: Array<Module>,
|};

type Output = {|
  data: ?TeacherRevisionData,
  error: ?ApolloError,
  loading: boolean,
|};

function transformItem(item: GqlItem): Question | null {
  if (
    item.__typename === 'DragAndDropQuestion' ||
    item.__typename === 'MultipleChoiceQuestion' ||
    item.__typename === 'ExactAnswerQuestion' ||
    item.__typename === 'SelfMarkedQuestion' ||
    item.__typename === 'SelfMarkedLiteQuestion'
  ) {
    return {
      id: item.id,
      marks: item.marks,
      duration: item.durationInSeconds,
      type: getChallengeItemType(item),
    };
  }

  return null;
}

function transformChallenge(challenge: GqlChallenge): ?Challenge {
  if (challenge.__typename === 'ClassChallengeLesson') {
    return {
      id: challenge.id,
      duration: challenge.duration,
      questions: challenge.challenge.items.map(transformItem).filter(Boolean),
    };
  }
}

function transformTopic(topic: GqlTopic): ?Topic {
  if (topic.__typename === 'ClassCategory') {
    return {
      id: topic.id,
      labels: topic.labels,
      name: topic.name,
      challenges: topic.challenges.map(transformChallenge).filter(Boolean),
    };
  }
}

function transformModule(module: GqlModule): ?Module {
  if (module.__typename === 'ClassCategory') {
    return {
      id: module.id,
      labels: module.labels,
      name: module.name,
      topics: module.topics.map(transformTopic).filter(Boolean),
    };
  }
}

export default function useGetTeacherRevisionDialog(props: Props): Output {
  const { accountId, classId } = props;

  const { data, loading, error } = useQuery<GetTeacherRevisionDialog, GetTeacherRevisionDialogVariables>(
    GET_TEACHER_REVISION_DIALOG,
    {
      variables: {
        accountId,
        classId,
      },
    }
  );

  if (loading && !data) {
    return {
      loading: true,
      data: null,
      error: null,
    };
  }

  if (error) {
    return {
      error,
      loading: false,
      data: null,
    };
  }

  const accountData = data?.me?.account;
  invariant(accountData, 'Account data should be defined');

  const subjectData = accountData.class?.subject;
  invariant(subjectData, 'Subject data should be defined');

  const modulesData = accountData.class?.modules;
  invariant(modulesData, 'Modules data should be defined');

  const subjectCode = subjectData.code;
  const subjectName = subjectData.shortName;
  const subjectTree = modulesData.map(transformModule).filter(Boolean);

  return {
    loading: false,
    error: null,
    data: {
      subjectCode,
      subjectName,
      subjectTree,
    },
  };
}
