// @flow
import keyMirror from 'keymirror';

const isoCodes = keyMirror({
  au: null,
  ca: null,
  gb: null,
  sg: null,
});

export default isoCodes;

export const registrationIsoCodes = keyMirror({
  au: null,
  ca: null,
});
