// @flow
import { ApolloError, useQuery } from '@apollo/client';
import invariant from 'invariant';

import type { Challenge, Module, Question, Topic } from 'src/components/RevisionDialog/useRevisionDialogState';
import type {
  GetStudentRevisionDialog,
  GetStudentRevisionDialogVariables,
  GetStudentRevisionDialog_me_account_class_modules as GqlModule,
  GetStudentRevisionDialog_me_account_class_modules_Category_topics as GqlTopic,
  GetStudentRevisionDialog_me_account_class_modules_Category_topics_Category_challenges as GqlChallenge,
  GetStudentRevisionDialog_me_account_class_modules_Category_topics_Category_challenges_StudentChallengeLesson_challenge_items as GqlItem,
} from 'src/graphql/types/generated/GetStudentRevisionDialog';
import { getChallengeItemType } from 'src/utils/getChallengeItemType';

import GET_STUDENT_REVISION_DIALOG from '../GetStudentRevisionDialog.graphql';

type Props = {|
  accountId: string,
  classId: string,
|};

type TransformedData = {|
  subjectTree: Array<Module>,
|};

type Output = {|
  data: ?TransformedData,
  error: ?ApolloError,
  loading: boolean,
|};

function transformItem(item: GqlItem): Question | null {
  if (
    item.__typename === 'DragAndDropQuestion' ||
    item.__typename === 'MultipleChoiceQuestion' ||
    item.__typename === 'ExactAnswerQuestion' ||
    item.__typename === 'SelfMarkedQuestion' ||
    item.__typename === 'SelfMarkedLiteQuestion'
  ) {
    return {
      id: item.id,
      marks: item.marks,
      duration: item.durationInSeconds,
      type: getChallengeItemType(item),
    };
  }

  return null;
}

function transformChallenge(challenge: GqlChallenge): ?Challenge {
  if (challenge.__typename === 'StudentChallengeLesson') {
    return {
      id: challenge.id,
      duration: challenge.duration,
      questions: challenge.challenge.items.map(transformItem).filter(Boolean),
      hasProgress: challenge.metrics.progress.lastCompletedAt !== null,
    };
  }
}

function transformTopic(topic: GqlTopic): ?Topic {
  if (topic.__typename === 'Category') {
    return {
      id: topic.id,
      labels: topic.labels,
      name: topic.name,
      challenges: topic.challenges.map(transformChallenge).filter(Boolean),
    };
  }
}

function transformModule(module: GqlModule): ?Module {
  if (module.__typename === 'Category') {
    return {
      id: module.id,
      labels: module.labels,
      name: module.name,
      topics: module.topics.map(transformTopic).filter(Boolean),
    };
  }
}

export default function useGetStudentRevisionDialog(props: Props): Output {
  const { classId, accountId } = props;

  const { data, loading, error } = useQuery<GetStudentRevisionDialog, GetStudentRevisionDialogVariables>(
    GET_STUDENT_REVISION_DIALOG,
    {
      variables: {
        classId,
        accountId,
      },
    }
  );

  if (error) {
    return {
      error,
      loading: false,
      data: null,
    };
  }

  // We want the loader to appear between subject changes,
  // so don't load cached data i.e. checking for `loading && !data`
  if (loading) {
    return {
      loading: true,
      error: null,
      data: null,
    };
  }

  const accountData = data?.me?.account;
  invariant(accountData, 'Account data should be defined');

  const modulesData = accountData.class?.modules;
  invariant(modulesData, 'Modules data should be defined');

  const subjectTree = modulesData.map(transformModule).filter(Boolean);

  return {
    data: {
      subjectTree,
    },
    loading: false,
    error: null,
  };
}
