// @flow
import { createContext, useContext, useState } from 'react';
import invariant from 'invariant';

import { type NavigationContext } from 'src/utils/navigationContext';

export type UseNavigationContextResult = [?NavigationContext, (NavigationContext) => void];

const Context = createContext<UseNavigationContextResult | void>();

export function NavigationContextProvider(props: { children: React.Node }) {
  const [navigationContext, setNavigationContext] = useState<?NavigationContext>();

  // TODO: The array passed as the value prop to the Context provider changes every render.
  // Consider wrapping it in a useMemo hook.
  return <Context.Provider value={[navigationContext, setNavigationContext]}>{props.children}</Context.Provider>;
}

export function useNavigationContext(): UseNavigationContextResult {
  const navigationContext = useContext(Context);
  invariant(navigationContext, 'useNavigationContext must be used within a NavigationContextProvider');
  return navigationContext;
}
