// @flow
import { Container } from '@getatomi/neon';
import Helmet from 'react-helmet';
import { useQuery } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetChallengeOverview,
  GetChallengeOverviewVariables,
} from 'src/graphql/types/generated/GetChallengeOverview';
import { useNavigationContext } from 'src/hooks/useNavigationContext';
import BackLink from 'src/components/BackLink/BackLink';
import { prepareNavigation } from 'src/utils/prepareNavigation';
import { getChallengeMarksUrl, getChallengeProgressReportUrl } from 'src/utils/routes';
import { type NavigationContext } from 'src/utils/navigationContext';

import OverviewLoader from './Overview/OverviewLoader/OverviewLoader';
import GET_CHALLENGE_OVERVIEW from './GetChallengeOverview.graphql';
import { getChallengeQuestionUrlFactory } from './utilities/getQuestionUrlFactory';
import { prepareChallengeOverviewData } from './utilities/prepareOverviewData/prepareOverviewData';
import { getIsTeacherReviewFeatureEnabled } from './utilities/teacherReviewFeature';
import Overview from './Overview/Overview';

export type ChallengeOverviewContainerProps = {|
  params: {|
    classId: string,
    moduleId: string,
    postId: string,
    subscriptionId: string,
  |},
|};

export function ChallengeOverviewContainer(props: ChallengeOverviewContainerProps) {
  const { params } = props;
  const { classId, postId: lessonId, moduleId, subscriptionId: accountId } = params;

  const [navigationContext] = useNavigationContext();

  const modulePageFallback: NavigationContext = {
    location: 'module',
    metadata: {
      accountId,
      classId,
      moduleId,
      title: 'Class',
    },
  };

  const { backUrl, backLinkUrl, backLinkLabel } = prepareNavigation(navigationContext ?? modulePageFallback);
  const marksUrl = getChallengeMarksUrl(accountId, classId, moduleId, lessonId);
  const overviewUrl = getChallengeProgressReportUrl(accountId, classId, moduleId, lessonId);

  const {
    data: rawOverviewData,
    loading,
  }: {
    data: ?GetChallengeOverview,
    loading: boolean,
  } = useQuery<GetChallengeOverview, GetChallengeOverviewVariables>(GET_CHALLENGE_OVERVIEW, {
    variables: {
      accountId,
      classId,
      lessonId,
      filters: {
        roles: ['STUDENT'],
      },
    },
  });

  if (loading && !rawOverviewData) {
    return (
      <OverviewLoader heading="How’s everyone going with this quiz?" marksUrl={marksUrl} overviewUrl={overviewUrl} />
    );
  }

  invariant(rawOverviewData, 'Query data should be defined');

  const accountData = rawOverviewData?.me?.account;

  const isTeacherReviewFeatureEnabled = accountData
    ? getIsTeacherReviewFeatureEnabled({ accountFeatures: accountData.enabledFeatures })
    : false;

  const getChallengeQuestionUrl = getChallengeQuestionUrlFactory({
    accountId,
    classId,
    moduleId,
    postId: lessonId,
    returnTo: backUrl,
    isTeacherReviewFeatureEnabled,
  });

  const data = prepareChallengeOverviewData(rawOverviewData, getChallengeQuestionUrl);

  return (
    <>
      <Helmet>
        <title>{data.title}</title>
      </Helmet>
      <Container>
        <BackLink to={backLinkUrl}>{`Back to ${backLinkLabel}`}</BackLink>
      </Container>
      <Overview
        averageMark={data.averageMark}
        challengeTier={data.challengeTier}
        challengingQuestions={data.challengingQuestions}
        completetionRate={data.completetionRate}
        marksUrl={marksUrl}
        overviewUrl={overviewUrl}
        students={data.students}
        subheading={data.subheading}
      />
    </>
  );
}

export default ChallengeOverviewContainer;
