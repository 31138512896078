// @flow
import { pick } from 'lodash';

import { LOAD_CREDITS, LOAD_CREDITS_SUCCESS, LOAD_CREDITS_FAIL } from 'src/actions/credits';

export type CreditsState = {
  isLoaded: boolean,
  isLoading: boolean,
  promotionalCredits: number,
  refundableCredits: number,
  routeError: ?Object,
};

type StateType = {
  credits: CreditsState,
};

const initialState = {
  promotionalCredits: 0,
  refundableCredits: 0,
  isLoaded: false,
  isLoading: false,
  routeError: null,
};

export default function invoicesReducer(state: CreditsState = initialState, action: Object = {}) {
  switch (action.type) {
    case LOAD_CREDITS:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_CREDITS_SUCCESS: {
      const { promotional_credits: promotionalCredits, refundable_credits: refundableCredits } = action.result;

      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        routeError: null,
        promotionalCredits,
        refundableCredits,
      };
    }
    case LOAD_CREDITS_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        routeError: action.routeError,
      };

    default:
      return state;
  }
}

// Selectors
export const getCredits = (state: StateType) => pick(state.credits, ['promotionalCredits', 'refundableCredits']);
