// @flow
import { useEffect, useState } from 'react';

// This hook provides a simple mechanism for forms to check if the client script is loaded. We need
// to latch on to the window so scripts outside of the client script can also check and log errors
// if the script hasn't loaded
//
// The following is set in Html.js
// window.__isClientLoaded = false;

const dataAttribute = 'data-requires-client';
export const isClientLoadedFlag = '__isClientLoaded';

// Should be attached to the parent form
export const clientRequiredAttribute = { [dataAttribute]: '' };

// Should be attached to the title attribute of disabled buttons
export const loadingMessage =
  'This Atomi webpage is still loading. Please visit our Help Centre for more information if this message doesn’t disappear shortly.';

export const isClientLoadedScript = `
  (function() {
    var requiresClientErrorSent = false;
    document.querySelectorAll('[${dataAttribute}] input').forEach(function(input) {
      input.addEventListener('focus', function(e) {
        if(!window.${isClientLoadedFlag} && !requiresClientErrorSent) {
          var message = 'A form requiring client scripts was acted upon without client scripts being available. This suggests the client script failed to load';
          console.error(message);
          Sentry.captureMessage('[Html]: ' + message, 'error');
          requiresClientErrorSent = true;
        }
      });
    });
  })();
`;

export default function useIsClientLoaded(): boolean {
  const [isClientLoaded, setIsClientLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!__CLIENT__) {
      return;
    }

    setIsClientLoaded(true);
    window[isClientLoadedFlag] = true;
  }, []);

  return isClientLoaded;
}
