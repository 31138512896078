// @flow
import { Table, useMediaQuery } from '@getatomi/neon';

import Avatar from 'src/components/Avatar/Avatar';
import createCellValue from 'src/utils/createCellValue';
import useSortedStudents, { initialTableState } from 'src/hooks/useSortedStudents';

import CompletionDate from './CompletionDate/CompletionDate';
import { type StudentWithProgress } from '../transformLessonProgress';

type Props = {|
  students: Array<StudentWithProgress>,
  testHook?: string,
|};

export default function StudentsProgressTable(props: Props) {
  const { students, testHook } = props;

  const data = students.map((student) => {
    const { cumulativeCompletionCount, lastCompletedAt } = student;

    return {
      rowMeta: {
        ...student,
        sortByValue: student.lastName.toUpperCase(),
      },
      completed: createCellValue(lastCompletedAt),
      viewed: createCellValue(cumulativeCompletionCount || null),
      isInactive: !cumulativeCompletionCount,
    };
  });
  const { sortedData, setSortBy } = useSortedStudents(data);

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  if (students.length === 0) {
    return null;
  }

  const columns = [
    {
      header: 'Student',
      accessorKey: 'rowMeta',
      cell: ({ cell }) => {
        const { rowMeta, isInactive } = cell.row.original;
        return (
          <Avatar
            user={rowMeta}
            size="sizeAvatarSmall"
            variant={isInactive ? 'muted' : undefined}
            withText
            withTruncatedFirstName={isMobile}
          />
        );
      },
      sortDescFirst: false,
      meta: {
        isHeading: true,
      },
    },
    {
      header: 'Number of views',
      accessorKey: 'viewed',
      cell: ({ getValue }) => getValue().displayValue,
    },
    {
      header: 'Last viewed',
      accessorKey: 'completed',
      cell: ({ getValue }) => <CompletionDate value={getValue().displayValue} />,
    },
  ];

  return (
    <Table
      columns={columns}
      data={sortedData}
      initialState={initialTableState}
      isFixedLayout
      isSortable
      toggleSortBy={setSortBy}
      testHook={testHook}
    >
      <colgroup>
        <col width={isMobile ? '40%' : '50%'} />
        <col width="20%" />
        <col width="30%" />
      </colgroup>
    </Table>
  );
}
