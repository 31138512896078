// @flow
import { Box } from '@getatomi/neon';
import invariant from 'invariant';

import ActionBar from 'src/components/ActionBar/ActionBar';
import Button from 'src/components/Button/Button';
import Duration from 'src/components/Duration/Duration';
import LessonList, { LessonListLoader, type TopicType } from 'src/components/LessonList/LessonList';
import useSelectedLessons from 'src/components/LessonList/useSelectedLessons';

import type { FoundContent } from '../../useFindContentDialog';
import ContentCatalogError from '../ContentCatalog/ContentCatalogError';
import useGetLessons, { type SelectLessonsVariables } from './useGetLessons';

type SelectLessonsQueryProps = {|
  classId: string,
  context: 'subject' | 'task',
  isStudent: boolean,
  onAssignContent: ($ReadOnlyArray<FoundContent>) => void,
  queryVariables: SelectLessonsVariables,
  showUpgradeModal: () => void,
|};

type SelectLessonsProps = {|
  ...$Exact<SelectLessonsQueryProps>,
  topics: $ReadOnlyArray<TopicType>,
|};

function SelectLessons(props: SelectLessonsProps) {
  // eslint-disable-next-line no-unused-vars
  const { context, isStudent, queryVariables, onAssignContent, showUpgradeModal, topics } = props;

  const { Selecting, selectedLessons, selectedLessonsDuration } = useSelectedLessons({ topics });

  const checkboxProps = {
    isSelected: Selecting.isSelected,
    onSelect: Selecting.select,
  };

  const actionLabel = context === 'subject' ? 'Assign as task' : 'Add';

  return (
    <>
      <Box marginBottom="spacingLarge9X">
        <LessonList
          checkboxProps={isStudent ? undefined : checkboxProps}
          previewInNewTab={context === 'task'}
          showUpgradeModal={showUpgradeModal}
          topics={topics}
        />
      </Box>

      <Box position="absolute" bottom={0} left={0} right={0} zIndex="zIndex3">
        <ActionBar
          variant="inline"
          selectedItemCount={Selecting.itemIds.length}
          onClearSelection={Selecting.clear}
          meta={<Duration duration={selectedLessonsDuration} format="minutes" />}
        >
          <Button
            onClick={() => {
              onAssignContent(
                selectedLessons.map((lesson) => ({
                  duration: lesson.duration,
                  id: lesson.id,
                  moduleId: queryVariables.moduleId,
                  name: lesson.name,
                  subjectCode: queryVariables.subjectCode,
                  type: lesson.type,
                  tier: lesson.challengeTier ?? undefined,
                }))
              );
            }}
          >
            {actionLabel}
          </Button>
        </ActionBar>
      </Box>
    </>
  );
}

export default function SelectLessonsQuery(props: SelectLessonsQueryProps) {
  const { queryVariables, classId } = props;

  const { loading, error, topics } = useGetLessons({ ...queryVariables, classId });

  if (error) {
    return (
      <Box marginTop="spacingSmall">
        <ContentCatalogError content="lessons" />
      </Box>
    );
  }

  if (loading) {
    return <LessonListLoader />;
  }

  invariant(topics != null, 'Topics should be defined');

  return <SelectLessons {...props} topics={topics} />;
}
