// @flow
import userFullName, { formatName } from 'src/utils/userFullName';

type Props = {
  className?: string,
  firstName: string,
  lastName?: string,
};

export default function UserName({ firstName, lastName, className }: Props) {
  return <span className={className}>{lastName ? userFullName(firstName, lastName) : formatName(firstName)}</span>;
}
