// @flow

function calculatePercentage(value: number, total: number): number {
  return total ? value / total : 0;
}

function percentageToDecimal(percentage: number): number {
  if (percentage < 0) {
    return 0;
  }

  if (percentage > 100) {
    return 1;
  }

  return percentage / 100;
}

export type MakeChallengeSummaryValuesArgs = {|
  missedMarks: number,
  scoredMarks: number,
  skippedMarks: number,
  strengthPercentage: number,
|};

export type ChallengeSummaryItem = {|
  label: string,
  percentage: number,
  value: number,
|};

export type ChallengeSummaryValues = {|
  missed: ChallengeSummaryItem,
  scored: ChallengeSummaryItem,
  skipped: ChallengeSummaryItem,
  strength: number,
|};

export function makeChallengeSummaryValues(args: MakeChallengeSummaryValuesArgs): ChallengeSummaryValues {
  const { scoredMarks, missedMarks, skippedMarks, strengthPercentage } = args;

  const totalMarks = scoredMarks + missedMarks + skippedMarks;

  const scored: ChallengeSummaryItem = {
    label: 'Scored',
    value: scoredMarks,
    percentage: calculatePercentage(scoredMarks, totalMarks),
  };

  const missed: ChallengeSummaryItem = {
    label: 'Missed',
    value: missedMarks,
    percentage: calculatePercentage(missedMarks, totalMarks),
  };

  const skipped: ChallengeSummaryItem = {
    label: 'Skipped',
    value: skippedMarks,
    percentage: calculatePercentage(skippedMarks, totalMarks),
  };

  const strength = percentageToDecimal(strengthPercentage);

  const summaryData: ChallengeSummaryValues = {
    scored,
    missed,
    skipped,
    strength,
  };

  return summaryData;
}
