// @flow
import keyMirror from 'keymirror';

/**
 * List of events
 * Event names must be in proper case
 */
export const trackingEvents = Object.freeze({
  avatarDeleteRequested: 'Avatar Delete Requested',
  avatarUploadRequested: 'Avatar Upload Requested',
  classAddUsersRequested: 'Class Add Users Requested',
  classCreateRequested: 'Class Create Requested',
  classDeleteRequested: 'Class Delete Requested',
  classBulkDeleteRequested: 'Class Bulk Delete Requested',
  classInviteUserRequested: ' Class Invite User Requested',
  classRemoveUserRequested: 'Class Remove User Requested',
  classRemoveUsersRequested: 'Class Remove Users Requested',
  classUpdateRequested: 'Class Update Requested',
  ctaClicked: 'CTA Clicked',
  myClassesLoaded: 'My Classes Loaded',
  paymentRetryRequested: 'Payment Retry Requested',
  paymentSourceAddRequested: 'Payment Source Add Requested',
  paymentSourceDeleteRequested: 'Payment Source Delete Requested',
  searchOpened: 'Search Opened',
  searchClosed: 'Search Closed',
  studentRevisionCreationRequested: 'Student Revision Creation Requested',
  subscriptionActivateSetupModeRequested: 'Subscription Activate Setup Mode Requested',
  subscriptionArchiveUsersRequested: 'Subscription Archive Users Requested',
  subscriptionCancelFeedbackSent: 'Subscription Cancel Feedback Sent',
  subscriptionCancelRequested: 'Subscription Cancel Requested',
  subscriptionDeactivateSetupModeRequested: 'Subscription Deactivate Setup Mode Requested',
  subscriptionInviteUserRequested: 'Subscription Invite User Requested',
  subscriptionInviteUsersRequested: 'Subscription Invite Users Requested',
  subscriptionMakeUserAdminRequested: 'Subscription Make User Admin Requested',
  subscriptionReactivateRequested: 'Subscription Reactivate Requested',
  subscriptionRemoveAdminRequested: 'Subscription Remove Admin Requested',
  subscriptionResendUsersInviteRequested: 'Subscription Resend Users Invite Requested',
  subscriptionRestoreUsersRequested: 'Subscription Restore Users Requested',
  subscriptionRetailUpgradeConfirmed: 'Subscription Retail Upgrade Confirmed',
  subscriptionRetailUpgradeRequested: 'Subscription Retail Upgrade Requested',
  subscriptionSchoolUpgradeRequested: 'Subscription School Upgrade Requested',
  subscriptionFamilyUpgradeRequested: 'Subscription Family Ask Parent To Upgrade Requested',
  subscriptionTransferAccountOwnershipRequested: 'Subscription Transfer Account Ownership Requested',
  subscriptionUpdateRequested: 'Subscription Update Requested',
  taskAssignContent: 'Task Assign Content',
  taskCreateRequested: 'Task Create Requested',
  taskUpdateRequested: 'Task Update Requested',
  taskUpdateCompletionRequested: 'Task Update Completion Requested',
  taskDeleteRequested: 'Task Delete Requested',
  teacherRevisionCreationRequested: 'Teacher Revision Creation Requested',
  textPostCompleted: 'Text Post Completed',
  upgradePromptTriggered: 'Upgrade Prompt Triggered',
  userConfirmationRequested: 'User Confirmation Requested',
  userDownloadDataRequested: 'User Download Data Requested',
  userDetailsRequested: 'User Details Requested',
  userEmailValidationRequested: 'User Email Validation Requested',
  userEraseDataRequested: 'User Erase Data Requested',
  userInvitedActivationRequested: 'User Invited Activation Requested',
  userInvitedRegistrationRequested: 'User Invitation Confirm Requested',
  userInvitationDetailsRequested: 'User Invitation Details Requested',
  userLoggedOut: 'User Logged Out',
  userLoginRequested: 'User Login Requested',
  userPasswordChangeRequested: 'User Password Change Requested',
  userPasswordResetEmailRequested: 'User Password Reset Email Requested',
  userPasswordResetRequested: 'User Password Reset Requested',
  userRegistrationDetailsEntered: 'User Registration Details Entered',
  userRegistrationRequested: 'User Registration Requested',
  userSubjectsSaveRequested: 'User Subjects Save Requested',
  userSocialLoginUrlRequested: 'User Social Login Url Requested',
  userUpdateRequested: 'User Update Requested',
  video25PercentWatched: 'Video 25% watched',
  video50PercentWatched: 'Video 50% watched',
  video75PercentWatched: 'Video 75% watched',
  videoEnded: 'Video ended',
  videoCompleted: 'Video Completed',
  videoPaused: 'Video paused',
  videoPlaybackRateChanged: 'Video playback rate changed',
  videoPlayed: 'Video played',
  videoSoughtAhead: 'Video sought ahead',
  videoSoughtBackwards: 'Video sought backwards',
});

/**
 * List of CTAs descriptions
 * This is not the event name but the `cta` property attached to `CTA Clicked` event type
 * e.g track('CTA Clicked', { cta: 'Class Add Users' });
 */
export const trackingCtas = Object.freeze({
  classAddUsers: 'Class Add Users',
  classBulkAddUsers: 'Class Bulk Add Users',
  classCreateOpenDialog: 'Class Create Open Dialog',
  classListChangeViewType: 'Class list Change View Type',
  classProgressOpenDialog: 'Class Progress Open Dialog',
  classTaskAddDialog: 'Class Task Add Dialog',
  classTaskEditDialog: 'Class Task Edit Dialog',
  classTaskProgressOpenDialog: 'Class Task Progress Open Dialog',
  curriculumDescriptor: 'Curriculum Descriptor',
  copyLink: 'Copy Link',
  copyEmbedCode: 'Copy Embed Code',
  findContentDialogBreadcrumb: 'Find Content Dialog Breadcrumb',
  shareToGoogleClassroom: 'Share to Google Classroom',
  lockedFeatureDialog: 'Locked Feature Dialog',
  requestUpgradeOpenDialog: 'Request Upgrade Open Dialog',
  navigateToForgotPassword: 'Navigate To Forgot Password',
  navigateToHome: 'Navigate To Home',
  searchResultSelect: 'Search Result Select',
  userLogin: 'User Login',
  userOnboardingVideoClosed: 'User Onboarding Video Closed',
  userUpdate: 'User Update',
  userRegistration: 'User Sign Up',
  userRegistrationFamilyAccountOwner: 'User Registration Family Account Owner',
  userRegistrationStudent: 'User Registration Student',
  userRegistrationTeacher: 'User Registration Teacher',
  userSubjectsClear: 'User Subjects Clear',
  userSubjectsSelect: 'User Subjects Select',
  subscriptionInviteUser: 'Subscription Invite User',
  subscriptionInviteUsers: 'Subscription Invite Users',
  expandColumnModule: 'Expand Column Module',
  expandColumnMonth: 'Expand Column Month',
  expandColumnTopic: 'Expand Column Topic',
  markbookDownload: 'Download Mark Book Data',
  viewModule: 'View Module',
  viewTopicInsights: 'View Topic Insights',
  viewLesson: 'View Lesson',
  viewTask: 'View Task',
  viewTaskProgressReport: 'View Task Progress Report',
  ltiEmbedSingleLesson: 'LTI Embed Lesson',
  ltiBulkEmbedLessons: 'LTI Bulk Embed Lessons',
  shuffleQuestionPreview: 'Shuffle Question Preview',
});

export const trackingCtaTypes = keyMirror({
  button: null,
  icon: null, // TODO: CAR-868 - remove this once Icon component is refactored
  link: null,
});

export const trackingFeatures = Object.freeze({
  tasks: 'Tasks',
  postProgress: 'Post Progress',
});

export const trackingLocations = Object.freeze({
  header: 'Header',
  postBreadcrumb: 'Post Breadcrumb',
});

export const trackingUpgradeSources = keyMirror({
  header: null,
  module: null,
  post: null,
  search: null,
  universalLink: null,
});

export const trackingSearchLocations = keyMirror({
  subject: null,
  task: null,
});
