// @flow
import { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import mem from 'mem';

import type { BoundAction, ReduxState, UserType } from 'src/types';
import { loadAvatar } from 'src/actions/avatars';
import { getUser, isUserLoggedIn as isUserLoggedInSelector } from 'src/reducers/auth';
import { getAvatarsByUser } from 'src/reducers/avatars';
import avatarSizes from 'src/constants/avatarSizes';
import subscriptionStatuses from 'src/constants/subscriptionStatuses';
import Avatar from 'src/components/Avatar/Avatar';
import type { AvatarSize } from 'src/components/Avatar/Avatar';

let loadAvatarActionMemoized;

type Props = {
  isDecorative?: boolean,
  isDisabled?: boolean,
  size?: AvatarSize,
  textTestHook?: string,
  useExistingAvatar?: boolean,
  user: UserType,
  variant?: 'muted',
  withText?: boolean,
  withTooltip?: boolean,
  withTruncatedFirstName?: boolean,
};

type InjectedProps = Props & {
  isUserLoggedIn: boolean,
  loadAvatarAction: BoundAction<typeof loadAvatar>,
  loggedInUserUrl?: ?string,
  url: ?string,
};

type State = {
  url: ?string,
};

// Nucleus avatar endpoint requires specific size names:
// https://api.getatomi.app/nucleus/api-docs#users-get-a-users-avatar
const sizeMappings = {
  sizeAvatarLarge1X: avatarSizes['retina-large'],
  sizeAvatarLarge: avatarSizes['retina-medium'],
  sizeAvatarSmall: avatarSizes['retina-small'],
  sizeAvatarSmall1X: avatarSizes.medium,
};
const getSize = (size?: AvatarSize) => (size ? sizeMappings[size] : sizeMappings.sizeAvatarSmall);

const mapStateToProps = (state: ReduxState, props: Props) => {
  const { user } = props;
  const loggedInUser = getUser(state);
  const isLoggedInUserAvatar = user.id === loggedInUser.id;
  const size = getSize(props.size);
  const url = size && getAvatarsByUser(state, user.id, size);

  return {
    url,
    loggedInUserUrl: !url && isLoggedInUserAvatar && loggedInUser.avatar_url,
    isUserLoggedIn: isUserLoggedInSelector(state),
  };
};

class ReduxAvatarContainer extends Component<InjectedProps, State> {
  constructor(props: InjectedProps) {
    super(props);

    if (this.props.loadAvatarAction && !loadAvatarActionMemoized) {
      // We only need to memoize for a short period of time, since there is another check inside the
      // action creator. We have to do this in two places because the action creator is a thunk so
      // the state is not updated right away so the check does't work for "concurrent" calls.
      loadAvatarActionMemoized = mem(this.props.loadAvatarAction, {
        maxAge: 30 * 1000,
      });
    }
  }

  loadUserAvatar() {
    const { isUserLoggedIn, size, user, url, useExistingAvatar } = this.props;

    if (
      isUserLoggedIn &&
      !url &&
      user.avatar_mime_type &&
      user.avatar_url &&
      !(useExistingAvatar && user.avatar_url !== 'null')
    ) {
      loadAvatarActionMemoized(user.id, getSize(size));
    }
  }

  // We want to only load the avatars on the client, hence `componentDidMount`.
  // Because a lot of `ReduxAvatarContainer` components are created at the same time AND
  // `actions/avatars#loadAvatar` is a thunk, the browser is spammed with a lot of requests. Chrome
  // (and other browsers) don't like that so they will throttle the requests.
  // https://developers.google.com/web/tools/chrome-devtools/network-performance/reference#timing-explanation
  componentDidMount() {
    this.loadUserAvatar();
  }

  shouldComponentUpdate(nextProps: InjectedProps) {
    const { loggedInUserUrl, url, user } = this.props;

    return nextProps.url !== url || !_.isEqual(nextProps.user, user) || nextProps.loggedInUserUrl !== loggedInUserUrl;
  }

  render() {
    const {
      url,
      user,
      loggedInUserUrl,
      useExistingAvatar,
      loadAvatarAction,
      isUserLoggedIn,
      isDisabled,
      variant,
      ...avatarProps
    } = this.props;

    if (!user) return null;

    const transformedUser = {
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      avatar: url || loggedInUserUrl || user.avatar_url,
      accountStatus: user.subscription_status === subscriptionStatuses.active ? 'ACTIVE' : user.subscription_status,
      color: user.color,
      deletedAt: user.deleted_at,
    };

    return <Avatar user={transformedUser} {...avatarProps} variant={isDisabled ? 'muted' : variant} />;
  }
}

export default (connect(mapStateToProps, { loadAvatarAction: loadAvatar })(
  ReduxAvatarContainer
): React.AbstractComponent<Props>);
