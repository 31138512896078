// @flow
import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

import type { Question } from 'src/components/RevisionDialog/types';
import type {
  GetQuestionPreviews,
  GetQuestionPreviewsVariables,
  GetQuestionPreviews_contentItems as GqlItem,
} from 'src/graphql/types/generated/GetQuestionPreviews';
import { getChallengeItemType } from 'src/utils/getChallengeItemType';

import type { ReplaceRevisionQuestionType } from './useDraftRevision';
import { type ReplaceQuestionPreviewType, transformQuestionPreview } from './useGetQuestionPreviews';
import GET_QUESTION_PREVIEWS from './GetQuestionPreviews.graphql';

type Props = {|
  currentQuestionId: string,
  replaceQuestionPreview: ReplaceQuestionPreviewType,
  replaceRevisionQuestion: ReplaceRevisionQuestionType,
  selectedTopics: Array<string>,
  setShouldDisableOtherShuffles: (newState: boolean) => void,
|};

type Output = {|
  fetchQuestionPreview: (questionId: string) => Promise<void>,
  isBeingShuffled: boolean,
|};

function transformRevisionQuestion(gqlItem: GqlItem): Question {
  if (
    gqlItem.__typename !== 'DragAndDropQuestion' &&
    gqlItem.__typename !== 'ExactAnswerQuestion' &&
    gqlItem.__typename !== 'MultipleChoiceQuestion' &&
    gqlItem.__typename !== 'SelfMarkedLiteQuestion' &&
    gqlItem.__typename !== 'SelfMarkedQuestion'
  ) {
    throw new Error(`Invalid replacement question type: ${gqlItem.__typename}`);
  }

  return {
    id: gqlItem.id,
    marks: gqlItem.marks,
    duration: gqlItem.durationInSeconds,
    type: getChallengeItemType(gqlItem),
  };
}

export default function useGetQuestionPreview(props: Props): Output {
  const {
    currentQuestionId,
    replaceQuestionPreview,
    replaceRevisionQuestion,
    selectedTopics,
    setShouldDisableOtherShuffles,
  } = props;

  const [getQuestionPreview, { data, loading: isBeingShuffled }] = useLazyQuery<
    GetQuestionPreviews,
    GetQuestionPreviewsVariables
  >(GET_QUESTION_PREVIEWS);

  useEffect(() => {
    if (data) {
      const replacementData = data.contentItems[0];

      const replacementRevisionQuestion = transformRevisionQuestion(replacementData);
      const replacementQuestionPreview = transformQuestionPreview(replacementData, selectedTopics);
      if (replacementQuestionPreview == null) {
        throw new Error('Replacement question preview should be defined');
      }
      replaceQuestionPreview(currentQuestionId, replacementQuestionPreview);
      replaceRevisionQuestion(currentQuestionId, replacementRevisionQuestion);
    }
  }, [currentQuestionId, data, replaceRevisionQuestion, replaceQuestionPreview, selectedTopics]);

  useEffect(() => {
    if (isBeingShuffled) {
      setShouldDisableOtherShuffles(true);
    } else {
      setShouldDisableOtherShuffles(false);
    }
  }, [isBeingShuffled, setShouldDisableOtherShuffles]);

  const fetchQuestionPreview = async (questionId: string) => {
    await getQuestionPreview({
      variables: {
        filters: {
          contentItemIds: [questionId],
        },
      },
    });
  };

  return {
    fetchQuestionPreview,
    isBeingShuffled,
  };
}
