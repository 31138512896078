// @flow

import { Container, Stack } from '@getatomi/neon';

import BackLink from 'src/components/BackLink/BackLink';

import { type LinkProps } from './types';

export type Props = {|
  backLinkProps: LinkProps | null,
  children: React.Node,
|};

export function RevisionChallengeLayout({ backLinkProps, children }: Props) {
  return (
    <Stack spacing="spacingLarge3X">
      {backLinkProps && (
        <Container paddingInline="spacingNone">
          <BackLink to={backLinkProps.to}>{`Back to ${backLinkProps.label}`}</BackLink>
        </Container>
      )}
      <Container paddingInline="spacingNone" textAlign="center">
        {children}
      </Container>
    </Stack>
  );
}
