// @flow
import { useState } from 'react';

import { generateSession, type RevisionSession } from 'src/components/RevisionDialog/generateSession';
import type { Question, QuestionTypeFilter, SessionLengthFilter } from 'src/components/RevisionDialog/types';

type Props = {|
  questions: Array<Question>,
  questionTypeFilters: Array<QuestionTypeFilter>,
  sessionLengthFilter: SessionLengthFilter,
|};

export type ReplaceRevisionQuestionType = (questionIdToShuffle: string, replacement: Question) => void;

type Output = {|
  discardDraftRevision: () => void,
  draftRevision: RevisionSession | null,
  initDraftRevision: () => void,
  replaceRevisionQuestion: ReplaceRevisionQuestionType,
|};

export default function useDraftRevision(props: Props): Output {
  // A draft revision is generated for the question preview screen to allow
  // users to edit the revision by shuffling questions. This draft revision
  // will need to be passed into the "Add to task" button to be included in
  // the mutation payload.

  const { questions, questionTypeFilters, sessionLengthFilter } = props;

  const [draftRevision, setDraftRevision] = useState<RevisionSession | null>(null);

  const replaceRevisionQuestion = (questionIdToShuffle: string, replacement: Question) => {
    setDraftRevision((prevRevision) => {
      if (prevRevision === null) {
        // Note that we expect this to only be called after the draft revision
        // has been initialised, so this shouldn't be triggered in practice.
        return null;
      }

      const editedQuestions = prevRevision.questions.map((question) => {
        return question.id === questionIdToShuffle ? replacement : question;
      });

      return {
        ...prevRevision,
        questions: editedQuestions,
      };
    });
  };

  const initDraftRevision = () => {
    setDraftRevision(
      generateSession({
        isTeacher: true,
        questions,
        questionTypeFilters,
        sessionLengthFilter,
      })
    );
  };

  const discardDraftRevision = () => {
    setDraftRevision(null);
  };

  return {
    replaceRevisionQuestion,
    draftRevision,
    initDraftRevision,
    discardDraftRevision,
  };
}
