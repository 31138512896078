// @flow
import _ from 'lodash';
import { useState } from 'react';
import { Breadcrumbs, BreadcrumbsItem } from '@getatomi/neon';

import { trackingCtas, trackingEvents, trackingSearchLocations } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';

import type { SelectSubjectVariables } from '../components/SelectSubject/useGetLevelSubjects';
import type { SelectYearVariables } from '../components/SelectYear/useGetLevels';
import type { SelectModuleVariables } from '../components/SelectModule/useGetModules';
import type { SelectLessonsVariables } from '../components/SelectLessons/useGetLessons';

type SelectYear = {|
  label: string,
  queryVariables: SelectYearVariables,
  screen: 'select-year',
|};

type SelectSubject = {|
  label: string,
  queryVariables: SelectSubjectVariables,
  screen: 'select-subject',
|};

type SelectModule = {|
  label: string,
  queryVariables: SelectModuleVariables,
  screen: 'select-module',
|};

type SelectLessons = {|
  label: string,
  queryVariables: SelectLessonsVariables,
  screen: 'select-lessons',
|};

type ScreenType =
  | $PropertyType<SelectYear, 'screen'>
  | $PropertyType<SelectSubject, 'screen'>
  | $PropertyType<SelectModule, 'screen'>
  | $PropertyType<SelectLessons, 'screen'>;

export type SelectorScreen = SelectYear | SelectSubject | SelectModule | SelectLessons;

export type Output = {|
  addBreadcrumb: (newBreadCrumb: SelectorScreen) => void,
  breadcrumbsComponent: React.Node,
  breadcrumbsState: $ReadOnlyArray<SelectorScreen>,
|};

export function omitLevelIdForRetailUsers(isRetailPlan: boolean, screen: SelectorScreen): SelectorScreen {
  // This is required for retail users as class levels aren't applicable to them.
  // Setting it to `null` ensures search works for modules, lessons etc which
  // accept a `levelId` parameter.
  if (isRetailPlan && screen.queryVariables.levelId) {
    _.set(screen, 'queryVariables.levelId', null);
  }
  return screen;
}

export default function useBreadcrumbs({
  breadcrumbs: defaultBreadcrumbs,
  context,
  isRetailPlan,
  isStudent,
}: {
  breadcrumbs: $ReadOnlyArray<SelectorScreen>,
  context: 'subject' | 'task',
  isRetailPlan: boolean,
  isStudent: boolean,
}): Output {
  const [breadcrumbs, setBreadcrumbs] = useState<$ReadOnlyArray<SelectorScreen>>(defaultBreadcrumbs);

  const handleBreadcrumbClick = (screen: ScreenType) => {
    setBreadcrumbs((prevBreadcrumbs) =>
      prevBreadcrumbs.slice(0, prevBreadcrumbs.findIndex((breadcrumb) => breadcrumb.screen === screen) + 1)
    );
    trackEvent(trackingEvents.ctaClicked, {
      cta: trackingCtas.findContentDialogBreadcrumb,
      item: screen,
      label: breadcrumbs.find((breadcrumb) => breadcrumb.screen === screen)?.label,
      searchLocation: context === 'subject' ? trackingSearchLocations.subject : trackingSearchLocations.task,
      userType: isStudent ? 'student' : 'teacher',
    });
  };

  const breadcrumbsComponent = (
    <Breadcrumbs maxItems={4} onAction={handleBreadcrumbClick} isTruncated>
      {breadcrumbs.map((breadcrumb) => (
        <BreadcrumbsItem key={breadcrumb.screen}>{breadcrumb.label}</BreadcrumbsItem>
      ))}
    </Breadcrumbs>
  );

  const addBreadcrumb = (newBreadCrumb: SelectorScreen) => {
    setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, omitLevelIdForRetailUsers(isRetailPlan, newBreadCrumb)]);
  };

  return {
    breadcrumbsComponent,
    breadcrumbsState: breadcrumbs,
    addBreadcrumb,
  };
}
