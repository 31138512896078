// @flow
import { withCookies } from 'react-cookie';
import type Cookies from 'universal-cookie';
import { Avatar, Box, Checkbox, Flex, Heading, IconSchool, IconUser } from '@getatomi/neon';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { useEffect, useState, createRef } from 'react';

import useRenderKey from 'src/hooks/useRenderKey';
import { clearLoginError, login, loadAuthStrategies, loginWithSaml } from 'src/actions/auth';
import ReduxAvatarContainer from 'src/components/ReduxAvatarContainer/ReduxAvatarContainer';
import Button, { trackButtonClick } from 'src/components/Button/Button';
import SocialLoginButton from 'src/domains/Login/SocialLoginButton/SocialLoginButton';
import { clearSocialLoginError } from 'src/actions/socialLogins';
import ErrorTypeMessage from 'src/components/ErrorTypeMessage/ErrorTypeMessage';
import Form from 'src/components/Form/Form';
import FormMessage from 'src/components/FormMessage/FormMessage';
import Link from 'src/components/Link/Link';
import Input from 'src/components/Input/Input';
import UserName from 'src/components/UserName/UserName';
import { DropTransition, TransitionGroup } from 'src/components/UITransition/UITransition';
import { trackingCtas } from 'src/constants/tracking';
import socialLogins from 'src/constants/socialLogins';
import { getLoginError, getAuthStrategiesError, getAuthStrategies } from 'src/reducers/auth';
import { getSocialLoginError, isLoggedInWithSocialAccount, isLoggingInWith } from 'src/reducers/socialLogins';
import Auth from 'src/utils/Auth';
import { getSubscriptionIdFromUrl } from 'src/utils/routes';
import scrollTo from 'src/utils/scrollTo';
import { formatName } from 'src/utils/userFullName';
import { authStrategyTypes } from 'src/constants/authStrategyTypes';
import authCustomerTypes from 'src/constants/authCustomerTypes';
import useIsClientLoaded, { clientRequiredAttribute, loadingMessage } from 'src/hooks/useIsClientLoaded';
import type { ApiError, BoundAction, ReduxState, AuthStrategy } from 'src/types';
import Choice from 'src/components/Choice/Choice';

import styles from './LoginForm.module.scss';

type Props = {
  authStrategies: Array<AuthStrategy>,
  clearLoginErrorAction: BoundAction<typeof clearLoginError>,
  clearSocialLoginErrorAction: BoundAction<typeof clearSocialLoginError>,
  cookies: Cookies,
  isLoggingInWithSocial: boolean,
  loadAuthStrategiesAction: BoundAction<typeof loadAuthStrategies>,
  loginAction: BoundAction<typeof login>,
  loginError?: ApiError,
  loginWithSamlAction: BoundAction<typeof loginWithSaml>,
  redirectUrl: ?string,
  samlErrorMessage: ?string,
};

const mapStateToProps = (state: ReduxState) => ({
  authStrategies: getAuthStrategies(state),
  isLoggingInWithSocial:
    Object.keys(socialLogins).some((provider) => isLoggingInWith(state, provider)) ||
    isLoggedInWithSocialAccount(state),
  loginError: getLoginError(state) || getSocialLoginError(state) || getAuthStrategiesError(state),
});

function LoginForm(props: Props) {
  const {
    authStrategies,
    clearLoginErrorAction,
    clearSocialLoginErrorAction,
    cookies,
    isLoggingInWithSocial,
    loadAuthStrategiesAction,
    loginAction,
    loginError,
    loginWithSamlAction,
    redirectUrl,
    samlErrorMessage: initialSamlErrorMessage,
  } = props;

  const auth = new Auth(cookies);
  const user = auth.getUserDetails();
  const reAuthUserEmail = auth.getReAuthUser();
  const isRememberMeLayout = !!user;

  const [key, updateKey] = useRenderKey();
  const [email, setEmail] = useState<string>(reAuthUserEmail || user?.email || '');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [isAuthStrategyListVisible, setIsAuthStrategyListVisible] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [isLoggingInWithPassword, setIsLoggingInWithPassword] = useState<boolean>(
    isRememberMeLayout || !!reAuthUserEmail
  );
  const [samlErrorMessage, setSamlErrorMessage] = useState<?string>(initialSamlErrorMessage);
  const [selectedAuthStrategy, setSelectedAuthStrategy] = useState<?AuthStrategy>(null);
  const isClientLoaded = useIsClientLoaded();

  const inputs = [];
  const newInputRef = () => {
    const ref = createRef();
    inputs.push(ref);
    return ref;
  };

  const selectAuthStrategy = (authStrategy: AuthStrategy) => {
    setSelectedAuthStrategy(authStrategy);
    if (authStrategy.type === authStrategyTypes.saml) {
      loginWithSamlAction({
        email,
        redirectUrl,
        subscriptionId: authStrategy.subscription.id,
      });
    } else {
      setIsLoggingInWithPassword(true);
      setIsAuthStrategyListVisible(false);
    }
  };

  useEffect(() => {
    const [, passwordInput] = inputs;
    if (isLoggingInWithPassword && passwordInput && passwordInput.current) {
      passwordInput.current.inputField.focus();
    }
  }, [isLoggingInWithPassword]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoggingIn(false);
  }, [loginError]);

  useEffect(() => {
    const unlistenHistory = browserHistory.listen(() => {
      clearLoginErrorAction();
      clearSocialLoginErrorAction();
    });
    return () => unlistenHistory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const clearUser = () => {
    setEmail('');
    setPassword('');
    setRememberMe(false);
    setIsAuthStrategyListVisible(false);
    setIsLoggingInWithPassword(false);
    clearLoginErrorAction();
    auth.removeReAuthUserEmail();
    auth.removeUserDetails();

    // force the uncontrolled inputs to re-render with their new (reset) values
    updateKey();
  };

  const onSubmit = async () => {
    setSamlErrorMessage(null);

    const invalidInputs = inputs
      .map((ref) => {
        if (!(ref.current && ref.current.validate())) return ref;
        return null;
      })
      .filter(Boolean);

    if (invalidInputs.length) {
      scrollTo(invalidInputs[0].current, -100);
      return;
    }

    setIsLoggingIn(true);

    if (isLoggingInWithPassword) {
      auth.removeReAuthUserEmail();

      return loginAction(email || user.email, password, rememberMe, redirectUrl, selectedAuthStrategy?.subscription.id);
    }

    const res = await loadAuthStrategiesAction(email);

    const subscriptionIdInRedirectUrl = redirectUrl && getSubscriptionIdFromUrl(redirectUrl);
    if (subscriptionIdInRedirectUrl) {
      const redirectUrlDerivedAuthStrategy = res.find((authStrategy) => {
        return authStrategy.subscription?.id === subscriptionIdInRedirectUrl;
      });

      if (redirectUrlDerivedAuthStrategy) {
        if (redirectUrlDerivedAuthStrategy.type === authStrategyTypes.saml) {
          loginWithSamlAction({
            email,
            redirectUrl,
            subscriptionId: subscriptionIdInRedirectUrl,
          });
        } else {
          setSelectedAuthStrategy(redirectUrlDerivedAuthStrategy);
          setIsLoggingInWithPassword(true);
          setIsLoggingIn(false);
        }
        return;
      }
    }

    const samlAuthStrategies = res.filter(({ type }) => type === authStrategyTypes.saml);
    if (res.length === 1 && samlAuthStrategies.length === 1) {
      loginWithSamlAction({
        email,
        redirectUrl,
        subscriptionId: samlAuthStrategies[0].subscription.id,
      });
    } else if (samlAuthStrategies.length > 0) {
      setIsAuthStrategyListVisible(true);
      setIsLoggingIn(false);
    } else {
      setIsLoggingInWithPassword(true);
      setIsLoggingIn(false);
    }
  };

  return (
    <section className={styles.root}>
      <Heading as="h1" color="colorTextInverted" variant="large" marginBottom="spacingLarge3X">
        {isRememberMeLayout
          ? `Hi ${formatName(user.first_name)}! You’re looking great today.`
          : 'Log in to your account'}
      </Heading>

      {isRememberMeLayout ? (
        <>
          <Box marginBottom="spacingRoot">
            <ReduxAvatarContainer user={user} size="sizeAvatarLarge1X" display="inline-block" />
          </Box>
          <Heading as="p" color="colorTextInverted" marginBottom="spacingSmall2X" variant="small">
            <UserName firstName={user.first_name} lastName={user.last_name} />
          </Heading>
        </>
      ) : (
        Object.keys(socialLogins).map((provider) => (
          <Box key={provider} marginBottom="spacingRoot">
            <SocialLoginButton provider={provider} />
          </Box>
        ))
      )}

      <Box marginTop="spacingLarge3X">
        {/* key used to force the uncontrolled inputs to re-render on demand */}
        <Form onSubmit={onSubmit} key={key} {...clientRequiredAttribute}>
          {loginError && (
            <FormMessage>
              <ErrorTypeMessage error={loginError} />
            </FormMessage>
          )}

          {samlErrorMessage && (
            <FormMessage>
              <ErrorTypeMessage error={{ message: samlErrorMessage }} useAPIMessage />
            </FormMessage>
          )}

          <Input
            // $FlowIgnore
            ref={newInputRef()}
            testHook="login-form-email"
            className={styles.loginInput}
            type="email"
            name="email"
            autoComplete="username"
            placeholder="Email"
            ariaLabel="Email"
            autoCorrect="off"
            isMandatory
            withErrorIcon={false}
            initialValue={email}
            onChange={(name, value) => setEmail(value)}
            inputOverlayInfo={
              isLoggingInWithPassword || isAuthStrategyListVisible ? (
                <Box
                  backgroundColor="colorBackgroundBrandBold"
                  display="flex"
                  position="absolute"
                  top="50%"
                  transform="translateY(-50%)"
                  right="spacingSmall"
                >
                  <Box
                    as="button"
                    color="colorTextInverted"
                    fontSize="fontSizeSmall1X"
                    paddingLeft="spacingSmall2X"
                    lineHeight="lineHeightLarge"
                    // type=reset prevents the browser's autocomplete from capturing clicks on
                    // this as a successful login
                    type="reset"
                    testHook="login-form-change-email-button"
                    transition="transitionRoot"
                    onClick={() => {
                      trackButtonClick({ children: 'Change' });
                      clearUser();
                    }}
                    _hoverAndFocus={{
                      color: 'colorWhiteTransparent70',
                    }}
                  >
                    Change
                  </Box>
                </Box>
              ) : undefined
            }
            disabled={isLoggingInWithSocial || isLoggingInWithPassword || isAuthStrategyListVisible}
          />

          <TransitionGroup>
            {isAuthStrategyListVisible && (
              <DropTransition>
                <Box as="ul" testHook="login-form-auth-strategy-list">
                  {authStrategies.map((authStrategy, i) => (
                    <Choice
                      key={i}
                      isLoading={
                        authStrategy.subscription.id === selectedAuthStrategy?.subscription.id &&
                        selectedAuthStrategy?.type === authStrategyTypes.saml
                      }
                      testHook="login-form-auth-strategy-list-item"
                      title={authStrategy.subscription.name}
                      onClick={() => selectAuthStrategy(authStrategy)}
                      {...(authStrategy.subscription.is_invited
                        ? {
                            tags: [
                              {
                                children: 'Activation pending',
                                color: 'colorTextDanger',
                                variant: 'dashed',
                                tooltip:
                                  'Click on this account to accept your pending invitation to join your school on Atomi.',
                              },
                            ],
                          }
                        : {})}
                      avatar={
                        <Avatar
                          backgroundColor="colorBackgroundBrandSubtle"
                          size="sizeAvatarLarge"
                          icon={
                            authStrategy.subscription.plan_customer_type === authCustomerTypes.school ? (
                              <IconSchool size="sizeIconRoot" strokeWidth="1.5" color="colorIconBrand" />
                            ) : (
                              <IconUser size="sizeIconRoot" strokeWidth="1.5" color="colorIconBrand" />
                            )
                          }
                        />
                      }
                    />
                  ))}
                </Box>
              </DropTransition>
            )}

            {isLoggingInWithPassword && (
              <DropTransition>
                <Input
                  ref={newInputRef()}
                  testHook="login-form-password"
                  className={styles.loginInput}
                  type="password"
                  name="password"
                  placeholder="Password"
                  ariaLabel="Password"
                  isMandatory
                  withErrorIcon={false}
                  onChange={(name, value) => setPassword(value)}
                  inputOverlayInfo={
                    <Box
                      backgroundColor="colorBackground"
                      display="flex"
                      paddingLeft="spacingSmall2X"
                      position="absolute"
                      top="50%"
                      transform="translateY(-50%)"
                      right="spacingSmall"
                    >
                      <Box
                        as={Link}
                        fontSize="fontSizeSmall1X"
                        to="/forgot-password"
                        trackingData={{ cta: trackingCtas.navigateToForgotPassword }}
                        onClick={() => clearUser()}
                      >
                        Forgot?
                      </Box>
                    </Box>
                  }
                  disabled={isLoggingInWithSocial}
                />
              </DropTransition>
            )}

            {!isAuthStrategyListVisible && (
              <DropTransition>
                <Flex
                  direction={{ base: 'column', tablet: 'row' }}
                  alignItems={{ tablet: 'center' }}
                  justifyContent={{ tablet: isRememberMeLayout ? 'center' : 'space-between' }}
                  gap="spacingLarge1X"
                  marginTop="spacingLarge"
                >
                  {!isRememberMeLayout && (
                    <Checkbox
                      isSelected={rememberMe}
                      onChange={(value) => setRememberMe(value)}
                      isDisabled={isLoggingInWithSocial}
                      variant="inverted"
                    >
                      Remember me
                    </Checkbox>
                  )}
                  <Button
                    testHook="login-form-submit-button"
                    isLoading={isLoggingIn}
                    variant="ghost"
                    type="submit"
                    disabled={!isClientLoaded || isLoggingInWithSocial}
                    title={isClientLoaded ? undefined : loadingMessage}
                  >
                    {isLoggingInWithPassword ? 'Log In' : 'Continue'}
                  </Button>
                </Flex>
              </DropTransition>
            )}
          </TransitionGroup>
        </Form>
      </Box>
    </section>
  );
}

export default withCookies(
  (connect(mapStateToProps, {
    clearLoginErrorAction: clearLoginError,
    clearSocialLoginErrorAction: clearSocialLoginError,
    loadAuthStrategiesAction: loadAuthStrategies,
    loginWithSamlAction: loginWithSaml,
    loginAction: login,
  })(LoginForm): React.AbstractComponent<Props>)
);
