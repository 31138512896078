// @flow
export const ACCESS_TOKEN_COOKIE = 'access_token';
export const CLASSES_SOURCE_COOKIE = 'classes_source';
export const CLASSES_VIEW_MODE_COOKIE = 'classes_view_mode';
export const REFRESH_TOKEN_COOKIE = 'refresh_token';
export const USER_DETAILS_COOKIE = 'user_details';
export const SAML_USER_COOKIE = 'saml_user';
export const SAML_USER_COOKIE_MAX_AGE = 300; // Five minutes
export const RE_AUTH_USER_EMAIL_COOKIE = 're_auth_user_email';
export const RE_AUTH_USER_EMAIL_COOKIE_MAX_AGE = 300; // Five minutes
export const COOKIE_MAX_AGE = 365 * 24 * 3600;
