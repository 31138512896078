// @flow
import { Box, Card, Flex, Heading, Stack, Text } from '@getatomi/neon';

import { UnstyledLink } from 'src/components/Link/Link';
import { getClassTopicInsightsUrl, getChallengeProgressReportUrl } from 'src/utils/routes';
import useProgressReportDialog from 'src/hooks/components/useProgressReportDialog';
import postTypes from 'src/constants/postTypes';

export type ContentItemProps = {
  context?: string,
  id: string,
  moduleId: string,
  name: string,
  stats: $ReadOnlyArray<string>,
  title?: string,
  type: $Keys<typeof postTypes>,
};

type SpotlightItemProps = {
  context?: string,
  cta: string,
  name: string,
  stats: $ReadOnlyArray<string>,
};

type ContentSpotlightProps = {
  accountId: string,
  classId: string,
  heading: string,
  items: $ReadOnlyArray<ContentItemProps>,
};

function SpotlightItem(props: SpotlightItemProps) {
  const { name, context, cta, stats, ...ctaProps } = props;

  return (
    <Card
      {...ctaProps}
      aria-label={name}
      height="sizeFull"
      padding={{ base: 'spacingRoot', tablet: 'spacingLarge' }}
      textAlign="left"
      variant="rounded"
    >
      <Flex direction="column" gap="spacingRoot" height="sizeFull">
        <Heading as="h4" variant="small">
          {name}
        </Heading>

        <Stack spacing="spacingSmall1X">
          {context && (
            <Text variant="bodySmall" color="colorTextSubtle">
              {context}
            </Text>
          )}
          {Boolean(stats.length) && (
            <Text variant="bodySmall" color="colorTextSubtle">
              {stats.join(' • ')}
            </Text>
          )}
        </Stack>

        <Text variant="bodySmall" color="colorTextLink" marginTop="auto">
          {cta}
        </Text>
      </Flex>
    </Card>
  );
}

export default function ContentSpotlight(props: ContentSpotlightProps) {
  const { accountId, classId, heading, items } = props;

  const [progressReportDialog, { openProgressReportDialog }] = useProgressReportDialog({
    subscriptionId: accountId,
    classId,
  });

  if (!items.length) return null;

  return (
    <>
      <Heading as="h3" variant="large" marginBottom="spacingLarge4X">
        {heading}
      </Heading>
      <Box
        display="grid"
        gap="spacingRoot"
        gridAutoRows="max-content"
        gridTemplateColumns={{ mobile: 'repeat(2, 1fr)', tablet: 'repeat(3, 1fr)' }}
      >
        {items.map((item) => {
          const { type, id, title, name, context, moduleId, stats } = item;

          let ctaProps = {};
          let cta = '';
          switch (type) {
            case postTypes.challenge:
              ctaProps = {
                as: UnstyledLink,
                to: getChallengeProgressReportUrl(accountId, classId, moduleId, id),
              };
              cta = 'View progress report';
              break;
            case postTypes.section:
              ctaProps = {
                as: UnstyledLink,
                to: getClassTopicInsightsUrl(accountId, classId, id),
              };
              cta = 'View details';
              break;
            default:
              // text or video
              ctaProps = {
                as: 'button',
                type: 'button',
                onClick: () => openProgressReportDialog({ post: { id, name, type }, moduleId }),
              };
              cta = 'View progress report';
              break;
          }

          return (
            <SpotlightItem
              key={name}
              name={title ?? name}
              context={title ? name : context}
              stats={stats}
              cta={cta}
              {...ctaProps}
            />
          );
        })}
        {progressReportDialog}
      </Box>
    </>
  );
}
