// @flow
import { Badge } from '@getatomi/neon';

import { isComingSoonLabel } from 'src/domains/Class/utils/checkIsComingSoon';

import styles from './SectionTags.module.scss';

const tooltips = {
  elective: `An elective is an optional course of study, chosen by your school. Check with your teacher which electives you'll be covering this year!`,
  option: `This is an optional course of study, chosen by your school. Check with your teacher which options you'll be covering this year!`,
};

export type Props = {
  tags: $ReadOnlyArray<{ id: string, name: string }>,
};

export default function SectionTags(props: Props) {
  return (
    <ul className={styles.root}>
      {props.tags.map(({ id, name }) => (
        <li key={id} className={styles.tag}>
          <Badge tooltip={tooltips[name.toLowerCase()]} variant={isComingSoonLabel(name) ? 'dashed' : 'chunky'}>
            {name}
          </Badge>
        </li>
      ))}
    </ul>
  );
}
