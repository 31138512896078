// @flow
import { useMutation } from '@apollo/client';
import { Button, useToast } from '@getatomi/neon';
import pluralize from 'pluralize';

import type { NewAttachedContent } from 'src/domains/Tasks/types';
import type {
  CreateClassRevision,
  CreateClassRevisionVariables,
} from 'src/graphql/types/generated/CreateClassRevision';
import { getAllTopicsWithQuestions, convertTimeLimitToSeconds } from 'src/components/RevisionDialog/utils';
import { generateSession, type RevisionSession } from 'src/components/RevisionDialog/generateSession';
import { trackingEvents } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';
import { getRevisionUrl } from 'src/utils/routes';
import type {
  Module,
  ContentTypeFilter,
  Question,
  QuestionTypeFilter,
  SessionLengthFilter,
} from 'src/components/RevisionDialog/useRevisionDialogState';
import type { ValidationParams } from 'src/components/RevisionDialog/useRevisionDialogValidation';

import CREATE_CLASS_REVISION from './CreateClassRevision.graphql';

type Props = {|
  accountId: string,
  classId: string,
  contentType: ContentTypeFilter,
  draftRevision: ?RevisionSession,
  formId: string,
  isTimeLimitEnabled: boolean,
  onCreate: (newRevision: NewAttachedContent) => void,
  questions: Array<Question>,
  questionType: Array<QuestionTypeFilter>,
  selectedTopics: Array<string>,
  sessionLength: SessionLengthFilter,
  subjectCode: string,
  subjectTree: Array<Module>,
  timeLimitMinutes: string,
  validateOnSubmit: (validationParams: ValidationParams) => boolean,
|};

function getSelectedTopicsWithQuestions(topics: Array<string>, subjectTree: Array<Module>): Array<string> {
  const allTopicsWithQuestions = getAllTopicsWithQuestions(subjectTree);
  const selectedTopicsWithQuestions = topics.filter((topicId) => allTopicsWithQuestions.includes(topicId));
  return selectedTopicsWithQuestions;
}

export function autoGenerateTitle(selectedTopicsWithQuestions: Array<string>, subjectTree: Array<Module>): string {
  const allTopicsWithQuestions = getAllTopicsWithQuestions(subjectTree);

  if (selectedTopicsWithQuestions.length === allTopicsWithQuestions.length) {
    return 'Revision: all topics';
  }

  const topicCount = selectedTopicsWithQuestions.length;
  return `Revision: ${topicCount} ${pluralize('topic', topicCount)}`;
}

export default function AddToTaskButton(props: Props) {
  const {
    accountId,
    classId,
    contentType,
    draftRevision,
    formId,
    isTimeLimitEnabled,
    onCreate,
    questions,
    questionType,
    selectedTopics,
    sessionLength,
    subjectCode,
    subjectTree,
    timeLimitMinutes,
    validateOnSubmit,
  } = props;

  const [createClassRevision, { loading: isSubmitting }] = useMutation<
    CreateClassRevision,
    CreateClassRevisionVariables
  >(CREATE_CLASS_REVISION, {
    onCompleted: (data) => {
      const { id, duration, timeLimitInSeconds, title } = data.createClassRevision;
      onCreate({
        id,
        duration: isTimeLimitEnabled && timeLimitInSeconds ? timeLimitInSeconds : duration,
        name: title,
        subjectCode,
        type: 'revision',
        url: getRevisionUrl(accountId, classId, id),
      });
    },
  });

  const toast = useToast();

  const onSubmit = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    const hasValidationError = validateOnSubmit({
      hasQuestionTypes: questionType.length > 0,
      hasTopics: questions.length > 0,
      isTimeLimitEnabled,
      timeLimitMinutes,
    });
    if (hasValidationError) {
      return;
    }

    const timeLimitInSeconds = isTimeLimitEnabled ? convertTimeLimitToSeconds(timeLimitMinutes) : null;

    trackEvent(trackingEvents.teacherRevisionCreationRequested, {
      classId,
      content: contentType,
      duration: sessionLength,
      subject: subjectCode,
      questionTypes: questionType,
      timeLimitInSeconds,
    });

    const { questions: sessionQuestions } =
      draftRevision ??
      generateSession({
        isTeacher: true,
        questions,
        questionTypeFilters: questionType,
        sessionLengthFilter: sessionLength,
      });

    try {
      const selectedTopicsWithQuestions = getSelectedTopicsWithQuestions(selectedTopics, subjectTree);

      await createClassRevision({
        variables: {
          input: {
            classId,
            questionIds: sessionQuestions.map(({ id }) => id),
            title: autoGenerateTitle(selectedTopicsWithQuestions, subjectTree),
            topicIds: selectedTopicsWithQuestions,
            timeLimitInSeconds,
          },
        },
      });
    } catch {
      toast.error('There was an error creating this revision session. Please try again.');
    }
  };

  return (
    <Button form={formId} isLoading={isSubmitting} onClick={onSubmit} size="small" type="submit">
      Add to task
    </Button>
  );
}
