// @flow
import { Alert, useToast } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import links from 'src/constants/links';
import { resendInvites } from 'src/actions/users';
import type { BoundAction, UserType } from 'src/types';

type Props = {
  isLoading: boolean,
  onDismiss: () => void,
  pendingStudents: Array<UserType>,
  resendInvitesAction: BoundAction<typeof resendInvites>,
};

export default function PendingChildAlert(props: Props) {
  const { pendingStudents, resendInvitesAction, onDismiss, isLoading } = props;
  const toast = useToast();

  const onResendInvite = async () => {
    try {
      await resendInvitesAction(pendingStudents.map((student) => student.id));
      toast.success('The invitation was sent successfully.');
    } catch (error) {
      toast.error('There was an error when sending the invite. Please try again.');
    }
  };

  return (
    <Alert
      isBanner
      variant="info"
      onDismiss={onDismiss}
      buttonProps={{
        children: 'Resend invite',
        onClick: onResendInvite,
        isLoading,
        testHook: 'alert-pending-child-button',
      }}
      testHook="alert-pending-child"
    >
      Your child hasn’t accepted their invitation to join your account yet.{' '}
      <Link variant="monochrome" href={links.support.joiningYourFamilyAccount} isExternal>
        Learn more
      </Link>
    </Alert>
  );
}
