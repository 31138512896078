// @flow
import { useEffect, useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Box, HelpText, Label, useId, tokens } from '@getatomi/neon';

const inputStyle = {
  base: {
    color: tokens.colorText,
    // tokens.fontSizeRoot rem can't be used here because the Stripe component is displayed in an
    // iframe
    fontSize: '16px',
    fontFamily: tokens.fontFamilySystem,
    '::placeholder': {
      color: tokens.colorTextSubtler,
    },
  },
};

type CardInputProps = {
  error: ?string,
};

export default function CardInput(props: CardInputProps) {
  const [error, setError] = useState<?string>(props.error);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const cardElementId = useId();

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const handleChange = (response) => {
    setError(response.error?.message);
  };

  let borderColor = 'colorBorderInput';
  if (error) {
    borderColor = 'colorBorderDanger';
  } else if (isFocused) {
    borderColor = 'colorBorderFocused';
  }

  return (
    <Box width="sizeFull" testHook="card-input">
      <Label htmlFor={cardElementId}>Card</Label>
      <Box
        borderWidth="borderWidthRoot"
        borderStyle="solid"
        borderColor={borderColor}
        borderRadius="borderRadiusSmall"
        padding="spacingSmall"
      >
        <CardElement
          id={cardElementId}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          options={{
            style: inputStyle,
          }}
        />
      </Box>
      {error && <HelpText variant="error">{error}</HelpText>}
    </Box>
  );
}
