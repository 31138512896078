// @flow
import { Component } from 'react';

import RequestCallbackDialog from 'src/components/RequestCallbackDialog/RequestCallbackDialog';
import Logger from 'src/utils/Logger';

const log = new Logger('components/RequestCallback');

export type RequestCallbackProps = {
  children: Function,
  className?: string,
  hasContainer?: boolean,
  isGeneralRequest?: boolean,
};

type State = {
  isModalOpened: boolean,
};

export default class RequestCallback extends Component<RequestCallbackProps, State> {
  static defaultProps = {
    hasContainer: true,
  };

  state = {
    isModalOpened: false,
  };

  onToggleModal = (e?: SyntheticEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }

    this.setState((prevState) => ({ isModalOpened: !prevState.isModalOpened }));
  };

  render() {
    const { children, className, hasContainer, isGeneralRequest } = this.props;

    if (!children) {
      log.warn('You must provide the children prop to the RequestCallback component');
      return null;
    }

    const contents = (
      <>
        {children(this.onToggleModal)}
        <RequestCallbackDialog
          isGeneralRequest={isGeneralRequest}
          isOpen={this.state.isModalOpened}
          onClose={this.onToggleModal}
        />
      </>
    );

    return hasContainer ? <div className={className}>{contents}</div> : contents;
  }
}
