// @flow
import { useState } from 'react';

import Button from 'src/components/Button/Button';

import SamlSsoDeleteDialog from './SamlSsoDeleteDialog/SamlSsoDeleteDialog';

export default function SamlSsoDelete() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button variant="tertiary" onClick={() => setIsOpen(true)}>
        Delete configuration
      </Button>
      <SamlSsoDeleteDialog isOpen={isOpen} onDone={() => setIsOpen(false)} />
    </>
  );
}
