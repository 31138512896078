// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import type { GetLevelSubjects, GetLevelSubjectsVariables } from 'src/graphql/types/generated/GetLevelSubjects';

import GET_LEVEL_SUBJECTS from './GetLevelSubjects.graphql';

type Subject = {|
  code: string,
  isLocked: boolean,
  name: string,
|};

type Output = {|
  error: ?ApolloError,
  loading: boolean,
  subjects: ?$ReadOnlyArray<Subject>,
|};

export type SelectSubjectVariables = {|
  levelId: string | null,
  subscriptionId: string,
|};

export default function useGetLevels(props: SelectSubjectVariables): Output {
  const { levelId, subscriptionId } = props;

  const { data, error, loading } = useQuery<GetLevelSubjects, GetLevelSubjectsVariables>(GET_LEVEL_SUBJECTS, {
    variables: {
      accountId: subscriptionId,
      filters: {
        levelId,
      },
    },
  });

  if (error) {
    return {
      error,
      loading: false,
      subjects: null,
    };
  }

  if (loading && !data) {
    return {
      loading: true,
      error: null,
      subjects: null,
    };
  }

  const subjectData = data?.me?.account.subjects;
  invariant(subjectData, 'Subject data should be defined');

  const subjects = subjectData.map(({ __typename, subject }) => ({
    isLocked: __typename === 'LockedLevelSubject',
    code: subject.code,
    name: subject.shortName,
  }));

  return {
    subjects,
    loading: false,
    error: null,
  };
}
