// @flow
import { setLocale } from 'yup';

// yup uses template-like syntax so we can ignore the template curly warning
/* eslint-disable no-template-curly-in-string */
setLocale({
  array: {
    length: '${label} must have ${length} items',
    max: '${label} field must have less than or equal to ${max} items',
    min: '${label} field must have at least ${min} items',
  },
  boolean: {
    isValue: '${label} field must be ${value}',
  },
  date: {
    max: '${label} field must be at earlier than ${max}',
    min: '${label} field must be later than ${min}',
  },
  mixed: {
    default: '${label} is invalid',
    defined: '${label} must be defined',
    notOneOf: '${label} must not be one of the following values: ${values}',
    notType: '${label} is not the correct type',
    oneOf: '${label} must be one of the listed values',
    required: '${label} is required',
  },
  number: {
    integer: '${label} must be an integer',
    lessThan: '${label} must be less than ${less}',
    max: '${label} must be at maximum ${max} characters',
    min: '${label} must be at minimum ${min} characters',
    moreThan: '${label} must be greater than ${more}',
    negative: '${label} must be a negative number',
    positive: '${label} must be a positive number',
  },
  object: {
    noUnknown: '${label} field has unspecified keys: ${unknown}',
  },
  string: {
    email: '${label} must be a valid email',
    length: '${label} must be exactly ${length} characters',
    lowercase: '${label} must be a lowercase string',
    matches: '${label} must match the following: ${regex}',
    max: '${label} must be at most ${max} characters',
    min: '${label} must be at least ${min} characters',
    trim: '${label} must be a trimmed string',
    uppercase: '${label} must be a upper case string',
    url: '${label} must be a valid URL',
    uuid: '${label} must be a valid UUID',
  },
});
/* eslint-enable */
