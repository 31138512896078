// @flow
import keyMirror from 'keymirror';

// userAccountStatuses represents the status of the user to the account e.g.
// a user's status can be active (ie. free retail subscription - email verified, profile updated, etc.)
// but their "user account status" can be invited (i.e. for a school account)

// the legacy user account statuses are returned from the user.subscription_status field
// in the redux store
const legacy = keyMirror({
  active: null,
  invited: null,
  archived: null,
});

// these user account statuses are returned from the user.accountStatus field in the federated graph
const statuses = keyMirror({
  ACTIVE: null,
  INVITED: null,
});

export default Object.freeze({ ...statuses, legacy });
