import _ from 'lodash';

import { checkAccessToken, logout } from 'src/actions/auth';

export default (store) => (next) => async (action) => {
  const { request } = action;

  // we attempt to refresh the token on every call, except in the following conditions
  if (!request || _.get(request, 'endpoint.sendAccessToken') === false || request.refreshToken === false) {
    return next(action);
  }

  if (request) {
    try {
      await store.dispatch(checkAccessToken());
    } catch (e) {
      return store.dispatch(logout());
    }
  }

  return next(action);
};
