// @flow
import moment from 'moment';

import type { GetClassTasksVariables } from 'src/graphql/types/generated/GetClassTasks';

import type { TaskFilterStatus } from '../useClassTaskListFilters';

type FilterParams = {
  dateDue?: {|
    +from?: ?any,
    +to?: ?any,
  |},
  isDue?: boolean,
  status?: TaskFilterStatus,
};

type OrderByClause = $ReadOnlyArray<{|
  column: 'DUE_AT',
  order: 'DESC' | 'ASC',
|}>;

type Props = {
  classId: string,
  postId: ?string,
  statusFilter: TaskFilterStatus,
  subscriptionId: string,
};

const startOfLastWeek = moment().startOf('isoWeek').subtract(7, 'days').format();

const endOfNextWeek = moment().endOf('isoWeek').add(7, 'days').format();

export const getFilterParams = (newFilter?: string): FilterParams => {
  switch (newFilter) {
    case 'upcoming':
      return {
        isDue: false,
      };
    case 'past':
      return {
        isDue: true,
      };
    case 'draft':
      return {
        status: 'draft',
      };
    case 'all':
      return {};
    default:
      // current
      return {
        dateDue: {
          from: startOfLastWeek,
          to: endOfNextWeek,
        },
      };
  }
};

export const getOrderByParams = (filter?: string): OrderByClause => [
  {
    column: 'DUE_AT',
    order: filter === 'past' ? 'DESC' : 'ASC',
  },
];

export default function getClassTasksVariables(props: Props): $Exact<GetClassTasksVariables> {
  const { classId, subscriptionId, statusFilter, postId } = props;

  return {
    classId,
    accountId: subscriptionId,
    filters: {
      ...getFilterParams(statusFilter),
      lessonId: postId,
    },
    orderBy: getOrderByParams(statusFilter),
  };
}
