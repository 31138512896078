// @flow
import { ApolloError, useQuery } from '@apollo/client';
import invariant from 'invariant';

import type { User as AvatarUser } from 'src/components/Avatar/Avatar';
import type {
  GetDashboardClasses,
  GetDashboardClassesVariables,
  GetDashboardClasses_me_account_classes_edges_node as GqlClass,
} from 'src/graphql/types/generated/GetDashboardClasses';
import classesSourceFilters from 'src/constants/classesSourceFilters';

import GET_DASHBOARD_CLASSES from './GetDashboardClasses.graphql';

type Props = {|
  classesSource: $Keys<typeof classesSourceFilters>,
  isLoggedInAsStudent: boolean,
  isLoggedInAsSuperAdmin: boolean,
  keywords: string | void,
  paginationCursor: {| after?: string, before?: string |},
  subscriptionId: string,
  updatePageLocation: (query: { [string]: string | void }, resetFocus?: boolean) => void,
|};

export type ClassData = {|
  classProgress?: number,
  createdAt?: string,
  id: string,
  levels: $ReadOnlyArray<{|
    id: string,
    name: string,
  |}>,
  name: string,
  studentCount?: number,
  studentProgress?: {
    completedContent: number,
    totalContent: number,
  },
  subject: {|
    code: string,
    color: string,
    groupCode: string,
    icon: string,
    name: string,
  |},
  teachers: $ReadOnlyArray<{ ...$Exact<AvatarUser>, id: string }>,
|};

type Output = {|
  classes: ?$ReadOnlyArray<ClassData>,
  error: ?ApolloError,
  goToNextPage?: () => void,
  goToPreviousPage?: () => void,
  loading: boolean,
  refetch: () => void,
  userId: string,
|};

function transformClass(gqlClass: GqlClass, isLoggedInAsSuperAdmin: boolean): ClassData {
  const schoolAccountLevel = gqlClass.level;
  const retailAccountLevels = gqlClass.subject.levels.map((level) => ({ id: level.id, name: level.name }));

  const studentCount = gqlClass.students ? gqlClass.students.length : undefined;

  const classProgress =
    gqlClass.studentMetrics && gqlClass.studentMetrics.__typename === 'ClassStudentMetrics'
      ? gqlClass.studentMetrics.progress.completionRate ?? 0
      : undefined;

  return {
    id: gqlClass.id,
    createdAt: isLoggedInAsSuperAdmin ? gqlClass.createdAt : undefined,
    classProgress,
    name: gqlClass.name,
    levels: schoolAccountLevel ? [{ id: schoolAccountLevel.id, name: schoolAccountLevel.name }] : retailAccountLevels,
    studentCount,
    subject: {
      code: gqlClass.subject.code,
      color: gqlClass.subject.color,
      groupCode: gqlClass.subject.groupCode,
      icon: gqlClass.subject.icon,
      name: gqlClass.subject.name,
    },
    teachers: gqlClass.teachers.map((teacher) => ({
      id: teacher.id,
      accountStatus: teacher.accountStatus,
      avatar: teacher.avatar,
      color: teacher.color,
      email: teacher.email,
      firstName: teacher.firstName,
      lastName: teacher.lastName,
    })),
  };
}

export default function useGetClasses(props: Props): Output {
  const {
    classesSource,
    isLoggedInAsStudent,
    isLoggedInAsSuperAdmin,
    keywords,
    paginationCursor,
    subscriptionId,
    updatePageLocation,
  } = props;

  const { data, error, loading, refetch } = useQuery<GetDashboardClasses, GetDashboardClassesVariables>(
    GET_DASHBOARD_CLASSES,
    {
      variables: {
        accountId: subscriptionId,
        filters: {
          searchTerm: keywords ?? undefined,
          source: classesSource === classesSourceFilters.user ? 'USER' : 'SUBSCRIPTION',
        },
        ...paginationCursor,
        isStudent: isLoggedInAsStudent,
      },
    }
  );

  if (error) {
    return {
      classes: null,
      error,
      loading: false,
      refetch,
      userId: '',
    };
  }

  const hasPaginationCursor = paginationCursor.after || paginationCursor.before;
  if ((loading && !data) || (loading && hasPaginationCursor)) {
    return {
      classes: null,
      error: null,
      loading: true,
      refetch,
      userId: '',
    };
  }

  const userData = data?.me;
  const classesData = userData.account?.classes;
  invariant(userData, classesData, 'Classes data should be defined');

  const classes = classesData.edges.map(({ node }) => transformClass(node, isLoggedInAsSuperAdmin));

  const { endCursor, hasNextPage, hasPreviousPage, startCursor } = classesData.pageInfo;
  const goToNextPage =
    hasNextPage && endCursor ? () => updatePageLocation({ after: endCursor, before: undefined, keywords }) : undefined;
  const goToPreviousPage = hasPreviousPage
    ? () => updatePageLocation({ after: undefined, before: startCursor, keywords })
    : undefined;

  return {
    classes,
    error: null,
    goToNextPage,
    goToPreviousPage,
    loading: false,
    refetch,
    userId: userData.id,
  };
}
