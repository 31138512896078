// @flow
import { Box, Flex, Avatar, TextLoader } from '@getatomi/neon';

type Cell = {
  row: {
    index: number,
    original: any,
  },
};

export type LoaderColumn = {
  accessorKey: string,
  cell: (cell: Cell) => React.Node,
  header: () => React.Node,
  meta?: {
    width?: number,
  },
};

export type LoaderData = {};

export function generatePlaceholderRows(visibleLoadingRows: number): $ReadOnlyArray<LoaderData> {
  const rows = Array.from({ length: visibleLoadingRows }, () => ({}));

  return rows;
}

export function generatePlaceholderColumns({
  columnCount,
  shouldRenderStudentColumn = true,
  headerHeight,
}: {
  columnCount: number,
  headerHeight?: number,
  shouldRenderStudentColumn?: boolean,
}): $ReadOnlyArray<LoaderColumn> {
  const columns = Array.from({ length: columnCount }, (v, i) => ({
    accessorKey: `loading-${i}`,
    header: () => <Box width="sizeFull" height={headerHeight ?? 'auto'} />,
    cell: ({ row }) => {
      const studentColumn = shouldRenderStudentColumn && i === 1;
      if (studentColumn) {
        return (
          <Flex direction="row" alignItems="center" gap="spacingSmall1X" testHook="student-column-placeholder">
            <Avatar variant="muted" />
            <TextLoader width="50%" />
          </Flex>
        );
      }
      return <TextLoader maxWidth={row.index % 2 ? '50%' : 'sizeFull'} />;
    },
  }));

  return columns;
}
