// @flow
import Logger from 'src/utils/Logger';

const log = new Logger('utils/payments');

type Stripe = $FlowSuppressAny;
type CardElement = $FlowSuppressAny;
export type PaymentMethodId = string;
export type CreateIntent = (
  paymentMethodId: PaymentMethodId
) => Promise<{ client_secret: string, id: string, object: string, status: string }>;

export async function handleCardPayment(stripe: Stripe, paymentMethodId: PaymentMethodId, createIntent: CreateIntent) {
  const { object, status, client_secret: clientSecret, id: intentId } = await createIntent(paymentMethodId);
  log.info('Intent created', intentId);

  // https://stripe.com/docs/payments/intents#intent-statuses
  // https://hschub.atlassian.net/wiki/spaces/DEV/pages/414875795/PSD2+Compliance+-+Payment+flows+Draft
  if (['requires_source_action', 'requires_action'].includes(status)) {
    const stripeAction =
      object === 'setup_intent' ? stripe.handleCardSetup(clientSecret) : stripe.handleCardAction(clientSecret);
    const { error: cardError } = await stripeAction;
    if (cardError) {
      throw cardError;
    }
  }

  return intentId;
}

export async function initCardPayment(stripe: Stripe, cardElement: CardElement, createIntent: CreateIntent) {
  const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
  });
  if (paymentMethodError) {
    throw paymentMethodError;
  }

  log.info('Payment method created', paymentMethod);

  const intentId = await handleCardPayment(stripe, paymentMethod.id, createIntent);

  return intentId;
}
