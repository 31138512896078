// @flow
import { useEffect, useRef, useState } from 'react';

export default function usePoll(
  trigger: boolean,
  callback: () => Promise<void>,
  condition: boolean,
  onComplete: () => mixed,
  frequency: number
) {
  const [delay, setDelay] = useState(null);
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function poll() {
      // Flow does not pick up savedCallback.current() holding the callback argument
      // $FlowIgnore
      savedCallback.current();
    }
    if (delay !== null && !condition && trigger) {
      const id = setInterval(poll, delay);
      return () => clearInterval(id);
    }
    condition && delay && onComplete();
    setDelay(condition ? null : frequency);
  }, [delay, condition, trigger, onComplete, frequency]);

  if (!condition && !trigger) return [false];

  return [!!delay];
}
