// @flow

import type { QuestionType } from '../constants/questionTypes';

type WithTypename = {
  +__typename: string,
};

export function getChallengeItemType(item: WithTypename): QuestionType {
  switch (item.__typename) {
    case 'TextSnippet':
      return 'TEXT';
    case 'VideoSnippet':
      return 'VIDEO';
    case 'DragAndDropQuestion':
      return 'DRAG_AND_DROP';
    case 'ExactAnswerQuestion':
      return 'EXACT_ANSWER';
    case 'MultipleChoiceQuestion':
      return 'MULTIPLE_CHOICE';
    case 'SelfMarkedLiteQuestion':
      return 'SELF_MARKED_LITE';
    case 'SelfMarkedQuestion':
      return 'SELF_MARKED';
    default:
      throw new Error(`Unknown item type: ${item.__typename}`);
  }
}
