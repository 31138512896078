// @flow
type ClassTaskStudent = {
  id: string,
  progress?: Object,
};

export function mergeTaskStudents(
  existing?: $ReadOnlyArray<?ClassTaskStudent>,
  incoming: $ReadOnlyArray<ClassTaskStudent>,
  {
    mergeObjects,
  }: {
    mergeObjects: <T>(existing: ?T, incoming: T) => T,
  }
) {
  if (!existing) return incoming;
  return [
    ...incoming.map((student) => {
      // $FlowIgnore existing is defined or we'd have already returned
      const existingStudent = existing.find((studentInCache) => studentInCache.id === student.id);
      if (!existingStudent) return student;
      const mergedCache = mergeObjects(existingStudent, student);
      if (student.progress) {
        mergedCache.progress = mergeObjects(existingStudent.progress, student.progress);
      }
      return mergedCache;
    }),
  ];
}

function groupTaskProgressUnderTask() {
  /**
   * We need to disable normalization for `ClassTaskStudent` and `StudentTaskProgress`
   * in order for task progress to be cached properly. Without it progress records
   * aren't task-specific but rather student-specific, where the same progress record gets
   * referenced by all tasks for a given student. Therefore, grouping progress under a task
   * (i.e. `ClassTask`) ensures progress records are task-specific.
   *
   * Replication: https://github.com/hschub/carbon/pull/2028#discussion_r1099497565
   * JIRA ticket: https://hschub.atlassian.net/browse/PROD-4993
   * Apollo docs: https://www.apollographql.com/docs/react/caching/cache-configuration/#disabling-normalization
   */

  return {
    ClassTask: {
      fields: {
        students: {
          merge: mergeTaskStudents,
        },
      },
    },
    ClassTaskStudent: {
      keyFields: false,
    },
    StudentTaskProgress: {
      keyFields: false,
    },
  };
}

type MetricsStudent = {
  studentId: string,
};

type MetricsStudents = {
  students: Array<MetricsStudent>,
};

export function mergeMetricsStudents(
  existing?: MetricsStudents,
  incoming: MetricsStudents,
  {
    mergeObjects,
  }: {
    mergeObjects: <T>(existing: ?T, incoming: T) => T,
  }
) {
  /**
   * Apollo-client doesn't know how to merge some of the data inside our metrics fields,
   * e.g. the student objects within our students arrays (metrics.progress.students) so
   * we need to define a custom merge function for those fields.
   */
  const mergedCache = mergeObjects<MetricsStudents>(existing, incoming);
  if (!existing || !incoming.students) return mergedCache;
  mergedCache.students = mergedCache.students.map((student) => {
    // $FlowIgnore existing is defined or we'd have already returned
    if (!existing.students) return student;
    const existingStudent = existing.students.find((studentInCache) => studentInCache.studentId === student.studentId);
    return mergeObjects<MetricsStudent>(existingStudent, student);
  });
  return mergedCache;
}

function consolidateStudentsWithinMetrics() {
  return {
    fields: {
      progress: {
        merge: mergeMetricsStudents,
      },
      assessment: {
        merge: mergeMetricsStudents,
      },
      tasks: {
        merge: mergeMetricsStudents,
      },
    },
  };
}

export function mergeIdenticalArrays(
  existing?: $ReadOnlyArray<?any>,
  incoming: $ReadOnlyArray<any>,
  {
    mergeObjects,
  }: {
    mergeObjects: <T>(existing: ?T, incoming: T) => T,
  }
) {
  /**
   * Merge the entities within two arrays, assuming they're the same things in the same order.
   */
  if (!existing || existing.length !== incoming.length) return incoming;
  return [
    ...incoming.map((item, index) => {
      // $FlowIgnore existing is defined or we'd have already returned
      const existingItem = existing[index];
      return mergeObjects(existingItem, item);
    }),
  ];
}

/**
 * These type policies allow us to configure and tweak how Apollo client normalizes and
 * caches our data.
 * See https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields
 */
function apolloTypePolicies() {
  return {
    AccountMetrics: {
      keyFields: ['accountId'],
    },
    Class: {
      fields: {
        subject: {
          merge: true, // classes only ever have one subject, so we can merge any fields
        },
      },
    },
    ClassMetrics: {
      keyFields: ['classId'],
      ...consolidateStudentsWithinMetrics(),
    },
    ClassContentNodeMetrics: {
      keyFields: ['contentNodeId', 'classId'],
      ...consolidateStudentsWithinMetrics(),
    },
    ClassVideoLessonMetrics: {
      keyFields: ['lessonId', 'classId'],
      ...consolidateStudentsWithinMetrics(),
    },
    StudentVideoLessonMetrics: {
      keyFields: ['lessonId', 'classId'],
      fields: {
        progress: {
          merge: true,
        },
      },
    },
    ClassTextLessonMetrics: {
      keyFields: ['lessonId', 'classId'],
      ...consolidateStudentsWithinMetrics(),
    },
    StudentTextLessonMetrics: {
      keyFields: ['lessonId', 'classId'],
      fields: {
        progress: {
          merge: true,
        },
      },
    },
    ClassChallengeLessonMetrics: {
      keyFields: ['lessonId', 'classId'],
      ...consolidateStudentsWithinMetrics(),
    },
    StudentChallengeLessonMetrics: {
      keyFields: ['lessonId', 'classId'],
      fields: {
        assessment: {
          merge: true,
        },
        progress: {
          merge: true,
        },
      },
    },
    ClassRevisionMetrics: {
      keyFields: ['revisionId', 'classId'],
      ...consolidateStudentsWithinMetrics(),
    },
    StudentRevisionMetrics: {
      keyFields: ['revisionId', 'classId'],
      fields: {
        progress: {
          merge: true,
        },
      },
    },
    ...groupTaskProgressUnderTask(),
    // Used in VQR: `me.account.class.users.lesson.latestAttempt.questionAttempts
    ChallengeAttempt: {
      fields: {
        questionAttempts: {
          merge: mergeIdenticalArrays,
        },
      },
    },
    Subject: {
      keyFields: ['code'],
    },
    CustomerPlan: {
      merge: true,
    },
  };
}

export default apolloTypePolicies;
