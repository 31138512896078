// @flow
import { useState } from 'react';
import { connect } from 'react-redux';
import { AlertDialog, useToast } from '@getatomi/neon';

import PasswordRequiredDialog from 'src/components/PasswordRequiredDialog/PasswordRequiredDialog';
import type { BoundAction, UserType, ReduxState } from 'src/types';
import Link from 'src/components/Link/Link';
import SubscriptionLink from 'src/components/SubscriptionLink/SubscriptionLink';
import { hasPassword as hasPasswordSelector } from 'src/reducers/auth';
import { logout } from 'src/actions/auth';
import { deleteAccount } from 'src/actions/users';
import { parseValidationErrors } from 'src/api/validation';
import links from 'src/constants/links';

type Props = {
  isAccountOwner: boolean,
  onClose: () => void,
  user: UserType,
};

type InjectedProps = Props & {
  deleteAccountAction: BoundAction<typeof deleteAccount>,
  hasPassword: boolean,
  logoutAction: BoundAction<typeof logout>,
};

function DeleteAccountDialog(props: InjectedProps) {
  const { user, isAccountOwner, onClose, hasPassword, deleteAccountAction, logoutAction } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<?string>(null);
  const toast = useToast();

  const onDeleteAccount = async (password: ?string) => {
    setIsLoading(true);
    try {
      await deleteAccountAction(user.id, password);
      logoutAction();
    } catch (error) {
      setIsLoading(false);
      const errors = parseValidationErrors(error);
      setServerErrors(errors.join('<br />'));

      if (errors.length === 0) {
        onClose();
        toast.error('There was an error deleting your account. Please try again.');
      }
    }
  };

  return isAccountOwner ? (
    <AlertDialog
      variant="danger"
      heading="Hold up, you can’t delete your account because you are the school account owner."
      onConfirm={onClose}
      onConfirmLabel="OK, got it"
      isOpen
    >
      Either <SubscriptionLink users>transfer your ownership</SubscriptionLink> first and try again, or{' '}
      <Link href={links.support.home} isExternal>
        contact support
      </Link>{' '}
      if you want delete all users and close your school account.
    </AlertDialog>
  ) : (
    <PasswordRequiredDialog
      isVisible={!isAccountOwner}
      heading="Delete my account"
      hasPassword={hasPassword}
      description={
        <>
          <p>
            <strong>Deleting your account is permanent:</strong> you’ll be logged out immediately when you delete your
            account. You won’t be able to undo it and you won’t be able to access or restore your account once it has
            been done.
          </p>
          <p>
            <strong>This will delete all accounts associated with your email address.</strong> If you only want to
            delete one of your accounts, please{' '}
            <Link href={links.contact} isExternal>
              get in touch with support
            </Link>{' '}
            instead.
          </p>
        </>
      }
      helpInfo={
        <Link href={links.support.deleteAccount} isExternal variant="monochrome">
          Learn more about deleting your account in the Help Centre.
        </Link>
      }
      inputInfo="Enter your password to confirm the request to delete your account."
      onClose={onClose}
      ctaIsLoading={isLoading}
      ctaText="Delete my account"
      ctaOnClick={onDeleteAccount}
      ctaVariant="danger"
      error={serverErrors}
    />
  );
}

export default (connect((state: ReduxState) => ({ hasPassword: hasPasswordSelector(state) }), {
  deleteAccountAction: deleteAccount,
  logoutAction: logout,
})(DeleteAccountDialog): React.AbstractComponent<Props>);
