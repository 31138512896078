// @flow
import { SSRProvider } from '@react-aria/ssr';
import Helmet from 'react-helmet';
import { type LocationShape, type ContextRouter } from 'react-router';
import { useEffect, useRef } from 'react';
import { SkipLink, ThemeProvider, Toaster } from '@getatomi/neon';
import 'what-input';

import ErrorPage from 'src/domains/ErrorPage/ErrorPage';
import RouteError from 'src/domains/RouteError/RouteError';
import headerStyles from 'src/layouts/AuthenticatedLayout/Header/Header.module.scss';
import useIntercomWidget from 'src/hooks/useIntercomWidget';

import ErrorBoundary from './ErrorBoundary/ErrorBoundary';

type Props = {
  children: React.Node,
  location: LocationShape,
  router: ContextRouter,
};

function useRefocusOnRouteChange(location: LocationShape, focusMagnetRef: { current: null | HTMLSpanElement }) {
  useEffect(() => {
    const { action, hash, state } = location;
    // Draw the focus back to the top of the page if react-router is navigating
    // and the nav action is not an initial page load, or back/forward action
    // Solves https://github.com/ReactTraining/react-router/issues/5210
    if (action !== 'POP') {
      if (state?.refocus === false || Boolean(hash)) {
        return;
      }
      // $FlowIgnore (Flow does not yet support method calls in optional chains)
      focusMagnetRef.current?.focus();
    }
  }, [focusMagnetRef, location]);
}

function useScrollTopOnRouteChange(location: LocationShape) {
  useEffect(() => {
    const { action, hash, state } = location;
    // Scroll to the top of the page when the route changes
    if (state?.scrollTop === false || action === 'POP') {
      return;
    }
    if (hash) {
      // scroll to id with hash
      const el = document.getElementById(hash.replace('#', ''));
      if (el) {
        const stickyHeader = document.getElementsByClassName(headerStyles.header);
        const scrollOffsetY = stickyHeader.length === 0 ? 0 : -stickyHeader[0].offsetHeight;

        el.scrollIntoView();
        window.scrollBy(0, scrollOffsetY);
      }
    } else {
      // scroll to top
      window.scrollTo(0, 0);
    }
  }, [location]);
}

function App(props: Props) {
  const { children, location, router } = props;
  const { isEmbed } = location.query;
  const ref = useRef<null | HTMLSpanElement>(null);

  useIntercomWidget({ hideOnMount: !!isEmbed });

  useRefocusOnRouteChange(location, ref);
  useScrollTopOnRouteChange(location);

  return (
    <SSRProvider>
      <ThemeProvider navigate={router.push}>
        <ErrorBoundary fallbackComponent={<ErrorPage code={500} withFeedbackLink />}>
          <Helmet titleTemplate="%s | Atomi" />
          <span data-test="focus-magnet" ref={ref} tabIndex={-1} />
          <SkipLink />
          <RouteError>{children}</RouteError>
          <Toaster />
        </ErrorBoundary>
      </ThemeProvider>
    </SSRProvider>
  );
}

export default App;
