// @flow
import { ApolloError, useQuery } from '@apollo/client';
import invariant from 'invariant';

import createCellValue, { type CellValue } from 'src/utils/createCellValue';
import type { GetEmailLogs, GetEmailLogsVariables } from 'src/graphql/types/generated/GetEmailLogs';

import GET_EMAIL_LOGS from './GetEmailLogs.graphql';

type Props = {|
  userEmail: string,
|};

export type EmailLog = {|
  deliveryStatus: CellValue<string, ?string>,
  description: CellValue<string, string>,
  event: CellValue<string, string>,
  subject: CellValue<string, ?string>,
  timestamp: CellValue<string, number>,
|};

type Output = {|
  emailLogs: ?$ReadOnlyArray<EmailLog>,
  error: ?ApolloError,
  loading: boolean,
|};

export function createTimestampValue(timestamp: string): number {
  const timestampDate = new Date(timestamp);
  const timestampValue = timestampDate.valueOf();
  return timestampValue;
}

export default function useGetEmailLogs(props: Props): Output {
  const { userEmail } = props;

  const { data, error, loading } = useQuery<GetEmailLogs, GetEmailLogsVariables>(GET_EMAIL_LOGS, {
    variables: {
      filters: {
        email: userEmail,
      },
    },
  });

  if (error) {
    return {
      error,
      loading: false,
      emailLogs: null,
    };
  }

  if (loading && !data) {
    return {
      loading: true,
      error: null,
      emailLogs: null,
    };
  }

  const emailData = data?.users[0]?.emailLog;
  invariant(emailData, 'Email logs should be defined');

  const emailLogs = emailData.map((log) => {
    const timestamp = new Date(log.timestamp);
    const datetime = timestamp.toLocaleDateString(undefined, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
    const timestampValue = createTimestampValue(log.timestamp);

    return {
      event: createCellValue(log.event),
      description: createCellValue(log.description),
      timestamp: createCellValue(datetime, timestampValue),
      subject: createCellValue(log.subject ?? '-', log.subject),
      deliveryStatus: createCellValue(log.deliveryStatus ?? '-', log.deliveryStatus),
    };
  });

  return {
    emailLogs,
    loading: false,
    error: null,
  };
}
