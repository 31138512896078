// @flow
import { useMemo } from 'react';
import moment from 'moment';
import { Box, Flex, HideVisually, HorizontalScroll, Table, useMediaQuery } from '@getatomi/neon';

import { ActionMenu, Item } from 'src/components/ActionMenu/ActionMenu';
import Avatar from 'src/components/Avatar/Avatar';
import Date from 'src/components/Date/Date';
import TextLoader from 'src/components/TextLoader/TextLoader';
import userStatusOnTask from 'src/constants/userStatusOnTask';
import type { SortBy } from 'src/hooks/useSortedStudents';
import type { OpenProgressReportDialogParams } from 'src/hooks/components/useProgressReportDialog';
import taskProgressStatuses from 'src/constants/taskProgressStatuses';
import SuccessRate from 'src/components/SuccessRate/SuccessRate';
import TaskProgressStatus from 'src/components/TaskProgressStatus/TaskProgressStatus';

import type { StudentWithProgress, ContentColumn } from './useGetTaskProgress';

type LoadingStudent = $Shape<{||}>;

type Props = {|
  contents: Array<ContentColumn>,
  data: $ReadOnlyArray<StudentWithProgress | LoadingStudent>,
  loading: boolean,
  openProgressReportDialog: (OpenProgressReportDialogParams) => mixed,
  toggleSortBy: (Array<SortBy>) => void,
|};

export function TaskProgressTable(props: Props) {
  const { contents, data, loading, openProgressReportDialog, toggleSortBy } = props;
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const columns = useMemo(
    () => [
      {
        header: 'Student',
        accessorKey: 'rowMeta',
        sortDescFirst: false,
        cell: (cell) => {
          const { rowMeta, isInactive } = cell.row.original;
          return (
            <Avatar
              user={rowMeta}
              size="sizeAvatarSmall"
              variant={isInactive ? 'muted' : undefined}
              withText
              withTruncatedFirstName={isMobile}
            />
          );
        },
        meta: {
          isHeading: true,
          loader: TextLoader,
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        sortDescFirst: true,
        cell: (cell) => {
          const statusValue = cell.getValue().displayValue;
          switch (statusValue) {
            case userStatusOnTask.COMPLETE:
              return <TaskProgressStatus status={taskProgressStatuses.done} />;
            case userStatusOnTask.IN_PROGRESS:
              return <TaskProgressStatus status={taskProgressStatuses.inProgress} />;
            default:
              return <TaskProgressStatus status={taskProgressStatuses.toDo} />;
          }
        },
        meta: {
          loader: TextLoader,
        },
      },
      {
        header: 'Completed',
        accessorKey: 'completed',
        sortDescFirst: true,
        cell: (cell) => {
          const completedValue = cell.getValue().displayValue;
          const statusValue = cell.row.original.status.displayValue;
          return statusValue === userStatusOnTask.COMPLETE ? (
            <Date
              isRelative={moment().diff(moment(completedValue), 'days', true) < 7}
              value={completedValue}
              testHook="hide-in-percy"
            />
          ) : null;
        },
        meta: {
          loader: TextLoader,
        },
      },
      ...contents.map((content) => ({
        header: content.name,
        accessorKey: `contents.${content.id}`,
        cell: ({ getValue }) => {
          const value = getValue().displayValue;
          if (typeof value === 'number') {
            return <SuccessRate successRate={value} />;
          }
          return value && <TaskProgressStatus status={taskProgressStatuses.done} />;
        },
        meta: {
          headerTemplate: (headerContent: React.Node) => (
            <Flex alignItems="start" gap="spacingSmall2X" justifyContent="space-between">
              <Box whiteSpace="initial">{headerContent}</Box>
              <Box marginRight="spacingNegativeSmall" overflow="hidden">
                <ActionMenu
                  orientation="vertical"
                  onAction={(key: string) => {
                    if (key === 'progress') {
                      openProgressReportDialog({
                        moduleId: content.moduleId,
                        post: {
                          id: content.id,
                          name: content.name,
                          type: content.type,
                        },
                      });
                    }
                  }}
                >
                  <Item key="view" href={content.url}>
                    View lesson
                  </Item>
                  <Item key="progress">View progress report</Item>
                </ActionMenu>
              </Box>
            </Flex>
          ),
          loader: TextLoader,
        },
      })),
    ],
    [contents, isMobile, openProgressReportDialog]
  );

  return (
    <Box overflow="hidden">
      {loading && <HideVisually>Loading the list of students and their progress.</HideVisually>}
      <HorizontalScroll forwardScrollPosition>
        <Table
          columns={columns}
          data={data}
          initialState={{ sortBy: [{ id: 'status', desc: true }] }}
          isFixedLayout
          isLoading={loading}
          isSortable
          toggleSortBy={toggleSortBy}
          sortingAssistiveText="Sort by"
        >
          <caption>
            <HideVisually>The list of students and their progress</HideVisually>
          </caption>
          <colgroup>
            <col width={isMobile ? 160 : 220} />
            <col width={180} />
            <col width={180} />
            {contents.map((content) => (
              <col key={content.id} width={180} />
            ))}
          </colgroup>
        </Table>
      </HorizontalScroll>
    </Box>
  );
}

export default TaskProgressTable;
