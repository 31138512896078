// @flow
import _ from 'lodash';
import invariant from 'invariant';

import type {
  GetChallengeOverview,
  GetChallengeOverview_me_account_class_users as GqlLessonUser,
  GetChallengeOverview_me_account_class_lesson_ClassChallengeLesson_attempts as GqlLessonAttempt,
  GetChallengeOverview_me_account_class_lesson_ClassChallengeLesson_attempts_questionAttempts as GqlAttempt,
  GetChallengeOverview_me_account_class_lesson_ClassChallengeLesson_metrics_assessment_students as GqlLessonMetrics,
} from 'src/graphql/types/generated/GetChallengeOverview';
import type {
  GetRevisionOverview,
  GetRevisionOverview_me_account_class_users as GqlRevisionUser,
  GetRevisionOverview_me_account_class_revision_ClassRevision_attempts as GqlRevisionAttempt,
  GetRevisionOverview_me_account_class_revision_ClassRevision_metrics_assessment_students as GqlRevisionMetrics,
} from 'src/graphql/types/generated/GetRevisionOverview';
import type { ChallengeTier } from 'src/types';
import type { ChallengeAverageMark } from 'src/domains/ViewingQuizResults/Overview/AverageMarkMetric/AverageMarkMetric';
import type { ChallengingQuestion, OverviewStudent } from 'src/domains/ViewingQuizResults/types';
import challengeTiers from 'src/constants/challengeTiers';

import type { GetQuestionUrl } from '../getQuestionUrlFactory';
import { getStudentCohorts } from '../studentCohorts';
import calculateChallengingQuestions from './calculateChallengingQuestions';
import calculateMarkDeltas from './calculateMarkDeltas';

export type PreparedOverviewData = {|
  averageMark: ChallengeAverageMark,
  challengeTier: ChallengeTier,
  challengingQuestions: $ReadOnlyArray<ChallengingQuestion>,
  completetionRate: {|
    total: number,
    value: number,
  |},
  students: $ReadOnlyArray<OverviewStudent>,
  subheading: string,
  title: string,
|};

function prepareStudents(
  users: $ReadOnlyArray<GqlLessonUser> | $ReadOnlyArray<GqlRevisionUser>,
  contentMetrics: $ReadOnlyArray<GqlLessonMetrics> | $ReadOnlyArray<GqlRevisionMetrics>
): $ReadOnlyArray<OverviewStudent> {
  const students = [];

  for (const user of users) {
    if (user.__typename === 'ClassStudent') {
      let classification = [];
      for (const metric of contentMetrics) {
        if (metric.studentId === user.id) {
          if (metric.__typename === 'ClassStudentLessonAssessmentMetrics') {
            classification = getStudentCohorts(metric.mark, metric.strength);
          } else {
            classification = getStudentCohorts(metric.mark);
          }
        }
      }
      students.push({
        id: user.id,
        avatar: user.avatar,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email,
        accountStatus: user.accountStatus,
        color: user.color,
        classification,
      });
    }
  }
  return students;
}

function prepareAttempts(attempts: $ReadOnlyArray<?GqlLessonAttempt> | $ReadOnlyArray<?GqlRevisionAttempt>): {
  [string]: Array<GqlAttempt>,
} {
  return _.groupBy(
    attempts
      .map((attempt) => attempt?.questionAttempts ?? [])
      // $FlowIgnore flow is too out of date to know about array.flat()
      .flat(),
    (attempt) => attempt.contentItemId
  );
}

export function prepareChallengeOverviewData(
  data: GetChallengeOverview,
  getQuestionUrl: GetQuestionUrl
): PreparedOverviewData {
  const classData = data.me?.account?.class;
  invariant(
    classData && classData.lesson && classData.lesson.__typename === 'ClassChallengeLesson',
    'Class challenge lesson should be defined'
  );

  const subjectName = classData.subject.shortName;
  const lessonData = classData.lesson;
  const {
    attempts: lessonAttempts,
    name: lessonName,
    tier: lessonTier,
    metrics: lessonMetrics,
    challenge: { items },
  } = lessonData;

  const students = prepareStudents(classData.users, lessonMetrics.assessment.students);

  const attempts = prepareAttempts(lessonAttempts);

  const browserTitle = `${lessonName} | ${subjectName}`;

  const transformed = {
    title: browserTitle,
    subheading: lessonName,
    challengeTier: lessonTier,
    challengingQuestions: calculateChallengingQuestions(items, attempts, getQuestionUrl),
    students,
    completetionRate: {
      total: students.length,
      value: lessonMetrics.progress.engagedStudentCount,
    },
    averageMark: calculateMarkDeltas(lessonMetrics.assessment),
  };

  return transformed;
}

export function prepareRevisionOverviewData(
  data: GetRevisionOverview,
  getQuestionUrl: GetQuestionUrl
): PreparedOverviewData {
  const classData = data?.me?.account?.class;
  const revisionData = classData?.revision;
  invariant(
    classData && revisionData && revisionData.__typename === 'ClassRevision',
    'Class revision should be defined'
  );

  const { title: revisionTitle, metrics: revisionMetrics, items } = revisionData;
  const subjectName = classData.subject.shortName;

  const students = prepareStudents(classData.users, revisionMetrics.assessment.students);

  const attempts = prepareAttempts(revisionData.attempts);

  const browserTitle = `${revisionTitle} | ${subjectName}`;

  const transformed = {
    title: browserTitle,
    subheading: revisionTitle,
    challengeTier: challengeTiers.TIER_4_REVISION,
    challengingQuestions: calculateChallengingQuestions(items, attempts, getQuestionUrl),
    students,
    completetionRate: {
      total: students.length,
      value: revisionMetrics.progress.engagedStudentCount,
    },
    averageMark: {
      value: revisionMetrics.assessment.classAverageMark ?? 0,
    },
  };

  return transformed;
}
