// @flow
import _ from 'lodash';
import moment from 'moment';

type Props = {
  format?: string,
  isRelative?: boolean,
  isSentenceCase?: boolean,
  testHook?: string,
  value: global.Date | string,
  withRelativeSuffix?: boolean, // https://momentjs.com/docs/#/displaying/fromnow/
};

const formatText = (text, isSentenceCase) => (isSentenceCase ? _.upperFirst(text) : text);

export default function Date(props: Props) {
  const {
    format = 'DD MMM YYYY',
    isRelative,
    isSentenceCase = true,
    testHook,
    value,
    withRelativeSuffix = true,
  } = props;

  return (
    <time dateTime={value} data-test={testHook}>
      {isRelative
        ? formatText(moment(value).fromNow(!withRelativeSuffix), isSentenceCase)
        : moment(value).format(format)}
    </time>
  );
}
