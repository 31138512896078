// @flow
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { AlertDialog } from '@getatomi/neon';

import { getUserId } from 'src/reducers/auth';

type Props = {
  isDeletingClassUsers: boolean,
  isRemovingCurrentUser?: boolean,
  selectedUsers: $ReadOnlyArray<string>,
  userType: string,
};

const mapStateToProps = (state, props) => ({
  isRemovingCurrentUser: props.selectedUsers.includes(getUserId(state)),
});

function RemoveUserFromClassDialog(props: Props) {
  const { userType, isDeletingClassUsers, isRemovingCurrentUser, selectedUsers, ...otherProps } = props;
  const countSelectedUsers = selectedUsers.length ?? 1;
  const pronoun = pluralize('this', countSelectedUsers);
  const noun = userType && pluralize(userType, countSelectedUsers);
  let actors = '';

  if (countSelectedUsers === 1 && isRemovingCurrentUser) {
    actors = 'yourself';
  } else if (countSelectedUsers > 1 && isRemovingCurrentUser) {
    actors = `${pronoun} ${noun}, including yourself,`;
  } else {
    actors = `${pronoun} ${noun}`;
  }

  return (
    <AlertDialog
      {...otherProps}
      variant="danger"
      heading={`Are you sure you want to remove ${actors} from this class?`}
      onConfirmLabel="Remove"
      isLoading={isDeletingClassUsers}
    >
      {isRemovingCurrentUser && (
        <>
          By removing yourself, this will no longer be one of your classes, but you’ll still be able to view it under{' '}
          <strong>All classes</strong>.
        </>
      )}
    </AlertDialog>
  );
}

export default (connect(mapStateToProps, {})(RemoveUserFromClassDialog): React.AbstractComponent<Props>);
