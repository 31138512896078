// @flow
import keyMirror from 'keymirror';

const taskProgressStatuses = keyMirror({
  toDo: null,
  inProgress: null,
  done: null,
  overdue: null,
  notAssigned: null,
});

export type TaskProgressStatus = $Keys<typeof taskProgressStatuses>;

export default taskProgressStatuses;
