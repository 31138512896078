// @flow
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { AlertDialog, Box, TextField, Stack, HelpInfo } from '@getatomi/neon';

import FormMessage from 'src/components/FormMessage/FormMessage';
import usePasswordRequiredDialogForm, { type Fields } from 'src/hooks/forms/usePasswordRequiredDialogForm';

type PasswordRequiredDialogProps = {|
  ctaIsLoading: boolean,
  ctaOnClick: (?string) => mixed | Promise<mixed>,
  ctaText: string,
  ctaVariant?: 'danger',
  description: React.Node,
  error?: ?string,
  hasPassword: boolean,
  heading: string,
  helpInfo: React.Node,
  inputInfo: string,
  isVisible: boolean,
  onClose: () => void,
|};

export default function PasswordRequiredDialog(props: PasswordRequiredDialogProps) {
  const {
    ctaText,
    ctaIsLoading,
    ctaVariant,
    ctaOnClick,
    description,
    error,
    hasPassword,
    heading,
    helpInfo,
    inputInfo,
    isVisible,
    onClose,
  } = props;

  const { control, fields, form } = usePasswordRequiredDialogForm({
    defaultValues: {
      password: '',
    },
    onSubmitSuccess: (data: Fields) => {
      const { password } = data;
      ctaOnClick(password === '' ? null : password);
    },
    context: {
      hasPassword,
    },
  });

  useEffect(() => {
    if (error) {
      window.scrollTo(0, 0);
    }
  }, [error]);

  return (
    <AlertDialog
      heading={heading}
      isLoading={ctaIsLoading}
      isOpen={isVisible}
      onConfirm={form.onSubmit}
      onConfirmLabel={ctaText}
      onDismiss={onClose}
      onDismissLabel="Cancel"
      variant={ctaVariant}
    >
      <Box as="form" method="post" onSubmit={form.onSubmit}>
        {error && <FormMessage>{error}</FormMessage>}
        <Stack>
          <Stack>
            {description}
            <HelpInfo alignment="left">{helpInfo}</HelpInfo>
          </Stack>

          {hasPassword && (
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  {...field}
                  {...fields.password}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  type="password"
                  helpText={inputInfo}
                />
              )}
            />
          )}
        </Stack>
      </Box>
    </AlertDialog>
  );
}
