// @flow
import { Box, Checkbox, HideVisually } from '@getatomi/neon';

import PostList from 'src/components/PostList/PostList';
import PostListItemLink from 'src/components/PostList/PostListItemLink/PostListItemLink';
import SectionTags from 'src/components/SectionTags/SectionTags';

import styles from './PostListItem.module.scss';

type PostListItemProps = {
  children: React.Element<typeof PostListItemLink | typeof PostList>,
  id?: string,
  isSelected?: boolean,
  labels?: $PropertyType<React.ElementProps<typeof SectionTags>, 'tags'>,
  onToggleSelection?: () => mixed,
  postId?: string,
  postName?: string,
  subSectionName?: string,
  testHook?: string,
};

export default function PostListItem(props: PostListItemProps) {
  const { children, id, isSelected, labels, onToggleSelection, postId, postName, subSectionName, testHook } = props;

  if (!children) return null;

  const withCheckbox = Boolean(onToggleSelection && postId && postName);

  return (
    <div data-test={testHook} className={styles.root}>
      {withCheckbox && (
        <Box display="flex" alignItems="center" height="sizeFull" position="absolute" left="spacingNegativeLarge5X">
          <Checkbox
            // $FlowIgnore postName isn't undefined if withCheckbox is true
            ariaLabel={`Select ${postName} lesson`}
            isSelected={isSelected}
            onChange={onToggleSelection}
          />
        </Box>
      )}
      {subSectionName && (
        <div className={styles.subSectionName}>
          <h3 id={id} className={styles.heading} data-test={testHook ? `${testHook}-heading` : undefined}>
            <HideVisually>Sub-section:</HideVisually>
            {subSectionName}
          </h3>
          {labels && <SectionTags tags={labels} />}
        </div>
      )}
      {children}
    </div>
  );
}
