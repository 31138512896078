// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetSearchModules,
  GetSearchModulesVariables,
  GetSearchModules_me_account_subjects_LevelSubject_subject_modules as QueryModule,
} from 'src/graphql/types/generated/GetSearchModules';

import GET_SEARCH_MODULES from './GetSearchModules.graphql';

type ModuleOption = {|
  count: number,
  id: string,
  name: string,
|};

type Output = {|
  error: ?ApolloError,
  loading: boolean,
  modules: ?$ReadOnlyArray<ModuleOption>,
|};

export type SelectModuleVariables = {|
  levelId: string | null,
  subjectCode: string,
  subscriptionId: string,
|};

export default function useGetModules(props: SelectModuleVariables): Output {
  const { subscriptionId, subjectCode, levelId } = props;

  const { data, error, loading } = useQuery<GetSearchModules, GetSearchModulesVariables>(GET_SEARCH_MODULES, {
    variables: {
      accountId: subscriptionId,
      filters: {
        excludeLocked: true,
        subjectCode,
        levelId,
      },
    },
  });

  if (error) {
    return {
      error,
      loading: false,
      modules: null,
    };
  }

  if (loading && !data) {
    return {
      loading: true,
      error: null,
      modules: null,
    };
  }

  const levelSubject = data?.me?.account.subjects[0];

  invariant(levelSubject != null, '[useGetModules] - Subject should be defined');
  invariant(levelSubject.__typename !== 'LockedLevelSubject', '[useGetModules] - Subject should not be locked');

  const subjectData = levelSubject.subject;
  invariant(subjectData, 'Subject data should be defined');

  const modules = subjectData.modules
    .map((module: QueryModule) => {
      if (module.__typename !== 'BaseCategory') {
        return null;
      }

      const count = module.metrics.contentCount;
      if (!count) {
        return null;
      }

      return {
        id: module.id,
        name: module.name,
        count,
      };
    })
    .filter(Boolean);

  return {
    modules,
    loading: false,
    error: null,
  };
}
