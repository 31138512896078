// @flow
import { useEffect, useMemo, useState } from 'react';

import type { ContentTypeFilter, Module, Question, QuestionTypeFilter, SessionLengthFilter } from './types';
import { contentTypes, sessionLengths, sessionLengthMinutes, questionTypes } from './constants';
import { getAllTopics, getDataFromPosts } from './utils';

export * from './constants';
export * from './types';

type Props = {|
  defaultContentType?: ContentTypeFilter,
  defaultTopicIds?: ?Array<string>,
  isTeacher: boolean,
  subjectTree: ?Array<Module>,
|};

type Output = {|
  availableDurations: { [string]: boolean },
  availableQuestionTypes: { [string]: boolean },
  contentType: ContentTypeFilter,
  coveredTopics: Array<string>,
  isTimeLimitEnabled: boolean,
  questions: Array<Question>,
  questionType: Array<QuestionTypeFilter>,
  selectAllTopics: () => void,
  selectCoveredTopics: () => void,
  selectedTopics: Array<string>,
  sessionLength: SessionLengthFilter,
  setContentType: (newContentType: ContentTypeFilter) => void,
  setQuestionType: (newQuestionType: Array<QuestionTypeFilter>) => void,
  setSelectedTopics: (newSelectedTopics: Array<string>) => void,
  setSessionLength: (newSessionLength: SessionLengthFilter) => void,
  setTimeLimitMinutes: (value: string) => void,
  timeLimitMinutes: string,
  toggleIsTimeLimitEnabled: () => void,
|};

export default function useRevisionDialogState(props: Props): Output {
  const { defaultContentType = null, defaultTopicIds, subjectTree, isTeacher } = props;

  const [contentType, setContentType] = useState<ContentTypeFilter>(() => {
    if (defaultContentType) {
      return defaultContentType;
    }
    if (defaultTopicIds?.length) {
      return contentTypes.custom;
    }
    return contentTypes.all;
  });

  const defaultSessionLength = sessionLengths.short;
  const [sessionLength, setSessionLength] = useState<SessionLengthFilter>(defaultSessionLength);

  const defaultQuestionTypes = [
    questionTypes.multiChoice,
    questionTypes.dragAndDrop,
    questionTypes.exactAnswer,
    questionTypes.shortAnswer,
    questionTypes.extendedResponse,
  ];
  const [questionType, setQuestionType] = useState<Array<QuestionTypeFilter>>(defaultQuestionTypes);

  const [selectedTopics, setSelectedTopics] = useState<Array<string>>(() => {
    const allTopicIds = getAllTopics(subjectTree).map(({ id }) => id);
    return defaultTopicIds ?? allTopicIds;
  });

  const selectAllTopics = () => {
    const allTopicIds = getAllTopics(subjectTree).map(({ id }) => id);
    setSelectedTopics(allTopicIds);
  };

  const coveredTopics = useMemo(() => {
    const allTopics = getAllTopics(subjectTree);
    const topicsWithProgress = allTopics.filter((topic) => topic.challenges.some((challenge) => challenge.hasProgress));
    return topicsWithProgress.map(({ id }) => id);
  }, [subjectTree]);

  const selectCoveredTopics = () => {
    setSelectedTopics(coveredTopics);
  };

  const { questions, availableDurations, availableQuestionTypes } = useMemo(
    () =>
      subjectTree != null
        ? getDataFromPosts({
            subjectTree,
            contentType,
            selectedTopics,
            isTeacher,
          })
        : {
            questions: [],
            availableDurations: {},
            availableQuestionTypes: {},
          },
    [subjectTree, contentType, selectedTopics, isTeacher]
  );

  useEffect(() => {
    if (!subjectTree) {
      return;
    }

    // reset fields if the selected value becomes disabled
    const resetQuestionType = questionType.filter((type) => availableQuestionTypes[type]);
    const resetSessionLength = availableDurations[sessionLength] ? sessionLength : defaultSessionLength;

    setQuestionType(resetQuestionType);
    setSessionLength(resetSessionLength);
  }, [availableDurations, availableQuestionTypes, subjectTree]); // eslint-disable-line react-hooks/exhaustive-deps

  const [isTimeLimitEnabled, setIsTimeLimitEnabled] = useState<boolean>(false);
  const toggleIsTimeLimitEnabled = () => {
    setIsTimeLimitEnabled((prev) => !prev);
  };

  const [timeLimitMinutes, setTimeLimitMinutes] = useState<string>(String(sessionLengthMinutes[sessionLength]));
  useEffect(() => {
    // Bind the time limit value to any changes to the sessionLength
    setTimeLimitMinutes(String(sessionLengthMinutes[sessionLength]));
  }, [sessionLength]);

  return {
    contentType,
    questionType,
    selectAllTopics,
    selectCoveredTopics,
    selectedTopics,
    sessionLength,
    setContentType,
    setQuestionType,
    setSelectedTopics,
    setSessionLength,
    coveredTopics,
    questions,
    availableDurations,
    availableQuestionTypes,
    isTimeLimitEnabled,
    toggleIsTimeLimitEnabled,
    setTimeLimitMinutes,
    timeLimitMinutes,
  };
}
