// @flow
import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';
import keyMirror from 'keymirror';

export const durationFormats = keyMirror({
  minutes: null,
  rounded: null,
});

export type DurationFormat = $Keys<typeof durationFormats>;

momentDurationFormat(moment);

export function formatDuration(
  durationInSeconds: number,
  {
    template = 'm:ss',
    usePlural = true,
  }: {
    template?: string,
    usePlural?: boolean,
  } = {}
) {
  // $FlowIgnoreLibDef format isn't found since it comes from the plugin
  return moment.duration(parseInt(durationInSeconds, 10), 's').format(template, {
    trim: template.includes(':') ? false : 'all',
    usePlural,
  });
}

export function formatPostDuration(durationInSeconds: number, format?: DurationFormat) {
  return format === durationFormats.minutes
    ? formatDuration(durationInSeconds, { template: 'm [min]', usePlural: false })
    : formatDuration(durationInSeconds, { template: format === durationFormats.rounded ? 'h[h] m[m]' : undefined });
}

const cappedDurationSeconds = 60 * 60;
const cappedDurationLabel = '> 1hr';

export function formatCappedDuration(durationInSeconds: number): string {
  if (durationInSeconds > cappedDurationSeconds) {
    return cappedDurationLabel;
  }

  return formatDuration(durationInSeconds);
}
