// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Alert, Box, Heading, Text } from '@getatomi/neon';

import Date from 'src/components/Date/Date';
import Link from 'src/components/Link/Link';
import { loadInvoiceDownloadUrl } from 'src/actions/invoices';
import { getAllInvoices } from 'src/reducers/invoices';
import { getPlanCurrency } from 'src/reducers/plans';
import {
  isFreePlan,
  isPaymentOverdueAlertVisible as isPaymentOverdueAlertVisibleSelector,
  isSchoolPlan as isSchoolPlanSelector,
} from 'src/reducers/subscriptions';
import anchors from 'src/constants/billingAnchors';
import type { BoundAction, DateString, Invoice, ReduxState } from 'src/types';

import SubscriptionInvoicesTable from './SubscriptionInvoicesTable/SubscriptionInvoicesTable';

// check invoice expiry every minute
const RELOAD_CHECK_INTERVAL_MS = 60000;
// reload invoice if it expires in 2 minutes or less
const RELOAD_LIMIT_MIN = 2;

const mapStateToProps = (state: ReduxState) => ({
  invoices: getAllInvoices(state),
  currency: getPlanCurrency(state),
  isPaidPlan: !isFreePlan(state),
  isSchoolPlan: isSchoolPlanSelector(state),
  isPaymentOverdueAlertVisible: isPaymentOverdueAlertVisibleSelector(state),
});

type Props = {
  isAccountOverdueSince?: DateString,
};

type InjectedProps = Props & {
  ...$Call<typeof mapStateToProps, ReduxState>,
  loadInvoiceDownloadUrlAction: BoundAction<typeof loadInvoiceDownloadUrl>,
};

class SubscriptionInvoices extends React.Component<InjectedProps> {
  interval: IntervalID;

  loadInvoiceUrl = (invoice: Invoice) => {
    const { loadInvoiceDownloadUrlAction } = this.props;

    if (!invoice.isLoaded) {
      loadInvoiceDownloadUrlAction(invoice.id);
    } else {
      const diff = moment(invoice.valid_till).diff(moment(), 'minutes');

      if (diff <= RELOAD_LIMIT_MIN) {
        loadInvoiceDownloadUrlAction(invoice.id);
      }
    }
  };

  loadInvoicesUrls = () => {
    this.props.invoices.map(this.loadInvoiceUrl);
  };

  componentDidMount() {
    if (this.props.isPaidPlan && this.props.invoices.length > 0) {
      this.loadInvoicesUrls();
      this.interval = setInterval(this.loadInvoicesUrls, RELOAD_CHECK_INTERVAL_MS);
    }
  }

  componentDidUpdate(prevProps: InjectedProps) {
    if (_.size(prevProps.invoices) !== _.size(this.props.invoices)) {
      this.loadInvoicesUrls();
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { isAccountOverdueSince, isPaymentOverdueAlertVisible, isSchoolPlan, invoices, currency } = this.props;

    return (
      <section>
        <Heading as="h3" id={anchors.invoices} variant="small1X" marginBottom="spacingLarge1X">
          Invoice history
        </Heading>
        {isSchoolPlan && isPaymentOverdueAlertVisible && (
          <Box marginBottom="spacingLarge2X">
            <Alert variant="danger" testHook="alert-overdue-invoice">
              Your account is{' '}
              <Date value={isAccountOverdueSince} isRelative withRelativeSuffix={false} isSentenceCase={false} />{' '}
              overdue. To avoid cancellation please pay any overdue invoices. For billing support please contact{' '}
              <Link variant="monochrome" href="mailto:accounts@getatomi.com">
                accounts@getatomi.com
              </Link>
              .
            </Alert>
          </Box>
        )}
        {invoices && invoices.length > 0 ? (
          <SubscriptionInvoicesTable isSchoolPlan={isSchoolPlan} invoices={invoices} currency={currency} />
        ) : (
          <Text as="p" variant="bodySmall">
            You don’t have any invoices to view yet.
          </Text>
        )}
      </section>
    );
  }
}

export default (connect(mapStateToProps, {
  loadInvoiceDownloadUrlAction: loadInvoiceDownloadUrl,
})(SubscriptionInvoices): React.AbstractComponent<Props>);
