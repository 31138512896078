// @flow
import _ from 'lodash';

import type { ChallengeAverageMark } from 'src/domains/ViewingQuizResults/Overview/AverageMarkMetric/AverageMarkMetric';

type ChallengeMetrics = {
  +classAverageMark: ?number,
  +regionAverageMark: ?number,
  +schoolAverageMark: ?number,
};

function calculateDelta(average: number, comparitor: ?number): number {
  return _.round(average - (comparitor ?? 0), 4);
}

function calculateMarkDeltas(metrics: ChallengeMetrics): ChallengeAverageMark {
  const average = metrics.classAverageMark ?? 0;

  return {
    deltas: {
      state: calculateDelta(average, metrics.regionAverageMark),
      school: calculateDelta(average, metrics.schoolAverageMark),
    },
    value: average,
  };
}

export default calculateMarkDeltas;
