// @flow
import _ from 'lodash';

const tiers = {
  TIER_1_QUIZ: 1,
  TIER_2_PRACTICE: 2,
  TIER_3_PRACTICE: 3,
  TIER_4_REVISION: 4,
  TIER_5_INTERACTIVE_LESSON: 5,
};

export default Object.freeze({
  ...tiers,
  ..._.invert(tiers),
});
