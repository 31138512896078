// @flow
import { Alert } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import { getSchoolAccountUrl } from 'src/utils/routes';
import links from 'src/constants/links';

type Props = {
  canToggleSetupMode: boolean,
  subscriptionId: number,
};

export default function SetupModeAlert(props: Props) {
  const { canToggleSetupMode, subscriptionId } = props;

  return (
    <Alert isBanner variant="info" testHook="setup-mode-alert">
      Your account is in setup mode.{' '}
      {canToggleSetupMode ? (
        <>
          Go to{' '}
          <Link variant="monochrome" to={getSchoolAccountUrl(subscriptionId)} testHook="setup-mode-alert-settings-link">
            your settings
          </Link>
        </>
      ) : (
        <Link variant="monochrome" href={links.support.setupMode} isExternal>
          Learn more
        </Link>
      )}
      .
    </Alert>
  );
}
