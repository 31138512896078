// @flow
import { EmptyState, Illustration } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import { UnstyledLink } from 'src/components/Link/Link';
import { getUpgradeUrl } from 'src/utils/routes';
import useUpgradePrompt from 'src/hooks/components/useUpgradePrompt';

type EmptyStateUpgradeStudentAccountProps = {|
  headingId?: string,
  isChild?: boolean,
  subscriptionId: number,
|};

export default function EmptyStateUpgradeStudentAccount(props: EmptyStateUpgradeStudentAccountProps) {
  const { headingId, isChild, subscriptionId } = props;
  const [modal, createHandler] = useUpgradePrompt({ shouldRequestUpgrade: true, subscriptionId });

  return (
    <>
      <EmptyState
        media={<Illustration name="main-payment" />}
        heading="Upgrade and unlock this lesson!"
        headingProps={{ as: 'h1', id: headingId }}
        description="Upgrade to unlimited and you’ll have full access to all content, anytime, and on any device."
        primaryAction={
          isChild ? (
            <Button onClick={createHandler()} size="large">
              Ask to upgrade
            </Button>
          ) : (
            <Button as={UnstyledLink} to={getUpgradeUrl(subscriptionId)} size="large">
              Upgrade
            </Button>
          )
        }
        testHook="empty-state-upgrade-student-account"
      />
      {isChild && modal}
    </>
  );
}
