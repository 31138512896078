// @flow
import { TextField, Stack, Text, useToast } from '@getatomi/neon';
import { Controller } from 'react-hook-form';
import { connect } from 'react-redux';

import Button from 'src/components/Button/Button';
import { upsertWondeConfiguration } from 'src/actions/subscriptions';
import scrollToActiveElement from 'src/utils/scrollToActiveElement';
import { getActiveSubscriptionId, isConfiguringEDS as isConfiguringEDSSelector } from 'src/reducers/subscriptions';
import useWondeConfigurationForm, { type Fields } from 'src/hooks/forms/useWondeConfigurationForm';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import type { BoundAction, ReduxState, ExternalDataSourceType } from 'src/types';

const mapStateToProps = (state: ReduxState) => {
  return {
    subscriptionId: getActiveSubscriptionId(state),
    isConfiguringEDS: isConfiguringEDSSelector(state),
  };
};

type Props = {
  externalDataSourceDetails?: ExternalDataSourceType,
};

type InjectedProps = Props & {
  isConfiguringEDS: boolean,
  subscriptionId: string,
  upsertWondeConfigurationAction: BoundAction<typeof upsertWondeConfiguration>,
};

function ConfigurationForm(props: InjectedProps) {
  const { subscriptionId, upsertWondeConfigurationAction, externalDataSourceDetails, isConfiguringEDS } = props;
  const toast = useToast();
  const { control, fields, form, serverError } = useWondeConfigurationForm({
    defaultValues: {
      providerId: externalDataSourceDetails?.provider_id ?? '',
      providerAccessToken: externalDataSourceDetails?.provider_access_token ?? '',
      providerRegion: externalDataSourceDetails?.provider_region ?? '',
      // $FlowIgnore (Flow does not yet support method calls in optional chains)
      allowedEmailDomains: externalDataSourceDetails?.allowed_email_domains?.join() ?? '',
    },
    onSubmitSuccess: async (data: Fields) => {
      await upsertWondeConfigurationAction({
        externalDataSourceId: externalDataSourceDetails?.id,
        subscriptionId,
        ...data,
      });
      toast.success('The external data source has been configured successfully.');
    },
    onSubmitFail: () => {
      scrollToActiveElement();
    },
  });

  return (
    <form {...form}>
      <Stack as="fieldset" spacing="spacingLarge1X">
        {serverError && <ValidationErrors errors={serverError.message} />}
        <Text as="legend" fontSize="fontSizeLarge">
          Step 1: Configuration
        </Text>
        <Controller
          control={control}
          name="providerId"
          render={({ field }) => <TextField {...field} {...fields.providerId} />}
        />
        <Controller
          control={control}
          name="providerAccessToken"
          render={({ field }) => <TextField type="password" {...field} {...fields.providerAccessToken} />}
        />
        <Controller
          control={control}
          name="providerRegion"
          render={({ field }) => <TextField {...field} {...fields.providerRegion} />}
        />
        <Controller
          control={control}
          name="allowedEmailDomains"
          render={({ field }) => <TextField {...field} {...fields.allowedEmailDomains} />}
        />
        <Button type="submit" isLoading={isConfiguringEDS}>
          Save configuration
        </Button>
      </Stack>
    </form>
  );
}

export default (connect(mapStateToProps, { upsertWondeConfigurationAction: upsertWondeConfiguration })(
  ConfigurationForm
): React.AbstractComponent<Props>);
