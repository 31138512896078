import { findDOMNode } from 'react-dom';

const ANIMATION_DURATION = 300;

export default function scrollTo(endPosOrElement, adjustment = 0) {
  if (!endPosOrElement) return;

  const startPos = window.scrollY || window.pageYOffset;
  let endPos;
  let endElement = endPosOrElement;

  if (endElement.isReactComponent) {
    endElement = findDOMNode(endElement);
  }

  if (typeof endPosOrElement === 'number') {
    endPos = endPosOrElement;
  } else if (typeof endElement.getBoundingClientRect === 'function') {
    const style = window.getComputedStyle(endElement);
    const marginTop = parseInt(style.getPropertyValue('margin-top'), 10);

    endPos = endElement.getBoundingClientRect().top - marginTop + (window.scrollY || window.pageYOffset);
  } else {
    endPos = 0;
  }

  endPos += adjustment;

  // Keep track of the element's position
  const stepCount = ANIMATION_DURATION / 16;
  const baseStepSize = (endPos - startPos) / stepCount;
  let currentPos = startPos;
  let currentStep = 0;

  // Keep track of the x value for sin
  const sinXStepSize = Math.PI / stepCount;
  const halfPi = Math.PI / 2;
  let sinX = 0;

  const nudge = () => {
    if (currentStep <= stepCount) {
      // easeFactor returns a number between 0, 1.6 in the middle, 0 at the end
      // which is used to adjust the amount the thing moves each step
      const easeFactor = Math.sin(sinX) * halfPi;
      currentPos += baseStepSize * easeFactor;
      currentStep++;
      sinX += sinXStepSize;

      window.scrollTo(0, currentPos);
      window.requestAnimationFrame(nudge);
    } else {
      // Tidy-up at the end
      window.scrollTo(0, endPos);
    }
  };

  nudge();
}
