// @flow
import _ from 'lodash';
import invariant from 'invariant';
import pluralize from 'pluralize';

import type {
  GetTopicInsights_me_account_class as ClassData,
  GetTopicInsights_me_account_class_students as ClassStudents,
  GetTopicInsights_me_account_class_contentNodes as Lessons,
  GetTopicInsights_me_account_class_category_metrics as TopicMetrics,
} from 'src/graphql/types/generated/GetTopicInsights';
import postTypes from 'src/constants/postTypes';
import userFullName from 'src/utils/userFullName';
import type { ChallengeTier, PostType, UserAccountStatusType } from 'src/types';
import createCellValue from 'src/utils/createCellValue';
import { formatPercentageAsString } from 'src/utils/formatPercentage';

import type { AverageMarkCell, LessonsCompletedCell, QuizzesCompletedCell } from '../prepareStudentTableColumns';
import type { ContentItemProps } from '../ContentSpotlight';

export type Student = {|
  accountStatus: UserAccountStatusType,
  avatar: ?string,
  color: number,
  email: string,
  firstName: string,
  fullName: string,
  id: string,
  lastName: string,
  sortByValue: ?string,
|};

export type TopicProgressStats = {
  assessmentsCompleted: {
    average: ?number,
    max: ?number,
    min: ?number,
    ofTotal: number,
  },
  lessonsCompleted: {
    average: ?number,
    max: ?number,
    min: ?number,
    ofTotal: number,
  },
  marks: {
    average: ?number,
    max: ?number,
    min: ?number,
  },
};

export type PreparedMetrics = {|
  averageMarkPercentage: AverageMarkCell,
  lessonsCompleted: LessonsCompletedCell,
  quizzesCompleted: QuizzesCompletedCell,
|};

export type StudentTableRow = {|
  rowMeta: Student,
  ...PreparedMetrics,
|};

export type StudentHighlight = {|
  icon: string,
  label: string,
  students: $ReadOnlyArray<Student>,
|};

export type MostEngagedContentType = {
  content: {
    challengeTier?: ChallengeTier,
    duration: number,
    lessonId: string,
    moduleId: string,
    name: string,
    type: PostType,
  },
  stats: $ReadOnlyArray<string>,
};

export type TopicInsightsData = TopicProgressStats & {
  moduleName: string,
  mostChallenging: $ReadOnlyArray<ContentItemProps>,
  mostEngagedContent: $ReadOnlyArray<MostEngagedContentType>,
  studentHighlights: $ReadOnlyArray<StudentHighlight>,
  students: $ReadOnlyArray<StudentTableRow>,
  topicName: string,
};

function prepareStudentData(student: ClassStudents): Student {
  const upperFirstName = _.upperFirst(student.firstName);
  const upperLastName = _.upperFirst(student.lastName);

  return {
    id: student.id,
    avatar: student.avatar,
    email: student.email,
    firstName: upperFirstName,
    lastName: upperLastName,
    // $FlowIgnore - we expect students with metrics to have name data
    fullName: userFullName(student.firstName, student.lastName),
    accountStatus: student.accountStatus,
    color: student.color,
    sortByValue: upperLastName,
  };
}

function getModuleId(categories) {
  return categories[0].id;
}

function getSubCategoryName(categories) {
  return categories.length === 3 ? categories[2].name : undefined;
}

function getMostChallengingItems(lessons: $ReadOnlyArray<Lessons>) {
  const challengingItems = lessons
    .filter(
      (lesson) => lesson.__typename === 'ClassChallengeLesson' && lesson.metrics.assessment.classAverageMark !== null
    )
    // $FlowIgnore nulls are filtered out
    .sort((a, b) => a.metrics.assessment.classAverageMark - b.metrics.assessment.classAverageMark)
    .slice(0, 3)
    .map((lesson) => ({
      type: 'challenge',
      id: lesson.id,
      name: lesson.name,
      context: getSubCategoryName(lesson.categories),
      moduleId: getModuleId(lesson.categories),
      stats: [
        // $FlowIgnore metrics is defined
        `Average mark ${formatPercentageAsString(lesson.metrics.assessment.classAverageMark)}`,
        // $FlowIgnore metrics is defined
        `${lesson.metrics.progress.engagedStudentCount} students`,
      ],
    }));

  return challengingItems;
}

export function getMostEngagedContent(lessons: $ReadOnlyArray<Lessons>): $ReadOnlyArray<MostEngagedContentType> {
  const completedContent = [];

  const formatStats = (numCompletions, completionUnit, numStudents): $ReadOnlyArray<string> => {
    const stats = [];
    if (numCompletions) {
      stats.push(`${numCompletions} ${pluralize(completionUnit, numCompletions)}`);
    }
    if (numStudents) {
      stats.push(`${numStudents} ${pluralize('student', numStudents)}`);
    }
    return stats;
  };

  for (const lesson of lessons) {
    invariant(
      ['ClassTextLesson', 'ClassVideoLesson', 'ClassChallengeLesson'].includes(lesson.__typename),
      `Lesson content nodes must be a text, video, or challenge lesson`
    );

    // $FlowIgnore - the three types specified above all have metrics
    const { id: lessonId, name, categories, duration, metrics } = lesson;

    const moduleId = getModuleId(categories);

    const numStudentsCompleted = metrics.progress.engagedStudentCount;
    const numCompletions = metrics.progress.cumulativeCompletionCount;

    if (numCompletions) {
      const contentData = (contentType: string, stats: $ReadOnlyArray<string>, challengeTier?: ChallengeTier) => ({
        numCompletions,
        content: {
          lessonId,
          moduleId,
          name,
          duration,
          type: contentType,
          ...(challengeTier && { challengeTier }),
        },
        stats,
      });

      switch (lesson.__typename) {
        case 'ClassTextLesson': {
          const textLessonStats = formatStats(numCompletions, 'view', numStudentsCompleted);
          completedContent.push(contentData(postTypes.text, textLessonStats));
          break;
        }

        case 'ClassVideoLesson': {
          const videoLessonStats = formatStats(numCompletions, 'view', numStudentsCompleted);
          completedContent.push(contentData(postTypes.video, videoLessonStats));
          break;
        }

        case 'ClassChallengeLesson': {
          const challengeLessonStats = formatStats(numCompletions, 'attempt', numStudentsCompleted);
          completedContent.push(contentData(postTypes.challenge, challengeLessonStats, lesson.tier));
          break;
        }

        default:
          break;
      }
    }
  }

  const topFiveContent = _.take(_.orderBy(completedContent, ['numCompletions'], ['desc']), 5);
  return topFiveContent.map((content) => _.omit(content, ['numCompletions']));
}

function prepareMetrics(studentId: string, metrics: TopicMetrics): PreparedMetrics {
  const { assessment, progress } = metrics;

  const assessmentStudent = assessment.students.find((student) => student.studentId === studentId);
  const progressStudent = progress.students.find((student) => student.studentId === studentId);

  let averageMarkPercentageDisplay;
  let averageMarkPercentageSortBy;
  let lessonsCompleted = 0;
  let quizzesCompleted = 0;
  if (assessmentStudent && assessmentStudent.averageMark != null) {
    const averageMarkPercentage = _.round(assessmentStudent.averageMark * 100);
    averageMarkPercentageDisplay = `${averageMarkPercentage}%`;
    averageMarkPercentageSortBy = averageMarkPercentage;
  }
  if (progressStudent && assessmentStudent) {
    lessonsCompleted = progressStudent.completedCount - assessmentStudent.completedCount;
    quizzesCompleted = assessmentStudent.completedCount;
  }

  return {
    averageMarkPercentage: createCellValue(averageMarkPercentageDisplay, averageMarkPercentageSortBy),
    lessonsCompleted: createCellValue(lessonsCompleted),
    quizzesCompleted: createCellValue(quizzesCompleted),
  };
}

export function transformStudentHighlights(
  studentRows: $ReadOnlyArray<StudentTableRow>
): $ReadOnlyArray<StudentHighlight> {
  const studentData = studentRows.map((studentRow, rowIndex) => ({
    rowIndex,
    lessonsCompleted: studentRow.lessonsCompleted.sortByValue,
    averageMark: studentRow.averageMarkPercentage.sortByValue,
  }));

  const mostLessonsCompleted = _.maxBy(studentData, 'lessonsCompleted')?.lessonsCompleted;
  const highestAverageMark = _.maxBy(studentData, 'averageMark')?.averageMark;
  const lowestAverageMark = _.minBy(studentData, 'averageMark')?.averageMark;

  const highlights = [];

  if (mostLessonsCompleted > 0) {
    const studentsWithMostLessonsCompleted = studentData.filter(
      ({ lessonsCompleted }) => lessonsCompleted === mostLessonsCompleted
    );
    highlights.push({
      icon: 'circle-play',
      label: 'Most lessons completed',
      students: studentsWithMostLessonsCompleted.map(({ rowIndex }) => studentRows[rowIndex].rowMeta),
    });
  }

  if (highestAverageMark != null) {
    const studentsWithHighestAvgMark = studentData.filter(({ averageMark }) => averageMark === highestAverageMark);
    highlights.push({
      icon: 'check',
      label: 'Highest average mark',
      students: studentsWithHighestAvgMark.map(({ rowIndex }) => studentRows[rowIndex].rowMeta),
    });
  }

  if (lowestAverageMark != null) {
    const studentsWithLowestAvgMark = studentData.filter(({ averageMark }) => averageMark === lowestAverageMark);
    highlights.push({
      icon: 'alert-error',
      label: 'Lowest average mark',
      students: studentsWithLowestAvgMark.map(({ rowIndex }) => studentRows[rowIndex].rowMeta),
    });
  }

  return highlights;
}

export function topicInsightsTransformer(classData: ClassData): TopicInsightsData {
  const { category: topic, contentNodes, students } = classData;
  invariant(topic && topic.metrics, 'Topic must be properly defined for insights transformer');
  const { classAverageMark, students: studentsWithAssessments } = topic.metrics.assessment;
  const studentMarks = studentsWithAssessments.map((student) => student.averageMark).filter((mark) => mark != null);

  const studentAssessmentCounts = [];
  const studentLessonCounts = [];
  const studentTableRows = [];
  for (const student of students) {
    const rowMeta = prepareStudentData(student);

    const { averageMarkPercentage, lessonsCompleted, quizzesCompleted } = prepareMetrics(student.id, topic.metrics);

    studentLessonCounts.push(lessonsCompleted.sortByValue);
    studentAssessmentCounts.push(quizzesCompleted.sortByValue);
    studentTableRows.push({
      rowMeta,
      averageMarkPercentage,
      lessonsCompleted,
      quizzesCompleted,
    });
  }

  const studentHighlights = transformStudentHighlights(studentTableRows);

  const getMax = (values) => (values.length >= 2 ? _.max(values) : null);
  const getMin = (values) => (values.length >= 2 ? _.min(values) : null);
  const lessonCompletedCount = _.sum(studentLessonCounts);
  const assessmentCompletedCount = _.sum(studentAssessmentCounts);

  return {
    topicName: topic.name,
    moduleName: topic.categories[0].name,
    marks: {
      average: studentMarks.length ? classAverageMark : null,
      max: getMax(studentMarks),
      min: getMin(studentMarks),
    },
    lessonsCompleted: {
      average: lessonCompletedCount ? lessonCompletedCount / students.length : null,
      max: getMax(studentLessonCounts),
      min: getMin(studentLessonCounts),
      ofTotal: topic.metrics.lessonCount,
    },
    assessmentsCompleted: {
      average: assessmentCompletedCount ? assessmentCompletedCount / students.length : null,
      max: getMax(studentAssessmentCounts),
      min: getMin(studentAssessmentCounts),
      ofTotal: topic.metrics.assessmentCount,
    },
    mostChallenging: getMostChallengingItems(contentNodes),
    mostEngagedContent: getMostEngagedContent(contentNodes),
    students: studentTableRows,
    studentHighlights,
  };
}
