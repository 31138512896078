// @flow
import { successLimits } from 'src/constants/progress';

import type { OverviewStudent } from '../types';

export const cohorts = Object.freeze({
  GOING_WELL: 'goingWell',
  NEEDS_HELP: 'needsHelp',
  NEEDS_REVISION: 'needsRevision',
  NOT_COMPLETED: 'notCompleted',
});

// eslint-disable-next-line no-restricted-syntax
export type StudentCohort = $Values<typeof cohorts>;

interface StudentWithClassification {
  classification: $ReadOnlyArray<StudentCohort>;
}
export function isPartOfCohort({ classification }: StudentWithClassification, groupName: StudentCohort): boolean {
  return classification.includes(groupName);
}

export function groupByCohort(
  students: $ReadOnlyArray<OverviewStudent>,
  groupName: StudentCohort
): $ReadOnlyArray<OverviewStudent> {
  return students.filter((student) => isPartOfCohort(student, groupName));
}

export function getStudentCohorts(successRate: ?number, strength?: ?number): $ReadOnlyArray<StudentCohort> {
  const studentCohorts = [];

  if (successRate == null && strength == null) {
    studentCohorts.push(cohorts.NOT_COMPLETED);
    return studentCohorts;
  }

  if (successRate != null && successRate >= successLimits.goingWellThreshold) {
    studentCohorts.push(cohorts.GOING_WELL);
  }

  if (successRate != null && successRate <= successLimits.needsHelpThreshold) {
    studentCohorts.push(cohorts.NEEDS_HELP);
  }

  if (strength != null && strength <= successLimits.revisionNeededThreshold) {
    studentCohorts.push(cohorts.NEEDS_REVISION);
  }

  return studentCohorts;
}
