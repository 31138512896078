// @flow
import { useMutation } from '@apollo/client';

import type {
  CreateStudentRevision,
  CreateStudentRevisionVariables,
  CreateStudentRevision_createStudentRevision as CreateStudentRevisionData,
} from 'src/graphql/types/generated/CreateStudentRevision';

import CREATE_STUDENT_REVISION from '../CreateStudentRevision.graphql';

export type Output = {|
  createStudentRevision: (params: {| variables: CreateStudentRevisionVariables |}) => Promise<{|
    data: {| createStudentRevision: CreateStudentRevisionData |},
  |}>,
  isCreatingStudentRevision: boolean,
|};

export default function useCreateStudentRevision(): Output {
  const [createStudentRevision, { loading: isCreatingStudentRevision }] = useMutation<
    CreateStudentRevision,
    CreateStudentRevisionVariables
  >(CREATE_STUDENT_REVISION);

  return {
    createStudentRevision,
    isCreatingStudentRevision,
  };
}
