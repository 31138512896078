// @flow
import { useState } from 'react';
import { connect } from 'react-redux';
import { Container, InfoTip, Stack, Switch, Text } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import { canToggleSetupMode, isActiveSubscriptionInSetupMode } from 'src/reducers/subscriptions';
import links from 'src/constants/links';

import ToggleSetupModeDialog from '../ToggleSetupModeDialog/ToggleSetupModeDialog';

type Props = {
  isDisabled: boolean,
  isSetupModeActive: boolean,
};

const mapStateToProps = (state) => ({
  isDisabled: !canToggleSetupMode(state),
  isSetupModeActive: isActiveSubscriptionInSetupMode(state),
});

function ToggleSetupMode(props: Props) {
  const { isDisabled, isSetupModeActive } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const onToggleDialog = () => {
    setIsDialogOpen((isOpen) => !isOpen);
  };

  return (
    <>
      <Container textAlign="center" paddingInline="spacingNone">
        <Stack spacing="spacingLarge1X">
          <Switch
            label={
              <>
                Setup mode
                {isDisabled && <InfoTip content="Only your school owner can enable or disable setup mode." />}
              </>
            }
            onChange={onToggleDialog}
            isSelected={isSetupModeActive}
            isDisabled={isDisabled}
            testHook="setup-mode-toggle"
          />
          <Text as="p" variant="bodySmall1X" color="colorTextSubtler">
            Use setup mode when inviting multiple users and setting up your school account. <br />
            Learn more about using{' '}
            <Link href={links.support.setupMode} isExternal>
              setup mode
            </Link>{' '}
            in the Help Centre.
          </Text>
        </Stack>
      </Container>
      {!isDisabled && <ToggleSetupModeDialog isOpen={isDialogOpen} onClose={onToggleDialog} />}
    </>
  );
}

export default (connect(mapStateToProps, null)(ToggleSetupMode): React.AbstractComponent<{}>);
