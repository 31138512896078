// @flow
import { useMemo, useState } from 'react';
import { useMediaQuery } from '@getatomi/neon';

import useSortedStudents from 'src/hooks/useSortedStudents';

import prepareCompletionColumns from './prepareCompletionColumns';
import type {
  TransformedCompletionData,
  ClassAverageRow,
  CompletionData,
  Module as CompletionColumn,
} from './completionTransformer';
import MarkbookTable from '../components/MarkbookTable';
import MarkbookControls from '../components/MarkbookControls';

type Props = {
  expandedModuleState: $Call<typeof useState>,
  params: { classId: string, subscriptionId: string },
  preparedData: TransformedCompletionData,
};

export default function CompletionView(props: Props) {
  const { preparedData, params, expandedModuleState } = props;
  const { completionData, columnList, classAverageRow, className, lessonCount, isDataExportEnabled } = preparedData;
  const { classId, subscriptionId } = params;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointSmall' });

  const { sortedData: sortedCompletionData, setSortBy } = useSortedStudents<CompletionData>(completionData);

  const completionColumns = useMemo(
    () =>
      prepareCompletionColumns({
        isMobile,
        columnList,
        lessonCount,
        expandedModuleState,
        classId,
      }),
    [isMobile, columnList, lessonCount, expandedModuleState, classId]
  );

  const rowData = useMemo(
    (): $ReadOnlyArray<ClassAverageRow | CompletionData> => [classAverageRow, ...sortedCompletionData],
    [classAverageRow, sortedCompletionData]
  );

  const downloadColumns: $ReadOnlyArray<CompletionColumn> = [
    {
      id: 'overall',
      categoryCount: 0,
      lessonCount,
      name: 'Overall completion',
    },
    ...columnList,
  ];

  return (
    <>
      <MarkbookControls
        activeLink="completion"
        className={className}
        isDataExportEnabled={isDataExportEnabled}
        params={params}
        columnList={downloadColumns}
        tableData={rowData}
      />
      <MarkbookTable
        isMobile={isMobile}
        caption="The list of students and their completion data."
        columns={completionColumns}
        data={rowData}
        isSortable
        toggleSortBy={setSortBy}
        classId={classId}
        subscriptionId={subscriptionId}
      />
    </>
  );
}
