// @flow
import { Container, EmptyState, Illustration } from '@getatomi/neon';

import { userStatusLabels } from 'src/constants/userStatuses';

type Props = {
  isVisible: boolean,
  status: string,
};

export default function EmptyStateUsers(props: Props) {
  const { isVisible, status } = props;

  if (!isVisible) return null;

  return (
    <Container>
      <EmptyState
        media={<Illustration name="emptystate-users" />}
        description={`There are no ${userStatusLabels[status]} users on your account yet.`}
      />
    </Container>
  );
}
