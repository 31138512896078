// @flow
import { Box, Card, Flex, Heading, ProgressBar, Stack, StatusLight, Text } from '@getatomi/neon';

import type { TaskProgress } from './classInsightsTransformer';

type TasksInsightsProps = {|
  taskProgress: TaskProgress,
|};

const baseProgressValues = [
  {
    color: 'colorChartSuccess',
    statusKey: 'done',
    statusLabel: 'Done',
  },
  {
    color: 'colorChartInfo',
    statusKey: 'inProgress',
    statusLabel: 'In progress',
  },
  {
    color: 'colorChartNeutral',
    statusKey: 'toDo',
    statusLabel: 'To do',
  },
  {
    color: 'colorChartDanger',
    statusKey: 'overdue',
    statusLabel: 'Overdue',
  },
];

function TasksInsights(props: TasksInsightsProps) {
  const { assigned, doneOnTimePercentage, statusPercentages } = props.taskProgress;

  const progressTooltipCopy = (status, percentage) => `${status}: ${percentage}% of tasks assigned per student`;

  const progressValues = baseProgressValues.map(({ statusKey, color, statusLabel }) => ({
    ariaLabel: statusLabel,
    color,
    value: statusPercentages[statusKey],
    tooltip: progressTooltipCopy(statusLabel, statusPercentages[statusKey]),
  }));

  const progressBarPercentages = progressValues.map((value) => ({
    label: value.ariaLabel,
    color: value.color,
    text: `${value.value}%`,
  }));

  return (
    <Box as="section">
      <Heading as="h2" variant="large" marginBottom="spacingLarge4X">
        Tasks
      </Heading>

      <Box
        display="grid"
        gap="spacingRoot"
        gridTemplateColumns={{ base: '1fr', mobile: '1fr 1fr', tablet: '1fr 1fr 1fr' }}
      >
        <Box gridColumn={{ tablet: 'span 2' }}>
          <Card
            height="sizeFull"
            padding="spacingLarge1X"
            variant="rounded"
            withHover={false}
            testHook="task-progress-card"
          >
            <Heading as="h3" variant="small">
              Tasks
            </Heading>
            <Stack spacing="spacingLarge2X">
              <Text color="colorTextSubtle" variant="bodyRoot">{`${assigned} tasks assigned`}</Text>

              <ProgressBar values={progressValues} />

              <Flex as="ul" wrap="wrap" gap="spacingLarge1X">
                {progressBarPercentages.map((progressBarPercentage, index) => (
                  <Flex as="li" direction="column" gap="spacingSmall1X" key={index}>
                    <Text color="colorTextSubtle" variant="bodySmall1X">
                      {progressBarPercentage.label}
                    </Text>
                    <Flex>
                      <StatusLight color={progressBarPercentage.color} testHook="task-progress-status-cell-icon" />
                      <Text as="span" variant="bodySmall">
                        {progressBarPercentage.text}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Stack>
          </Card>
        </Box>

        <Card
          height="sizeFull"
          padding="spacingLarge1X"
          variant="rounded"
          withHover={false}
          testHook="task-completion-card"
        >
          <Heading as="h3" variant="small" marginBottom="spacingSmall">
            Task completion rate
          </Heading>

          <Box marginBottom="spacingSmall1X">
            <Text fontSize="fontSizeLarge6X" variant="bodyRoot">
              {statusPercentages.done}%
            </Text>
          </Box>

          <Text
            as="p"
            color="colorTextSubtle"
            variant="bodySmall"
          >{`On average ${doneOnTimePercentage}% of tasks are completed on time.`}</Text>
        </Card>
      </Box>
    </Box>
  );
}

export default TasksInsights;
