import { SUBSCRIPTIONS_ENDPOINT, USERS_ENDPOINT } from 'src/api/endpoints';
import { classesResponseSchema } from 'src/api/schema';
import { buildURLWithPaginationAndSearch } from 'src/utils/pagination';
import { trackingEvents } from 'src/constants/tracking';
import classesSourceFilters from 'src/constants/classesSourceFilters';
import { getPageSize, isPageLoaded } from 'src/reducers/classes';
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';

export const LOAD_CLASSES = 'classes/LOAD_CLASSES';
export const LOAD_CLASSES_SUCCESS = 'classes/LOAD_CLASSES_SUCCESS';
export const LOAD_CLASSES_FAIL = 'classes/LOAD_CLASSES_FAIL';
export const LOAD_SUBSCRIPTION_CLASSES = 'classes/LOAD_SUBSCRIPTION_CLASSES';
export const LOAD_SUBSCRIPTION_CLASSES_SUCCESS = 'classes/LOAD_SUBSCRIPTION_CLASSES_SUCCESS';
export const LOAD_SUBSCRIPTION_CLASSES_FAIL = 'classes/LOAD_SUBSCRIPTION_CLASSES_FAIL';
export const UPDATE_CURRENT_PAGE = 'classes/UPDATE_CURRENT_PAGE';
export const SEND_USER_INVITE = 'classes/SEND_USER_INVITE';
export const SEND_USER_INVITE_SUCCESS = 'classes/SEND_USER_INVITE_SUCCESS';
export const SEND_USER_INVITE_FAIL = 'classes/SEND_USER_INVITE_FAIL';
export const CLEAR_VALIDATION_ERRORS = 'classes/CLEAR_VALIDATION_ERRORS';

export const getClassesEndpointUrl = (state) => {
  const subscriptionId = getActiveSubscriptionId(state);
  return `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/classes`;
};

export function updateCurrentPage({ page, source }) {
  return {
    type: UPDATE_CURRENT_PAGE,
    source,
    page,
  };
}

export function loadCurrentSubscriptionClasses({ page, keywords, withRelatedEntities = true, isRefetch = false } = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const pageSize = getPageSize(state);
    const url = buildURLWithPaginationAndSearch({
      url: `${getClassesEndpointUrl(getState())}${withRelatedEntities ? '?include=teachers' : ''}`,
      page,
      pageSize,
      keywords,
    });

    return dispatch({
      data: {
        isRefetching: isRefetch,
      },
      request: {
        throwErrors: true,
        types: [LOAD_SUBSCRIPTION_CLASSES, LOAD_SUBSCRIPTION_CLASSES_SUCCESS, LOAD_SUBSCRIPTION_CLASSES_FAIL],
        schema: classesResponseSchema,
        endpoint: {
          url,
        },
      },
    });
  };
}

export function loadCurrentUserClasses({ page, keywords, isRefetch = false } = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state.auth;
    const pageSize = getPageSize(state);
    const userId = user.id;
    const subscriptionId = getActiveSubscriptionId(state);

    const url = buildURLWithPaginationAndSearch({
      url: `${USERS_ENDPOINT}/${userId}/subscriptions/${subscriptionId}/classes?include=teachers`,
      page,
      pageSize,
      keywords,
    });

    return dispatch({
      data: {
        isRefetching: isRefetch,
      },
      request: {
        throwErrors: true,
        types: [LOAD_CLASSES, LOAD_CLASSES_SUCCESS, LOAD_CLASSES_FAIL],
        schema: classesResponseSchema,
        endpoint: {
          url,
        },
      },
    });
  };
}

// Deprecated: only used by retail students' Settings > Subjects page
export function loadClasses({ page = 1 } = {}) {
  return async (dispatch, getState) => {
    const source = classesSourceFilters.user;

    // we already have the results in the state
    if (isPageLoaded(getState(), page, source)) {
      return dispatch(updateCurrentPage({ page, source }));
    }

    await dispatch(loadCurrentUserClasses({ page }));
  };
}

export function sendInvitationToClass(classId, userId) {
  return (dispatch, getState) => {
    return dispatch({
      data: { ids: [userId] },
      request: {
        types: [SEND_USER_INVITE, SEND_USER_INVITE_SUCCESS, SEND_USER_INVITE_FAIL],
        endpoint: {
          method: 'post',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${getActiveSubscriptionId(getState())}/users/${userId}/send-invitation`,
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.classInviteUserRequested,
        data: {
          classId,
          userId,
        },
      },
    });
  };
}

export function clearValidationErrors() {
  return {
    type: CLEAR_VALIDATION_ERRORS,
  };
}
