// @flow
import { Box, IconCheck, useMediaQuery } from '@getatomi/neon';
import classnames from 'classnames/bind';

import SubjectIcon from 'src/components/SubjectIcon/SubjectIcon';
import type { Subject } from 'src/types';

import styles from './SubjectGroup.module.scss';

const cx = classnames.bind(styles);

export default function SubjectGroup({
  subjects,
  selectedSubjects,
  onSelect,
}: {
  onSelect: Function,
  selectedSubjects: Array<string>,
  subjects: Array<Subject>,
}) {
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  if (!subjects) return null;

  return (
    <div className={styles.group}>
      {subjects.map((subject) => {
        const isSelected = selectedSubjects.includes(subject.code);

        return (
          <Box
            key={subject.code}
            as="button"
            type="button"
            onClick={() => onSelect(subject.code)}
            className={cx('subject', { isSelected })}
          >
            <div className={styles.circle}>
              <SubjectIcon
                name={subject.icon}
                display="inline-block"
                color={isSelected ? 'colorIconBrand' : subject.color}
                size={isMobile ? 'sizeIconRoot' : 'sizeIconLarge1X'}
                strokeWidth={isMobile ? 1 : 2}
              />
              <span className={styles.check}>
                <IconCheck className={styles.checkIcon} />
              </span>
            </div>
            <span className={styles.name}>{subject.name}</span>
          </Box>
        );
      })}
    </div>
  );
}
