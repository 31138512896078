// @flow
import pluralize from 'pluralize';

export default function getStudentCountText({
  count,
  total,
  totalText,
}: {
  count: number,
  total: number,
  totalText: string,
}) {
  return count !== 0 && count >= total ? totalText : pluralize('Student', count, true);
}
