// @flow
import { Box, Flex, IconCog, Skeleton, Text, TextLoader } from '@getatomi/neon';

import SubjectLevelBadges from 'src/components/SubjectLevelBadges/SubjectLevelBadges';
import SubjectIcon from 'src/components/SubjectIcon/SubjectIcon';
import getSubjectColors from 'src/utils/getSubjectColors';

type Props = {|
  className: string,
  isStudent: boolean,
  levels: $ReadOnlyArray<{ id: string, name: string }>,
  showSubjectTag?: boolean,
  subject: {|
    code: string,
    color: string,
    groupCode: string,
    icon: string,
    name: string,
  |},
  toggleEditModal?: () => void,
|};

type LayoutProps = {|
  badges: React.Node,
  className: React.Node,
  isStudent: boolean,
  subjectIcon: React.Node,
  toggleEditModal?: React.Node,
|};

function SubjectHeaderLayout(props: LayoutProps) {
  const { badges, className, isStudent, subjectIcon, toggleEditModal } = props;

  return (
    <Box marginBottom={{ base: 'spacingLarge', tablet: 'spacingLarge4X' }} testHook="subject-header">
      <Flex
        alignItems="center"
        direction={isStudent ? 'column' : 'row'}
        gap={isStudent ? 'spacingSmall1X' : 'spacingSmall'}
        textAlign={isStudent ? 'center' : undefined}
      >
        {subjectIcon}

        <Flex direction="column" gap="spacingSmall1X">
          {className}
          {badges}
        </Flex>

        {toggleEditModal}
      </Flex>
    </Box>
  );
}

export function SubjectHeaderLoader({ isStudent }: { isStudent: boolean }) {
  return (
    <SubjectHeaderLayout
      badges={
        <Flex
          alignItems="center"
          direction="row"
          display="inline-flex"
          gap="spacingSmall1X"
          justifyContent={isStudent ? 'center' : undefined}
          wrap
        >
          <Skeleton borderRadius="borderRadiusLarge" height={20} width={120} />
          <Skeleton borderRadius="borderRadiusLarge" height={20} width={80} />
        </Flex>
      }
      className={<TextLoader height={{ base: 28, tablet: 43 }} width={350} />}
      isStudent={isStudent}
      subjectIcon={<Skeleton borderRadius="borderRadiusCircle" height={56} width={56} />}
    />
  );
}

export default function SubjectHeader(props: Props) {
  const { className, isStudent, subject, levels, showSubjectTag = true, toggleEditModal } = props;
  const colors = getSubjectColors(subject.groupCode, subject.color);

  return (
    <SubjectHeaderLayout
      badges={
        <SubjectLevelBadges
          isCentered={isStudent}
          levelsData={levels}
          subjectData={
            showSubjectTag
              ? {
                  name: subject.name,
                  color: subject.color,
                  groupCode: subject.groupCode,
                }
              : undefined
          }
        />
      }
      className={
        <Text
          as="h1"
          fontSize={{ base: 'fontSizeLarge3X', tablet: 'fontSizeLarge6X' }}
          fontWeight="fontWeightLight"
          lineHeight="lineHeightSmall2X"
        >
          {className}
        </Text>
      }
      isStudent={isStudent}
      subjectIcon={
        <Box
          backgroundColor={colors.backgroundSubtle}
          borderRadius="borderRadiusCircle"
          display="inline-block"
          padding="spacingRoot"
        >
          <SubjectIcon name={subject.icon} color={colors.icon} size="sizeIconRoot" />
        </Box>
      }
      toggleEditModal={
        toggleEditModal && (
          <Box
            as="button"
            color="colorIcon"
            _hoverAndFocus={{ color: 'colorIconHovered' }}
            transition="transitionRoot"
            marginLeft="auto"
            onClick={toggleEditModal}
            testHook="edit-class-button"
            zIndex="zIndex1"
          >
            <IconCog isDecorative={false} title="Edit class" />
          </Box>
        )
      }
    />
  );
}
