// @flow
import { Container, EmptyState } from '@getatomi/neon';

export type EmptyStateSearchProps = {|
  contentType: string,
  searchTerm: string,
|};

export default function EmptyStateSearch(props: EmptyStateSearchProps) {
  const { contentType, searchTerm } = props;

  return (
    <Container>
      <EmptyState
        heading={`No ${contentType} found for '${searchTerm}'.`}
        description="Bummer. Maybe try another search term?"
      />
    </Container>
  );
}
