// @flow
import { useState } from 'react';

import type { EditableTask } from 'src/components/useTaskDialog/TaskDialog';

import DuplicateTaskDialog, { type CurrentClass } from './DuplicateTaskDialog';

type Props = {
  currentClass: CurrentClass,
  onSubmit: (args: { duplicateClassId: string, duplicateTask: EditableTask }) => mixed,
};

export default function useDuplicateTaskDialog({ currentClass, onSubmit }: Props) {
  const [task, setTask] = useState<?EditableTask>(null);

  const open = (taskToDuplicate: EditableTask) => {
    setTask(taskToDuplicate);
  };

  const closeDuplicateTaskDialog = () => {
    setTask(null);
  };

  const taskProgressDialog = task ? (
    // $FlowIgnore - withRouter passes in params
    <DuplicateTaskDialog
      defaultDuplicateClass={currentClass}
      task={task}
      onSubmit={onSubmit}
      onClose={() => closeDuplicateTaskDialog()}
    />
  ) : null;
  return [taskProgressDialog, { open }];
}
