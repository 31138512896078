// @flow
import classnames from 'classnames/bind';
import pluralize from 'pluralize';
import { debounce, sortBy } from 'lodash';
import { Box, HelpText, Text, Label, Heading, Container, Stack, Divider } from '@getatomi/neon';

import type { ApiError, UserGroupType } from 'src/types/index';
import Button from 'src/components/Button/Button';
import FormMessage from 'src/components/FormMessage/FormMessage';
import Link from 'src/components/Link/Link';
import TextArea from 'src/components/TextArea/TextArea';
import validators from 'src/utils/validators';
import { trackingCtas } from 'src/constants/tracking';
import links from 'src/constants/links';

import { getDialogHeading } from '../AddUsersDialog';
import type { Output } from '../useAddClassUsers/useAddClassUsers';
import UsersValidationErrors from '../UsersValidationErrors/UsersValidationErrors';
import styles from './BulkAddUsersForm.module.scss';

const cx = classnames.bind(styles);

type EmailOption = {
  isNew: boolean,
  isValid: boolean,
  value: string,
};

type Props = {|
  allUsersEmails: $ReadOnlyArray<string>,
  bulkAddUsers: $PropertyType<Output, 'addClassUsers'>,
  classId: string,
  className: string,
  isAddingUsers: boolean,
  onClose: () => mixed,
  onGoBack: (e: SyntheticMouseEvent<HTMLButtonElement>) => mixed,
  onSuccess: (countUsers: number) => mixed,
  userType: UserGroupType,
|};

type State = {
  apiError: ?ApiError,
  areEmailsValid: boolean,
  emails: Array<EmailOption>,
  numNewUsers: number,
  showFakeTextArea: boolean,
};

export default class BulkAddUsersForm extends React.Component<Props, State> {
  state = {
    areEmailsValid: true,
    emails: [],
    numNewUsers: 0,
    showFakeTextArea: false,
    apiError: null,
  };

  // input field ref
  textArea = null;

  /**
   * Go through the emails and order/flag them as valid/new/existing emails
   **/
  prepareListOfEmails = (emails: Array<string>): Object => {
    let hasInvalidEmails = false;
    let numNewUsers = 0;
    const validEmails: Array<EmailOption> = [];
    const invalidEmails: Array<EmailOption> = [];
    const newUsersEmails: Array<EmailOption> = [];

    emails.forEach((value) => {
      const email = value.trim();

      if (validators.email.pattern.test(email)) {
        if (this.props.allUsersEmails.includes(email)) {
          validEmails.push({ value: email, isValid: true, isNew: false });
        } else {
          numNewUsers++;
          newUsersEmails.push({ value: email, isValid: true, isNew: true });
        }
      } else {
        hasInvalidEmails = true;
        invalidEmails.push({ value: email, isValid: false, isNew: false });
      }
    });

    return {
      hasInvalidEmails,
      numNewUsers,
      emails: [...sortBy(invalidEmails, ['value']), ...newUsersEmails, ...validEmails],
    };
  };

  onEmailsChange = (name: string, value: string) => {
    const allEmails = value.replace(/,\s*$/, '').replace(/\n/g, ',').split(',').filter(Boolean);
    const { emails, hasInvalidEmails, numNewUsers } = this.prepareListOfEmails(allEmails);

    this.setState({
      emails,
      areEmailsValid: !hasInvalidEmails,
      numNewUsers,
      showFakeTextArea: numNewUsers > 0 || hasInvalidEmails,
      apiError: null,
    });
  };

  debouncedOnEmailsChange = debounce(this.onEmailsChange, 1000);

  onSubmit = async (e: SyntheticEvent<>) => {
    e.preventDefault();

    const { classId } = this.props;
    const emails = this.state.emails.map(({ value }) => value);

    if (emails.length === 0 || !this.state.areEmailsValid) return;

    try {
      await this.props.bulkAddUsers({
        classId: String(classId),
        userEmails: emails,
      });
      this.props.onSuccess(emails.length);
      this.onClose();
    } catch (apiError) {
      this.setState({
        apiError,
      });
    }
  };

  toggleFakeTextArea = () => {
    this.setState(
      (state) => ({ showFakeTextArea: !state.showFakeTextArea }),
      () => {
        if (!this.state.showFakeTextArea && this.textArea && this.textArea.inputField) {
          this.textArea.inputField.focus();
        }
      }
    );
  };

  onClose = () => {
    // reset state when closing the modal
    this.setState(
      {
        emails: [],
        numNewUsers: 0,
        areEmailsValid: true,
        showFakeTextArea: false,
        apiError: null,
      },
      this.props.onClose
    );
  };

  getModalText = () => {
    const { numNewUsers } = this.state;
    const pronoun = numNewUsers > 1 ? 'them' : 'it';

    return numNewUsers === 0 ? (
      <span>
        Import students to your class using a list of email addresses. New students will be sent invites to join your
        school account and added to the class, all in one go!
      </span>
    ) : (
      <span>
        <b className={styles.emailsCount}>
          {numNewUsers} {pluralize('email', numNewUsers)}
        </b>{' '}
        {pluralize('was', numNewUsers)} not found on your school account and will be sent invites to join. You can
        remove {pronoun} from the list below if you do not wish to invite {pronoun} to your account.
      </span>
    );
  };

  getButtonLabel = () => {
    const { userType } = this.props;
    const numEmailsEntered = this.state.emails.length;

    let buttonLabel = `Add ${userType}`;
    if (numEmailsEntered > 1) {
      if (this.state.numNewUsers > 0) {
        buttonLabel = `Continue adding ${numEmailsEntered} ${userType}`;
      } else {
        buttonLabel = `Add ${numEmailsEntered} ${userType}`;
      }
    }

    return buttonLabel;
  };

  render() {
    const { classId, className, userType } = this.props;
    const { areEmailsValid, emails, showFakeTextArea, apiError } = this.state;
    const numEmailsEntered = emails.length;
    const canSubmitForm = numEmailsEntered > 0 && areEmailsValid;
    const helpInfo = userType === 'students' && [
      'Not sure what to do? See ',
      <Link key="link" href={links.support.bulkAddStudentsToClass} isExternal>
        Bulk adding students to a class
      </Link>,
      ' in our Help Centre.',
    ];

    return (
      <Box as="form" paddingBottom="spacingLarge4X" testHook="bulk-add-users-form">
        <Stack>
          <Container maxWidth="sizeContainerRoot" textAlign="center">
            <Stack spacing="spacingLarge2X">
              <Heading as="h1">{getDialogHeading({ userType, className, isBulkAdding: true })}</Heading>
              <Text as="p" variant="lead">
                {this.getModalText()}
              </Text>
            </Stack>
          </Container>

          <Container maxWidth="sizeContainerSmall">
            <Stack spacing="spacingLarge4X">
              <Divider />

              {!areEmailsValid && (
                <FormMessage>Oops! Some email addresses are not valid. Please amend the errors below.</FormMessage>
              )}

              {apiError && <UsersValidationErrors error={apiError} />}
              <Label htmlFor="emails">Enter multiple email addresses</Label>
            </Stack>

            {/** Shown instead of the text area when there are invalid or new users **/}
            {showFakeTextArea && (
              <div className={styles.fakeTextArea}>
                <Box
                  as="ul"
                  borderColor="colorBorderInput"
                  borderStyle="solid"
                  borderWidth="borderWidthRoot"
                  borderRadius="borderRadiusSmall"
                  color="colorText"
                  fontFamily="fontFamilySystem"
                  height="150px"
                  lineHeight="lineHeightSmall"
                  marginBottom="spacingRoot"
                  overflowY="scroll"
                  padding="spacingSmall1X spacingSmall"
                  width="sizeFull"
                  onClick={this.toggleFakeTextArea}
                >
                  {emails.map(({ value, isValid, isNew }, i) => (
                    <li
                      key={`email-${i}`}
                      className={cx('fakeTextAreaLine', {
                        fakeTextAreaLineInvalid: !isValid,
                        fakeTextAreaLineNew: isNew,
                      })}
                    >
                      {value}{' '}
                      <Text as="span" fontSize="fontSizeSmall1X" color="colorTextSubtler" marginLeft="spacingSmall1X">
                        {!isValid && 'Not valid'}
                        {isNew && 'New user'}
                      </Text>
                    </li>
                  ))}
                </Box>
                <HelpText>{helpInfo}</HelpText>
              </div>
            )}

            <Box marginBottom="spacingLarge3X">
              <TextArea
                ref={(el) => {
                  this.textArea = el;
                }}
                name="emails"
                id="emails"
                placeholder="Copy and paste a list of the email addresses of the students that you would like to add here, each in a new line."
                value={this.state.emails.map(({ value }) => value).join('\n')}
                info={helpInfo}
                onChange={this.debouncedOnEmailsChange}
                onBlur={this.onEmailsChange}
                className={cx('textArea', { [styles.hide]: showFakeTextArea })}
              />
            </Box>
            <Box textAlign="center">
              <Stack spacing="spacingLarge2X">
                <Button
                  type="submit"
                  isLoading={this.props.isAddingUsers}
                  isDisabled={!canSubmitForm}
                  onClick={this.onSubmit}
                  trackingData={{
                    classId,
                    cta: trackingCtas.classBulkAddUsers,
                    count: numEmailsEntered,
                    userType,
                  }}
                >
                  {this.getButtonLabel()}
                </Button>
                <Text variant="bodySmall1X" color="colorTextSubtler">
                  or{' '}
                  <Button variant="text" size="small" onClick={this.props.onGoBack}>
                    Go back.
                  </Button>
                </Text>
              </Stack>
            </Box>
          </Container>
        </Stack>
      </Box>
    );
  }
}
