// @flow
import { useEffect } from 'react';
import { CachePersistor, SessionStorageWrapper } from 'apollo3-cache-persist';
import { ApolloProvider } from '@apollo/client';

import client, { cache } from 'src/graphql/client';
import Logger from 'src/utils/Logger';

type Props = {
  children: React$Node,
};

const log = new Logger('components/GraphQLProvider');

function usePersistedCache() {
  const persistCache = async () => {
    try {
      const persistor = new CachePersistor({
        cache,
        storage: new SessionStorageWrapper(window.sessionStorage),
      });
      await persistor.restore();

      // $FlowIgnore - onClearStore is defined
      client.onClearStore(() => {
        /**
         * Manually purge the cache from session storage by
         * calling client.clearStore() in consuming code.
         *
         * At the time this was implemented, the session storage cache
         * does not get purged automatically whenever the in-memory
         * cache is cleared. So this has to be done manually.
         *
         * Reference: https://github.com/apollographql/apollo-cache-persist/issues/504
         */
        persistor.purge();
      });
    } catch {
      log.warn('Failed to persist cache');
    }
  };

  useEffect(() => {
    persistCache();
  }, []);
}

export default function GraphQLProvider(props: Props) {
  const { children } = props;

  usePersistedCache();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
