// @flow
import { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

import type { ApiError, PaymentSource } from 'src/types';
import StripeProvider from 'src/components/StripeProvider/StripeProvider';
import FormMessage from 'src/components/FormMessage/FormMessage';
import ErrorTypeMessage from 'src/components/ErrorTypeMessage/ErrorTypeMessage';
import Logger from 'src/utils/Logger';
import { type CreateIntent, type PaymentMethodId, handleCardPayment } from 'src/utils/payments';

import PaymentSourceSelection from './PaymentSourceSelection/PaymentSourceSelection';

const log = new Logger('domains/shared/PaymentSourceForm');

type PaymentSourceFormProps = {
  children: React.Node,
  createIntent: (
    paymentMethodId: PaymentMethodId,
    otherPaymentParams: { [key: string]: string }
  ) => $Call<CreateIntent, PaymentMethodId>,
  error: ?ApiError,
  hidePaymentSourceSelection?: boolean,
  onError: (error: ApiError) => mixed,
  onRequest: () => mixed,
  onSuccess: (intentId?: string) => mixed,
  paymentSource: PaymentSource,
  shouldCreateIntent: () => boolean,
};

function PaymentSourceForm(props: PaymentSourceFormProps) {
  const {
    createIntent,
    shouldCreateIntent,
    onError,
    onRequest,
    onSuccess,
    paymentSource,
    error,
    children,
    hidePaymentSourceSelection,
  } = props;
  const [paymentError, setPaymentError] = useState(null);
  const stripe = useStripe();

  const handleSubmit = async (event: SyntheticEvent<>) => {
    event.preventDefault();
    setPaymentError(null);

    if (!shouldCreateIntent()) {
      onSuccess();
      return;
    }

    onRequest();
    try {
      const intentId = await handleCardPayment(stripe, paymentSource.gateway_payment_method_id, (paymentMethodId) =>
        createIntent(paymentMethodId, { gateway_customer_id: paymentSource.gateway_customer_id })
      );
      onSuccess(intentId);
    } catch (_paymentError) {
      log.warn('Error creating payment method/intent', _paymentError);
      setPaymentError(_paymentError);
      onError(_paymentError);
    }
  };

  const errorToDisplay = error || paymentError;

  return (
    <>
      {errorToDisplay && (
        <FormMessage>
          <ErrorTypeMessage error={errorToDisplay} useAPIMessage />
        </FormMessage>
      )}
      {!hidePaymentSourceSelection && <PaymentSourceSelection paymentSource={paymentSource} />}
      <form onSubmit={handleSubmit}>{children}</form>
    </>
  );
}

export default function PaymentSourceFormWrapper(props: PaymentSourceFormProps) {
  return (
    <StripeProvider>
      <PaymentSourceForm {...props} />
    </StripeProvider>
  );
}
