// @flow
import cx from 'classnames';
import { HideVisually, Text } from '@getatomi/neon';

import type { Subscription, UserType } from 'src/types';
import SubscriptionName from 'src/components/SubscriptionName/SubscriptionName';
import UserName from 'src/components/UserName/UserName';
import ReduxAvatarContainer from 'src/components/ReduxAvatarContainer/ReduxAvatarContainer';
import type { AvatarSize } from 'src/components/Avatar/Avatar';

import styles from './UserNameAvatar.module.scss';

type Props = {
  avatarSize?: AvatarSize,
  isLoggedInAsAccountManager?: boolean,
  isRowLayout?: boolean,
  subscription: Subscription,
  truncateClassName?: string,
  user: UserType,
  withAdditionalItem?: React.Node,
};

export default function UserNameAvatar(props: Props) {
  const {
    user,
    avatarSize,
    isLoggedInAsAccountManager,
    isRowLayout = false,
    subscription,
    truncateClassName,
    withAdditionalItem = false,
  } = props;

  if (!user) return null;

  return (
    <span
      className={cx(styles.root, {
        [styles.isRowLayout]: isRowLayout,
        [styles.withAdditionalItem]: withAdditionalItem,
        [styles.isAvatarSizeLarge]: avatarSize === 'sizeAvatarLarge',
      })}
    >
      <ReduxAvatarContainer user={user} size={avatarSize} />
      <span className={styles.rootInner}>
        <HideVisually>User name: </HideVisually>
        <UserName
          className={cx(styles.userName, truncateClassName && truncateClassName)}
          firstName={user.first_name}
          lastName={user.last_name}
        />
        <Text
          as="span"
          fontSize="fontSizeSmall1X"
          color="colorTextSubtler"
          className={cx(styles.subscriptionName, truncateClassName && truncateClassName)}
        >
          <HideVisually>Subscription name: </HideVisually>
          <SubscriptionName
            enforceDefaultName={isLoggedInAsAccountManager}
            name={subscription.name}
            planType={subscription.plan_customer_type}
          />
        </Text>
        {withAdditionalItem && <span>{withAdditionalItem}</span>}
      </span>
    </span>
  );
}
