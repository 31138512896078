// @flow
import keyMirror from 'keymirror';

// Reference: https://api.getatomi.app/nucleus/api-docs#users-get-a-users-avatar
export default keyMirror({
  small1x: null,
  small: null,
  medium: null,
  large: null,
  'retina-small': null,
  'retina-medium': null,
  'retina-large': null,
});
