// @flow
import { useMemo } from 'react';
import { useQuery, type ApolloError } from '@apollo/client';

import type {
  GetLessonLocations,
  GetLessonLocationsVariables,
  GetLessonLocations_me_classes as GraphqlClassLessonLocation,
  CustomerPlanType,
} from 'src/graphql/types/generated/GetLessonLocations';
import { getPostUrl } from 'src/utils/routes';

import GET_LESSON_LOCATIONS from './GetLessonLocations.graphql';

export type LessonLocation = {
  account: {
    customerType: CustomerPlanType,
    id: string,
    isFamilyPlan: boolean,
    isFree: boolean,
    isSchoolPlan: boolean,
    name: string,
  },
  class: {
    id: string,
    name: string,
  },
  isLocked: boolean,
  subject: {
    color: string,
    groupCode: string,
    levels: Array<string>,
    name: string,
  },
  url: string,
};

export type UseLessonLocation = {
  data: $ReadOnlyArray<LessonLocation> | null,
  error?: ApolloError,
  hasSingleLocation: boolean | null,
  loading: boolean,
};

function isLocationLocked(classLessonLocation: ?GraphqlClassLessonLocation): boolean {
  if (!classLessonLocation) {
    return true;
  }

  const { lesson } = classLessonLocation;
  return lesson ? lesson.__typename.toLowerCase().includes('locked') : true;
}

function isPlan(classLessonLocation: ?GraphqlClassLessonLocation, planName: CustomerPlanType): boolean {
  if (!classLessonLocation) {
    return false;
  }
  const {
    account: { plan },
  } = classLessonLocation;

  return plan.customerType === planName;
}

export function transformLessonLocations(data: GetLessonLocations, isEmbed: boolean): $ReadOnlyArray<LessonLocation> {
  const classLessonLocations = data.me?.classes || [];

  return classLessonLocations.map((classLessonLocation) => {
    const location = {
      account: {
        id: classLessonLocation.account.id,
        isSchoolPlan: isPlan(classLessonLocation, 'SCHOOL'),
        isFamilyPlan: isPlan(classLessonLocation, 'RETAIL_FAMILY'),
        isFree: classLessonLocation.account.plan.isFree,
        customerType: classLessonLocation.account.plan.customerType,
        name: classLessonLocation.account.name,
      },
      class: {
        id: classLessonLocation.id,
        name: classLessonLocation.name,
      },
      isLocked: isLocationLocked(classLessonLocation),
      subject: {
        color: classLessonLocation.subject.color,
        groupCode: classLessonLocation.subject.groupCode,
        name: classLessonLocation.subject.shortName,
        levels: classLessonLocation.subject.levels.map((level) => level.name),
      },
    };

    const moduleId = classLessonLocation.lesson?.categories[0].id;
    const lessonId = classLessonLocation.lesson?.id;

    const url =
      moduleId && lessonId
        ? getPostUrl(classLessonLocation.account.id, classLessonLocation.id, moduleId, lessonId, isEmbed)
        : '';

    return { ...location, url };
  });
}

export default function useLessonLocations(lessonId: string, isEmbed: boolean): UseLessonLocation {
  const { data, error, loading } = useQuery<GetLessonLocations, GetLessonLocationsVariables>(GET_LESSON_LOCATIONS, {
    variables: { lessonId },
  });

  const transformedData = useMemo(() => {
    if (data) {
      return transformLessonLocations(data, isEmbed);
    }
    return null;
  }, [data, isEmbed]);

  const hasSingleLocation = useMemo(() => {
    if (transformedData) {
      return transformedData.length === 1;
    }
    return null;
  }, [transformedData]);

  return {
    error,
    loading,
    hasSingleLocation,
    data: transformedData,
  };
}
