// @flow
import { Box, Divider, Flex, Stack, Strength, Text } from '@getatomi/neon';

import { strengthThresholds } from 'src/utils/config';

import StrengthInfoDelta from './StrengthInfoDelta';

function getStrengthLabel(value: number) {
  const strengthLabels = ['Very low', 'Low', 'Medium', 'High'];

  return value >= 1
    ? strengthLabels[strengthLabels.length - 1]
    : strengthLabels.reduce((acc, label, i) => (value > strengthThresholds[i] ? label : acc));
}

export type StrengthInfoProps = {|
  deltas: {
    +class: ?number,
    +school: ?number,
    +state: ?number,
  },
  mark: ?number,
  markLabel: string,
  strengthLabel: string,
  strengthProps: {|
    color?: string,
    limits?: $ReadOnlyArray<number>,
    value: number,
  |},
|};

export default function StrengthInfo(props: StrengthInfoProps) {
  const { deltas, mark, markLabel, strengthLabel, strengthProps } = props;

  return (
    <Text variant="bodySmall1X" lineHeight="lineHeightRoot">
      <Stack spacing="spacingSmall">
        <Stack spacing="spacingSmall1X">
          {mark != null && (
            <Box testHook="strength-info-mark">
              {markLabel} <strong>{Intl.NumberFormat(undefined, { style: 'percent' }).format(mark)}</strong>
            </Box>
          )}
          <StrengthInfoDelta type="class" value={deltas.class} />
          <StrengthInfoDelta type="school" value={deltas.school} />
          <StrengthInfoDelta type="state" value={deltas.state} />
        </Stack>
        <Stack spacing="spacingSmall1X">
          <Divider />
          <Flex alignItems="center" gap="spacingSmall1X">
            <Strength {...strengthProps} display="inline" />
            <Box as="span" testHook="strength-info-strength">
              {strengthLabel} <strong>{getStrengthLabel(strengthProps.value)}</strong>
            </Box>
          </Flex>
        </Stack>
      </Stack>
    </Text>
  );
}
