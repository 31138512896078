// @flow
import tiers from 'src/constants/challengeTiers';
import type { ChallengeTier } from 'src/types';

export function isPracticeChallenge(tier?: ChallengeTier) {
  return [tiers.TIER_2_PRACTICE, tiers.TIER_3_PRACTICE, tiers['2'], tiers['3']].includes(tier);
}

export function isInteractiveLesson(tier?: ChallengeTier) {
  return [tiers.TIER_5_INTERACTIVE_LESSON, tiers['5']].includes(tier);
}

export const getChallengeLabel = (tier?: ?ChallengeTier) => {
  switch (tier) {
    case tiers['2']:
    case tiers.TIER_2_PRACTICE:
    case tiers['3']:
    case tiers.TIER_3_PRACTICE: {
      return 'practice';
    }
    case tiers['4']:
    case tiers.TIER_4_REVISION: {
      return 'revision';
    }
    case tiers['5']:
    case tiers.TIER_5_INTERACTIVE_LESSON: {
      return 'lesson';
    }
    default:
      return 'quiz';
  }
};
