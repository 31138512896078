// @flow
import { getFraction } from 'src/utils/formatPercentage';
import { successLimits } from 'src/constants/progress';
import type {
  GetChallengeOverview_me_account_class_lesson_ClassChallengeLesson_attempts_questionAttempts as GqlAttempt,
  GetChallengeOverview_me_account_class_lesson_ClassChallengeLesson_challenge_items as GqlItem,
} from 'src/graphql/types/generated/GetChallengeOverview';
import type { ChallengingQuestion } from 'src/domains/ViewingQuizResults/types';

import { mapContentItemType } from '../mapContentItemType';
import type { GetQuestionUrl } from '../getQuestionUrlFactory';

type CommonFields = {
  id: string,
  marks: number,
  title: string,
};

function extractCommonFields(item: GqlItem): CommonFields | null {
  switch (item.__typename) {
    case 'DragAndDropQuestion':
    case 'ExactAnswerQuestion':
    case 'MultipleChoiceQuestion':
    case 'SelfMarkedLiteQuestion':
    case 'SelfMarkedQuestion':
      return {
        id: item.id,
        marks: item.marks,
        title: item.prompt,
      };

    case 'TextSnippet':
    case 'VideoSnippet':
    default:
      return null;
  }
}

const challengingQuestionsLimit = 5;

function isStudentMarkedAttempt(attempt: GqlAttempt): boolean %checks {
  return attempt.__typename === 'QuestionAttemptSelfMarked' || attempt.__typename === 'QuestionAttemptSelfMarkedLite';
}

function getTotalIncorrect(attempts: $ReadOnlyArray<GqlAttempt>) {
  return attempts.reduce(
    (incorrect, attempt) => (attempt.isCorrect === false || attempt.isCorrect === null ? incorrect + 1 : incorrect),
    0
  );
}

function getTotalCorrect(attempts: $ReadOnlyArray<GqlAttempt>): number {
  return attempts.reduce((correct, attempt) => (attempt.isCorrect ? correct + 1 : correct), 0);
}

function getAverageGrade(itemMarks: number, attempts: Array<GqlAttempt>): number {
  const totalGrade = attempts.reduce((accumulator, attempt) => {
    if (isStudentMarkedAttempt(attempt)) {
      const grade = getFraction(attempt.marks, itemMarks) ?? 0;
      return accumulator + grade;
    }
    return accumulator;
  }, 0);

  return attempts.length > 0 ? totalGrade / attempts.length : 0;
}

function getAverageFailedAttempts(incorrect: number, attempts: Array<GqlAttempt>): number {
  return attempts.length > 0 ? incorrect / attempts.length : 0;
}

function sortByAverageFailedAttempts(left: ChallengingQuestion, right: ChallengingQuestion): number {
  return right.averages.incorrect - left.averages.incorrect;
}

export default function calculateChallengingQuestions(
  items: $ReadOnlyArray<GqlItem>,
  attempts: {
    // This is an Array<> due to how lodash.groupBy is typed
    [string]: Array<GqlAttempt>,
  },
  getQuestionUrl: GetQuestionUrl
): $ReadOnlyArray<ChallengingQuestion> {
  return items
    .map((item: GqlItem) => {
      const fields = extractCommonFields(item);
      // Snippet attempts cannot be incorrect, so we can safely ignore them.
      if (!fields) {
        return null;
      }
      const { id, marks, title } = fields;

      const type = mapContentItemType(item.__typename);

      const questionAttempts = attempts[id];
      const sequencePosition = items.findIndex((x) => x.id === id);

      if (!questionAttempts || questionAttempts.length === 0 || sequencePosition === -1) {
        return null;
      }

      const totals = {
        correct: getTotalCorrect(questionAttempts),
        incorrect: getTotalIncorrect(questionAttempts),
      };

      if (totals.incorrect === 0) {
        return null;
      }

      const averageGrade = getAverageGrade(marks, questionAttempts);

      const averages = {
        grade: averageGrade,
        gradeStatus: averageGrade >= successLimits.marksSuccessThreshold ? 'success' : 'error',
        incorrect: getAverageFailedAttempts(totals.incorrect, questionAttempts),
      };

      // There is no specific user associated with challenging questions.
      const url = getQuestionUrl({ itemId: id, userId: null });

      return {
        id,
        title,
        url,
        type,
        sequencePosition: String(sequencePosition + 1),
        totals,
        averages,
      };
    })
    .filter(Boolean)
    .sort(sortByAverageFailedAttempts)
    .slice(0, challengingQuestionsLimit);
}
