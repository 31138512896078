// @flow
import { useState } from 'react';
import { Flex, Text } from '@getatomi/neon';

import type { PaymentSource as PaymentSourceType } from 'src/types';
import PaymentSource from 'src/components/PaymentSource/PaymentSource';
import AddPaymentSourceDialog from 'src/components/AddPaymentSourceDialog/AddPaymentSourceDialog';
import AlertPaymentSourceStateError from 'src/components/AlertPaymentSourceStateError/AlertPaymentSourceStateError';
import Button from 'src/components/Button/Button';

type Props = {|
  paymentSource: ?PaymentSourceType,
|};

export default function PaymentSourceSelection(props: Props) {
  const { paymentSource } = props;
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  const onToggleUpdateModal = () => {
    setIsUpdateModalVisible((isVisible) => !isVisible);
  };

  if (!paymentSource) return null;

  return (
    <Flex direction="column" alignItems="center" gap="spacingSmall">
      <Text variant="bodySmall1X" color="colorTextSubtler">
        Paying with card
      </Text>
      <AlertPaymentSourceStateError
        paymentSourceStatus={paymentSource.status}
        buttonProps={{ children: 'Update card details', onClick: onToggleUpdateModal }}
      />
      <PaymentSource data={paymentSource} />
      {!paymentSource.hasErrors && (
        <Button variant="text" onClick={onToggleUpdateModal} testHook="edit-payment-source-button">
          or edit payment details
        </Button>
      )}
      <AddPaymentSourceDialog actionLabel="update" isVisible={isUpdateModalVisible} onClose={onToggleUpdateModal} />
    </Flex>
  );
}
