import { normalize } from 'normalizr';
import _ from 'lodash';

import apiFetchHandler from 'src/utils/apiFetchHandler';
// Remove tracking property from success and error action payload
// to prevent event tracking to be called twice in trackingMiddleware
const successAndErrorPayload = (payload) => _.omit(payload, 'tracking');

export default function apiMiddleware(requestPathname, rawCookies, auth) {
  return () => (next) => async (action) => {
    if (!action.request) {
      return next(action);
    }

    const { request, ...rest } = action;
    const { types, endpoint, schema, saveErrors = true, throwErrors = false } = request;
    const [requestType, successType, failType] = types;

    // dispatch initial request action
    next({ ...rest, type: requestType });

    const onSuccess = (data) => {
      // if the request object has a 'schema' property then normalize the response
      const result = schema ? normalize(data, schema) : data;

      next({ ...successAndErrorPayload(rest), result, type: successType });

      return result;
    };

    const onError = (exception) => {
      // status will be undefined if it's a network error that had no HTTP response
      // default it to 500 to ensure status codes are sent on the server
      const { statusCode = 500 } = exception;

      let pathname;
      if (__CLIENT__) {
        ({ pathname } = window.location);
      } else {
        pathname = requestPathname;
      }

      next({
        ...successAndErrorPayload(rest),
        error: exception,
        type: failType,
        // routerError should be saved in reducers and are used in App.js to render error pages
        // `saveErrors` is true by default. If set to false, errors will be ignored.
        routeError: saveErrors ? { pathname, status: statusCode, exception } : null,
      });

      // `throwErrors` is false by default. If set to true, the error will be thrown
      // e.g when the user deletes classes from the dashboard  and it fails,
      // we want to catch the error and dispatch a notification action
      if (throwErrors) {
        throw exception;
      }
    };

    try {
      const res = await apiFetchHandler(endpoint, rawCookies, auth);
      return onSuccess(res);
    } catch (e) {
      return onError(e);
    }
  };
}
