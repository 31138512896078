// @flow
import { useEffect } from 'react';

export default function useScrollAndFocus({ shouldTrigger, ref }: { ref: any, shouldTrigger: boolean }) {
  useEffect(() => {
    const element = ref.current;

    if (shouldTrigger && element) {
      element.scrollIntoView();
      element.focus();
    }
  }, [shouldTrigger, ref]);
}
