// @flow
const formatter = new Intl.NumberFormat(undefined, { maximumSignificantDigits: 4, maximumFractionDigits: 3 });
const intFormatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 });

export function format(n: number) {
  if (n >= 1000 && n < 1e6) return intFormatter.format(n);
  return formatter.format(n);
}

export function abbreviate(n: number) {
  if (n < 1000) return formatter.format(n);
  // "we think it might be good, creates a perception of bigger numbers"
  if (n < 1e6) return intFormatter.format(n);
  if (n < 1e9) return `${formatter.format(n / 1e6)}m`;
  if (n < 1e12) return `${formatter.format(n / 1e9)}b`;
  return `${formatter.format(n / 1e12)}t`;
}
