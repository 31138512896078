// @flow

import type {
  GetRevisionMarks_me_account_class_revision_ClassRevision_attempts_questionAttempts as GqlRevisionQuestionAttempt,
  GetRevisionMarks_me_account_class_revision_ClassRevision_items as GqlRevisionItem,
} from 'src/graphql/types/generated/GetRevisionMarks';
import type {
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_attempts_questionAttempts as GqlChallengeQuestionAttempt,
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_challenge_items as GqlChallengeItem,
} from 'src/graphql/types/generated/GetChallengeMarks';
import { getFraction } from 'src/utils/formatPercentage';
import { successLimits } from 'src/constants/progress';

export type QuestionResult = {|
  result: string | null,
  status: 'success' | 'error',
  type: 'default' | 'self-marked' | 'multiple-choice',
|};

export type EmptyQuestionResult = {|
  result: null,
  status: null,
  type: null,
|};

export type IndividualQuestionResult = QuestionResult | EmptyQuestionResult;

const multipleChoiceLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

// An `empty` is visually represented as a dash
export const empty: EmptyQuestionResult = {
  result: null,
  status: null,
  type: null,
};

export function getIndividualQuestionResult(
  studentAttempt: ?GqlChallengeQuestionAttempt | ?GqlRevisionQuestionAttempt,
  item: GqlChallengeItem | GqlRevisionItem
): IndividualQuestionResult {
  if (!studentAttempt) {
    return empty;
  }

  // Skipped questions have `isCorrect === null`
  const isSkipped = studentAttempt.isCorrect === null;

  if (isSkipped) {
    return empty;
  }

  if (studentAttempt.__typename === 'QuestionAttemptSelfMarked' && item.__typename === 'SelfMarkedQuestion') {
    const studentAttemptMarks: number | null = studentAttempt.marks ?? null;
    if (studentAttemptMarks === null) {
      return empty;
    }

    const grade: number | null = getFraction(studentAttemptMarks, item.marks);
    if (grade === null) {
      return empty;
    }

    return {
      result: `${studentAttemptMarks}/${item.marks}`,
      status: grade >= successLimits.marksSuccessThreshold ? 'success' : 'error',
      type: 'self-marked',
    };
  }

  if (studentAttempt.__typename === 'QuestionAttemptSelfMarkedLite' && item.__typename === 'SelfMarkedLiteQuestion') {
    const studentAttemptMarks: number | null = studentAttempt.marks ?? null;
    if (studentAttemptMarks === null) {
      return empty;
    }

    return {
      result: `${studentAttemptMarks}/${item.marks}`,
      status: studentAttempt.isCorrect ? 'success' : 'error',
      type: 'self-marked',
    };
  }

  if (studentAttempt.__typename === 'QuestionAttemptMultipleChoice' && item.__typename === 'MultipleChoiceQuestion') {
    const studentAttemptAnswerId = studentAttempt.answerId;
    const answerIndex = item.options.findIndex((option) => option.id === studentAttemptAnswerId);

    if (answerIndex === -1) {
      return empty;
    }

    return {
      result: multipleChoiceLetters[answerIndex] ?? '',
      status: studentAttempt.isCorrect ? 'success' : 'error',
      type: 'multiple-choice',
    };
  }

  if (
    item.__typename === 'DragAndDropQuestion' ||
    item.__typename === 'ExactAnswerQuestion' ||
    item.__typename === 'TextSnippet' ||
    item.__typename === 'VideoSnippet'
  ) {
    return {
      result: null,
      status: studentAttempt.isCorrect ? 'success' : 'error',
      type: 'default',
    };
  }

  return empty;
}
