// @flow
import { Box, Container, Flex, Heading, HideVisually, Stack } from '@getatomi/neon';

type OverviewLayoutProps = {|
  averageMarkMetric: React.Node,
  challengingQuestionList?: React.Node,
  completionRateMetric: React.Node,
  header: React.Node,
  studentClassificationMetrics: React.Node,
  studentRevisionPanel?: React.Node,
  tabs: React.Node,
  testHook?: string,
|};

export default function OverviewLayout(props: OverviewLayoutProps) {
  const {
    header,
    tabs,
    completionRateMetric,
    averageMarkMetric,
    studentClassificationMetrics,
    challengingQuestionList,
    studentRevisionPanel,
    testHook,
  } = props;

  return (
    <Container maxWidth="sizeContainerLarge" testHook={testHook}>
      <Box paddingBottom="spacingLarge6X">
        <Stack spacing="spacingLarge6X">
          <Stack spacing="spacingLarge5X">
            {header}
            {tabs}
          </Stack>

          <HideVisually>
            <Heading as="h1">Overview</Heading>
          </HideVisually>

          <Box display="grid" gridTemplateColumns={{ desktop: '2fr 1fr' }} gridGap="spacingRoot">
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gridGap="inherit">
              {completionRateMetric}
              {averageMarkMetric}
            </Box>
            <Flex direction="column" gap="spacingRoot" height="sizeFull">
              {studentClassificationMetrics}
            </Flex>
          </Box>

          {challengingQuestionList && (
            <Stack spacing="spacingLarge4X">
              <Heading as="h2" variant="large">
                Most challenging questions
              </Heading>
              {challengingQuestionList}
            </Stack>
          )}

          {studentRevisionPanel && (
            <Stack spacing="spacingLarge4X">
              <Heading as="h2" variant="large">
                Students that need to revise
              </Heading>
              {studentRevisionPanel}
            </Stack>
          )}
        </Stack>
      </Box>
    </Container>
  );
}
