// @flow
import type { Dispatch, GetState } from 'src/types';
import { SUBSCRIPTIONS_ENDPOINT } from 'src/api/endpoints';
import { invoiceListSchema } from 'src/api/schema';
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import { trackingEvents } from 'src/constants/tracking';

export const COLLECT_INVOICE_PAYMENT = 'COLLECT_INVOICE_PAYMENT';
export const COLLECT_INVOICE_PAYMENT_SUCCESS = 'COLLECT_INVOICE_PAYMENT_SUCCESS';
export const COLLECT_INVOICE_PAYMENT_FAIL = 'COLLECT_INVOICE_PAYMENT_FAIL';
export const LOAD_INVOICES = 'LOAD_INVOICES';
export const LOAD_INVOICES_SUCCESS = 'LOAD_INVOICES_SUCCESS';
export const LOAD_INVOICES_FAIL = 'LOAD_INVOICES_FAIL';
export const LOAD_INVOICE_DOWNLOAD_URL = 'LOAD_INVOICE_DOWNLOAD_URL';
export const LOAD_INVOICE_DOWNLOAD_URL_SUCCESS = 'LOAD_INVOICE_DOWNLOAD_URL_SUCCESS';
export const LOAD_INVOICE_DOWNLOAD_URL_FAIL = 'LOAD_INVOICE_DOWNLOAD_URL_FAIL';

export function loadInvoicesBySubscription(subscriptionId: number) {
  return {
    request: {
      types: [LOAD_INVOICES, LOAD_INVOICES_SUCCESS, LOAD_INVOICES_FAIL],
      schema: invoiceListSchema,
      endpoint: {
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/invoices`,
      },
    },
  };
}

export function loadInvoices() {
  return (dispatch: Dispatch, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());
    return dispatch(loadInvoicesBySubscription(subscriptionId));
  };
}

export function loadInvoiceDownloadUrlById(subscriptionId: number, invoiceId: number) {
  return {
    data: {
      invoiceId,
    },
    request: {
      types: [LOAD_INVOICE_DOWNLOAD_URL, LOAD_INVOICE_DOWNLOAD_URL_SUCCESS, LOAD_INVOICE_DOWNLOAD_URL_FAIL],
      endpoint: {
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/invoices/${invoiceId}/download`,
      },
    },
  };
}

export function loadInvoiceDownloadUrl(invoiceId: number) {
  return (dispatch: Dispatch, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());
    return dispatch(loadInvoiceDownloadUrlById(subscriptionId, invoiceId));
  };
}

export function collectInvoicePayment(invoiceId: number, paymentIntentId: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());
    return dispatch({
      request: {
        types: [COLLECT_INVOICE_PAYMENT, COLLECT_INVOICE_PAYMENT_SUCCESS, COLLECT_INVOICE_PAYMENT_FAIL],
        throwErrors: true,
        endpoint: {
          method: 'post',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/invoices/${invoiceId}/collect-payment`,
          body: {
            payment_intent_id: paymentIntentId,
          },
        },
      },
      tracking: {
        event: trackingEvents.paymentRetryRequested,
      },
    });
  };
}
