// @flow
import { useRef } from 'react';
import { Avatar, Flex, HideVisually, IconAvatar, Text } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import ReduxAvatarContainer from 'src/components/ReduxAvatarContainer/ReduxAvatarContainer';
import type { UserType } from 'src/types';

/**
 * NOTE: this is a private component as it's reliant on the API, therefore, only
 * ever consume the shared domain component: `<AvatarUploadContainer />`.
 */

type Props = {
  data: UserType,
  isDeleting?: boolean,
  isOptional?: boolean,
  isUploading?: boolean,
  onChange: (formData: FormData) => mixed,
  onDelete: () => mixed,
  withDelete?: boolean,
};

export default function AvatarUpload(props: Props) {
  const {
    data,
    onChange,
    onDelete,
    isDeleting,
    isOptional = false,
    isUploading = false,
    withDelete = false,
    ...otherProps
  } = props;
  const inputRef = useRef<null | HTMLInputElement>(null);

  if (!data && !onChange && !onDelete) return null;

  const { avatar_url: avatarUrl } = data;

  const onChangeHandler = (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault();
    const [file] = e.target.files;
    const formData = new FormData();
    formData.append('image', file);
    onChange(formData);
  };

  return (
    <Flex alignItems="center" justifyContent="center" gap="spacingLarge">
      <HideVisually>
        <label>
          {`${avatarUrl ? 'Update' : 'Add'} your profile photo`}
          <input
            ref={inputRef}
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={onChangeHandler}
            data-test="avatar-upload-input"
          />
        </label>
      </HideVisually>
      {avatarUrl ? (
        <ReduxAvatarContainer
          user={data}
          size="sizeAvatarLarge1X"
          isDecorative={false}
          testHook="avatar-upload-image"
          {...otherProps}
        />
      ) : (
        <Avatar
          backgroundColor="colorBackgroundBrand"
          size="sizeAvatarLarge1X"
          icon={<IconAvatar size="sizeIconLarge" strokeWidth="1.5" color="colorIconInverted" />}
          testHook="avatar-upload-placeholder"
        />
      )}
      <Flex alignItems="baseline" gap="spacingSmall">
        <Button
          isLoading={isUploading}
          variant="tertiary"
          size="small"
          // $FlowIgnore (Flow does not yet support method calls in optional chains)
          onClick={() => inputRef.current?.click()}
          testHook="avatar-upload"
        >
          {avatarUrl ? 'Update' : 'Add profile photo'}
        </Button>
        {avatarUrl && withDelete && (
          <Button isLoading={isDeleting} variant="tertiary" size="small" onClick={onDelete}>
            Delete
          </Button>
        )}
        {isOptional && (
          <Text variant="bodySmall3X" color="colorTextSubtler">
            Optional
          </Text>
        )}
      </Flex>
    </Flex>
  );
}
