// @flow
import { forwardRef } from 'react';
import { Box } from '@getatomi/neon';

type CircleCountProps = {
  backgroundColor: string,
  children: React.Node,
  color: string,
  size: string | { base?: string, desktop?: string, tablet?: string },
};

export default forwardRef<CircleCountProps, any>(function CircleCount(props, ref) {
  const { backgroundColor, children, color, size, ...otherProps } = props;
  return (
    <Box
      {...otherProps}
      as="span"
      ref={ref}
      size={size}
      color={color}
      backgroundColor={backgroundColor}
      borderRadius="borderRadiusCircle"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      flexShrink="0"
      fontSize={{ base: 'fontSizeSmall1X', desktop: 'fontSizeMedium' }}
      fontWeight={{ base: 'fontWeightSemiBold', desktop: 'fontWeightRegular' }}
      letterSpacing="letterSpacingLarge"
      textIndent="letterSpacingLarge1X"
    >
      {children}
    </Box>
  );
});
