// @flow
import { Flex } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import type { ButtonVariantsType, TrackingDataType } from 'src/types';

type Props = {
  cancelLabel?: string,
  isDisabled?: boolean,
  isLoading?: boolean,
  isStacked?: boolean,
  onCancel: (e: SyntheticEvent<>) => mixed,
  onSubmit?: (e: SyntheticEvent<>) => mixed,
  submitLabel: string,
  submitVariant?: ButtonVariantsType,
  testHook?: string,
  trackingData?: TrackingDataType,
};

export default function ModalActions(props: Props) {
  const {
    isDisabled,
    isLoading,
    isStacked,
    cancelLabel = 'Cancel',
    submitVariant,
    onCancel,
    onSubmit,
    trackingData,
    submitLabel,
    testHook,
  } = props;

  return (
    <Flex
      direction={isStacked ? 'column' : 'row'}
      justifyContent={isStacked ? 'center' : { base: 'center', tablet: 'flex-end' }}
      gap={isStacked ? 'spacingLarge1X' : 'spacingRoot'}
      alignItems="center"
      testHook={testHook}
    >
      <Button
        type="submit"
        variant={submitVariant}
        size={!isStacked ? 'small' : undefined}
        isLoading={isLoading}
        isDisabled={isDisabled}
        onClick={onSubmit}
        testHook={testHook && `${testHook}-submit-button`}
        trackingData={trackingData}
      >
        {submitLabel}
      </Button>
      <Button
        variant="text"
        onClick={onCancel}
        testHook={testHook && `${testHook}-cancel-button`}
        style={{ order: !isStacked ? -1 : undefined }}
      >
        {cancelLabel}
      </Button>
    </Flex>
  );
}
