// @flow
import Link from 'src/components/Link/Link';
import links from 'src/constants/links';

type FindSubjectHelpLinkProps = {
  variant?: 'monochrome',
};

export default function FindSubjectHelpLink(props: FindSubjectHelpLinkProps) {
  const { variant } = props;

  return (
    <Link href={links.support.subjects} isExternal variant={variant}>
      Can’t find your subject?
    </Link>
  );
}
