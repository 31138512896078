// @flow
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Text } from '@getatomi/neon';

import type { PaymentFrequencyType } from 'src/types';
import Button from 'src/components/Button/Button';
import SubscriptionLink from 'src/components/SubscriptionLink/SubscriptionLink';
import ActionResult from 'src/components/ActionResult/ActionResult';
import { getPlanPaymentFrequency } from 'src/reducers/plans';
import { isLoggedInAsParent as isLoggedInAsParentSelector } from 'src/reducers/subscriptions';
import { LoadingBar } from 'src/components/LoadingBar/LoadingBar';

type Props = {
  route: Object,
};

type InjectedProps = Props & {
  isLoggedInAsParent: boolean,
  paymentFrequency: PaymentFrequencyType,
};

const mapStateToProps = (state) => ({
  paymentFrequency: getPlanPaymentFrequency(state),
  isLoggedInAsParent: isLoggedInAsParentSelector(state),
});

function UpgradeSuccess(props: InjectedProps) {
  const { paymentFrequency, isLoggedInAsParent } = props;

  // if payment frequency is not available (e.g when the page is hard reloaded)
  // redirect to the home page on the client-side
  if (!paymentFrequency) {
    if (__CLIENT__) {
      browserHistory.replace('/');
    }

    return null;
  }

  const isSwitchingPaymentFrequency = props.route.path.includes('switch');

  const upgradeDescription = isLoggedInAsParent
    ? 'Your upgrade has been successful. Your child can start learning right away.'
    : 'Your upgrade has been successful.';

  return (
    <main id="main">
      <Helmet>
        <title>Successful upgrade</title>
      </Helmet>
      <LoadingBar />
      <ActionResult
        cta={
          <SubscriptionLink billing={isSwitchingPaymentFrequency}>
            {(to) => (
              <Button to={to} testHook="upgrade-success-confirm">
                Ok great, thanks!
              </Button>
            )}
          </SubscriptionLink>
        }
        description={
          // eslint-disable-next-line no-nested-ternary
          isSwitchingPaymentFrequency ? (
            paymentFrequency === 'month' ? (
              <>
                You can now enjoy monthly unlimited Atomi access{' '}
                <Text as="span" fontSize="fontSizeLarge3X">
                  🙌
                </Text>
              </>
            ) : (
              <>
                You can now enjoy yearly unlimited worry-free Atomi access{' '}
                <Text as="span" fontSize="fontSizeLarge3X">
                  🙌
                </Text>
              </>
            )
          ) : (
            upgradeDescription
          )
        }
        heading={isSwitchingPaymentFrequency ? 'Your payment was successful!' : 'Welcome to the Atomi club!'}
        isFullViewport
        testHook="upgrade-success"
      />
    </main>
  );
}

export default (connect(mapStateToProps, {})(UpgradeSuccess): React.AbstractComponent<Props>);
