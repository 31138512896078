// @flow
import { OptionGroup, OptionGroupItem } from '@getatomi/neon';

import type { SessionLengthFilter } from './types';
import { sessionLengths } from './constants';
import { disabledProps, getSessionDuration } from './utils';

export default function SelectSessionLength({
  disabledValues,
  isTeacher,
  value,
  onChange,
}: {
  disabledValues: { [key: SessionLengthFilter]: boolean },
  isTeacher?: boolean,
  onChange: (value: SessionLengthFilter) => mixed,
  value: SessionLengthFilter,
}) {
  return (
    <OptionGroup
      selectionMode="single"
      label="Session length"
      value={value}
      onChange={(newValue) => onChange(newValue)}
    >
      <OptionGroupItem
        value={sessionLengths.short}
        info={getSessionDuration(isTeacher, sessionLengths.short).displayLabel}
        {...disabledProps(disabledValues[sessionLengths.short])}
      >
        Short
      </OptionGroupItem>
      <OptionGroupItem
        value={sessionLengths.medium}
        info={getSessionDuration(isTeacher, sessionLengths.medium).displayLabel}
        {...disabledProps(disabledValues[sessionLengths.medium])}
      >
        Medium
      </OptionGroupItem>
      <OptionGroupItem
        value={sessionLengths.long}
        info={getSessionDuration(isTeacher, sessionLengths.long).displayLabel}
        {...disabledProps(disabledValues[sessionLengths.long])}
      >
        Long
      </OptionGroupItem>
    </OptionGroup>
  );
}
