// @flow
import { AvatarGroup as NeonAvatarGroup } from '@getatomi/neon';

import type { User } from 'src/components/Avatar/Avatar';
import userFullName from 'src/utils/userFullName';

const renderName = (props: { user: User }) => {
  const { email, firstName, lastName } = props.user;

  return userFullName(firstName, lastName) ?? email;
};

export default function AvatarGroup(props: React.ElementConfig<typeof NeonAvatarGroup>) {
  const { children, ...otherProps } = props;

  return (
    <NeonAvatarGroup {...otherProps} renderName={renderName}>
      {children}
    </NeonAvatarGroup>
  );
}
