// @flow
import CreditInfo from 'src/components/CreditInfo/CreditInfo';
import type { CurrencyType } from 'src/types';

type Props = {
  currency: CurrencyType,
  estimate: ?Object,
};

export default function UpgradeCredits(props: Props) {
  const { currency, estimate } = props;

  if (!estimate || !estimate.isLoaded) return null;

  return (
    <CreditInfo
      currency={currency}
      promotionalCredits={estimate.promotionalCredits}
      refundableCredits={estimate.refundableCredits}
    />
  );
}
