// @flow
import { Button, Flex, IconActionFlip, Stack, Switch, Text, useId, useToast } from '@getatomi/neon';
import { connect } from 'react-redux';
import { useState } from 'react';

import type { ReduxState, BoundAction } from 'src/types';
import {
  isActiveSubscriptionInSetupMode as isActiveSubscriptionInSetupModeSelector,
  getActiveSubscriptionId,
} from 'src/reducers/subscriptions';
import useAlertDialog from 'src/hooks/components/useAlertDialog';
import { activateDailySync, deactivateDailySync, activateSetupMode } from 'src/actions/subscriptions';

import RunManualImportDialog from '../RunManualImportDialog/RunManualImportDialog';

const mapStateToProps = (state: ReduxState) => {
  return {
    isActiveSubscriptionInSetupMode: isActiveSubscriptionInSetupModeSelector(state),
    subscriptionId: getActiveSubscriptionId(state),
  };
};

type Props = {
  externalDataSourceId: string,
  isExternalDataSourceActivated: boolean,
};

type InjectedProps = Props & {
  activateDailySyncAction: BoundAction<typeof activateDailySync>,
  activateSetupModeAction: BoundAction<typeof activateSetupMode>,
  deactivateDailySyncAction: BoundAction<typeof deactivateDailySync>,
  isActiveSubscriptionInSetupMode: boolean,
  subscriptionId: string,
};

function Sync(props: InjectedProps) {
  const {
    isActiveSubscriptionInSetupMode,
    externalDataSourceId,
    isExternalDataSourceActivated,
    subscriptionId,
    activateDailySyncAction,
    deactivateDailySyncAction,
    activateSetupModeAction,
  } = props;

  const [showRunManualImportDialog, setShowRunManualImportDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const labelId = useId();
  const helpTextId = useId();
  const toast = useToast();

  const onSubmit = async ({ action, successMessage, errorMessage, togglePrompt }) => {
    try {
      setIsLoading(true);
      await action({
        subscriptionId,
        externalDataSourceId,
      });
      setIsLoading(false);
      toast.success(successMessage);
    } catch (err) {
      setIsLoading(false);
      toast.error(errorMessage);
    }
    togglePrompt();
  };

  const [alertDialogActivateDailySync, toggleAlertDialogActivateDailySync] = useAlertDialog({
    onConfirmLabel: 'Activate',
    heading: 'Activate daily sync',
    children:
      'Activating daily sync will result in the classes and users on this account being updated to match the school’s external data every 24 hours.',
    isLoading,
    closeOnConfirm: false,
    onConfirm: () =>
      onSubmit({
        action: activateDailySyncAction,
        successMessage: 'The daily sync has been activated.',
        errorMessage: 'There was an error when activating the daily sync. Please try again.',
        togglePrompt: toggleAlertDialogActivateDailySync,
      }),
  });

  const [alertDialogDeactivateDailySync, toggleAlertDialogDeactivateDailySync] = useAlertDialog({
    onConfirmLabel: 'Deactivate',
    heading: 'Deactivate daily sync',
    children:
      'Deactivating daily sync will mean the classes and users on this account are not automatically synced with the school’s external data every 24 hours. This can mean that over time the classes and users may not match the school’s timetable.',
    isLoading,
    closeOnConfirm: false,
    onConfirm: () =>
      onSubmit({
        action: deactivateDailySyncAction,
        successMessage: 'The daily sync has been deactivated.',
        errorMessage: 'There was an error when deactivating the daily sync. Please try again.',
        togglePrompt: toggleAlertDialogDeactivateDailySync,
      }),
  });

  const [alertDialogSetupMode, toggleAlertDialogSetupMode] = useAlertDialog({
    onConfirmLabel: 'Turn on setup mode',
    heading: 'Setup mode is turned off',
    children: 'Setup mode must be active on the account to run a manual import.',
    isLoading,
    closeOnConfirm: false,
    onConfirm: () =>
      onSubmit({
        action: activateSetupModeAction,
        successMessage: 'Setup mode has been activated.',
        errorMessage: 'There was an error when activating setup mode. Please try again.',
        togglePrompt: toggleAlertDialogSetupMode,
      }),
  });

  return (
    <Stack spacing="spacingLarge2X">
      <Text fontSize="fontSizeLarge">Step 3: Sync</Text>
      <Stack spacing="spacingSmall1X">
        <Text id={labelId} variant="bodySmall1X" color="colorTextSubtle" fontWeight="fontWeightMedium">
          Daily Sync
        </Text>
        <Flex gap="spacingSmall" justifyContent="space-between">
          <Text id={helpTextId} variant="bodySmall2X" color="colorTextSubtle">
            If activated this will synchronise users and classes on this account with the school’s Wonde data every 24
            hours.
          </Text>
          <Switch
            ariaLabelledBy={labelId}
            ariaDescribedBy={helpTextId}
            isSelected={isExternalDataSourceActivated}
            onChange={(isSelected) =>
              isSelected ? toggleAlertDialogActivateDailySync() : toggleAlertDialogDeactivateDailySync()
            }
          />
        </Flex>
      </Stack>
      <Button
        onClick={() =>
          isActiveSubscriptionInSetupMode ? setShowRunManualImportDialog(true) : toggleAlertDialogSetupMode()
        }
        iconBefore={<IconActionFlip />}
        variant="tertiary"
      >
        Trigger manual sync
      </Button>
      {showRunManualImportDialog && (
        <RunManualImportDialog
          setShowRunManualImportDialog={setShowRunManualImportDialog}
          externalDataSourceId={externalDataSourceId}
        />
      )}
      {alertDialogActivateDailySync}
      {alertDialogDeactivateDailySync}
      {alertDialogSetupMode}
    </Stack>
  );
}

export default (connect(mapStateToProps, {
  activateDailySyncAction: activateDailySync,
  deactivateDailySyncAction: deactivateDailySync,
  activateSetupModeAction: activateSetupMode,
})(Sync): React.AbstractComponent<Props>);
