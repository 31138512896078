// @flow
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { Alert, Container, useToast } from '@getatomi/neon';
import pluralize from 'pluralize';
import invariant from 'invariant';

import type {
  GetFloatingStudents,
  GetFloatingStudentsVariables,
} from 'src/graphql/types/generated/GetFloatingStudents';

import GET_FLOATING_STUDENTS from './GetFloatingStudents.graphql';
import AlertDialogChangeUserStatus from '../AlertDialogChangeUserStatus/AlertDialogChangeUserStatus';

type Props = {|
  archiveUsersAction: (Array<number>) => void,
  subscriptionId: string,
|};

export default function ArchiveUsersAlert(props: Props) {
  const { archiveUsersAction, subscriptionId } = props;
  const [showArchiveUsersPrompt, setShowArchiveUsersPrompt] = useState(false);
  const [archivingUsers, setArchivingUsers] = useState(false);
  const toast = useToast();

  const { data, loading, error, refetch } = useQuery<GetFloatingStudents, GetFloatingStudentsVariables>(
    GET_FLOATING_STUDENTS,
    {
      variables: {
        accountId: subscriptionId,
      },
    }
  );

  if (loading || error) {
    return null;
  }

  const floatingStudents = data?.me?.account?.students;
  invariant(floatingStudents, 'Floating student data should be defined');

  const numFloatingStudents = floatingStudents.length;

  if (!numFloatingStudents) {
    return null;
  }

  async function handleArchiveUsers() {
    setArchivingUsers(true);
    try {
      await archiveUsersAction(floatingStudents.map((student) => Number(student.id)));
      refetch();

      setShowArchiveUsersPrompt(false);
      toast.success(`The users were archived successfully.`);
    } catch {
      setShowArchiveUsersPrompt(false);
      toast.error('There was an error archiving these users. Please try again.');
    }
  }

  return (
    <Container marginBottom="spacingLarge3X">
      <Alert
        variant="warning"
        buttonProps={{
          children: 'Archive',
          onClick: () => {
            setShowArchiveUsersPrompt(true);
          },
        }}
      >
        There {pluralize('is', numFloatingStudents)} {numFloatingStudents} {pluralize('student', numFloatingStudents)}{' '}
        that {pluralize('is', numFloatingStudents)} not enrolled in any classes. Would you like to archive them?
      </Alert>
      <AlertDialogChangeUserStatus
        action="archive"
        numSelectedUsers={numFloatingStudents}
        isLoading={archivingUsers}
        isOpen={showArchiveUsersPrompt}
        onDismiss={() => setShowArchiveUsersPrompt(false)}
        onConfirm={handleArchiveUsers}
      />
    </Container>
  );
}
