// @flow
import { useQuery, type ApolloError } from '@apollo/client';

import type { GetClassModules, GetClassModulesVariables } from 'src/graphql/types/generated/GetClassModules';

import GET_CLASS_MODULES from './GetClassModules.graphql';

type Props = {|
  classId: string,
  subscriptionId: string,
|};

type Output = {|
  className: ?string,
  error: ?ApolloError,
  loading: boolean,
  modules: $ReadOnlyArray<{|
    id: string,
    lessonCount: number,
    name: string,
  |}>,
|};

export default function useGetClassModules(props: Props): Output {
  const { classId, subscriptionId } = props;

  const { data, error, loading } = useQuery<GetClassModules, GetClassModulesVariables>(GET_CLASS_MODULES, {
    variables: {
      accountId: subscriptionId,
      classId,
    },
  });

  const rawClass = data?.me?.account?.class;
  const rawModules = rawClass?.modules ?? [];
  const modules = rawModules
    .map((module) => {
      if (module.__typename !== 'ClassCategory' || module.metrics.contentCount === 0) {
        return null;
      }
      return {
        id: module.id,
        name: module.name,
        lessonCount: module.metrics.contentCount,
      };
    })
    .filter(Boolean);

  return {
    className: rawClass?.name ?? null,
    error,
    loading: loading && !data,
    modules,
  };
}
