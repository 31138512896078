// @flow
import * as React from 'react';
import { Box, Divider, Heading, IconChevronRight, IconLock, Stack, Text, TextLoader, useHover } from '@getatomi/neon';

import { UnstyledLink } from 'src/components/Link/Link';

type ItemProps = {|
  heading: string,
  isLocked?: boolean,
  onClick?: () => void,
  subheading?: string,
  url?: string,
|};

type DividerStackProps = {|
  items: $ReadOnlyArray<ItemProps>,
|};

function Item(props: ItemProps) {
  const { heading, url, onClick, isLocked = false } = props;

  const { hoverProps, isHovered } = useHover({});

  let interactiveProps = {};
  if (url) {
    interactiveProps = {
      as: UnstyledLink,
      to: url,
    };
  } else if (onClick) {
    interactiveProps = {
      as: 'button',
      type: 'button',
      onClick,
    };
  }

  const subheading = isLocked ? 'Upgrade to use content' : props.subheading;

  return (
    <Box width="sizeFull" {...interactiveProps}>
      <Box
        display="flex"
        alignItems="center"
        gap="spacingRoot"
        cursor="pointer"
        transition="transitionRoot"
        backgroundColor={isHovered && 'colorBackgroundHovered'}
        padding="spacingLarge"
        {...hoverProps}
      >
        <Box flex={1} textAlign="left">
          <Heading as="p" variant="small" marginBottom="spacingSmall2X">
            {heading}
          </Heading>

          {subheading && (
            <Text as="span" color="colorTextSubtle" variant="bodySmall">
              {subheading}
            </Text>
          )}
        </Box>

        {isLocked ? (
          <IconLock size="sizeIconSmall1X" color="colorIcon" strokeWidth={1.5} testHook="lock-icon" />
        ) : (
          <IconChevronRight size="sizeIconSmall2X" strokeWidth={2} color="colorIcon" />
        )}
      </Box>
    </Box>
  );
}

export default function DividerStack(props: DividerStackProps) {
  const { items } = props;

  return (
    <Stack as="ul" spacing="spacingNone">
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <Item {...item} />
          <Divider />
        </React.Fragment>
      ))}
    </Stack>
  );
}

export function DividerStackLoader() {
  return (
    <Stack as="ul" spacing="spacingNone" testHook="divider-stack-loader">
      {Array.from({ length: 3 }, (v, i) => (
        <React.Fragment key={i}>
          <Box padding="spacingLarge">
            <TextLoader height={25} marginBottom="spacingSmall" width={230} />
            <TextLoader height={22} width={100} />
          </Box>
          <Divider />
        </React.Fragment>
      ))}
    </Stack>
  );
}
