// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import postTypes from 'src/constants/postTypes';
import { getPostUrl } from 'src/utils/routes';
import type { LessonType, SubtopicType, TopicType } from 'src/components/LessonList/LessonList';
import type {
  GetSearchLessons,
  GetSearchLessonsVariables,
  GetSearchLessons_me_account_subjects_LevelSubject_subject_module_topics as GqlTopic,
  GetSearchLessons_me_account_subjects_LevelSubject_subject_module_topics_BaseCategory_lessons as GqlTopicLesson,
  GetSearchLessons_me_account_subjects_LevelSubject_subject_module_topics_BaseCategory_subtopics as GqlSubtopic,
  GetSearchLessons_me_account_subjects_LevelSubject_subject_module_topics_BaseCategory_subtopics_BaseCategory_lessons as GqlSubtopicLesson,
} from 'src/graphql/types/generated/GetSearchLessons';

import GET_SEARCH_LESSONS from './GetSearchLessons.graphql';

type Output = {|
  error: ?ApolloError,
  loading: boolean,
  topics: ?$ReadOnlyArray<TopicType>,
|};

export type SelectLessonsVariables = {|
  levelId: string | null,
  moduleId: string,
  subjectCode: string,
  subscriptionId: string,
|};

type Props = {
  ...SelectLessonsVariables,
  classId: string,
};

function transformLesson(rawLesson: GqlTopicLesson | GqlSubtopicLesson, getPreviewUrl): ?LessonType {
  if (rawLesson.__typename === 'BaseCategory') {
    return;
  }

  let type;
  switch (rawLesson.__typename) {
    case 'BaseTextLesson':
    case 'LockedTextLesson': {
      type = postTypes.text;
      break;
    }
    case 'BaseVideoLesson':
    case 'LockedVideoLesson': {
      type = postTypes.video;
      break;
    }
    default: {
      type = postTypes.challenge;
      break;
    }
  }

  const challengeTier = rawLesson.__typename === 'BaseChallengeLesson' ? rawLesson.tier : null;

  return {
    id: rawLesson.id,
    isLocked: rawLesson.__typename.includes('Locked'),
    duration: rawLesson.duration,
    name: rawLesson.name,
    previewUrl: getPreviewUrl(rawLesson.id),
    challengeTier,
    type,
  };
}

function transformSubtopic(subtopic: GqlSubtopic, getPreviewUrl): ?SubtopicType {
  if (subtopic.__typename === 'BaseCategory') {
    const lessons = subtopic.lessons.map((lesson) => transformLesson(lesson, getPreviewUrl)).filter(Boolean);
    if (!lessons.length) {
      return;
    }
    return {
      name: subtopic.name,
      lessons,
    };
  }
}

function transformTopic(topic: GqlTopic, getPreviewUrl): ?TopicType {
  if (topic.__typename === 'BaseCategory') {
    const subtopics = topic.subtopics.map((subtopic) => transformSubtopic(subtopic, getPreviewUrl)).filter(Boolean);
    const lessons = topic.lessons.map((topicLesson) => transformLesson(topicLesson, getPreviewUrl)).filter(Boolean);

    if (!subtopics.length && !lessons.length) {
      return;
    }

    return {
      name: topic.name,
      subtopics,
      lessons,
    };
  }
}

export default function useGetLessons(props: Props): Output {
  const { subscriptionId, levelId, subjectCode, moduleId, classId } = props;

  const { data, error, loading } = useQuery<GetSearchLessons, GetSearchLessonsVariables>(GET_SEARCH_LESSONS, {
    variables: {
      accountId: subscriptionId,
      moduleId,
      filters: {
        excludeLocked: true,
        subjectCode,
        levelId,
      },
    },
  });

  if (error) {
    return {
      error,
      loading: false,
      topics: null,
    };
  }

  if (loading && !data) {
    return {
      loading: true,
      error: null,
      topics: null,
    };
  }

  const levelSubject = data?.me?.account.subjects[0];
  invariant(levelSubject != null, '[useGetLessons] - Subject should be defined');
  invariant(levelSubject.__typename !== 'LockedLevelSubject', '[useGetLessons] - Subject should not be locked');

  const subjectData = levelSubject.subject;
  invariant(subjectData, 'Subject data should be defined');

  const moduleData = subjectData.module;
  invariant(moduleData && moduleData.__typename === 'BaseCategory', 'Module data should be defined');

  const getPreviewUrl = (lessonId: string): string => {
    return getPostUrl(subscriptionId, classId, moduleId, lessonId);
  };

  const topics = moduleData.topics.map((topic) => transformTopic(topic, getPreviewUrl)).filter(Boolean);

  return {
    topics,
    loading: false,
    error: null,
  };
}
