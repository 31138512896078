// @flow
import { useState } from 'react';
import { Container, Modal } from '@getatomi/neon';

import type { NewAttachedContent } from 'src/domains/Tasks/types';
import UpgradeAccountPanel from 'src/domains/Class/UpgradeAccountPanel/UpgradeAccountPanel';

import TeacherRevisionDialogContainer from './TeacherRevisionDialogContainer';

type Props = {|
  isFreePlan?: boolean,
  onCreate: (newRevision: NewAttachedContent) => void,
  region?: string,
|};

export default function useTeacherRevisionDialog(props: Props) {
  const { isFreePlan = false, onCreate, region } = props;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [defaultTopicIds, setDefaultTopicIds] = useState<?$ReadOnlyArray<string>>(null);

  const close = () => setShowDialog(false);

  let dialog = null;
  if (showDialog) {
    dialog = isFreePlan ? (
      <Modal isOpen ariaLabel="Create revision" size="fullscreen" variant="view" onClose={close}>
        <Container maxWidth="sizeContainerLarge">
          <UpgradeAccountPanel
            videoId={region === 'CA_ON' ? 'vjpccjuwaa' : 'jod1jninkn'}
            heading="Want to create a revision session?"
            description="When you upgrade to unlimited access you can create sessions to revise content or get exam-ready by doing practice questions."
          />
        </Container>
      </Modal>
    ) : (
      // $FlowIgnore - withRouter passes in params
      <TeacherRevisionDialogContainer
        isOpen
        defaultTopicIds={defaultTopicIds}
        onClose={close}
        onCreate={(newRevision) => {
          onCreate(newRevision);
          close();
        }}
      />
    );
  }

  const open = ({ selectedTopicIds }: {| selectedTopicIds?: $ReadOnlyArray<string> |} = {}) => {
    setShowDialog(true);

    if (selectedTopicIds) {
      setDefaultTopicIds(selectedTopicIds);
    }
  };

  return { dialog, open, close };
}
