// @flow
import { connect } from 'react-redux';
import { withRouter, type ContextRouter } from 'react-router';

import type { ReduxState } from 'src/types';
import { isLoggedInAsStudent } from 'src/reducers/subscriptions';

import StudentTaskContainer from './StudentTask/StudentTaskContainer';
import ClassTaskContainer from './ClassTask/ClassTaskContainer';

type Props = {
  params: {
    classId: string,
    subscriptionId: string,
    taskId: string,
  },
};

export type InjectedProps = Props & {
  isStudent: boolean,
  router: ContextRouter,
};

const mapStateToProps = (state: ReduxState) => {
  return {
    isStudent: isLoggedInAsStudent(state),
  };
};

function Task(props: InjectedProps) {
  const { isStudent, ...rest } = props;
  const TaskContainer = isStudent ? StudentTaskContainer : ClassTaskContainer;
  return <TaskContainer {...rest} />;
}

export default withRouter(connect(mapStateToProps, {})(Task));
