// @flow
import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Container } from '@getatomi/neon';
import invariant from 'invariant';
import Helmet from 'react-helmet';

import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import { useNavigationContext } from 'src/hooks/useNavigationContext';

import GET_TOPIC_INSIGHTS from './GetTopicInsights.graphql';
import { topicInsightsTransformer } from './topicInsightsTransformer';
import TopicInsightsView, { TopicInsightsViewLoader } from './TopicInsightsView';

type Params = {
  classId: string,
  subscriptionId: string,
  topicId: string,
};

type TopicInsightsProps = {
  params: Params,
};

export function TopicInsightsQuery(props: TopicInsightsProps) {
  const { classId, subscriptionId: accountId, topicId } = props.params;

  const { data, loading, error } = useQuery(GET_TOPIC_INSIGHTS, {
    variables: {
      accountId,
      classId,
      topicId,
      filters: {
        roles: ['STUDENT'],
        userAccountStatuses: ['ACTIVE'],
      },
    },
  });

  const [, setNavigationContext] = useNavigationContext();
  useEffect(() => {
    const topicName = data?.me.account.class.category.name;
    if (topicName) {
      setNavigationContext({
        location: 'classTopicInsights',
        metadata: {
          accountId,
          classId,
          topicId,
          title: `${topicName} insights`,
        },
      });
    }
  }, [classId, accountId, setNavigationContext, topicId, data]);

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load the topic insights page." />;
  }

  if (loading && !data) {
    return <TopicInsightsViewLoader />;
  }

  invariant(
    data && data.me && data.me.account && data.me.account.class && data.me.account.class.category,
    'Topic insights data should be defined'
  );

  const preparedData = topicInsightsTransformer(data.me.account.class);

  return <TopicInsightsView accountId={accountId} classId={classId} preparedData={preparedData} topicId={topicId} />;
}

export default function TopicInsightsContainer(props: TopicInsightsProps) {
  return (
    <Container maxWidth="sizeContainerLarge" marginTop="spacingLarge">
      <Helmet>
        <title>Topic insights</title>
      </Helmet>
      <TopicInsightsQuery {...props} />
    </Container>
  );
}
