// @flow
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useCallback, useState, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { checkExistingUser as checkExistingUserAction } from 'src/actions/auth';
import { trackPotentialUser } from 'src/utils/tracking';
import type { BoundAction } from 'src/types';
import useAsyncValidation, { type AsyncValidation } from 'src/hooks/useAsyncValidation';

import emailValidator from './validators/emailValidator';

export type Fields = {
  email?: string,
  firstName?: string,
  lastName?: string,
  password?: string,
};

type AllFields =
  | Fields
  | {
      strength?: number,
    };

// Data to be omitted when returning a successful submission
const BLOCK_LIST = ['strength'];
const omitBlocked = (data: AllFields): Fields => _.omit(data, BLOCK_LIST);

// By default, the yup resolver will use the label of the field as the label param for the
// translation message. In the case of password, the label over the field doesn't make sense when
// used in a validation message, so we are providing an alternative
const labels = {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  password: 'Set your password',
  passwordAlternate: 'Password',
};

const createResolver = (emailAsyncValidation: AsyncValidation) =>
  yupResolver(
    yup.object().shape({
      firstName: yup
        .string()
        .trim()
        .label(labels.firstName)
        .required()
        .max(process.env.VALIDATION_MAX_LENGTH_FIRSTNAME),
      lastName: yup.string().trim().label(labels.lastName).required().max(process.env.VALIDATION_MAX_LENGTH_LASTNAME),
      email: yup
        .string()
        .trim()
        .label(labels.email)
        .required()
        .test('email', '', function test() {
          return emailAsyncValidation.isValid.current === null
            ? true
            : this.createError({
                message: emailAsyncValidation.isValid.current,
              });
        }),
      password: yup
        .string()
        .label(labels.passwordAlternate)
        .required()
        .min(process.env.VALIDATION_PASSWORD_MIN_LENGTH)
        .test('strength', '', function test() {
          return this.parent.strength >= process.env.VALIDATION_PASSWORD_MIN_STRENGTH
            ? true
            : this.createError({
                message:
                  'The password you’ve submitted is too easy to guess and would risk the security of your account. Try creating a longer password and/or avoid using common words or sequences.',
              });
        }),
    })
  );

export default function useRegistrationFormStep1({
  defaultValues,
  onSubmitSuccess,
  onSubmitFail,
  checkExistingUser,
}: {
  checkExistingUser: BoundAction<typeof checkExistingUserAction>,
  defaultValues: Fields,
  onSubmitFail: () => void,
  onSubmitSuccess: (data: Fields) => void,
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailWarning, setEmailWarning] = useState<?string>(null);

  const emailAsyncValidation = useAsyncValidation(async (email) => {
    return emailValidator({
      email,
      setEmailWarning,
      checkExistingUser,
    });
  });

  const resolver = useMemo(() => createResolver(emailAsyncValidation), [emailAsyncValidation]);
  const { control, formState, handleSubmit, getValues, trigger, setValue } = useForm({
    resolver,
    defaultValues,
  });
  const { isSubmitted } = formState;

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    setIsLoading(true);

    const email = getValues('email');
    if (email !== '') {
      await emailAsyncValidation.validate(email);
    }

    handleSubmit((data) => onSubmitSuccess(omitBlocked(data)), onSubmitFail)(e);
    setIsLoading(false);
  };

  const onPasswordStrengthChange = useCallback(
    (strength: number) => {
      setValue('strength', strength, { shouldValidate: isSubmitted });
      // retrigger password validation if the form has already been submitted
      if (isSubmitted && strength) trigger('password');
    },
    [isSubmitted, setValue, trigger]
  );

  const firstNameMessage = formState.errors.firstName?.message ?? null;
  const lastNameMessage = formState.errors.lastName?.message ?? null;
  const emailMessage = formState.errors.email?.message ?? null;
  const passwordMessage = formState.errors.password?.message ?? null;

  return {
    control,
    isLoading,
    form: {
      onSubmit,
    },
    fields: {
      firstName: {
        validationText: firstNameMessage,
        errorVariant: firstNameMessage && 'error',
        label: labels.firstName,
        onBlur: trackPotentialUser,
        isRequired: true,
      },
      lastName: {
        validationText: lastNameMessage,
        errorVariant: lastNameMessage && 'error',
        label: labels.lastName,
        onBlur: trackPotentialUser,
        isRequired: true,
      },
      email: {
        validationText: emailMessage || emailWarning,
        errorVariant: (emailMessage && 'error') || (emailWarning && 'warning'),
        label: labels.email,
        onChange: () => {
          if (formState.errors.email) {
            emailAsyncValidation.clear();
            trigger('email');
          }
        },
        onBlur: async (event: SyntheticInputEvent<HTMLInputElement>) => {
          const { value } = event.currentTarget;
          // do not validate the email when is an empty string, e.g. if the user only tabbed through
          // the field and didn't fill anything
          if (value !== '') {
            await emailAsyncValidation.validate(value);
            trigger('email');
          }
        },
        isLoading: !isLoading && emailAsyncValidation.isLoading,
        isRequired: true,
      },
      password: {
        onStrengthChange: onPasswordStrengthChange,
        validationText: passwordMessage,
        errorVariant: passwordMessage && 'error',
        label: labels.password,
        isRequired: true,
      },
    },
  };
}
