// @flow
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@getatomi/neon';

import { parseValidationErrors } from 'src/api/validation';
import { trackPotentialUser } from 'src/utils/tracking';
import referralItems from 'src/constants/referral';

export type Fields = {
  levelId?: ?number | ?string,
  referral?: ?string,
  schoolId?: number | string,
};

const labels = {
  schoolId: 'School',
  levelId: 'Year level',
  referral: 'How did you hear about us?',
  referralAlternate: 'Referral',
};

const resolver = yupResolver(
  yup.object().shape({
    schoolId: yup.lazy((value) => {
      if (typeof value === 'number') {
        return yup.number().required().label(labels.schoolId);
      }
      return yup.string().required().label(labels.schoolId);
    }),
    levelId: yup.number().nullable().required().label(labels.levelId),
    referral: yup
      .mixed()
      .oneOf([null].concat(referralItems.map((item) => item.key)))
      .label(labels.referralAlternate),
  })
);

export default function useStudentRegistrationFormStep2({
  defaultValues,
  onSubmitSuccess,
  onSubmitFail,
}: {
  defaultValues: Fields,
  onSubmitFail: () => void,
  onSubmitSuccess: (data: Fields) => void | Promise<void>,
}) {
  const { clearErrors, control, formState, getValues, handleSubmit, setError, setValue } = useForm({
    resolver,
    defaultValues,
  });
  const toast = useToast();

  const onSubmit = (e: Event) => {
    e.preventDefault();
    clearErrors('serverError');
    handleSubmit(async (data) => {
      // See "Redux in form hooks" in Forms - The Basics (README.md#redux-and-createresolver)
      try {
        await onSubmitSuccess(data);
      } catch (error) {
        const validationErrors = parseValidationErrors(error);
        setError('serverError', { type: 'manual', message: validationErrors });

        if (validationErrors.length === 0) {
          toast.error('There was an error submitting your registration. Please try again.');
        }
      }
    }, onSubmitFail)(e);
  };

  const schoolIdMessage = formState.errors.schoolId?.message ?? null;
  const levelIdMessage = formState.errors.levelId?.message ?? null;
  const referralMessage = formState.errors.referral?.message ?? null;

  const { serverError } = formState.errors;

  return {
    control,
    setValue,
    serverError,
    form: {
      onSubmit,
    },
    tracking: {
      trackSchoolId: () => trackPotentialUser('schoolId', getValues('schoolId')),
      trackLevelId: () => trackPotentialUser('levelId', getValues('levelId')),
      trackReferral: () => trackPotentialUser('referral', getValues('referral')),
    },
    fields: {
      schoolId: {
        label: labels.schoolId,
        validationText: schoolIdMessage,
        errorVariant: schoolIdMessage && 'error',
      },
      levelId: {
        label: labels.levelId,
        validationText: levelIdMessage,
        errorVariant: levelIdMessage && 'error',
      },
      referral: {
        label: labels.referral,
        validationText: referralMessage,
        errorVariant: referralMessage && 'error',
      },
    },
  };
}
