// @flow
import { connect } from 'react-redux';
import { withRouter, type LocationShape } from 'react-router';

import type { ReduxState } from 'src/types';
import BackLink from 'src/components/BackLink/BackLink';
import { getClassById } from 'src/reducers/classes';
import { isLoggedInAsStudent } from 'src/reducers/subscriptions';
import {
  getClassTasksUrl,
  getClassUrl,
  getTasksUrl,
  isLtiUrl,
  getLtiUrl,
  getLtiClassUrl,
  getLtiClassModuleUrl,
} from 'src/utils/routes';

import MobileBackLinkLayout from '../MobileBackLinkLayout/MobileBackLinkLayout';

type Props = {
  location: LocationShape,
  params: {
    classId: number,
    moduleId?: number,
    postId?: number,
    subscriptionId: number,
    taskId?: number,
  },
};

const mapStateToProps = (state: ReduxState, { params }: Props) => {
  const { classId } = params;
  const parentClass = getClassById(state, classId);

  return {
    isStudent: isLoggedInAsStudent(state),
    parentClassName: parentClass?.name ?? null,
  };
};

type InjectedProps = Props & {
  isStudent: boolean,
  parentClassName: ?string,
};

function MobileBackLink(props: InjectedProps) {
  const {
    isStudent,
    location,
    parentClassName,
    params: { classId, moduleId, postId, subscriptionId, taskId },
  } = props;

  let label: string = 'Back';
  let to: string = '/';

  if (isLtiUrl(location.pathname)) {
    to = getLtiUrl(subscriptionId);
    label = 'Back to classes';

    if (moduleId) {
      to = getLtiClassUrl(subscriptionId, classId);
      label = 'Back to modules';

      if (postId) {
        to = getLtiClassModuleUrl(subscriptionId, classId, moduleId);
        label = 'Back to lessons';
      }
    }
  } else if (moduleId) {
    to = getClassUrl(subscriptionId, classId);
    if (parentClassName) {
      label = parentClassName;
    }
  } else if (taskId) {
    if (isStudent) {
      to = getTasksUrl(subscriptionId);
      label = 'Tasks';
    } else {
      to = getClassTasksUrl(subscriptionId, classId);
      label = parentClassName ? `${parentClassName} Tasks` : 'Tasks';
    }
  }

  return (
    <MobileBackLinkLayout>
      <BackLink to={to}>{label}</BackLink>
    </MobileBackLinkLayout>
  );
}

export default withRouter(connect(mapStateToProps, {})(MobileBackLink));
