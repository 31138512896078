// @flow
import pluralize from 'pluralize';
import {
  Box,
  Card,
  Flex,
  Heading,
  IconAlertError,
  IconCircleHelp,
  IconCheck,
  IconCirclePlay,
  Stack,
  Text,
  Tooltip,
} from '@getatomi/neon';

import Avatar from 'src/components/Avatar/Avatar';

import type { Student, StudentHighlight } from './classInsightsTransformer';

type HighlightsProps = {
  heading: string,
  highlights: $ReadOnlyArray<StudentHighlight>,
};

function BadgeIcon(props: {| icon: string |}) {
  const { icon } = props;

  switch (icon) {
    case 'alert-error':
      return <IconAlertError size="sizeIconSmall1X" />;
    case 'check':
      return <IconCheck size="sizeIconSmall1X" />;
    case 'circle-help':
      return <IconCircleHelp size="sizeIconSmall1X" />;
    case 'circle-play':
      return <IconCirclePlay size="sizeIconSmall1X" />;
    default:
      return null;
  }
}

function Badge(props: { icon: string }) {
  const { icon } = props;

  return (
    <Box
      alignItems="center"
      backgroundColor="colorBackgroundNeutralBold"
      bottom={0}
      borderRadius="borderRadiusCircle"
      display="flex"
      justifyContent="center"
      padding="spacingSmall1X"
      position="absolute"
      right={0}
    >
      <BadgeIcon icon={icon} />
    </Box>
  );
}

function StudentAvatar(props: { icon: string, student: Student }) {
  const { icon, student } = props;

  return (
    <Box position="relative">
      <Avatar size="sizeAvatarLarge1X" user={student} />
      <Badge icon={icon} />
    </Box>
  );
}

function StudentLabel(props: { students: $ReadOnlyArray<Student> }) {
  const studentDisplayName = (student) => {
    return student.fullName;
  };

  const { students } = props;

  const [firstStudent, ...otherStudents] = students;

  if (students.length === 1) {
    return studentDisplayName(firstStudent);
  }

  const tooltipContent = (
    <Stack as="ul" spacing="spacingSmall4X">
      {students.map(studentDisplayName)}
    </Stack>
  );

  const studentLabel = `${studentDisplayName(firstStudent)} and ${otherStudents.length} ${pluralize(
    'other',
    otherStudents.length
  )}`;

  return (
    <Tooltip content={tooltipContent}>
      <Box as="span" tabIndex={0}>
        {studentLabel}
      </Box>
    </Tooltip>
  );
}

function HighlightCard(props: StudentHighlight) {
  const { icon, label, students } = props;

  const firstStudent = students[0];

  return (
    <li>
      <Card
        height="sizeFull"
        paddingBlock="spacingLarge3X"
        paddingInline="spacingRoot"
        textAlign="center"
        variant="rounded"
        withHover={false}
      >
        <Flex justifyContent="center" marginBottom="spacingLarge1X">
          <StudentAvatar icon={icon} student={firstStudent} />
        </Flex>

        <Text variant="bodySmall1X" fontWeight="fontWeightMedium">
          <StudentLabel students={students} />
        </Text>

        <Text color="colorTextSubtle" variant="bodySmall2X">
          {label}
        </Text>
      </Card>
    </li>
  );
}

export function Highlights(props: HighlightsProps) {
  const { heading, highlights } = props;

  return (
    <Stack as="section" spacing="spacingLarge5X">
      <Heading as="h3" variant="large">
        {heading}
      </Heading>

      <Box
        as="ul"
        display="grid"
        gap="spacingRoot"
        gridTemplateColumns={{
          base: '1fr',
          mobile: 'repeat(2, 1fr)',
          tablet: 'repeat(3, 1fr)',
          desktop: 'repeat(5, 1fr)',
        }}
      >
        {highlights.map((highlight, index) => (
          <HighlightCard key={index} {...highlight} />
        ))}
      </Box>
    </Stack>
  );
}

export default Highlights;
