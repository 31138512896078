import { startCase, toLower } from 'lodash';

import { trackEvent } from 'src/utils/tracking';

/**
 * Tracking middleware
 * looks for the 'tracking' object on the action e.g
 *
 tracking: {
    event: 'Event name',
    data: {
      ...actionData,
    },
    // custom callback method to extract data from the state before sending it with the action
    // if not provided it will use the action 'data' object
    selectTrackingData: (state) => ({
      productCode: state.reducer.property,
    }),
  },
 *
 */
export default (store) => (next) => (action) => {
  const { tracking } = action;

  if (!tracking) return next(action);

  const eventName = startCase(toLower(tracking.event));
  let eventData = tracking.data || action.data;
  const { selectTrackingData } = tracking;

  if (selectTrackingData) {
    const additionalTrackingData = selectTrackingData(store.getState());
    eventData = { ...eventData, ...additionalTrackingData };
  }

  trackEvent(eventName, eventData);

  return next(action);
};
