// @flow
import { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { map, size } from 'lodash';
import {
  Box,
  Container,
  Flex,
  HideVisually,
  Item,
  ItemDescription,
  ItemLabel,
  PickList,
  Stack,
  StatusLight,
  Text,
  Truncate,
  useToast,
} from '@getatomi/neon';

import type { BoundAction, ClassType, ReduxState, RegionCode, Subject } from 'src/types';
import Logger from 'src/utils/Logger';
import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';
import FindSubjectHelpLink from 'src/components/FindSubjectHelpLink/FindSubjectHelpLink';
import { updateUser } from 'src/actions/auth';
import { getUserRegion, isUpdatingUser as isUpdatingUserSelector } from 'src/reducers/auth';
import { getAllClasses, isUserClassesLoaded } from 'src/reducers/classes';
import { isLevelsLoadedWithSubjects } from 'src/reducers/levels';
import { getAllSubjectsWithLevels } from 'src/reducers/subjects';
import { loadClasses } from 'src/actions/classes';
import { loadLevels } from 'src/actions/levels';
import connectData from 'src/decorators/connectData';
import { getSubjectLimit } from 'src/utils/config';
import links from 'src/constants/links';
import classesSourceFilters from 'src/constants/classesSourceFilters';

const log = new Logger('domains/Settings/Subjects');

const fetchData = (getState, dispatch) => {
  const promises = [];
  const state = getState();
  // load levels
  if (!isLevelsLoadedWithSubjects(state)) {
    promises.push(dispatch(loadLevels()));
  }

  // load classes
  if (!isUserClassesLoaded(state)) {
    promises.push(dispatch(loadClasses({ source: classesSourceFilters.user })));
  }

  if (promises.length > 0) {
    return Promise.all(promises);
  }
};

const mapStateToProps = (state: ReduxState) => {
  const classes = getAllClasses(state).map((c) => c.subject_code);
  const subjects = getAllSubjectsWithLevels(state);

  return {
    classes,
    subjects: map(subjects, (subject) => ({ label: subject.name, value: subject.code, ...subject })),
    isUpdatingUser: isUpdatingUserSelector(state),
    regionCode: getUserRegion(state),
  };
};

type SubjectsType = Array<$PropertyType<ClassType, 'subject_code'>>;

export type SubjectsProps = {
  classes: SubjectsType,
  isUpdatingUser: boolean,
  regionCode: RegionCode,
  subjects: Array<Subject>,
  updateUserAction: BoundAction<typeof updateUser>,
};

function Subjects(props: SubjectsProps) {
  const { classes, isUpdatingUser, regionCode, subjects, updateUserAction } = props;
  const pageTitle = 'Subjects';
  const subjectLimit = getSubjectLimit(regionCode);

  const [selectedKeys, setSelectedKeys] = useState<Set<string>>(new Set(classes));
  const inputRef = useRef(null);
  const toast = useToast();

  // Reset selectedKeys when classes change
  useEffect(() => {
    if (classes.length > 0 && selectedKeys.size === 0) {
      setSelectedKeys(new Set(classes));
    }
  }, [classes]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectionChange = (keys) => {
    if (size(keys) > getSubjectLimit(regionCode)) {
      return toast.warning(
        <>
          You can only add {subjectLimit} subjects to your account.
          <br />
          <Link href={links.support.subjectsLimit} isExternal>
            Read more about the subjects limit in our Help Center
          </Link>
        </>
      );
    }
    setSelectedKeys(keys);
  };

  const handleSubmit = async (event: SyntheticEvent<>) => {
    event.preventDefault();

    if (size(selectedKeys) === 0) {
      toast.error('Select at least one subject.');
      if (inputRef.current) {
        inputRef.current.focus();
      }
      return;
    }
    try {
      await updateUserAction({ subjects: Array.from(selectedKeys) });
      toast.success('Your subjects have been updated successfully.');
    } catch (error) {
      log.error('Form submission: update subjects failed', error);
      toast.error('There was an error updating your subjects. Please try again.');
    }
  };

  return (
    <Container maxWidth="sizeContainerSmall">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <HideVisually>
        <h2>{pageTitle}</h2>
      </HideVisually>
      <Stack as="form" spacing="spacingLarge5X">
        <PickList
          ref={inputRef}
          label="Subjects"
          inputPlaceholder="Search by subject name"
          items={subjects}
          onSelectionChange={handleSelectionChange}
          selectedKeys={selectedKeys}
          renderTag={(item) => (
            <Item textValue={item.name}>
              <StatusLight color={item.color} />
              <Truncate>{item.name}</Truncate>
            </Item>
          )}
          emptyState="None of the subjects match your search."
          helpText={
            <>
              Select up to {subjectLimit} subjects. <FindSubjectHelpLink />
            </>
          }
        >
          {(item) => (
            <Item textValue={item.name}>
              <StatusLight color={item.color} />
              <Flex alignItems="baseline" columnGap="spacingRoot" wrap>
                <ItemLabel>
                  <Text variant="bodySmall" color="colorText">
                    {item.name}
                  </Text>
                </ItemLabel>
                <ItemDescription>
                  <Text variant="bodySmall1X" color="colorTextSubtler">
                    {item.levels.map((level) => level.name).join(', ')}
                  </Text>
                </ItemDescription>
              </Flex>
            </Item>
          )}
        </PickList>
        <Box textAlign="center">
          <Button type="submit" isLoading={isUpdatingUser} onClick={handleSubmit}>
            Update subjects
          </Button>
        </Box>
      </Stack>
    </Container>
  );
}

export default connectData(fetchData)(
  connect(mapStateToProps, {
    updateUserAction: updateUser,
  })(Subjects)
);
