// @flow
import { Box, Popover, Strength } from '@getatomi/neon';

import { strengthThresholds } from 'src/utils/config';
import StrengthInfo from 'src/components/StrengthInfo/StrengthInfo';

import calculateMarkDeltas from './calculateMarkDeltas';

export type LessonMetrics = {|
  +classAverageMark: ?number,
  +regionAverageMark?: ?number,
  +schoolAverageMark?: ?number,
|};

type StudentStrengthProps = {|
  isRevisionNeeded?: boolean,
  mark: number,
  metrics: LessonMetrics,
  strength: number,
|};

function StudentStrength(props: StudentStrengthProps) {
  const { strength, mark, metrics, isRevisionNeeded = false } = props;

  const strengthProps = {
    value: strength,
    limits: strengthThresholds,
    color: isRevisionNeeded ? 'colorBackgroundDangerBold' : undefined,
  };

  const deltas = calculateMarkDeltas(mark, metrics);
  const popoverTrigger = (
    <Box as="button" type="button" display="block">
      <Strength {...strengthProps} testHook="strength" />
    </Box>
  );

  return (
    <Popover ariaLabel="Mark comparison" trigger={popoverTrigger}>
      <StrengthInfo
        markLabel="Latest mark:"
        mark={mark}
        deltas={deltas}
        strengthLabel="Current strength:"
        strengthProps={strengthProps}
      />
    </Popover>
  );
}

export default StudentStrength;
