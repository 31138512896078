// @flow
import {
  IconSubjectsAncientHistory,
  IconSubjectsBiology,
  IconSubjectsBusinessStudies,
  IconSubjectsCafs,
  IconSubjectsChemistry,
  IconSubjectsCognitiveVerbs,
  IconSubjectsComputing,
  IconSubjectsDesignAndTechnology,
  IconSubjectsDrama,
  IconSubjectsEconomics,
  IconSubjectsEnglish,
  IconSubjectsEnglishLiterature,
  IconSubjectsGeneric,
  IconSubjectsGeography,
  IconSubjectsIndustrialTechnology,
  IconSubjectsLegalStudies,
  IconSubjectsMaths,
  IconSubjectsModernHistory,
  IconSubjectsMusic,
  IconSubjectsNew,
  IconSubjectsPdhpe,
  IconSubjectsPhysics,
  IconSubjectsPsychology,
  IconSubjectsSeniorScience,
  IconSubjectsStudiesOfReligion,
  IconSubjectsVisualArts,
} from '@getatomi/neon';

const iconsMapping = {
  'ancient-history': IconSubjectsAncientHistory,
  biology: IconSubjectsBiology,
  'business-studies': IconSubjectsBusinessStudies,
  cafs: IconSubjectsCafs,
  chemistry: IconSubjectsChemistry,
  'cognitive-verbs': IconSubjectsCognitiveVerbs,
  computing: IconSubjectsComputing,
  'design-and-technology': IconSubjectsDesignAndTechnology,
  drama: IconSubjectsDrama,
  economics: IconSubjectsEconomics,
  english: IconSubjectsEnglish,
  'english-literature': IconSubjectsEnglishLiterature,
  generic: IconSubjectsGeneric,
  geography: IconSubjectsGeography,
  'industrial-technology': IconSubjectsIndustrialTechnology,
  'legal-studies': IconSubjectsLegalStudies,
  maths: IconSubjectsMaths,
  'modern-history': IconSubjectsModernHistory,
  music: IconSubjectsMusic,
  new: IconSubjectsNew,
  pdhpe: IconSubjectsPdhpe,
  physics: IconSubjectsPhysics,
  psychology: IconSubjectsPsychology,
  'senior-science': IconSubjectsSeniorScience,
  'studies-of-religion': IconSubjectsStudiesOfReligion,
  'visual-arts': IconSubjectsVisualArts,
};

export type SubjectIconProps = {|
  className?: string,
  color?: string,
  display?: string,
  name: string,
  size?: string | { base?: string, desktop?: string, tablet?: string, wide?: string },
  strokeWidth?: number,
|};

export default function SubjectIcon(props: SubjectIconProps) {
  const { name, ...otherProps } = props;
  const Icon = iconsMapping[name];

  return <Icon {...otherProps} />;
}
