// @flow
import { Alert } from '@getatomi/neon';

import paymentSourcesStatuses from 'src/constants/paymentSourcesStatuses';

type Props = {
  paymentSourceStatus: string,
};

export default function AlertPaymentSourceStateError(props: Props) {
  const { paymentSourceStatus, ...otherProps } = props;

  const isCardExpiring = paymentSourceStatus === paymentSourcesStatuses.expiring;
  const isCardExpired = paymentSourceStatus === paymentSourcesStatuses.expired;

  if (!isCardExpiring && !isCardExpired) return null;

  return (
    <Alert {...otherProps} variant="danger">
      {isCardExpiring && 'Your credit card is about to expire.'}
      {isCardExpired && 'Your credit card has expired.'}
    </Alert>
  );
}
