// @flow
import { useMutation } from '@apollo/client';
import { useToast } from '@getatomi/neon';

import type { UserGroupType } from 'src/types';
import type {
  DeleteClassUsers,
  DeleteClassUsersVariables,
  DeleteClassUsersInput,
} from 'src/graphql/types/generated/DeleteClassUsers';
import { trackEvent } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';

import REMOVE_USERS_FROM_CLASS from './DeleteClassUsers.graphql';
import GET_CLASS_USERS from './GetClassUsers.graphql';

const getSuccessToastMessage = ({ type, count }: { count: number, type: UserGroupType }) => {
  if (type === 'students') {
    return count === 1
      ? `This student has been removed successfully.`
      : `These students have been removed successfully.`;
  }
  return count === 1 ? `This teacher has been removed successfully.` : `These teachers have been removed successfully.`;
};

const getErrorToastMessage = ({ type, count }: { count: number, type: UserGroupType }) => {
  if (type === 'students') {
    return count === 1
      ? `There was an error removing this student. Please try again.`
      : `There was an error removing these students. Please try again.`;
  }
  return count === 1
    ? `There was an error removing this teacher. Please try again.`
    : `There was an error removing these teachers. Please try again.`;
};

export default function useDeleteClassUsers(userType: UserGroupType) {
  const toast = useToast();
  const [deleteClassUsers, { loading }] = useMutation<DeleteClassUsers, DeleteClassUsersVariables>(
    REMOVE_USERS_FROM_CLASS,
    {
      refetchQueries: [GET_CLASS_USERS],
      awaitRefetchQueries: true,
    }
  );

  return {
    deleteClassUsers: async (params: DeleteClassUsersInput) => {
      trackEvent(trackingEvents.classRemoveUsersRequested, {
        classId: params.classId,
        userIds: params.userIds,
        userType,
      });
      try {
        await deleteClassUsers({ variables: { input: params } });
        toast.success(getSuccessToastMessage({ type: userType, count: params.userIds.length }));
      } catch (error) {
        toast.error(getErrorToastMessage({ type: userType, count: params.userIds.length }));
      }
    },
    isDeletingClassUsers: loading,
  };
}
