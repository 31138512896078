// @flow
import { PureComponent } from 'react';
import classnames from 'classnames/bind';
import { IconClose, IconSearch } from '@getatomi/neon';

import styles from './SearchInput.module.scss';

const cx = classnames.bind(styles);

type Props = {
  className?: string,
  initialValue?: string,
  onChange: Function,
  onReset: Function,
  placeholder?: string,
};

type State = {
  value: string,
};

export default class SearchInput extends PureComponent<Props, State> {
  static defaultProps = {
    placeholder: 'Search',
  };

  state = {
    value: this.props.initialValue || '',
  };

  onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;

    this.setState({ value });

    this.props.onChange(value);
  };

  onReset = (e: Event) => {
    e.preventDefault();
    this.setState({ value: '' });
    this.props.onReset();
  };

  // eslint-disable-next-line class-methods-use-this
  onSubmit = (e: Event) => {
    e.preventDefault();
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.initialValue !== this.props.initialValue) {
      this.setState({ value: nextProps.initialValue });
    }
  }

  render() {
    const { placeholder = 'Search', className } = this.props;
    const { value } = this.state;

    return (
      <div className={cx('root', className)}>
        <IconSearch className={styles.icon} strokeWidth="2" size="sizeIconSmall" />
        <form onSubmit={this.onSubmit} className={styles.form}>
          <div className={styles.inputWrapper}>
            <input
              placeholder={placeholder}
              value={value}
              onChange={this.onChange}
              className={styles.input}
              aria-label={placeholder}
            />
          </div>
          {value && (
            <button type="reset" aria-label="reset" onClick={this.onReset} className={styles.clearButton}>
              <IconClose strokeWidth="2" size="sizeIconSmall1X" />
            </button>
          )}
        </form>
      </div>
    );
  }
}
