// @flow
import { useQuery } from '@apollo/client';
import invariant from 'invariant';
import { useToast } from '@getatomi/neon';

import type { NewAttachedContent } from 'src/domains/Tasks/types';
import type { GetTaskDialog, GetTaskDialogVariables } from 'src/graphql/types/generated/GetTaskDialog';
import type { StudentProp } from 'src/components/StudentPicker/StudentPicker';

import GET_TASK_DIALOG from './GetTaskDialog.graphql';
import TaskDialog, { TaskDialogLoader, type EditableTask } from './TaskDialog';

type Props = {
  classId: string,
  initialContents?: Array<NewAttachedContent>,
  initialStudents?: Array<string>,
  isOpen: boolean,
  onClose: () => mixed,
  onSubmit?: () => mixed,
  subscriptionId: string,
  task?: ?EditableTask,
};

type TransformedClassData = {
  className: string,
  isFreePlan: boolean,
  region: string,
  students: Array<StudentProp>,
  subject: {
    code: string,
    color: string,
    groupCode: string,
    shortName: string,
  },
};

export function transformClassData(data: GetTaskDialog): TransformedClassData {
  const accountData = data?.me?.account;
  const classData = accountData?.class;
  invariant(accountData && classData, 'Class data should be defined');

  const { name: className, students, subject } = classData;
  const { code, color, groupCode, shortName } = subject;

  return {
    className,
    isFreePlan: accountData.plan.isFree,
    region: accountData.region.code,
    students: students.map((student) => ({
      accountStatus: student.accountStatus,
      avatar: student.avatar,
      color: student.color,
      email: student.email,
      firstName: student.firstName,
      id: student.id,
      lastName: student.lastName,
    })),
    subject: {
      code,
      color,
      groupCode,
      shortName,
    },
  };
}

export default function TaskDialogContainer(props: Props) {
  const { classId, initialStudents, isOpen, onClose, onSubmit, initialContents, subscriptionId, task } = props;

  const { data, error, loading } = useQuery<GetTaskDialog, GetTaskDialogVariables>(GET_TASK_DIALOG, {
    variables: {
      accountId: String(subscriptionId),
      classId: String(classId),
    },
  });

  const toast = useToast();

  if (error) {
    toast.error('There was an error opening the task editor. Please try again.');
    return null;
  }

  if (loading && !data) {
    return <TaskDialogLoader isOpen={isOpen} onClose={onClose} isExistingTask={Boolean(task)} />;
  }

  invariant(data, 'Class data should be defined');
  const classData = transformClassData(data);

  return (
    <TaskDialog
      classId={classId}
      currentClassName={classData.className}
      initialContents={initialContents}
      initialStudents={initialStudents}
      isFreePlan={classData.isFreePlan}
      isOpen={isOpen}
      region={classData.region}
      students={classData.students}
      subject={classData.subject}
      subscriptionId={subscriptionId}
      task={task}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
}
