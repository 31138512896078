// @flow
import type { Dispatch, GetState } from 'src/types';
import Logger from 'src/utils/Logger';
import { SUBSCRIPTIONS_ENDPOINT } from 'src/api/endpoints';
import { paymentSourcesSchema, paymentSourcesListSchema } from 'src/api/schema';
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import { trackingEvents } from 'src/constants/tracking';

import { loadSubscription } from './subscriptions';

const log = new Logger('actions/paymentSources');

export const LOAD_PAYMENT_SOURCES = 'LOAD_PAYMENT_SOURCES';
export const LOAD_PAYMENT_SOURCES_SUCCESS = 'LOAD_PAYMENT_SOURCES_SUCCESS';
export const LOAD_PAYMENT_SOURCES_FAIL = 'LOAD_PAYMENT_SOURCES_FAIL';
export const CREATE_SETUP_INTENT = 'CREATE_SETUP_INTENT';
export const CREATE_SETUP_INTENT_SUCCESS = 'CREATE_SETUP_INTENT_SUCCESS';
export const CREATE_SETUP_INTENT_FAIL = 'CREATE_SETUP_INTENT_FAIL';
export const ADD_PAYMENT_SOURCE = 'ADD_PAYMENT_SOURCE';
export const ADD_PAYMENT_SOURCE_SUCCESS = 'ADD_PAYMENT_SOURCE_SUCCESS';
export const ADD_PAYMENT_SOURCE_FAIL = 'ADD_PAYMENT_SOURCE_FAIL';
export const DELETE_PAYMENT_SOURCE = 'DELETE_PAYMENT_SOURCE';
export const DELETE_PAYMENT_SOURCE_SUCCESS = 'DELETE_PAYMENT_SOURCE_SUCCESS';
export const DELETE_PAYMENT_SOURCE_FAIL = 'DELETE_PAYMENT_SOURCE_FAIL';
export const SET_ADD_PAYMENT_SOURCE_STATUS = 'SET_ADD_PAYMENT_SOURCE_STATUS';

export function loadPaymentSourcesBySubscription(subscriptionId: number) {
  return {
    request: {
      types: [LOAD_PAYMENT_SOURCES, LOAD_PAYMENT_SOURCES_SUCCESS, LOAD_PAYMENT_SOURCES_FAIL],
      schema: paymentSourcesListSchema,
      endpoint: {
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/payment-sources`,
      },
    },
  };
}

export function loadPaymentSources() {
  return (dispatch: Dispatch, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());
    return dispatch(loadPaymentSourcesBySubscription(subscriptionId));
  };
}

export function setAddPaymentSourceStatus(isAddingPaymentSource: boolean) {
  return {
    type: SET_ADD_PAYMENT_SOURCE_STATUS,
    isAddingPaymentSource,
  };
}

export function createSetupIntent(paymentMethodId: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());

    return dispatch({
      request: {
        types: [CREATE_SETUP_INTENT, CREATE_SETUP_INTENT_SUCCESS, CREATE_SETUP_INTENT_FAIL],
        throwErrors: true,
        endpoint: {
          method: 'post',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/setup-intent`,
          body: {
            payment_method_id: paymentMethodId,
          },
        },
      },
    });
  };
}

export function addPaymentSource(setupIntentId: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());

    await dispatch({
      request: {
        types: [ADD_PAYMENT_SOURCE, ADD_PAYMENT_SOURCE_SUCCESS, ADD_PAYMENT_SOURCE_FAIL],
        schema: paymentSourcesSchema,
        endpoint: {
          method: 'post',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/payment-sources`,
          body: {
            setup_intent_id: setupIntentId,
          },
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.paymentSourceAddRequested,
      },
    });

    return dispatch(loadSubscription());
  };
}

export function deletePaymentSource(paymentSourceId: number) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());

    try {
      await dispatch({
        data: {
          id: paymentSourceId,
        },
        request: {
          types: [DELETE_PAYMENT_SOURCE, DELETE_PAYMENT_SOURCE_SUCCESS, DELETE_PAYMENT_SOURCE_FAIL],
          endpoint: {
            method: 'delete',
            url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/payment-sources/${paymentSourceId}`,
          },
          throwErrors: true,
        },
        tracking: {
          event: trackingEvents.paymentSourceDeleteRequested,
          data: {
            paymentSourceId,
          },
        },
      });

      return dispatch(loadSubscription());
    } catch (error) {
      if (error && error.statusCode === 404) {
        log.info(`Payment source doesn't exist: reload all payment sources`, error);
        await dispatch(loadPaymentSources());
      }
      throw error;
    }
  };
}
