// @flow
import { Flex, Text } from '@getatomi/neon';

import type { PaymentSource } from 'src/types';

import PaymentTypeIcon from './PaymentTypeIcon/PaymentTypeIcon';

export type PaymentSourceDetailsProps = {
  data: ?PaymentSource,
};

export default function PaymentSourceDetails(props: PaymentSourceDetailsProps) {
  const { data } = props;

  if (!data) return null;

  return (
    <Flex
      alignItems="flex-start"
      justifyContent="space-between"
      columnGap="spacingSmall1X"
      testHook="payment-source-details"
    >
      <div>
        <Text variant="bodySmall" fontWeight="fontWeightSemiBold">
          Number ending in {data.card_last_four}
        </Text>
        <Text
          as="time"
          variant="bodySmall2X"
          dateTime={`${data.card_expiry_year}-${data.card_expiry_month}`}
          testHook="hide-in-percy"
        >
          {data.card_expiry_month}/{data.card_expiry_year.toString().substring(2)}
        </Text>
      </div>
      <PaymentTypeIcon name={data.card_brand} />
    </Flex>
  );
}
