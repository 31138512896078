// @flow
import { Box, Text } from '@getatomi/neon';

import type { CurrencyType } from 'src/types';
import currencies from 'src/constants/currencies';

type Props = {
  currency: CurrencyType,
  isRounded?: boolean,
  removeZeroDecimals?: boolean,
  value: number,
  withCurrencyCode?: boolean,
};

export default function Price(props: Props) {
  const { currency, isRounded, removeZeroDecimals = false, withCurrencyCode = false, value, ...otherProps } = props;
  let price = value / 100;

  // Round price if isRounded is true
  if (isRounded) {
    price = Math.round(price);
  }

  let formattedValue = price.toLocaleString(currencies[currency].locale, { style: 'currency', currency });

  // Remove .00 if number doesn't have decimals
  if (price % 1 === 0 && removeZeroDecimals) {
    formattedValue = formattedValue.slice(0, -3);
  }

  return (
    <Box as="span" whiteSpace="nowrap">
      <Box as="span" {...otherProps}>
        {formattedValue}
      </Box>
      {withCurrencyCode && (
        <Text as="span" fontSize="fontSizeSmall2X">
          {' '}
          {currency}
        </Text>
      )}
    </Box>
  );
}
