// @flow
import * as React from 'react';

import FormMessage from 'src/components/FormMessage/FormMessage';

type Props = {
  errors: $ReadOnlyArray<string>,
};

export default function ValidationErrors(props: Props) {
  const { errors } = props;

  if (errors.length === 0) return null;

  return (
    <FormMessage>
      {errors.map((error, key) => (
        <React.Fragment key={key}>
          {key > 0 && <br />}
          {error}
        </React.Fragment>
      ))}
    </FormMessage>
  );
}
