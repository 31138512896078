// @flow

import Cookies, { type CookieSetOptions } from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import HttpClient from 'src/utils/httpClient';
import type { UserType } from 'src/types';
import Logger from 'src/utils/Logger';
import { getCookieOptions } from 'src/utils/cookies';
import * as trackingUtils from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';
import { PROXIED_USER_ENDPOINT } from 'src/api/endpoints';
import {
  ACCESS_TOKEN_COOKIE,
  CLASSES_SOURCE_COOKIE,
  REFRESH_TOKEN_COOKIE,
  SAML_USER_COOKIE_MAX_AGE,
  SAML_USER_COOKIE,
  RE_AUTH_USER_EMAIL_COOKIE_MAX_AGE,
  RE_AUTH_USER_EMAIL_COOKIE,
  USER_DETAILS_COOKIE,
} from 'src/constants/cookies';

const log = new Logger('utils/Auth');

let pendingAccessTokenRefresh;

export default class Authentication {
  cookies: Cookies;

  options: CookieSetOptions;

  constructor(cookies?: Cookies) {
    this.cookies = cookies || new Cookies();
    this.options = getCookieOptions();
  }

  saveAccessToken(token: string) {
    this.cookies.set(ACCESS_TOKEN_COOKIE, token, this.options);
  }

  /**
   * @deprecated in favour of `getFreshAccessToken`
   */
  getAccessToken() {
    // In Cypress cookies are not set using Auth (Cookies) so this.cookies is not up-to-date when
    // checking if the access token is not expired
    if (__CLIENT__ && navigator.userAgent.includes('Cypress')) {
      this.cookies = new Cookies();
    }
    return this.cookies.get(ACCESS_TOKEN_COOKIE);
  }

  async getFreshAccessToken(): Promise<?string> {
    const accessToken = this.getAccessToken();

    if (accessToken && this.isTokenExpired(accessToken)) {
      return this.refreshAccessToken();
    }

    return accessToken;
  }

  async refreshAccessToken(): Promise<?string> {
    if (__CLIENT__ && pendingAccessTokenRefresh) {
      const response = await pendingAccessTokenRefresh;
      const { access_token: accessToken } = response.meta.auth;

      return accessToken;
    }

    const response = await this.requestRefreshToken();
    const { access_token: accessToken } = response.meta.auth;

    this.saveAccessToken(accessToken);
    return accessToken;
  }

  hasRefreshTokenCookie() {
    return this.cookies.get(REFRESH_TOKEN_COOKIE) !== undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  async requestRefreshToken(headers?: { [string]: string }) {
    // credentials cookies from a client request are autoamtically sent with this request
    // server requests need to provide the cookies inside the headers parameter
    const payload = { grant_type: 'refresh_token' };
    const newAccessTokenRefresh = HttpClient.post(`${PROXIED_USER_ENDPOINT}/refresh`, payload, headers);

    pendingAccessTokenRefresh = newAccessTokenRefresh;
    const response = await newAccessTokenRefresh;

    pendingAccessTokenRefresh = null;
    return response;
  }

  /**
   * This method is called from the main server middleware to ensure we have a non-expired accessToken before to perform the SSR
   * We only attempt to refresh the accessToken if we have a refreshToken cookie, and the existing accessToken is expired
   * If there is no access token, we do not attempt to get a new one because it means the user is explicitly logged out
   * This method is non-blocking and does not throw errors
   * It returns the new accessToken and refreshToken so we can send the refreshToken back to the client in the response
   */
  async verifyServerAccessToken() {
    const accessToken = this.getAccessToken();
    if (!accessToken || !this.isTokenExpired(accessToken)) {
      return;
    }

    if (!this.hasRefreshTokenCookie()) {
      return;
    }

    const headers = {
      cookie: `refresh_token=${this.cookies.get(REFRESH_TOKEN_COOKIE)}`,
    };

    const response = await this.requestRefreshToken(headers).catch(() => {
      log.info('SSR was not able to refresh access token, non-blocking');
    });

    if (!response) {
      return;
    }

    const { access_token: newAccessToken, refresh_token: newRefreshToken } = response.meta.auth;

    this.saveAccessToken(newAccessToken);
    return { refreshToken: newRefreshToken, accessToken: newAccessToken };
  }

  getAuthorizedSubscriptions() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return [];
    }

    const decoded = jwtDecode(accessToken);

    return decoded.subscriptions ?? [];
  }

  getIsAdmin() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);

    return Boolean(decoded.is_admin);
  }

  removeAccessToken() {
    this.cookies.remove(ACCESS_TOKEN_COOKIE, this.options);
  }

  removeRefreshToken() {
    this.cookies.remove(REFRESH_TOKEN_COOKIE, this.options);
  }

  isTokenExpired(token?: string) {
    const decoded = jwtDecode(token || this.getAccessToken());
    const tokenExpiry = moment.unix(decoded.exp).utc();
    const diffInSeconds = tokenExpiry.diff(Date.now(), 'seconds');
    const isExpired = diffInSeconds < 60;

    log.info(
      `Token ${isExpired ? 'expires' : 'will expire'} at ${tokenExpiry.utc().format()} in ${diffInSeconds} seconds`
    );

    return isExpired;
  }

  saveUserDetails(user: UserType) {
    this.cookies.set(
      USER_DETAILS_COOKIE,
      `${user.email};${user.first_name};${user.last_name};${user.color}`,
      this.options
    );
  }

  getUserDetails() {
    const user = this.cookies.get(USER_DETAILS_COOKIE);
    if (!user) return null;

    const [email, firstName, lastName, color] = user.split(';');
    return {
      email,
      first_name: firstName,
      last_name: lastName,
      color,
    };
  }

  removeUserDetails() {
    this.cookies.remove(USER_DETAILS_COOKIE, this.options);
  }

  logout(redirect?: boolean, redirectUrl?: string) {
    this.removeAccessToken();
    this.removeRefreshToken();
    this.cookies.remove(CLASSES_SOURCE_COOKIE, this.options);

    if (__CLIENT__) {
      trackingUtils.trackEvent(trackingEvents.userLoggedOut);
      // reset Segment user (clear cookies/localStorage)
      // https://segment.com/docs/sources/website/analytics.js/#reset-logout
      trackingUtils.reset();
      if (redirect) {
        window.location.replace(redirectUrl || '/');
      }
    }
  }

  logoutAndRedirect(redirectUrl: string = '/') {
    this.logout(true, redirectUrl);
  }

  clearUserData() {
    this.removeAccessToken();
    this.removeRefreshToken();
    this.removeUserDetails();
  }

  setSAMLLogin(args: { email: string, redirectUrl: string, subscriptionId: number }) {
    this.cookies.set(SAML_USER_COOKIE, JSON.stringify(args), {
      ...this.options,
      maxAge: SAML_USER_COOKIE_MAX_AGE,
    });
  }

  setReAuthUser(email: string) {
    this.cookies.set(RE_AUTH_USER_EMAIL_COOKIE, email, {
      ...this.options,
      maxAge: RE_AUTH_USER_EMAIL_COOKIE_MAX_AGE,
    });
  }

  getReAuthUser() {
    return this.cookies.get(RE_AUTH_USER_EMAIL_COOKIE);
  }

  removeReAuthUserEmail() {
    this.cookies.remove(RE_AUTH_USER_EMAIL_COOKIE);
  }
}
