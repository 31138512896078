// @flow
import { Box, Card, Flex, HideVisually, MarkdownPreview, ResultSummary, Text, useMediaQuery } from '@getatomi/neon';
import _ from 'lodash';

import { formatPercentageAsString } from 'src/utils/formatPercentage';
import { UnstyledLink } from 'src/components/Link/Link';
import questionTypes from 'src/constants/questionTypes';
import type { ChallengingQuestion as ChallengingQuestionType } from 'src/domains/ViewingQuizResults/types';

import CircleCount from './CircleCount/CircleCount';

type ChallengingQuestionProps = {|
  question: ChallengingQuestionType,
|};

export default function ChallengingQuestion(props: ChallengingQuestionProps) {
  const { question } = props;
  const { id, sequencePosition, title, type } = question;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  let summaryChildren = null;
  let typeLabel = _.startCase(type);

  switch (type) {
    case questionTypes.DRAG_AND_DROP:
    case questionTypes.EXACT_ANSWER:
    case questionTypes.MULTIPLE_CHOICE:
      summaryChildren = (
        <>
          <ResultSummary type="aggregate" status="error" result={question.totals.incorrect} />
          <ResultSummary type="aggregate" status="success" result={question.totals.correct} />
        </>
      );
      break;
    case questionTypes.SELF_MARKED:
    case questionTypes.SELF_MARKED_LITE:
      typeLabel = 'SELF MARKED';
      summaryChildren = (
        <ResultSummary
          type="aggregate"
          status={question.averages.gradeStatus}
          result={`${formatPercentageAsString(parseFloat(question.averages.grade))} Avg`}
        />
      );
      break;
    default:
      summaryChildren = null;
      break;
  }

  const summary = (
    <Flex gap="spacingSmall" marginLeft="auto" paddingLeft="spacingLarge" testHook="summary">
      {summaryChildren}
    </Flex>
  );

  const sequencePositionMarker = (
    <CircleCount
      backgroundColor="colorBackgroundNeutral"
      color="colorTextSubtler"
      size={{ base: 'sizeAvatarSmall1X', tablet: 'sizeAvatarRoot' }}
      testHook="sequence"
    >
      <HideVisually>Question number </HideVisually>
      {sequencePosition}
    </CircleCount>
  );

  const details = (
    <Box>
      <Text variant="bodySmall1X" textTransform="uppercase" color="colorTextSubtle">
        {typeLabel}
      </Text>
      <Text lineHeight="lineHeightSmall">
        <MarkdownPreview>{title}</MarkdownPreview>
      </Text>
    </Box>
  );

  return (
    <Card testHook={id}>
      <UnstyledLink href={question.url}>
        <Flex
          direction={{ base: 'column', tablet: 'row' }}
          alignItems={{ base: undefined, tablet: 'center' }}
          gap={{ base: 'spacingSmall', tablet: 'spacingLarge' }}
          padding={{ base: 'spacingRoot', tablet: 'spacingLarge1X' }}
        >
          {isMobile ? (
            <>
              <Flex alignItems="center" justifyContent="space-between" width="sizeFull">
                {sequencePositionMarker}
                {summary}
              </Flex>
              {details}
            </>
          ) : (
            <>
              {sequencePositionMarker}
              {details}
              {summary}
            </>
          )}
        </Flex>
      </UnstyledLink>
    </Card>
  );
}
