// @flow
import { useState } from 'react';
import { Stack } from '@getatomi/neon';

import type { PaymentSource as PaymentSourceType } from 'src/types';
import PaymentSource from 'src/components/PaymentSource/PaymentSource';
import AddPaymentSourceDialog from 'src/components/AddPaymentSourceDialog/AddPaymentSourceDialog';
import AlertPaymentSourceStateError from 'src/components/AlertPaymentSourceStateError/AlertPaymentSourceStateError';
import paymentSourcesStatuses from 'src/constants/paymentSourcesStatuses';

import AlertDialogRemovePaymentSource from '../AlertDialogRemovePaymentSource/AlertDialogRemovePaymentSource';

type Props = {
  source: PaymentSourceType,
};

export default function PaymentSourceWithActions(props: Props) {
  const { source } = props;
  const [isDeletePromptVisible, setIsDeletePromptVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  const onToggleDeletePrompt = () => {
    setIsDeletePromptVisible((isVisible) => !isVisible);
  };

  const onToggleUpdateModal = () => {
    setIsUpdateModalVisible((isVisible) => !isVisible);
  };

  return (
    <>
      <Stack spacing="spacingLarge2X">
        {(source.status === paymentSourcesStatuses.expired || source.status === paymentSourcesStatuses.expiring) && (
          <AlertPaymentSourceStateError
            paymentSourceStatus={source.status}
            buttonProps={{ children: 'Update card details', onClick: onToggleUpdateModal }}
          />
        )}
        <PaymentSource data={source} isLarge onUpdate={onToggleUpdateModal} onDelete={onToggleDeletePrompt} />
      </Stack>
      {/* $FlowIgnore react-redux types issue */}
      <AlertDialogRemovePaymentSource
        paymentSourceId={source.id}
        isOpen={isDeletePromptVisible}
        onDismiss={onToggleDeletePrompt}
      />
      <AddPaymentSourceDialog actionLabel="update" isVisible={isUpdateModalVisible} onClose={onToggleUpdateModal} />
    </>
  );
}
