//@flow
import { Heading, Stack, Text } from '@getatomi/neon';

export default function MarketingPanel() {
  return (
    <Stack>
      <img
        src="https://uploads.getatomi.com/General/atomi-teachers-students.png"
        srcSet="https://uploads.getatomi.com/General/atomi-teachers-students%402x.png 2x"
        width="310"
        height="254"
        alt=""
      />
      <Heading as="h2" variant="large">
        You’re in good company
      </Heading>
      <Text as="p" fontSize="fontSizeLarge" fontWeight="fontWeightLight" color="colorTextSubtle">
        Atomi helps deliver millions of lessons each year to more than 100,000 students.
      </Text>
    </Stack>
  );
}
