// @flow
import {
  Alert,
  Box,
  ButtonLoader,
  Container,
  Heading,
  Illustration,
  Skeleton,
  Stack,
  Text,
  TextLoader,
} from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import { formatPostDuration, durationFormats, formatDuration } from 'src/utils/duration';
import type { ChallengeTier } from 'src/types';

import { makeTierProperties } from './makeTierProperties';

export type ChallengeStartProps = {|
  badges: React.Node,
  challengeFirstItemUrl: string,
  duration: number,
  questionsCount: number,
  secondaryActions: React.Node,
  tier: ChallengeTier,
  timeLimitInSeconds?: number,
  title: string,
  totalMarks: number,
|};

export function ChallengeStart({
  secondaryActions,
  challengeFirstItemUrl,
  duration,
  badges,
  questionsCount,
  tier,
  timeLimitInSeconds,
  title,
  totalMarks,
}: ChallengeStartProps) {
  const tierProperties = makeTierProperties({ tier, challengeTitle: title });
  const estimatedDuration = formatPostDuration(duration, durationFormats.minutes);

  return (
    <Box textAlign="center">
      <Box marginBottom="spacingLarge3X">
        <Illustration name={tierProperties.illustrationName} />
      </Box>

      <Box marginBottom="spacingLarge1X">
        <Heading as="h1" variant="large">
          {title}
        </Heading>
        {badges && <Box marginTop="spacingSmall2X">{badges}</Box>}
        {tierProperties.subHeading && (
          <Text as="p" variant="lead" marginTop="spacingSmall1X">
            {tierProperties.subHeading}
          </Text>
        )}
      </Box>

      <Box marginBottom="spacingLarge3X">
        <Stack spacing="spacingSmall">
          <Text variant="bodySmall1X" color="colorTextSubtle">
            <>
              {`${questionsCount} questions`}
              {' ‧ '}
              {`${totalMarks} marks`}
              {!timeLimitInSeconds && (
                <>
                  {' ‧ '}
                  {`${estimatedDuration} ${tierProperties.name}`}
                </>
              )}
            </>
          </Text>
          {timeLimitInSeconds && (
            <Container maxWidth="sizeContainerSmall2X">
              <Alert variant="info">
                This revision has a {formatDuration(timeLimitInSeconds, { template: 'm' })} minute time limit.
              </Alert>
            </Container>
          )}
        </Stack>
      </Box>

      {secondaryActions && <Box marginBottom="spacingLarge4X">{secondaryActions}</Box>}

      <Button as="a" href={challengeFirstItemUrl} size="large">
        Start {tierProperties.name}
      </Button>
    </Box>
  );
}

export function ChallengeStartLoader() {
  return (
    <Box textAlign="center">
      <Illustration.Loader marginInline="auto" marginBottom="spacingLarge3X" />
      <TextLoader
        width={{ base: '80%', tablet: '50%', desktop: '40%' }}
        height={30}
        marginInline="auto"
        marginBottom="spacingSmall"
      />
      <Box display="flex" justifyContent="center" gap="spacingSmall1X" marginBottom="spacingLarge2X">
        {Array.from({ length: 2 }, (_val, index) => (
          <Skeleton key={index} animation="wave" width={80} height={20} />
        ))}
      </Box>
      <TextLoader width={250} marginInline="auto" marginBottom="spacingLarge4X" />
      <ButtonLoader size="large" marginInline="auto" />
    </Box>
  );
}
