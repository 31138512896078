// @flow
import keyMirror from 'keymirror';

export const errorCodes = keyMirror({
  BAD_REQUEST: null,
  FORBIDDEN: null,
  SYSTEM_ERROR: null,
  CONFLICTING_USER: null,
});

export const errorMessages = {
  [errorCodes.BAD_REQUEST]:
    'There was an error connecting to your school’s login page. Please ensure you have cookies enabled in your browser and try again. If this message persists, please contact support.',
  [errorCodes.FORBIDDEN]:
    'We couldn’t verify the authenticity of the response from your school’s login page. Please wait a moment and try again. If this issue persists, please contact support.',
  [errorCodes.SYSTEM_ERROR]:
    'There was an issue signing you in using your school’s login page. Please wait a moment and try again. If this message persists, please contact support.',
  [errorCodes.CONFLICTING_USER]:
    'Your login to Atomi failed as another user is still logged in to your school on this device. Try ending their session or use a different browser or device.',
};
