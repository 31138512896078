// @flow
import { Badge } from '@getatomi/neon';

import getSubjectColors from 'src/utils/getSubjectColors';

type SubjectBadgeProps = {|
  children: React.Node,
  color: string,
  groupCode: string,
  variant?: 'filled',
|};

export default function SubjectBadge(props: SubjectBadgeProps) {
  const { children, color, groupCode, variant } = props;
  const colors = getSubjectColors(groupCode, color);
  const isFilled = variant === 'filled';

  return (
    <Badge
      color={isFilled ? undefined : colors?.text}
      fillColor={isFilled ? colors.background : undefined}
      variant={variant}
    >
      {children}
    </Badge>
  );
}
