// @flow
import { browserHistory } from 'react-router';
import { inspect } from 'util';
import type { ErrorLink } from '@apollo/client/link/error';

import Logger from 'src/utils/Logger';
import { getErrorUrl } from 'src/utils/routes';
import Auth from 'src/utils/Auth';

const log = new Logger('src/graphql/client');

export const makeErrorLink =
  (auth: Auth): ErrorLink.ErrorHandler =>
  ({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        const { extensions, message, locations, path } = error;
        const errorCode = extensions?.code;

        // Apollo AuthenticationError has been thrown by the server
        if (errorCode === 'UNAUTHENTICATED') {
          log.error('[GraphQL error]: UNAUTHENTICATED', error);
          auth.logoutAndRedirect(window.location.pathname);
          break;
        }

        // User is not subscribed to the account: redirect to error page
        if (errorCode === 'FORBIDDEN') {
          // If the user has an inviteToken to the subscription, include it in the redirect
          const { inviteToken } = extensions;
          browserHistory.push(getErrorUrl('403', inviteToken));
          break;
        }

        if (errorCode === 'NOT_FOUND') {
          browserHistory.push(getErrorUrl('404'));
          break;
        }

        log.error(
          `${operation.operationName}: ${message}, Path: ${inspect(path)}, Location: ${inspect(locations)}`,
          error
        );
      }
    }
    if (networkError) log.info(`[Network error]:`, networkError);
  };
