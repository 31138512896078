// @flow
import _ from 'lodash';

import type { ApiError, ReduxState } from 'src/types';
import { LOAD_INVITED_USER_SUCCESS } from 'src/actions/auth';
import {
  UPDATE_REGISTRATION,
  REGISTRATION_START,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAIL,
  SAVE_SUBJECTS,
  SAVE_SUBJECTS_SUCCESS,
  SAVE_SUBJECTS_FAIL,
  SAVE_INVITED_USER,
  SAVE_INVITED_USER_FAIL,
} from 'src/actions/registration';
import { parseValidationErrors } from 'src/api/validation';

export type RegistrationState = {
  accountName: string,
  childEmail: string,
  childFirstName: string,
  childLastName: string,
  childLevelId: ?number | ?string,
  email: string,
  firstName: string,
  intent?: Array<string>,
  invitedRegistrationError: ?ApiError,
  isRegistering: boolean,
  isSavingSubjects: boolean,
  lastName: string,
  levelId: ?number | ?string,
  password: string,
  phoneNumber: string,
  position: string,
  referral: ?string,
  schoolId: number | string,
  validationErrors: ?Array<string>,
};

const initialState = {
  accountName: '',
  childEmail: '',
  childFirstName: '',
  childLastName: '',
  childLevelId: null,
  email: '',
  firstName: '',
  invitedRegistrationError: null,
  isRegistering: false,
  isSavingSubjects: false,
  lastName: '',
  levelId: null,
  password: '',
  phoneNumber: '',
  position: '',
  referral: null,
  schoolId: '',
  validationErrors: null,
};

export default function registrationReducer(
  state: RegistrationState = initialState,
  action: Object = {}
): RegistrationState {
  const { type, data } = action;

  switch (type) {
    case UPDATE_REGISTRATION:
      return {
        ...state,
        ...data,
      };
    case REGISTRATION_START:
      return {
        ...state,
        isRegistering: true,
      };
    case REGISTRATION_SUCCESS:
    case REGISTRATION_FAIL:
      return {
        ...state,
        isRegistering: false,
      };

    case SAVE_SUBJECTS:
      return {
        ...state,
        isSavingSubjects: true,
      };
    case SAVE_SUBJECTS_SUCCESS:
      return {
        ...state,
        isSavingSubjects: false,
      };
    case SAVE_SUBJECTS_FAIL:
      return {
        ...state,
        isSavingSubjects: false,
      };

    case LOAD_INVITED_USER_SUCCESS: {
      const { result } = action;
      const { user } = result.result;

      return {
        ...state,
        firstName: user.first_name ?? '',
        lastName: user.last_name ?? '',
        email: user.email,
      };
    }

    case SAVE_INVITED_USER:
      return {
        ...state,
        isRegistering: true,
        invitedRegistrationError: null,
        validationErrors: null,
      };
    case SAVE_INVITED_USER_FAIL:
      return {
        ...state,
        isRegistering: false,
        invitedRegistrationError: _.omit(_.get(action.routeError, 'exception.output'), 'request'),
        validationErrors: parseValidationErrors(action.routeError),
      };

    default:
      return state;
  }
}

// Selectors
export const isRegistering = (state: ReduxState) => state.registration.isRegistering;
