// @flow
import { useState } from 'react';
import { Box, Modal } from '@getatomi/neon';

import type { ChallengeTier, PostType } from 'src/types';
import { trackingEvents, trackingSearchLocations } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';

import FindContentDialog from './FindContentDialog';

export type FoundContent = {|
  duration: number,
  id: string,
  moduleId: string,
  name: string,
  subjectCode: string,
  tier?: ChallengeTier,
  type: PostType,
|};

type Props = {|
  classId: string,
  context: 'subject' | 'task',
  heading: string,
  onAssignContent?: ($ReadOnlyArray<FoundContent>) => void, // TODO: Remove optional
  subscriptionId: string,
|};

type Output = {|
  findContentDialog: React.Node,
  openFindContentDialog: () => void,
|};

export default function useFindContentDialog(props: Props): Output {
  const { context, classId, heading, onAssignContent, subscriptionId } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const searchLocation = context === 'subject' ? trackingSearchLocations.subject : trackingSearchLocations.task;

  const openFindContentDialog = () => {
    setIsOpen(true);
    trackEvent(trackingEvents.searchOpened, {
      classId,
      searchLocation,
    });
  };

  const closeFindContentDialog = () => {
    setIsOpen(false);
    trackEvent(trackingEvents.searchClosed, {
      classId,
      searchLocation,
    });
  };

  const handleAssignContent = (content: $ReadOnlyArray<FoundContent>) => {
    onAssignContent && onAssignContent(content);
    closeFindContentDialog();
  };

  const findContentDialog = (
    <Modal heading={heading} size="medium" onClose={closeFindContentDialog} isOpen={isOpen} noBodyPadding>
      {isOpen && (
        <Box height={{ base: '100%', tablet: '65vh' }}>
          <FindContentDialog
            classId={classId}
            subscriptionId={subscriptionId}
            context={context}
            onAssignContent={handleAssignContent}
          />
        </Box>
      )}
    </Modal>
  );

  return {
    openFindContentDialog,
    findContentDialog,
  };
}
