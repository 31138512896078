// @flow

type Props = {
  children: React.Node,
  onSubmit: Function,
};

type State = {
  withNoValidateAttribute: boolean,
};

export default class Form extends React.Component<Props, State> {
  state = { withNoValidateAttribute: false };

  componentDidMount() {
    this.setState({ withNoValidateAttribute: true });
  }

  onFormSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.props.onSubmit(e);
  };

  render() {
    const { children, onSubmit, ...otherProps } = this.props;
    const { withNoValidateAttribute } = this.state;

    if (!children && !onSubmit) return null;

    return (
      <form method="post" {...otherProps} noValidate={withNoValidateAttribute} onSubmit={this.onFormSubmit}>
        {children}
      </form>
    );
  }
}
