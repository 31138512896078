// @flow
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Flex, IconSocialGoogle, Spinner, useHover } from '@getatomi/neon';

import type { BoundAction, ReduxState, SocialLoginProvider } from 'src/types';
import { login } from 'src/actions/socialLogins';
import { isLoggingInWith } from 'src/reducers/socialLogins';
import Logger from 'src/utils/Logger';
import { getSocialLoginCallbackUrl } from 'src/utils/routes';

import Popup from './Popup/Popup';
import FeatureFlag from './FeatureFlag/FeatureFlag';

type DataEvent = {
  data: {
    code: string,
    provider: string,
    source: string,
  },
  origin: string,
};

type Props = {
  provider: SocialLoginProvider,
};

type InjectedProps = Props & {
  isLoggingIn: boolean,
  loginAction: BoundAction<typeof login>,
};

const log = new Logger('domains/Login/SocialLoginButton');
const postMessageSrc = 'login-with';

const defaultStyles = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'uppercase',
  backgroundColor: 'colorBackground',
  borderRadius: 'borderRadiusLegacyButton',
  color: 'colorText',
  fontFamily: 'fontFamilySystem',
  fontSize: 'fontSizeSmall1X',
  fontWeight: 'fontWeightMedium',
  letterSpacing: 'letterSpacingLarge1X',
  lineHeight: 'lineHeightSmall3X',
  padding: 'spacingSmall',
  width: 'sizeFull',
  transition: 'transitionRoot',
};
const hoveredStyles = {
  backgroundColor: 'colorBackgroundBrandSubtler',
};

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
  isLoggingIn: isLoggingInWith(state, ownProps.provider),
});

function SocialLoginButton(props: InjectedProps) {
  const { isLoggingIn, loginAction, provider } = props;

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const { hoverProps, isHovered } = useHover({ isDisabled: isLoggingIn });

  useEffect(() => {
    const onReceiveAuthCode = async (event: DataEvent) => {
      // ignore messages other than our popup one
      if (event.origin !== window.location.origin || event.data.source !== postMessageSrc) {
        return;
      }

      if (event.data.provider !== provider) {
        return;
      }

      log.info(`${provider} oauth token received from popup`, event.data.code);

      try {
        await loginAction(provider, encodeURIComponent(event.data.code), getSocialLoginCallbackUrl(provider));
        setIsPopupOpen(false);
      } catch (err) {
        log.warn(`Login the user with ${provider} failed`, err);
      }
    };

    window.addEventListener('message', onReceiveAuthCode, false);

    return () => {
      window.removeEventListener('message', onReceiveAuthCode);
    };
  }, [loginAction, provider]);

  return (
    <FeatureFlag name={`${provider}_login`}>
      <Box
        as="button"
        type="button"
        onClick={() => setIsPopupOpen(true)}
        aria-busy={isLoggingIn}
        disabled={isLoggingIn}
        {...hoverProps}
        {...defaultStyles}
        {...(isHovered && hoveredStyles)}
        _active={
          !isLoggingIn && {
            transform: 'scale(0.95)',
          }
        }
        _focus={{
          outlineWidth: 'borderWidthLarge1X',
          outlineColor: 'colorBackgroundBrandSubtler',
          outlineStyle: 'solid',
          outlineOffset: 'spacingSmall3X',
        }}
      >
        {isLoggingIn ? (
          <Spinner size="sizeIconSmall1X" color="colorIconBold" />
        ) : (
          <Flex as="span" alignItems="center" gap="spacingSmall1X" justifyContent="center">
            <IconSocialGoogle size="sizeIconSmall1X" />
            <span>Continue with {provider}</span>
          </Flex>
        )}
      </Box>
      {isPopupOpen && (
        <Popup
          url={getSocialLoginCallbackUrl(provider)}
          onClose={() => setIsPopupOpen(false)}
          testHook="social-login-popup"
        />
      )}
    </FeatureFlag>
  );
}

export default (connect(mapStateToProps, { loginAction: login })(SocialLoginButton): React.AbstractComponent<Props>);
