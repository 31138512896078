// @flow
import { connect } from 'react-redux';

import type { BoundAction, SocialLoginProvider } from 'src/types';
import Loader from 'src/components/Loader/Loader';
import { getSocialLoginUrl } from 'src/actions/socialLogins';
import { getSocialLoginCallbackUrl } from 'src/utils/routes';

type Props = {
  getSocialLoginUrl: BoundAction<typeof getSocialLoginUrl>,
  location: {
    query: {
      code: ?string,
      error_code?: string,
    },
  },
  params: {
    provider: SocialLoginProvider,
  },
};

export default
@connect(null, { getSocialLoginUrl })
class SocialLogin extends React.Component<Props> {
  async componentDidMount() {
    const { provider } = this.props.params;
    const callbackUrl = getSocialLoginCallbackUrl(provider);
    const { code } = this.props.location.query;

    if (!code) {
      // get oauth url from API and redirect to it
      const res = await this.props.getSocialLoginUrl(provider, callbackUrl);
      window.location.href = res.auth_url;
    } else if (window.opener) {
      // send the oauth code to the parent window and close this popup to continue with the login flow
      window.opener.postMessage({ source: 'login-with', code, provider }, window.location.origin);
      window.close();
    }
  }

  render() {
    return <Loader isFullHeight />;
  }
}
