// @flow
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import cx from 'classnames';
import { Box, Container, useMediaQuery } from '@getatomi/neon';

import type { Subscription, UserType } from 'src/types';
import {
  isLoggedInAsSuperAdmin as isLoggedInAsSuperAdminSelector,
  isUserLoggedIn as isUserLoggedInSelector,
} from 'src/reducers/auth';
import {
  getActiveSubscription,
  getUserOtherActiveAndPendingSubscriptions,
  getTrialEndDate,
  isFreeFamilyPlan as isFreeFamilyPlanSelector,
  isFreeStudentPlan as isFreeStudentPlanSelector,
  isFreeSchoolPlan as isFreeSchoolPlanSelector,
  isInsightsEnabled as isInsightsEnabledSelector,
  isLoggedInAsAccountManager as isLoggedInAsAccountManagerSelector,
  isLoggedInAsChild as isLoggedInAsChildSelector,
  isLoggedInAsParent as isLoggedInAsParentSelector,
  isLoggedInAsStudent as isLoggedInAsStudentSelector,
  isRetailPlan as isRetailPlanSelector,
  isPaidSchoolTrialSubscription,
  isSubscriptionCancelled as isSubscriptionCancelledSelector,
  isAutoCollectionOn as isAutoCollectionOnSelector,
} from 'src/reducers/subscriptions';
import Auth from 'src/utils/Auth';
import useIntercomWidget from 'src/hooks/useIntercomWidget';
import useBodyClass from 'src/hooks/useBodyClass';
import usePreventScrollObserver from 'src/hooks/usePreventScrollObserver';
import { LoadingBar } from 'src/components/LoadingBar/LoadingBar';

import AccountMenu from './AccountMenu/AccountMenu';
import Alerts from './Alerts/Alerts';
import Logo from './Logo/Logo';
import ContextualMobilePostBackLink from './ContextualMobilePostBackLink/ContextualMobilePostBackLink';
import MobileBackLink from './MobileBackLink/MobileBackLink';
import MobileDialog from './MobileDialog/MobileDialog';
import Nav from './Nav/Nav';
import TrialExpiryBadge from './TrialExpiryBadge/TrialExpiryBadge';
import UpgradeCta from './UpgradeCta/UpgradeCta';
import styles from './Header.module.scss';
import EmbedButton from './EmbedButton/EmbedButton';

const mapStateToProps = (state) => {
  const isFreeStudentPlan = isFreeStudentPlanSelector(state);
  const subscription = _.pick(getActiveSubscription(state), ['id', 'name', 'plan_code', 'plan_customer_type']);
  const isSuperAdmin = isLoggedInAsSuperAdminSelector(state);

  return {
    canViewInsights: isInsightsEnabledSelector(state) || isSuperAdmin,
    isFreeSchoolPlan: isFreeSchoolPlanSelector(state),
    isFreeFamilyPlan: isFreeFamilyPlanSelector(state),
    isFreeStudentPlan,
    isLoggedInAsAccountManager: isLoggedInAsAccountManagerSelector(state),
    isLoggedInAsChild: isLoggedInAsChildSelector(state),
    isLoggedInAsParent: isLoggedInAsParentSelector(state),
    isLoggedInAsStudent: isLoggedInAsStudentSelector(state),
    isLoggedInAsSuperAdmin: isLoggedInAsSuperAdminSelector(state),
    isOnTrial: isPaidSchoolTrialSubscription(state),
    isRetailPlan: isRetailPlanSelector(state),
    isAutoCollectionOn: isAutoCollectionOnSelector(state),
    isSubscriptionCancelled: isSubscriptionCancelledSelector(state),
    isUserLoggedIn: isUserLoggedInSelector(state),
    otherSubscriptions: getUserOtherActiveAndPendingSubscriptions(state),
    subscription,
    trialEndDate: getTrialEndDate(state),
    user: state.auth.user,
  };
};

type Props = {
  isEmbed?: boolean,
  withContextualMobilePostBackLink?: boolean,
  withMobileBackLink?: boolean,
  withNav?: boolean,
  withTrialExpiry?: boolean,
  withUpgradeCta?: boolean,
  withUserDropdown?: boolean,
};

type InjectedProps = Props & {
  canViewInsights: boolean,
  isAutoCollectionOn: boolean,
  isFreeFamilyPlan: boolean,
  isFreeSchoolPlan: boolean,
  isFreeStudentPlan: boolean,
  isLoggedInAsAccountManager: boolean,
  isLoggedInAsChild: boolean,
  isLoggedInAsParent: boolean,
  isLoggedInAsStudent: boolean,
  isLoggedInAsSuperAdmin: boolean,
  isOnTrial: boolean,
  isRetailPlan: boolean,
  isSubscriptionCancelled: boolean,
  isUserLoggedIn: boolean,
  otherSubscriptions: Array<Subscription>,
  subscription: Subscription,
  trialEndDate: ?string,
  user: UserType,
};

function Header(props: InjectedProps) {
  const {
    canViewInsights,
    isRetailPlan,
    isFreeSchoolPlan,
    isFreeFamilyPlan,
    isFreeStudentPlan,
    isLoggedInAsAccountManager,
    isAutoCollectionOn,
    isLoggedInAsChild,
    isLoggedInAsParent,
    isLoggedInAsStudent,
    isLoggedInAsSuperAdmin,
    isSubscriptionCancelled,
    isOnTrial,
    isUserLoggedIn,
    otherSubscriptions,
    subscription,
    trialEndDate,
    user,
    withContextualMobilePostBackLink,
    withMobileBackLink,
    withNav = true,
    withTrialExpiry = true,
    withUpgradeCta = true,
    withUserDropdown = true,
    isEmbed,
  } = props;
  const auth = new Auth();
  const [isMobileDialogOpen, setIsMobileDialogOpen] = useState(false);
  const [showIntercom, hideIntercom] = useIntercomWidget();
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const { scrollbarWidth } = usePreventScrollObserver();
  useBodyClass(styles.isOverlayOpen, isMobileDialogOpen);

  useEffect(() => {
    isMobileDialogOpen ? hideIntercom() : showIntercom();
  }, [hideIntercom, isMobileDialogOpen, showIntercom]);

  if (!isUserLoggedIn) return null;

  const onMobileDialogToggle = () => setIsMobileDialogOpen((isOpen) => !isOpen);
  const onMobileDialogClose = () => setIsMobileDialogOpen(false);

  const withTrialExpiryTag =
    isAutoCollectionOn && isOnTrial && withTrialExpiry && !isSubscriptionCancelled && trialEndDate;
  const isFreePlan = isFreeStudentPlan || isFreeFamilyPlan || isFreeSchoolPlan;

  return (
    <div className={styles.root}>
      <Box as="header" className={styles.header} paddingRight={scrollbarWidth}>
        <LoadingBar />
        <Container maxWidth="sizeFull">
          <div className={styles.headerInner}>
            {/* START container */}
            <div className={styles.section}>
              {/* Non-mobile logo */}
              <Logo isMobile={false} isEmbed={isEmbed} />

              {/** Mobile back link or mobile logo **/}
              {!withContextualMobilePostBackLink && !withMobileBackLink && !isEmbed && <Logo />}
              {withContextualMobilePostBackLink && <ContextualMobilePostBackLink />}
              {withMobileBackLink && <MobileBackLink />}

              {/** Non-mobile nav **/}
              {(withNav || withUserDropdown) && (
                <Nav
                  isMobile={false}
                  onNavigate={onMobileDialogClose}
                  onLogout={() => auth.logoutAndRedirect()}
                  isRetailPlan={isRetailPlan}
                  isLoggedInAsParent={isLoggedInAsParent}
                  isLoggedInAsStudent={isLoggedInAsStudent}
                  canViewInsights={canViewInsights}
                />
              )}
            </div>

            {/** MIDDLE container (only non-mobile components) **/}
            <div
              className={cx(styles.section, {
                [styles.middle]: !withTrialExpiryTag,
                [styles.withTrialExpiryTag]: withTrialExpiryTag,
              })}
            >
              {/** Trial expiry tag **/}
              {withTrialExpiryTag && trialEndDate && <TrialExpiryBadge trialEndDate={trialEndDate} />}

              {/** Upgrade CTA **/}
              {withUpgradeCta && isFreePlan && (
                <UpgradeCta
                  insertAfter={
                    // eslint-disable-next-line no-nested-ternary
                    isFreeSchoolPlan
                      ? 'and start using Atomi with your class or school!'
                      : isLoggedInAsParent
                      ? 'and get unlimited access for your child!'
                      : 'and get unlimited access!'
                  }
                  size="small1X"
                  isChild={isLoggedInAsChild}
                  isRetail={isFreeStudentPlan || isFreeFamilyPlan}
                  onNavigate={onMobileDialogClose}
                  testHook="upgrade-button"
                />
              )}
            </div>

            {/** END container **/}
            <div className={styles.section}>
              {/** Mobile dialog **/}
              {(withNav || withUserDropdown) && (
                <MobileDialog
                  canViewInsights={canViewInsights}
                  isFreeSchoolPlan={isFreeSchoolPlan}
                  isFreeStudentPlan={isFreeStudentPlan}
                  isFreeFamilyPlan={isFreeFamilyPlan}
                  isLoggedInAsAccountManager={isLoggedInAsAccountManager}
                  isLoggedInAsChild={isLoggedInAsChild}
                  isLoggedInAsSuperAdmin={isLoggedInAsSuperAdmin}
                  isLoggedInAsParent={isLoggedInAsParent}
                  isLoggedInAsStudent={isLoggedInAsStudent}
                  isOnTrial={isOnTrial}
                  isOpen={isMobileDialogOpen}
                  isRetailPlan={isRetailPlan}
                  isSubscriptionCancelled={isSubscriptionCancelled}
                  onLogout={() => auth.logoutAndRedirect()}
                  onNavigate={onMobileDialogClose}
                  onToggle={onMobileDialogToggle}
                  otherSubscriptions={otherSubscriptions}
                  subscription={subscription}
                  trialEndDate={trialEndDate}
                  user={user}
                  withUpgradeCta={withUpgradeCta}
                />
              )}

              {/** User dropdown (non-mobile only) **/}
              {!isMobile && withUserDropdown && (
                <AccountMenu
                  isFreeFamilyPlan={isFreeFamilyPlan}
                  isFreeSchoolPlan={isFreeSchoolPlan}
                  isFreeStudentPlan={isFreeStudentPlan}
                  isLoggedInAsAccountManager={isLoggedInAsAccountManager}
                  isLoggedInAsChild={isLoggedInAsChild}
                  isLoggedInAsParent={isLoggedInAsParent}
                  isLoggedInAsSuperAdmin={isLoggedInAsSuperAdmin}
                  otherSubscriptions={otherSubscriptions}
                  subscription={subscription}
                  user={user}
                  withUpgradeCta={withUpgradeCta}
                />
              )}

              {/** Open full app in new tab **/}
              {isEmbed && <EmbedButton />}

              {/** Render post embed button via portal **/}
              <div id="embed-portal-destination" />
            </div>
          </div>
        </Container>
      </Box>

      <Alerts />
    </div>
  );
}

export default (connect(mapStateToProps, {})(Header): React.AbstractComponent<Props>);
