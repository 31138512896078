// @flow
import { Text } from '@getatomi/neon';
import invariant from 'invariant';

import type { CurrencyType, DateString } from 'src/types';
import Date from 'src/components/Date/Date';
import Price from 'src/components/Price/Price';

type AmountChargedProps = {
  currency: CurrencyType,
  value: number,
  withCurrencyCode?: boolean,
};

function AmountCharged(props: AmountChargedProps) {
  const { currency, value, withCurrencyCode } = props;
  if (!currency && !value) return null;

  return (
    <Price
      currency={currency}
      value={value}
      withCurrencyCode={withCurrencyCode}
      fontWeight="fontWeightBold"
      removeZeroDecimals
    />
  );
}

type Props = {
  currency: CurrencyType,
  estimate: {
    amount_due: number,
    next_billing_at: DateString,
    totalCredits: number,
  },
  frequency: string,
};

export default function ActiveUserPaymentDescription(props: Props) {
  const { currency, estimate, frequency } = props;

  if (!currency && !estimate && !frequency) return null;

  const { amount_due: amountDue, next_billing_at: nextBillingAt, totalCredits } = estimate;
  const isCreditOnly = totalCredits !== 0 && amountDue === 0;
  const isCreditAndCard = totalCredits !== 0 && amountDue !== 0;
  const isCardOnly = totalCredits === 0 && amountDue !== 0;

  let translationSuffix;
  if (isCreditOnly) {
    translationSuffix = 'creditOnly';
  } else if (isCreditAndCard) {
    translationSuffix = 'creditAndCard';
  } else if (isCardOnly) {
    translationSuffix = 'cardOnly';
  }

  invariant(translationSuffix, 'translationSuffix should be set');

  return (
    <Text as="p" variant="bodySmall" testHook="hide-in-percy">
      Your {frequency === 'year' ? 'yearly' : 'monthly'} subscription will automatically renew on{' '}
      <strong>
        <Date value={nextBillingAt} />
      </strong>
      {isCardOnly && (
        <>
          {' '}
          and your card will be charged <AmountCharged currency={currency} value={amountDue} withCurrencyCode />.
        </>
      )}
      {isCreditAndCard && (
        <>
          . You’ll use <AmountCharged currency={currency} value={totalCredits} /> of credit and your card will be
          charged <AmountCharged currency={currency} value={amountDue} withCurrencyCode />.
        </>
      )}
      {isCreditOnly && (
        <>
          . You’ll use <AmountCharged currency={currency} value={totalCredits} /> of credit and your card won’t be
          charged.
        </>
      )}
    </Text>
  );
}
