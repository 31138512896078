// @flow
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { type ContextRouter, withRouter } from 'react-router';
import _ from 'lodash';
import yn from 'yn';
import { Alert, Container, EmptyState, Illustration, Stack } from '@getatomi/neon';

import type { ReduxState, RegistrationIsoCode, RegistrationRegionCode } from 'src/types';
import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';
import ButtonGroup from 'src/components/ButtonGroup/ButtonGroup';
import NonAuthenticatedLayout from 'src/layouts/NonAuthenticatedLayout/NonAuthenticatedLayout';
import {
  getAllocatedCountryCode,
  getGeolocatedCountryCode,
  isBrowsingIncorrectCountry as isBrowsingIncorrectCountrySelector,
} from 'src/reducers/regions';
import { registrationIsoCodes } from 'src/constants/isoCodes';
import links from 'src/constants/links';
import { registrationRegions } from 'src/constants/regions';
import { trackingCtas } from 'src/constants/tracking';
import userRoles, { rolesLabels } from 'src/constants/userRoles';

import GeoroutingDialog from './GeoroutingDialog/GeoroutingDialog';
import MarketingPanel from '../MarketingPanel/MarketingPanel';

type Props = {
  params: {
    country: RegistrationIsoCode,
    region?: RegistrationRegionCode,
  },
};

type Regions = Array<{
  code: RegistrationRegionCode,
  countryCode: RegistrationIsoCode,
  name: string,
}>;

type InjectedProps = Props & {
  allocatedCountryCode: RegistrationRegionCode,
  countryActiveRegions: Regions,
  geolocatedCountryCode: RegistrationIsoCode,
  isBrowsingIncorrectCountry: boolean,
  router: ContextRouter,
};

const customTrackingCtas = {
  [userRoles.familyAccountOwner]: trackingCtas.userRegistrationFamilyAccountOwner,
  [userRoles.student]: trackingCtas.userRegistrationStudent,
  [userRoles.teacher]: trackingCtas.userRegistrationTeacher,
};

export const mapStateToProps = (state: ReduxState, ownProps: Props) => {
  const { country } = ownProps.params;

  return {
    countryActiveRegions: (_.filter(
      registrationRegions,
      (region) =>
        region.countryCode === country && yn(config[`RUNTIME_FEATURE_REGISTRATION_${region.code.toUpperCase()}`])
    ): Regions),
    isBrowsingIncorrectCountry: isBrowsingIncorrectCountrySelector(state, country),
    geolocatedCountryCode: getGeolocatedCountryCode(state),
    allocatedCountryCode: getAllocatedCountryCode(state),
  };
};

function RegistrationLanding(props: InjectedProps) {
  const {
    allocatedCountryCode,
    countryActiveRegions,
    geolocatedCountryCode,
    isBrowsingIncorrectCountry,
    params: { country, region },
    router,
  } = props;
  const hasMultipleRegions = countryActiveRegions.length > 1;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(isBrowsingIncorrectCountry);

  useEffect(() => {
    if (!registrationIsoCodes[country]) {
      router.replace(`/register/${allocatedCountryCode}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const availableRoles =
    country === 'ca' ? [userRoles.teacher] : [userRoles.student, userRoles.familyAccountOwner, userRoles.teacher];

  return (
    <NonAuthenticatedLayout sidePanel={<MarketingPanel />}>
      <Helmet>
        <title>Registration</title>
      </Helmet>

      {isBrowsingIncorrectCountry && (
        <GeoroutingDialog
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          redirectUrl={`/register/${allocatedCountryCode}`}
          countryApp={country}
          countryUser={geolocatedCountryCode}
        />
      )}

      <Container maxWidth="sizeContainerSmall">
        {hasMultipleRegions && !region ? (
          <EmptyState
            media={<Illustration name="main-map" />}
            heading="Where are you?"
            headingProps={{ as: 'h1' }}
            description="Select your state so we can give you the right curriculum!"
            primaryAction={
              <ButtonGroup testHook="registration-regions-list">
                {countryActiveRegions.map(({ code, name }) => (
                  <Button key={code} variant="tag" size="small" to={`/register/${country}/${code}`}>
                    {name}
                  </Button>
                ))}
              </ButtonGroup>
            }
            helpInfo={
              <Link href={links.support.registrationStates} isExternal variant="monochrome">
                Don’t see your state above?
              </Link>
            }
          />
        ) : (
          <EmptyState
            media={<Illustration name="main-characters" />}
            heading="I’m a…"
            headingProps={{ as: 'h1' }}
            description="Let us know your role so we can give you the right content."
            primaryAction={
              <Stack>
                <ButtonGroup>
                  {availableRoles.map((role) => (
                    <Button
                      key={role}
                      variant="tag"
                      size="small"
                      to={`/register/${rolesLabels[role].toLowerCase()}/${
                        region || (countryActiveRegions[0] && countryActiveRegions[0].code)
                      }`}
                      trackingData={{ cta: customTrackingCtas[role] }}
                    >
                      {rolesLabels[role].toLowerCase()}
                    </Button>
                  ))}
                </ButtonGroup>
                {country === 'ca' && (
                  <Alert variant="info">
                    Atomi is currently available in English only. Sign-up is limited to teachers.
                  </Alert>
                )}
              </Stack>
            }
            helpInfo={
              <Link href={links.support.joiningYourSchoolAccount} isExternal variant="monochrome">
                Trying to join an existing school account?
              </Link>
            }
          />
        )}
      </Container>
    </NonAuthenticatedLayout>
  );
}

export default (connect(mapStateToProps)(withRouter(RegistrationLanding)): React.AbstractComponent<Props>);
