// @flow
import { REGIONS_ENDPOINT } from 'src/api/endpoints';
import { regionSchema } from 'src/api/schema';

export const LOAD_REGION = 'LOAD_REGION';
export const LOAD_REGION_SUCCESS = 'LOAD_REGION_SUCCESS';
export const LOAD_REGION_FAIL = 'LOAD_REGION_FAIL';

type QueryString = { [key: string]: string };

function getQueryStringParameters(withLevels, withInternalSubjects): string {
  const queryStringParams: QueryString = {};
  if (withLevels) {
    queryStringParams.include = 'levels';
    if (withInternalSubjects) {
      queryStringParams.internal = 'true';
    }
  }

  return Object.keys(queryStringParams)
    .map((key) => `${key}=${queryStringParams[key]}`)
    .join('&');
}

export function loadRegion({
  region,
  authenticated = false,
  withLevels = false,
  withInternalSubjects = false,
}: {
  authenticated?: boolean,
  region: string,
  withInternalSubjects?: boolean,
  withLevels?: boolean,
}) {
  const queryString = getQueryStringParameters(withLevels, withInternalSubjects);

  return {
    request: {
      types: [LOAD_REGION, LOAD_REGION_SUCCESS, LOAD_REGION_FAIL],
      schema: regionSchema,
      endpoint: {
        sendAccessToken: authenticated,
        url: `${REGIONS_ENDPOINT}/${region}${queryString ? `?${queryString}` : ''}`,
      },
    },
  };
}
