// @flow
import taskStatuses from 'src/constants/taskStatuses';
import type { GetStudentTasksVariables } from 'src/graphql/types/generated/GetStudentTasks';

export function createStudentTaskFilterInput(
  accountId: string,
  { status, classId }: { classId?: ?string, status?: ?$Keys<typeof taskStatuses> } = {}
): GetStudentTasksVariables {
  const filters = {};

  if (status === taskStatuses.incomplete) {
    filters.isComplete = false;
  } else if (status === taskStatuses.completed) {
    filters.isComplete = true;
  }

  if (classId) {
    filters.classId = classId;
  }

  return { accountId, filters };
}
