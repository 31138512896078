//@flow
import urlRegex from 'url-regex-safe';

export function isValidURL(url: string) {
  return urlRegex({ exact: true, strict: false }).test(url);
}

export function sanitiseURL(url: string) {
  return !/^https?:\/\//i.test(url) ? `http://${url}` : url;
}
