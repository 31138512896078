// @flow
import _ from 'lodash';

import type { Level } from 'src/types';
import formatForSelect from 'src/utils/formatForSelect';
import { LOAD_CLASSES_SUCCESS, LOAD_SUBSCRIPTION_CLASSES_SUCCESS } from 'src/actions/classes';
import { LOAD_REGION_SUCCESS } from 'src/actions/regions';
import { LOAD, LOAD_SUCCESS, LOAD_FAIL } from 'src/actions/levels';

type LevelsStateType = {
  entities: {
    [levelId: number]: Level,
  },
  loadedWithSubjects: boolean,
  loading: boolean,
};
type StateType = {
  levels: LevelsStateType,
};

const initialState = {
  entities: {},
  loaded: false,
  loadedWithSubjects: false,
  loading: false,
};

export default function reducer(state: LevelsStateType = initialState, action: Object = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };

    case LOAD_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.result.entities.levels,
        },
        loading: false,
        loaded: true,
        loadedWithSubjects: true,
      };

    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        routeError: action.routeError,
      };

    case LOAD_CLASSES_SUCCESS:
    case LOAD_SUBSCRIPTION_CLASSES_SUCCESS:
      return {
        ...state,
        // use merge here as some API responses includes the subjects
        entities: _.merge({}, state.entities, action.result.entities.levels),
      };

    case LOAD_REGION_SUCCESS:
      return {
        ...state,
        loaded: true,
        entities: {
          ...state.entities,
          ...action.result.entities.levels,
        },
      };

    default:
      return state;
  }
}

// Selectors
export const isLevelsLoadedWithSubjects = (state: StateType) => state.levels.loadedWithSubjects;
export const getAllLevels = (state: StateType) => _.values(state.levels.entities);
export const getAllLevelsForSelect = (state: StateType, incSubjects?: boolean) =>
  formatForSelect(getAllLevels(state), ['is_alias'].concat(incSubjects ? ['subjects'] : []));
export const getLevelById = (state: StateType, id: number) => state.levels.entities[id];
export const getLevelsByIds = (state: StateType, ids: Array<number>) =>
  _.sortBy(
    ids.map((id) => state.levels.entities[id]),
    'name'
  ).filter(Boolean);
