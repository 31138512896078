// @flow

import RevisionChallengeStart from '../RevisionChallengeStart/RevisionChallengeStart';
import RevisionChallengeResults from '../RevisionChallengeResults/RevisionChallengeResults';
import type { UserClass } from '../useGetRevision';

export type Props = {|
  accountId: string,
  challengeId: string,
  class: UserClass,
  duration: number,
  isTeacher: boolean,
  questionsCount: number,
  timeLimitInSeconds?: number,
  title: string,
  totalMarks: number,
  view: 'Start' | 'Results',
|};

export default function RevisionChallenge({
  accountId,
  challengeId,
  class: userClass,
  duration,
  isTeacher,
  timeLimitInSeconds,
  title,
  questionsCount,
  totalMarks,
  view,
}: Props) {
  const { id: classId, subject } = userClass;

  if (view === 'Start') {
    return (
      <RevisionChallengeStart
        accountId={accountId}
        challengeId={challengeId}
        classId={classId}
        duration={duration}
        questionsCount={questionsCount}
        subject={subject}
        timeLimitInSeconds={timeLimitInSeconds}
        title={title}
        totalMarks={totalMarks}
      />
    );
  }

  return (
    <RevisionChallengeResults accountId={accountId} challengeId={challengeId} classId={classId} isTeacher={isTeacher} />
  );
}
