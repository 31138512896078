// @flow
import { useEffect, useState } from 'react';
import invariant from 'invariant';
import { useQuery } from '@apollo/client';
import Helmet from 'react-helmet';

import type { GetClassMarks, GetClassMarksVariables } from 'src/graphql/types/generated/GetClassMarks';
import type {
  GetClassTopicsMarks,
  GetClassTopicsMarksVariables,
  GetClassTopicsMarks_me_account_class_topics as ClassTopics,
} from 'src/graphql/types/generated/GetClassTopicsMarks';
import type {
  GetClassAssessmentMarks,
  GetClassAssessmentMarksVariables,
  GetClassAssessmentMarks_me_account_class_assessments as ClassAssesments,
} from 'src/graphql/types/generated/GetClassAssessmentMarks';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import { useNavigationContext } from 'src/hooks/useNavigationContext';

import MarkbookLoader from '../components/MarkbookLoader';
import MarkbookControls from '../components/MarkbookControls';
import renderEmptyState from '../utils/renderEmptyState';
import { transformMarksData } from './marksTransformer';
import MarksView from './MarksView';
import GET_CLASS_MARKS from './GetClassMarks.graphql';
import GET_CLASS_TOPICS_MARKS from './GetClassTopicsMarks.graphql';
import GET_CLASS_ASSESSMENT_MARKS from './GetClassAssessmentMarks.graphql';

type Params = {
  classId: string,
  subscriptionId: string,
};

type Props = {
  params: Params,
};

function DocumentTitle() {
  return (
    <Helmet>
      <title>Marks | Mark book</title>
    </Helmet>
  );
}

function useModuleMarksQuery(
  accountId: string,
  classId: string,
  params: Params
): {
  modulesData: ?GetClassMarks,
  renderError: () => ?React.Node,
  renderLoaderIfCacheEmpty: () => ?React.Node,
} {
  const {
    data: modulesData,
    error: modulesError,
    loading: modulesLoading,
  } = useQuery<GetClassMarks, GetClassMarksVariables>(GET_CLASS_MARKS, {
    variables: {
      accountId,
      classId,
      contentFilters: {
        types: ['MODULE'],
      },
      studentFilters: {
        roles: ['STUDENT'],
        userAccountStatuses: ['ACTIVE'],
      },
    },
  });

  const renderLoaderIfCacheEmpty = () => {
    if (modulesLoading && !modulesData) {
      return (
        <>
          <DocumentTitle />
          <MarkbookControls isLoading params={params} activeLink="marks" />
          <MarkbookLoader assistiveText="Loading the list of students and their marks." />
        </>
      );
    }
  };

  const renderError = () => {
    if (modulesError) {
      return (
        <>
          <DocumentTitle />
          <MarkbookControls isErrorState params={params} activeLink="marks" />
          <GraphQLError error={modulesError} description="We couldn’t load the Mark Book." />
        </>
      );
    }
  };

  return {
    modulesData,
    renderError,
    renderLoaderIfCacheEmpty,
  };
}

function useTopicMarksQuery(
  accountId: string,
  classId: string
): {
  expandedModuleState: $Call<typeof useState>,
  topics: ?$ReadOnlyArray<ClassTopics>,
} {
  const expandedModuleState = useState<?string>(null);
  const [expandedModuleId, setExpandedModuleId] = expandedModuleState;

  const {
    data: topicsData,
    error: topicsError,
    loading: topicsLoading,
  } = useQuery<GetClassTopicsMarks, GetClassTopicsMarksVariables>(GET_CLASS_TOPICS_MARKS, {
    skip: !expandedModuleId,
    variables: {
      accountId,
      classId,
      contentFilters: {
        ancestorId: expandedModuleId,
        types: ['TOPIC'],
      },
    },
  });

  const shouldHaveTopicsData = Boolean(expandedModuleId && !topicsLoading && !topicsError);
  const hasTopicsData = Boolean(topicsData?.me?.account?.class);
  if (shouldHaveTopicsData) {
    invariant(hasTopicsData, 'Class data should be defined for topics');
  }

  // Prevent topics expansion on error
  if (topicsError) {
    setExpandedModuleId(null);
  }

  return {
    expandedModuleState,
    topics: topicsData?.me?.account.class?.topics,
  };
}

function useAssessmentMarksQuery(
  accountId: string,
  classId: string
): {
  assessments: ?$ReadOnlyArray<ClassAssesments>,
  expandedTopicState: $Call<typeof useState>,
} {
  const expandedTopicState = useState<?string>(null);
  const [expandedTopicId, setExpandedTopicId] = expandedTopicState;

  const {
    data: assessmentsData,
    error: assessmentsError,
    loading: assessmentsLoading,
  } = useQuery<GetClassAssessmentMarks, GetClassAssessmentMarksVariables>(GET_CLASS_ASSESSMENT_MARKS, {
    skip: !expandedTopicId,
    variables: {
      accountId,
      classId,
      contentFilters: {
        ancestorId: expandedTopicId,
        types: ['ASSESSMENT'],
      },
    },
  });

  const shouldHaveAssesments = Boolean(expandedTopicId && !assessmentsLoading && !assessmentsError);
  const hasAssessmentsData = Boolean(assessmentsData?.me?.account?.class);
  if (shouldHaveAssesments) {
    invariant(hasAssessmentsData, 'Class data should be defined for assessments');
  }

  // Prevent assessments expansion on error
  if (assessmentsError) {
    setExpandedTopicId(null);
  }

  return {
    expandedTopicState,
    assessments: assessmentsData?.me?.account.class?.assessments,
  };
}

export default function MarksContainer(props: Props) {
  const { params } = props;
  const { classId, subscriptionId: accountId } = params;

  const { modulesData, renderError, renderLoaderIfCacheEmpty } = useModuleMarksQuery(accountId, classId, props.params);

  const [, setNavigationContext] = useNavigationContext();
  useEffect(() => {
    setNavigationContext({
      location: 'markbookMarks',
      metadata: {
        accountId,
        classId,
        title: 'Mark Book',
      },
    });
  }, [accountId, classId, setNavigationContext]);

  const { expandedModuleState, topics } = useTopicMarksQuery(accountId, classId);

  const { expandedTopicState, assessments } = useAssessmentMarksQuery(accountId, classId);

  const LoadingState = renderLoaderIfCacheEmpty();
  if (LoadingState) return LoadingState;

  const ErrorState = renderError();
  if (ErrorState) return ErrorState;

  const accountData = modulesData?.me?.account;
  const classData = accountData?.class;
  invariant(accountData && classData, 'Account and Class data should be defined for modules');

  const preparedData = transformMarksData({
    accountData,
    classData,
    expandedModuleId: expandedModuleState[0],
    topics,
    expandedTopicId: expandedTopicState[0],
    assessments,
  });

  const { isClassEmpty, isFreePlan, region } = preparedData;

  const emptyState = renderEmptyState(isClassEmpty, isFreePlan, params, region);

  return (
    <>
      <DocumentTitle />
      {emptyState ?? (
        <MarksView
          expandedModuleState={expandedModuleState}
          expandedTopicState={expandedTopicState}
          params={props.params}
          preparedData={preparedData}
        />
      )}
    </>
  );
}
