// @flow
import { ButtonGroup as NeonButtonGroup } from '@getatomi/neon';

import useIntercomWidget from 'src/hooks/useIntercomWidget';

type Props = React.ElementConfig<typeof NeonButtonGroup>;

export default function ButtonGroup(props: Props) {
  const { collapse, ...otherProps } = props;
  const { drawerProps, ...otherCollapseProps } = collapse || {};
  const [showIntercom, hideIntercom] = useIntercomWidget({ showOnUnmount: true });

  return (
    <NeonButtonGroup
      collapse={
        collapse && {
          drawerProps: {
            onOpen: () => hideIntercom(),
            onClose: () => showIntercom(),
            ...drawerProps,
          },
          ...otherCollapseProps,
        }
      }
      {...otherProps}
    />
  );
}

ButtonGroup.Item = NeonButtonGroup.Item;
