// @flow
import { upperFirst } from 'lodash';
import { useMediaQuery } from '@getatomi/neon';
import invariant from 'invariant';

import type { ChallengeTier, PostType } from 'src/types';
import postTypes from 'src/constants/postTypes';
import { getChallengeLabel } from 'src/utils/challenge';

type Props = {|
  challengeTier?: ?ChallengeTier,
  name: string,
  type: PostType,
  withPrefix?: boolean,
  withPrefixOnMobileOnly?: boolean,
|};

export default function PostName(props: Props) {
  const { challengeTier, name, type, withPrefix, withPrefixOnMobileOnly } = props;
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  invariant(
    !(withPrefix && withPrefixOnMobileOnly),
    `withPrefix and withPrefixOnMobileOnly shouldn't be used together`
  );

  if ((!withPrefix && !withPrefixOnMobileOnly) || type !== postTypes.challenge) return name;

  const isPrefixVisible = withPrefix || (isMobile && withPrefixOnMobileOnly);
  const prefix = isPrefixVisible && `${upperFirst(getChallengeLabel(challengeTier))}: `;

  return (
    <>
      {prefix}
      {name}
    </>
  );
}
