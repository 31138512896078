// @flow
import { forwardRef } from 'react';
import { ActionMenu as NeonActionMenu } from '@getatomi/neon';

import useIntercomWidget from 'src/hooks/useIntercomWidget';

export type ActionMenuProps = {|
  ariaDescribedBy?: string,
  ariaLabel?: string,
  ariaLabelledBy?: string,
  children: React.Node | ((item: any) => React.Node),
  id?: string,
  isLoading?: boolean,
  items?: $ReadOnlyArray<any>,
  onAction?: (key: any) => mixed,
  onOpenChange?: (isOpen: boolean, isTray: boolean) => mixed,
  orientation?: 'vertical',
  renderHeader?: (isTray: boolean) => React.Node,
  testHook?: string,
|};

export const ActionMenu = forwardRef<ActionMenuProps, HTMLButtonElement>(function ActionMenu(props, ref) {
  const { onOpenChange, ...otherProps } = props;
  const [showIntercom, hideIntercom] = useIntercomWidget({ showOnUnmount: true });

  return (
    <NeonActionMenu
      ref={ref}
      onOpenChange={(isOpen, isTray) => {
        if (isTray) {
          if (isOpen) {
            hideIntercom();
          } else {
            showIntercom();
          }
        }
        if (onOpenChange) {
          onOpenChange(isOpen, isTray);
        }
      }}
      {...otherProps}
    />
  );
});

export { Item, Section } from '@getatomi/neon';
