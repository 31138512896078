// @flow
import pluralize from 'pluralize';
import { AlertDialog } from '@getatomi/neon';

export type Props = {
  numSelectedClasses?: number,
};

export default function DeleteClassDialog(props: Props) {
  const { numSelectedClasses = 1, ...otherProps } = props;
  const noun = pluralize('class', numSelectedClasses);
  const pronoun = pluralize('this', numSelectedClasses);

  return (
    <AlertDialog
      {...otherProps}
      variant="danger"
      heading={`Are you sure you want to delete ${pronoun} ${noun}?`}
      onConfirmLabel={`Delete ${noun}`}
    >
      This cannot be undone. Students will be removed from the {noun}, but they will not be deleted from your account.
    </AlertDialog>
  );
}
