// @flow

import SectionListItem from './SectionListItem/SectionListItem';

type Props = {
  children: ?React.ChildrenArray<React.Element<typeof SectionListItem>>,
  testHook?: string,
};

export default function SectionList(props: Props) {
  const { children, testHook } = props;

  if (!children) return null;

  return <ol data-test={testHook}>{children}</ol>;
}
