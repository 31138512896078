// @flow
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router';
import { Link as NeonLink } from '@getatomi/neon';

import type { TrackingDataType } from 'src/types';
import { trackingCtaTypes, trackingEvents } from 'src/constants/tracking';
import { extractLabelFromChildren, trackEvent } from 'src/utils/tracking';
import { toProperCase } from 'src/utils/string';

type To = $PropertyType<React.ElementProps<typeof RouterLink>, 'to'>;

export type LinkProps = {
  ...$Shape<HTMLAnchorElement>,
  activeClassName?: string,
  children: React.Node,
  className?: string,
  href?: string,
  innerRef?: React.Ref<typeof RouterLink>,
  isExternal?: boolean,
  isUnstyled?: boolean,
  onClick?: (e: SyntheticMouseEvent<>) => mixed,
  testHook?: string,
  to?: To,
  trackingData?: TrackingDataType,
  variant?: 'inverted' | 'monochrome',
};

function isRouterLink(to: To) {
  return typeof to === 'string' || (to && to.pathname);
}

const Link = forwardRef<LinkProps, React.AbstractComponent<LinkProps>>(function Link(props, ref) {
  const {
    innerRef,
    href,
    isExternal = false,
    isUnstyled = false,
    children,
    className,
    activeClassName,
    onClick,
    testHook,
    to,
    trackingData,
    ...otherProps
  } = props;

  const handleClick = (e: SyntheticMouseEvent<>) => {
    const text = children ? extractLabelFromChildren(children) : null;
    const cta = text && toProperCase(text);
    const eventProperties = {
      cta,
      href: href ?? to,
      isExternal,
      text,
      type: trackingCtaTypes.link,
      ...trackingData,
    };

    trackEvent(trackingEvents.ctaClicked, eventProperties);

    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  const sharedProps = {
    className,
    onClick: handleClick,
    children,
    ...otherProps,
  };

  // $FlowIgnore To is a known type
  if (to && isRouterLink(to)) {
    const routerLinkProps = {
      ...sharedProps,
      innerRef: innerRef ?? ref,
      activeClassName,
      to,
    };

    return isUnstyled ? (
      // $FlowIgnore To is a known type
      <RouterLink data-test={testHook} {...routerLinkProps} />
    ) : (
      <NeonLink as={RouterLink} {...routerLinkProps} testHook={testHook} />
    );
  }

  const anchorProps = {
    ...sharedProps,
    ref,
    href,
    target: isExternal ? '_blank' : undefined,
  };

  return isUnstyled ? (
    // $FlowIgnore - AbstractComponent is not compatible with HTMLAnchorElement
    <a data-test={testHook} {...anchorProps}>
      {children}
    </a>
  ) : (
    <NeonLink {...anchorProps} testHook={testHook} />
  );
});

export const UnstyledLink = forwardRef<LinkProps, React.AbstractComponent<LinkProps>>(function UnstyledLink(
  props,
  ref
) {
  return <Link ref={ref} {...props} isUnstyled />;
});

export default Link;
