// @flow
import { EmptyState, Illustration } from '@getatomi/neon';

import Button from 'src/components/Button/Button';

export default function ContentCatalogError({ content, refetch }: {| content: string, refetch?: () => mixed |}) {
  return (
    <EmptyState
      description={`We couldn’t load your ${content}.`}
      heading="Oops something went wrong."
      media={<Illustration name="main-browser" />}
      primaryAction={refetch ? <Button onClick={() => refetch()}>Retry</Button> : null}
    />
  );
}
