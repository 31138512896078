// @flow
import { Diff } from '@getatomi/neon';

export type StrengthInfoDeltaProps = {|
  type: 'class' | 'school' | 'state',
  value: ?number,
|};

export default function StrengthInfoDelta(props: StrengthInfoDeltaProps) {
  const { type, value } = props;

  if (!value) return null;

  return <Diff value={value} insertAfter={`compared to ${type} avg`} variant="bold" />;
}
