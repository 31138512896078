// @flow
import { Button, HideVisually, IconCalendar, IconCopy, IconGoogleClassroom, IconViewTask } from '@getatomi/neon';

import usePostActions from 'src/hooks/components/usePostActions';
import getStudentCountText from 'src/utils/getStudentCountText';
import ButtonGroup from 'src/components/ButtonGroup/ButtonGroup';
import CompletionIcon from 'src/components/CompletionCount/CompletionIcon';
import { getPostUrl } from 'src/utils/routes';

import type { CurrentClass, Lesson } from '../useGetLesson';

export type PostActionsProps = {|
  currentClass: CurrentClass,
  isCrossSubject: boolean,
  isFreePlan: boolean,
  lesson: Lesson,
  moduleId: string,
  onSubmitTask: () => mixed,
  onViewTasks: (url: string) => mixed,
  progressCount: number,
  region: string,
  subscriptionId: string,
|};

export default function PostActions(props: PostActionsProps) {
  const {
    isCrossSubject,
    isFreePlan,
    subscriptionId,
    currentClass,
    moduleId,
    lesson,
    progressCount,
    onSubmitTask,
    onViewTasks,
    region,
  } = props;
  const { subject } = currentClass;
  const [elements, { assignTask, viewTasks, viewProgress, copyLink, copyEmbed, shareToGoogleClassroom }] =
    usePostActions({
      isFreePlan,
      subscriptionId,
      classId: String(currentClass.id),
      subject,
      moduleId,
      lesson: {
        ...lesson,
        subjectCode: subject.code,
        url: getPostUrl(subscriptionId, currentClass.id, moduleId, lesson.id),
      },
      onSubmitTask,
      onViewTasks,
      region,
    });

  return (
    <>
      <ButtonGroup
        collapse={{
          label: 'More',
          after: 3,
          variant: 'tertiary',
        }}
        testHook="post-actions"
      >
        <Button
          variant="tertiary"
          iconBefore={<CompletionIcon color={subject.color} assistiveText="View progress report." />}
          onClick={viewProgress}
          testHook="post-actions-completed"
        >
          {getStudentCountText({
            count: progressCount,
            total: currentClass.studentCount,
            totalText: 'Everyone',
          })}
          {progressCount === 0 || progressCount !== currentClass.studentCount ? (
            ' completed'
          ) : (
            <HideVisually>completed</HideVisually>
          )}
        </Button>

        <Button
          variant="tertiary"
          iconBefore={<IconCalendar size="sizeIconSmall1X" />}
          onClick={assignTask}
          testHook="post-actions-assign"
        >
          Assign as task
        </Button>

        {lesson.hasTasks && (
          <Button
            variant="tertiary"
            iconBefore={<IconViewTask size="sizeIconSmall1X" />}
            onClick={viewTasks}
            testHook="post-actions-view-tasks"
          >
            View linked tasks
          </Button>
        )}

        {!isCrossSubject && (
          <>
            <Button
              variant="tertiary"
              iconBefore={<IconCopy size="sizeIconSmall1X" />}
              onClick={copyLink}
              testHook="post-actions-copy-link"
            >
              Copy link
            </Button>
            <Button
              variant="tertiary"
              iconBefore={<IconCopy size="sizeIconSmall1X" />}
              onClick={copyEmbed}
              testHook="post-actions-copy-embed"
            >
              Copy embed code
            </Button>
            <Button
              variant="tertiary"
              iconBefore={<IconGoogleClassroom size="sizeIconSmall1X" />}
              onClick={shareToGoogleClassroom}
              testHook="post-actions-google-classroom"
            >
              Share to Google Classroom
            </Button>
          </>
        )}
      </ButtonGroup>
      {elements}
    </>
  );
}
