// @flow
import { Box } from '@getatomi/neon';
import invariant from 'invariant';
import pluralize from 'pluralize';

import type { Output as UseBreadcrumbsOutput } from 'src/components/useFindContentDialog/hooks/useBreadcrumbs';
import DividerStack, { DividerStackLoader } from 'src/components/DividerStack/DividerStack';

import ContentCatalogError from '../ContentCatalog/ContentCatalogError';
import useGetModules, { type SelectModuleVariables } from './useGetModules';

type Props = {|
  addBreadcrumb: $PropertyType<UseBreadcrumbsOutput, 'addBreadcrumb'>,
  queryVariables: SelectModuleVariables,
|};

export default function SelectModule(props: Props) {
  const { addBreadcrumb, queryVariables } = props;

  const { loading, error, modules } = useGetModules(queryVariables);
  if (error) {
    return (
      <Box marginTop="spacingSmall">
        <ContentCatalogError content="modules" />
      </Box>
    );
  }

  if (loading) {
    return <DividerStackLoader />;
  }

  invariant(modules, `Modules should be defined`);

  return (
    <DividerStack
      items={modules.map((module) => ({
        heading: module.name,
        subheading: `${module.count} ${pluralize('Lesson', module.count)}`,
        onClick: () => {
          addBreadcrumb({
            screen: 'select-lessons',
            label: module.name,
            queryVariables: {
              ...queryVariables,
              moduleId: module.id,
            },
          });
        },
      }))}
    />
  );
}
