// @flow
import { upperFirst } from 'lodash';

export function formatName(name: ?string) {
  return upperFirst(name);
}

export default function userFullName(firstName: ?string, lastName: ?string) {
  if (!firstName && !lastName) return null;

  return `${formatName(firstName)} ${formatName(lastName)}`;
}
