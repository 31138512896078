// @flow
import { useEffect, useRef, useState } from 'react';
import { Box, EmptyState, Illustration, KebabList, Card, Divider } from '@getatomi/neon';
import _ from 'lodash';

import Button from 'src/components/Button/Button';
import taskStatuses from 'src/constants/taskStatuses';
import getSubjectColors, { type SubjectColors } from 'src/utils/getSubjectColors';

import type { TaskAttachment } from '../types';
import type { GetTasks, Task } from './useGetStudentTasks';
import useTaskListFilters, { type TaskFilters } from '../hooks/useStudentTaskListFilters';
import { isCompletedTask, groupStudentTasks } from '../utils/groupTasks';
import StudentTaskCardHeader from '../components/StudentTaskCardHeader/StudentTaskCardHeader';
import TaskCardBody from '../components/TaskCardBody/TaskCardBody';
import { StudentTaskCardContentList } from '../components/TaskCardContentList/TaskCardContentList';
import TaskAttachmentList from '../components/TaskAttachmentList/TaskAttachmentList';
import StudentTaskCompletionToggle from '../components/StudentTaskCompletionToggle/StudentTaskCompletionToggle';

type StudentTaskCardProps = {|
  colors: SubjectColors,
  onTaskCompletionChange: (id: string, isCompleted: boolean) => mixed,
  task: Task,
  withStatus: boolean,
|};

type StudentTaskListProps = {|
  data: GetTasks,
  filters: TaskFilters,
  onClassIdChange: (classId: ?string) => mixed,
  onCompletionFilterChange: (status: ?$Keys<typeof taskStatuses>) => mixed,
  onTaskCompletionChange: (id: string, isCompleted: boolean) => mixed,
|};

function StudentTaskCard(props: StudentTaskCardProps) {
  const { colors, onTaskCompletionChange, task, withStatus } = props;
  const { attachments, author, body, subject, dueDate, id: taskId, contents, title } = task;

  const isTaskCompleted = isCompletedTask(task);
  const [isCompleted, setIsCompleted] = useState(isTaskCompleted);
  const isRequestInFlightRef = useRef<boolean>(false);

  // Using useEffect to update the derived state only when isTaskCompleted changes. The isCompleted
  // hook dependency is ignored on purpose to prevent the state change to be reverted when
  // onCompletionChange is called
  useEffect(() => {
    isRequestInFlightRef.current = false;
    if (isTaskCompleted !== isCompleted) {
      setIsCompleted(isTaskCompleted);
    }
  }, [isTaskCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCompletionChange = () => {
    if (isRequestInFlightRef.current) return;
    // toggle the completion in the derived state for quick UI feedback
    setIsCompleted((value) => !value);
    // set the ref to avoid other updates while mutation is in progress
    isRequestInFlightRef.current = true;
    onTaskCompletionChange(taskId, !isCompleted);
  };

  // completion toggle is disabled when the task is marked as autocomplete or when not
  // all the attached task content is done
  const isCompletionDisabled = task.autoComplete || Boolean(task.contents.find((content) => content.progress === null));

  return (
    <Card as="article" padding="spacingRoot" variant="elevated" withHover={false} testHook="task-list-card">
      <Box marginBottom="spacingSmall">
        <StudentTaskCardHeader
          author={author}
          dueDate={dueDate}
          subjectColor={colors.background}
          subjectName={subject.name}
          withStatus={withStatus}
        />
      </Box>

      <TaskCardBody title={title} body={body} taskUrl={task.url} />

      {attachments.length > 0 && (
        <Box marginBlock="spacingRoot">
          <TaskAttachmentList attachments={((attachments: $FlowSuppressAny): $ReadOnlyArray<TaskAttachment>)} />
        </Box>
      )}

      {contents.length > 0 && <StudentTaskCardContentList contents={contents} subjectRootColor={colors.icon} />}

      <Divider />

      <Box marginTop="spacingRoot">
        <StudentTaskCompletionToggle
          colors={colors}
          isCompleted={isCompleted}
          isDisabled={isCompletionDisabled}
          onClick={onCompletionChange}
        />
      </Box>
    </Card>
  );
}

function StudentTaskList(props: StudentTaskListProps) {
  const { data, filters, onClassIdChange, onCompletionFilterChange, onTaskCompletionChange } = props;
  const [isFiltering, taskListFilters, clearFilters] = useTaskListFilters({
    classes: data.classes,
    filters,
    onClassIdChange,
    onCompletionFilterChange,
  });

  const sections = groupStudentTasks(data.tasks);

  if (_.isEmpty(sections)) {
    return (
      <KebabList filters={taskListFilters}>
        <KebabList.Empty>
          <Box marginTop="spacingLarge3X">
            {isFiltering ? (
              <EmptyState
                media={<Illustration name="emptystate-tasks" />}
                description="There are no results for your selection."
                primaryAction={
                  <Button variant="text" onClick={clearFilters}>
                    Clear all filters
                  </Button>
                }
              />
            ) : (
              <EmptyState
                media={<Illustration name="emptystate-tasks" />}
                heading="You have no tasks to do, yet."
                headingProps={{ as: 'h1' }}
                description="When your teachers create tasks, they’ll show up here!"
              />
            )}
          </Box>
        </KebabList.Empty>
      </KebabList>
    );
  }

  return (
    <KebabList filters={taskListFilters}>
      {Object.keys(sections).map((sectionName) => (
        <KebabList.Section key={sectionName} title={sectionName} testHook={`task-list-section:${sectionName}`}>
          {sections[sectionName].map((task: Task) => {
            const { subject } = task;
            const subjectColorRange = getSubjectColors(subject.groupCode, subject.color);
            return (
              <StudentTaskCard
                key={task.id}
                // All KebabList.Section children must have a `colors` prop,
                // so we have to define this on the StudentTaskCard
                colors={subjectColorRange}
                onTaskCompletionChange={onTaskCompletionChange}
                task={task}
                withStatus={filters.completionFilter !== taskStatuses.completed}
              />
            );
          })}
        </KebabList.Section>
      ))}
    </KebabList>
  );
}

export default StudentTaskList;
