// @flow
import { useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { withRouter, type ContextRouter, type LocationShape } from 'react-router';
import { Box, Container, HideVisually, HelpInfo, TextLoader, EmptyState, Illustration } from '@getatomi/neon';

import type { ReduxState } from 'src/types';
import Link from 'src/components/Link/Link';
import EmptyStateUpgradeStudentAccount from 'src/components/EmptyStateUpgradeStudentAccount/EmptyStateUpgradeStudentAccount';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import links from 'src/constants/links';
import {
  isLoggedInAsStudent as isLoggedInAsStudentSelector,
  isLoggedInAsChild as isLoggedInAsChildSelector,
} from 'src/reducers/subscriptions';

import useLessonLocations from './useLessonLocations';
import LessonLocationList from './LessonLocationList';

export type Props = {
  isLoggedInAsChild: boolean,
  isLoggedInAsStudent: boolean,
  location: LocationShape,
  params: {
    postId: string,
  },
  router: ContextRouter,
};

export function LessonLocationsQuery(props: Props) {
  const {
    isLoggedInAsChild,
    isLoggedInAsStudent,
    params: { postId },
    router,
    location: { query },
  } = props;

  const isEmbed = query?.isEmbed === 'true';
  const { data, error, loading, hasSingleLocation } = useLessonLocations(postId, isEmbed);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (hasSingleLocation) {
      const [location] = data;
      if (!location.isLocked || !isLoggedInAsStudent) {
        router.replace(location.url);
      }
    }
  }, [data, hasSingleLocation, isLoggedInAsStudent, router, isEmbed]);

  if (data && hasSingleLocation) {
    const [lessonLocation] = data;
    if (lessonLocation.isLocked && isLoggedInAsStudent) {
      return (
        <EmptyStateUpgradeStudentAccount subscriptionId={+lessonLocation.account.id} isChild={isLoggedInAsChild} />
      );
    }
    return <Loader />;
  }

  if (loading) return <Loader />;
  if (error) {
    return (
      <GraphQLError
        description="We couldn’t load this lesson."
        customMessages={{
          '404': { description: 'This lesson doesn’t exist anymore.' },
        }}
        error={error}
      />
    );
  }

  if (data?.length === 0) {
    return (
      <EmptyState
        media={<Illustration name="main-browser" />}
        heading="Access forbidden!"
        headingProps={{ as: 'h1' }}
        description="You don’t have permission to access this lesson."
        primaryAction={<Link href="/">Take me back to the home page</Link>}
      />
    );
  }

  // $FlowIgnore data is set at this point
  return <LessonLocationList data={data} />;
}

export function Loader() {
  return (
    <Container maxWidth="sizeContainerSmall" testHook="loader">
      <Box marginBottom="spacingLarge3X">
        <TextLoader height={40} />
      </Box>
      <Box marginBottom="spacingSmall1X">
        <TextLoader height={120} />
      </Box>
      <TextLoader height={120} />
    </Container>
  );
}

const title = 'Available lessons';

function LessonLocations(props: Props) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <HideVisually>
        <h1>{title}</h1>
      </HideVisually>
      <Container textAlign="center">
        <Box marginBottom="spacingLarge4X">
          <LessonLocationsQuery {...props} />
        </Box>
        <HelpInfo>
          Need help with your accounts?{' '}
          <Link href={links.support.home} isExternal variant="monochrome">
            Contact support
          </Link>
          .
        </HelpInfo>
      </Container>
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  isLoggedInAsStudent: isLoggedInAsStudentSelector(state),
  isLoggedInAsChild: isLoggedInAsChildSelector(state),
});

export default withRouter(connect(mapStateToProps, null)(LessonLocations));
