// @flow
export default class HttpError extends Error {
  response: Response;

  status: number;

  constructor(response: Response) {
    super(`HttpError ${response.status}`);
    this.name = 'HttpError';
    this.response = response;
    this.status = response.status;
  }
}
