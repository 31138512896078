// @flow
import _ from 'lodash';

import type { GetClassCompletion_me_account_class_students as GraphqlClassCompletionStudents } from 'src/graphql/types/generated/GetClassCompletion';
import type { GetClassMarks_me_account_class_students as GraphqlClassMarksStudents } from 'src/graphql/types/generated/GetClassMarks';
import type { GetMarkbookTasks_me_account_class_students as GraphqlMarkbookTasksStudent } from 'src/graphql/types/generated/GetMarkbookTasks';
import type { UserAccountStatusType } from 'src/types';
import userFullName from 'src/utils/userFullName';

export type Student = {|
  accountStatus: UserAccountStatusType,
  avatar: ?string,
  color: number,
  email: string,
  firstName: string,
  fullName: string,
  id: string,
  isInactive: boolean,
  lastName: string,
  sortByValue: ?string,
  type: 'Student',
|};

export default function prepareStudentData(
  studentId: string,
  students: $ReadOnlyArray<GraphqlClassCompletionStudents | GraphqlClassMarksStudents | GraphqlMarkbookTasksStudent>,
  isInactive: boolean
): ?Student {
  const gqlStudent = students.find((student) => student.id === studentId);
  if (!gqlStudent) return null;

  const { avatar, color, email, firstName, id, lastName, accountStatus } = gqlStudent;

  const fullName = userFullName(firstName, lastName);
  const upperFirstName = _.upperFirst(firstName);
  const upperLastName = _.upperFirst(lastName);

  return {
    avatar,
    color,
    email,
    firstName: upperFirstName,
    fullName: fullName ?? '',
    id,
    isInactive,
    lastName: upperLastName,
    accountStatus,
    sortByValue: upperLastName,
    type: 'Student',
  };
}
