// @flow
import { useState } from 'react';
import { connect } from 'react-redux';
import { Container, Divider, Heading, Modal, Stack, Text, useId, Box, useToast } from '@getatomi/neon';

import type { BoundAction, ReduxState } from 'src/types';
import CardDetails from 'src/components/CardDetails/CardDetails';
import CardTerms from 'src/components/CardDetails/CardTerms/CardTerms';
import ModalActions from 'src/components/deprecated/ModalActions/ModalActions';
import PaymentSourceForm from 'src/components/PaymentSourceForm/PaymentSourceForm';
import Price from 'src/components/Price/Price';
import { collectInvoicePayment, loadInvoices } from 'src/actions/invoices';
import { createPaymentIntent } from 'src/actions/subscriptions';
import { getOverdueInvoice } from 'src/reducers/invoices';
import { getPrimaryPaymentSource } from 'src/reducers/paymentSources';
import { getActiveSubscriptionPlan } from 'src/reducers/plans';

const mapStateToProps = (state: ReduxState) => {
  const plan = getActiveSubscriptionPlan(state);

  return {
    currency: plan.pricing.currency,
    invoice: getOverdueInvoice(state),
    paymentSource: getPrimaryPaymentSource(state),
    reactivateSubscriptionError: null,
    taxCode: plan.tax_code,
  };
};

type Props = {
  isOpen: boolean,
  onClose: () => mixed,
};

type InjectedProps = Props & {
  ...$Call<typeof mapStateToProps, ReduxState>,
  collectInvoicePaymentAction: BoundAction<typeof collectInvoicePayment>,
  createPaymentIntentAction: BoundAction<typeof createPaymentIntent>,
  loadInvoicesAction: BoundAction<typeof loadInvoices>,
};

function CollectPaymentDialog(props: InjectedProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [retryPaymentError, setRetryPaymentError] = useState(null);

  const {
    collectInvoicePaymentAction,
    createPaymentIntentAction,
    currency,
    invoice,
    isOpen,
    loadInvoicesAction,
    onClose,
    paymentSource,
    taxCode,
  } = props;

  const PaymentForm = paymentSource ? PaymentSourceForm : CardDetails;
  const formProps = PaymentForm === PaymentSourceForm ? { paymentSource, shouldCreateIntent: () => true } : {};
  const submitButtonLabel = 'Authorise payment';
  const headingId = useId();
  const toast = useToast();

  const onPaymentSuccess = async (paymentIntentId: string) => {
    setIsLoading(true);
    try {
      await collectInvoicePaymentAction(invoice.id, paymentIntentId);
    } catch (error) {
      setIsLoading(false);
      if (!error.output) {
        onClose();
        return toast.error('There was an error processing your payment. Please try again.');
      }
      setRetryPaymentError(error.output);
    }
    loadInvoicesAction();
    onClose();
  };

  return (
    <Modal ariaLabelledBy={headingId} isOpen={isOpen} onClose={onClose} size="fullscreen" variant="view">
      <Container textAlign="center" maxWidth="sizeContainerSmall">
        <Stack spacing="spacingLarge5X">
          <Stack spacing="spacingSmall">
            <Heading as="h1" id={headingId}>
              Authorise payment
            </Heading>
            <Text variant="lead">Authorise collection of the overdue payment on your account.</Text>
          </Stack>
          <Divider />
          <Text>
            Your card will be charged{' '}
            <Price
              currency={currency}
              value={invoice.total}
              withCurrencyCode
              fontWeight="fontWeightBold"
              removeZeroDecimals
            />
            .
          </Text>
          <PaymentForm
            {...formProps}
            error={retryPaymentError}
            createIntent={(paymentMethodId, other) =>
              createPaymentIntentAction({
                paymentMethodId,
                amount: invoice.total,
                currencyCode: currency,
                ...other,
              })
            }
            onRequest={() => setIsLoading(true)}
            onError={() => setIsLoading(false)}
            // shouldCreateIntent is always set to true in that scenario so we would always get
            // a payment intent id back
            // $FlowIgnore
            onSuccess={onPaymentSuccess}
          >
            <Box marginTop="spacingLarge5X">
              <ModalActions
                isLoading={isLoading}
                cancelLabel="No, thanks"
                submitLabel={submitButtonLabel}
                submitVariant="success"
                isStacked
                onCancel={onClose}
                testHook="retry-payment-actions"
              />
            </Box>
          </PaymentForm>
          <CardTerms currency={currency} taxCode={taxCode} buttonLabel={submitButtonLabel} />
        </Stack>
      </Container>
    </Modal>
  );
}

export default (connect(mapStateToProps, {
  collectInvoicePaymentAction: collectInvoicePayment,
  createPaymentIntentAction: createPaymentIntent,
  loadInvoicesAction: loadInvoices,
})(CollectPaymentDialog): React.AbstractComponent<Props>);
