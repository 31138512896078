// @flow
import _ from 'lodash';
import { useQuery, type ApolloError } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import invariant from 'invariant';

import type {
  GetQuestionPreviews,
  GetQuestionPreviewsVariables,
  GetQuestionPreviews_contentItems as GqlItem,
} from 'src/graphql/types/generated/GetQuestionPreviews';
import { isExtendedResponse } from 'src/utils/question';

import GET_QUESTION_PREVIEWS from './GetQuestionPreviews.graphql';

export type QuestionPreviewType = {|
  duration: number,
  id: string,
  marks: ?number,
  metadata: string,
  previewText: string,
|};

type Props = {|
  revisionQuestionIds: $ReadOnlyArray<string>,
  selectedTopics: Array<string>,
|};

export type ReplaceQuestionPreviewType = (
  questionIdToReplace: string,
  replacementQuestion: QuestionPreviewType
) => void;

type QuestionPreviewData = {|
  questionPreviews: $ReadOnlyArray<QuestionPreviewType>,
  replaceQuestionPreview: ReplaceQuestionPreviewType,
  totalDuration: number,
  totalMarks: number,
|};

type Output = {|
  data: ?QuestionPreviewData,
  error: ?ApolloError,
  loading: boolean,
|};

function getDisplayType(gqlItem: GqlItem): string {
  switch (gqlItem.__typename) {
    case 'DragAndDropQuestion':
      return 'Drag and drop';
    case 'ExactAnswerQuestion':
      return 'Exact answer';
    case 'MultipleChoiceQuestion':
      return 'Multi-choice';
    case 'SelfMarkedLiteQuestion':
      return 'Short answer';
    case 'SelfMarkedQuestion': {
      if (isExtendedResponse({ type: 'SELF_MARKED', marks: gqlItem.marks })) {
        return 'Extended response';
      }
      return 'Short answer';
    }
    default:
      throw new Error(`Unknown item type: ${gqlItem.__typename}`);
  }
}

export function getTopicNameForQuestion(question: GqlItem, selectedTopicIds: Array<string>): string {
  // This function gets a topic name for a question in a somewhat hacky way.
  // The graph returns a list of lessons for a given question.
  // From this list, we need to guess which lesson a given question belongs to.
  // We do this by matching each lesson's topic with a topic in `selectedTopicIds`

  // Note that most of the time, lessons will have only one topic.

  invariant(
    question.__typename === 'DragAndDropQuestion' ||
      question.__typename === 'ExactAnswerQuestion' ||
      question.__typename === 'MultipleChoiceQuestion' ||
      question.__typename === 'SelfMarkedLiteQuestion' ||
      question.__typename === 'SelfMarkedQuestion',
    `Unexpected question type: ${question.__typename}`
  );

  for (const lesson of question.lessons) {
    const lessonTopic = lesson.categories[1];
    const isSelectedTopic = selectedTopicIds.includes(lessonTopic.id);

    if (isSelectedTopic) {
      // There could be a case where multiple lessons are associated with a selected topic.
      // In this case, we just return the first match.
      return lessonTopic.name;
    }
  }

  throw new Error(
    `No topic name was found for question ID: ${question.id} in selected topic IDs: ${String(selectedTopicIds)}`
  );
}

export function transformQuestionPreview(gqlItem: GqlItem, selectedTopics: Array<string>): ?QuestionPreviewType {
  if (
    gqlItem.__typename !== 'DragAndDropQuestion' &&
    gqlItem.__typename !== 'ExactAnswerQuestion' &&
    gqlItem.__typename !== 'MultipleChoiceQuestion' &&
    gqlItem.__typename !== 'SelfMarkedLiteQuestion' &&
    gqlItem.__typename !== 'SelfMarkedQuestion'
  ) {
    return null;
  }

  const topicName = getTopicNameForQuestion(gqlItem, selectedTopics);
  const displayType = getDisplayType(gqlItem);
  const metadata = `${topicName} • ${displayType} • ${gqlItem.source}`;

  return {
    id: gqlItem.id,
    duration: gqlItem.durationInSeconds,
    marks: gqlItem.marks,
    metadata,
    previewText: gqlItem.prompt,
  };
}

export default function useGetQuestionPreviews(props: Props): Output {
  const { revisionQuestionIds, selectedTopics } = props;

  const [questionPreviews, setQuestionPreviews] = useState<$ReadOnlyArray<QuestionPreviewType> | null>(null);

  const { data, loading, error } = useQuery<GetQuestionPreviews, GetQuestionPreviewsVariables>(GET_QUESTION_PREVIEWS, {
    // This query is only run once (when mounted)
    // i.e. ignore further changes to `revisionQuestionIds`
    skip: questionPreviews !== null,
    variables: {
      filters: {
        contentItemIds: revisionQuestionIds,
      },
    },
  });

  useEffect(() => {
    if (data) {
      const initialQuestionPreviews = data.contentItems
        .map((contentItem) => transformQuestionPreview(contentItem, selectedTopics))
        .filter(Boolean);
      setQuestionPreviews(initialQuestionPreviews);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const totalDuration = useMemo(() => {
    if (questionPreviews === null) {
      return 0;
    }
    return _.sumBy(questionPreviews, 'duration');
  }, [questionPreviews]);

  const totalMarks = useMemo(() => {
    if (questionPreviews === null) {
      return 0;
    }
    return _.sumBy(questionPreviews, 'marks');
  }, [questionPreviews]);

  const replaceQuestionPreview = (questionIdToReplace: string, replacementQuestion: QuestionPreviewType) => {
    setQuestionPreviews((currentQuestionPreviews) => {
      if (!currentQuestionPreviews) {
        return null;
      }

      return currentQuestionPreviews.map((question) =>
        question.id === questionIdToReplace ? replacementQuestion : question
      );
    });
  };

  return {
    error,
    loading: loading || questionPreviews === null,
    data: questionPreviews
      ? {
          questionPreviews,
          replaceQuestionPreview,
          totalDuration,
          totalMarks,
        }
      : null,
  };
}
