// @flow
/**
 * Pagination reusable action creators
 */

const actionName = 'pagination';

export const updateCurrentPage = (page: string, name: string) => ({
  name: actionName,
  type: `UPDATE_${name.toUpperCase()}_CURRENT_PAGE`,
  page,
});

export const clearPagination = (excludedPage: number, name: string) => ({
  name: actionName,
  type: `CLEAR_${name.toUpperCase()}_PAGINATION`,
  currentPage: excludedPage,
});

export const updateSearchKeywords = (keywords: string, name: string) => ({
  name: actionName,
  type: `UPDATE_${name.toUpperCase()}_SEARCH_KEYWORDS`,
  keywords,
});
