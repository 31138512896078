// @flow
import { useLazyQuery, type ApolloError } from '@apollo/client';
import _ from 'lodash';

import type {
  GetNestedLevelSubjects,
  GetNestedLevelSubjectsVariables,
} from 'src/graphql/types/generated/GetNestedLevelSubjects';
import sortYearLevels from 'src/utils/sortYearLevels';

import GET_LEVEL_SUBJECTS from './GetLevelSubjects.graphql';

type YearLevel = {|
  id: string,
  name: string,
  subjects: $ReadOnlyArray<{|
    code: string,
    color: string,
    name: string,
  |}>,
|};

type Output = {|
  error: ?ApolloError,
  getLevelSubjects: () => void,
  levels: ?$ReadOnlyArray<YearLevel>,
  loading: boolean,
|};

export type SelectYearVariables = {|
  subscriptionId: string,
|};

export default function useGetLevelSubjects(props: SelectYearVariables): Output {
  const { subscriptionId } = props;

  const [getLevelSubjects, { data, error, loading }] = useLazyQuery<
    GetNestedLevelSubjects,
    GetNestedLevelSubjectsVariables
  >(GET_LEVEL_SUBJECTS, {
    variables: {
      accountId: subscriptionId,
    },
  });

  if (error) {
    return {
      getLevelSubjects,
      error,
      loading: false,
      levels: null,
    };
  }

  if (loading && !data) {
    return {
      getLevelSubjects,
      loading: true,
      error: null,
      levels: null,
    };
  }

  const subjectData = data?.me?.account.subjects;
  const groupedLevels = _.groupBy(subjectData, (subject) => subject.level.id);
  const levels = Object.keys(groupedLevels).map((levelId) => {
    const { level } = groupedLevels[levelId][0];
    return {
      id: level.id,
      name: level.name,
      subjects: groupedLevels[levelId].map(({ subject }) => ({
        code: subject.code,
        color: subject.color,
        name: subject.name,
      })),
    };
  });
  const sortedLevels = sortYearLevels(levels, 'asc');

  return {
    getLevelSubjects,
    levels: sortedLevels,
    loading: false,
    error: null,
  };
}
