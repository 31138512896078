// @flow
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Container, HideVisually, Modal, SplitButton, tokens, useToast } from '@getatomi/neon';
import moment from 'moment';
import { type ApolloError } from '@apollo/client';

import useFindContentDialog, { type FoundContent } from 'src/components/useFindContentDialog/useFindContentDialog';
import Editor from 'src/components/Editor/Editor';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import { trackingEvents } from 'src/constants/tracking';
import taskPublicationStatuses from 'src/constants/taskPublicationStatuses';
import useAlertDialog from 'src/hooks/components/useAlertDialog';
import useTeacherRevisionDialog from 'src/components/useTeacherRevisionDialog/useTeacherRevisionDialog';
import { taskAttachmentTypes } from 'src/constants/taskAttachmentTypes';
import { getPostUrl } from 'src/utils/routes';
import useIntercomWidget from 'src/hooks/useIntercomWidget';
import { type StudentProp } from 'src/components/StudentPicker/StudentPicker';
import { renderEditableAtomiContent, renderFileAttachment } from 'src/utils/tasks';
import { trackEvent } from 'src/utils/tracking';
import type { AttachedContent, NewAttachedContent, TaskAttachment } from 'src/domains/Tasks/types';

import useCreateTask from './hooks/useCreateTask';
import useEditTask from './hooks/useEditTask';
import useUnsavedChangesPrompt from './hooks/useUnsavedChangesPrompt';
import useAttachments from './hooks/useAttachments';
import InsertLinkDialog from './InsertLinkDialog';
import UploadFile, { type UploadingFile, type UploadedFile } from './UploadFile';
import useContents from './hooks/useContents';
import { trimContents, trimAttachments } from './utilities/trimAttachments';
import TaskScheduleDialog from './TaskScheduleDialog';
import { EditorLoader, EditorMenu, MarkdownEditor } from './TaskDialogEditor';
import { ScheduledDateTime, SidebarControls, SidebarLoader } from './TaskDialogSidebar';

export type Subject = {
  code: string,
  color: string,
  groupCode: string,
  shortName: string,
};

export type EditableTask = {
  +attachments: $ReadOnlyArray<TaskAttachment>,
  +autoComplete: boolean,
  +body: ?string,
  +contents: $ReadOnlyArray<AttachedContent>,
  +dueDate: string,
  +id: string,
  +scheduledFor: ?string,
  +status: $Keys<typeof taskPublicationStatuses>,
  +studentIds: $ReadOnlyArray<string>,
  +title: ?string,
};

export type TaskDialogProps = {|
  classId: string,
  currentClassName: string,
  initialContents?: Array<NewAttachedContent>,
  initialStudents?: Array<string>,
  isFreePlan: boolean,
  isOpen: boolean,
  onClose: () => mixed,
  onSubmit?: (any) => mixed,
  region: string,
  students: Array<StudentProp>,
  subject: Subject,
  subscriptionId: string,
  task?: ?EditableTask,
|};

export function TaskTeacherRevisionBaseDialog(props: { editor: React.Node, sidebar: React.Node }) {
  const { editor, sidebar } = props;

  return (
    <Box
      display="grid"
      gridTemplateColumns={{ mobile: '2fr 1fr', tablet: '3fr 1fr', desktop: '2fr 7fr 3fr' }}
      height="sizeFull"
    >
      <Box display={{ base: 'none', desktop: 'block' }} />
      <Box minWidth="0">
        <Container maxWidth="sizeContainerLarge">{editor}</Container>
      </Box>
      {sidebar}
    </Box>
  );
}

export function TaskDialogLoader(props: {| isExistingTask: boolean, isOpen: boolean, onClose: () => mixed |}) {
  const { isExistingTask, isOpen, onClose } = props;

  return (
    <Modal
      actions={
        <Button size="small" isAriaDisabled>
          {isExistingTask ? 'Save' : 'Create task'}
        </Button>
      }
      heading={isExistingTask ? 'Edit task' : 'Create a task'}
      isOpen={isOpen}
      onClose={onClose}
      size="fullscreen"
    >
      <Box testHook="task-dialog-loader">
        <HideVisually>Loading the task dialog.</HideVisually>
        <TaskTeacherRevisionBaseDialog editor={<EditorLoader />} sidebar={<SidebarLoader />} />
      </Box>
    </Modal>
  );
}

export default function TaskDialog(props: TaskDialogProps) {
  const {
    classId,
    currentClassName,
    initialContents = [],
    isFreePlan,
    isOpen,
    onClose,
    onSubmit,
    region,
    students,
    initialStudents,
    subject,
    subscriptionId,
    task,
  } = props;

  const toast = useToast();
  const editorRef = useRef();
  const fileUploadRef = useRef<React.ElementRef<typeof UploadFile>>(null);
  const duplicateContentToastId = useRef<?string>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState<boolean>(false);
  const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState<boolean>(false);
  const [attachments, dispatchAttachments] = useAttachments(task?.attachments);
  const [contents, dispatchContents] = useContents(initialContents, task?.contents);
  const [status, setStatus] = useState<$Keys<typeof taskPublicationStatuses>>(task?.status || 'draft');

  useEffect(() => {
    if (initialContents.length) {
      initialContents.forEach((content) =>
        trackEvent(trackingEvents.taskAssignContent, {
          subscriptionId,
          classId,
          postId: content.id,
          classSubject: subject.code,
          postSubject: content.subjectCode,
          isCrossSubject: subject.code !== content.subjectCode,
        })
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // $FlowIgnore (Flow does not yet support method calls in optional chains)
  const insertAtomiContent = (contentId) => editorRef.current?.insertAtomiContent(contentId);

  const onAssignContent = (selectedPosts: $ReadOnlyArray<FoundContent>) => {
    selectedPosts.forEach((selectedPost) => {
      if (contents.find((content) => content.id === selectedPost.id)) {
        duplicateContentToastId.current = toast.error(
          'We can’t add that content again because it’s already in the task.'
        );
        return;
      }

      dispatchContents({
        type: 'add',
        content: {
          name: selectedPost.name,
          duration: selectedPost.duration,
          id: String(selectedPost.id),
          subjectCode: selectedPost.subjectCode,
          type: selectedPost.type,
          tier: selectedPost.tier,
          url: getPostUrl(subscriptionId, classId, selectedPost.moduleId, selectedPost.id),
        },
      });
      trackEvent(trackingEvents.taskAssignContent, {
        subscriptionId,
        classId,
        postId: selectedPost.id,
        classSubject: subject.code,
        postSubject: selectedPost.subjectCode,
        isCrossSubject: subject.code !== selectedPost.subjectCode,
      });
      insertAtomiContent(selectedPost.id);
    });
  };

  const { openFindContentDialog, findContentDialog } = useFindContentDialog({
    context: 'task',
    classId,
    heading: 'Add Atomi Content',
    onAssignContent,
    subscriptionId,
  });

  const [isAutoComplete, setIsAutoComplete] = useState<boolean>(
    // only use the existing task autocomplete value if it has attached contents
    // otherwise always default to true
    task && task.contents.length > 0 ? task.autoComplete : true
  );
  const [userIds, setUserIds] = useState<$ReadOnlyArray<string>>(() => {
    if (task && task.studentIds) {
      return task.studentIds;
    }

    if (initialStudents) {
      return initialStudents;
    }

    return students.map((student) => student.id);
  });

  const [dueDate, setDueDate] = useState<moment$Moment>(() =>
    task && task.dueDate
      ? moment(task.dueDate)
      : moment().add(1, 'day').set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
  );
  const [titleValidationError, setTitleValidationError] = useState<?string>(null);
  const [dueDateValidationError, setDueDateValidationError] = useState<?string>(null);
  const [studentsValidationError, setStudentsValidationError] = useState<?string>(null);
  const [scheduledFor, setScheduledFor] = useState(() =>
    task?.scheduledFor
      ? moment(task?.scheduledFor)
      : moment().add(1, 'day').set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
  );

  const contentsRef = useRef(contents);
  const attachmentsRef = useRef(attachments);

  const createTask = useCreateTask({
    subscriptionId,
    classId,
  });

  const editTask = useEditTask();

  let defaultBody = task?.body ?? '';
  if (initialContents.length) {
    defaultBody += [
      '&#x20;', // Add a space to type into above the inserted content
      ...initialContents.map((content) => `::atomi-content{ postId=${content.id} }`),
    ].join('\n');
  }
  const [body, setBody] = useState<string>(defaultBody);
  const [title, setTitle] = useState<string>(task?.title ?? '');

  const [infectedPrompt, showInfectedPrompt] = useAlertDialog({
    closeOnConfirm: true,
    heading: 'This file did not pass the virus security scan and can’t be downloaded.',
    variant: 'danger',
    onConfirmLabel: 'Ok, thanks',
    isDismissable: false,
  });

  const [showIntercom, hideIntercom] = useIntercomWidget();

  const [error, setError] = useState<?ApolloError>();

  const {
    dialog: teacherRevisionDialog,
    open: openTeacherRevisionDialog,
    close: closeTeacherRevisionDialog,
  } = useTeacherRevisionDialog({
    isFreePlan,
    onCreate: (newRevision: NewAttachedContent) => {
      dispatchContents({
        type: 'add',
        content: newRevision,
      });
      trackEvent(trackingEvents.taskAssignContent, {
        subscriptionId,
        classId,
        postId: newRevision.id,
        classSubject: subject.code,
        postSubject: newRevision.subjectCode,
        isCrossSubject: subject.code !== newRevision.subjectCode,
      });
      insertAtomiContent(newRevision.id);
      closeTeacherRevisionDialog();
    },
    region,
  });

  const classStudentsCount = students.length;

  const dismissDuplicateContentToast = () => {
    if (duplicateContentToastId.current) {
      toast.hide(duplicateContentToastId.current);
      duplicateContentToastId.current = null;
    }
  };

  const validateTask = (taskStatus): boolean => {
    const isDueDatePassed = moment(dueDate).isBefore();
    const isDueDateUnchanged = moment(task?.dueDate).isSame(dueDate);
    if ((!task && isDueDatePassed) || (task && !isDueDateUnchanged && isDueDatePassed)) {
      setDueDateValidationError('The due date and time must not be in the past.');
      return true;
    }
    if (taskStatus === 'scheduled' && scheduledFor.isAfter(dueDate)) {
      setDueDateValidationError('Task must be scheduled before the due date and time.');
      return true;
    }
    if (userIds.length === 0 && students.length > 0) {
      setStudentsValidationError('Please assign this task to at least one student.');
      return true;
    }
    if (taskStatus !== 'draft' && title.trim() === '') {
      setTitleValidationError(
        `The task title is required to ${taskStatus === 'scheduled' ? 'schedule' : 'create'} a task.`
      );
      return true;
    }
    return false;
  };

  const triggerMutation = async (taskStatus) => {
    setIsLoading(true);

    try {
      const mutationData = {
        title,
        body,
        // If empty the task will be assigned to all current and future students in the class
        studentIds: userIds.length === classStudentsCount ? [] : userIds,
        contents: contents.map(({ id, type }) => ({
          id,
          type: type === 'revision' ? 'REVISION' : 'LESSON',
        })),
        // isAutocomplete is always false when there is no content attached
        isAutocomplete: contents.length > 0 ? isAutoComplete : false,
        dateDue: dueDate.format(),
        attachments: attachments.map(({ hash, id, isFresh, isUploading, isInfected, type, url, ...attachment }) =>
          // Only send the id for existing attachments
          isFresh
            ? {
                ...attachment,
                type,
                url: url ?? '',
              }
            : { id }
        ),
        status: taskStatus,
        scheduledFor: taskStatus === 'scheduled' ? scheduledFor.format() : null,
      };

      if (task?.id) {
        await editTask({
          variables: {
            input: {
              // $FlowIgnore - task.id exists
              id: task.id,
              ...mutationData,
            },
          },
        });
      } else {
        await createTask({
          variables: {
            input: {
              classId,
              ...mutationData,
            },
          },
        });
      }

      if (onSubmit) await onSubmit();

      onClose();
    } catch (graphqlErrors) {
      setError(graphqlErrors);
    }
    setIsLoading(false);
  };

  const handleSubmit = (taskStatus) => {
    dismissDuplicateContentToast();

    const hasError = validateTask(taskStatus);
    if (hasError) return;

    trackEvent(task ? trackingEvents.taskUpdateRequested : trackingEvents.taskCreateRequested, {
      subscriptionId,
      classId,
      classMemberCount: classStudentsCount,
      assignedStudentCount: userIds.length,
      autoComplete: isAutoComplete,
      atomiPostAttachedCount: contents.length,
      atomiPostAttachedIds: contents.map(({ id }) => id),
      linkAttachmentCount: attachments.filter(({ type }) => type === taskAttachmentTypes.LINK).length,
      uploadAttachmentCount: attachments.filter(({ type }) => type === taskAttachmentTypes.FILE).length,
      subjectCode: subject.code,
      dueDate,
      scheduledFor: taskStatus === 'scheduled' ? scheduledFor : null,
      status: taskStatus,
      title,
    });

    triggerMutation(taskStatus);
  };

  const isExistingTask = task && status !== 'draft';

  const [
    unsavedChangesPrompt,
    showUnsavedChangesPrompt,
    shouldShowUnsavedChangesPrompt,
    setShouldShowUnsavedChangesPrompt,
  ] = useUnsavedChangesPrompt({
    handleSubmit,
    onClose,
    taskStatus: status,
  });

  const handleSelectStudentsOnClose = useCallback(() => {
    if (userIds.length > 0) {
      setStudentsValidationError(null);
    }
  }, [userIds]);

  const insertAttachedFile = (attachmentId) => {
    // $FlowIgnore (Flow does not yet support method calls in optional chains)
    editorRef.current?.insertAttachedFile(attachmentId);
  };
  const insertAttachedLink = (url) => {
    // $FlowIgnore (Flow does not yet support method calls in optional chains)
    editorRef.current?.insertAttachedLink(url);
  };

  // $FlowIgnore (Flow does not yet support method calls in optional chains)
  const updateEditor = () => editorRef.current?.update();

  useEffect(() => {
    attachmentsRef.current = attachments;
    updateEditor();
  }, [attachments]);

  useEffect(() => {
    contentsRef.current = contents;
    updateEditor();
  }, [contents]);

  const closeScheduleDialog = () => {
    showIntercom();
    setIsScheduleDialogOpen(false);
  };

  const scheduleDialogPreValidation = (): boolean => {
    if (title.trim() === '') {
      setTitleValidationError(`The task title is required to schedule a task.`);
      return false;
    }
    return true;
  };

  const getAttachment = (id) =>
    attachmentsRef.current && attachmentsRef.current.find((attachment) => attachment.externalId === id);
  const getAttachedContent = (id) => contentsRef.current && contentsRef.current.find((content) => content.id === id);

  const fileAttachmentRenderer = renderFileAttachment(getAttachment, showInfectedPrompt);
  const atomiContentRenderer = renderEditableAtomiContent({ getAttachedContent, subjectColor: subject.color });

  const onEditorChange = ({ atomiContent, attachedFiles, attachedLinks, markdown }) => {
    setShouldShowUnsavedChangesPrompt();
    setBody(markdown);
    contentsRef.current && trimContents(atomiContent, contentsRef.current, dispatchContents);
    attachmentsRef.current &&
      trimAttachments(attachedFiles, attachedLinks, attachmentsRef.current, dispatchAttachments);
  };

  const onTitleChange = (value: string) => {
    setShouldShowUnsavedChangesPrompt();
    setTitle(value);
    if (titleValidationError && title !== '') setTitleValidationError(null);
  };

  const toggleAutoComplete = useCallback((value) => {
    setShouldShowUnsavedChangesPrompt();
    setIsAutoComplete(value);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectStudentsOnChange = useCallback((selectedStudentIds) => {
    setShouldShowUnsavedChangesPrompt();
    setUserIds(selectedStudentIds);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onDueDateChange = (date: moment$Moment) => {
    const newDate = dueDate.clone().set({
      date: date.get('date'),
      month: date.get('month'),
      year: date.get('year'),
    });
    setShouldShowUnsavedChangesPrompt();
    setDueDateValidationError(null);
    setDueDate(newDate);
  };

  const onDueTimeChange = (value: string) => {
    const time = moment(value, 'H:mm');
    const newDate = dueDate.clone().set({
      hour: time.get('hour'),
      minute: time.get('minute'),
    });
    setShouldShowUnsavedChangesPrompt();
    setDueDateValidationError(null);
    setDueDate(newDate);
  };

  const handleSplitButtonAction = (key: string) => {
    if (key === 'schedule') {
      dismissDuplicateContentToast();
      if (scheduleDialogPreValidation()) {
        hideIntercom();
        setIsScheduleDialogOpen(true);
      }
    } else if (key === 'save-as-draft') {
      handleSubmit('draft');
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        actions={
          isExistingTask ? (
            <Button size="small" onClick={() => handleSubmit(status)} isLoading={isLoading}>
              Save
            </Button>
          ) : (
            <SplitButton
              size="small"
              isLoading={isLoading}
              onClick={() => {
                handleSubmit(status === 'scheduled' ? 'scheduled' : 'published');
              }}
              items={[
                { key: 'schedule', name: 'Schedule' },
                { key: 'save-as-draft', name: 'Save as draft' },
              ]}
              onMenuAction={handleSplitButtonAction}
            >
              Create task
            </SplitButton>
          )
        }
        heading={isExistingTask ? 'Edit task' : 'Create a task'}
        onClose={() => {
          dismissDuplicateContentToast();

          if (shouldShowUnsavedChangesPrompt) {
            showUnsavedChangesPrompt();
            return;
          }

          onClose();
        }}
        size="fullscreen"
      >
        {infectedPrompt}
        {unsavedChangesPrompt}
        {error && <GraphQLError error={error} isValidation />}
        <TaskScheduleDialog
          setScheduledFor={setScheduledFor}
          scheduledFor={scheduledFor}
          dueDate={dueDate}
          onCancel={() => {
            if (task && task.scheduledFor) setScheduledFor(moment(task.scheduledFor));
            closeScheduleDialog();
          }}
          isOpen={isScheduleDialogOpen}
          onClose={() => closeScheduleDialog()}
          onSubmit={() => {
            closeScheduleDialog();
            setStatus('scheduled');
            handleSubmit('scheduled');
          }}
        />

        <UploadFile
          ref={fileUploadRef}
          subscriptionId={subscriptionId}
          onUploading={(attachment: UploadingFile) => {
            insertAttachedFile(attachment.externalId);
            dispatchAttachments({ type: 'add', attachment });
          }}
          onSuccess={(attachment: UploadedFile) => {
            dispatchAttachments({ type: 'update', attachment });
          }}
          onError={(id) => {
            dispatchAttachments({ type: 'remove', id });
          }}
          testHook="upload-file"
        />

        {findContentDialog}

        <TaskTeacherRevisionBaseDialog
          editor={
            <Editor
              ref={editorRef}
              ariaLabel="Task body"
              fileAttachmentRenderer={fileAttachmentRenderer}
              onChange={onEditorChange}
              placeholder="Start typing instructions or add content…"
              renderAtomiContent={atomiContentRenderer}
              loader={<EditorLoader />}
              renderEditor={(editor, menu) => (
                <>
                  <EditorMenu
                    defaultMenu={menu}
                    dismissDuplicateContentToast={dismissDuplicateContentToast}
                    fileUploadRef={fileUploadRef}
                    onAddAtomiContent={openFindContentDialog}
                    onAddRevision={() => openTeacherRevisionDialog()}
                    onInsertLink={() => setIsLinkDialogOpen(true)}
                  />
                  <MarkdownEditor
                    currentClassName={currentClassName}
                    editor={editor}
                    onTitleChange={onTitleChange}
                    subject={subject}
                    title={title}
                    titleValidationError={titleValidationError}
                  />
                </>
              )}
            >
              {body}
            </Editor>
          }
          sidebar={
            <Box
              as="aside"
              borderColor="colorBorder"
              borderStyle="solid"
              borderWidth={{
                base: `${tokens.borderWidthRoot} ${tokens.borderWidthNone} ${tokens.borderWidthNone}`,
                mobile: `${tokens.borderWidthNone} ${tokens.borderWidthNone} ${tokens.borderWidthNone} ${tokens.borderWidthRoot}`,
              }}
              height="sizeFull"
            >
              {status === 'scheduled' && (
                <ScheduledDateTime
                  dismissDuplicateContentToast={dismissDuplicateContentToast}
                  scheduledFor={scheduledFor}
                  setIsScheduleDialogOpen={setIsScheduleDialogOpen}
                  setStatus={setStatus}
                />
              )}

              <SidebarControls
                classStudentsCount={classStudentsCount}
                dueDate={dueDate}
                dueDateValidationError={dueDateValidationError}
                handleSelectStudentsOnClose={handleSelectStudentsOnClose}
                hasPosts={Boolean(contents.length)}
                isAutoComplete={isAutoComplete}
                onDueDateChange={onDueDateChange}
                onDueTimeChange={onDueTimeChange}
                selectStudentsOnChange={selectStudentsOnChange}
                students={students}
                studentsValidationError={studentsValidationError}
                toggleAutoComplete={toggleAutoComplete}
                userIds={userIds}
              />
            </Box>
          }
        />

        <InsertLinkDialog
          isOpen={isLinkDialogOpen}
          onSubmit={(attachment) => {
            dispatchAttachments({ type: 'add', attachment });
            insertAttachedLink(attachment.url);
            setIsLinkDialogOpen(false);
          }}
          onClose={() => setIsLinkDialogOpen(false)}
        />
      </Modal>

      {teacherRevisionDialog}
    </>
  );
}
