// @flow
import _ from 'lodash';
import invariant from 'invariant';
import { useRef } from 'react';
import { Box, Flex, useId, Skeleton } from '@getatomi/neon';

import useUpgradePrompt from 'src/hooks/components/useUpgradePrompt';
import { DividerStackLoader } from 'src/components/DividerStack/DividerStack';

import type { FoundContent } from './useFindContentDialog';
import useGetFindContentDialog from './useGetFindContentDialog';
import useContentAutocomplete from './hooks/useContentAutocomplete';
import useBreadcrumbs, { omitLevelIdForRetailUsers } from './hooks/useBreadcrumbs';
import ContentCatalog from './components/ContentCatalog/ContentCatalog';
import ContentSearchBox from './components/ContentSearchBox/ContentSearchBox';
import ContentSearchResults from './components/ContentSearchResults/ContentSearchResults';
import ScrollWithFade from './components/ScrollWithFade/ScrollWithFade';
import ContentCatalogError from './components/ContentCatalog/ContentCatalogError';

type FindContentDialogContainerProps = {|
  classId: string,
  context: 'subject' | 'task',
  onAssignContent: ($ReadOnlyArray<FoundContent>) => void,
  subscriptionId: string,
|};

type FindContentDialogProps = {|
  ...FindContentDialogContainerProps,
  getLessonUrl: (args: {| lessonId: string, moduleId: string |}) => string,
  isFreePlan: boolean,
  isRetailPlan: boolean,
  isSchoolPlan: boolean,
  isStudent: boolean,
  subjectCode: string,
  subjectName: string,
  subscriptionId: string,
  yearLevelId: string,
  yearLevelName: string,
|};

function FindContentDialog(props: FindContentDialogProps) {
  const {
    subjectCode: defaultSubjectCode,
    subjectName,
    subscriptionId,
    yearLevelId,
    yearLevelName,
    isFreePlan,
    isRetailPlan,
    isSchoolPlan,
    isStudent,
    getLessonUrl,
    context,
    classId,
    onAssignContent,
  } = props;

  const formId = useId();
  const formRef = useRef<HTMLFormElement | null>(null);
  const resultsRef = useRef<HTMLDivElement | null>(null);
  const searchBoxRef = useRef<HTMLInputElement | null>(null);
  const enableCrossSubjectBrowsing = context === 'task';

  const { addBreadcrumb, breadcrumbsComponent, breadcrumbsState } = useBreadcrumbs({
    breadcrumbs: [
      ...(enableCrossSubjectBrowsing
        ? [
            {
              screen: 'select-year',
              label: 'All subjects',
              queryVariables: {
                subscriptionId,
              },
            },
            omitLevelIdForRetailUsers(isRetailPlan, {
              screen: 'select-subject',
              label: yearLevelName,
              queryVariables: {
                subscriptionId,
                levelId: yearLevelId,
              },
            }),
          ]
        : []),
      omitLevelIdForRetailUsers(isRetailPlan, {
        screen: 'select-module',
        label: subjectName,
        queryVariables: {
          subscriptionId,
          subjectCode: defaultSubjectCode,
          levelId: yearLevelId,
        },
      }),
    ],
    context,
    isRetailPlan,
    isStudent,
  });

  const currentSelector = _.last(breadcrumbsState);

  // Only allow autocomplete in the context of a subject
  const subjectCode =
    currentSelector.screen === 'select-module' || currentSelector.screen === 'select-lessons'
      ? currentSelector.queryVariables.subjectCode
      : null;

  const [upgradeModal, createShowModal] = useUpgradePrompt({
    shouldRequestUpgrade: isSchoolPlan,
    subscriptionId: Number(subscriptionId),
  });
  const showModal = createShowModal();

  const autocomplete = useContentAutocomplete({
    subjectCode,
    resultsRef,
  });

  const rootProps = autocomplete && autocomplete.getters.getRootProps({});

  return (
    <>
      <Flex
        direction="column"
        height="sizeFull"
        marginTop="spacingRoot"
        gap="spacingRoot"
        overflow="hidden"
        borderBottomLeftRadius="borderRadiusRoot"
        borderBottomRightRadius="borderRadiusRoot"
        {...rootProps}
      >
        {breadcrumbsComponent && <Box marginInline="spacingLarge">{breadcrumbsComponent}</Box>}

        {autocomplete && (
          <Box marginInline="spacingLarge">
            <ContentSearchBox
              autocomplete={autocomplete}
              formId={formId}
              formRef={formRef}
              searchBoxRef={searchBoxRef}
            />
          </Box>
        )}

        {autocomplete && autocomplete.query.length > 2 ? (
          <ContentSearchResults
            autocomplete={autocomplete}
            formRef={formRef}
            resultsRef={resultsRef}
            searchBoxRef={searchBoxRef}
            isFreePlan={isFreePlan}
            getLessonUrl={getLessonUrl}
            showUpgradeModal={() => showModal()}
            classId={classId}
            context={context}
            onAssignContent={onAssignContent}
          />
        ) : (
          <ScrollWithFade>
            <ContentCatalog
              addBreadcrumb={addBreadcrumb}
              context={context}
              classId={classId}
              isStudent={isStudent}
              onAssignContent={onAssignContent}
              selector={currentSelector}
              showUpgradeModal={() => showModal()}
            />
          </ScrollWithFade>
        )}
      </Flex>
      {upgradeModal}
    </>
  );
}

export default function FindContentDialogContainer(props: FindContentDialogContainerProps) {
  const { classId, subscriptionId } = props;

  const { data, loading, error, refetch } = useGetFindContentDialog({ subscriptionId, classId });

  if (loading) {
    return (
      <>
        <Flex direction="column" marginInline="spacingLarge" marginBlock="spacingRoot" gap="spacingRoot">
          <Skeleton height={28} width={200} animation="wave" marginBottom="spacingRoot" />
          <Skeleton height={45} width="sizeFull" animation="wave" />
        </Flex>
        <DividerStackLoader />
      </>
    );
  }

  if (error) {
    return (
      <Flex height="sizeFull" justifyContent="center" alignItems="center">
        <ContentCatalogError content="content" refetch={refetch} />
      </Flex>
    );
  }

  invariant(data, 'FindContentDialog data should be defined');

  return <FindContentDialog {...data} {...props} />;
}
