// @flow
import classnames from 'classnames/bind';

import Link from 'src/components/Link/Link';
import { trackingCtas, trackingLocations } from 'src/constants/tracking';
import logoNonMobile from 'src/assets/images/atomi-logo.svg';
import logoMobile from 'src/assets/images/atomi-logo-text-only-blue.svg';

import styles from './Logo.module.scss';

// TODO: https://hschub.atlassian.net/browse/CAR-786

const cx = classnames.bind(styles);

type Props = {
  isEmbed?: boolean,
  isMobile?: boolean,
};

export default function Logo(props: Props) {
  const { isMobile = true, isEmbed } = props;
  const image = <img alt="Atomi logo" src={isMobile ? logoMobile : logoNonMobile} width={isMobile ? '57' : '97'} />;

  return isEmbed ? (
    image
  ) : (
    <Link
      className={cx('root', isMobile ? 'isMobile' : 'isNonMobile')}
      to="/"
      trackingData={{
        cta: trackingCtas.navigateToHome,
        location: trackingLocations.header,
      }}
    >
      {image}
    </Link>
  );
}
