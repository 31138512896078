// @flow
import { AlertDialog } from '@getatomi/neon';
import _ from 'lodash';

type Props = {
  action: 'make admin' | 'remove admin',
};

export default function AlertDialogMakeUserAdmin(props: Props) {
  const { action, ...otherProps } = props;
  const isMakeAdminAction = action === 'make admin';

  return (
    <AlertDialog
      {...otherProps}
      variant="danger"
      heading={isMakeAdminAction ? 'You’re about to make 1 user an admin' : 'Remove admin privileges'}
      onConfirmLabel={_.upperFirst(action)}
    >
      {isMakeAdminAction
        ? `Admins on a school account can: Manage all the school account settings; and manage admin permissions for
      other users.`
        : `Are you sure you want to remove admin privileges for this user? They'll no longer be able to manage your
      school account settings or admin permissions for other users.`}
    </AlertDialog>
  );
}
