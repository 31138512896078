// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';
import _ from 'lodash';

import type { GetAllSubjects, GetAllSubjectsVariables } from 'src/graphql/types/generated/GetAllSubjects';
import sortYearLevels from 'src/utils/sortYearLevels';

import GET_ALL_SUBJECTS from './GetAllSubjects.graphql';

type YearLevel = {|
  count: number,
  id: string,
  isLocked: boolean,
  name: string,
|};

type Output = {|
  error: ?ApolloError,
  levels: ?$ReadOnlyArray<YearLevel>,
  loading: boolean,
|};

export type SelectYearVariables = {|
  subscriptionId: string,
|};

export default function useGetLevels(props: SelectYearVariables): Output {
  const { subscriptionId } = props;

  const { data, error, loading } = useQuery<GetAllSubjects, GetAllSubjectsVariables>(GET_ALL_SUBJECTS, {
    variables: {
      accountId: subscriptionId,
    },
  });

  if (error) {
    return {
      error,
      loading: false,
      levels: null,
    };
  }

  if (loading && !data) {
    return {
      loading: true,
      error: null,
      levels: null,
    };
  }

  const subjectData = data?.me?.account.subjects;
  invariant(subjectData, 'Subject data should be defined');

  const groupedLevels = _.groupBy(subjectData, (subject) => subject.level.id);
  const levels = Object.keys(groupedLevels).map((levelId) => {
    const count = groupedLevels[levelId].length;
    const { level } = groupedLevels[levelId][0];
    const isLocked = !_.some(groupedLevels[levelId], (subject) => subject.__typename === 'LevelSubject');
    return {
      id: level.id,
      isLocked,
      name: level.name,
      count,
    };
  });
  const sortedLevels = sortYearLevels(levels, 'asc');

  return {
    levels: sortedLevels,
    loading: false,
    error: null,
  };
}
