// @flow

import { type NavigationContext } from 'src/utils/navigationContext';
import { getClassTasksUrl, getTasksUrl } from 'src/utils/routes';

import { type LinkProps } from '../types';
import { makeBackLinkProps } from '../makeBackLinkProps';

export function makeContinueLinkProps({
  accountId,
  classId,
  isTeacher,
  navigationContext,
}: {|
  accountId: string,
  classId: string,
  isTeacher: boolean,
  navigationContext: NavigationContext | null,
|}): LinkProps {
  if (navigationContext) {
    return makeBackLinkProps(navigationContext);
  }

  if (isTeacher) {
    return {
      to: getClassTasksUrl(accountId, classId),
      label: 'Tasks',
    };
  }

  return {
    to: getTasksUrl(accountId),
    label: 'Tasks',
  };
}
