// @flow
import { formatPercentageAsString } from 'src/utils/formatPercentage';

type CohortVariant = 'needHelp' | 'goingWell';

type CohortThresholds = {
  goingWellThreshold: number,
  needsHelpThreshold: number,
};

export default function getHeadingText(variant: CohortVariant, thresholds: CohortThresholds) {
  return variant === 'needHelp'
    ? `Scoring less than ${formatPercentageAsString(thresholds.needsHelpThreshold)}`
    : `Scoring above ${formatPercentageAsString(thresholds.goingWellThreshold)}`;
}
