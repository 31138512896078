// @flow
import { useState } from 'react';
import { AlertDialog } from '@getatomi/neon';

type Props = {|
  autoFocusButton?: 'confirm' | 'dismiss',
  children?: React.Node,
  closeOnConfirm?: boolean,
  heading: React.Node,
  isDismissable?: boolean,
  isLoading?: boolean,
  isOpen?: boolean,
  onConfirm?: () => Promise<any>,
  onConfirmLabel: string,
  onDismiss?: () => void,
  onDismissLabel?: string,
  variant?: 'danger' | 'success',
|};

export default function useAlertDialog(props: ?false | Props): [?React.Node, () => void] {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (!props) return [null, () => {}];

  const { onConfirm, onDismiss, closeOnConfirm = true, isDismissable = true, ...otherProps } = props;

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    if (closeOnConfirm) {
      setIsOpen(false);
    }
  };
  const handleDismiss = () => {
    if (onDismiss) onDismiss();
    setIsOpen(false);
  };

  const toggleAlertDialog = () => setIsOpen((prevState) => !prevState);

  const alertDialog = (
    <AlertDialog
      {...otherProps}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onDismiss={isDismissable ? handleDismiss : undefined}
    />
  );

  return [alertDialog, toggleAlertDialog];
}
