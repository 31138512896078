// @flow
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Divider, Heading, HelpInfo, Modal, Stack, Text, useId } from '@getatomi/neon';

import type { BoundAction, ReduxState } from 'src/types';
import Logger from 'src/utils/Logger';
import ModalActions from 'src/components/deprecated/ModalActions/ModalActions';
import Link from 'src/components/Link/Link';
import { activateSetupMode, deactivateSetupMode } from 'src/actions/subscriptions';
import { isActiveSubscriptionInSetupMode } from 'src/reducers/subscriptions';
import links from 'src/constants/links';

const log = new Logger('domains/Settings/SchoolAccount/ToggleSetupModeDialog');

type Props = {
  isOpen: boolean,
  onClose: () => mixed,
};

const mapStateToProps = (state: ReduxState) => ({
  isSetupModeActive: isActiveSubscriptionInSetupMode(state),
});

type InjectedProps = Props & {
  activateSetupModeAction: BoundAction<typeof activateSetupMode>,
  deactivateSetupModeAction: BoundAction<typeof deactivateSetupMode>,
  isSetupModeActive: boolean,
};

function ToggleSetupModeDialog(props: InjectedProps) {
  const { isOpen, isSetupModeActive, onClose } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isMountedRef = useRef<boolean>(false);
  const headingId = useId();

  const onSubmit = async () => {
    const { activateSetupModeAction, deactivateSetupModeAction } = props;

    setIsLoading(true);

    try {
      if (isSetupModeActive) {
        await deactivateSetupModeAction();
      } else {
        await activateSetupModeAction();
      }
      if (isMountedRef.current) {
        setIsLoading(false);
        onClose();
      }
    } catch (error) {
      if (isMountedRef.current) {
        setIsLoading(false);
        log.info('Form submission: toggling setup mode failed', error);
      }
    }
  };

  const closeModal = () => {
    isMountedRef.current = false;
    onClose();
  };

  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  return (
    <Modal ariaLabelledBy={headingId} isOpen={isOpen} onClose={closeModal} size="fullscreen" variant="view">
      <Container maxWidth="sizeContainerRoot" textAlign="center">
        <Stack spacing="spacingLarge5X">
          <Stack spacing="spacingSmall">
            <Heading as="h1" id={headingId}>
              {isSetupModeActive ? 'Turn off setup mode' : 'Turn on setup mode'}
            </Heading>
            <Text variant="lead">
              {isSetupModeActive
                ? 'By turning off setup mode all account invitations and notifications will go out to the users you invited during setup mode! Ready to turn it off?'
                : 'By turning on setup mode all account invitations or notifications will be suppressed for new users, giving you time to add them to their classes and set up your school account. Once you’re done just turn it off and all invitations will be sent out in one go!'}
            </Text>
          </Stack>
          <Divider />
          <Text>
            {isSetupModeActive ? (
              <>
                Account invitations will now be sent out to all new users invited during setup mode;
                <br />
                any existing users will not be affected.
              </>
            ) : (
              `No account invitations or notifications will be sent out to new users invited during setup mode; Setup mode will NOT affect access or notifications for your existing users.`
            )}
          </Text>
          <ModalActions
            isLoading={isLoading}
            cancelLabel={isSetupModeActive ? 'No, take me back to safety' : 'No, thanks'}
            submitLabel={isSetupModeActive ? 'I understand, turn it off' : 'I understand, turn it on'}
            submitVariant={isSetupModeActive ? 'danger' : 'success'}
            isStacked
            onCancel={closeModal}
            onSubmit={onSubmit}
            testHook="toogle-setup-mode-actions"
          />
          <HelpInfo>
            Learn more about{' '}
            <Link href={links.support.setupMode} isExternal variant="monochrome">
              setup mode
            </Link>{' '}
            in the Help Centre
          </HelpInfo>
        </Stack>
      </Container>
    </Modal>
  );
}

export default (connect(mapStateToProps, {
  activateSetupModeAction: activateSetupMode,
  deactivateSetupModeAction: deactivateSetupMode,
})(ToggleSetupModeDialog): React.AbstractComponent<Props>);
