// @flow
import { useCallback } from 'react';
import {
  ActionBar,
  Box,
  Flex,
  Heading,
  HorizontalScroll,
  Stack,
  Table,
  useMediaQuery,
  useTableSelection,
} from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import { type SortBy, initialTableState } from 'src/hooks/useSortedStudents';
import useTaskDialog from 'src/components/useTaskDialog/useTaskDialog';

type RequiredColumnProps = { accessorKey: string };

type StudentTableProps<TData, TColumn> = {
  columns: $ReadOnlyArray<TColumn & RequiredColumnProps>,
  data: TData,
  taskDialogProps: {
    accountId: string,
    classId: string,
  },
  toggleSortBy?: (value: Array<SortBy>) => void,
};

export default function StudentTable<TData, TColumn>(props: StudentTableProps<TData, TColumn>) {
  const { columns, data, taskDialogProps, toggleSortBy } = props;
  const isSortable = Boolean(toggleSortBy);

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  // row.id isn't set in this data, so we use rowMeta.id instead
  const getIdentifier = useCallback((row) => row.rowMeta.id, []);
  const { resetSelection, selectedIds, tableProps } = useTableSelection({
    data,
    initialState: initialTableState,
    getIdentifier,
  });

  const [taskDialog, { openTaskDialog }] = useTaskDialog({
    subscriptionId: taskDialogProps.accountId,
    classId: taskDialogProps.classId,
    onSubmit: resetSelection,
  });

  return (
    <>
      <Stack as="section" spacing="spacingLarge5X">
        <Flex justifyContent="space-between">
          <Heading as="h3" variant="large">
            Students
          </Heading>

          <Button onClick={() => openTaskDialog()} size="small" variant="secondary">
            Create task
          </Button>
        </Flex>

        <Box overflow="hidden">
          <HorizontalScroll forwardScrollPosition>
            <Table
              {...tableProps}
              isFixedLayout
              isStickyHeadings
              columns={columns}
              data={data}
              isSortable={isSortable}
              toggleSortBy={toggleSortBy}
            >
              <colgroup data-test="colgroup">
                <col data-test="col" key="select" style={{ width: 50 }} />
                {columns.map((column) => {
                  const { accessorKey } = column;

                  let width;
                  switch (accessorKey) {
                    case 'rowMeta':
                      width = isMobile ? 160 : 'auto';
                      break;
                    case 'averageMarkPercentage':
                      width = 70;
                      break;
                    default:
                      width = 160;
                  }

                  return <col data-test="col" key={accessorKey} style={{ width }} />;
                })}
              </colgroup>
            </Table>
          </HorizontalScroll>
        </Box>
      </Stack>
      <ActionBar selectedItemCount={selectedIds.length} onClearSelection={resetSelection}>
        <Button onClick={() => openTaskDialog({ students: selectedIds })}>Create task</Button>
      </ActionBar>
      {taskDialog}
    </>
  );
}
