// @flow
import { get, omit } from 'lodash';

import {
  UPLOAD_AVATAR,
  UPLOAD_AVATAR_SUCCESS,
  UPLOAD_AVATAR_FAIL,
  LOAD_AVATAR,
  LOAD_AVATAR_SUCCESS,
  LOAD_AVATAR_FAIL,
  DELETE_AVATAR,
  DELETE_AVATAR_SUCCESS,
  DELETE_AVATAR_FAIL,
} from 'src/actions/avatars';
import type { AvatarSizesType } from 'src/types';

export type AvatarsState = {
  isDeletingAvatar: boolean,
  isUploadingAvatar: false,
  urls: Object,
};

type StateType = {
  avatars: Object,
};

const initialState = {
  urls: {},
  isUploadingAvatar: false,
  isDeletingAvatar: false,
};

export default function avatarsReducer(state: AvatarsState = initialState, action: Object = {}) {
  switch (action.type) {
    case LOAD_AVATAR: {
      const { userId, size } = action.data;

      return {
        ...state,
        urls: {
          ...state.urls,
          [userId]: {
            ...state.urls[userId],
            [size]: {
              isLoading: true,
            },
          },
        },
      };
    }

    case LOAD_AVATAR_SUCCESS: {
      const { userId, size } = action.data;

      return {
        ...state,
        urls: {
          ...state.urls,
          [userId]: {
            ...state.urls[userId],
            [size]: {
              ...state.urls[userId][size],
              isLoading: false,
              url: action.result.avatar_url,
            },
          },
        },
      };
    }

    case LOAD_AVATAR_FAIL: {
      const { userId, size } = action.data;

      return {
        ...state,
        urls: {
          ...state.urls,
          [userId]: {
            ...state.urls[userId],
            [size]: {
              ...state.urls[userId][size],
              isLoading: false,
            },
          },
        },
      };
    }

    case UPLOAD_AVATAR:
      return {
        ...state,
        isUploadingAvatar: true,
      };
    case UPLOAD_AVATAR_FAIL:
      return {
        ...state,
        isUploadingAvatar: false,
      };

    case UPLOAD_AVATAR_SUCCESS: {
      const { avatar_url: url, size } = action.result;
      const { userId } = action.data;

      return {
        ...state,
        isUploadingAvatar: false,
        urls: {
          ...state.urls,
          [userId]: {
            [size]: {
              ...get(state.urls, `${userId}.${size}`, {}),
              isLoading: false,
              url,
            },
          },
        },
      };
    }

    case DELETE_AVATAR:
      return {
        ...state,
        isDeletingAvatar: true,
      };

    case DELETE_AVATAR_FAIL:
      return {
        ...state,
        isDeletingAvatar: false,
      };

    case DELETE_AVATAR_SUCCESS:
      return {
        ...state,
        isDeletingAvatar: false,
        urls: {
          ...omit(state.urls, action.data.userId),
        },
      };

    default:
      return state;
  }
}

// Selectors
export const isLoadingAvatar = (state: StateType, userId: number, size: AvatarSizesType) =>
  get(state.avatars.urls, `${userId}.${size}.isLoading`);
export const isUploadingAvatar = (state: StateType) => state.avatars.isUploadingAvatar;
export const isDeletingAvatar = (state: StateType) => state.avatars.isDeletingAvatar;

export const getAvatarsByUser = (state: StateType, userId: number, size: AvatarSizesType) => {
  if (!size) return null;

  return get(state.avatars.urls, `${userId}.${size}.url`);
};
