// @flow
import type { Store } from 'redux';
import { IndexRedirect, Redirect, Route } from 'react-router';
import { createRoutes } from 'react-router/lib/RouteUtils';
import type Cookies from 'universal-cookie';
import { pick } from 'lodash';

import insightTypes from 'src/constants/insightTypes';
import Logger from 'src/utils/Logger';
import fetchDataMiddleware from 'src/middleware/fetchDataMiddleware';
import { loadUserDetails } from 'src/actions/auth';
import { setActiveSubscription, setActiveSubscriptionFailed } from 'src/actions/subscriptions';
import { isLoggedInAsSuperAdmin, isUserLoggedIn } from 'src/reducers/auth';
import {
  getActiveSubscription,
  getActiveSubscriptionId,
  getActiveSubscriptionPlanCode,
  getActiveSubscriptionsCount,
  getActiveSubscriptionStatus,
  getUserRole,
  hasClasses,
  isFamilyPlan,
  isFreeFamilyPlan,
  isFreeStudentPlan,
  isInsightsEnabled,
  isLoggedInAsAccountManager,
  isLoggedInAsParent,
  isLoggedInAsStudent,
  isPaidRetailPlan,
  isPaidStudentPlan,
  isSchoolPlan,
  isSubscriptionCancelled,
} from 'src/reducers/subscriptions';
import Auth from 'src/utils/Auth';
import { trackUser } from 'src/utils/sentry';
import { identifyLoggedInUser } from 'src/utils/tracking';
import {
  getDashboardUrl,
  getExpiredUrl,
  getLoginUrl,
  getLoginUrlWithRedirect,
  getSubjectsSelectionUrl,
  getTasksUrl,
} from 'src/utils/routes';
import AuthenticatedLayout from 'src/layouts/AuthenticatedLayout/AuthenticatedLayout';
// Domains
import Admin from 'src/domains/Admin/Admin';
import App from 'src/domains/App/App';
import Class from 'src/domains/Class/Class';
import ClassInsights from 'src/domains/ClassInsights/Insights';
import ClassTopicInsights from 'src/domains/ClassInsights/Topics/TopicInsights';
import ClassSubject from 'src/domains/Class/ClassSubject';
import ClassUsers from 'src/domains/Class/ClassUsers';
import ClassTasksContainer from 'src/domains/Tasks/ClassTasks/ClassTasksContainer';
import CloseWindow from 'src/domains/CloseWindow/CloseWindow';
import Dashboard from 'src/domains/Dashboard/Dashboard';
import ErrorPage from 'src/domains/ErrorPage/ErrorPage';
import Expired from 'src/domains/Expired/Expired';
import ForgotPassword from 'src/domains/ForgotPassword/ForgotPassword';
import Insights from 'src/domains/Insights/Insights';
import Login from 'src/domains/Login/Login';
import MarkbookCompletionContainer from 'src/domains/Markbook/Completion/CompletionContainer';
import MarkbookMarksContainer from 'src/domains/Markbook/Marks/MarksContainer';
import MarkbookTasksContainer from 'src/domains/Markbook/Tasks/TasksContainer';
import Module from 'src/domains/Module/Module';
import Post from 'src/domains/Post/Post';
import LessonLocations from 'src/domains/LessonLocations/LessonLocations';
import QuizReportDialogOverview from 'src/domains/ViewingQuizResults/ChallengeOverviewContainer';
import RevisionOverviewContainer from 'src/domains/ViewingQuizResults/RevisionOverviewContainer';
import QuizReportDialogMarks from 'src/domains/ViewingQuizResults/ChallengeMarksContainer';
import RevisionMarksContainer from 'src/domains/ViewingQuizResults/RevisionMarksContainer';
import ResetPassword from 'src/domains/ForgotPassword/ResetPassword/ResetPassword';
import StudentRevisionPage from 'src/domains/Revision/StudentRevisionPage';
import Revision from 'src/domains/Revision/Revision';
import SocialLogin from 'src/domains/Login/SocialLogin/SocialLogin';
import StudentTasksContainer from 'src/domains/Tasks/StudentTasks/StudentTasksContainer';
import Subscriptions from 'src/domains/Subscriptions/Subscriptions';
import Task from 'src/domains/Tasks/Task/Task';
import TaskProgressReport from 'src/domains/Tasks/TaskProgressReport/TaskProgressReportContainer';
import Upgrade from 'src/domains/Upgrade/Upgrade';
import UpgradeSuccess from 'src/domains/UpgradeSuccess/UpgradeSuccess';
import UnsupportedBrowser from 'src/domains/UnsupportedBrowser/UnsupportedBrowser';
// Settings
import Billing from 'src/domains/Settings/Billing/Billing';
import ChangePassword from 'src/domains/Settings/ChangePassword/ChangePassword';
import Profile from 'src/domains/Settings/Profile/Profile';
import Settings from 'src/domains/Settings/Settings';
import SchoolAccount from 'src/domains/Settings/SchoolAccount/SchoolAccount';
import Subjects from 'src/domains/Settings/Subjects/Subjects';
import Users from 'src/domains/Settings/Users/Users';
import Wonde from 'src/domains/Settings/Wonde/Wonde';
import SamlSso from 'src/domains/Settings/SamlSso/SamlSso';
// LTI integration
import LTISelectClass from 'src/domains/LTI/SelectClass/SelectClass';
import LTISelectLessons from 'src/domains/LTI/SelectLessons/SelectLessons';
import LTISelectModule from 'src/domains/LTI/SelectModule/SelectModule';
// Registration routes
import ConfirmUser from 'src/domains/Registration/ConfirmUser/ConfirmUser';
import RegistrationLanding from 'src/domains/Registration/RegistrationLanding/RegistrationLanding';
import RegistrationStep1 from 'src/domains/Registration/RegistrationCommon/RegistrationStep1';
import RegistrationStudentStep2 from 'src/domains/Registration/RegistrationStudent/RegistrationStudentStep2';
import RegistrationStudentSubjects from 'src/domains/Registration/RegistrationStudent/RegistrationStudentSubjects';
import RegistrationTeacherStep2 from 'src/domains/Registration/RegistrationTeacher/RegistrationTeacherStep2';
import RegistrationParentStep2 from 'src/domains/Registration/RegistrationParent/RegistrationParentStep2';
import RegistrationParentStep3 from 'src/domains/Registration/RegistrationParent/RegistrationParentStep3';
import RegistrationTeacherStep3 from 'src/domains/Registration/RegistrationTeacher/RegistrationTeacherStep3';
import RegistrationInvitedUser from 'src/domains/Registration/RegistrationInvitedUser/RegistrationInvitedUser';
import RegistrationInvitedUserStep2 from 'src/domains/Registration/RegistrationInvitedUser/RegistrationInvitedUserStep2';
import RegistrationWelcome from 'src/domains/Registration/RegistrationWelcome/RegistrationWelcome';

import createRouterMiddleware from './createRouterMiddleware';

type Query = {
  [key: string]: string,
};

const log = new Logger('routes');

export default (store: Store<*, *, *>, universalCookies?: Cookies, query?: Query = {}) => {
  const auth = new Auth(universalCookies);

  const redirectToDashboard = (subscriptionId, state) => {
    const userRole = getUserRole(state);
    return getDashboardUrl(subscriptionId, { userRole });
  };

  const redirectToIndex = () => '/?redirected=true';

  // middleware which checks that the user is logged in
  const requireLogin = async (nextState, replace, cb) => {
    const state = store.getState();

    // user isn't logged in
    if (!isUserLoggedIn(state)) {
      // try to load user details
      try {
        await store.dispatch(loadUserDetails());
      } catch (err) {
        // user details could not be retrieved
        log.warn('Routes: navigation to restricted page prevented, log user out', err);
        // logout the user (remove invalid access_token)
        auth.logout();
        replace(getLoginUrlWithRedirect(nextState.location));
        return cb();
      }
    }

    // check for subscription id in url and save it as current one
    const { subscriptionId } = nextState.params;
    const currentSubscription = getActiveSubscription(store.getState());
    if (subscriptionId && currentSubscription && parseInt(subscriptionId, 10) !== currentSubscription.id) {
      log.info(
        `Subscription id ${subscriptionId} found in url is different from the last active one ${currentSubscription.id}`
      );
      if (auth.getIsAdmin() || auth.getAuthorizedSubscriptions().find((s) => s.id === parseInt(subscriptionId, 10))) {
        log.info(`Setting active subscription id to ${subscriptionId}`);
        await store.dispatch(setActiveSubscription(parseInt(subscriptionId, 10)));
      } else {
        log.info(`User is not authorised for subscription id ${subscriptionId}. Redirecting to 403 page.`);
        await store.dispatch(setActiveSubscriptionFailed(nextState.location.pathname));
      }
    }

    // send user details to all third parties
    const {
      auth: { user },
    } = store.getState();
    trackUser(user, currentSubscription);
    identifyLoggedInUser({
      ...pick(user, ['id', 'email', 'intercom_hashed_id', 'is_free']),
      activePlanCode: getActiveSubscriptionPlanCode(store.getState()),
      activePlanStatus: getActiveSubscriptionStatus(store.getState()),
      activeRole: getUserRole(store.getState()),
      activeSubscriptionId: getActiveSubscriptionId(store.getState()),
    });

    cb();
  };

  // middleware which checks that a retail student has an incompleted registration
  const requireIncompletedRegistration = (nextState, replace, cb) => {
    const state = store.getState();

    const isRetailStudent = isFreeStudentPlan(state) || isPaidStudentPlan(state);
    const isFamilyChild = isFamilyPlan(state) && isLoggedInAsStudent(state);
    const hasSubjects = hasClasses(state);

    if (!isRetailStudent && !isFamilyChild) {
      // not a retail user
      replace(redirectToIndex());
    } else if (hasSubjects) {
      // a retail user with classes
      replace(redirectToIndex());
    }

    cb();
  };

  // middleware which checks that a retail student has a complete registration
  const requireCompletedRegistration = (nextState, replace, cb) => {
    const state = store.getState();

    const isRetailStudent = isFreeStudentPlan(state) || isPaidStudentPlan(state);
    const isFamilyChild = isFamilyPlan(state) && isLoggedInAsStudent(state);
    const hasSubjects = hasClasses(state);

    if ((isRetailStudent || isFamilyChild) && !hasSubjects && !isLoggedInAsAccountManager(state)) {
      const subscriptionId = getActiveSubscriptionId(state);
      replace(getSubjectsSelectionUrl(subscriptionId));
    }

    cb();
  };

  // middleware which checks that a user is not logged in
  const checkLogin = async (nextState, replace, cb) => {
    if (auth.getAccessToken()) {
      const state = store.getState();

      if (isUserLoggedIn(state)) {
        const activeSubscriptionId = getActiveSubscriptionId(state);
        replace(redirectToDashboard(activeSubscriptionId, state));
        return cb();
      }

      try {
        await store.dispatch(loadUserDetails());
        log.info('User is logged in, redirect to dashboard');
        const activeSubscriptionId = getActiveSubscriptionId(store.getState());
        replace(redirectToDashboard(activeSubscriptionId, state));
      } catch (err) {
        auth.logout();
        if (!query.redirect) {
          replace(getLoginUrl());
        }
      }
    }

    cb();
  };

  const requireFreePlan = (nextState, replace, cb) => {
    const state = store.getState();

    if (!isFreeStudentPlan(state) && !(isFreeFamilyPlan(state) && isLoggedInAsParent)) {
      log.info('User is already on a paid plan, prevented access to upgrade page and redirected to dashboard');
      replace(redirectToDashboard(getActiveSubscriptionId(state), state));
    }

    cb();
  };

  const requirePaidPlan = (nextState, replace, cb) => {
    const state = store.getState();

    if (!isPaidRetailPlan(state)) {
      log.info('User is not on a paid plan, prevented access to yearly upgrade page and redirected to dashboard');
      replace(redirectToDashboard(getActiveSubscriptionId(state), state));
    }

    cb();
  };

  const requireSchoolStudentAccount = (nextState, replace, cb) => {
    const state = store.getState();

    if (!(isLoggedInAsStudent(state) && isSchoolPlan(state))) {
      log.info('User is not a school student.');
      replace(redirectToDashboard(getActiveSubscriptionId(state), state));
    }

    cb();
  };

  const requireTeacherRoleOrAbove = (nextState, replace, cb) => {
    const state = store.getState();
    if (isLoggedInAsStudent(state)) {
      replace(redirectToDashboard(getActiveSubscriptionId(state), state));
    }
    cb();
  };

  const requireInsightsEnabled = (nextState, replace, cb) => {
    const state = store.getState();
    if (!isInsightsEnabled(state) && !isLoggedInAsSuperAdmin(state)) {
      replace(redirectToDashboard(getActiveSubscriptionId(state), state));
    }
    cb();
  };

  // middleware which checks for users whose subscription status === 'cancelled' so we can redirect
  // them to the '/expired' page
  const isUserStatusCancelled = (nextState, replace, cb) => {
    const state = store.getState();

    if (isSubscriptionCancelled(state)) {
      const activeSubscriptionId = getActiveSubscriptionId(state);
      replace(getExpiredUrl(activeSubscriptionId));
    }

    cb();
  };

  const redirectParents = (nextState, replace, cb) => {
    const state = store.getState();

    if (isLoggedInAsParent(state)) {
      const activeSubscriptionId = getActiveSubscriptionId(state);
      replace(redirectToDashboard(activeSubscriptionId, state));
    }

    cb();
  };

  // middleware which checks for users whose subscription status !== 'cancelled' so we can redirect
  // them to the dashboard
  const isUserStatusNotCancelled = (nextState, replace, cb) => {
    const state = store.getState();

    if (!isSubscriptionCancelled(state)) {
      const activeSubscriptionId = getActiveSubscriptionId(state);
      replace(redirectToDashboard(activeSubscriptionId, state));
    }

    cb();
  };

  const redirectToStudentTask = (nextState, replace, cb) => {
    const state = store.getState();
    const subscriptionId = getActiveSubscriptionId(state);

    if (isLoggedInAsStudent(state)) {
      const { ids } = nextState.location.query;
      replace(ids ? getTasksUrl(subscriptionId) : redirectToDashboard(subscriptionId, state));
    }
    cb();
  };

  const requireStudentRole = (nextState, replace, cb) => {
    const state = store.getState();
    if (!isLoggedInAsStudent(state)) {
      replace(redirectToDashboard(getActiveSubscriptionId(state), state));
    }
    cb();
  };

  const requireSuperAdminRole = (nextState, replace, cb) => {
    const state = store.getState();
    if (!isLoggedInAsSuperAdmin(state)) {
      replace(redirectToDashboard(getActiveSubscriptionId(state), state));
    }
    cb();
  };

  const redirectToLTIShareContent = (subscriptionId, postData) => {
    return { pathname: `/subscriptions/${subscriptionId}/lti/classes`, query: { postData } };
  };

  const ltiRedirect = (nextState, replace, cb) => {
    const state = store.getState();
    const subscriptionId = getActiveSubscriptionId(state);
    const { postData } = nextState.location.query;
    // If user has multiple accounts, get them to choose one
    if (getActiveSubscriptionsCount(state) > 1) {
      replace({ pathname: '/subscriptions', query: { redirect: `/lti/classes?postData=${postData}` } });
    } else {
      replace(redirectToLTIShareContent(subscriptionId, postData));
    }
    cb();
  };

  const onEnter = createRouterMiddleware(fetchDataMiddleware)(store, auth);

  const attachHandler = (routes) =>
    routes.map((route) => {
      if (route.childRoutes) {
        return { ...route, childRoutes: attachHandler(route.childRoutes) };
      }
      if (route.component) {
        return { ...route, onEnter };
      }
      return route;
    });

  return attachHandler(
    createRoutes(
      <Route path="/" component={App}>
        {/* Home (main) route is login page */}
        <IndexRedirect to="login" />

        <Route path="/register/confirm/:code" component={ConfirmUser} />
        {/* Invited user registration */}
        <Route path="/register/invite/:inviteCode" component={RegistrationInvitedUser} />
        <Route path="/register/invite/step-2/:inviteCode" component={RegistrationInvitedUserStep2} />

        {/* Login and registration (don't require auth) */}
        <Route onEnter={checkLogin}>
          <Route path="login" component={Login} />
          <Route path="/login/:provider" component={SocialLogin} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Redirect from="/register/nsw" to="register/au/nsw" />
          <Route path="/register/student/:region" component={RegistrationStep1} role="student" />
          <Route path="/register/teacher/:region" component={RegistrationStep1} role="teacher" />
          <Route path="/register/parent/:region" component={RegistrationStep1} role="parent" />
          <Route path="/register/:country(/:region)" component={RegistrationLanding} />
          <Route path="/register/student/step-2/:region" component={RegistrationStudentStep2} />
          <Route path="/register/teacher/step-2/:region" component={RegistrationTeacherStep2} />
          <Route path="/register/parent/step-2/:region" component={RegistrationParentStep2} />
          <Route path="/register/parent/step-3/:region" component={RegistrationParentStep3} />
          <Route path="/register/teacher/step-3/:region" component={RegistrationTeacherStep3} />
        </Route>

        {/* Reset password route can be accessed when both logged-in or logged-out */}
        <Route path="/reset-password/:token" component={ResetPassword} />

        {/* An interstitial page to show login success when logging in from an iframe */}
        <Route path="/closeWindow" component={CloseWindow} />

        <Route component={AuthenticatedLayout} onEnter={requireLogin}>
          {/* User with multiple subscriptions */}
          <Route
            path="/subscriptions"
            component={Subscriptions}
            layout={{
              withFooter: false,
              withTrialExpiry: false,
              withSecondaryBackgroundColor: true,
            }}
          />
          <Route
            path="/posts/:postId"
            component={LessonLocations}
            layout={{
              withFooter: false,
              withTrialExpiry: false,
            }}
          />
          <Route onEnter={requireSuperAdminRole}>
            <Route path="/admin" component={Admin} />
          </Route>
          <Route path="/lti/share-content" onEnter={ltiRedirect} />
        </Route>

        {/* Subscription pages */}
        <Route path="/subscriptions/:subscriptionId" onEnter={requireLogin}>
          <IndexRedirect to="classes" />

          <Route path="switch/:paymentFrequency/success" component={UpgradeSuccess} />
          <Route path="upgrade/success" component={UpgradeSuccess} />
          <Route path="welcome" component={RegistrationWelcome} />

          <Route component={AuthenticatedLayout}>
            <Route onEnter={isUserStatusCancelled}>
              <Route onEnter={requireCompletedRegistration}>
                <Route onEnter={redirectParents}>
                  <Route path="classes/:classId/revisions/:revisionId" component={Revision} />
                  <Route path="classes/:classId/tasks/:taskId" component={Task} layout={{ withMobileBackLink: true }} />
                  <Route path="classes" component={Dashboard} />
                  <Route component={Class}>
                    <Route path="classes/:classId" component={ClassSubject} />
                    <Route onEnter={requireTeacherRoleOrAbove}>
                      <Route path="classes/:classId/users/:userType" component={ClassUsers} />
                      <Route path="classes/:classId/insights" component={ClassInsights} />
                      <Route path="classes/:classId/insights/topics/:topicId" component={ClassTopicInsights} />
                      <Route path="classes/:classId/markbook-completion" component={MarkbookCompletionContainer} />
                      <Route path="classes/:classId/markbook-marks" component={MarkbookMarksContainer} />
                      <Route path="classes/:classId/markbook-tasks" component={MarkbookTasksContainer} />
                    </Route>
                    <Route onEnter={redirectToStudentTask}>
                      <Route path="classes/:classId/tasks" component={ClassTasksContainer} />
                    </Route>
                  </Route>
                </Route>
                <Route path="insights" onEnter={requireInsightsEnabled}>
                  <IndexRedirect to={insightTypes.questions} />
                  <Route path=":insightType" component={Insights} />
                </Route>
                <Route onEnter={requireStudentRole}>
                  <Route path="revision" component={StudentRevisionPage} />
                </Route>
              </Route>
            </Route>
            {/* Settings */}
            <Route path="settings" component={Settings}>
              <IndexRedirect to="profile" />
              <Route path="profile" component={Profile} />
              <Route path="password" component={ChangePassword} />
              <Route path="school" component={SchoolAccount} />
              <Route onEnter={redirectParents}>
                <Route path="subjects" component={Subjects} />
              </Route>
              <Route path="users" component={Users} />
              <Route path="billing" component={Billing} />
              <Route onEnter={requireSuperAdminRole}>
                <Route path="wonde" component={Wonde} />
                <Route path="saml-sso" component={SamlSso} />
              </Route>
            </Route>

            {/* LTI Intergration */}
            <Route path="lti/classes" component={LTISelectClass} layout={{ withNav: false, withUserDropdown: false }} />
            <Route
              path="lti/classes/:classId/modules/:moduleId"
              component={LTISelectLessons}
              layout={{ withMobileBackLink: true, withNav: false, withUserDropdown: false }}
            />
            <Route
              path="lti/classes/:classId"
              component={LTISelectModule}
              layout={{ withMobileBackLink: true, withNav: false, withUserDropdown: false }}
            />
            <Route
              path="lti/classes/:classId/modules/:moduleId/posts/:postId(/**)"
              component={Post}
              layout={{ withMobileBackLink: true, withNav: false, withUserDropdown: false }}
            />

            <Route onEnter={isUserStatusCancelled}>
              <Route onEnter={redirectParents}>
                {/* Subjects selection after initial registration */}
                <Route onEnter={requireIncompletedRegistration}>
                  <Route
                    path="subjects-selection"
                    component={RegistrationStudentSubjects}
                    layout={{ withNav: false, withUpgradeCta: false }}
                  />
                </Route>
                <Route
                  path="classes/:classId/modules/:moduleId"
                  component={Module}
                  layout={{ withMobileBackLink: true }}
                />
                <Route onEnter={requireTeacherRoleOrAbove}>
                  <Route
                    path="classes/:classId/modules/:moduleId/posts/:postId/overview"
                    component={QuizReportDialogOverview}
                  />
                  <Route path="classes/:classId/revisions/:revisionId/overview" component={RevisionOverviewContainer} />
                  <Route
                    path="classes/:classId/modules/:moduleId/posts/:postId/marks"
                    component={QuizReportDialogMarks}
                  />
                  <Route path="classes/:classId/revisions/:revisionId/marks" component={RevisionMarksContainer} />
                  <Route path="classes/:classId/tasks/:taskId/progress" component={TaskProgressReport} />
                </Route>
                <Route path="classes/:classId/revisions/:revisionId(/**)" component={Revision} />
                <Route
                  path="classes/:classId/modules/:moduleId/posts/:postId(/**)"
                  component={Post}
                  layout={{ withContextualMobilePostBackLink: true }}
                />
              </Route>
              <Route onEnter={requireFreePlan}>
                <Route
                  path="upgrade"
                  component={Upgrade}
                  layout={{ withNav: false, withUpgradeCta: false, withUserDropdown: false }}
                />
              </Route>
              <Route onEnter={requireSchoolStudentAccount}>
                <Route path="tasks" component={StudentTasksContainer} />
              </Route>
            </Route>
            <Route onEnter={requirePaidPlan}>
              <Route
                path="switch/:paymentFrequency"
                component={Upgrade}
                layout={{ withNav: false, withUpgradeCta: false, withUserDropdown: false }}
              />
            </Route>
            {/* Expired (when a user’s subscription status === 'cancelled') */}
            <Route onEnter={isUserStatusNotCancelled}>
              <Route
                path="expired"
                component={Expired}
                layout={{
                  isMainContentVerticallyCentered: true,
                  withFooter: false,
                  withSecondaryBackgroundColor: true,
                  withUpgradeCta: false,
                }}
              />
            </Route>
          </Route>
        </Route>
        <Route path="/unsupported-browser" component={UnsupportedBrowser} />
        <Route path="error/:errorCode" component={ErrorPage} />
        {/* Catch all route */}
        <Route path="*" component={ErrorPage} status={404} />
      </Route>
    )
  );
};
