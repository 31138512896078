// @flow
import _ from 'lodash';

import type { APIResultAction, IsoCode, ReduxState, Region, RegionCode } from 'src/types';
import { LOAD_REGION_SUCCESS, LOAD_REGION_FAIL } from 'src/actions/regions';
import isoCodes from 'src/constants/isoCodes';

export type RegionsState = {
  allocatedCountryCode: ?IsoCode,
  entities: {
    [code: string]: Region,
  },
  geolocatedCountryCode: ?IsoCode,
  isInSupportedRegion: boolean,
  loaded: boolean,
  routeError: ?{
    message: string,
    status: number,
  },
};

type LoadRegionActionSuccess = APIResultAction<'LOAD_REGION_SUCCESS', Region>;
type LoadRegionActionFail = APIResultAction<'LOAD_REGION_FAIL', Region>;
type Action = LoadRegionActionSuccess | LoadRegionActionFail;

const initialState = {
  allocatedCountryCode: null,
  geolocatedCountryCode: null,
  entities: {},
  isInSupportedRegion: false,
  loaded: false,
  routeError: null,
};

export default function regionsReducer(state: RegionsState = initialState, action: Action) {
  switch (action.type) {
    case LOAD_REGION_SUCCESS:
      return {
        ...state,
        loaded: true,
        entities: _.merge({}, state.entities, action.result.entities.region),
        routeError: null,
      };
    case LOAD_REGION_FAIL:
      return {
        ...state,
        loaded: false,
        routeError: action.routeError,
      };
    default:
      return state;
  }
}

// Selectors
export const isRegionsLoaded = (state: ReduxState) => state.regions.loaded;
export const getAllRegions = (state: ReduxState) => _.values(state.regions.entities);
export const getRegionByCode = (state: ReduxState, code: RegionCode) =>
  state.regions.entities && state.regions.entities[code];
export const getRegionCountryISO = (state: ReduxState, code: RegionCode) =>
  _.get(getRegionByCode(state, code), 'country_iso');
export const getGeolocatedCountryCode = (state: ReduxState) => state.regions.geolocatedCountryCode;
export const getAllocatedCountryCode = (state: ReduxState) => state.regions.allocatedCountryCode;
export const isBrowsingIncorrectCountry = (state: ReduxState, currentCountryCode: IsoCode) => {
  const { allocatedCountryCode, isInSupportedRegion } = state.regions;
  return isoCodes[currentCountryCode] && isInSupportedRegion && allocatedCountryCode !== currentCountryCode;
};
