// @flow
import _ from 'lodash';
import { useCallback } from 'react';

import type { Question, SessionLengthFilter } from 'src/components/RevisionDialog/types';
import { type RevisionSession } from 'src/components/RevisionDialog/generateSession';
import { getSessionDuration } from 'src/components/RevisionDialog/utils';

type Props = {|
  revisionSession: RevisionSession,
  sessionLength: SessionLengthFilter,
|};

export type GetReplacementQuestionType = (questionIdToShuffle: string) => ?Question;

function shuffleQuestion(props: {|
  maxDuration: number,
  minDuration: number,
  questionToShuffle: Question,
  revisionDuration: number,
  revisionQuestions: $ReadOnlyArray<Question>,
  shuffleCandidates: $ReadOnlyArray<Question>,
|}): ?Question {
  const { revisionDuration, questionToShuffle, shuffleCandidates, revisionQuestions, maxDuration, minDuration } = props;

  const durationBeforeShuffle = revisionDuration - questionToShuffle.duration;

  const candidates = shuffleCandidates.filter((question) => !revisionQuestions.map((q) => q.id).includes(question.id));
  const randomisedCandidates = _.shuffle(candidates);

  const replacementQuestion = randomisedCandidates.find((candidate) => {
    const durationAfterShuffle = durationBeforeShuffle + candidate.duration;
    return durationAfterShuffle >= minDuration && durationAfterShuffle <= maxDuration;
  });
  return replacementQuestion;
}

export default function useQuestionShuffle(props: Props): GetReplacementQuestionType {
  const { revisionSession, sessionLength } = props;
  const {
    questions: revisionQuestions,
    filteredQuestions: shuffleCandidates,
    duration: revisionDuration,
  } = revisionSession;
  const { minDuration, maxDuration } = getSessionDuration(true, sessionLength);

  const getReplacementQuestion = useCallback(
    (questionIdToShuffle: string): ?Question => {
      const questionToShuffle = revisionQuestions.find((question) => question.id === questionIdToShuffle);
      if (!questionToShuffle) {
        return;
      }

      const replacementQuestion = shuffleQuestion({
        revisionDuration,
        questionToShuffle,
        shuffleCandidates,
        revisionQuestions,
        maxDuration,
        minDuration,
      });

      return replacementQuestion;
    },
    [minDuration, maxDuration, shuffleCandidates, revisionQuestions, revisionDuration]
  );

  return getReplacementQuestion;
}
