// @flow
import { Box, Card, Flex, Heading, Illustration, StatusLight, Text, tokens } from '@getatomi/neon';
import { PolarAngleAxis, RadialBarChart, RadialBar, ResponsiveContainer } from 'recharts';

import { UnstyledLink } from 'src/components/Link/Link';
import { getClassTopicInsightsUrl } from 'src/utils/routes';

import { type RankedTopic } from './classInsightsTransformer';

function RankedItem(props: {| accountId: string, classId: string, item: RankedTopic, totalStudents: number |}) {
  const { accountId, classId, item, totalStudents } = props;
  const { id, name, moduleName, lessonsCompleted, challengesCompleted, activeStudents, totalChallenges, totalLessons } =
    item;
  const attributes = [
    {
      label: 'Lessons completed',
      value: lessonsCompleted,
      color: 'colorChartInfoBolder',
    },
    {
      label: 'Quizzes completed',
      value: challengesCompleted,
      color: 'colorChartInfo',
    },
    {
      label: 'Active students',
      value: activeStudents,
      color: 'colorChartInfoSubtle',
    },
  ];

  const chartData = [
    {
      fill: tokens.colorChartInfoSubtle,
      name: 'Active students',
      value: activeStudents / totalStudents,
    },
    {
      fill: tokens.colorChartInfo,
      name: 'Quizzes completed',
      value: challengesCompleted / totalChallenges,
    },
    {
      fill: tokens.colorChartInfoBolder,
      name: 'Lessons completed',
      value: lessonsCompleted / totalLessons,
    },
  ];

  return (
    <li>
      <Card as={UnstyledLink} to={getClassTopicInsightsUrl(accountId, classId, id)} variant="rounded" height="sizeFull">
        <Text color="colorTextSubtle" variant="bodySmall1X" height="sizeFull">
          <Flex direction="column" gap="spacingSmall1X" padding="spacingLarge1X" height="sizeFull">
            <p>
              <Text
                as="span"
                color="colorText"
                display="block"
                fontFamily="fontFamilyRoot"
                fontSize="fontSizeLarge"
                lineHeight="lineHeightSmall2X"
                marginBottom="spacingSmall2X"
              >
                {name}
              </Text>
              in {moduleName}
            </p>
            <Box marginTop="auto" aria-hidden="true">
              {/* Width 99% to get ResponsiveContainer to properly respond within the flex context
               * https://github.com/recharts/recharts/issues/172
               */}
              <ResponsiveContainer width="99%" height={185}>
                <RadialBarChart
                  barSize={6}
                  cursor="pointer"
                  cx="50%"
                  cy="50%"
                  data={chartData}
                  innerRadius="50%"
                  startAngle={90}
                  endAngle={-270}
                  outerRadius="100%"
                >
                  <PolarAngleAxis type="number" domain={[0, 1]} dataKey="value" angleAxisId={0} tick={false} />
                  <RadialBar
                    angleAxisId={0}
                    background={{ fill: tokens.colorChartInfoSubtlest }}
                    cornerRadius="100%"
                    data={chartData}
                    dataKey="value"
                  />
                </RadialBarChart>
              </ResponsiveContainer>
            </Box>
            {attributes.map(({ label, value, color }) => (
              <Flex alignItems="center" justifyContent="space-between" testHook={label} key={label}>
                <StatusLight color={color}>{label}</StatusLight>
                <Box as="span" fontVariantNumeric="tabular-nums">
                  {value}
                </Box>
              </Flex>
            ))}
          </Flex>
        </Text>
      </Card>
    </li>
  );
}

function RankedList(props: {| children: React.Node |}) {
  return (
    <Box
      as="ol"
      display="grid"
      gap="spacingRoot"
      gridTemplateColumns={{ base: '1fr', mobile: '1fr 1fr', tablet: '1fr 1fr 1fr' }}
      marginTop="spacingLarge4X"
    >
      {props.children}
    </Box>
  );
}

function RankedListEmptyState() {
  return (
    <Flex alignItems="center" direction="column" gap="spacingLarge1X" marginTop="spacingLarge4X">
      <Illustration name="emptystate-markbook" />
      <Box as="p" color="colorTextSubtler" fontSize="fontSizeLarge" fontWeight="fontWeightLight" textAlign="center">
        There is not enough data to show ranked topics yet.
      </Box>
    </Flex>
  );
}

function RankedTopics(props: {|
  accountId: string,
  classId: string,
  rankedTopics: $ReadOnlyArray<RankedTopic>,
  totalStudents: number,
|}) {
  const { accountId, classId, rankedTopics, totalStudents } = props;

  const rankedTopicsContent = rankedTopics.map((item, index) => (
    <RankedItem key={index} accountId={accountId} classId={classId} item={item} totalStudents={totalStudents} />
  ));

  return (
    <Box as="section">
      <Heading as="h3" variant="large">
        Topics
      </Heading>
      {rankedTopicsContent.length > 0 ? <RankedList>{rankedTopicsContent}</RankedList> : <RankedListEmptyState />}
    </Box>
  );
}

export default RankedTopics;
