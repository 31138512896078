// @flow
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Box, Container, Heading, HelpInfo, HideVisually, Stack, Text } from '@getatomi/neon';

import type { ReduxState } from 'src/types';
import Link from 'src/components/Link/Link';
import { isLoggedInAsSuperAdmin as isLoggedInAsSuperAdminSelector } from 'src/reducers/auth';
import links from 'src/constants/links';

import JoinSubscriptionDialog from './JoinSubscriptionDialog/JoinSubscriptionDialog';
import SubscriptionList from './SubscriptionList/SubscriptionList';

type Props = {
  isLoggedInAsSuperAdmin: boolean,
};

const mapStateToProps = (state: ReduxState) => ({
  isLoggedInAsSuperAdmin: isLoggedInAsSuperAdminSelector(state),
});

function Subscriptions(props: Props) {
  const { isLoggedInAsSuperAdmin } = props;

  return (
    <>
      <Helmet>
        <title>Your subscriptions</title>
      </Helmet>
      <Container textAlign="center">
        <HideVisually>
          <Heading as="h1">Accounts</Heading>
        </HideVisually>
        <Box marginBottom="spacingLarge2X">
          <Stack spacing="spacingLarge4X">
            <Text as="p" variant="lead" color="colorTextSubtle">
              Looks like you have more than one account.
              <br />
              Where would you like to go today?
            </Text>
            {isLoggedInAsSuperAdmin && <JoinSubscriptionDialog />}
            <SubscriptionList />
          </Stack>
        </Box>
        {!isLoggedInAsSuperAdmin && (
          <HelpInfo>
            Need help with your accounts?{' '}
            <Link href={links.support.home} isExternal variant="monochrome">
              Contact support
            </Link>{' '}
            or{' '}
            <Link href={links.support.switchingBetweenAccounts} isExternal variant="monochrome">
              read more about account switching
            </Link>{' '}
            in our Help Centre.
          </HelpInfo>
        )}
      </Container>
    </>
  );
}

export default (connect(mapStateToProps)(Subscriptions): React.AbstractComponent<Props>);
