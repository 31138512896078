// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import classesSourceFilters from 'src/constants/classesSourceFilters';
import type { GetClasses, GetClassesVariables } from 'src/graphql/types/generated/GetClasses';

import GET_CLASSES from './GetClasses.graphql';

type Props = {|
  classesSource: $Keys<typeof classesSourceFilters>,
  keywords: string | void,
  paginationCursor: {| after?: string, before?: string |},
  subscriptionId: string,
  updatePageLocation: (query: { [string]: string | void }, resetFocus?: boolean) => void,
|};

type Output = {|
  classes: $ReadOnlyArray<{|
    id: string,
    levels: $ReadOnlyArray<{| id: string, name: string |}>,
    name: string,
    subject: {|
      color: string,
      groupCode: ?string,
      icon: string,
      name: string,
    |},
  |}>,
  error: ?ApolloError,
  goToNextPage?: () => void,
  goToPreviousPage?: () => void,
  loading: boolean,
|};

export default function useGetClasses(props: Props): Output {
  const { classesSource, keywords, subscriptionId, updatePageLocation, paginationCursor } = props;

  const { data, error, loading } = useQuery<GetClasses, GetClassesVariables>(GET_CLASSES, {
    variables: {
      accountId: subscriptionId,
      filters: {
        source: classesSource === classesSourceFilters.user ? 'USER' : 'SUBSCRIPTION',
      },
      ...paginationCursor,
    },
  });

  if (error) {
    return {
      error,
      loading: false,
      classes: [],
    };
  }

  const hasPaginationCursor = paginationCursor.after || paginationCursor.before;
  if ((loading && !data) || (loading && hasPaginationCursor)) {
    return {
      loading: true,
      classes: [],
      error: null,
    };
  }

  const classesData = data?.me?.account?.classes;
  invariant(classesData, 'Classes data should be defined');

  const rawClasses = classesData.edges ?? [];
  const classes = rawClasses.map(({ node: currentClass }) => ({
    id: currentClass.id,
    name: currentClass.name,
    subject: {
      color: currentClass.subject.color,
      icon: currentClass.subject.icon,
      name: currentClass.subject.shortName,
      groupCode: currentClass.subject.groupCode,
    },
    levels: currentClass.subject.levels.map((level) => ({ id: level.id, name: level.name })),
  }));

  const { endCursor, hasNextPage, hasPreviousPage, startCursor } = classesData.pageInfo;

  const goToNextPage =
    hasNextPage && endCursor ? () => updatePageLocation({ after: endCursor, before: undefined, keywords }) : undefined;

  const goToPreviousPage = hasPreviousPage
    ? () => updatePageLocation({ after: undefined, before: startCursor, keywords })
    : undefined;

  return {
    classes,
    error,
    loading: loading && !data,
    goToNextPage,
    goToPreviousPage,
  };
}
