// @flow
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { EmptyState, Illustration } from '@getatomi/neon';

import Button from 'src/components/Button/Button';

function CloseWindow() {
  useEffect(() => {
    // Tell the window that spawned this one that login has succeeded
    if (window.opener) {
      window.opener.postMessage({ loginSuccess: true }, '*');
    }

    setTimeout(() => {
      window.close();
    }, 2000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Log in successful</title>
      </Helmet>
      <EmptyState
        media={<Illustration name="validation-correct" />}
        description="You’re now successfully logged in to Atomi. This window will automatically close momentarily, or you can click below to close it yourself."
        primaryAction={
          <Button variant="text" onClick={() => window.close()}>
            Close window
          </Button>
        }
      />
    </>
  );
}

export default CloseWindow;
