// @flow
import { connect } from 'react-redux';
import { withRouter, type ContextRouter } from 'react-router';
import { Box } from '@getatomi/neon';

import type { RouteError as ReduxRouteError, ReduxState } from 'src/types';
import Button from 'src/components/Button/Button';
import { UnstyledLink } from 'src/components/Link/Link';
import ErrorPage from 'src/domains/ErrorPage/ErrorPage';
import Unauthorised from 'src/domains/Unauthorised/Unauthorised';
import { getInviteUrl } from 'src/utils/routes';
import errorTypes from 'src/constants/errorTypes';

type Props = {
  children: React.Node,
  error: ?ReduxRouteError,
  router: ContextRouter, // eslint-disable-line react/no-unused-prop-types
};

const getRouteErrorFromState = (state: ReduxState, pathname: string) => {
  let error;
  for (const key in state) {
    if (state.hasOwnProperty(key)) {
      const { routeError } = state[key];
      if (routeError?.pathname === pathname) {
        error = routeError;
      }
    }
  }
  return error?.status ? error : null;
};

export const mapStateToProps = (state: ReduxState, props: Props) => ({
  error: getRouteErrorFromState(state, props.router.location.pathname),
});

function RouteError(props: Props) {
  const { children, error } = props;

  if (!error) return children;

  const { status: code } = error;

  switch (code) {
    case 401:
      return <Unauthorised />;
    case 403: {
      const { errorType, response } = error.exception.output;
      // the user is trying to view a post with a subscription where they have an invited user
      // status
      if (errorType === errorTypes.SubscriptionMismatchException && response && response.invite_token) {
        return (
          <ErrorPage
            code={code}
            description={response.message}
            customCta={
              <Box marginBottom="spacingLarge2X">
                <Button as={UnstyledLink} href={getInviteUrl(response.invite_token)}>
                  Accept invite
                </Button>
              </Box>
            }
          />
        );
      }
      return <ErrorPage code={code} />;
    }
    case 404:
      return <ErrorPage code={code} />;
    default:
      return <ErrorPage code={500} />;
  }
}

export default withRouter(connect(mapStateToProps, {})(RouteError));
