// @flow
import _ from 'lodash';
import { Alert, Box, Container, Divider, Heading, TextField, Stack, Text } from '@getatomi/neon';
import { connect } from 'react-redux';
import { Controller } from 'react-hook-form';
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { type Location } from 'react-router';

import {
  getActiveSubscriptionName,
  getUserRole,
  isFreeSchoolPlan as isFreeSchoolPlanSelector,
  isEnforcingSaml as isEnforcingSamlSelector,
  isFamilyPlan as isFamilyPlanSelector,
  isSchoolPlan as isSchoolPlanSelector,
} from 'src/reducers/subscriptions';
import { loadInvitedUserDetails } from 'src/actions/auth';
import { loadRegion } from 'src/actions/regions';
import { saveInvitedUser, updateRegistrationDetails } from 'src/actions/registration';
import Button from 'src/components/Button/Button';
import ComboboxSchool from 'src/components/ComboboxSchool/ComboboxSchool';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import ErrorTypeMessage from 'src/components/ErrorTypeMessage/ErrorTypeMessage';
import PasswordField from 'src/components/PasswordField/PasswordField';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import errorTypes from 'src/constants/errorTypes';
import userRoles, { rolesLabels, indefiniteRoleLabels } from 'src/constants/userRoles';
import connectData from 'src/decorators/connectData';
import AvatarUploadContainer from 'src/components/AvatarUploadContainer/AvatarUploadContainer';
import useInvitedUserRegistrationFormStep2, { type Fields } from 'src/hooks/forms/useInvitedUserRegistrationFormStep2';
import useIsClientLoaded, { clientRequiredAttribute, loadingMessage } from 'src/hooks/useIsClientLoaded';
import NonAuthenticatedLayout from 'src/layouts/NonAuthenticatedLayout/NonAuthenticatedLayout';
import { getInviteError, getUserSchoolName, isInvitedUserLoaded } from 'src/reducers/auth';
import { getAllLevelsForSelect } from 'src/reducers/levels';
import { isRegistering as isRegisteringSelector, type RegistrationState } from 'src/reducers/registration';
import scrollToActiveElement from 'src/utils/scrollToActiveElement';
import positions from 'src/constants/positions';
import type { BoundAction, Dispatch, GetState, ReduxState, UserType } from 'src/types';

import TermsLine from '../TermsLine/TermsLine';
import MarketingPanel from '../MarketingPanel/MarketingPanel';
import RedirectInvalidInvite from './RedirectInvalidInvite';

type Params = {
  inviteCode: string,
};

type Props = {
  params: Params,
};

type InjectedProps = Props & {
  accountName: string,
  isEnforcingSamlAuth: boolean,
  isFamilySubscription: boolean,
  isFreeSchoolPlan: boolean,
  isInviteValid: boolean,
  isRegistering: boolean,
  isSchoolPlan: boolean,
  levels: Array<{
    label: string,
    value: number | string,
  }>,
  loadRegionAction: BoundAction<typeof loadRegion>,
  registrationDetails: $Shape<RegistrationState>,
  saveInvitedUserAction: BoundAction<typeof saveInvitedUser>,
  schoolName?: string,
  updateRegistrationDetailsAction: BoundAction<typeof updateRegistrationDetails>,
  user: $Shape<UserType>,
  userRole: string,
};

const mapStateToProps = (state: ReduxState) => ({
  accountName: getActiveSubscriptionName(state),
  isFreeSchoolPlan: isFreeSchoolPlanSelector(state),
  isInviteValid: !getInviteError(state),
  isRegistering: isRegisteringSelector(state),
  isEnforcingSamlAuth: isEnforcingSamlSelector(state),
  isFamilySubscription: isFamilyPlanSelector(state),
  isSchoolPlan: isSchoolPlanSelector(state),
  levels: getAllLevelsForSelect(state),
  registrationDetails: state.registration,
  schoolName: getUserSchoolName(state),
  user: state.auth.user,
  userRole: getUserRole(state),
});

export const fetchData = (getState: GetState, dispatch: Dispatch, location: Location, params: Params) => {
  const state = getState();
  const { inviteCode } = params;

  if (!isInvitedUserLoaded(state, inviteCode)) {
    return dispatch(loadInvitedUserDetails(inviteCode));
  }
};

export function RegistrationInvitedUserStep2(props: InjectedProps) {
  const {
    accountName,
    isEnforcingSamlAuth,
    isFamilySubscription,
    isFreeSchoolPlan,
    isSchoolPlan,
    isInviteValid,
    isRegistering,
    levels,
    loadRegionAction,
    params: { inviteCode },
    registrationDetails,
    saveInvitedUserAction,
    schoolName,
    updateRegistrationDetailsAction,
    user,
    userRole,
  } = props;
  const isClientLoaded = useIsClientLoaded();
  const { invitedRegistrationError } = registrationDetails;

  const isAvatarFieldPresent = !isFamilySubscription;
  const isSchoolIdFieldPresent = userRole === userRoles.student && isFamilySubscription;
  const isLevelIdFieldPresent = userRole === userRoles.student;
  const isPositionFieldPresent = userRole !== userRoles.student;
  const isPasswordFieldPresent = !isEnforcingSamlAuth;

  const { control, fields, form, serverError } = useInvitedUserRegistrationFormStep2({
    defaultValues: {
      email: registrationDetails.email,
      firstName: registrationDetails.firstName,
      lastName: registrationDetails.lastName,
      levelId: registrationDetails.levelId,
      password: registrationDetails.password,
      position: registrationDetails.position,
      schoolId: registrationDetails.schoolId,
    },
    onSubmitSuccess: async (data: Fields) => {
      updateRegistrationDetailsAction(data);
      await saveInvitedUserAction(inviteCode);
    },
    onSubmitFail: () => {
      scrollToActiveElement();
    },
    context: {
      isSchoolIdFieldPresent,
      isLevelIdFieldPresent,
      isPositionFieldPresent,
      isPasswordFieldPresent,
    },
  });

  // Needs to occur after fetchData decorator so we have the user region code
  useEffect(() => {
    if (isInviteValid) {
      loadRegionAction({ region: user.region_code, withLevels: true, withInternalSubjects: isSchoolPlan });
    }
  }, [isInviteValid, levels.length, loadRegionAction, user.region_code, isSchoolPlan]);

  const reloadPage = () => {
    window.location.reload(true);
  };

  return (
    <RedirectInvalidInvite>
      {isInviteValid && (
        <NonAuthenticatedLayout
          sidePanel={<MarketingPanel />}
          showSwitcher={false}
          removeSpacingBetweenHeaderAndMainContent="all"
        >
          <Helmet>
            <title>{`Your details | ${isFreeSchoolPlan ? 'Free ' : ''}${_.upperFirst(
              rolesLabels[userRole]
            )} Registration`}</title>
          </Helmet>
          <Stack spacing="spacingLarge1X">
            <Container maxWidth="sizeContainerSmall" textAlign="center">
              <form method="post" {...form} {...clientRequiredAttribute}>
                <Stack spacing="spacingLarge5X">
                  <div>
                    <Heading as="h1">Let’s complete your profile!</Heading>{' '}
                    <Text as="p" variant="lead" testHook="registration-instructions">
                      You’re joining {isFreeSchoolPlan && 'a free trial for '}
                      {accountName} as {indefiniteRoleLabels[userRole].toLowerCase()}.
                    </Text>
                  </div>
                  <Stack spacing="spacingLarge1X">
                    {serverError && <ValidationErrors errors={serverError.message} />}
                    {invitedRegistrationError && Object.keys(invitedRegistrationError).length > 0 && (
                      <Alert
                        variant="danger"
                        testHook="registration-invite-message-error"
                        buttonProps={
                          invitedRegistrationError.errorType === errorTypes.ExpiredAccessTokenException
                            ? {
                                children: 'refresh',
                                onClick: reloadPage,
                                testHook: 'registration-invite-message-link',
                              }
                            : undefined
                        }
                      >
                        {invitedRegistrationError.errorType === errorTypes.ExpiredAccessTokenException ? (
                          <>
                            Oops! It looks like we could not verify your details. Please refresh your page so we can get
                            you back on track.
                          </>
                        ) : (
                          <ErrorTypeMessage error={invitedRegistrationError} useAPIMessage />
                        )}
                      </Alert>
                    )}
                    {isAvatarFieldPresent && (
                      <>
                        <Divider />
                        <AvatarUploadContainer user={user} isOptional />
                        <Divider />
                      </>
                    )}
                    <Box display="grid" gridGap="spacingRoot" gridTemplateColumns={{ mobile: 'repeat(2, 1fr)' }}>
                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field }) => <TextField {...field} {...fields.firstName} autoCorrect="off" />}
                      />
                      <Controller
                        control={control}
                        name="lastName"
                        render={({ field }) => <TextField {...field} {...fields.lastName} autoCorrect="off" />}
                      />
                    </Box>
                    <div data-test="hide-in-percy">
                      <Controller
                        control={control}
                        name="email"
                        render={({ field }) => (
                          <TextField {...field} {...fields.email} type="email" helpText="You’ll use this to log in." />
                        )}
                      />
                    </div>
                    {isSchoolIdFieldPresent && (
                      <Controller
                        control={control}
                        name="schoolId"
                        render={({ field }) => (
                          <ComboboxSchool
                            {...field}
                            {...fields.schoolId}
                            schoolId={field.value}
                            schoolName={schoolName}
                            region={user.region_code}
                            onSelectionChange={(option) => {
                              field.onChange(option.value ? parseInt(option.value, 10) : option.label);
                            }}
                          />
                        )}
                      />
                    )}
                    {isLevelIdFieldPresent && (
                      <Controller
                        control={control}
                        name="levelId"
                        render={({ field }) => (
                          <Dropdown
                            {...field}
                            {...fields.levelId}
                            items={levels}
                            selectedKey={field.value ? String(field.value) : null}
                            onSelectionChange={field.onChange}
                          >
                            {(item) => <Item key={item.value}>{item.label}</Item>}
                          </Dropdown>
                        )}
                      />
                    )}
                    {isPositionFieldPresent && (
                      <Controller
                        control={control}
                        name="position"
                        render={({ field }) => (
                          <Dropdown
                            {...field}
                            {...fields.position}
                            items={positions}
                            selectedKey={field.value}
                            onSelectionChange={field.onChange}
                          >
                            {(item) => <Item>{item.label}</Item>}
                          </Dropdown>
                        )}
                      />
                    )}
                    {isPasswordFieldPresent && (
                      <Controller
                        control={control}
                        name="password"
                        render={({ field }) => (
                          <PasswordField
                            {...field}
                            {...fields.password}
                            helpText={`Must contain at least ${process.env.VALIDATION_PASSWORD_MIN_LENGTH} characters.`}
                          />
                        )}
                      />
                    )}
                  </Stack>
                  <Button
                    isLoading={isRegistering}
                    type="submit"
                    isDisabled={!isClientLoaded}
                    title={isClientLoaded ? undefined : loadingMessage}
                  >
                    Start using Atomi
                  </Button>
                </Stack>
              </form>
            </Container>
            <Container maxWidth="sizeContainerSmall">
              <TermsLine schoolName={!isFreeSchoolPlan ? schoolName : undefined} />
            </Container>
          </Stack>
        </NonAuthenticatedLayout>
      )}
    </RedirectInvalidInvite>
  );
}

export default connectData(fetchData)(
  connect(mapStateToProps, {
    updateRegistrationDetailsAction: updateRegistrationDetails,
    saveInvitedUserAction: saveInvitedUser,
    loadRegionAction: loadRegion,
  })(RegistrationInvitedUserStep2)
);
