// @flow
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import { SUBSCRIPTIONS_ENDPOINT } from 'src/api/endpoints';

export const LOAD_PLAN_UPDATE_ESTIMATE = 'LOAD_PLAN_UPDATE_ESTIMATE';
export const LOAD_PLAN_UPDATE_ESTIMATE_SUCCESS = 'LOAD_PLAN_UPDATE_ESTIMATE_SUCCESS';
export const LOAD_PLAN_UPDATE_ESTIMATE_FAIL = 'LOAD_PLAN_UPDATE_ESTIMATE_FAIL';
export const LOAD_PLAN_RENEWAL_ESTIMATE = 'LOAD_PLAN_RENEWAL_ESTIMATE';
export const LOAD_PLAN_RENEWAL_ESTIMATE_SUCCESS = 'LOAD_PLAN_RENEWAL_ESTIMATE_SUCCESS';
export const LOAD_PLAN_RENEWAL_ESTIMATE_FAIL = 'LOAD_PLAN_RENEWAL_ESTIMATE_FAIL';

export function loadUpdateEstimateByPlan(subscriptionId: number, planCode: string) {
  return {
    data: {
      planCode,
    },
    request: {
      types: [LOAD_PLAN_UPDATE_ESTIMATE, LOAD_PLAN_UPDATE_ESTIMATE_SUCCESS, LOAD_PLAN_UPDATE_ESTIMATE_FAIL],
      endpoint: {
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/billing-estimate/update?plan_code=${planCode}`,
      },
    },
  };
}

export function loadPlanUpdateEstimate(planCode: string) {
  return (dispatch: Function, getState: Function) => {
    const subscriptionId = getActiveSubscriptionId(getState());
    return dispatch(loadUpdateEstimateByPlan(subscriptionId, planCode));
  };
}

export function loadRenewalEstimate(subscriptionId: number) {
  return {
    request: {
      types: [LOAD_PLAN_RENEWAL_ESTIMATE, LOAD_PLAN_RENEWAL_ESTIMATE_SUCCESS, LOAD_PLAN_RENEWAL_ESTIMATE_FAIL],
      endpoint: {
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/billing-estimate/renewal`,
      },
    },
  };
}

export function loadCurrentPlanRenewalEstimate() {
  return (dispatch: Function, getState: Function) => {
    const subscriptionId = getActiveSubscriptionId(getState());
    return dispatch(loadRenewalEstimate(subscriptionId));
  };
}

export function loadReactivateEstimate(subscriptionId: number) {
  return {
    request: {
      types: [LOAD_PLAN_RENEWAL_ESTIMATE, LOAD_PLAN_RENEWAL_ESTIMATE_SUCCESS, LOAD_PLAN_RENEWAL_ESTIMATE_FAIL],
      endpoint: {
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/billing-estimate/reactivate`,
      },
    },
  };
}

export function loadCurrentPlanReactivateEstimate() {
  return (dispatch: Function, getState: Function) => {
    const subscriptionId = getActiveSubscriptionId(getState());
    return dispatch(loadReactivateEstimate(subscriptionId));
  };
}
