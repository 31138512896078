// @flow
import { useQuery } from '@apollo/client';
import {
  Avatar,
  Box,
  Divider,
  EmptyState,
  Flex,
  Heading,
  HideVisually,
  Illustration,
  Skeleton,
  Stack,
  Table,
  Text,
  TextLoader,
  useId,
  useMediaQuery,
} from '@getatomi/neon';

import type { GetLessonProgress, GetLessonProgressVariables } from 'src/graphql/types/generated/GetLessonProgress';
import Link from 'src/components/Link/Link';
import ProgressDialog from 'src/components/ProgressDialog/ProgressDialog';
import UpgradeAccountPanel from 'src/domains/Class/UpgradeAccountPanel/UpgradeAccountPanel';
import links from 'src/constants/links';
import postTypes from 'src/constants/postTypes';
import { trackingCtas } from 'src/constants/tracking';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';

import GET_LESSON_PROGRESS from './GetLessonProgress.graphql';
import StudentsProgressTable from './StudentsProgressTable/StudentsProgressTable';
import ProgressSummary from './ProgressSummary/ProgressSummary';
import transformLessonProgress from './transformLessonProgress';

type ProgressReportDialogProps = {|
  classId: string,
  isOpen: boolean,
  lesson: {
    id: string | number,
    name: string,
    type: typeof postTypes.video | typeof postTypes.text,
  },
  onClose: () => mixed,
  subscriptionId: string,
|};

function ProgressReportDialogLoader() {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 'breakpointLarge' });

  const circleLoader = <Skeleton animation="wave" size={96} borderRadius="borderRadiusCircle" />;

  const studentColIndex = 0;
  const columnCount = 3;
  const rowCount = 10;

  const columnHeaders = ['Student', 'Number of views', 'Last viewed'];
  const data = Array.from({ length: rowCount }, () => ({}));

  const columns = Array.from({ length: columnCount }, (_colValue, colIndex) => ({
    accessorKey: `loading-${colIndex}`,
    header: () => columnHeaders[colIndex],
    cell: () =>
      colIndex === studentColIndex ? (
        <Flex direction="row" alignItems="center" gap="spacingSmall1X">
          <Avatar variant="muted" />
          <TextLoader width="50%" />
        </Flex>
      ) : (
        <TextLoader maxWidth={colIndex === 1 ? 80 : 40} />
      ),
  }));

  return (
    <>
      <HideVisually>Loading the progress report.</HideVisually>
      <Stack spacing="spacingLarge7X">
        <Flex gap="spacingLarge7X" justifyContent={{ base: 'space-evenly', desktop: 'center' }}>
          {circleLoader}
          {circleLoader}
        </Flex>

        <Table columns={columns} data={data} isFixedLayout isLoading>
          <colgroup>
            <col width={isMobileOrTablet ? '40%' : '50%'} />
            <col width="20%" />
            <col width="30%" />
          </colgroup>
        </Table>
      </Stack>
    </>
  );
}

export default function ProgressReportDialog(props: ProgressReportDialogProps) {
  const { classId, isOpen, lesson, onClose, subscriptionId } = props;
  const headingId = useId();

  const variables: $Exact<GetLessonProgressVariables> = {
    accountId: subscriptionId,
    classId,
    lessonId: lesson.id.toString(),
    studentFilters: {
      roles: ['STUDENT'],
      userAccountStatuses: ['ACTIVE'],
    },
  };
  const { data, error } = useQuery<GetLessonProgress, GetLessonProgressVariables>(GET_LESSON_PROGRESS, {
    variables,
  });

  const { students } = transformLessonProgress(data?.me?.account.class);
  const isEmptyClass = students.length === 0;

  const isPostProgressLoaded = Boolean(data) || Boolean(error);

  const heading = (
    <Stack spacing="spacingSmall1X">
      <Heading as="h2" id={headingId}>
        How’s everyone going with this {lesson.type === postTypes.video ? 'video' : 'lesson'}?
      </Heading>
      <Text variant="lead">{lesson.name}</Text>
    </Stack>
  );

  const mainContent = isPostProgressLoaded ? (
    <>
      <ProgressSummary postType={lesson.type} students={students} />
      <StudentsProgressTable students={students} testHook="class-post-progress-dialog-table" />
    </>
  ) : (
    <ProgressReportDialogLoader />
  );

  return (
    <ProgressDialog
      ariaLabelledBy={headingId}
      isLoaded={isPostProgressLoaded}
      heading={heading}
      isOpen={isOpen}
      onClose={onClose}
      testHook="class-post-progress-dialog"
    >
      <Box position="relative">
        <Stack spacing="spacingLarge2X">
          <Divider />
          {error && <GraphQLError error={error} description="We couldn’t load the progress report." />}
          {Boolean(data) && isEmptyClass ? (
            <EmptyState
              media={<Illustration name="emptystate-insights" />}
              heading="There are no students in this class."
              headingProps={{ as: 'h3' }}
              description="When you add students you’ll be able to view their lesson progress here."
            />
          ) : (
            mainContent
          )}
        </Stack>
      </Box>
    </ProgressDialog>
  );
}

export function ProgressReportDialogLocked(props: {| isOpen: boolean, onClose: () => mixed, region?: string |}) {
  const { isOpen, onClose, region } = props;
  const headingId = useId();

  return (
    <ProgressDialog
      ariaLabelledBy={headingId}
      isOpen={isOpen}
      onClose={onClose}
      testHook="class-post-progress-dialog-locked"
    >
      <UpgradeAccountPanel
        videoId={region === 'CA_ON' ? 'c154ti5r7h' : '6zo55cyyys'}
        heading="Want to see how your students are progressing?"
        headingId={headingId}
        description="When you upgrade to a school account you can see how and when students complete lessons on Atomi, who’s doing well and who needs help. You can use this formative assessment data to inform your teaching and learning in the classroom."
        descriptionLink={
          <Link href={links.support.progress} isExternal>
            See how progress reports work in more detail
          </Link>
        }
        trackingData={{
          ref: trackingCtas.lockedFeatureDialog,
        }}
      />
    </ProgressDialog>
  );
}
