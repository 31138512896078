// @flow
import { Box, Flex, IconDuration, IconLessons, Text, Tooltip } from '@getatomi/neon';

import Duration from 'src/components/Duration/Duration';

function SubjectMetaItem(props: {| children: React.Node, icon: React.Node, tooltip: string |}) {
  const { children, icon, tooltip } = props;

  return (
    <Box as="li" display={{ base: 'none', tablet: 'initial' }}>
      <Tooltip content={tooltip}>
        <Flex as="button" alignItems="center" shrink={0} gap="spacingSmall1X">
          {icon}
          <Text as="span" fontSize="fontSizeSmall1X" color="colorTextSubtler">
            {children}
          </Text>
        </Flex>
      </Tooltip>
    </Box>
  );
}

type Props = {
  children: React.Node,
  count: number,
  duration: number,
};

export default function SubjectMetrics(props: Props) {
  const { count, duration, children } = props;

  return (
    <Flex as="ul" gap="spacingRoot" alignItems="center">
      <Flex
        as="li"
        alignItems="center"
        basis={0}
        grow={1}
        shrink={1}
        minWidth="sizeNone"
        marginRight={{ base: undefined, tablet: 'spacingSmall1X' }}
      >
        {children}
      </Flex>
      <SubjectMetaItem icon={<IconLessons color="colorIconSubtle" />} tooltip="Number of lessons">
        {count}
      </SubjectMetaItem>
      <SubjectMetaItem icon={<IconDuration color="colorIconSubtle" />} tooltip="Estimated total duration">
        <Duration duration={duration} format="rounded" />
      </SubjectMetaItem>
    </Flex>
  );
}
