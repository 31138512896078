// @flow

import { Flex, ProgressCircle, Stack, Strength } from '@getatomi/neon';

import {
  makeChallengeSummaryValues,
  type ChallengeSummaryItem,
  type ChallengeSummaryValues,
} from './makeChallengeSummaryValues';

type StrengthLabels = [string, string, string, string, string];

const strengthLabels: StrengthLabels = [
  'Maybe try that again.',
  'Needs some work.',
  'Getting there!',
  'You know your stuff!',
  'Perfect score. Whoo!',
];

type ChallengeProgressCircleProps = {|
  item: ChallengeSummaryItem,
|};

function ChallengeProgressCircle(props: ChallengeProgressCircleProps): React.Node {
  const { item } = props;
  const { label, value, percentage } = item;
  return (
    <ProgressCircle as="meter" label={label} value={percentage}>
      {value}
    </ProgressCircle>
  );
}

export type ChallengeSummaryProps = {|
  missedMarks: number,
  scoredMarks: number,
  skippedMarks: number,
  strengthPercentage: number,
  strengthThresholds: [number, number, number, number],
|};

export function ChallengeSummary(props: ChallengeSummaryProps): React.Node {
  const { strengthPercentage, scoredMarks, missedMarks, skippedMarks, strengthThresholds } = props;
  const summaryValues: ChallengeSummaryValues = makeChallengeSummaryValues({
    scoredMarks,
    missedMarks,
    skippedMarks,
    strengthPercentage,
  });

  return (
    <Stack spacing={{ base: 'spacingLarge4X', tablet: 'spacingLarge6X' }}>
      <Strength
        size="large"
        isAnimated
        value={summaryValues.strength}
        labels={strengthLabels}
        limits={strengthThresholds}
      />
      <Flex
        gap={{ mobile: 'spacingLarge8X' }}
        alignItems="start"
        justifyContent={{ base: 'space-between', mobile: 'center' }}
      >
        <ChallengeProgressCircle item={summaryValues.scored} />
        <ChallengeProgressCircle item={summaryValues.missed} />
        <ChallengeProgressCircle item={summaryValues.skipped} />
      </Flex>
    </Stack>
  );
}
