// @flow
import moment from 'moment';

import type { DateString } from 'src/types';
import Date from 'src/components/Date/Date';

type Props = {
  value?: DateString,
};

export default function CompletionDate(props: Props) {
  const { value } = props;

  return value ? (
    <Date isRelative={moment().diff(moment(value), 'days', true) < 7} value={value} testHook="hide-in-percy" />
  ) : (
    'Not viewed yet'
  );
}
