// @flow
import { Box, EmptyState, Illustration } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import { getClassUrl } from 'src/utils/routes';

type Props = {
  accountId: string,
  classId: string,
};

export default function EmptyMarkbookState({ accountId, classId }: Props) {
  const classUrl = getClassUrl(accountId, classId);
  return (
    <Box marginTop="spacingLarge7X">
      <EmptyState
        media={<Illustration name="emptystate-markbook" />}
        description="There are no students in the class yet."
        primaryAction={<Link to={`${classUrl}/users/students`}>Add students</Link>}
      />
    </Box>
  );
}
