// @flow
import { useToast } from '@getatomi/neon';

import type { NewAttachedContent } from 'src/domains/Tasks/types';
import type { Task } from 'src/types';
import { trackingCtas, trackingCtaTypes, trackingEvents } from 'src/constants/tracking';
import useProgressReportDialog from 'src/hooks/components/useProgressReportDialog';
import useTaskDialog from 'src/components/useTaskDialog/useTaskDialog';
import { getPostUniversalUrl, getClassTaskUrl } from 'src/utils/routes';
import { trackEvent } from 'src/utils/tracking';
import getEmbedCode from 'src/utils/getEmbedCode';

export type Post = NewAttachedContent & {
  tasks: $ReadOnlyArray<Task>,
};

type Props = {
  classId: string,
  isFreePlan: boolean,
  lesson: NewAttachedContent,
  moduleId: string,
  onCloseProgressDialog?: () => mixed,
  onSubmitTask: () => mixed,
  onViewTasks: (url: string) => mixed,
  region: string,
  subject: {
    code: string,
    groupCode: string,
  },
  subscriptionId: string,
};

export default function usePostActions(props: Props) {
  const {
    isFreePlan,
    subscriptionId,
    classId,
    subject,
    moduleId,
    lesson,
    onCloseProgressDialog,
    onSubmitTask,
    onViewTasks,
    region,
  } = props;

  const toast = useToast();
  const [progressReportDialog, { openProgressReportDialog }] = useProgressReportDialog({
    classId,
    isFreePlan,
    onClose: onCloseProgressDialog,
    region,
    subscriptionId,
  });

  const [taskDialog, { openTaskDialog }] = useTaskDialog({
    subscriptionId,
    classId,
    onSubmit: onSubmitTask,
  });

  const trackAction = (cta, data) =>
    trackEvent(trackingEvents.ctaClicked, {
      cta,
      type: trackingCtaTypes.button,
      postId: lesson.id,
      subject_code: subject.code,
      subject_group_code: subject.groupCode,
      ...data,
    });
  const copyToClipboard = async ({ isEmbed }) => {
    const url = getPostUniversalUrl(lesson.id, isEmbed);
    try {
      navigator.clipboard.writeText(isEmbed ? getEmbedCode(url) : url);
      trackAction(isEmbed ? trackingCtas.copyEmbedCode : trackingCtas.copyLink, {
        subscriptionId,
        classId,
        postId: lesson.id,
        url,
      });
      toast.success(isEmbed ? 'Embed code copied to the clipboard.' : 'Link copied to the clipboard.');
    } catch (error) {
      toast.error('There was an error copying the link to the clipboard. Please try again.');
    }
  };
  const shareToGoogleClassroom = () => {
    const url = getPostUniversalUrl(lesson.id);
    trackAction(trackingCtas.shareToGoogleClassroom, {
      subscriptionId,
      classId,
      postId: lesson.id,
      url,
    });
    window.open(`https://classroom.google.com/share?url=${url}`);
  };

  const elements = (
    <>
      {progressReportDialog}
      {taskDialog}
    </>
  );
  const actions = {
    assignTask: () => openTaskDialog({ contents: [lesson] }),
    viewTasks: () => onViewTasks(getClassTaskUrl(subscriptionId, classId, { postId: lesson.id })),
    viewProgress: () =>
      openProgressReportDialog({
        moduleId,
        post: {
          id: lesson.id,
          type: lesson.type,
          name: lesson.name,
        },
      }),
    copyLink: () => copyToClipboard({ isEmbed: false }),
    copyEmbed: () => copyToClipboard({ isEmbed: true }),
    shareToGoogleClassroom: () => shareToGoogleClassroom(),
  };

  return [elements, actions];
}
