// @flow
import type { APIDispatch, GetState, Level } from 'src/types';
import { LEVELS_ENDPOINT, REGIONS_ENDPOINT } from 'src/api/endpoints';
import { levelSchema, levelsResponseSchema } from 'src/api/schema';
import { getActiveSubscriptionRegion } from 'src/reducers/subscriptions';

export const LOAD = 'levels/LOAD';
export const LOAD_SUCCESS = 'levels/LOAD_SUCCESS';
export const LOAD_FAIL = 'levels/LOAD_FAIL';
export const LOAD_LEVEL = 'levels/LOAD_LEVEL';
export const LOAD_LEVEL_SUCCESS = 'levels/LOAD_LEVEL_SUCCESS';
export const LOAD_LEVEL_FAIL = 'levels/LOAD_LEVEL_FAIL';

export function loadLevelsByRegion(region: string) {
  return {
    request: {
      types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      schema: levelsResponseSchema,
      endpoint: {
        url: `${REGIONS_ENDPOINT}/${region}/levels?include=subjects`,
      },
    },
  };
}

export function loadLevels() {
  return (dispatch: APIDispatch<Level>, getState: GetState) => {
    const region = getActiveSubscriptionRegion(getState());
    return dispatch(loadLevelsByRegion(region));
  };
}

export function loadLevelById(levelId: number) {
  return {
    request: {
      types: [LOAD_LEVEL, LOAD_LEVEL_SUCCESS, LOAD_LEVEL_FAIL],
      schema: levelSchema,
      endpoint: {
        url: `${LEVELS_ENDPOINT}/${levelId}?include=subjects`,
      },
    },
  };
}
