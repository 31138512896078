// @flow
import { type ContextRouter, withRouter } from 'react-router';

import { useNavigationContext } from 'src/hooks/useNavigationContext';
import BackLink from 'src/components/BackLink/BackLink';
import { prepareNavigation } from 'src/utils/prepareNavigation';

type Props = {
  renderText: (label?: string) => React.Node,
  router: ContextRouter,
} & $Diff<React.ElementConfig<typeof BackLink>, { children: React.Node }>;

function PostBackLink(props: Props) {
  const { renderText, router, ...otherProps } = props;

  const [navigationContext] = useNavigationContext();
  // if useRouterGoBack is set on the origin link state then use router.goBack() to preserve the
  // scroll position
  const useRouterGoBack = Boolean(router.location.state?.useRouterGoBack);
  // Clear the state so that useRouterGoBack is reset if the user refreshes the page (the link might have changed)
  if (__CLIENT__) {
    window.history.replaceState({}, document.title);
  }

  if (!navigationContext) {
    return null;
  }

  const { backLinkUrl, backLinkLabel } = prepareNavigation(navigationContext);

  const onClickHandler = (e: SyntheticEvent<>) => {
    e.preventDefault();
    router.goBack();
  };

  return (
    <BackLink
      {...otherProps}
      to={{ pathname: backLinkUrl, state: { scrollTop: false } }}
      onClick={useRouterGoBack ? onClickHandler : undefined}
    >
      {renderText(backLinkLabel)}
    </BackLink>
  );
}

export default withRouter(PostBackLink);
