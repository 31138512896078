// @flow
import _ from 'lodash';
import { Fragment, useEffect } from 'react';
import { parseAlgoliaHitSnippet } from '@algolia/autocomplete-preset-algolia';
import { Badge, Box, EmptyState, IconLock, Text } from '@getatomi/neon';

import { UnstyledButton } from 'src/components/Button/Button';
import { UnstyledLink } from 'src/components/Link/Link';
import type { FoundContent } from 'src/components/useFindContentDialog/useFindContentDialog';
import {
  type UseAutocompleteOutput,
  type Hit,
  type AccessLevel,
  matchLevels,
} from 'src/components/useFindContentDialog/hooks/useContentAutocomplete';
import { getChallengeLabel } from 'src/utils/challenge';
import postTypes from 'src/constants/postTypes';
import type { ChallengeTier, PostType } from 'src/types';
import { trackEvent } from 'src/utils/tracking';
import {
  trackingEvents,
  trackingCtas,
  trackingCtaTypes,
  trackingSearchLocations,
  trackingUpgradeSources,
} from 'src/constants/tracking';

import ScrollWithFade from '../ScrollWithFade/ScrollWithFade';

type Props = {|
  autocomplete: UseAutocompleteOutput,
  classId: string,
  context: 'subject' | 'task',
  formRef: any,
  getLessonUrl: (args: {| lessonId: string, moduleId: string |}) => string,
  isFreePlan: boolean,
  onAssignContent: ($ReadOnlyArray<FoundContent>) => void,
  resultsRef: any,
  searchBoxRef: any,
  showUpgradeModal: () => void,
|};

export function isLocked(accessLevel: AccessLevel, isFreePlan: boolean): boolean {
  return accessLevel === 'paid' && isFreePlan;
}

function NoResults(props: { query: string }) {
  const { query } = props;

  return (
    <Box marginTop="spacingLarge4X">
      <EmptyState heading={`No results found for “${query}”.`} description="Maybe try another search term?" />
    </Box>
  );
}

function Highlighted(props: {|
  // When nesting attributes, it has to be an array
  attribute: string | $ReadOnlyArray<string>,
  hit: Hit,
|}) {
  const { hit, attribute } = props;

  const snippet = parseAlgoliaHitSnippet({
    hit,
    attribute,
  });

  return (
    <>
      {snippet.map(({ value, isHighlighted }, index) => {
        if (isHighlighted) {
          return (
            <Box key={index} as="mark" color="colorTextLink" backgroundColor="transparent">
              {value}
            </Box>
          );
        }
        return <Fragment key={index}>{value}</Fragment>;
      })}
    </>
  );
}

function HighlightedResults(props: {|
  autocomplete: UseAutocompleteOutput,
  classId: string,
  context: 'subject' | 'task',
  getLessonUrl: (args: {| lessonId: string, moduleId: string |}) => string,
  isFreePlan: boolean,
  onAssignContent: ($ReadOnlyArray<FoundContent>) => void,
  results: $PropertyType<UseAutocompleteOutput, 'results'>,
  showUpgradeModal: () => void,
|}) {
  const { autocomplete, results, isFreePlan, getLessonUrl, showUpgradeModal, context, classId, onAssignContent } =
    props;

  const getContentLineage = (hit: Hit): $ReadOnlyArray<string> => {
    const longestLineage = Object.keys(hit.categories).pop();
    return ['categories', longestLineage];
  };

  const getContentType = (type: PostType, tier?: ChallengeTier): string | null => {
    if (type === postTypes.challenge) return _.upperFirst(getChallengeLabel(tier));
    if (type === postTypes.text) return 'Text lesson';
    if (type === postTypes.video) return 'Video';
    return null;
  };

  const getContentPreview = (hit: Hit): 'captions' | 'description' | null => {
    const { description, captions } = hit;

    const captionsSnippet = hit._snippetResult.captions;
    const descriptionSnippet = hit._snippetResult.description;

    if (description && captions && captionsSnippet && descriptionSnippet) {
      return matchLevels[captionsSnippet.matchLevel] >= matchLevels[descriptionSnippet.matchLevel]
        ? 'captions'
        : 'description';
    }
    if (captions) {
      return 'captions';
    }
    if (description) {
      return 'description';
    }
    return null;
  };

  const { getListProps, getItemProps } = autocomplete.getters;

  return (
    <Box {...getListProps({})}>
      {results.map((result) => {
        const contentType = getContentType(result.hit.type, result.hit.tier);
        const captionsOrDescription = getContentPreview(result.hit);
        const itemProps = getItemProps({ item: result.hit, source: result.source });

        const isLessonLocked = isLocked(result.hit.access_level, isFreePlan);

        const getInteractiveProps = () => {
          const searchLocation = context === 'subject' ? trackingSearchLocations.subject : trackingSearchLocations.task;
          const commonTrackingData = {
            cta: trackingCtas.searchResultSelect,
            searchLocation,
            classId,
            moduleId: result.hit.module_id,
            postId: result.hit.id,
          };

          if (isLessonLocked) {
            return {
              onClick: () => {
                trackEvent(trackingEvents.upgradePromptTriggered, {
                  type: trackingCtaTypes.button,
                  source: trackingUpgradeSources.search,
                  ...commonTrackingData,
                });
                showUpgradeModal();
              },
            };
          }
          if (context === 'subject') {
            return {
              as: UnstyledLink,
              trackingData: commonTrackingData,
              to: getLessonUrl({ moduleId: String(result.hit.module_id), lessonId: String(result.hit.id) }),
            };
          }
          return {
            as: UnstyledButton,
            trackingData: commonTrackingData,
            onClick: () => {
              onAssignContent([
                {
                  duration: result.hit.duration,
                  id: String(result.hit.id),
                  moduleId: String(result.hit.module_id),
                  name: result.hit.name,
                  subjectCode: result.hit.subject_code,
                  tier: result.hit.tier,
                  type: result.hit.type,
                },
              ]);
            },
          };
        };

        return (
          <Box
            key={result.hit.id}
            {...itemProps}
            padding="spacingRoot"
            backgroundColor={itemProps['aria-selected'] && 'colorBackgroundSelectedSubtler'}
            cursor="pointer"
            textAlign="left"
            display="block"
            width="sizeFull"
            _hover={{ backgroundColor: 'colorBackgroundHovered' }}
            {...getInteractiveProps()}
          >
            <Box as="p" display="inline-flex" gap="spacingSmall1X" alignItems="center">
              {isLessonLocked && <IconLock size="sizeIconSmall" color="colorIconSubtle" testHook="locked-lesson" />}

              <Text as="span" variant="bodySmall">
                <Highlighted hit={result.hit} attribute="name" />
              </Text>

              {contentType && (
                <Badge variant="filled" fillColor="colorBackgroundNeutralBold">
                  {contentType}
                </Badge>
              )}
            </Box>

            <Text as="p" variant="bodySmall2X">
              <Highlighted hit={result.hit} attribute={getContentLineage(result.hit)} />
            </Text>

            {captionsOrDescription && (
              <Box marginTop="spacingSmall1X">
                <Text as="p" variant="bodySmall1X" color="colorTextSubtler">
                  <Highlighted hit={result.hit} attribute={captionsOrDescription} />
                </Text>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

export default function ContentSearchResults(props: Props) {
  const {
    autocomplete,
    formRef,
    resultsRef,
    searchBoxRef,
    isFreePlan,
    getLessonUrl,
    showUpgradeModal,
    context,
    classId,
    onAssignContent,
  } = props;
  const { query, results, getters } = autocomplete;
  const { getEnvironmentProps } = getters;

  useEffect(() => {
    if (!formRef.current || !resultsRef.current || !searchBoxRef.current) {
      return undefined;
    }

    const { onTouchStart, onTouchMove, onMouseDown } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: searchBoxRef.current,
      panelElement: resultsRef.current,
    });

    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);

    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
    };
  }, [getEnvironmentProps, formRef, resultsRef, searchBoxRef]);

  if (!results.length) {
    return <NoResults query={query} />;
  }

  return (
    <ScrollWithFade
      containerProps={{
        ...getters.getPanelProps({}),
      }}
      ref={resultsRef}
    >
      <HighlightedResults
        autocomplete={autocomplete}
        results={results}
        isFreePlan={isFreePlan}
        getLessonUrl={getLessonUrl}
        showUpgradeModal={showUpgradeModal}
        classId={classId}
        context={context}
        onAssignContent={onAssignContent}
      />
    </ScrollWithFade>
  );
}
