// @flow
import _ from 'lodash';
import { Alert } from '@getatomi/neon';

import type { CurrencyType } from 'src/types';
import Link from 'src/components/Link/Link';
import Price from 'src/components/Price/Price';
import links from 'src/constants/links';

type Props = {
  currency: CurrencyType,
  isFuturePayment?: boolean,
  promotionalCredits?: number,
  refundableCredits?: number,
};

function CreditInfoItem({ name, currency, value }: { currency: CurrencyType, name: string, value: number }) {
  return (
    <span>
      {name} of <Price currency={currency} value={value} fontWeight="fontWeightBold" />
    </span>
  );
}

function CreditInfo(props: Props) {
  const { currency, promotionalCredits = 0, refundableCredits = 0, isFuturePayment } = props;

  if (
    !currency ||
    (!promotionalCredits && !refundableCredits) ||
    (promotionalCredits === 0 && refundableCredits === 0)
  ) {
    return null;
  }

  const refundableCreditInfo = _.toNumber(refundableCredits) > 0 && (
    <CreditInfoItem currency={currency} name="a pro-rata credit" value={refundableCredits} />
  );
  const promotionalCreditInfo = _.toNumber(promotionalCredits) > 0 && (
    <CreditInfoItem currency={currency} name="promo credits" value={promotionalCredits} />
  );

  return (
    <Alert variant="info" testHook="credit-info">
      You currently have {refundableCreditInfo} {refundableCreditInfo && promotionalCreditInfo && 'and '}
      {promotionalCreditInfo} that will be used against {isFuturePayment ? 'future payments' : 'this payment'}.{' '}
      <Link variant="monochrome" href={links.support.credit} isExternal>
        Need help with credits?
      </Link>
    </Alert>
  );
}

export default CreditInfo;
