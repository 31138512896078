// @flow
import { Alert, Box } from '@getatomi/neon';

type FormMessageProps = {|
  children: ?React.Node,
  variant?: 'danger' | 'warning',
|};

export default function FormMessage(props: FormMessageProps) {
  const { variant = 'danger', children } = props;

  return (
    <Box marginBottom="spacingLarge">
      <Alert variant={variant}>{children}</Alert>
    </Box>
  );
}
