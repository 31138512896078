// @flow
import { find, mapValues, omit, size, values } from 'lodash';

import type { PaymentSource } from 'src/types';
import {
  LOAD_PAYMENT_SOURCES,
  LOAD_PAYMENT_SOURCES_SUCCESS,
  LOAD_PAYMENT_SOURCES_FAIL,
  SET_ADD_PAYMENT_SOURCE_STATUS,
  ADD_PAYMENT_SOURCE,
  ADD_PAYMENT_SOURCE_SUCCESS,
  ADD_PAYMENT_SOURCE_FAIL,
  DELETE_PAYMENT_SOURCE,
  DELETE_PAYMENT_SOURCE_SUCCESS,
  DELETE_PAYMENT_SOURCE_FAIL,
} from 'src/actions/paymentSources';
import { UPGRADE_SUBSCRIPTION_SUCCESS, REACTIVATE_SUBSCRIPTION } from 'src/actions/subscriptions';
import paymentSourcesStatuses from 'src/constants/paymentSourcesStatuses';

type PaymentSourcesState = {
  entities: {
    [id: string]: PaymentSource,
  },
  isAddingPaymentSource: boolean,
  isDeletingPaymentSource: boolean,
  isLoaded: boolean,
  isLoadingPaymentSources: boolean,
  routeError: ?Object,
};

type StateType = {
  paymentSources: PaymentSourcesState,
};

const initialState = {
  entities: {},
  isLoadingPaymentSources: false,
  isLoaded: false,
  isAddingPaymentSource: false,
  isDeletingPaymentSource: false,
  routeError: null,
};

const processPaymentSources = (paymentSources: Object) =>
  mapValues(paymentSources, (source) => ({
    ...source,
    hasErrors: source.status === paymentSourcesStatuses.expiring || source.status === paymentSourcesStatuses.expired,
  }));

export default function paymentSourcesReducer(state: PaymentSourcesState = initialState, action: Object = {}) {
  switch (action.type) {
    case LOAD_PAYMENT_SOURCES:
      return {
        ...state,
        isLoadingPaymentSources: true,
      };
    case LOAD_PAYMENT_SOURCES_SUCCESS:
      return {
        ...state,
        isLoadingPaymentSources: false,
        isLoaded: true,
        routeError: null,
        entities: processPaymentSources(action.result.entities.paymentSources),
      };

    case LOAD_PAYMENT_SOURCES_FAIL:
      return {
        ...state,
        isLoadingPaymentSources: false,
        isLoaded: false,
        routeError: action.routeError,
      };

    case UPGRADE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoaded: false,
      };

    case SET_ADD_PAYMENT_SOURCE_STATUS:
      return {
        ...state,
        isAddingPaymentSource: action.isAddingPaymentSource,
      };

    case ADD_PAYMENT_SOURCE:
      return {
        ...state,
        isAddingPaymentSource: true,
      };

    case ADD_PAYMENT_SOURCE_FAIL:
      return {
        ...state,
        isAddingPaymentSource: false,
      };

    case ADD_PAYMENT_SOURCE_SUCCESS:
      return {
        ...state,
        entities: processPaymentSources(action.result.entities.paymentSources),
        isAddingPaymentSource: false,
        isLoaded: true,
      };

    case DELETE_PAYMENT_SOURCE:
      return {
        ...state,
        isDeletingPaymentSource: true,
      };

    case DELETE_PAYMENT_SOURCE_FAIL:
      return {
        ...state,
        isDeletingPaymentSource: false,
      };

    case DELETE_PAYMENT_SOURCE_SUCCESS:
      return {
        ...state,
        isDeletingPaymentSource: false,
        entities: omit(state.entities, action.data.id),
      };

    case REACTIVATE_SUBSCRIPTION:
      return {
        ...state,
        isLoaded: action.data.hasNewPaymentSource ? false : state.isLoaded,
      };

    default:
      return state;
  }
}

// Selectors
export const isPaymentSourcesLoaded = (state: StateType) => state.paymentSources.isLoaded;
export const isAddingPaymentSource = (state: StateType) => state.paymentSources.isAddingPaymentSource;
export const isDeletingPaymentSource = (state: StateType) => state.paymentSources.isDeletingPaymentSource;
export const isLoadingPaymentSources = (state: StateType) => state.paymentSources.isLoadingPaymentSources;
export const getAllPaymentSources = (state: StateType) => values(state.paymentSources.entities);
export const hasPaymentSources = (state: StateType) => size(getAllPaymentSources(state)) > 0;
export const getPrimaryPaymentSource = (state: StateType) => find(getAllPaymentSources(state), 'is_primary');
export const isPrimaryPaymentSourceExpired = (state: StateType) => {
  const paymentSource = getPrimaryPaymentSource(state);
  if (!paymentSource) return false;

  return paymentSource.status === paymentSourcesStatuses.expired;
};
