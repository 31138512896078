// @flow
import cx from 'classnames';
import _ from 'lodash';
import { Container } from '@getatomi/neon';

import type { MasterLayoutSpacingBetweenHeaderAndMainContent } from 'src/types';

import styles from './Main.module.scss';

type Props = {
  children: React.Node,
  isContained?: boolean,
  isFlexContainer?: boolean,
  isVerticallyCentered?: boolean,
  removeTopPadding?: MasterLayoutSpacingBetweenHeaderAndMainContent,
  withFooter?: boolean,
};

export default function Main(props: Props) {
  const {
    children,
    isContained = false,
    isFlexContainer = false,
    isVerticallyCentered = false,
    removeTopPadding,
    withFooter = true,
  } = props;

  return (
    <main
      className={cx(styles.root, {
        [styles.withoutFooter]: !withFooter,
        [styles.isFlexContainer]: isFlexContainer,
        [styles.isVerticallyCentered]: isVerticallyCentered,
        [styles[`removeTopPadding${_.upperFirst(removeTopPadding)}`]]: removeTopPadding,
      })}
      id="main"
    >
      {isContained ? <Container>{children}</Container> : children}
    </main>
  );
}
