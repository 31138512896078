// @flow
import type { FormPhoneNumber } from 'src/types';

export default function phoneNumberValidator({
  createError,
  phoneNumber,
}: {
  createError: ({
    message: string,
  }) => void,
  phoneNumber: FormPhoneNumber,
}) {
  // Neon.PhoneField passes alone a validation state on each change (along with a value and
  // number). We refer to that valid state here, if valid is true we pass the validation
  if (!phoneNumber || !phoneNumber.value || phoneNumber.value.trim() === '') {
    return createError({ message: 'Phone number is required' });
  }

  // Neon.PhoneField side loads a util script for validation. If that library doesn't load,
  // isValid will be null. In this case the user can progress forward with a potentially invalid
  // number
  if (phoneNumber.isValid === false) {
    return createError({ message: 'Enter a valid phone number' });
  }

  return true;
}
