// @flow
import { forwardRef } from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Box, Flex, IconChevron, Text } from '@getatomi/neon';

import { Item, Menu, Section } from 'src/components/Menu/Menu';
import ReduxAvatarContainer from 'src/components/ReduxAvatarContainer/ReduxAvatarContainer';
import SubscriptionName from 'src/components/SubscriptionName/SubscriptionName';
import UserName from 'src/components/UserName/UserName';
import { loginWithSaml, loadAuthStrategies } from 'src/actions/auth';
import type { Subscription, UserType } from 'src/types';

import { useAccountMenuItems, type AccountMenuItemsProps } from './useAccountMenuItems';

type AccountMenuTriggerProps = {|
  subscription: Subscription,
  user: UserType,
|};

export const AccountMenuTrigger = forwardRef<AccountMenuTriggerProps, HTMLButtonElement>(function AccountMenuTrigger(
  props,
  ref: any
) {
  const { subscription, user, ...triggerProps } = props;

  return (
    <Box ref={ref} as="button" alignSelf="center" paddingBlock="spacingSmall1X" {...triggerProps}>
      <Flex gap="spacingSmall" alignItems="center">
        <ReduxAvatarContainer user={user} size="sizeAvatarRoot" />
        <Box textAlign="left">
          <Text variant="bodySmall2X" lineHeight="lineHeightSmall1X" fontWeight="fontWeightMedium">
            <UserName firstName={user.first_name} lastName={user.last_name} />
          </Text>
          <Text variant="bodySmall2X" lineHeight="lineHeightSmall1X" color="colorTextSubtler">
            <SubscriptionName name={subscription.name} planType={subscription.plan_customer_type} />
          </Text>
        </Box>
        <IconChevron size="sizeIconSmall3X" color="colorIcon" />
      </Flex>
    </Box>
  );
});

type AccountMenuHeaderProps = {|
  isLoggedInAsAccountManager: boolean,
  subscription: Subscription,
  user: UserType,
|};

function AccountMenuHeader(props: AccountMenuHeaderProps) {
  const { isLoggedInAsAccountManager, subscription, user } = props;

  return (
    <Flex
      gap="spacingSmall"
      direction="column"
      alignItems="center"
      paddingTop="spacingRoot"
      paddingBottom="spacingSmall"
    >
      <ReduxAvatarContainer user={user} size="sizeAvatarLarge1X" />
      <Box textAlign="center">
        <Text variant="bodyRoot">Hi, {user.first_name}!</Text>
        <Text variant="bodySmall2X" lineHeight="lineHeightSmall1X" color="colorTextSubtler">
          <SubscriptionName
            enforceDefaultName={isLoggedInAsAccountManager}
            name={subscription.name}
            planType={subscription.plan_customer_type}
          />
        </Text>
      </Box>
    </Flex>
  );
}

export type AccountMenuProps = AccountMenuItemsProps;

function AccountMenu(props: AccountMenuProps) {
  const { isLoggedInAsAccountManager, subscription, user } = props;

  const { disabledKeys, handleAction, items, upgradeDialog } = useAccountMenuItems(props);

  return (
    <>
      <Menu
        ariaLabel="Account menu"
        items={items}
        onAction={handleAction}
        disabledKeys={disabledKeys}
        renderTrigger={({ triggerProps, ref }) => (
          <AccountMenuTrigger ref={ref} {...triggerProps} subscription={subscription} user={user} />
        )}
        renderHeader={() => (
          <AccountMenuHeader
            subscription={subscription}
            user={user}
            isLoggedInAsAccountManager={isLoggedInAsAccountManager}
          />
        )}
        width={280}
      >
        {({ children, title, ...otherProps }) =>
          children ? (
            <Section items={children} title={title}>
              {({ label, ...itemProps }) => <Item {...itemProps}>{label}</Item>}
            </Section>
          ) : (
            <Item {...otherProps}>{otherProps.label}</Item>
          )
        }
      </Menu>
      {upgradeDialog}
    </>
  );
}

export default withCookies(
  connect(null, {
    loginWithSamlAction: loginWithSaml,
    loadAuthStrategiesAction: loadAuthStrategies,
  })(AccountMenu)
);
