// @flow
import keyMirror from 'keymirror';

const questionTypes = keyMirror({
  DRAG_AND_DROP: null,
  EXACT_ANSWER: null,
  MULTIPLE_CHOICE: null,
  SELF_MARKED: null,
  SELF_MARKED_LITE: null,
  TEXT: null,
  VIDEO: null,
});

export type QuestionType = $Keys<typeof questionTypes>;

export default questionTypes;
