// @flow
import Logger from 'src/utils/Logger';
import errorMessageVariants from 'src/constants/errorMessageVariants';

const log = new Logger('utils/validateEmail');

export default async function validateEmail(email: string) {
  try {
    const response = await fetch(
      `${process.env.MAILGUN_BASE_URL}/address/validate?api_key=${
        process.env.MAILGUN_API_KEY
      }&address=${encodeURIComponent(email)}`
    );

    const {
      did_you_mean: didYouMean,
      is_disposable_address: isDisposable,
      is_role_address: isRoleAddress,
      is_valid: isValid,
    } = await response.json();

    let message = '';
    let level = errorMessageVariants.warning;

    if (!isValid) {
      level = errorMessageVariants.error;
      message = `Hmm. It looks like this isn’t a valid email address.`;
    } else if (isDisposable) {
      level = errorMessageVariants.warning;
      message = `This email looks like it might be fake. Double check you’ve entered a valid address.`;
    } else if (isRoleAddress) {
      level = errorMessageVariants.warning;
      message =
        'This looks like it might be a shared email address. For security reasons we recommend using an email that only you have access to.';
    }

    if (didYouMean) {
      message = `${message} Did you mean: ${didYouMean}?`.trim();
    }

    return {
      message,
      level,
    };
  } catch (err) {
    log.warn('Validating the email with Mailgun failed', err);
  }
}

export function isBlocking(level: string) {
  return [errorMessageVariants.error].includes(level);
}
