// @flow
import HttpError from './HttpError';

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD';

type HttpHeaders = { [string]: string };

type JsonRequestOptions = {
  headers?: HttpHeaders,
  method: Method,
  payload?: mixed,
};

function checkHttpStatus(response: Response): Response {
  if (!response.ok) {
    throw new HttpError(response);
  }
  return response;
}

function parseJSON(response: Response): Promise<any> {
  return response.json();
}

function canSendBody(method: Method): boolean {
  return ['GET', 'HEAD'].includes(method) === false;
}

function hasBody(body): boolean {
  return Boolean(body);
}

function jsonRequest(resource: string, options: JsonRequestOptions) {
  const { method, payload, headers } = options;

  const requestOptions = {
    method,
    headers: {
      ...headers,
      ...(canSendBody(method) && hasBody(payload) ? { 'Content-Type': 'application/json' } : {}),
      Accept: 'application/json',
    },
    body: canSendBody(method) ? JSON.stringify(payload) : undefined,
    credentials: 'same-origin', // includes same origin cookies
  };

  return fetch(resource, requestOptions).then(checkHttpStatus).then(parseJSON);
}

export function get(resource: string, headers?: HttpHeaders) {
  return jsonRequest(resource, { headers, method: 'GET' });
}

export function post(resource: string, payload: mixed, headers?: HttpHeaders) {
  return jsonRequest(resource, { payload, headers, method: 'POST' });
}
