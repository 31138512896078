// @flow
import { Link } from '@getatomi/neon';
import _ from 'lodash';

import styles from './BrowsersList.module.scss';

const browsers = Object.freeze({
  chrome: 'https://www.google.com/chrome/',
  edge: 'https://support.getatomi.com/faqs/technical/updating-microsoft-edge',
  firefox: 'https://www.mozilla.org/firefox',
  safari: 'https://support.getatomi.com/faqs/technical/updating-safari',
});

type Props = {
  names?: Array<$Keys<typeof browsers>>,
  testHook?: string,
};

export default function BrowsersList(props: Props) {
  const { names = _.keys(browsers), testHook } = props;

  return (
    <ul data-test={testHook}>
      {names.map((name) => (
        <li className={styles.listItem} key={name}>
          <Link href={browsers[name]} className={styles.link} target="_blank" rel="noopener noreferrer">
            <img src={require(`src/assets/images/logo-browser-${name}.png`)} alt="" width="36" />
            <span className={styles.name}>{_.upperFirst(name)}</span>
          </Link>
        </li>
      ))}
    </ul>
  );
}
