// @flow

// safely formats a given value and total as a percentage number
// e.g. getFraction(5, 10) => 0.5
export function getFraction(value: ?number, total: ?number): number | null {
  if (value == null || total == null) {
    return null;
  }
  return Number.isFinite(value / total) ? value / total : null;
}

// formats a given percentage as a human friendly string
// e.g. formatPercentageAsString(0.5) => 50%
export function formatPercentageAsString(percentage: ?number) {
  return `${Math.round((percentage ?? 0) * 100)}%`;
}
