// @flow
import _ from 'lodash';
import jwtDecode from 'jwt-decode';

import type { ReduxState, Subscription } from 'src/types';
import Logger from 'src/utils/Logger';
import { getSchoolById } from 'src/reducers/schools';
import {
  ADD_USER_TO_SUBSCRIPTION_SUCCESS,
  LOAD_SUBSCRIPTION_BY_CHARGEBEE_ID_FAIL,
  ADD_USER_TO_SUBSCRIPTION_FAIL,
  LOAD_SUBSCRIPTION,
  LOAD_SUBSCRIPTION_SUCCESS,
  LOAD_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  REQUEST_UPGRADE,
  REQUEST_UPGRADE_SUCCESS,
  REQUEST_UPGRADE_FAIL,
  SET_ACTIVE_SUBSCRIPTION,
  SET_ACTIVE_SUBSCRIPTION_FAIL,
  SET_UPGRADE_SUBSCRIPTION_STATUS,
  UPGRADE_SUBSCRIPTION,
  UPGRADE_SUBSCRIPTION_SUCCESS,
  UPGRADE_SUBSCRIPTION_FAIL,
  CLEAR_UPGRADE_ERROR,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAIL,
  REACTIVATE_SUBSCRIPTION,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_FAIL,
  SET_REACTIVATE_SUBSCRIPTION_STATUS,
  CLEAR_REACTIVATE_SUBSCRIPTION_ERROR,
  REMOVE_USER_FROM_SUBSCRIPTION_SUCCESS,
  TOGGLE_FEATURE_FLAG_SUCCESS,
  UPSERT_WONDE_CONFIGURATION,
  UPSERT_WONDE_CONFIGURATION_SUCCESS,
  UPSERT_WONDE_CONFIGURATION_FAIL,
  LOAD_EDS_DETAILS_SUCCESS,
  LOAD_EDS_DETAILS_FAIL,
  ACTIVATE_DAILY_SYNC_SUCCESS,
  DEACTIVATE_DAILY_SYNC_SUCCESS,
} from 'src/actions/subscriptions';
import {
  LOAD_SUCCESS as GET_AUTH_USER_SUCCESS,
  LOGIN_SUCCESS,
  LOAD_INVITED_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
} from 'src/actions/auth';
import { ACTIVATE_USER_SUCCESS, REGISTRATION_SUCCESS, SAVE_SUBJECTS_SUCCESS } from 'src/actions/registration';
import { SOCIAL_LOGIN_SUCCESS } from 'src/actions/socialLogins';
import {
  ARCHIVE_USERS_SUCCESS,
  RESTORE_USERS_SUCCESS,
  TRANSFER_ACCOUNT_OWNERSHIP_SUCCESS,
  UNMAKE_USER_ADMIN_SUCCESS,
} from 'src/actions/users';
import { COLLECT_INVOICE_PAYMENT_SUCCESS } from 'src/actions/invoices';
import { isRefreshingToken } from 'src/reducers/auth';
import featureFlags from 'src/constants/featureFlags';
import PLANS from 'src/constants/plans';
import ROLES from 'src/constants/userRoles';
import SUBSCRIPTION_STATUSES from 'src/constants/subscriptionStatuses';
import USER_ACCOUNT_STATUSES from 'src/constants/userAccountStatuses';

const log = new Logger('reducers/subscriptions');

export type SubscriptionState = {
  activeSubscriptionId: number,
  entities: {
    [id: number]: Subscription,
  },
  externalDataSource: ?Object,
  isCancellingSubscription: boolean,
  isConfiguringEDS: boolean,
  isLoaded: boolean,
  isLoading: boolean,
  isReactivatingSubscription: boolean,
  isRequestingUpgrade: boolean,
  isUpdatingSubscription: boolean,
  isUpgradingSubscription: boolean,
  reactivateError: ?Object,
  routeError: ?string,
  upgradeError: ?Object,
};

const initialState = {
  activeSubscriptionId: 0,
  activateDailySyncError: false,
  entities: {},
  isCancellingSubscription: false,
  isLoading: false,
  isLoaded: false,
  isUpdatingSubscription: false,
  isConfiguringEDS: false,
  isReactivatingSubscription: false,
  isRequestingUpgrade: false,
  isUpgradingSubscription: false,
  upgradeError: null,
  reactivateError: null,
  routeError: null,
  externalDataSource: {},
};

export default function subscriptionsReducer(state: SubscriptionState = initialState, action: Object = {}) {
  switch (action.type) {
    case LOAD_SUBSCRIPTION:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        entities: _.merge({}, state.entities, action.result.entities.subscriptions),
        routeError: null,
      };
    case LOAD_SUBSCRIPTION_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        routeError: action.routeError,
      };

    case ADD_USER_TO_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        entities: _.merge({}, state.entities, action.result.entities.subscriptions),
      };

    case TOGGLE_FEATURE_FLAG_SUCCESS: {
      const subscriptionId = action.result.result;
      const updatedSubscription = action.result.entities.subscriptions[subscriptionId];

      return {
        ...state,
        entities: {
          ...state.entities,
          [subscriptionId]: {
            ...state.entities[subscriptionId],
            ...updatedSubscription,
          },
        },
      };
    }

    case REMOVE_USER_FROM_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        entities: _.omit(state.entities, action.data.subscriptionId),
      };

    case LOAD_SUBSCRIPTION_BY_CHARGEBEE_ID_FAIL:
      return {
        ...state,
        routeError: action.routeError,
      };

    case ADD_USER_TO_SUBSCRIPTION_FAIL: {
      const { routeError } = action;
      return {
        ...state,
        // don't save 400 errors in the state so that we can show them in the form validation errors
        routeError: routeError.status !== 400 ? routeError : null,
      };
    }

    case LOGIN_SUCCESS:
    case SOCIAL_LOGIN_SUCCESS:
    case GET_AUTH_USER_SUCCESS: {
      const { subscriptions, users } = action.result.entities;
      const user = users[action.result.result];
      let { last_active_subscription_id: activeSubscriptionId } = user;

      // the meta is only available on the login response, not when retrieving the user details
      if (user.meta) {
        const { access_token: accessToken } = user.meta.auth;
        const authorizedSubscriptions = jwtDecode(accessToken).subscriptions ?? [];

        // user is not a super admin and their token includes the authorized subscriptions
        if (authorizedSubscriptions) {
          const isAuthorized = !!_.find(authorizedSubscriptions, { id: activeSubscriptionId });

          // If the last active subscription is not authorized, default to the first authorized subscription
          // in the list.
          if (!activeSubscriptionId || !isAuthorized) {
            log.info('Default to the first available authorized subscription');
            [activeSubscriptionId] = authorizedSubscriptions.map((subscription) => subscription.id);
          }
        }
      }

      // fall back to the first user subscriptions (e.g. for a super admin without authorized
      // subscriptions)
      if (!activeSubscriptionId) {
        log.info('Default to the first available user subscription');
        [activeSubscriptionId] = Object.keys(subscriptions);
      }

      log.info(`Set active subscription to ${activeSubscriptionId}`);

      if (!activeSubscriptionId) {
        log.error(`The active subscription was not found in the subscriptions for user with id ${user.id}`, {
          user,
          subscriptions,
        });
      }

      return {
        ...state,
        entities: _.merge({}, state.entities, subscriptions),
        activeSubscriptionId: parseInt(activeSubscriptionId, 10),
      };
    }

    case LOAD_INVITED_USER_SUCCESS: {
      const { subscriptions } = action.result.entities;
      const activeSubscriptionId = action.result.result.subscription;

      const subscription = { ...subscriptions[activeSubscriptionId] };
      subscription.user = {
        role: action.result.result.role,
      };

      log.info(`Set active subscription to ${activeSubscriptionId}`);

      return {
        ...state,
        entities: _.merge({}, state.entities, { [activeSubscriptionId]: subscription }),
        activeSubscriptionId: parseInt(activeSubscriptionId, 10),
      };
    }

    case REGISTRATION_SUCCESS: {
      const activeSubscriptionId = parseInt(action.result.id, 10);
      const subscription = {
        ..._.omit(action.result, ['meta', 'creator']),
        user: {
          role: action.result.user.role,
        },
      };

      return {
        ...state,
        entities: _.merge({}, state.entities, { [activeSubscriptionId]: subscription }),
        activeSubscriptionId,
      };
    }

    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        isUpdatingSubscription: true,
      };
    case UPDATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        isUpdatingSubscription: false,
      };
    case UPDATE_SUBSCRIPTION_SUCCESS: {
      const subscription = action.result.entities.subscriptions[action.result.result];

      return {
        ...state,
        isUpdatingSubscription: false,
        entities: {
          ...state.entities,
          [subscription.id]: {
            ..._.get(state.entities, subscription.id, {}),
            ...subscription,
          },
        },
      };
    }

    case SET_UPGRADE_SUBSCRIPTION_STATUS:
      return {
        ...state,
        isUpgradingSubscription: action.isUpgradingSubscription,
      };
    case UPGRADE_SUBSCRIPTION:
      return {
        ...state,
        isUpgradingSubscription: true,
      };
    case UPGRADE_SUBSCRIPTION_FAIL: {
      const upgradeError = action.error.output;

      return {
        ...state,
        isUpgradingSubscription: false,
        upgradeError,
      };
    }
    case UPGRADE_SUBSCRIPTION_SUCCESS: {
      const subscription = action.result;

      return {
        ...state,
        isUpgradingSubscription: false,
        entities: {
          ...state.entities,
          [subscription.id]: {
            ..._.get(state.entities, subscription.id, {}),
            ...subscription,
          },
        },
      };
    }

    case REQUEST_UPGRADE:
      return {
        ...state,
        isRequestingUpgrade: true,
      };
    case REQUEST_UPGRADE_SUCCESS:
    case REQUEST_UPGRADE_FAIL:
      return {
        ...state,
        isRequestingUpgrade: false,
      };

    case SET_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        activeSubscriptionId: action.id,
      };

    case SET_ACTIVE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        routeError: action.routeError,
      };

    case CLEAR_UPGRADE_ERROR:
      return {
        ...state,
        upgradeError: null,
      };

    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        isCancellingSubscription: true,
      };
    case CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        isCancellingSubscription: false,
      };
    case CANCEL_SUBSCRIPTION_SUCCESS: {
      const subscription = action.result;

      return {
        ...state,
        isCancellingSubscription: false,
        entities: {
          ...state.entities,
          [subscription.id]: {
            ..._.get(state.entities, subscription.id, {}),
            ...subscription,
          },
        },
      };
    }

    case REACTIVATE_SUBSCRIPTION:
      return {
        ...state,
        isReactivatingSubscription: true,
        reactivateError: null,
      };
    case REACTIVATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        isReactivatingSubscription: false,
        reactivateError: action.error.output,
      };
    case REACTIVATE_SUBSCRIPTION_SUCCESS: {
      const subscription = action.result;

      return {
        ...state,
        isReactivatingSubscription: false,
        entities: {
          ...state.entities,
          [subscription.id]: {
            ..._.get(state.entities, subscription.id, {}),
            ...subscription,
          },
        },
      };
    }
    case SET_REACTIVATE_SUBSCRIPTION_STATUS:
      return {
        ...state,
        isReactivatingSubscription: action.isReactivatingSubscription,
      };
    case CLEAR_REACTIVATE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        reactivateError: null,
      };

    case UPDATE_USER_SUCCESS:
    case ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.result.entities.subscriptions,
        },
      };

    case ARCHIVE_USERS_SUCCESS:
    case RESTORE_USERS_SUCCESS:
      return {
        ...state,
        entities: _.merge({}, state.entities, _.get(action.result, 'entities.subscriptions')),
      };

    case TRANSFER_ACCOUNT_OWNERSHIP_SUCCESS:
    case UNMAKE_USER_ADMIN_SUCCESS:
    case COLLECT_INVOICE_PAYMENT_SUCCESS: {
      const subscription = action.result;

      return {
        ...state,
        entities: {
          ...state.entities,
          [subscription.id]: {
            ..._.get(state.entities, subscription.id, {}),
            ...subscription,
          },
        },
      };
    }

    case SAVE_SUBJECTS_SUCCESS: {
      const { subscriptionId } = action.data;
      const subscription = state.entities?.[subscriptionId] ?? {};
      const classCount = action.result.length;

      return {
        ...state,
        entities: {
          ...state.entities,
          [subscriptionId]: {
            ...subscription,
            user: {
              ...subscription.user,
              class_count: classCount,
            },
          },
        },
      };
    }

    case UPSERT_WONDE_CONFIGURATION: {
      return {
        ...state,
        isConfiguringEDS: true,
      };
    }

    case UPSERT_WONDE_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        isConfiguringEDS: false,
        externalDataSource: {
          ...action.result,
        },
      };
    }
    case UPSERT_WONDE_CONFIGURATION_FAIL: {
      return {
        ...state,
        isConfiguringEDS: false,
      };
    }

    case LOAD_EDS_DETAILS_SUCCESS: {
      return {
        ...state,
        routeError: null,
        externalDataSource: {
          ...action.result,
        },
      };
    }

    case LOAD_EDS_DETAILS_FAIL:
      return {
        ...state,
        routeError: action.routeError,
      };

    case ACTIVATE_DAILY_SYNC_SUCCESS: {
      return {
        ...state,
        externalDataSource: {
          ...state.externalDataSource,
          status: 'active',
        },
      };
    }

    case DEACTIVATE_DAILY_SYNC_SUCCESS: {
      return {
        ...state,
        externalDataSource: {
          ...state.externalDataSource,
          status: 'inactive',
        },
      };
    }

    default:
      return state;
  }
}

// Selectors
export const isCancellingSubscription = (state: ReduxState) => state.subscriptions.isCancellingSubscription;
export const isReactivatingSubscription = (state: ReduxState) =>
  state.subscriptions.isReactivatingSubscription || isRefreshingToken(state);
export const isSubscriptionsLoaded = (state: ReduxState) => state.subscriptions.isLoaded;
export const isUpdatingSubscription = (state: ReduxState) => state.subscriptions.isUpdatingSubscription;
export const isUserSubscription = (state: ReduxState, id: number) => id in state.subscriptions.entities;
export const isUpgradingSubscription = (state: ReduxState) =>
  state.subscriptions.isUpgradingSubscription || isRefreshingToken(state);
export const getAllSubscriptions = (state: ReduxState) => _.values(state.subscriptions.entities);
export const getActiveSubscriptionId = (state: ReduxState) => state.subscriptions.activeSubscriptionId;
export const getReactivateSubscriptionError = (state: ReduxState) => state.subscriptions.reactivateError;
export const getActiveSubscription = (state: ReduxState) =>
  state.subscriptions.entities[getActiveSubscriptionId(state)];
export const getActiveSubscriptionPlanCustomerType = (state: ReduxState) =>
  _.get(getActiveSubscription(state), 'plan_customer_type');
export const getActiveSubscriptionPlanCode = (state: ReduxState) => _.get(getActiveSubscription(state), 'plan_code');
export const getActiveSubscriptionRegion = (state: ReduxState) => _.get(getActiveSubscription(state), 'region_code');
export const getActiveSubscriptionSchoolId = (state: ReduxState) => _.get(getActiveSubscription(state), 'school_id');
export const getNumSubscriptions = (state: ReduxState) => _.size(state.subscriptions.entities);
export const getActiveSubscriptionCreationDate = (state: ReduxState) =>
  _.get(getActiveSubscription(state), 'created_at');
export const getActiveSubscriptionExpiryDate = (state: ReduxState) => _.get(getActiveSubscription(state), 'ends_at');
export const hasClasses = (state: ReduxState) => getActiveSubscription(state)?.user.class_count > 0;

export const getActiveAndPendingSubscriptions = (
  state: ReduxState,
  { includeSchool = false }: { includeSchool: boolean } = {}
): $ReadOnlyArray<Subscription> =>
  _.sortBy(
    getAllSubscriptions(state)
      .filter((subscription) =>
        [USER_ACCOUNT_STATUSES.legacy.active, USER_ACCOUNT_STATUSES.legacy.invited].includes(
          subscription.user.subscription_status
        )
      )
      .map((s) => ({
        ...s,
        school: includeSchool ? getSchoolById(state, s.school_id) : null,
      })),
    'user.subscription_status'
  );
export const getUserOtherActiveAndPendingSubscriptions = (state: ReduxState): $ReadOnlyArray<Subscription> =>
  _.reject(
    getActiveAndPendingSubscriptions(state),
    (subscription) => subscription.id === getActiveSubscriptionId(state)
  );
export const getActiveSubscriptionsCount = (state: ReduxState) =>
  getAllSubscriptions(state).filter((s) => s.user.subscription_status === USER_ACCOUNT_STATUSES.legacy.active).length;

export const getActiveSubscriptionStatus = (state: ReduxState) => _.get(getActiveSubscription(state), 'status');
export const isSubscriptionActive = (state: ReduxState) =>
  getActiveSubscriptionStatus(state) === SUBSCRIPTION_STATUSES.active;
export const isSubscriptionCancelled = (state: ReduxState) =>
  getActiveSubscriptionStatus(state) === SUBSCRIPTION_STATUSES.cancelled;
export const isSubscriptionFuture = (state: ReduxState) =>
  getActiveSubscriptionStatus(state) === SUBSCRIPTION_STATUSES.future;
export const isSubscriptionNonRenewing = (state: ReduxState) =>
  getActiveSubscriptionStatus(state) === SUBSCRIPTION_STATUSES.non_renewing;

export const getEDSDetails = (state: ReduxState) => state.subscriptions.externalDataSource;
export const isConfiguringEDS = (state: ReduxState) => state.subscriptions.isConfiguringEDS;

// Roles selectors
export const getUserRole = (state: ReduxState) => _.get(getActiveSubscription(state), 'user.role', null);
export const isLoggedInAsAccountManager = (state: ReduxState) => getUserRole(state) === ROLES.accountManager;
export const isLoggedInAsParent = (state: ReduxState) => getUserRole(state) === ROLES.familyAccountOwner;
export const isLoggedInAsAccountOwner = (state: ReduxState) => getUserRole(state) === ROLES.accountOwner;
export const isLoggedInAsAccountAdmin = (state: ReduxState) => getUserRole(state) === ROLES.accountAdmin;
export const isLoggedInAsStudent = (state: ReduxState) => getUserRole(state) === ROLES.student;
export const isLoggedInAsTeacher = (state: ReduxState) => getUserRole(state) === ROLES.teacher;
export const isAdminOrAbove = (state: ReduxState) => {
  const userRole = getUserRole(state);
  return (
    userRole === ROLES.accountAdmin ||
    userRole === ROLES.accountOwner ||
    userRole === ROLES.familyAccountOwner ||
    userRole === ROLES.accountManager
  );
};

// Plan selectors
export const isPaidFamilyPlan = (state: ReduxState) => _.has(PLANS.family, getActiveSubscriptionPlanCode(state));
export const isPaidStudentPlan = (state: ReduxState) => _.has(PLANS.retail, getActiveSubscriptionPlanCode(state));
export const isPaidRetailPlan = (state: ReduxState) => isPaidStudentPlan(state) || isPaidFamilyPlan(state);
export const isPaidSchoolPlan = (state: ReduxState) => _.has(PLANS.school, getActiveSubscriptionPlanCode(state));
export const isFreeFamilyPlan = (state: ReduxState) => _.has(PLANS.free.family, getActiveSubscriptionPlanCode(state));
export const isFreeStudentPlan = (state: ReduxState) => _.has(PLANS.free.student, getActiveSubscriptionPlanCode(state));
export const isFreeSchoolPlan = (state: ReduxState) => _.has(PLANS.free.school, getActiveSubscriptionPlanCode(state));
export const isFreePlan = (state: ReduxState) =>
  isFreeStudentPlan(state) || isFreeSchoolPlan(state) || isFreeFamilyPlan(state);
export const isFamilyPlan = (state: ReduxState) => isFreeFamilyPlan(state) || isPaidFamilyPlan(state);
export const isRetailPlan = (state: ReduxState) =>
  isPaidRetailPlan(state) || isFreeStudentPlan(state) || isFamilyPlan(state);
export const isSchoolPlan = (state: ReduxState) => isFreeSchoolPlan(state) || isPaidSchoolPlan(state);
export const isUsingTooManySeats = (state: ReduxState) => {
  if (!isPaidSchoolPlan(state)) return false;
  const subscription = getActiveSubscription(state);
  return subscription.quantity_used > subscription.quantity;
};
export const isLoggedInAsChild = (state: ReduxState) => isFamilyPlan(state) && isLoggedInAsStudent(state);
export const getOwnerDetails = (state: ReduxState) => {
  const subscription = getActiveSubscription(state);
  return subscription?.owner;
};

// Permissions selectors
export const getActiveSubscriptionName = (state: ReduxState) => {
  const subscription = getActiveSubscription(state);
  if (!subscription) return null;
  return subscription.name;
};
export const canManageBilling = (state: ReduxState) => {
  return (
    [ROLES.accountManager, ROLES.accountOwner].includes(getUserRole(state)) ||
    (isRetailPlan(state) && !isLoggedInAsChild(state)) ||
    (isFreeSchoolPlan(state) && isLoggedInAsAccountOwner(state))
  );
};
export const canToggleSetupMode = (state: ReduxState) => {
  return [ROLES.accountManager, ROLES.accountOwner].includes(getUserRole(state));
};

// Payment sources selectors
export const hasPaymentSource = (state: ReduxState) => getActiveSubscription(state)?.has_payment_source;
export const getAllPaymentSources = (state: ReduxState) => getActiveSubscription(state)?.payment_sources;
export const isPaymentSourceRequired = (state: ReduxState) =>
  isPaidRetailPlan(state) && !isSubscriptionCancelled(state) && !isSubscriptionNonRenewing(state);

// Trial selectors
export const isTrialSubscription = (state: ReduxState) =>
  getActiveSubscription(state)?.status === SUBSCRIPTION_STATUSES.in_trial;
export const isPaidSchoolTrialSubscription = (state: ReduxState) =>
  isPaidSchoolPlan(state) && isTrialSubscription(state);
export const getTrialEndDate = (state: ReduxState) => _.get(getActiveSubscription(state), 'trial_ends_at');
export const hasRequestedUpgrade = (state: ReduxState) => _.get(state, 'subscriptions.isRequestingUpgrade');

// Setup mode

export const isAccountOverdueSince = (state: ReduxState) => getActiveSubscription(state)?.due_since || undefined;
export const isPaymentOverdueAlertVisible = (state: ReduxState) =>
  !isSubscriptionCancelled(state) && !!isAccountOverdueSince(state);

// Feature flags (from Nucleus)
export const isFeatureFlagEnabled = (state: ReduxState, flag: string) => {
  const activeSubscription = getActiveSubscription(state);
  const enabledFeatures = activeSubscription?.enabled_features ?? [];
  return enabledFeatures.includes(flag);
};

// return if the current subscription is using SAML
export const isEnforcingSaml = (state: ReduxState) => {
  const activeSubscription = getActiveSubscription(state);
  return !!activeSubscription?.has_saml_auth;
};

export const isActiveSubscriptionInSetupMode = (state: ReduxState) =>
  isFeatureFlagEnabled(state, featureFlags.setup_mode);
export const isInsightsEnabled = (state: ReduxState) => isFeatureFlagEnabled(state, featureFlags.insights);
export const isDataExportEnabled = (state: ReduxState) =>
  !isFeatureFlagEnabled(state, featureFlags.denyStudentDataExport);
export const isCrossSubjectContentSharingEnabled = (state: ReduxState) =>
  isFeatureFlagEnabled(state, featureFlags.allowCrossSubjectContentSharing);

export const isEDSMappingProcessing = (state: ReduxState) => {
  const externalDataSource = getEDSDetails(state);
  return externalDataSource?.mapping_status === 'started';
};
export const isEDSMappingCreated = (state: ReduxState) => {
  const externalDataSource = getEDSDetails(state);
  return externalDataSource?.mapping_status === 'created';
};
export const isEDSMappingValidating = (state: ReduxState) => {
  const externalDataSource = getEDSDetails(state);
  return externalDataSource?.mapping_status === 'validating';
};
export const isEDSMappingValid = (state: ReduxState) => {
  const externalDataSource = getEDSDetails(state);
  return externalDataSource?.mapping_status === 'valid';
};

export const isAutoCollectionOn = (state: ReduxState) => {
  const subscription = getActiveSubscription(state);
  return subscription?.auto_collection === 'on';
};
