// @flow
import type { ChallengeTier, PostType } from 'src/types';
import Duration from 'src/components/Duration/Duration';
import postTypes from 'src/constants/postTypes';
import { getChallengeLabel } from 'src/utils/challenge';

type Props = {
  challengeTier?: ?ChallengeTier,
  duration: number,
  type: PostType,
};

const durationWithSuffix = (postType: PostType, duration: string, challengeTier?: ?ChallengeTier) => {
  if (postType === postTypes.challenge) {
    return `${duration} ${getChallengeLabel(challengeTier)}`;
  }
  if (postType === postTypes.text) {
    return `${duration} read`;
  }
  if (postType === postTypes.revision) {
    return `${duration} revision`;
  }
  return `${duration} video`;
};

export default function PostDuration(props: Props) {
  const { challengeTier, type } = props;

  return (
    <Duration duration={props.duration} format="minutes" testHook="post-duration">
      {(formattedDuration) => durationWithSuffix(type, formattedDuration, challengeTier)}
    </Duration>
  );
}
