// @flow
import { useState } from 'react';
import { connect } from 'react-redux';
import { type ContextRouter, type Location, withRouter } from 'react-router';
import Helmet from 'react-helmet';
import { HideVisually, Stack, useToast } from '@getatomi/neon';
import _ from 'lodash';

import type { BoundAction, GetState, Dispatch, OwnerDetails, ReduxState, UserType } from 'src/types';
import Logger from 'src/utils/Logger';
import Button from 'src/components/Button/Button';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout/LogoOnlyLayout';
import { loadInvitedUserDetails } from 'src/actions/auth';
import { activateUser } from 'src/actions/registration';
import {
  getAuthDetails,
  getInviteError,
  getUserSchoolName,
  isInvitedUserLoaded,
  isUserInvited,
} from 'src/reducers/auth';
import {
  isLoggedInAsChild,
  isLoggedInAsStudent,
  getOwnerDetails,
  getUserRole,
  getActiveSubscriptionId,
  getActiveSubscriptionName,
  isFreeSchoolPlan as isFreeSchoolPlanSelector,
} from 'src/reducers/subscriptions';
import connectData from 'src/decorators/connectData';
import { getDashboardUrl } from 'src/utils/routes';
import errorTypes from 'src/constants/errorTypes';

import WelcomeBox from '../WelcomeBox/WelcomeBox';
import RedirectInvalidInvite from './RedirectInvalidInvite';

const log = new Logger('registration/invited');

type Params = {
  inviteCode: string,
};
type Props = {
  params: Params,
};
export type InjectedProps = Props & {
  accountName: string,
  activateUserAction: BoundAction<typeof activateUser>,
  authDetails: {
    access_token: string,
    expiry: string,
    refresh_token: string,
  },
  isChild: boolean,
  isFreeSchoolPlan: boolean,
  isNewUser: boolean,
  isStudent: boolean,
  ownerDetails?: OwnerDetails,
  router: ContextRouter,
  schoolName?: string,
  subscriptionId?: number,
  user?: UserType,
  userRole?: string,
};

const fetchData = (getState: GetState, dispatch: Dispatch, location: Location, params: Params) => {
  const state = getState();
  const { inviteCode } = params;

  if (!isInvitedUserLoaded(state, inviteCode)) {
    return dispatch(loadInvitedUserDetails(inviteCode));
  }
};

const mapStateToProps = (state: ReduxState) => {
  if (getInviteError(state)) {
    return {};
  }

  return {
    authDetails: getAuthDetails(state),
    accountName: getActiveSubscriptionName(state),
    isChild: isLoggedInAsChild(state),
    isNewUser: isUserInvited(state),
    isFreeSchoolPlan: isFreeSchoolPlanSelector(state),
    isStudent: isLoggedInAsStudent(state),
    ownerDetails: getOwnerDetails(state),
    schoolName: getUserSchoolName(state),
    subscriptionId: getActiveSubscriptionId(state),
    user: state.auth.user,
    userRole: getUserRole(state),
  };
};

export function RegistrationInvitedUser(props: InjectedProps) {
  const {
    authDetails,
    params: { inviteCode },
    activateUserAction,
    user,
    params,
    userRole,
    isChild,
    isNewUser,
    isFreeSchoolPlan,
    isStudent,
    ownerDetails,
    router,
    subscriptionId,
    accountName,
    schoolName,
  } = props;

  const toast = useToast();
  const [isActivatingUser, setIsActivatingUser] = useState<boolean>(false);

  const onClickHandler = async (e: SyntheticEvent<>) => {
    e.preventDefault();
    setIsActivatingUser(true);

    try {
      const { access_token: accessToken, refresh_token: refreshToken } = authDetails;
      await activateUserAction(inviteCode, accessToken, refreshToken);
      router.push(getDashboardUrl(subscriptionId));
    } catch (error) {
      log.warn('Activating an invited user failed', error);
      setIsActivatingUser(false);

      if (error.output && error.output.errorType === errorTypes.ExpiredAccessTokenException) {
        toast.error(
          <Stack spacing="spacingSmall2X">
            <p>
              Oops! It looks like we could not verify your details. Please refresh your page so we can get you back on
              track.
            </p>
            <Button variant="text" onClick={() => window.location.reload(true)}>
              Refresh the page
            </Button>
          </Stack>
        );
      } else {
        toast.error('There was an error activating your account. Please try again later.');
      }
    }
  };

  const title = `${isFreeSchoolPlan ? 'Free ' : ''}${_.upperFirst(userRole)} Registration`;

  return (
    <RedirectInvalidInvite>
      <LogoOnlyLayout withContainer withGreyBg hideLogoOnMobile>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {user ? (
          <WelcomeBox
            isInvited
            accountName={accountName}
            isLoading={isActivatingUser}
            ctaOnClick={!isNewUser ? onClickHandler : undefined}
            ctaUrl={isNewUser ? `/register/invite/step-2/${params.inviteCode}` : undefined}
            isChild={isChild}
            isFreeSchoolPlan={isFreeSchoolPlan}
            isStudent={isStudent}
            ownerDetails={ownerDetails}
            schoolName={schoolName}
            user={user}
            testHook="invited-user-welcome-box"
          />
        ) : (
          <HideVisually>
            <h1>{title}</h1>
          </HideVisually>
        )}
      </LogoOnlyLayout>
    </RedirectInvalidInvite>
  );
}

export default withRouter(
  connectData(fetchData)(connect(mapStateToProps, { activateUserAction: activateUser })(RegistrationInvitedUser))
);
