// @flow
import invariant from 'invariant';
import { useQuery, type ApolloError } from '@apollo/client';

import type { ChallengeTier, LessonProgress, PostType } from 'src/types';
import type {
  GetModule,
  GetModuleVariables,
  GetModule_me_account_class_topics as GqlTopic,
  GetModule_me_account_class_topics_Category_lessons as GqlStudentLesson,
  GetModule_me_account_class_topics_ClassCategory_lessons as GqlClassLesson,
} from 'src/graphql/types/generated/GetModule';
import postTypes from 'src/constants/postTypes';
import { getPostUrl } from 'src/utils/routes';

import GET_MODULE from './GetModule.graphql';

type Props = {|
  accountId: string,
  classId: string,
  moduleId: string,
|};

export type UserData = {|
  id: string,
  isChild: boolean,
  isFreePlan: boolean,
  isSchoolPlan: boolean,
  isStudent: boolean,
|};

type ClassData = {|
  name: string,
  studentCount: number,
|};

type SubjectData = {|
  code: string,
  color: string,
  groupCode: string,
  name: string,
|};

type LabelData = {|
  id: string,
  name: string,
|};

export type LessonData = {|
  challengeTier: ?ChallengeTier,
  completionCount: ?number,
  duration: number,
  id: string,
  isLocked: boolean,
  name: string,
  studentLessonMetrics: ?LessonProgress,
  type: PostType,
  url: string,
|};

type SubtopicData = {|
  id: string,
  labels: $ReadOnlyArray<LabelData>,
  lessons: $ReadOnlyArray<LessonData>,
  name: string,
|};

export type TopicData = {|
  id: string,
  labels: $ReadOnlyArray<LabelData>,
  lessons: $ReadOnlyArray<LessonData>,
  name: string,
  subtopics: $ReadOnlyArray<SubtopicData>,
|};

type ModuleData = {|
  name: string,
  topics: $ReadOnlyArray<TopicData>,
|};

export type TransformedData = {|
  class: ClassData,
  lessons: $ReadOnlyArray<LessonData>,
  module: ModuleData,
  refetch: () => Promise<any>,
  region: string,
  subject: SubjectData,
  user: UserData,
|};

type Output = {|
  data: ?TransformedData,
  error: ?ApolloError,
  loading: boolean,
|};

export function transformClassLesson(rawLesson: GqlClassLesson, getLessonUrl: (id: string) => string): ?LessonData {
  if (
    rawLesson.__typename !== 'ClassChallengeLesson' &&
    rawLesson.__typename !== 'ClassLockedChallengeLesson' &&
    rawLesson.__typename !== 'ClassVideoLesson' &&
    rawLesson.__typename !== 'ClassLockedVideoLesson' &&
    rawLesson.__typename !== 'ClassTextLesson' &&
    rawLesson.__typename !== 'ClassLockedTextLesson'
  ) {
    return null;
  }

  let type;
  switch (rawLesson.__typename) {
    case 'ClassChallengeLesson':
    case 'ClassLockedChallengeLesson': {
      type = postTypes.challenge;
      break;
    }
    case 'ClassVideoLesson':
    case 'ClassLockedVideoLesson': {
      type = postTypes.video;
      break;
    }
    default: {
      type = postTypes.text;
    }
  }

  const { id, name, duration } = rawLesson;
  const challengeTier = rawLesson.__typename === 'ClassChallengeLesson' ? rawLesson.tier : null;

  const isLocked = rawLesson.__typename.includes('Locked');

  const completionCount =
    rawLesson.__typename === 'ClassTextLesson' ||
    rawLesson.__typename === 'ClassVideoLesson' ||
    rawLesson.__typename === 'ClassChallengeLesson'
      ? rawLesson.metrics.progress.engagedStudentCount
      : null;

  return {
    id,
    name,
    duration,
    challengeTier,
    type,
    isLocked,
    completionCount,
    studentLessonMetrics: null,
    url: getLessonUrl(id),
  };
}

export function transformStudentLesson(rawLesson: GqlStudentLesson, getLessonUrl: (id: string) => string): ?LessonData {
  if (
    rawLesson.__typename !== 'StudentChallengeLesson' &&
    rawLesson.__typename !== 'StudentLockedChallengeLesson' &&
    rawLesson.__typename !== 'StudentVideoLesson' &&
    rawLesson.__typename !== 'StudentLockedVideoLesson' &&
    rawLesson.__typename !== 'StudentTextLesson' &&
    rawLesson.__typename !== 'StudentLockedTextLesson'
  ) {
    return null;
  }

  let type;
  switch (rawLesson.__typename) {
    case 'StudentChallengeLesson':
    case 'StudentLockedChallengeLesson': {
      type = postTypes.challenge;
      break;
    }
    case 'StudentVideoLesson':
    case 'StudentLockedVideoLesson': {
      type = postTypes.video;
      break;
    }
    default: {
      type = postTypes.text;
    }
  }

  const { id, name, duration } = rawLesson;
  const challengeTier = rawLesson.__typename === 'StudentChallengeLesson' ? rawLesson.tier : null;

  const isLocked = rawLesson.__typename.includes('Locked');

  let studentLessonMetrics = null;
  if (rawLesson.__typename === 'StudentChallengeLesson') {
    const { strength, mark, classAverageMark, regionAverageMark, schoolAverageMark } = rawLesson.metrics.assessment;
    if (strength != null) {
      studentLessonMetrics = {
        __typename: 'Progress',
        strength,
        mark,
        classAverageMark,
        postAverageMark: regionAverageMark,
        subscriptionAverageMark: schoolAverageMark,
      };
    }
  } else if (rawLesson.__typename === 'StudentTextLesson' || rawLesson.__typename === 'StudentVideoLesson') {
    const completions = rawLesson.metrics.progress.cumulativeCompletionCount;
    if (completions > 0) {
      studentLessonMetrics = {
        __typename: 'Progress',
        completions,
      };
    }
  }

  return {
    id,
    name,
    duration,
    challengeTier,
    type,
    isLocked,
    completionCount: null,
    studentLessonMetrics,
    url: getLessonUrl(id),
  };
}

function transformTopic(topic: GqlTopic, getLessonUrl: (id: string) => string): ?TopicData {
  if (topic.__typename === 'ClassCategory') {
    const lessons = topic.lessons
      .map((lesson) => {
        return transformClassLesson(lesson, getLessonUrl);
      })
      .filter(Boolean);

    const subtopics = topic.subtopics
      .map((subtopic) => {
        if (subtopic.__typename !== 'ClassCategory') {
          return;
        }
        return {
          id: subtopic.id,
          name: subtopic.name,
          labels: subtopic.labels.map(({ id, name }) => ({ id, name })),
          lessons: subtopic.lessons
            .map((lesson) => {
              return transformClassLesson(lesson, getLessonUrl);
            })
            .filter(Boolean),
        };
      })
      .filter(Boolean);

    return {
      id: topic.id,
      name: topic.name,
      labels: topic.labels.map(({ id, name }) => ({ id, name })),
      lessons,
      subtopics,
    };
  }

  if (topic.__typename === 'Category') {
    const lessons = topic.lessons
      .map((lesson) => {
        return transformStudentLesson(lesson, getLessonUrl);
      })
      .filter(Boolean);

    const subtopics = topic.subtopics
      .map((subtopic) => {
        if (subtopic.__typename !== 'Category') {
          return;
        }
        return {
          id: subtopic.id,
          name: subtopic.name,
          labels: subtopic.labels.map(({ id, name }) => ({ id, name })),
          lessons: subtopic.lessons
            .map((lesson) => {
              return transformStudentLesson(lesson, getLessonUrl);
            })
            .filter(Boolean),
        };
      })
      .filter(Boolean);

    return {
      id: topic.id,
      name: topic.name,
      labels: topic.labels.map(({ id, name }) => ({ id, name })),
      lessons,
      subtopics,
    };
  }
}

function extractLessons(topics: $ReadOnlyArray<TopicData>): $ReadOnlyArray<LessonData> {
  const lessons = [];

  for (const topic of topics) {
    for (const topicLesson of topic.lessons) {
      lessons.push(topicLesson);
    }
    for (const subtopic of topic.subtopics) {
      for (const subtopicLesson of subtopic.lessons) {
        lessons.push(subtopicLesson);
      }
    }
  }

  return lessons;
}

export default function useGetModule(props: Props): Output {
  const { accountId, classId, moduleId } = props;

  const {
    data: queryData,
    error,
    loading,
    refetch,
  } = useQuery<GetModule, GetModuleVariables>(GET_MODULE, {
    variables: {
      accountId,
      classId,
      moduleId,
    },
  });

  if (error || (loading && !queryData)) {
    return { data: undefined, error, loading };
  }

  const userData = queryData?.me;
  const accountData = userData?.account;
  const classData = accountData?.class;
  const subjectData = classData?.subject;
  const moduleData = classData?.module;

  invariant(userData && accountData && classData && subjectData && moduleData, 'Response data should be defined');

  const topics = classData.topics
    .map((topic) => {
      const getLessonUrl = (lessonId: string): string => {
        return getPostUrl(accountData.id, classData.id, moduleData.id, lessonId);
      };
      return transformTopic(topic, getLessonUrl);
    })
    .filter(Boolean);
  const lessons = extractLessons(topics);

  const data = {
    user: {
      id: userData.id,
      isStudent: accountData.role === 'STUDENT',
      isChild: accountData.role === 'STUDENT' && accountData.plan.customerType === 'RETAIL_FAMILY',
      isSchoolPlan: accountData.plan.customerType === 'SCHOOL',
      isFreePlan: accountData.plan.isFree,
    },
    class: {
      name: classData.name,
      studentCount: classData.students.length,
    },
    subject: {
      code: subjectData.code,
      groupCode: subjectData.groupCode,
      color: subjectData.color,
      name: subjectData.shortName,
    },
    module: {
      name: moduleData.name,
      topics,
    },
    lessons,
    refetch,
    region: accountData.region.code,
  };

  return {
    data,
    error: null,
    loading: false,
  };
}
