// @flow
import _ from 'lodash';

import challengeQuestionTypes from 'src/constants/questionTypes';
import { isExtendedResponse, isShortAnswer } from 'src/utils/question';

import {
  contentTypes,
  studentSessionDurations,
  teacherSessionDurations,
  sessionLengths,
  questionTypes,
} from './constants';
import type {
  Challenge,
  ContentTypeFilter,
  Module,
  Question,
  SessionDuration,
  SessionLengthFilter,
  Topic,
} from './types';

export function disabledProps(
  isDisabled: boolean,
  text: string = `Based on previous selections, this option is not available.`
) {
  return {
    isDisabled,
    tooltip: isDisabled ? text : undefined,
  };
}

export function getQuestionsAvailable(challenges: $ReadOnlyArray<Challenge>) {
  // exclude text and video snippets from revisions
  const excludedQuestionTypes = [challengeQuestionTypes.TEXT, challengeQuestionTypes.VIDEO];
  const questions: Array<Question> = _.flatMap(challenges, (challenge: Challenge) => challenge.questions).filter(
    ({ type }) => !excludedQuestionTypes.includes(type)
  );
  return questions;
}

export function getChallenges({
  topics,
  onlyFromTopics,
  onlyWithProgress,
}: {
  onlyFromTopics?: Array<string>,
  onlyWithProgress?: boolean,
  topics: $ReadOnlyArray<Topic>,
}) {
  const challenges = [];
  topics.forEach((topic) => {
    if (onlyFromTopics && !onlyFromTopics.includes(topic.id)) {
      return;
    }
    if (onlyWithProgress && topic.challenges.every((challenge) => !challenge.hasProgress)) {
      return;
    }
    challenges.push(...topic.challenges);
  });
  return challenges;
}

export function getSessionDuration(isTeacher: ?boolean, sessionDurationKey: SessionLengthFilter): SessionDuration {
  return isTeacher ? teacherSessionDurations[sessionDurationKey] : studentSessionDurations[sessionDurationKey];
}

export function getDataFromPosts({
  contentType,
  selectedTopics,
  subjectTree,
  isTeacher,
}: {
  contentType: ContentTypeFilter,
  isTeacher?: boolean,
  selectedTopics: Array<string>,
  subjectTree: $ReadOnlyArray<Module>,
}) {
  const challenges = getChallenges({
    topics: _.flatten(subjectTree.map((module) => module.topics.map((topic) => topic))),
    onlyFromTopics: contentType === contentTypes.custom ? selectedTopics : undefined,
    onlyWithProgress: contentType === contentTypes.covered,
  });

  const questions = getQuestionsAvailable(challenges);
  const totalDuration = _.sumBy(challenges, 'duration');

  return {
    questions,
    availableQuestionTypes: {
      [questionTypes.multiChoice]: _.some(questions, { type: challengeQuestionTypes.MULTIPLE_CHOICE }),
      [questionTypes.dragAndDrop]: _.some(questions, { type: challengeQuestionTypes.DRAG_AND_DROP }),
      [questionTypes.exactAnswer]: _.some(questions, { type: challengeQuestionTypes.EXACT_ANSWER }),
      [questionTypes.shortAnswer]: _.some(questions, ({ type, marks }) => isShortAnswer({ type, marks })),
      [questionTypes.extendedResponse]: _.some(questions, ({ type, marks }) => isExtendedResponse({ type, marks })),
    },
    availableDurations: {
      [sessionLengths.short]: true,
      [sessionLengths.medium]: totalDuration >= getSessionDuration(isTeacher, sessionLengths.medium).minDuration,
      [sessionLengths.long]: totalDuration >= getSessionDuration(isTeacher, sessionLengths.long).minDuration,
    },
  };
}

export function getAllTopics(subjectTree: ?Array<Module>): Array<Topic> {
  const allTopics = _.flatMap(subjectTree, 'topics');
  return allTopics;
}

export function getAllTopicsWithQuestions(subjectTree: ?Array<Module>): Array<string> {
  const allTopics = getAllTopics(subjectTree);
  const allTopicsWithQuestions = allTopics.filter(({ challenges }) => _.flatMap(challenges, 'questions').length > 0);
  return allTopicsWithQuestions.map(({ id }) => id);
}

export function getDurationRange(durationMidPoint: number): {
  maxDuration: number,
  minDuration: number,
} {
  const offset = 0.1;

  return {
    minDuration: _.round(durationMidPoint * (1 - offset)),
    maxDuration: _.round(durationMidPoint * (1 + offset)),
  };
}

export function convertTimeLimitToSeconds(timeLimitMinutes: string): number {
  return parseInt(timeLimitMinutes.trim(), 10) * 60;
}
