// @flow
import { Component } from 'react';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { HelpText, IconAlertError, IconAlertWarning, Text } from '@getatomi/neon';

import Logger from 'src/utils/Logger';
import validators from 'src/utils/validators';
import errorMessageVariants from 'src/constants/errorMessageVariants';

import styles from './Input.module.scss';

const log = new Logger('components/Input');
const cx = classNames.bind(styles);

const defaultErrorMessage = 'This field is required';

export type BaseInputProps = {
  ariaLabel?: string,
  autoCapitalize?: string,
  autoComplete?: string,
  autoCorrect?: string,
  autoFocus?: boolean,
  className?: string,
  disabled?: boolean,
  initialValue?: ?string,
  isMandatory?: boolean,
  label?: React.Node,
  maxLength?: number,
  minLength?: number,
  name: string,
  onBlur?: (name: string, value: string) => mixed,
  onChange?: (name: string, value: string) => mixed,
  pattern?: string,
  patternMismatchErrorMessage?: string,
  placeholder?: string,
  testHook?: string,
  title?: string,
};

type Props = BaseInputProps & {
  customError?: {
    message: ?string,
    variant: ?$Keys<typeof errorMessageVariants>,
  },
  inputInfo?: React.Node,
  inputOverlayInfo?: React.Node,
  type: 'text' | 'tel' | 'password' | 'date' | 'email' | 'url',
  withErrorIcon?: boolean,
};

type State = {
  errorMessage?: string,
  hasError: boolean,
  value?: string,
};

export default class Input extends Component<Props, State> {
  inputField: ?HTMLInputElement;

  static defaultProps = {
    type: 'text',
    isMandatory: false,
    withErrorIcon: true,
    disabled: false, // eslint-disable-line react/default-props-match-prop-types
  };

  state = {
    value: this.props.initialValue || '',
    hasError: false,
    errorMessage: defaultErrorMessage,
  };

  sanitizeValue(value: string) {
    return this.props.type === 'password' ? value : _.trim(value);
  }

  onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const { onChange } = this.props;
    let { hasError } = this.state;

    // hide errors when the user starts typing
    if (hasError && value.trim()) {
      hasError = false;
    }

    this.setState({ value, hasError });

    if (onChange) {
      onChange(this.props.name, this.sanitizeValue(value));
    }
  };

  onBlur = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;
    const value = this.sanitizeValue(e.currentTarget.value);

    this.setState({ value });

    if (onBlur) {
      onBlur(this.props.name, value);
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  validate = () => {
    if (!this.inputField) return null;

    const { customError } = this.props;

    // input has a custom error with an error variant, prevent any further validation
    if (customError && customError.variant === errorMessageVariants.error) {
      return false;
    }

    let isValid = true;
    let errorMessage = defaultErrorMessage;
    const value = this.inputField.value.trim();

    // eslint-disable-next-line default-case
    switch (this.props.type) {
      case 'tel':
        return this.validateCustom('tel');
      case 'email': {
        return this.validateCustom('email');
      }
    }

    if (this.props.isMandatory) {
      isValid = !!value;
    }

    const { minLength, title } = this.props;

    if (isValid && minLength && value.length < minLength) {
      // minLength validation doesn't work cross browsers, do custom one instead
      isValid = false;
      errorMessage = title
        ? `Your ${title} must contain at least ${minLength} characters.`
        : `Length should be minimum ${minLength} characters`;
    } else if (this.props.pattern && this.inputField && !this.inputField.checkValidity()) {
      isValid = false;
      errorMessage = this.props.patternMismatchErrorMessage;
    }

    log.info(`IsValid ${this.props.name} ? ${isValid.toString()}`);
    this.setState({ hasError: !isValid, errorMessage });
    return isValid;
  };

  validateCustom = (validator: string) => {
    const input = this.inputField;

    if (input) {
      const value = input.value.trim();
      const { pattern, patternMismatchErrorMessage } = validators[validator];
      const isValidValue = pattern.test(value);
      let isValid = true;
      let errorMessage = '';

      if (value && !isValidValue) {
        isValid = false;
        errorMessage = patternMismatchErrorMessage;
      }

      if (!value && this.props.isMandatory) {
        isValid = false;
        errorMessage = defaultErrorMessage;
      }

      if (!isValid) {
        log.info(`IsValid ${this.props.name} ? ${isValid.toString()} ${errorMessage}`);
        this.setState({ hasError: !isValid, errorMessage });
      }

      return isValid;
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  clear = () => {
    this.setState({
      value: '',
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  reset = () => {
    this.setState({
      value: this.props.initialValue || '',
    });
  };

  getErrorMessage = () => {
    const { customError } = this.props;
    const { errorMessage, hasError } = this.state;

    // validation error takes precedence over the custom error
    if (hasError) {
      return errorMessage;
    }
    if (customError) {
      return customError.message;
    }
  };

  getErrorType = () => {
    const { customError } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return errorMessageVariants.error;
    }
    if (customError) {
      return customError.variant;
    }
  };

  render() {
    const {
      ariaLabel,
      customError,
      label,
      name,
      type,
      minLength,
      maxLength,
      pattern,
      placeholder,
      disabled,
      title,
      autoComplete,
      autoCapitalize,
      autoCorrect,
      autoFocus,
      inputInfo,
      inputOverlayInfo,
      testHook,
      withErrorIcon,
    } = this.props;
    const { hasError, value } = this.state;
    const hasValidationError = hasError || !!customError;
    const showErrorIcon = withErrorIcon && !inputOverlayInfo && hasValidationError;
    const errorType = this.getErrorType();

    return (
      <div className={cx('root', this.props.className)} data-test={testHook}>
        {label && (
          <Text
            as="label"
            color="colorTextSubtle"
            fontFamily="fontFamilySystem"
            fontSize="fontSizeSmall1X"
            fontWeight="fontWeightMedium"
            display="inline-block"
            htmlFor={name}
            marginBottom="spacingSmall1X"
          >
            {label}
          </Text>
        )}

        <span className={styles.inputWrapper}>
          <input
            aria-label={ariaLabel}
            ref={(input) => {
              this.inputField = input;
            }}
            onChange={this.onChange}
            onBlur={this.onBlur}
            className={cx('input', {
              error: hasValidationError,
              warning: errorType === 'warning',
            })}
            // Input usages don't include an id so we fallback to the name prop to make this
            // component a bit more accessible until it is replaced by the neon TextField
            id={name}
            name={name}
            type={type}
            minLength={minLength}
            maxLength={maxLength}
            pattern={pattern}
            placeholder={placeholder}
            disabled={disabled}
            title={title}
            value={value}
            autoComplete={autoComplete}
            autoCapitalize={autoCapitalize}
            autoCorrect={autoCorrect}
            autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
            data-test={testHook && `${testHook}-input`}
          />

          {/** Other info over the input **/}
          {inputOverlayInfo}

          {/** Error/Warning icon over the input **/}
          {showErrorIcon &&
            (errorType === errorMessageVariants.warning ? (
              <IconAlertWarning size="sizeIconSmall" className={cx(styles.iconError, styles.iconWarning)} />
            ) : (
              <IconAlertError size="sizeIconSmall" className={styles.iconError} />
            ))}
        </span>
        {inputInfo && <HelpText>{inputInfo}</HelpText>}
        {hasValidationError && (
          <HelpText variant={errorType} testHook={testHook && `${testHook}-error-message`}>
            {this.getErrorMessage()}
          </HelpText>
        )}
      </div>
    );
  }
}
