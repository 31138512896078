// @flow
import { Alert } from '@getatomi/neon';

import { UnstyledLink } from 'src/components/Link/Link';
import PlanSeatsLimit from 'src/components/PlanSeatsLimit/PlanSeatsLimit';
import { getBillingUrl } from 'src/utils/routes';

type Props = {
  onDismiss: () => mixed,
  subscriptionId: number,
};

export default function PlanSeatsAlert(props: Props) {
  const { onDismiss, subscriptionId } = props;

  return (
    <Alert
      variant="warning"
      isBanner
      onDismiss={onDismiss}
      buttonProps={{ children: 'Go to settings', to: `${getBillingUrl(subscriptionId)}`, as: UnstyledLink }}
      testHook="alert-plan-seats"
    >
      <PlanSeatsLimit /> Please review your billing settings.
    </Alert>
  );
}
