// @flow
import { split, trim } from 'lodash';

import type { APIDispatch, CurrencyType, Dispatch, GetState, PlanCode, Subscription } from 'src/types';
import { SUBSCRIPTIONS_ENDPOINT } from 'src/api/endpoints';
import { subscriptionsListSchema, subscriptionSchema } from 'src/api/schema';
import { refreshToken } from 'src/actions/auth';
import { loadPaymentSources } from 'src/actions/paymentSources';
import { getUserId } from 'src/reducers/auth';
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import { trackEvent } from 'src/utils/tracking';
import featureFlags from 'src/constants/featureFlags';
import { trackingEvents } from 'src/constants/tracking';
import roles from 'src/constants/userRoles';

export const LOAD_SUBSCRIPTION = 'LOAD_SUBSCRIPTION';
export const LOAD_SUBSCRIPTION_SUCCESS = 'LOAD_SUBSCRIPTION_SUCCESS';
export const LOAD_SUBSCRIPTION_FAIL = 'LOAD_SUBSCRIPTION_FAIL';
export const LOAD_SUBSCRIPTION_BY_CHARGEBEE_ID = 'LOAD_SUBSCRIPTION_BY_CHARGEBEE_ID';
export const LOAD_SUBSCRIPTION_BY_CHARGEBEE_ID_SUCCESS = 'LOAD_SUBSCRIPTION_BY_CHARGEBEE_ID_SUCCESS';
export const LOAD_SUBSCRIPTION_BY_CHARGEBEE_ID_FAIL = 'LOAD_SUBSCRIPTION_BY_CHARGEBEE_ID_FAIL';
export const ADD_USER_TO_SUBSCRIPTION = 'ADD_USER_TO_SUBSCRIPTION';
export const ADD_USER_TO_SUBSCRIPTION_SUCCESS = 'ADD_USER_TO_SUBSCRIPTION_SUCCESS';
export const ADD_USER_TO_SUBSCRIPTION_FAIL = 'ADD_USER_TO_SUBSCRIPTION_FAIL';
export const CREATE_PAYMENT_INTENT = 'CREATE_PAYMENT_INTENT';
export const CREATE_PAYMENT_INTENT_SUCCESS = 'CREATE_PAYMENT_INTENT_SUCCESS';
export const CREATE_PAYMENT_INTENT_FAIL = 'CREATE_PAYMENT_INTENT_FAIL';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAIL = 'UPDATE_SUBSCRIPTION_FAIL';
export const REQUEST_UPGRADE = 'REQUEST_UPGRADE';
export const REQUEST_UPGRADE_SUCCESS = 'REQUEST_UPGRADE_SUCCESS';
export const REQUEST_UPGRADE_FAIL = 'REQUEST_UPGRADE_FAIL';
export const SET_ACTIVE_SUBSCRIPTION = 'SET_ACTIVE_SUBSCRIPTION';
export const SET_ACTIVE_SUBSCRIPTION_FAIL = 'SET_ACTIVE_SUBSCRIPTION_FAIL';
export const SET_UPGRADE_SUBSCRIPTION_STATUS = 'SET_UPGRADE_SUBSCRIPTION_STATUS';
export const UPGRADE_SUBSCRIPTION = 'UPGRADE_SUBSCRIPTION';
export const UPGRADE_SUBSCRIPTION_SUCCESS = 'UPGRADE_SUBSCRIPTION_SUCCESS';
export const UPGRADE_SUBSCRIPTION_FAIL = 'UPGRADE_SUBSCRIPTION_FAIL';
export const CLEAR_UPGRADE_ERROR = 'CLEAR_UPGRADE_ERROR';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';
export const REACTIVATE_SUBSCRIPTION = 'REACTIVATE_SUBSCRIPTION';
export const REACTIVATE_SUBSCRIPTION_SUCCESS = 'REACTIVATE_SUBSCRIPTION_SUCCESS';
export const REACTIVATE_SUBSCRIPTION_FAIL = 'REACTIVATE_SUBSCRIPTION_FAIL';
export const REMOVE_USER_FROM_SUBSCRIPTION = 'REMOVE_USER_FROM_SUBSCRIPTION';
export const REMOVE_USER_FROM_SUBSCRIPTION_SUCCESS = 'REMOVE_USER_FROM_SUBSCRIPTION_SUCCESS';
export const REMOVE_USER_FROM_SUBSCRIPTION_FAIL = 'REMOVE_USER_FROM_SUBSCRIPTION_FAIL';
export const SET_REACTIVATE_SUBSCRIPTION_STATUS = 'SET_REACTIVATE_SUBSCRIPTION_STATUS';
export const CLEAR_REACTIVATE_SUBSCRIPTION_ERROR = 'CLEAR_REACTIVATE_SUBSCRIPTION_ERROR';
export const TOGGLE_FEATURE_FLAG = 'TOGGLE_FEATURE_FLAG';
export const TOGGLE_FEATURE_FLAG_SUCCESS = 'TOGGLE_FEATURE_FLAG_SUCCESS';
export const TOGGLE_FEATURE_FLAG_FAIL = 'TOGGLE_FEATURE_FLAG_FAIL';
export const RUN_EDS_IMPORT = 'RUN_EDS_IMPORT';
export const RUN_EDS_IMPORT_SUCCESS = 'RUN_EDS_IMPORT_SUCCESS';
export const RUN_EDS_IMPORT_FAIL = 'RUN_EDS_IMPORT_FAIL';
export const ACTIVATE_DAILY_SYNC = 'ACTIVATE_DAILY_SYNC';
export const ACTIVATE_DAILY_SYNC_SUCCESS = 'ACTIVATE_DAILY_SYNC_SUCCESS';
export const ACTIVATE_DAILY_SYNC_FAIL = 'ACTIVATE_DAILY_SYNC_FAIL';
export const DEACTIVATE_DAILY_SYNC = 'DEACTIVATE_DAILY_SYNC';
export const DEACTIVATE_DAILY_SYNC_SUCCESS = 'DEACTIVATE_DAILY_SYNC_SUCCESS';
export const DEACTIVATE_DAILY_SYNC_FAIL = 'DEACTIVATE_DAILY_SYNC_FAIL';
export const UPSERT_WONDE_CONFIGURATION = 'UPSERT_WONDE_CONFIGURATION';
export const UPSERT_WONDE_CONFIGURATION_SUCCESS = 'UPSERT_WONDE_CONFIGURATION_SUCCESS';
export const UPSERT_WONDE_CONFIGURATION_FAIL = 'UPSERT_WONDE_CONFIGURATION_FAIL';
export const LOAD_EDS_DETAILS = 'LOAD_EDS_DETAILS';
export const LOAD_EDS_DETAILS_SUCCESS = 'LOAD_EDS_DETAILS_SUCCESS';
export const LOAD_EDS_DETAILS_FAIL = 'LOAD_EDS_DETAILS_FAIL';
export const WRITE_EDS_CLASSES_TO_GOOGLE_SHEET = 'WRITE_EDS_CLASSES_TO_GOOGLE_SHEET';
export const WRITE_EDS_CLASSES_TO_GOOGLE_SHEET_SUCCESS = 'WRITE_EDS_CLASSES_TO_GOOGLE_SHEET_SUCCESS';
export const WRITE_EDS_CLASSES_TO_GOOGLE_SHEET_FAIL = 'WRITE_EDS_CLASSES_TO_GOOGLE_SHEET_FAIL';
export const UPDATE_EDS_CONFIG_FROM_GOOGLE_SHEET = 'UPDATE_EDS_CONFIG_FROM_GOOGLE_SHEET';
export const UPDATE_EDS_CONFIG_FROM_GOOGLE_SHEET_SUCCESS = 'UPDATE_EDS_CONFIG_FROM_GOOGLE_SHEET_SUCCESS';
export const UPDATE_EDS_CONFIG_FROM_GOOGLE_SHEET_FAIL = 'UPDATE_EDS_CONFIG_FROM_GOOGLE_SHEET_FAIL';

export function loadSubscription() {
  return (dispatch: APIDispatch<Subscription>, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());

    return dispatch({
      request: {
        types: [LOAD_SUBSCRIPTION, LOAD_SUBSCRIPTION_SUCCESS, LOAD_SUBSCRIPTION_FAIL],
        schema: subscriptionSchema,
        endpoint: {
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}?include=plan`,
        },
      },
    });
  };
}

export function updateSubscription(body: {}) {
  return (dispatch: APIDispatch<Subscription>, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());

    return dispatch({
      request: {
        types: [UPDATE_SUBSCRIPTION, UPDATE_SUBSCRIPTION_SUCCESS, UPDATE_SUBSCRIPTION_FAIL],
        schema: subscriptionSchema,
        throwErrors: true,
        endpoint: {
          method: 'put',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}?include=school`,
          body,
        },
      },
      tracking: {
        event: trackingEvents.subscriptionUpdateRequested,
        data: body,
      },
    });
  };
}

export function setUpgradeSubscriptionStatus(isUpgradingSubscription: boolean) {
  return {
    type: SET_UPGRADE_SUBSCRIPTION_STATUS,
    isUpgradingSubscription,
  };
}

export function createPaymentIntent({
  paymentMethodId,
  amount,
  currencyCode,
  ...other
}: {
  amount: number,
  currencyCode: CurrencyType,
  paymentMethodId: string,
}) {
  return (dispatch: Dispatch, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());

    return dispatch({
      request: {
        types: [CREATE_PAYMENT_INTENT, CREATE_PAYMENT_INTENT_SUCCESS, CREATE_PAYMENT_INTENT_FAIL],
        throwErrors: true,
        endpoint: {
          method: 'post',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/payment-intent`,
          body: {
            payment_method_id: paymentMethodId,
            amount,
            currency_code: currencyCode,
            ...other,
          },
        },
      },
    });
  };
}

/**
 * Upgrade a free subscription to a paid subscription. If `paymentIntentId` is not provided Nucleus
 * will use the saved payment method stored on the account.
 */
export function upgradeSubscription({ planCode, paymentIntentId }: { paymentIntentId?: string, planCode: PlanCode }) {
  return async (dispatch: APIDispatch<Subscription>, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());

    await dispatch({
      request: {
        types: [UPGRADE_SUBSCRIPTION, UPGRADE_SUBSCRIPTION_SUCCESS, UPGRADE_SUBSCRIPTION_FAIL],
        throwErrors: true,
        endpoint: {
          method: 'put',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}`,
          body: {
            plan_code: planCode,
            payment_intent_id: paymentIntentId,
          },
        },
      },
      tracking: {
        event: trackingEvents.subscriptionRetailUpgradeRequested,
        data: { plan_code: planCode },
      },
    });
    // Get a new access token containing an updated isFree flag (for segment, etc.)
    return dispatch(refreshToken());
  };
}

export function requestUpgrade({
  isLoggedInAsChild,
  phoneNumber,
}: {
  isLoggedInAsChild: boolean,
  phoneNumber?: string,
}) {
  return (dispatch: APIDispatch<Subscription>, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());

    return dispatch({
      request: {
        types: [REQUEST_UPGRADE, REQUEST_UPGRADE_SUCCESS, REQUEST_UPGRADE_FAIL],
        throwErrors: true,
        endpoint: {
          method: 'post',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/request-upgrade`,
          body: isLoggedInAsChild ? null : { phone_number: phoneNumber },
        },
      },
      tracking: {
        event: isLoggedInAsChild
          ? trackingEvents.subscriptionFamilyUpgradeRequested
          : trackingEvents.subscriptionSchoolUpgradeRequested,
        data: isLoggedInAsChild ? null : { phoneNumber },
      },
    });
  };
}

export function setActiveSubscription(id: number) {
  return {
    type: SET_ACTIVE_SUBSCRIPTION,
    id,
  };
}

export function setActiveSubscriptionFailed(pathname: string) {
  return {
    type: SET_ACTIVE_SUBSCRIPTION_FAIL,
    routeError: {
      exception: { output: {} },
      status: 403,
      pathname,
    },
  };
}

export function clearUpgradeError() {
  return {
    type: CLEAR_UPGRADE_ERROR,
  };
}

export function cancelSubscriptionById(subscriptionId: number) {
  return {
    request: {
      types: [CANCEL_SUBSCRIPTION, CANCEL_SUBSCRIPTION_SUCCESS, CANCEL_SUBSCRIPTION_FAIL],
      throwErrors: true,
      endpoint: {
        method: 'put',
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/cancel`,
      },
    },
    tracking: {
      event: trackingEvents.subscriptionCancelRequested,
    },
  };
}

export function cancelSubscription(reason: string, details: string) {
  return async (dispatch: APIDispatch<Subscription>, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());

    await dispatch(cancelSubscriptionById(subscriptionId));
    return trackEvent(trackingEvents.subscriptionCancelFeedbackSent, { reason, details });
  };
}

export function reactivateSubscriptionById(subscriptionId: number, paymentIntentId?: string) {
  return {
    data: {
      hasNewPaymentSource: !!paymentIntentId,
    },
    request: {
      types: [REACTIVATE_SUBSCRIPTION, REACTIVATE_SUBSCRIPTION_SUCCESS, REACTIVATE_SUBSCRIPTION_FAIL],
      throwErrors: true,
      endpoint: {
        method: 'put',
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/reactivate`,
        body: paymentIntentId && {
          payment_intent_id: paymentIntentId,
        },
      },
    },
    tracking: {
      event: trackingEvents.subscriptionReactivateRequested,
    },
  };
}

export function reactivateSubscription(paymentIntentId?: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());

    await dispatch(reactivateSubscriptionById(subscriptionId, paymentIntentId));
    // refresh the token to update the subscription scope with the re-activated subscription
    await dispatch(refreshToken());

    if (paymentIntentId) {
      return dispatch(loadPaymentSources());
    }
  };
}

export function setReactivateSubscriptionStatus(isReactivatingSubscription: boolean) {
  return {
    type: SET_REACTIVATE_SUBSCRIPTION_STATUS,
    isReactivatingSubscription,
  };
}

export function clearReactivateSubscriptionError() {
  return {
    type: CLEAR_REACTIVATE_SUBSCRIPTION_ERROR,
  };
}

export function loadSubscriptionByChargbeeId(chargebeeId: string) {
  return {
    request: {
      types: [
        LOAD_SUBSCRIPTION_BY_CHARGEBEE_ID,
        LOAD_SUBSCRIPTION_BY_CHARGEBEE_ID_SUCCESS,
        LOAD_SUBSCRIPTION_BY_CHARGEBEE_ID_FAIL,
      ],
      schema: subscriptionsListSchema,
      throwErrors: true,
      endpoint: {
        url: `${SUBSCRIPTIONS_ENDPOINT}?filter=chargebee_subscription_id:${chargebeeId}`,
      },
    },
  };
}

export function addUserToSubscription(subscriptionId: number, userId: number) {
  return {
    request: {
      types: [ADD_USER_TO_SUBSCRIPTION, ADD_USER_TO_SUBSCRIPTION_SUCCESS, ADD_USER_TO_SUBSCRIPTION_FAIL],
      schema: subscriptionSchema,
      throwErrors: true,
      endpoint: {
        method: 'post',
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/users/${userId}?include=plan,user`,
        body: { role: roles.accountManager },
      },
    },
  };
}

export function removeUserFromSubscription(subscriptionId: number, userId: number) {
  return {
    data: {
      subscriptionId,
    },
    request: {
      types: [REMOVE_USER_FROM_SUBSCRIPTION, REMOVE_USER_FROM_SUBSCRIPTION_SUCCESS, REMOVE_USER_FROM_SUBSCRIPTION_FAIL],
      throwErrors: true,
      endpoint: {
        method: 'delete',
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/users/${userId}`,
      },
    },
  };
}

export function joinSubscription(chargebeeId: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const subscriptions = await dispatch(loadSubscriptionByChargbeeId(chargebeeId));
    const subscriptionId = subscriptions.result.items[0];

    if (!subscriptionId) {
      throw new Error('There is no subscription associated to this Chargebee ID.');
    }

    const userId = getUserId(getState());
    await dispatch(addUserToSubscription(subscriptionId, userId));

    return subscriptionId;
  };
}

export function toggleFeatureFlag(flag: string, active: boolean, trackingEvent?: string) {
  return async (dispatch: APIDispatch<Subscription>, getState: GetState) => {
    const subscriptionId = getActiveSubscriptionId(getState());
    return dispatch({
      request: {
        types: [TOGGLE_FEATURE_FLAG, TOGGLE_FEATURE_FLAG_SUCCESS, TOGGLE_FEATURE_FLAG_FAIL],
        schema: subscriptionSchema,
        throwErrors: true,
        endpoint: {
          method: 'put',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/toggle-feature`,
          body: { flag, active },
        },
      },
      ...(trackingEvent ? { tracking: { event: trackingEvent } } : {}),
    });
  };
}

export function activateSetupMode() {
  return toggleFeatureFlag(featureFlags.setup_mode, true, trackingEvents.subscriptionActivateSetupModeRequested);
}

export function deactivateSetupMode() {
  return toggleFeatureFlag(featureFlags.setup_mode, false, trackingEvents.subscriptionDeactivateSetupModeRequested);
}

export function runEDSImport({
  externalDataSourceId,
  subscriptionId,
  isDryRun,
  isSinceLastImport,
  isNotifyOnSlack,
}: {
  externalDataSourceId: string,
  isDryRun: boolean,
  isNotifyOnSlack: boolean,
  isSinceLastImport: boolean,
  subscriptionId: string,
}) {
  return {
    request: {
      types: [RUN_EDS_IMPORT, RUN_EDS_IMPORT_SUCCESS, RUN_EDS_IMPORT_FAIL],
      throwErrors: true,
      endpoint: {
        method: 'post',
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/external-data-sources/${externalDataSourceId}/import`,
        body: {
          dry_run: isDryRun,
          since_last_import: isSinceLastImport,
          send_report_notification: isNotifyOnSlack,
        },
      },
    },
  };
}

export function activateDailySync({
  externalDataSourceId,
  subscriptionId,
}: {
  externalDataSourceId: string,
  subscriptionId: string,
}) {
  return {
    request: {
      throwErrors: true,
      types: [ACTIVATE_DAILY_SYNC, ACTIVATE_DAILY_SYNC_SUCCESS, ACTIVATE_DAILY_SYNC_FAIL],
      endpoint: {
        method: 'put',
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/external-data-sources/${externalDataSourceId}/activate`,
      },
    },
  };
}

export function deactivateDailySync({
  externalDataSourceId,
  subscriptionId,
}: {
  externalDataSourceId: string,
  subscriptionId: string,
}) {
  return {
    request: {
      throwErrors: true,
      types: [DEACTIVATE_DAILY_SYNC, DEACTIVATE_DAILY_SYNC_SUCCESS, DEACTIVATE_DAILY_SYNC_FAIL],
      endpoint: {
        method: 'put',
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/external-data-sources/${externalDataSourceId}/deactivate`,
      },
    },
  };
}

export function loadEDSDetails({
  externalDataSourceId,
  subscriptionId,
}: {
  externalDataSourceId: string,
  subscriptionId: number | string,
}) {
  return {
    request: {
      types: [LOAD_EDS_DETAILS, LOAD_EDS_DETAILS_SUCCESS, LOAD_EDS_DETAILS_FAIL],
      endpoint: {
        method: 'get',
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/external-data-sources/${externalDataSourceId}`,
      },
    },
  };
}

export function upsertWondeConfiguration({
  providerId,
  providerAccessToken,
  providerRegion,
  allowedEmailDomains,
  externalDataSourceId,
  subscriptionId,
}: {
  allowedEmailDomains: string,
  externalDataSourceId: ?string,
  providerAccessToken: string,
  providerId: string,
  providerRegion: string,
  subscriptionId: string,
}) {
  const body = {
    provider: 'wonde',
    provider_id: providerId,
    provider_access_token: providerAccessToken,
    ...(!!providerRegion && { provider_region: providerRegion }: $FlowSuppressAny),
    ...((!!allowedEmailDomains || allowedEmailDomains === '') && {
      config: {
        allowed_email_domains: split(allowedEmailDomains, ',').map((domain) => trim(domain)),
      },
    }: $FlowSuppressAny),
  };

  return {
    request: {
      types: [UPSERT_WONDE_CONFIGURATION, UPSERT_WONDE_CONFIGURATION_SUCCESS, UPSERT_WONDE_CONFIGURATION_FAIL],
      throwErrors: true,
      endpoint: {
        method: 'post',
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/external-data-sources${
          externalDataSourceId ? `/${externalDataSourceId}` : ''
        }`,
        body,
      },
    },
  };
}

export function writeEDSClassesToGoogleSheet({
  externalDataSourceId,
  subscriptionId,
}: {
  externalDataSourceId: string,
  subscriptionId: number | string,
}) {
  return {
    request: {
      types: [
        WRITE_EDS_CLASSES_TO_GOOGLE_SHEET,
        WRITE_EDS_CLASSES_TO_GOOGLE_SHEET_SUCCESS,
        WRITE_EDS_CLASSES_TO_GOOGLE_SHEET_FAIL,
      ],
      endpoint: {
        method: 'post',
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/external-data-sources/${externalDataSourceId}/sheet`,
      },
      throwErrors: true,
    },
  };
}

export function updateEDSConfigFromGoogleSheet({
  externalDataSourceId,
  subscriptionId,
}: {
  externalDataSourceId: string,
  subscriptionId: number | string,
}) {
  return {
    request: {
      types: [
        UPDATE_EDS_CONFIG_FROM_GOOGLE_SHEET,
        UPDATE_EDS_CONFIG_FROM_GOOGLE_SHEET_SUCCESS,
        UPDATE_EDS_CONFIG_FROM_GOOGLE_SHEET_FAIL,
      ],
      endpoint: {
        method: 'put',
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/external-data-sources/${externalDataSourceId}/sheet`,
      },
      throwErrors: true,
    },
  };
}
