// @flow
export default [
  { key: 'principal', label: 'Principal or Head of School' },
  { key: 'deputy_principal', label: 'Deputy Principal' },
  { key: 'head_of_curriculum', label: 'Head of Curriculum, Pedagogy or Teaching & Learning' },
  { key: 'head_of_technology', label: 'Head of Technology, Innovation or eLearning' },
  { key: 'head_of_department', label: 'Head of Department, Faculty or Learning Area' },
  { key: 'assistant_head', label: 'Assistant Head' },
  { key: 'teacher', label: 'Classroom Teacher' },
  { key: 'other', label: 'Other' },
];

export const canadianPositions = [
  { key: 'superintendent', label: 'Superintendent' },
  { key: 'board_or_district_administrator', label: 'Board/District Administrator' },
  { key: 'school_administrator', label: 'School Administrator' },
  { key: 'principal', label: 'Principal' },
  { key: 'vice_principal', label: 'Vice-Principal' },
  { key: 'department_head', label: 'Department Head' },
  { key: 'teacher', label: 'Teacher' },
];
