// @flow
import { Badge, Box, Card, Checkbox, Flex, Heading, Stack, Text, useMediaQuery } from '@getatomi/neon';
import _ from 'lodash';

import AvatarGroup from 'src/components/AvatarGroup/AvatarGroup';
import Avatar from 'src/components/Avatar/Avatar';
import Date from 'src/components/Date/Date';
import SubjectIcon from 'src/components/SubjectIcon/SubjectIcon';
import SubjectLevelBadges from 'src/components/SubjectLevelBadges/SubjectLevelBadges';
import { UnstyledLink } from 'src/components/Link/Link';
import ClassProgress from 'src/domains/Dashboard/ClassProgress/ClassProgress';
import getSubjectColors from 'src/utils/getSubjectColors';

type ClassListRowProps = {
  canDeleteClass?: boolean,
  currentClass: Object,
  isSelected?: boolean,
  onSelect?: (id: string) => mixed,
  url: string,
  withStudentsCount?: boolean,
  withSubjectTag?: boolean,
};

export default function ClassListRow(props: ClassListRowProps) {
  const {
    currentClass,
    canDeleteClass = false,
    isSelected = false,
    onSelect = _.noop,
    url,
    withStudentsCount,
    withSubjectTag = false,
  } = props;
  const { classProgress, id, levels, name, studentCount, subject, teachers } = currentClass;
  const { color } = subject;
  const hasTeachers = teachers && teachers.length > 0;
  const subjectColors = getSubjectColors(subject.groupCode, subject.color);

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const classNameHeading = <Heading variant="small">{name}</Heading>;
  const subjectBadges = (
    <SubjectLevelBadges
      levelsData={[
        ...levels,
        currentClass.createdAt
          ? {
              id: 'date-created',
              name: ((
                <>
                  Created: <Date value={currentClass.createdAt} format="DD MMM YYYY HH:mm" />
                </>
              ): React.Node),
            }
          : null,
      ].filter(Boolean)}
      subjectData={withSubjectTag ? subject : undefined}
    />
  );
  const studentProgressBar =
    classProgress != null ? <ClassProgress progress={classProgress} subjectColor={subjectColors.chart} /> : null;

  if (isMobile) {
    return (
      <Card>
        <UnstyledLink to={url}>
          <Box
            borderLeftColor={color}
            borderLeftStyle="solid"
            borderLeftWidth="borderWidthLarge1X"
            paddingBlock="spacingLarge"
            paddingInline="spacingRoot"
          >
            <Stack spacing="spacingSmall">
              <Stack spacing="spacingSmall2X">
                {classNameHeading}
                {subjectBadges}
              </Stack>
              {studentProgressBar}
            </Stack>
          </Box>
        </UnstyledLink>
      </Card>
    );
  }

  const deleteCheckbox = canDeleteClass ? (
    <Box
      display={{
        base: 'none',
        wide: 'block',
      }}
      position="absolute"
      paddingTop="spacingLarge3X"
      paddingRight="spacingLarge2X"
      top="spacingNegativeSmall1X"
      left="spacingNegativeLarge6X"
    >
      <Checkbox ariaLabel={`Select ${name}`} isSelected={isSelected} onChange={() => onSelect(id)} />
    </Box>
  ) : null;

  const subjectIcon = (
    <Box
      backgroundColor={subjectColors.backgroundSubtle}
      borderRadius="borderRadiusCircle"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      size="sizeIconLarge"
    >
      <SubjectIcon name={subject.icon} color={subjectColors.icon} size="sizeIconSmall" />
    </Box>
  );

  return (
    <Box as={Card} position="relative">
      {deleteCheckbox}

      <UnstyledLink to={url}>
        <Flex alignItems="center" gap="spacingLarge" paddingBlock="spacingLarge" paddingInline="spacingRoot">
          {subjectIcon}

          <Box flex={1}>
            <Stack spacing="spacingSmall">
              <Box display="inline-flex" alignItems="baseline" gap="spacingSmall">
                {classNameHeading}
                {subjectBadges}
              </Box>
              {studentProgressBar}
            </Stack>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            gap="spacingRoot"
            justifyContent="space-between"
            width={{
              tablet: '35%',
            }}
          >
            {withStudentsCount && (
              <Box
                display={{ base: 'none', tablet: 'flex' }}
                textAlign="right"
                gap="spacingSmall1X"
                testHook="student-count"
              >
                <Badge variant="counter">{studentCount}</Badge>{' '}
                <Text as="span" fontSize="fontSizeSmall1X" color="colorTextSubtler">
                  Students
                </Text>
              </Box>
            )}

            {hasTeachers && (
              <Box display={{ base: 'none', tablet: 'block' }} textAlign="right" marginLeft="auto">
                <AvatarGroup max={3}>
                  {teachers.map((teacher) => (
                    <Avatar key={teacher.id} user={teacher} withTooltip isDecorative={false} />
                  ))}
                </AvatarGroup>
              </Box>
            )}
          </Box>
        </Flex>
      </UnstyledLink>
    </Box>
  );
}
