// @flow
import { ButtonGroup, IconBinDelete, IconCalendarTick, IconCopy, IconDuplicate, IconEdit } from '@getatomi/neon';

import Button from 'src/components/Button/Button';

type ClassTaskMenuProps = {|
  copyTaskUrlToClipboard: () => Promise<void>,
  isDraft: boolean,
  isPublished: boolean,
  openDeleteTaskAlertDialog: () => void,
  openDuplicateTaskDialog: () => void,
  openTaskDialog: () => void,
  progressReportUrl: string,
|};
function ClassTaskMenu(props: ClassTaskMenuProps) {
  const {
    copyTaskUrlToClipboard,
    isDraft,
    isPublished,
    openDeleteTaskAlertDialog,
    openDuplicateTaskDialog,
    openTaskDialog,
    progressReportUrl,
  } = props;

  const taskActions = [
    isPublished && (
      <Button
        key="view"
        iconBefore={<IconCalendarTick size="sizeIconSmall1X" />}
        to={progressReportUrl}
        variant="tertiary"
      >
        View task progress
      </Button>
    ),
    <Button
      key="edit"
      iconBefore={<IconEdit size="sizeIconSmall1X" />}
      onClick={() => openTaskDialog()}
      variant="tertiary"
    >
      Edit
    </Button>,
    <Button
      key="duplicate"
      iconBefore={<IconDuplicate size="sizeIconSmall1X" />}
      onClick={() => openDuplicateTaskDialog()}
      variant="tertiary"
    >
      Duplicate
    </Button>,
    !isDraft && (
      <Button
        key="link"
        iconBefore={<IconCopy size="sizeIconSmall1X" />}
        onClick={copyTaskUrlToClipboard}
        variant="tertiary"
      >
        Copy link
      </Button>
    ),
    <Button
      key="delete"
      iconBefore={<IconBinDelete size="sizeIconSmall1X" />}
      onClick={() => {
        openDeleteTaskAlertDialog();
      }}
      variant="danger"
    >
      Delete
    </Button>,
  ].filter(Boolean);

  return (
    <ButtonGroup
      collapse={{
        label: 'More',
        // The last action (Delete) should always be in the 'after' group
        after: taskActions.length - 1,
        variant: 'tertiary',
      }}
    >
      {taskActions}
    </ButtonGroup>
  );
}

export default ClassTaskMenu;
