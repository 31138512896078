// @flow
import keyMirror from 'keymirror';

export default Object.freeze({
  retail: keyMirror({
    student_all_subjects_annual_nsw: null,
    student_all_subjects_annual_au_qld: null,
    student_all_subjects_annual_au_sa: null,
    student_all_subjects_annual_au_vic: null,
    student_all_subjects_annual_au_tas: null,
    student_all_subjects_annual_au_wa: null,
    student_all_subjects_annual_int: null,
    student_all_subjects_annual_uk: null,
    student_all_subjects_annual_sg: null,
    student_all_subjects_annual_ca_on: null,
    student_all_subjects_monthly_nsw: null,
    student_all_subjects_monthly_au_qld: null,
    student_all_subjects_monthly_au_sa: null,
    student_all_subjects_monthly_au_vic: null,
    student_all_subjects_monthly_au_tas: null,
    student_all_subjects_monthly_au_wa: null,
    student_all_subjects_monthly_int: null,
    student_all_subjects_monthly_uk: null,
    student_all_subjects_monthly_sg: null,
    student_all_subjects_monthly_ca_on: null,
  }),
  school: keyMirror({
    school_all_subjects_annual_nsw: null,
    school_all_subjects_annual_au_qld: null,
    school_all_subjects_annual_au_sa: null,
    school_all_subjects_annual_au_vic: null,
    school_all_subjects_annual_au_tas: null,
    school_all_subjects_annual_au_wa: null,
    school_all_subjects_annual_int: null,
    school_all_subjects_annual_uk: null,
    school_all_subjects_annual_sg: null,
    school_all_subjects_annual_ca_on: null,
    school_per_subject_annual_nsw: null,
    school_per_subject_annual_au_qld: null,
    school_per_subject_annual_au_sa: null,
    school_per_subject_annual_au_vic: null,
    school_per_subject_annual_au_tas: null,
    school_per_subject_annual_au_wa: null,
    school_per_subject_annual_int: null,
    school_per_subject_annual_uk: null,
    school_per_subject_annual_sg: null,
    school_per_subject_annual_ca_on: null,
  }),
  family: keyMirror({
    family_all_subjects_annual_nsw: null,
    family_all_subjects_annual_au_qld: null,
    family_all_subjects_annual_au_sa: null,
    family_all_subjects_annual_au_vic: null,
    family_all_subjects_annual_au_tas: null,
    family_all_subjects_annual_au_wa: null,
    family_all_subjects_annual_int: null,
    family_all_subjects_annual_uk: null,
    family_all_subjects_annual_sg: null,
    family_all_subjects_annual_ca_on: null,
    family_all_subjects_monthly_nsw: null,
    family_all_subjects_monthly_au_qld: null,
    family_all_subjects_monthly_au_sa: null,
    family_all_subjects_monthly_au_vic: null,
    family_all_subjects_monthly_au_tas: null,
    family_all_subjects_monthly_au_wa: null,
    family_all_subjects_monthly_int: null,
    family_all_subjects_monthly_uk: null,
    family_all_subjects_monthly_sg: null,
    family_all_subjects_monthly_ca_on: null,
  }),
  free: {
    student: keyMirror({
      student_free_nsw: null,
      student_free_au_qld: null,
      student_free_au_sa: null,
      student_free_au_vic: null,
      student_free_au_tas: null,
      student_free_au_wa: null,
      student_free_int: null,
      student_free_uk: null,
      student_free_sg: null,
      student_free_ca_on: null,
    }),
    school: keyMirror({
      school_free_nsw: null,
      school_free_au_qld: null,
      school_free_au_sa: null,
      school_free_au_vic: null,
      school_free_au_tas: null,
      school_free_au_wa: null,
      school_free_int: null,
      school_free_uk: null,
      school_free_sg: null,
      school_free_ca_on: null,
    }),
    family: keyMirror({
      family_free_nsw: null,
      family_free_au_qld: null,
      family_free_au_sa: null,
      family_free_au_vic: null,
      family_free_au_tas: null,
      family_free_au_wa: null,
      family_free_int: null,
      family_free_uk: null,
      family_free_sg: null,
      family_free_ca_on: null,
    }),
  },
});
