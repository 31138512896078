// @flow
import { useReducer } from 'react';

import type { AttachedContent } from 'src/domains/Tasks/types';

function contentsReducer(contents: $ReadOnlyArray<AttachedContent>, action) {
  switch (action.type) {
    case 'add':
      return [...contents, action.content];
    case 'remove':
      return contents.filter((content) => content.id !== action.id);
    default:
      return contents;
  }
}

export default function useContents(
  initialContents: $ReadOnlyArray<AttachedContent>,
  taskContents?: $ReadOnlyArray<AttachedContent>
): [$ReadOnlyArray<AttachedContent>, Function] {
  return useReducer(contentsReducer, taskContents ?? initialContents);
}
