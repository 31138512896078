// @flow
import pluralize from 'pluralize';
import { Alert, Stack, Text } from '@getatomi/neon';

import type { DateString } from 'src/types';
import Date from 'src/components/Date/Date';
import PlanSeatsLimit from 'src/components/PlanSeatsLimit/PlanSeatsLimit';
import useUpgradePrompt from 'src/hooks/components/useUpgradePrompt';

type Props = {
  description: string,
  expiryDate: DateString,
  isFamilyPlan: boolean,
  isFreeFamilyPlan: boolean,
  isPaidSchool: boolean,
  isTrialSubscription: boolean,
  numSeats: number,
  numSeatsUsed: number,
  trialEndDate: DateString,
};

export default function PlanDescription(props: Props) {
  const {
    description,
    expiryDate,
    isFamilyPlan,
    isFreeFamilyPlan,
    isPaidSchool,
    isTrialSubscription,
    numSeats,
    numSeatsUsed,
    trialEndDate,
  } = props;
  const [modal, createHandler] = useUpgradePrompt({ shouldRequestUpgrade: isPaidSchool });

  let isUsingTooManySeats = false;
  if (isPaidSchool && numSeatsUsed && numSeats) {
    isUsingTooManySeats = numSeatsUsed > numSeats;
  }

  const seatCount = numSeats === 0 ? 1 : numSeats;

  let text = description;
  if (isPaidSchool) {
    text = (
      <>
        <strong>
          <Text as="span" fontSize="fontSizeSmall1X" color={isUsingTooManySeats ? 'colorTextWarning' : undefined}>
            {numSeatsUsed} of {seatCount} {pluralize('seat', seatCount)} used
          </Text>
        </strong>{' '}
        on a {isTrialSubscription ? 'trial' : 'contract'} which will {isTrialSubscription ? 'end' : 'expire'} on{' '}
        <strong>
          <Date value={isTrialSubscription ? trialEndDate : expiryDate} />
        </strong>
        .
      </>
    );
  } else if (isFamilyPlan) {
    text = isFreeFamilyPlan ? (
      <>
        Limited access to content and features for <strong>you</strong> and{' '}
        <strong>
          {isFreeFamilyPlan || seatCount === 1 ? 'one' : seatCount} {pluralize('student', seatCount)}
        </strong>
        .
      </>
    ) : (
      <>
        That’s full access to all content and features for <strong>you</strong> and{' '}
        <strong>
          {isFreeFamilyPlan || seatCount === 1 ? 'one' : seatCount} {pluralize('student', seatCount)}
        </strong>
        , anytime, on any device.
      </>
    );
  }

  return (
    <Stack spacing="spacingLarge1X">
      <Text as="p" variant="bodySmall1X" color="colorTextSubtler" testHook="hide-in-percy">
        {text}
      </Text>
      {isUsingTooManySeats && (
        <>
          <Alert
            variant="warning"
            buttonProps={{
              children: 'Get in touch',
              onClick: createHandler(),
            }}
          >
            <PlanSeatsLimit withRemovalMessage />
          </Alert>
          {modal}
        </>
      )}
    </Stack>
  );
}
