// @flow
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useToast } from '@getatomi/neon';

import type { BoundAction, UserType } from 'src/types';
import { ActionMenu, Item } from 'src/components/ActionMenu/ActionMenu';
import ActionMenuUserHeader from 'src/components/ActionMenu/ActionMenuUserHeader';
import {
  archiveUsers,
  restoreUsers,
  resendInvites,
  makeUserAdmin,
  unmakeUserAdmin,
  loadUsersForOwnershipTransfer,
} from 'src/actions/users';
import {
  isActiveSubscriptionInSetupMode,
  getUserRole as getAuthUserRole,
  isLoggedInAsAccountManager,
} from 'src/reducers/subscriptions';
import {
  getStatusFilter,
  isProcessingUser,
  isPending as isPendingSelector,
  isTeacher as isTeacherSelector,
  isAccountAdmin as isAccountAdminSelector,
  isAccountOwner as isAccountOwnerSelector,
  isUsersForOwnershipTransferLoaded,
  hasUsersWhoCanBeAddedOwners as hasUsersWhoCanBeAddedOwnersSelector,
} from 'src/reducers/users';
import { isLoggedInAsSuperAdmin as isLoggedInAsSuperAdminSelector } from 'src/reducers/auth';
import userStatuses from 'src/constants/userStatuses';
import roles from 'src/constants/userRoles';

import EditUserDialog from './EditUserDialog/EditUserDialog';
import ViewEmailLogsDialog from '../ViewEmailLogsDialog/ViewEmailLogsDialog';
import TransferOwnershipDialog from '../TransferOwnershipDialog/TransferOwnershipDialog';
import AlertDialogMakeUserAdmin from '../AlertDialogMakeUserAdmin/AlertDialogMakeUserAdmin';
import AlertDialogChangeUserStatus from '../AlertDialogChangeUserStatus/AlertDialogChangeUserStatus';

type Props = {
  canChangeStatus: boolean,
  isCurrentAuthenticatedUser: boolean, // eslint-disable-line react/no-unused-prop-types
  user: UserType,
};
type InjectedProps = Props & {
  archiveUsersAction: BoundAction<typeof archiveUsers>,
  hasUsersWhoCanBeAddedOwners: boolean,
  isAccountAdmin: boolean,
  isAccountOwner: boolean,
  isInSetupMode: boolean,
  isLoggedInAsAM: boolean,
  isLoggedInAsSuperAdmin: boolean,
  isPending: boolean,
  isProcessing: boolean,
  isTeacher: boolean,
  isUsersLoaded: boolean,
  loadUsersForOwnershipTransferAction: BoundAction<typeof loadUsersForOwnershipTransfer>,
  loggedInUserRole: BoundAction<typeof getAuthUserRole>,
  makeUserAdminAction: BoundAction<typeof makeUserAdmin>,
  resendInvitesAction: BoundAction<typeof resendInvites>,
  restoreUsersAction: BoundAction<typeof restoreUsers>,
  unmakeUserAdminAction: BoundAction<typeof unmakeUserAdmin>,
  user: UserType,
  userStatus: BoundAction<typeof getStatusFilter>,
};

const mapStateToProps = (state, props) => {
  const { user } = props;

  return {
    userStatus: getStatusFilter(state),
    isTeacher: isTeacherSelector(state, user.id),
    isAccountAdmin: isAccountAdminSelector(state, user.id),
    isAccountOwner: isAccountOwnerSelector(state, user.id),
    isLoggedInAsAM: isLoggedInAsAccountManager(state),
    isLoggedInAsSuperAdmin: isLoggedInAsSuperAdminSelector(state),
    loggedInUserRole: getAuthUserRole(state),
    isProcessing: isProcessingUser(state, user.id),
    isPending: isPendingSelector(state, user.id),
    isInSetupMode: isActiveSubscriptionInSetupMode(state),
    isUsersLoaded: isUsersForOwnershipTransferLoaded(state),
    hasUsersWhoCanBeAddedOwners: hasUsersWhoCanBeAddedOwnersSelector(state),
  };
};

function UserDropdownActions(props: InjectedProps) {
  const {
    archiveUsersAction,
    restoreUsersAction,
    resendInvitesAction,
    makeUserAdminAction,
    unmakeUserAdminAction,
    user,
    userStatus,
    isTeacher,
    isAccountAdmin,
    isAccountOwner,
    isProcessing,
    isPending,
    isInSetupMode,
    isLoggedInAsAM,
    isLoggedInAsSuperAdmin,
    loggedInUserRole,
    isUsersLoaded,
    hasUsersWhoCanBeAddedOwners,
    loadUsersForOwnershipTransferAction,
    canChangeStatus,
    isCurrentAuthenticatedUser,
  } = props;

  const toast = useToast();

  const isArchived = userStatus === userStatuses.archived;
  const canBeArchived = !isArchived;
  const canEditUser = isLoggedInAsSuperAdmin;
  const canBeRestored = isArchived;
  const canBeReinvited = isPending && !isInSetupMode;
  const canBeMadeUnmadeAdmin =
    ![roles.student, roles.teacher].includes(loggedInUserRole) && !isArchived && (isAccountAdmin || isTeacher);
  const canTransferOwnership =
    (hasUsersWhoCanBeAddedOwners || !isUsersLoaded) &&
    (canChangeStatus || isCurrentAuthenticatedUser || isLoggedInAsAM) &&
    isAccountOwner;
  const canViewEmailLogs = isLoggedInAsSuperAdmin;
  const hasActions =
    canEditUser ||
    canBeReinvited ||
    canChangeStatus ||
    canBeMadeUnmadeAdmin ||
    canTransferOwnership ||
    canViewEmailLogs;
  const statusAction = canBeArchived ? 'archive' : 'restore';
  const adminAction = isAccountAdmin ? 'remove admin' : 'make admin';

  useEffect(() => {
    (async () => {
      if (canTransferOwnership && !isUsersLoaded) {
        try {
          await loadUsersForOwnershipTransferAction();
        } catch (error) {
          toast.error('There was an error loading all the users. Please refresh the page.', { id: 'users-load-error' });
        }
      }
    })();
  }, [canTransferOwnership, isUsersLoaded, loadUsersForOwnershipTransferAction]); // eslint-disable-line react-hooks/exhaustive-deps

  const [showChangeUserStatusPrompt, setShowChangeUserStatusPrompt] = useState<boolean>(false);
  const [showMakeUnmakeAdminPrompt, setShowMakeUnmakeAdminPrompt] = useState<boolean>(false);
  const [showTransferOwnershipPrompt, setShowTransferOwnershipPrompt] = useState<boolean>(false);
  const [showEmailLogs, setShowEmailLogs] = useState<boolean>(false);
  const [showEditUserPrompt, setShowEditUserPrompt] = useState<boolean>(false);

  const onChangeUserStatus = async () => {
    setShowChangeUserStatusPrompt((prevState) => !prevState);

    try {
      if (canBeArchived) {
        await archiveUsersAction([user.id]);
        toast.success('The user was archived successfully.');
      } else if (canBeRestored) {
        await restoreUsersAction([user.id]);
        toast.success('The user was restored successfully.');
      }
    } catch (error) {
      toast.error(
        error.statusCode === 403
          ? `You don’t have permission to ${statusAction} the user.`
          : `There was an error when ${canBeArchived ? 'archiving' : 'restoring'} the user. Please try again.`
      );
    }
  };

  const onResendInvite = useCallback(async () => {
    try {
      await resendInvitesAction([user.id]);
      toast.success('The invitation was sent successfully.');
    } catch (error) {
      toast.error(
        error.statusCode === 403
          ? `You don’t have permission to send the invitation.`
          : `There was an error when sending the invitation. Please try again.`
      );
    }
  }, [resendInvitesAction, toast, user.id]);

  const onMakeUnmakeUserAdmin = async () => {
    setShowMakeUnmakeAdminPrompt((prevState) => !prevState);

    if (isTeacher) {
      try {
        await makeUserAdminAction(user.id);
        toast.success('The user was successfully granted admin privileges.');
      } catch (error) {
        toast.error(
          error.statusCode === 403
            ? `You don’t have permission to make this user an admin.`
            : `There was an error when making this user an admin. Please try again.`
        );
      }
    } else if (isAccountAdmin) {
      try {
        await unmakeUserAdminAction(user.id);
        toast.success('The admin privileges were successfully removed from this user.');
      } catch (error) {
        toast.error(
          error.statusCode === 403
            ? `You don’t have permission to remove the admin privileges from this user.`
            : `There was an error when removing the admin privileges form this user. Please try again.`
        );
      }
    }
  };

  const handleAction = useCallback(
    (key) => {
      switch (key) {
        case 'edit':
          setShowEditUserPrompt((prevState) => !prevState);
          break;
        case 'resend-invite':
          onResendInvite();
          break;
        case 'change-status':
          setShowChangeUserStatusPrompt((prevState) => !prevState);
          break;
        case 'make-unmake-admin':
          setShowMakeUnmakeAdminPrompt((prevState) => !prevState);
          break;
        case 'transfer-ownership':
          setShowTransferOwnershipPrompt((prevState) => !prevState);
          break;
        case 'view-email-logs':
          setShowEmailLogs((prevState) => !prevState);
          break;
        default:
          break;
      }
    },
    [onResendInvite]
  );

  if (!hasActions) {
    return null;
  }

  return (
    <>
      <ActionMenu
        renderHeader={(isTray) => isTray && <ActionMenuUserHeader user={user} />}
        onAction={handleAction}
        isLoading={isProcessing}
        testHook={`user-dropdown-${user.id}`}
      >
        {canEditUser && <Item key="edit">Edit user</Item>}
        {canBeReinvited && <Item key="resend-invite">Resend invite</Item>}
        {canChangeStatus && <Item key="change-status">{_.upperFirst(statusAction)}</Item>}
        {canBeMadeUnmadeAdmin && <Item key="make-unmake-admin">{_.upperFirst(adminAction)}</Item>}
        {canTransferOwnership && <Item key="transfer-ownership">Transfer account ownership</Item>}
        {canViewEmailLogs && <Item key="view-email-logs">View email logs</Item>}
      </ActionMenu>
      {canChangeStatus && (
        <AlertDialogChangeUserStatus
          action={statusAction}
          isOpen={showChangeUserStatusPrompt}
          onDismiss={() => setShowChangeUserStatusPrompt(false)}
          onConfirm={onChangeUserStatus}
        />
      )}
      {canBeMadeUnmadeAdmin && (
        <AlertDialogMakeUserAdmin
          action={adminAction}
          isOpen={showMakeUnmakeAdminPrompt}
          onDismiss={() => setShowMakeUnmakeAdminPrompt(false)}
          onConfirm={onMakeUnmakeUserAdmin}
        />
      )}
      {canTransferOwnership && (
        <TransferOwnershipDialog
          user={user}
          onClose={() => setShowTransferOwnershipPrompt(false)}
          showModal={showTransferOwnershipPrompt}
        />
      )}
      {canViewEmailLogs && (
        <ViewEmailLogsDialog userEmail={user.email} onClose={() => setShowEmailLogs(false)} showModal={showEmailLogs} />
      )}
      {canEditUser && (
        <EditUserDialog user={user} isVisible={showEditUserPrompt} onClose={() => setShowEditUserPrompt(false)} />
      )}
    </>
  );
}

export default (connect(mapStateToProps, {
  archiveUsersAction: archiveUsers,
  restoreUsersAction: restoreUsers,
  resendInvitesAction: resendInvites,
  makeUserAdminAction: makeUserAdmin,
  unmakeUserAdminAction: unmakeUserAdmin,
  loadUsersForOwnershipTransferAction: loadUsersForOwnershipTransfer,
})(UserDropdownActions): React.AbstractComponent<Props>);
