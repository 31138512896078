// @flow
import invariant from 'invariant';
import { Button } from '@getatomi/neon';
import { withRouter } from 'react-router';

import type { NewAttachedContent } from 'src/domains/Tasks/types';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import RevisionSelectorsLoader, {
  RevisionDialogSidebarLoader,
} from 'src/components/RevisionDialog/RevisionSelectorsLoader';

import TeacherRevisionBaseDialog from './TeacherRevisionBaseDialog';
import TeacherRevisionDialog from './TeacherRevisionDialog';
import useGetTeacherRevisionDialog from './useGetTeacherRevisionDialog';

type Params = {
  classId: string,
  subscriptionId: string,
};

type TeacherRevisionDialogContainerProps = {|
  defaultTopicIds: ?Array<string>,
  isOpen: boolean,
  onClose: () => void,
  onCreate: (newRevision: NewAttachedContent) => void,
  params: Params,
|};

export function TeacherRevisionDialogContainer(props: TeacherRevisionDialogContainerProps) {
  const { defaultTopicIds, isOpen, onClose, onCreate, params } = props;
  const { subscriptionId: accountId, classId } = params;

  const { data, loading, error } = useGetTeacherRevisionDialog({
    accountId,
    classId,
  });

  if (loading) {
    return (
      <TeacherRevisionBaseDialog
        isOpen={isOpen}
        modalActions={
          <Button size="small" isAriaDisabled>
            Add to task
          </Button>
        }
        onClose={onClose}
        sidebar={<RevisionDialogSidebarLoader />}
      >
        <RevisionSelectorsLoader />
      </TeacherRevisionBaseDialog>
    );
  }

  if (error) {
    return (
      <TeacherRevisionBaseDialog
        isOpen={isOpen}
        modalActions={
          <Button size="small" isAriaDisabled>
            Add to task
          </Button>
        }
        onClose={onClose}
        sidebar={null}
      >
        <GraphQLError error={error} description="We couldn’t load the revision dialog." />
      </TeacherRevisionBaseDialog>
    );
  }

  invariant(data, 'Subject data should be defined');
  const { subjectCode, subjectName, subjectTree } = data;

  return (
    <TeacherRevisionDialog
      accountId={accountId}
      classId={classId}
      defaultTopicIds={defaultTopicIds}
      isOpen={isOpen}
      onClose={onClose}
      onCreate={onCreate}
      subjectCode={subjectCode}
      subjectName={subjectName}
      subjectTree={subjectTree}
    />
  );
}

export default withRouter(TeacherRevisionDialogContainer);
