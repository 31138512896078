// @flow
import type { RegionCode } from 'src/types';

const config = typeof window !== 'undefined' ? window.config : process.env;

export default config;

// `strengthFloor` is passed into Thorium Client for the purpose of calculating
// a strength value for use in the Thorium Client Challenge End page.
// When Thorium Client is retired we should be able to remove this.
export const strengthFloor = 0.55;

// `strengthThresholds` are used to influence how the Neon <Strength /> components display.
export const strengthThresholds: [number, number, number, number] = [0, 0.7, 0.8, 0.9];

export const getSubjectLimit = (region: RegionCode) =>
  +(config[`RUNTIME_MAX_SUBJECTS_${region.toUpperCase()}`] || config.RUNTIME_MAX_SUBJECTS);
