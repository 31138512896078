// @flow
import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Flex,
  Heading,
  IconActionFlip,
  MarkdownPreview,
  Skeleton,
  Stack,
  Text,
  TextLoader,
} from '@getatomi/neon';
import invariant from 'invariant';

import { trackingCtas } from 'src/constants/tracking';
import Button from 'src/components/Button/Button';
import Duration from 'src/components/Duration/Duration';
import type { SessionLengthFilter } from 'src/components/RevisionDialog/types';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import { type RevisionSession } from 'src/components/RevisionDialog/generateSession';

import type { ReplaceRevisionQuestionType } from './useDraftRevision';
import useQuestionShuffle, { type GetReplacementQuestionType } from './useQuestionShuffle';
import useGetQuestionPreview from './useGetQuestionPreview';
import useGetQuestionPreviews, {
  type QuestionPreviewType,
  type ReplaceQuestionPreviewType,
} from './useGetQuestionPreviews';

type QuestionPreviewCardProps = {|
  getReplacementQuestion: GetReplacementQuestionType,
  order: number,
  questionPreview: QuestionPreviewType,
  replaceQuestionPreview: ReplaceQuestionPreviewType,
  replaceRevisionQuestion: ReplaceRevisionQuestionType,
  selectedTopics: Array<string>,
  setShouldDisableOtherShuffles: (newState: boolean) => void,
  shouldDisableOtherShuffles: boolean,
|};

type QuestionPreviewProps = {|
  questionPreviews: $ReadOnlyArray<QuestionPreviewType>,
  replaceQuestionPreview: ReplaceQuestionPreviewType,
  replaceRevisionQuestion: ReplaceRevisionQuestionType,
  revisionSession: RevisionSession,
  selectedTopics: Array<string>,
  sessionLength: SessionLengthFilter,
  totalDuration: number,
  totalMarks: number,
|};

type QuestionPreviewContainerProps = {|
  discardDraftRevision: () => void,
  draftRevision: ?RevisionSession,
  initDraftRevision: () => void,
  replaceRevisionQuestion: ReplaceRevisionQuestionType,
  selectedTopics: Array<string>,
  sessionLength: SessionLengthFilter,
|};

type QuestionPreviewQueryProps = {|
  draftRevision: RevisionSession,
  replaceRevisionQuestion: ReplaceRevisionQuestionType,
  selectedTopics: Array<string>,
  sessionLength: SessionLengthFilter,
|};

function QuestionPreviewCard(props: QuestionPreviewCardProps) {
  const {
    order,
    questionPreview,
    getReplacementQuestion,
    shouldDisableOtherShuffles,
    selectedTopics,
    setShouldDisableOtherShuffles,
    replaceQuestionPreview,
    replaceRevisionQuestion,
  } = props;
  const { id: questionId, previewText, metadata } = questionPreview;

  const { fetchQuestionPreview, isBeingShuffled } = useGetQuestionPreview({
    currentQuestionId: questionId,
    replaceQuestionPreview,
    replaceRevisionQuestion,
    selectedTopics,
    setShouldDisableOtherShuffles,
  });

  const handleShuffle = () => {
    const replacementQuestion = getReplacementQuestion(questionId);
    if (replacementQuestion) {
      fetchQuestionPreview(replacementQuestion.id);
    }
  };

  const shuffleTransitionProps = {
    transition: 'transitionRoot',
    opacity: isBeingShuffled ? 0.2 : 1,
  };

  return (
    <Card as="article" aria-busy={isBeingShuffled} padding="spacingLarge1X" variant="rounded" withHover={false}>
      <Box {...shuffleTransitionProps} marginBottom="spacingRoot">
        <Text
          as="h2"
          color="colorTextSubtle"
          fontWeight="fontWeightSemiBold"
          textTransform="uppercase"
          variant="bodySmall"
        >
          Question {order}
        </Text>
        <Text as="span" variant="bodySmall1X" color="colorTextSubtle">
          {metadata}
        </Text>
      </Box>

      <Box {...shuffleTransitionProps} marginBottom="spacingLarge1X">
        <MarkdownPreview lines={3}>{previewText}</MarkdownPreview>
      </Box>

      <Button
        iconBefore={<IconActionFlip size="sizeIconSmall2X" />}
        isLoading={isBeingShuffled}
        isDisabled={shouldDisableOtherShuffles}
        size="small1X"
        variant="secondary"
        onClick={handleShuffle}
        trackingData={{
          cta: trackingCtas.shuffleQuestionPreview,
        }}
      >
        Shuffle
      </Button>
    </Card>
  );
}

export function QuestionPreview(props: QuestionPreviewProps) {
  const {
    replaceQuestionPreview,
    replaceRevisionQuestion,
    revisionSession,
    selectedTopics,
    sessionLength,
    questionPreviews,
    totalDuration,
    totalMarks,
  } = props;

  const [shouldDisableOtherShuffles, setShouldDisableOtherShuffles] = useState<boolean>(false);

  const getReplacementQuestion = useQuestionShuffle({
    revisionSession,
    sessionLength,
  });

  return (
    <section>
      <Flex
        direction={{ base: 'column', tablet: 'row' }}
        alignItems="baseline"
        gap="spacingRoot"
        marginBottom="spacingLarge4X"
      >
        <Heading as="h1" variant="large">
          {`${questionPreviews.length} questions`}
        </Heading>
        <Text as="span" color="colorTextSubtle" variant="bodySmall">
          {totalMarks} marks • <Duration duration={totalDuration} format="minutes" />
        </Text>
      </Flex>

      <Stack as="ol" spacing="spacingLarge1X">
        {questionPreviews.map((questionPreview, index) => (
          <QuestionPreviewCard
            key={questionPreview.id}
            getReplacementQuestion={getReplacementQuestion}
            order={index + 1}
            questionPreview={questionPreview}
            replaceQuestionPreview={replaceQuestionPreview}
            replaceRevisionQuestion={replaceRevisionQuestion}
            shouldDisableOtherShuffles={shouldDisableOtherShuffles}
            selectedTopics={selectedTopics}
            setShouldDisableOtherShuffles={setShouldDisableOtherShuffles}
          />
        ))}
      </Stack>
    </section>
  );
}

function Loader() {
  return (
    <Box testHook="question-preview-loader">
      <Flex
        direction={{ base: 'column', tablet: 'row' }}
        alignItems="baseline"
        gap="spacingRoot"
        marginBottom="spacingLarge4X"
      >
        <TextLoader width={150} height={37} />
        <TextLoader width={100} height={25} />
      </Flex>

      <Stack as="ol" spacing="spacingLarge1X">
        {Array.from({ length: 2 }).map((_val, index) => (
          <Card key={index} padding="spacingLarge1X" variant="rounded" withHover={false}>
            <Stack>
              <TextLoader width={86} height={25} />
              <TextLoader width={250} height={15} />
              <Skeleton animation="wave" height={100} />
            </Stack>
          </Card>
        ))}
      </Stack>
    </Box>
  );
}

function QuestionPreviewQuery(props: QuestionPreviewQueryProps) {
  const { draftRevision, replaceRevisionQuestion, selectedTopics, sessionLength } = props;

  const { data, loading, error } = useGetQuestionPreviews({
    revisionQuestionIds: draftRevision.questions.map((q) => q.id),
    selectedTopics,
  });

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load your questions." />;
  }

  if (loading) {
    return <Loader />;
  }

  invariant(data, 'Question preview data should be defined');

  const { questionPreviews, totalDuration, totalMarks, replaceQuestionPreview } = data;

  return (
    <QuestionPreview
      questionPreviews={questionPreviews}
      totalDuration={totalDuration}
      totalMarks={totalMarks}
      replaceRevisionQuestion={replaceRevisionQuestion}
      replaceQuestionPreview={replaceQuestionPreview}
      revisionSession={draftRevision}
      selectedTopics={selectedTopics}
      sessionLength={sessionLength}
    />
  );
}

export default function QuestionPreviewContainer(props: QuestionPreviewContainerProps) {
  const {
    replaceRevisionQuestion,
    draftRevision,
    initDraftRevision,
    discardDraftRevision,
    selectedTopics,
    sessionLength,
  } = props;

  useEffect(() => {
    initDraftRevision();
    return () => {
      discardDraftRevision();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (draftRevision == null) {
    return <Loader />;
  }

  return (
    <QuestionPreviewQuery
      draftRevision={draftRevision}
      replaceRevisionQuestion={replaceRevisionQuestion}
      selectedTopics={selectedTopics}
      sessionLength={sessionLength}
    />
  );
}
