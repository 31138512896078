import { browserHistory } from 'react-router';
import _ from 'lodash';

import Link from 'src/components/Link/Link';
import Logger from 'src/utils/Logger';
import { trackingEvents } from 'src/constants/tracking';
import {
  getDashboardUrl,
  getSubscriptionsListUrl,
  getSubscriptionIdFromUrl,
  isLessonLocationsUrl,
  isLtiUrl,
} from 'src/utils/routes';
import * as trackingUtils from 'src/utils/tracking';
import { getUser, isUserLoggedIn } from 'src/reducers/auth';
import {
  getActiveSubscriptionsCount,
  getActiveSubscriptionId,
  isUserSubscription,
  getUserRole,
} from 'src/reducers/subscriptions';
import {
  AUTH_STRATEGIES_ENDPOINT,
  PASSWORD_ENDPOINT,
  PROXIED_PASSWORD_ENDPOINT,
  PROXIED_USER_ENDPOINT,
  USER_ENDPOINT,
} from 'src/api/endpoints';
import { authSchema, invitedUserSchema } from 'src/api/schema';

const log = new Logger('actions/auth');

export const LOGIN = 'auth/LOGIN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'auth/LOGIN_FAIL';
export const LOGIN_LEGACY = 'auth/LOGIN_LEGACY';
export const LOGIN_LEGACY_SUCCESS = 'auth/LOGIN_LEGACY_SUCCESS';
export const LOGIN_LEGACY_FAIL = 'auth/LOGIN_LEGACY_FAIL';
export const CLEAR_LOGIN_ERROR = 'auth/CLEAR_LOGIN_ERROR';
export const LOAD = 'auth/LOAD';
export const LOAD_SUCCESS = 'auth/LOAD_SUCCESS';
export const LOAD_FAIL = 'auth/LOAD_FAIL';
export const LOAD_AUTH_STRATEGIES = 'auth/LOAD_AUTH_STRATEGIES';
export const LOAD_AUTH_STRATEGIES_SUCCESS = 'auth/LOAD_AUTH_STRATEGIES_SUCCESS';
export const LOAD_AUTH_STRATEGIES_FAIL = 'auth/LOAD_AUTH_STRATEGIES_FAIL';
export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'auth/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'auth/REFRESH_TOKEN_FAIL';
export const CLEAR_DATA = 'auth/CLEAR_DATA';
export const VALIDATE_EMAIL = 'auth/VALIDATE_EMAIL';
export const VALIDATE_EMAIL_FAIL = 'auth/VALIDATE_EMAIL_FAIL';
export const VALIDATE_EMAIL_SUCCESS = 'auth/VALIDATE_EMAIL_SUCCESS';
export const CONFIRM_USER = 'auth/CONFIRM_USER';
export const CONFIRM_USER_FAIL = 'auth/CONFIRM_USER_FAIL';
export const CONFIRM_USER_SUCCESS = 'auth/CONFIRM_USER_SUCCESS';
export const REQUEST_RESET_PASSWORD_EMAIL = 'auth/REQUEST_RESET_PASSWORD_EMAIL';
export const REQUEST_RESET_PASSWORD_EMAIL_SUCCESS = 'auth/REQUEST_RESET_PASSWORD_EMAIL_SUCCESS';
export const REQUEST_RESET_PASSWORD_EMAIL_FAIL = 'auth/REQUEST_RESET_PASSWORD_EMAIL_FAIL';
export const RESET_PASSWORD = 'auth/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'auth/RESET_PASSWORD_FAIL';
export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'auth/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'auth/CHANGE_PASSWORD_FAIL';
export const LOAD_INVITED_USER = 'auth/LOAD_INVITED_USER';
export const LOAD_INVITED_USER_SUCCESS = 'auth/LOAD_INVITED_USER_SUCCESS';
export const LOAD_INVITED_USER_FAIL = 'auth/LOAD_INVITED_USER_FAIL';
export const UPDATE_USER = 'auth/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'auth/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'auth/UPDATE_USER_FAIL';
export const UPDATE_USER_AND_SUBJECTS = 'auth/UPDATE_USER_AND_SUBJECTS';
export const UPDATE_USER_AND_SUBJECTS_SUCCESS = 'auth/UPDATE_USER_AND_SUBJECTS_SUCCESS';
export const UPDATE_USER_AND_SUBJECTS_FAIL = 'auth/UPDATE_USER_AND_SUBJECTS_FAIL';

const isValidSubscription = (state, subscriptionId) => isUserSubscription(state, subscriptionId);

const authenticate = (username, password) => {
  return {
    request: {
      types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
      schema: authSchema,
      throwErrors: true,
      endpoint: {
        method: 'post',
        url: `${PROXIED_USER_ENDPOINT}/login?include=subscriptions.school`,
        sendAccessToken: false,
        body: {
          grant_type: 'password',
          username,
          password,
        },
      },
    },
    tracking: {
      event: trackingEvents.userLoginRequested,
      data: { username },
    },
  };
};

export function redirectToDashboard() {
  return (dispatch, getState) => {
    const state = getState();
    const userRole = getUserRole(state);
    const activeSubscriptionId = getActiveSubscriptionId(state);
    // if the redirect url contains an invalid subscription id then use the last active one
    const subscriptionId = isValidSubscription(state, activeSubscriptionId)
      ? activeSubscriptionId
      : getUser(state).last_active_subscription_id;

    browserHistory.replace(getDashboardUrl(subscriptionId, { userRole }));
  };
}

export function login(username, password, rememberMe, redirectUrl, subscriptionId = null) {
  return async (dispatch, getState, auth) => {
    try {
      const normalisedResponse = await dispatch(authenticate(username, password));
      const user = normalisedResponse.entities.users[normalisedResponse.result];

      // eslint-disable-next-line camelcase
      const { access_token } = user.meta.auth;
      log.info('login was successful: saving access_token in cookie', user);

      // reset Segment user
      trackingUtils.reset();

      //save access_token in cookie so that we can use it for API request (on both client and server)
      auth.saveAccessToken(access_token);

      // if the user checked 'remember me' save details in cookie so that we can display them when the token expires
      if (rememberMe && user) {
        auth.saveUserDetails(user);
      }

      const state = getState();
      const userRole = getUserRole(state);

      if (redirectUrl) {
        if (
          isValidSubscription(state, getSubscriptionIdFromUrl(redirectUrl)) ||
          isLessonLocationsUrl(redirectUrl) ||
          isLtiUrl(redirectUrl) ||
          redirectUrl === 'closeWindow'
        ) {
          browserHistory.replace(redirectUrl);
        } else {
          dispatch(redirectToDashboard());
        }
      } else if (subscriptionId) {
        // the user has selected a subscription
        return browserHistory.replace(getDashboardUrl(subscriptionId, { userRole }));
      } else if (getActiveSubscriptionsCount(state) > 1) {
        // the user has more than one subscription
        return browserHistory.replace(getSubscriptionsListUrl());
      } else {
        dispatch(redirectToDashboard());
      }
    } catch (err) {
      if (err.statusCode === 302) {
        log.info('redirecting legacy user');
      } else {
        log.info('login failed: ', err);
      }
    }
  };
}

export function refreshToken() {
  return async (dispatch, getState, auth) => {
    log.info('Refreshing token');
    dispatch({ type: REFRESH_TOKEN });

    try {
      await auth.refreshAccessToken();
      return dispatch({ type: REFRESH_TOKEN_SUCCESS });
    } catch (error) {
      log.info('Error while refreshing access token', error);
      return dispatch({ type: REFRESH_TOKEN_FAIL });
    }
  };
}

export function checkAccessToken() {
  return async (dispatch, getState, auth) => {
    const accessToken = auth.getAccessToken();
    if (accessToken && auth.isTokenExpired(accessToken)) {
      return dispatch(refreshToken());
    }
  };
}

export function clearLoginError() {
  return {
    type: CLEAR_LOGIN_ERROR,
  };
}

export function loadUserDetails() {
  return async (dispatch, getState, auth) => {
    const accessToken = auth.getAccessToken();

    log.info('loadUserDetails with access_token', _.truncate(accessToken));

    if (!accessToken) {
      throw new Error('The access_token cookie is not set');
    }

    return dispatch({
      request: {
        throwErrors: true,
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        schema: authSchema,
        endpoint: {
          url: `${USER_ENDPOINT}?include=subscriptions.school`,
        },
      },
      tracking: {
        event: trackingEvents.userDetailsRequested,
      },
    });
  };
}

// eslint-disable-next-line default-param-last
export function logout(redirect = true, redirectUrl) {
  return async (dispatch, getState, auth) => {
    try {
      // use Fetch directly because we don't want to go through Redux middleware and create an
      // infinite loop
      await fetch(`${PROXIED_USER_ENDPOINT}/logout`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.getAccessToken()}`,
        },
        credentials: 'same-origin',
      });
    } catch (e) {
      // ignore
    }
    auth.logout(redirect, redirectUrl);
  };
}

// Load the invited user's details but don't set their access token in the cookie as this would log them in
export function loadInvitedUserDetails(inviteCode) {
  return async (dispatch, getState, auth) => {
    const res = await dispatch({
      data: { inviteCode },
      request: {
        types: [LOAD_INVITED_USER, LOAD_INVITED_USER_SUCCESS, LOAD_INVITED_USER_FAIL],
        schema: invitedUserSchema,
        refreshToken: false,
        endpoint: {
          method: 'post',
          sendAccessToken: false,
          url: `${PROXIED_USER_ENDPOINT}/invite?avatars=retina-large`,
          body: {
            grant_type: 'application',
            invite_token: inviteCode,
          },
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.userInvitationDetailsRequested,
        data: { inviteCode },
      },
    });

    // if the user is already logged in, log them out
    if (auth.getAccessToken()) {
      await dispatch(logout());
    }

    return res;
  };
}

export function clearUserData() {
  return (dispatch, getState, auth) => {
    auth.clearUserData();
    return dispatch({
      type: CLEAR_DATA,
    });
  };
}

export function checkExistingUser(email) {
  return async (dispatch) => {
    try {
      await dispatch({
        request: {
          types: [VALIDATE_EMAIL, VALIDATE_EMAIL_SUCCESS, VALIDATE_EMAIL_FAIL],
          endpoint: {
            method: 'post',
            sendAccessToken: false,
            url: `${USER_ENDPOINT}/validate-email`,
            body: { email: email.trim() },
          },
          throwErrors: true,
        },
        tracking: {
          event: trackingEvents.userEmailValidationRequested,
          data: { email },
        },
      });
      return { isValid: true };
    } catch (e) {
      return {
        isValid: false,
        errorMessage: (
          <span>
            This email is already registered.{' '}
            <Link to="/login" variant="monochrome">
              Try logging in instead.
            </Link>
          </span>
        ),
      };
    }
  };
}

export function confirmUser(code) {
  return async (dispatch, getState) => {
    // load the user details if the user is logged in
    // we''ll need the user email to compared the current logged in user email and the email that is being confirmed
    // and logout the user if the emails are different
    if (!isUserLoggedIn(getState())) {
      try {
        await dispatch(loadUserDetails());
      } catch (e) {
        log.info('confirmUser action:', e);
      }
    }

    const { email } = await dispatch({
      request: {
        types: [CONFIRM_USER, CONFIRM_USER_SUCCESS, CONFIRM_USER_FAIL],
        endpoint: {
          method: 'post',
          sendAccessToken: false,
          url: `${USER_ENDPOINT}/confirm/${code}`,
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.userConfirmationRequested,
        data: { code },
      },
    });

    const { isLoggedIn, user } = getState().auth;

    // if the confirmed email is different from the current logged in user email then log the user out
    if (isLoggedIn && email !== user.email) {
      dispatch(logout());
    }

    return email;
  };
}

export function requestResetPasswordEmail(email) {
  return async (dispatch) => {
    await dispatch({
      request: {
        types: [REQUEST_RESET_PASSWORD_EMAIL, REQUEST_RESET_PASSWORD_EMAIL_SUCCESS, REQUEST_RESET_PASSWORD_EMAIL_FAIL],
        endpoint: {
          method: 'post',
          sendAccessToken: false,
          url: `${PASSWORD_ENDPOINT}/send-password-reset`,
          body: { email },
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.userPasswordResetEmailRequested,
        data: { email },
      },
    });
  };
}

export function resetPassword(token, userId, password, passwordConfirmation) {
  return async (dispatch, getState, auth) => {
    const res = await dispatch({
      request: {
        types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL],
        endpoint: {
          method: 'post',
          sendAccessToken: false,
          url: `${PROXIED_PASSWORD_ENDPOINT}/reset`,
          body: {
            grant_type: 'application',
            user_id: userId,
            password,
            password_confirmation: passwordConfirmation,
            token,
          },
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.userPasswordResetRequested,
      },
    });

    // save access token in cookie
    const accessToken = _.get(res, 'meta.auth.access_token');
    auth.saveAccessToken(accessToken);

    return dispatch(loadUserDetails());
  };
}

export function changePassword(oldPassword, password, passwordConfirmation) {
  return {
    request: {
      types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
      throwErrors: true,
      endpoint: {
        method: 'put',
        url: `${USER_ENDPOINT}`,
        body: {
          old_password: oldPassword,
          password,
          password_confirmation: passwordConfirmation,
        },
      },
    },
    tracking: {
      event: trackingEvents.userPasswordChangeRequested,
    },
  };
}

export function updateUser(body) {
  const types = body.subjects
    ? [UPDATE_USER_AND_SUBJECTS, UPDATE_USER_AND_SUBJECTS_SUCCESS, UPDATE_USER_AND_SUBJECTS_FAIL]
    : [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL];

  return {
    request: {
      types,
      schema: authSchema,
      throwErrors: true,
      endpoint: {
        method: 'put',
        url: `${USER_ENDPOINT}?include=subscriptions,classes`,
        body,
      },
    },
    tracking: {
      event: trackingEvents.userUpdateRequested,
      data: body,
    },
  };
}

export function loadAuthStrategies(email) {
  return {
    request: {
      types: [LOAD_AUTH_STRATEGIES, LOAD_AUTH_STRATEGIES_SUCCESS, LOAD_AUTH_STRATEGIES_FAIL],
      throwErrors: false,
      endpoint: {
        method: 'post',
        url: AUTH_STRATEGIES_ENDPOINT,
        sendAccessToken: false,
        body: {
          email,
        },
      },
    },
  };
}

export function loginWithSaml({ email, subscriptionId, redirectUrl }) {
  let sanitizedRedirectUrl;
  if (
    getSubscriptionIdFromUrl(redirectUrl) === subscriptionId ||
    isLessonLocationsUrl(redirectUrl) ||
    isLtiUrl(redirectUrl) ||
    redirectUrl === 'closeWindow'
  ) {
    sanitizedRedirectUrl = redirectUrl;
  } else {
    sanitizedRedirectUrl = getDashboardUrl(subscriptionId);
  }

  return (dispatch, getState, auth) => {
    auth.setSAMLLogin({
      email,
      subscriptionId,
      redirectUrl: sanitizedRedirectUrl,
    });

    /**
     * Redirect the user to login via SAML
     *
     * The native Location API is used because this is an Express route and not a react route.
     */
    window.location.assign('/saml-redirect');
  };
}
