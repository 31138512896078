// @flow
import { Box, Card, Diff, HideVisually, Skeleton, Text, useMediaQuery } from '@getatomi/neon';

import InsightGraph, { type InsightGraphProps } from './InsightGraph';

export type InsightProps = {
  description: React.Node,
  diffDescription: React.Node,
  diffTooltip?: React.Node,
  diffValue: number,
  graphProps?: InsightGraphProps,
  isActive?: boolean,
  onClick?: () => mixed,
  testHook?: string,
  title: React.Node,
  value: React.Node,
};

export default function Insight(props: InsightProps) {
  const {
    title,
    description,
    value,
    diffDescription,
    diffTooltip,
    diffValue,
    graphProps,
    isActive,
    onClick,
    testHook,
  } = props;
  const withHover = Boolean(onClick);
  const isMobile = useMediaQuery({ maxWidth: 'breakpointSmall' });
  const displayAsButton = withHover && !isMobile;

  const buttonBorderStyles = {
    borderRadius: 'borderRadiusRoot',
    borderTopWidth: 'borderWidthLarge1X',
    borderTopStyle: 'solid',
    borderTopColor: isActive && !isMobile ? 'colorBorderBrandBold' : 'transparent',
  };

  return (
    <Card variant={isMobile ? 'elevated' : 'rounded'} withHover={displayAsButton} testHook={testHook}>
      <Box
        display="grid"
        gridTemplateAreas={
          isMobile
            ? `
              "title diff"
              "value value"
              "description description"
              "graph graph"
            `
            : `
              "title"
              "description"
              "value"
              "diff"
            `
        }
        gridTemplateColumns={{ base: '1fr auto', mobile: '1fr' }}
        as={displayAsButton ? 'button' : 'div'}
        onClick={displayAsButton ? onClick : undefined}
        type={displayAsButton ? 'button' : undefined}
        lineHeight="lineHeightRoot"
        padding={{ base: 'spacingRoot', mobile: 'spacingLarge1X' }}
        textAlign="start"
        width="sizeFull"
        {...buttonBorderStyles}
      >
        <Box
          gridArea="title"
          testHook={testHook && `${testHook}-title`}
          marginBottom={{ base: 'spacingNone', mobile: 'spacingSmall1X' }}
        >
          <Text
            as="span"
            color="colorText"
            fontSize={{ base: 'fontSizeSmall1X', tablet: 'fontSizeSmall' }}
            fontWeight="fontWeightSemiBold"
            lineHeight="lineHeightSmall1X"
          >
            {title}
          </Text>
        </Box>

        <Box gridArea="diff" display="flex" alignItems="center" gap="spacingSmall1X">
          <Diff
            variant="filled"
            value={diffValue}
            tooltip={isMobile ? diffTooltip || diffDescription : undefined}
            testHook={testHook && `${testHook}-diff`}
          />
          {isMobile ? (
            <HideVisually>{diffDescription}</HideVisually>
          ) : (
            <Text as="span" color="colorTextSubtle" variant="bodySmall2X">
              {diffDescription}
            </Text>
          )}
        </Box>

        <Box
          gridArea="value"
          color="colorTextBrand"
          fontSize="fontSizeLarge6X"
          fontFamily="fontFamilySystem"
          lineHeight="lineHeightSmall1X"
          marginBottom={{ base: 'spacingSmall1X', mobile: 'spacingNone' }}
          testHook={testHook && `${testHook}-value`}
        >
          {value}
        </Box>

        <Box
          gridArea="description"
          color="colorTextSubtle"
          fontSize="fontSizeSmall1X"
          fontFamily="fontFamilySystem"
          lineHeight="lineHeightSmall3X"
          marginBottom={{ base: 'spacingSmall2X', mobile: 'spacingSmall' }}
          testHook={testHook && `${testHook}-description`}
        >
          {description}
        </Box>

        {graphProps && isMobile && (
          <Box gridArea="graph" height={150} marginTop="spacingRoot" width="sizeFull">
            <InsightGraph {...graphProps} isEmbedded />
          </Box>
        )}
      </Box>
    </Card>
  );
}

export function InsightLoader() {
  return <Skeleton height={{ base: 300, tablet: 195 }} animation="wave" />;
}
