// @flow
import { HideVisually, StatusLight } from '@getatomi/neon';

import { successLimits } from 'src/constants/progress';

type Props = {|
  successRate: number,
|};

function SuccessRate({ successRate }: Props) {
  const isHighSuccessRate = successRate >= successLimits.marksSuccessThreshold * 100;

  return (
    <StatusLight color={isHighSuccessRate ? 'colorIconSuccess' : 'colorIconDanger'} testHook="success-rate">
      <HideVisually>{isHighSuccessRate ? 'High Mark: ' : 'Low Mark: '}</HideVisually>
      {successRate}%
    </StatusLight>
  );
}

export default SuccessRate;
