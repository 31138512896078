// @flow
import { useMemo, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';

import type { ChallengeTier, PostType } from 'src/types';

type Props = {|
  resultsRef: { current: HTMLDivElement | null },
  subjectCode: string | null,
|};

export const matchLevels = {
  full: 2,
  partial: 1,
  none: 0,
};

type MatchLevel = $Keys<typeof matchLevels>;

type SnippetResult = {|
  matchLevel: MatchLevel,
  value: string,
|};

export type AccessLevel = 'paid' | 'authenticated';

export type Hit = {|
  _snippetResult: {
    captions: ?SnippetResult,
    categories: {
      lv0: ?SnippetResult,
      lv1: ?SnippetResult,
      lv2: ?SnippetResult,
    },
    description: ?SnippetResult,
    name: ?SnippetResult,
  },
  // eslint-disable-next-line camelcase
  access_level: AccessLevel,
  captions: ?string,
  categories: {
    lv0: string,
    lv1: string,
    lv2: string,
  },
  description: ?string,
  duration: number,
  id: number,
  // eslint-disable-next-line camelcase
  module_id: number,
  name: string,
  objectID: string,
  // eslint-disable-next-line camelcase
  subject_code: string,
  tier?: ChallengeTier,
  type: PostType,
|};

export type AutocompleteResult = {|
  hit: Hit,
  source: string,
|};

type AutocompleteGetters = {|
  getEnvironmentProps: (args: Object) => {
    onMouseDown: () => void,
    onTouchMove: () => void,
    onTouchStart: () => void,
  },
  getFormProps: (args: Object) => Object,
  getInputProps: (args: { inputElement: HTMLInputElement }) => Object,
  getItemProps: (args: { item: Hit, source: string }) => Object,
  getLabelProps: (args: Object) => Object,
  getListProps: (args: Object) => Object,
  getPanelProps: (args: Object) => Object,
  getRootProps: (args: Object) => Object,
|};

export type UseAutocompleteOutput = {|
  getters: AutocompleteGetters,
  query: string,
  results: $ReadOnlyArray<AutocompleteResult>,
  setQuery: (newQuery: string) => void,
|};

type AutocompleteCollection = {|
  items: $ReadOnlyArray<Hit>,
  source: string,
|};

function transformResults(collections: $ReadOnlyArray<AutocompleteCollection>): $ReadOnlyArray<AutocompleteResult> {
  const results = [];
  for (const collection of collections) {
    const { source, items } = collection;
    for (const item of items) {
      results.push({ source, hit: item });
    }
  }
  return results;
}

// Rather than duplicating our various onClick behaviours, we simulate a click on the selected item
function clickSelectedItem(itemIndex: number, resultsRef: { current: HTMLDivElement | null }) {
  if (!resultsRef.current) return;
  const resultsList = resultsRef.current.children[0];
  const selectedResult = resultsList?.children[itemIndex];
  selectedResult.click();
}

export default function useContentAutocomplete(props: Props): UseAutocompleteOutput | null {
  const { subjectCode, resultsRef } = props;

  const searchClient = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY);

  const [autocompleteState, setAutocompleteState] = useState({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: '',
    activeItemId: null,
    status: 'idle',
  });

  const sourceId = 'content';
  const autocomplete = useMemo(
    () =>
      subjectCode
        ? createAutocomplete({
            onStateChange({ state }) {
              setAutocompleteState(state);
            },
            getSources() {
              return [
                {
                  sourceId,
                  getItems({ query }) {
                    const trimmedQuery = query.trim();

                    if (trimmedQuery.length < 3) {
                      return [];
                    }

                    return getAlgoliaResults({
                      searchClient,
                      queries: [
                        {
                          indexName: process.env.ALGOLIA_INDEX_POSTS,
                          query: trimmedQuery,
                          params: {
                            hitsPerPage: 1000,
                            analytics: true,
                            attributesToRetrieve: [
                              'type',
                              'access_level',
                              'name',
                              'categories',
                              'description',
                              'captions',
                              '*',
                            ],
                            attributesToSnippet: ['*:30'],
                            responseFields: ['hits'],
                            filters: `subject_code:${subjectCode}`,
                          },
                        },
                      ],
                    });
                  },
                  // This function is required for keyboard navigation, but we don't need a url
                  getItemUrl() {
                    return '';
                  },
                },
              ];
            },
            // Handle when a user selects a result via keyboard navigation
            navigator: {
              // User has hit Enter
              navigate({ state }) {
                clickSelectedItem(state.activeItemId, resultsRef);
              },
              // User has hit Enter with the Cmd/Ctrl key
              navigateNewTab({ state }) {
                clickSelectedItem(state.activeItemId, resultsRef);
              },
              // User has hit Enter with the Shift key
              navigateNewWindow({ state }) {
                clickSelectedItem(state.activeItemId, resultsRef);
              },
            },
          })
        : null,
    [subjectCode] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (autocomplete === null) {
    return null;
  }

  const {
    setQuery,
    getEnvironmentProps,
    getFormProps,
    getPanelProps,
    getLabelProps,
    getListProps,
    getItemProps,
    getInputProps,
    getRootProps,
  } = autocomplete;

  const getters = {
    getEnvironmentProps,
    getFormProps,
    getPanelProps,
    getLabelProps,
    // the source prop is required to create the correct list HTML id for the listbox
    getListProps: (args: Object) => getListProps({ source: { sourceId }, ...args }),
    getItemProps,
    getInputProps,
    getRootProps,
  };

  const results = transformResults(autocompleteState.collections);

  return {
    query: autocompleteState.query,
    setQuery,
    getters,
    results,
  };
}
