// @flow
import keyMirror from 'keymirror';

export default keyMirror({
  insights: null,
  setup_mode: null,
  denyStudentDataExport: null,
  challengesV2: null,
  allowCrossSubjectContentSharing: null,
});
