// @flow
import invariant from 'invariant';

import { getTasksUrl } from 'src/utils/routes';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';

import TaskDoesNotExist from '../components/TaskDoesNotExist';
import TaskContainerLoader from '../components/TaskContainerLoader';
import StudentTask from './StudentTask';
import useGetStudentTask from './useGetStudentTask';

type Props = {|
  params: {
    classId: string,
    subscriptionId: string,
    taskId: string,
  },
|};

export default function StudentTaskContainer(props: Props) {
  const {
    params: { classId, subscriptionId, taskId },
  } = props;

  const { data, error, loading } = useGetStudentTask({ classId, subscriptionId, taskId });
  const tasksPageRedirectUrl = getTasksUrl(subscriptionId);

  if (loading) {
    return <TaskContainerLoader backLinkLabel="Back to Tasks" backLinkUrl={tasksPageRedirectUrl} />;
  }

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load the task." />;
  }

  invariant(data, 'Student task data should be defined');

  const { currentClass, task, userId } = data;

  if (!task || !currentClass) {
    return <TaskDoesNotExist tasksPageRedirectUrl={tasksPageRedirectUrl} />;
  }

  return (
    <StudentTask
      backLinkUrl={tasksPageRedirectUrl}
      currentClass={currentClass}
      subscriptionId={subscriptionId}
      taskData={task}
      userId={userId}
    />
  );
}
