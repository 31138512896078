// @flow
import { useMutation } from '@apollo/client';

import type { EditClass, EditClassVariables, EditClassInput } from 'src/graphql/types/generated/EditClass';
import { trackEvent } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';

import EDIT_CLASS from './EditClass.graphql';
import GET_CLASS from '../GetClass.graphql';

type Output = {|
  editClass: (params: EditClassInput) => Promise<void>,
  isEditingClass: boolean,
|};

export default function useEditClass(): Output {
  const [mutation, { loading }] = useMutation<EditClass, EditClassVariables>(EDIT_CLASS, {
    refetchQueries: [GET_CLASS],
    awaitRefetchQueries: true,
  });

  return {
    editClass: async (params: EditClassInput) => {
      trackEvent(trackingEvents.classUpdateRequested, {
        id: params.classId,
        level_id: params.levelId,
        name: params.name,
        subject_code: params.subjectCode,
      });
      await mutation({
        variables: {
          input: params,
        },
      });
    },
    isEditingClass: loading,
  };
}
