// @flow

import SectionTags from 'src/components/SectionTags/SectionTags';
import PostList from 'src/components/PostList/PostList';
import type { Props as SectionTagsProps } from 'src/components/SectionTags/SectionTags';
import { isComingSoonLabel } from 'src/domains/Class/utils/checkIsComingSoon';

import styles from './SectionListItem.module.scss';

type Props = {
  children: ?React.Element<typeof PostList>,
  color: string,
  id?: string,
  labels?: $PropertyType<SectionTagsProps, 'tags'>,
  name: string,
  testHook?: string,
};

export default function SectionListItem(props: Props) {
  const { children, color, name, id, labels, testHook } = props;

  if (!children && !color && !name) return null;

  const isComingSoon = labels && labels.some((label) => isComingSoonLabel(label.name));

  return (
    <li id={id} className={styles.root} data-test={testHook}>
      <div className={styles.header} data-test={testHook ? `${testHook}-header` : undefined}>
        <span className={styles.subjectIndicator} style={{ backgroundColor: color }} />
        <div>
          <h2 className={styles.heading} data-test={testHook ? `${testHook}-heading` : undefined}>
            {name}
          </h2>
          {labels && <SectionTags tags={labels} />}
        </div>
      </div>
      {!isComingSoon && children}
    </li>
  );
}
