// @flow
import type { Revision } from '../useGetClassSubject/useGetClassSubject';
import RevisionPrompt from '../RevisionPrompt/RevisionPrompt';

type Props = {|
  revision: Revision | null,
|};

export default function SubjectRevision(props: Props) {
  const { revision } = props;

  if (!revision) {
    return (
      <RevisionPrompt
        buttonProps={{
          label: 'Revise now',
          isDisabled: true,
        }}
        text="Once you complete some quizzes recommendations will show up here!"
        testHook="subject-revision-prompt"
      />
    );
  }

  return (
    <RevisionPrompt
      text="It looks like it’s time to revise:"
      buttonProps={{
        label: 'Revise now',
        to: revision.url,
      }}
      linkProps={{
        label: revision.name,
        to: revision.url,
      }}
      testHook="subject-revision-prompt"
    />
  );
}
