// @flow
import { useCallback, useEffect, useState } from 'react';

const toggleIntercomWidget = (isVisible: boolean) => {
  const { Intercom } = window;

  if (!Intercom) return;

  if (!isVisible) {
    // hide the main messenger panel if it's opened
    Intercom('hide');
  }

  // hide the widget
  Intercom('update', {
    hide_default_launcher: !isVisible,
  });
};

export default function useIntercomWidget({
  hideOnMount,
  showOnUnmount,
}: { hideOnMount?: boolean, showOnUnmount?: boolean } = {}) {
  const [isVisible, setIsVisible] = useState(true);

  const hide = useCallback(() => {
    // the Intercom API
    // (https://developers.intercom.com/installing-intercom/docs/intercom-javascript) doesn't
    // provide a way to check if the widget is visible so we have to look at the widget className.
    const isWidgetInDOM = document.querySelector(config.RUNTIME_INTERCOM_LAUNCHER_CLASSNAME);
    if (!isWidgetInDOM || !isVisible) return;
    setIsVisible(false);
    toggleIntercomWidget(false);
  }, [isVisible]);

  const show = useCallback(() => {
    if (isVisible) return;
    setIsVisible(true);
    toggleIntercomWidget(true);
  }, [isVisible]);

  useEffect(() => {
    if (hideOnMount) hide();
    return () => {
      if (showOnUnmount) show();
    };
  }, [hide, hideOnMount, show, showOnUnmount]);

  return [show, hide];
}
