import { combineReducers } from 'redux';

import auth from './auth';
import avatars from './avatars';
import classes from './classes';
import credits from './credits';
import estimates from './estimates';
import invoices from './invoices';
import levels from './levels';
import paymentSources from './paymentSources';
import plans from './plans';
import regions from './regions';
import registration from './registration';
import schools from './schools';
import socialLogins from './socialLogins';
import subscriptions from './subscriptions';
import subjects from './subjects';
import users from './users';

const reducer = combineReducers({
  auth,
  avatars,
  classes,
  credits,
  estimates,
  invoices,
  levels,
  paymentSources,
  plans,
  regions,
  registration,
  schools,
  socialLogins,
  subscriptions,
  subjects,
  users,
});

export default reducer;
