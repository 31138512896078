// @flow
import { useMutation } from '@apollo/client';
import { Stack, useToast } from '@getatomi/neon';

import type {
  CreateClassTask,
  CreateClassTaskVariables,
  TaskStatus,
} from 'src/graphql/types/generated/CreateClassTask';
import Link from 'src/components/Link/Link';
import { getTaskUrl } from 'src/utils/routes';

import CREATE_CLASS_TASK from '../CreateClassTask.graphql';

export const showToast = (
  data: CreateClassTask,
  subscriptionId: string,
  classId: string,
  toast: $Call<typeof useToast>
) => {
  if (!data.createClassTask) return;

  const { id: newTaskId, status: newTaskStatus } = data.createClassTask;

  const draftOrTaskLabel = newTaskStatus === 'draft' ? 'draft' : 'task';

  const successLabels: { [TaskStatus]: string } = {
    published: 'Your task has been created.',
    draft: 'Your task has been saved as a draft.',
    scheduled: 'Your task has been scheduled.',
  };

  const toastId = toast.success(
    <Stack spacing="spacingSmall2X">
      <p>{successLabels[newTaskStatus]}</p>
      <Link
        to={getTaskUrl(subscriptionId, classId, newTaskId)}
        onClick={() => toast.hide(toastId)}
      >{`View the ${draftOrTaskLabel}`}</Link>
    </Stack>,
    { duration: Infinity }
  );
};

export default function useCreateTask({ subscriptionId, classId }: { classId: string, subscriptionId: string }) {
  const toast = useToast();

  const [createTask] = useMutation<CreateClassTask, CreateClassTaskVariables>(CREATE_CLASS_TASK, {
    refetchQueries: ['GetClassTasks'],
    awaitRefetchQueries: true,
    onCompleted: (data) => showToast(data, subscriptionId, classId, toast),
  });

  return createTask;
}
