// @flow
import { Badge, Text } from '@getatomi/neon';

import Price from 'src/components/Price/Price';
import type { PaymentFrequencyType } from 'src/types';
import paymentFrequencies from 'src/constants/paymentFrequencies';

import styles from './PlanCostDetails.module.scss';

type Props = {
  paymentFrequency: PaymentFrequencyType,
  plan: Object,
  yearlyDiscount: number,
};

export default function PlanCostDetails(props: Props) {
  const {
    paymentFrequency,
    plan: { pricing },
  } = props;

  const heading = <h1 className={styles.heading}>Unlimited access</h1>;
  const weeklyPriceLine = (
    <Text
      as="span"
      className={styles.weeklyPriceLine}
      color="colorTextSubtler"
      lineHeight="lineHeightLarge1X"
      variant="bodySmall1X"
    >
      for{' '}
      <Price
        currency={pricing.currency}
        value={pricing.weekly}
        isRounded
        removeZeroDecimals
        color="colorTextBrand"
        // Using custom font-size here as 50px doesn't exist in neon tokens
        fontFamily="fontFamilyRoot"
        fontSize={{ base: 'fontSizeLarge5X', tablet: '50px' }}
        fontWeight="fontWeightLight"
      />{' '}
      per week
    </Text>
  );
  const discount = paymentFrequency === paymentFrequencies.year && (
    <Badge color="colorTextInfo">You save {props.yearlyDiscount}%</Badge>
  );
  const billingLine = (
    <Text as="p" color="colorTextSubtler" variant="bodySmall1X">
      Billed upfront at{' '}
      <Price currency={pricing.currency} value={pricing[`${paymentFrequency}ly`]} removeZeroDecimals /> per{' '}
      {paymentFrequency}.
    </Text>
  );

  return (
    <div className={styles.root}>
      {/** mobile only **/}
      <div className={styles.mobile}>
        {heading}
        {weeklyPriceLine}
        {discount}
        {billingLine}
      </div>

      {/** tablet up only **/}
      <div className={styles.tabletUp}>
        <div>
          {heading}
          {billingLine}
        </div>
        {discount}
        {weeklyPriceLine}
      </div>
    </div>
  );
}
