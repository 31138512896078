// @flow
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type DefaultFields = {
  isTeacherForThisClass?: boolean,
  levelId: string | null,
  name: string,
  subjectCode: string | null,
};

export type SubmitFields = {|
  isTeacherForThisClass: boolean,
  levelId: string,
  name: string,
  subjectCode: string,
|};

const labels = {
  levelId: 'Year',
  subjectCode: 'Subject',
  name: 'Class name',
  isTeacherForThisClass: 'I am the teacher for this class',
};

const resolver = yupResolver(
  yup.object().shape({
    levelId: yup.string().nullable().required().label(labels.levelId),
    subjectCode: yup.string().nullable().required().label(labels.subjectCode),
    name: yup.string().trim().required().label(labels.name),
    isTeacherForThisClass: yup.boolean().label(labels.isTeacherForThisClass),
  })
);

export default function useAddClassDialogForm({
  defaultValues,
  onSubmitError,
  onSubmitSuccess,
}: {
  defaultValues: DefaultFields,
  onSubmitError: () => void,
  onSubmitSuccess: (data: SubmitFields) => void | Promise<void>,
}) {
  const { clearErrors, control, formState, handleSubmit, reset, setError, setValue, watch } = useForm({
    resolver,
    defaultValues,
  });
  const levelId = watch('levelId');

  // Reset the subject code when the level changes using react-hook-form's watch API.
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'levelId' && type === 'change') {
        setValue('subjectCode', null);
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  const onSubmit = async (event: Event) => {
    event.preventDefault();
    clearErrors('serverError');
    handleSubmit(async (data) => {
      try {
        await onSubmitSuccess(data);
      } catch (error) {
        const validationErrors = [];

        for (const gqlError of error.graphQLErrors) {
          const gqlValidationErrors = gqlError.extensions?.validation;
          if (gqlValidationErrors) {
            for (const errorMessages of Object.values(gqlValidationErrors)) {
              // $FlowIgnore - errorMessages is an array
              for (const message of errorMessages) {
                validationErrors.push(message);
              }
            }
          }
        }

        if (validationErrors.length === 0) {
          onSubmitError();
          return;
        }

        setError('serverError', { type: 'manual', message: validationErrors });
      }
    })(event);
  };

  const levelIdMessage = formState.errors.levelId?.message ?? null;
  const nameMessage = formState.errors.name?.message ?? null;
  const subjectCodeMessage = formState.errors.subjectCode?.message ?? null;

  const { serverError } = formState.errors;

  return {
    control,
    reset,
    setValue,
    serverError,
    onSubmit,
    watch,
    fields: {
      levelId: {
        label: labels.levelId,
        validationText: levelIdMessage,
        errorVariant: levelIdMessage && 'error',
      },
      subjectCode: {
        label: labels.subjectCode,
        validationText: subjectCodeMessage,
        errorVariant: subjectCodeMessage && 'error',
        isDisabled: levelId === null,
        isRequired: true,
      },
      name: {
        label: labels.name,
        placeholder: 'E.g. English 12A',
        validationText: nameMessage,
        errorVariant: nameMessage && 'error',
        isRequired: true,
        autoComplete: 'off',
        autoCorrect: 'off',
        maxLength: 70,
      },
      isTeacherForThisClass: {
        children: labels.isTeacherForThisClass,
      },
    },
  };
}
