// @flow
import { Box, Flex, Heading, Illustration, Modal, Text, useId } from '@getatomi/neon';

import type { RegistrationIsoCode } from 'src/types';
import Button from 'src/components/Button/Button';
import { registrationIsoCodes } from 'src/constants/isoCodes';

const countries = {
  [registrationIsoCodes.au]: 'Australia',
  [registrationIsoCodes.ca]: 'Canada',
};
const illustrations = {
  [registrationIsoCodes.au]: 'region-au',
  [registrationIsoCodes.ca]: 'region-ca',
};
const websites = {
  [registrationIsoCodes.au]: 'our Australian website',
  [registrationIsoCodes.ca]: 'our Canadian website',
};

export type GeoroutingDialogProps = {|
  countryApp: RegistrationIsoCode,
  countryUser: RegistrationIsoCode,
  isOpen: boolean,
  onClose: () => mixed,
  redirectUrl: string,
|};

export default function GeoroutingDialog(props: GeoroutingDialogProps) {
  const { countryApp, countryUser, isOpen, onClose, redirectUrl } = props;

  const headingId = useId();

  return (
    <Modal ariaLabelledBy={headingId} size="small" isOpen={isOpen} onClose={onClose}>
      <Flex alignItems="center" direction="column" rowGap="spacingLarge3X">
        <Illustration name={illustrations[countryUser]} />
        <Box textAlign="center">
          <Heading as="h1" id={headingId} variant="large" marginBottom="spacingSmall">
            Looks like you’re in {countries[countryUser]}!
          </Heading>
          <Text as="p" variant="bodySmall" color="colorTextSubtler">
            You’re currently on {websites[countryApp]}, would you like to switch to {countries[countryUser]} instead?
          </Text>
        </Box>
        <Flex alignItems="center" direction="column" rowGap="spacingRoot">
          <Button as="a" href={redirectUrl} size="small">
            {`Yes, switch to ${countries[countryUser]}`}
          </Button>
          <Button onClick={onClose} variant="text">
            {`No, stay in ${countries[countryApp]}`}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}
