// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import _ from 'lodash';

import type {
  GetTaskProgress,
  GetTaskProgressVariables,
  GetTaskProgress_me_account_class_tasks_students as GqlStudent,
  GetTaskProgress_me_account_class_tasks_contents as GqlContent,
} from 'src/graphql/types/generated/GetTaskProgress';
import type { PostType } from 'src/types';
import createCellValue, { type CellValue } from 'src/utils/createCellValue';
import postTypes from 'src/constants/postTypes';
import userAccountStatuses from 'src/constants/userAccountStatuses';
import userStatusOnTask from 'src/constants/userStatusOnTask';
import { getPostUrl, getRevisionUrl } from 'src/utils/routes';
import getPercentageValue from 'src/domains/Markbook/utils/getPercentageValue';

import GET_TASK_PROGRESS from './GetTaskProgress.graphql';

type Props = {
  classId: string,
  subscriptionId: string,
  taskId: string,
};

type StatusValues = CellValue<$Keys<typeof userStatusOnTask>, number>;
type CompletedValues = CellValue<string, string>;
type ProgressValues = CellValue<boolean | number, boolean | number>;

export type StudentWithProgress = {|
  completed: CompletedValues,
  contents?: {
    [ContentKey: string]: ProgressValues,
  },
  isInactive: boolean,
  rowMeta: {|
    accountStatus: $Keys<typeof userAccountStatuses>,
    avatar: ?string,
    color: ?number,
    email: string,
    firstName: string,
    lastName: string,
    sortByValue: string,
  |},
  status: StatusValues,
|};

export type ContentColumn = {|
  id: string,
  moduleId: ?string,
  name: string,
  type: PostType,
  url: string,
|};

type TaskProgress = {|
  data: {|
    contents: Array<ContentColumn>,
    isFreePlan: boolean,
    region: string,
    students: Array<StudentWithProgress>,
  |},
  error: ?ApolloError,
  loading: boolean,
|};

const statusRanks = {
  [userStatusOnTask.COMPLETE]: 2,
  [userStatusOnTask.IN_PROGRESS]: 1,
  [userStatusOnTask.PENDING]: 0,
};

export function getProgressForContent(student: GqlStudent, content: GqlContent): ProgressValues {
  switch (content.__typename) {
    case 'ClassChallengeLesson':
    case 'ClassRevision':
      const studentMark = _.find(content.metrics.assessment.students, { studentId: student.id })?.mark;
      const studentMarkPercent = studentMark ? getPercentageValue(studentMark, 1) : false;
      return createCellValue(studentMarkPercent);
    case 'ClassTextLesson':
    case 'ClassVideoLesson':
      const completionCount = _.find(content.metrics.progress.students, {
        studentId: student.id,
      })?.cumulativeCompletionCount;
      return createCellValue(Boolean(completionCount));
    default:
      return createCellValue(false);
  }
}

export default function useGetTaskProgress(props: Props): TaskProgress {
  const { classId, subscriptionId, taskId } = props;
  const variables: $Exact<GetTaskProgressVariables> = {
    accountId: subscriptionId,
    classId,
    filters: { ids: [taskId] },
  };
  const { loading, data, error } = useQuery<GetTaskProgress, GetTaskProgressVariables>(GET_TASK_PROGRESS, {
    variables,
  });

  const accountData = data?.me?.account;
  const rawContents = accountData?.class?.tasks[0]?.contents ?? [];
  const contents = rawContents.map((content) => {
    const transformedTypename = content.__typename.toLowerCase().replace('class', '').replace('lesson', '');
    const type: PostType = postTypes[transformedTypename];

    let moduleId;
    let url;
    if (content.__typename === 'ClassRevision') {
      url = getRevisionUrl(subscriptionId, classId, content.id);
    } else {
      moduleId = content.categories[0].id;
      url = getPostUrl(subscriptionId, classId, moduleId, content.id);
    }

    return ({
      id: content.id,
      moduleId,
      name: content.name,
      type,
      url,
    }: ContentColumn);
  });

  const rawStudents = accountData?.class?.tasks[0]?.students ?? [];
  const students = rawStudents.map((student) => {
    const upperFirstName = _.upperFirst(student.firstName);
    const upperLastName = _.upperFirst(student.lastName);

    const contentProgress = {};
    for (const content of rawContents) {
      contentProgress[content.id] = getProgressForContent(student, content);
    }

    return ({
      rowMeta: {
        accountStatus: student.accountStatus,
        avatar: student.avatar,
        color: student.color,
        email: student.email,
        firstName: upperFirstName,
        lastName: upperLastName,
        sortByValue: upperLastName,
      },
      completed: createCellValue(student.progress.updatedAt),
      isInactive: student.accountStatus === userAccountStatuses.INVITED,
      status: createCellValue(student.progress.status, statusRanks[student.progress.status]),
      contents: contentProgress,
    }: StudentWithProgress);
  });

  return {
    data: {
      contents,
      isFreePlan: accountData?.plan.isFree ?? false,
      region: accountData?.region.code ?? '',
      students,
    },
    error,
    loading: loading && !data,
  };
}
