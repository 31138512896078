// @flow
import { Box, Container, Flex, Skeleton, Stack } from '@getatomi/neon';

export default function ClassListLoader() {
  return (
    <Container paddingInline={{ base: 'spacingNone', desktop: 'spacingLarge3X' }}>
      <Stack as="ul" spacing="spacingNegativeSmall4X" testHook="class-list-loader">
        {Array.from({ length: 8 }).map((_, index) => (
          <Box
            key={index}
            borderWidth="borderWidthRoot"
            borderStyle="solid"
            borderColor="colorBorderNeutral"
            paddingInline="spacingRoot"
            width="sizeFull"
          >
            <Flex alignItems="center" gap="spacingRoot" height={80}>
              <Skeleton
                size="sizeIconRoot"
                borderRadius="borderRadiusCircle"
                animation="wave"
                display={{
                  base: 'none',
                  tablet: 'block',
                }}
              />

              <Flex
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                alignItems={{
                  base: 'auto',
                  tablet: 'center',
                }}
                gap={{
                  base: 'spacingSmall',
                  tablet: 'spacingRoot',
                }}
              >
                <Skeleton width={150} height={26} animation="wave" />

                <Flex alignItems="center" gap="spacingSmall">
                  <Skeleton width={100} height={16} animation="wave" borderRadius="borderRadiusLarge" />
                  <Skeleton width={60} height={16} animation="wave" borderRadius="borderRadiusLarge" />
                </Flex>
              </Flex>
            </Flex>
          </Box>
        ))}
      </Stack>
    </Container>
  );
}
