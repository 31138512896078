// @flow
import { useCallback } from 'react';
import cx from 'classnames';

import type { Player } from 'src/components/VideoPlayer/VideoPlayer';
import VideoPlayer from 'src/components/VideoPlayer/VideoPlayer';
import spacerImage from 'src/assets/images/16-9-spacer.png';
import lessonTypes from 'src/constants/lessonTypes';
import { trackingEvents } from 'src/constants/tracking';
import { trackEvent, getLessonTrackingData } from 'src/utils/tracking';
import useCompletion from 'src/hooks/completion/useCompletion';

import type { Lesson, Subject } from '../useGetLesson';
import styles from './PostVideoPlayer.module.scss';

type UseVideoCompletionProps = {|
  classId: number,
  lesson: Lesson,
  levelId: ?string,
  subject: Subject,
  subscriptionId: number,
|};
export type PostVideoPlayerProps = {
  ...UseVideoCompletionProps,
  isEmbed: boolean,
  pagination?: React.Node,
};

export default function PostVideoPlayer(props: PostVideoPlayerProps) {
  const { pagination, lesson, levelId, isEmbed, subscriptionId, classId, subject } = props;
  const { videoId } = lesson;

  const { start: startLesson, completeLesson } = useCompletion({
    accountId: subscriptionId,
    classId,
    lessonId: lesson.id,
    subjectCode: subject.code,
    lessonType: lessonTypes.VIDEO,
  });

  const onVideoCompleted = useCallback(
    async (player: Player) => {
      trackEvent(
        trackingEvents.videoCompleted,
        {
          data: getLessonTrackingData(lesson, subject, { classId, levelId }),
          playerData: {
            currentTime: player.time(),
            percentWatched: player.percentWatched() * 100,
          },
        },
        { verifyUser: true }
      );

      await completeLesson();
    },
    [completeLesson, levelId, classId, lesson, subject]
  );

  if (!videoId) return null;

  return (
    <div className={styles.videoWrapper}>
      <div className={styles.videoWrapperInner}>
        <div className={styles.videoPlayer}>
          <VideoPlayer
            videoId={videoId}
            onComplete={onVideoCompleted}
            onStart={startLesson}
            trackingData={getLessonTrackingData(lesson, subject, { classId, levelId })}
            testHook="hide-in-percy"
          />
        </div>

        {/** Spacer image for video ratio **/}
        <img className={cx(styles.spacerImage, { [styles.isEmbedded]: isEmbed })} src={spacerImage} alt="" />

        <div className={styles.pagination}>{pagination}</div>
      </div>
    </div>
  );
}
