// @flow

export default function sortYearLevels<T>(
  levels: $ReadOnlyArray<T & { +name: string }>,
  direction: 'asc' | 'desc'
): $ReadOnlyArray<T> {
  const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

  const sortedLevels = [...levels].sort((thisLevel, thatLevel) => collator.compare(thisLevel.name, thatLevel.name));

  if (direction === 'asc') {
    return sortedLevels;
  }

  return sortedLevels.reverse();
}
