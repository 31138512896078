// @flow
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';

export type TaskFilterStatus = 'current' | 'all' | 'upcoming' | 'past' | 'draft';
export type TaskFilters = {
  postId?: string,
  statusFilter?: TaskFilterStatus,
};

type Props = {|
  filters: TaskFilters,
  onStatusFilterChange: (status: TaskFilterStatus) => mixed,
|};

export default function useClassTaskListFilters(props: Props) {
  const {
    filters: { postId, statusFilter },
    onStatusFilterChange,
  } = props;
  const isAlerting = Boolean(postId);

  const filters = (
    <Dropdown
      variant="filter"
      ariaLabel="Filter by status"
      selectedKey={statusFilter}
      // Trigger the callback at the end of the event loop to prevent DOM errors in e2e tests:
      // https://github.com/adobe/react-spectrum/discussions/1937.
      onSelectionChange={(key) => setTimeout(() => onStatusFilterChange(key), 0)}
      isDisabled={isAlerting}
    >
      <Item key="current">Current 3 weeks</Item>
      <Item key="upcoming">Upcoming tasks</Item>
      <Item key="past">Past tasks</Item>
      <Item key="draft">Drafts</Item>
      <Item key="all">All tasks</Item>
    </Dropdown>
  );
  const clearFilters = () => {
    onStatusFilterChange('all');
  };

  return [filters, clearFilters];
}
