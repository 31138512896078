// @flow
import * as React from 'react';
import { Badge } from '@getatomi/neon';

import SubjectBadge from 'src/components/SubjectBadge/SubjectBadge';

import { type Subject } from './useGetRevision';

type Props = {|
  subject: Subject,
|};

export function RevisionChallengeBadges(props: Props) {
  const { subject } = props;
  const { color, groupCode, shortName, levels } = subject;
  return (
    <>
      <SubjectBadge color={color} groupCode={groupCode} variant="filled">
        {shortName}
      </SubjectBadge>
      {levels.map((level) => (
        <React.Fragment key={level.id}>
          {' '}
          <Badge>{level.name}</Badge>
        </React.Fragment>
      ))}
    </>
  );
}
