// @flow
import { Box, HideVisually, IconCheck } from '@getatomi/neon';

type CompletionIconProps = {|
  assistiveText?: string,
  color: string,
  isHovered?: boolean,
|};

export default function CompletionIcon(props: CompletionIconProps) {
  const { assistiveText, color, isHovered } = props;

  return (
    <Box
      as="span"
      display="inline-flex"
      flexShrink="0"
      // create a new stacking context for the negative z-index to work in all use cases
      isolation="isolate"
    >
      <Box
        as="span"
        display="inline-flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        size="sizeIconSmall1X"
        color={isHovered ? 'colorIconInverted' : color}
        _before={{
          content: '""',
          backgroundColor: color,
          borderRadius: 'borderRadiusCircle',
          opacity: isHovered ? 1 : 0.2,
          position: 'absolute',
          inset: 0,
          zIndex: '-1',
        }}
      >
        <IconCheck display="inline-flex" size="sizeIconSmall3X" strokeWidth={1.5} />
      </Box>
      {assistiveText && <HideVisually>{assistiveText}</HideVisually>}
    </Box>
  );
}
