// @flow
import StripeProvider from 'src/components/StripeProvider/StripeProvider';

import CardForm from './CardForm/CardForm';

type Props = React.ElementProps<typeof CardForm>;

export default function CardDetails(props: Props) {
  const { children, ...otherProps } = props;

  return (
    <StripeProvider>
      <CardForm {...otherProps}>{children}</CardForm>
    </StripeProvider>
  );
}
