// @flow
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Container, HideVisually } from '@getatomi/neon';

import type { Dispatch, GetState, ReduxState, UserType } from 'src/types';
import { getUser, getUserRegion } from 'src/reducers/auth';
import {
  isLoggedInAsAccountOwner,
  isLoggedInAsParent,
  isLoggedInAsStudent,
  isSchoolPlan as isSchoolPlanSelector,
} from 'src/reducers/subscriptions';
import { isLevelsLoadedWithSubjects } from 'src/reducers/levels';
import { getRegionByCode } from 'src/reducers/regions';
import { loadLevels } from 'src/actions/levels';
import { loadRegion } from 'src/actions/regions';
import connectData from 'src/decorators/connectData';

import ProfileForm from './ProfileForm/ProfileForm';

type Props = {
  isAccountOwner: boolean,
  isParent: boolean,
  isSchoolPlan: boolean,
  isStudent: boolean,
  user: UserType,
};

const fetchData = (getState: GetState, dispatch: Dispatch) => {
  const promises = [];
  const state = getState();

  // load levels
  if (!isLevelsLoadedWithSubjects(state)) {
    promises.push(dispatch(loadLevels()));
  }

  // load user region details for users other than students
  const region = getUserRegion(state);
  if (!isLoggedInAsStudent(state) && !getRegionByCode(state, region)) {
    promises.push(dispatch(loadRegion({ region, authenticated: true })));
  }

  if (promises.length > 0) {
    return Promise.all(promises);
  }
};

const mapStateToProps = (state: ReduxState) => {
  return {
    user: getUser(state),
    isParent: isLoggedInAsParent(state),
    isSchoolPlan: isSchoolPlanSelector(state),
    isAccountOwner: isLoggedInAsAccountOwner(state),
    isStudent: isLoggedInAsStudent(state),
  };
};

function Profile(props: Props) {
  const { isAccountOwner, isParent, isStudent, isSchoolPlan, user } = props;
  const pageTitle = 'Profile';

  return (
    <Container maxWidth="sizeContainerSmall">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <HideVisually>
        <h2>{pageTitle}</h2>
      </HideVisually>
      <ProfileForm
        isStudent={isStudent}
        isAccountOwner={isAccountOwner}
        isParent={isParent}
        isSchoolPlan={isSchoolPlan}
        user={user}
      />
    </Container>
  );
}

export default connectData(fetchData)(connect(mapStateToProps)(Profile));
