// @flow
import Button from 'src/components/Button/Button';
import { getSchoolAccountUrl, getSettingsUrl } from 'src/utils/routes';
import type { ButtonSizesType } from 'src/types';

type Props = {|
  isLoggedInAsAccountManager: boolean,
  onNavigate: () => mixed,
  size?: ButtonSizesType,
  subscriptionId: number,
|};

export default function SettingsLink(props: Props) {
  const { isLoggedInAsAccountManager, onNavigate, subscriptionId, size } = props;

  if (!onNavigate && !subscriptionId) return null;

  return (
    <Button
      to={`${isLoggedInAsAccountManager ? getSchoolAccountUrl(subscriptionId) : getSettingsUrl(subscriptionId)}`}
      onClick={onNavigate}
      variant="tertiary"
      size={size}
    >
      Settings
    </Button>
  );
}
