// @flow
import keyMirror from 'keymirror';

export default keyMirror({
  section: null,
  text: null,
  video: null,
  challenge: null,
  revision: null,
});
