// @flow
import classnames from 'classnames/bind';
import { IconArrowBack, Text } from '@getatomi/neon';

import { UnstyledLink } from 'src/components/Link/Link';

import styles from './BackLink.module.scss';

const cx = classnames.bind(styles);

type Props = {
  children: React.Node,
  isBlock?: boolean,
  isCentered?: boolean,
  isDirectionSwitched?: boolean,
  onClick?: () => mixed,
  testHook?: string,
  to?: string,
};

export default function BackLink(props: Props) {
  const { children, isCentered = false, isBlock = false, isDirectionSwitched = false, onClick, testHook, to } = props;

  if (!children) return null;

  const Component = to ? UnstyledLink : 'button';

  const link = (
    <Component
      className={cx(styles.link, {
        isBlock,
        isDirectionSwitched,
      })}
      data-test={testHook}
      onClick={onClick}
      to={to}
      type={!to ? 'button' : undefined}
    >
      <Text as="span" fontSize="fontSizeSmall1X" color="colorTextSubtle" className={styles.linkInner}>
        <IconArrowBack className={styles.icon} size="sizeIconSmall" />
        <span>{children}</span>
      </Text>
    </Component>
  );

  return isCentered ? <div className={styles.root}>{link}</div> : link;
}
