// @flow
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export type SortBy = {|
  desc: boolean,
  id: string,
|};

const defaults = {
  sortBy: { id: 'utilisation', desc: true },
};

export const initialTableState = {
  sortBy: [defaults.sortBy],
};

function useSortedData<T>(unsortedData: $ReadOnlyArray<T>): {
  setSortBy: (value: Array<SortBy>) => void,
  sortedData: $ReadOnlyArray<T>,
} {
  const [sortBy, setSortBy] = useState<SortBy>(defaults.sortBy);
  const direction = sortBy.desc ? 'desc' : 'asc';

  const sortedData = useMemo(() => {
    // react-table changes . to _ when setting the column id for sorting, so change it back
    // https://github.com/TanStack/table/blob/main/packages/table-core/src/core/column.ts#L39C1-L39C65
    const sortById = sortBy.id.replace('_', '.');
    const sortByKey = `${sortById}.sortByValue`;
    if (sortByKey === 'utilisation.sortByValue') {
      return _.orderBy(unsortedData, [sortByKey, 'usedSeats.sortByValue'], [direction, direction]);
    }
    return _.orderBy(unsortedData, [sortByKey], [direction]);
  }, [unsortedData, sortBy.id, direction]);

  const setSortCallback = useCallback((sortByArray: Array<SortBy>) => {
    const [sortByObj] = sortByArray;

    if (sortByObj?.id == null) {
      return setSortBy(defaults.sortBy);
    }
    return setSortBy(sortByObj);
  }, []);

  return { sortedData, setSortBy: setSortCallback };
}

export default useSortedData;
