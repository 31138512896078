// @flow
import { Container } from '@getatomi/neon';

import CardGrid from './CardGrid/CardGrid';
import ClassCard from './ClassCard/ClassCard';
import type { ClassData } from '../useGetClasses/useGetClasses';

type Props = {|
  classes: $ReadOnlyArray<ClassData>,
  withStudentsCount?: boolean,
  withSubjectTag?: boolean,
|};

export default function ClassCards(props: Props) {
  const { classes, withSubjectTag = false, withStudentsCount = false } = props;

  return (
    <Container>
      <CardGrid>
        {classes.map((currentClass) => (
          <ClassCard
            key={`class-col-${currentClass.id}`}
            currentClass={currentClass}
            withSubjectTag={withSubjectTag}
            withStudentsCount={withStudentsCount}
          />
        ))}
      </CardGrid>
    </Container>
  );
}
