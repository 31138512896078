// @flow
import { IconClassStudents, Avatar as NeonAvatar } from '@getatomi/neon';

import Avatar from 'src/components/Avatar/Avatar';
import AvatarWithText from 'src/components/AvatarWithText/AvatarWithText';

import type { Student } from '../utils/prepareStudentData';

interface ClassAverage {
  type: 'ClassAverage';
}

type Props = {
  isMobile: boolean,
  value: Student | ClassAverage,
};

function RowMetaCell({ value, isMobile }: Props) {
  if (value.type === 'ClassAverage') {
    const rowName = 'Class';
    return (
      <AvatarWithText
        avatar={
          <NeonAvatar
            backgroundColor="colorBackgroundNeutralBold"
            icon={<IconClassStudents color="colorIconBold" size="sizeIconSmall" />}
            name={rowName}
          />
        }
        text={rowName}
      />
    );
  }

  return (
    <Avatar user={value} variant={value.isInactive ? 'muted' : undefined} withText withTruncatedFirstName={isMobile} />
  );
}

export default RowMetaCell;
