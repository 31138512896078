// @flow
import { connect } from 'react-redux';

import CreditInfo from 'src/components/CreditInfo/CreditInfo';
import { getCredits } from 'src/reducers/credits';
import { getPlanCurrency } from 'src/reducers/plans';
import type { CurrencyType } from 'src/types';

type Props = {
  currency: CurrencyType,
  promotionalCredits: number,
  refundableCredits: number,
};

const mapStateToProps = (state) => ({
  currency: getPlanCurrency(state),
  ...getCredits(state),
});

function BillingCredits(props: Props) {
  const { currency, promotionalCredits, refundableCredits } = props;

  return (
    <CreditInfo
      currency={currency}
      promotionalCredits={promotionalCredits}
      refundableCredits={refundableCredits}
      isFuturePayment
    />
  );
}

export default (connect(mapStateToProps, {})(BillingCredits): React.AbstractComponent<{}>);
