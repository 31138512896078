// @flow
import { Container, Text } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import links from 'src/constants/links';

export type CardTermsProps = {|
  buttonLabel: string,
  currency: string,
  isLoggedInAsParent?: boolean,
  taxCode?: ?string,
|};

export default function CardTerms(props: CardTermsProps) {
  const { buttonLabel, currency, taxCode, isLoggedInAsParent = false } = props;

  const supportCancellationLink = isLoggedInAsParent
    ? links.support.cancellingFamilySubscription
    : links.support.cancellingSubscription;

  return (
    <Container textAlign="center" maxWidth="sizeFull" paddingInline="spacingNone">
      <Text as="p" variant="bodySmall1X" color="colorTextSubtler">
        All currencies are in {currency} and includes {taxCode ? taxCode.toUpperCase() : 'tax'}. By clicking ‘
        {buttonLabel}’ you agree to the Atomi{' '}
        <Link href={links.subscriptionAgreement} isExternal variant="monochrome">
          subscription agreement
        </Link>{' '}
        and{' '}
        <Link href={links.terms} isExternal variant="monochrome">
          terms and conditions
        </Link>{' '}
        and authorise Atomi to send instructions to the financial institution that issued your card to take payments
        from the card account in accordance with the terms of your agreement with Atomi. Learn more about{' '}
        <Link href={supportCancellationLink} isExternal variant="monochrome">
          cancelling your subscription
        </Link>{' '}
        and{' '}
        <Link href={links.support.refundPolicy} isExternal variant="monochrome">
          our refund policy.
        </Link>
      </Text>
    </Container>
  );
}
