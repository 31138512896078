// @flow
// $FlowIgnoreLibdef
import { forwardRef, Suspense, useImperativeHandle } from 'react';

import '@getatomi/neon/dist/editor.css';

import useSuspense from 'src/hooks/useSuspense';

type NodeWithPos = {
  node: any,
  pos: number,
};
type OnChangeProps = {
  atomiContent: NodeWithPos[],
  attachedFiles: NodeWithPos[],
  attachedLinks: NodeWithPos[],
  markdown: string,
};

type NeonRenderer = (args: { id: string, onRemove: () => mixed }) => React.Element<*> | null;
type NeonProps = {
  ariaLabel?: string,
  ariaLabelledBy?: string,
  children?: React.Node,
  onChange?: (args: OnChangeProps) => mixed,
  placeholder?: string,
  readOnly?: boolean,
  renderAtomiContent?: NeonRenderer,
  renderFileAttachment?: NeonRenderer,
};
type EditorProps = NeonProps & {
  fileAttachmentRenderer?: (readOnly?: boolean) => NeonRenderer,
  loader: React.Node,
  renderEditor: (editor: React.Element<*>, menu: React.Element<*>) => React.Element<*>,
};
type EditorRendererProps = EditorProps & {
  editor: ?React.Element<*>,
  menu: ?React.Element<*>,
};

const SuspendedEditor = forwardRef((props, ref) => {
  const useEditor = useSuspense(import(/* webpackChunkName: "editor" */ '@getatomi/neon/dist/editor'), 'editor');
  const { editor, insertAtomiContent, insertAttachedFile, insertAttachedLink, menu, update } = useEditor.default(props);
  // add editor functions to the ref, so they can be accessed by parent
  useImperativeHandle(ref, () => ({
    insertAtomiContent,
    insertAttachedFile,
    insertAttachedLink,
    update,
  }));
  // $FlowIgnore
  return <EditorRenderer {...props} editor={editor} menu={menu} />;
});

const EditorRenderer = (props: EditorRendererProps) => {
  const { renderEditor, loader, editor, menu } = props;

  if (!editor || !menu) {
    return loader;
  }

  return renderEditor(editor, menu);
};

export default forwardRef<EditorProps, React.AbstractComponent<EditorProps>>(function Editor(props: EditorProps, ref) {
  const { fileAttachmentRenderer, ...otherProps } = props;
  const editorProps = {
    ...otherProps,
    renderFileAttachment: fileAttachmentRenderer ? fileAttachmentRenderer(props.readOnly) : undefined,
  };
  return (
    // $FlowIgnore
    <Suspense fallback={<EditorRenderer {...editorProps} editor={null} menu={null} />}>
      <SuspendedEditor {...editorProps} ref={ref} />
    </Suspense>
  );
});
