// @flow
import _ from 'lodash';

import subjectColors from 'src/constants/subjectColors';

export type SubjectColors = {|
  background: string,
  backgroundBold: string,
  backgroundSubtle: string,
  chart: string,
  icon: string,
  text: string,
|};

// Some subjects such as economics don't use the 'subject_group_code' colour but have a specific one
export default function getSubjectColors(subjectGroupCode: string, subjectBaseColor: string): SubjectColors {
  let subjectColorRange = subjectColors[subjectGroupCode];
  const subjectColor = subjectBaseColor.toLowerCase();

  if (subjectColorRange.icon !== subjectColor) {
    subjectColorRange = _.find(subjectColors, { icon: subjectColor });
  }

  return subjectColorRange;
}
