// @flow
import { useCallback, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { type ContextRouter, withRouter } from 'react-router';
import {
  ActionBar,
  Container,
  Flex,
  HelpInfo,
  HideVisually,
  Stack,
  Table,
  Truncate,
  useMediaQuery,
  useTableSelection,
  useToast,
} from '@getatomi/neon';
import cx from 'classnames';
import _ from 'lodash';

import Logger from 'src/utils/Logger';
import Button from 'src/components/Button/Button';
import ButtonAdd from 'src/components/ButtonAdd/ButtonAdd';
import EmptyStateSearch from 'src/components/EmptyStateSearch/EmptyStateSearch';
import SearchInput from 'src/components/SearchInput/SearchInput';
import Link from 'src/components/Link/Link';
import { archiveUsers, loadUsers, resendInvites, restoreUsers } from 'src/actions/users';
import {
  isActiveSubscriptionInSetupMode,
  isAdminOrAbove,
  isFamilyPlan as isFamilyPlanSelector,
  isLoggedInAsParent,
} from 'src/reducers/subscriptions';
import { isLoggedInAsSuperAdmin } from 'src/reducers/auth';
import { getStatusFilter, getSearchKeywords } from 'src/reducers/users';
import { getSubscriptionUsersUrl } from 'src/utils/routes';
import links from 'src/constants/links';
import userRoles from 'src/constants/userRoles';
import userStatuses, { userStatusLabels } from 'src/constants/userStatuses';
import type { BoundAction, ReduxState, UserType } from 'src/types';
import TextLoader from 'src/components/TextLoader/TextLoader';

import UserRoleBadge from './components/UserRoleBadge/UserRoleBadge';
import UserDropdownActions from '../UserDropdownActions/UserDropdownActions';
import EmptyStateUsers from '../EmtpyStateUsers/EmptyStateUsers';
import UserStatusFilters from '../UserStatusFilters/UserStatusFilters';
import UsersPagination from './components/UsersPagination/UsersPagination';
import CellUserName from './components/CellUserName';
import AlertDialogChangeUserStatus from '../AlertDialogChangeUserStatus/AlertDialogChangeUserStatus';
import InviteUsersDialog from '../InviteUsersDialog/InviteUsersDialog';
import prepareUserForRow from './utilities/prepareUserForRow';
import styles from './SubscriptionUsersTable.module.scss';

const log = new Logger('domains/Settings/Users/SubscriptionUsersTable');

type Props = {
  currentAuthenticatedUserId: number,
  isLoading?: boolean,
  subscriptionId: number,
  users: Array<UserType>,
  usersWithHigherRolesIds: Array<number>, // eslint-disable-line react/no-unused-prop-types
};
type InjectedProps = Props & {
  activeStatusFilter: string,
  archiveUsersAction: BoundAction<typeof archiveUsers>,
  canChangeStatus: boolean,
  canSeeEmailError: boolean,
  disabledUsers: Array<number>,
  isFamilyPlan: boolean,
  isSetupModeActive: boolean,
  isSuperAdmin: boolean,
  loadUsersAction: BoundAction<typeof loadUsers>,
  resendInvitesAction: BoundAction<typeof resendInvites>,
  restoreUsersAction: BoundAction<typeof restoreUsers>,
  router: ContextRouter,
  searchKeywords: string,
};

type ColumnMeta = {
  isMobile?: boolean,
};

const mapStateToProps = (state: ReduxState, props: Props) => {
  const activeStatusFilter = getStatusFilter(state);

  const disabledUsers = props.users
    // can't archive/restore an account owner or users with higher roles
    .filter((user) => user.role === userRoles.accountOwner || props.usersWithHigherRolesIds.includes(user.id))
    .map((user) => user.id);

  return {
    disabledUsers,
    activeStatusFilter,
    isSetupModeActive: isActiveSubscriptionInSetupMode(state),
    isSuperAdmin: isLoggedInAsSuperAdmin(state),
    canChangeStatus: !isLoggedInAsParent(state),
    canSeeEmailError: isAdminOrAbove(state),
    searchKeywords: getSearchKeywords(state),
    isFamilyPlan: isFamilyPlanSelector(state),
  };
};

function SubscriptionUsersTable(props: InjectedProps) {
  const {
    archiveUsersAction,
    activeStatusFilter,
    isLoading,
    isFamilyPlan,
    isSetupModeActive,
    isSuperAdmin,
    users,
    disabledUsers,
    canChangeStatus,
    currentAuthenticatedUserId,
    loadUsersAction,
    subscriptionId,
    searchKeywords,
    canSeeEmailError,
    resendInvitesAction,
    restoreUsersAction,
    router,
  } = props;

  const hasUsers = users && users.length > 0;
  const hasNoSearchResults = searchKeywords && !hasUsers;
  const action = activeStatusFilter === userStatuses.archived ? 'restore' : 'archive';
  const enableSelection = activeStatusFilter === userStatuses.invited || canChangeStatus;

  const isOnMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const [isProcessingUsers, setIsProcessingUsers] = useState(false);
  const [isResendingInvites, setIsResendingInvites] = useState(false);
  const [showChangeUserStatusPrompt, setShowChangeUserStatusPrompt] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [mounted, setMounted] = useState(false);
  const toast = useToast();

  const toggleChangeUserStatusPrompt = () => {
    setShowChangeUserStatusPrompt(!showChangeUserStatusPrompt);
  };
  const toggleInviteModal = () => {
    setShowInviteModal(!showInviteModal);
  };

  const onSearch = _.debounce((keywords) => {
    router.push({
      pathname: getSubscriptionUsersUrl({ subscriptionId }),
      query: { page: '1', status: activeStatusFilter, keywords },
      state: {
        refocus: false, // prevent App from throwing focus to top of document
        scrollTop: false, // prevent the scrollTopOnRouteChange HOC to scroll the page to the top
      },
    });
  }, 500);

  const resetSearchFilter = () => {
    onSearch('');
  };

  useEffect(() => {
    setMounted(true);
    return function cleanup() {
      setMounted(false);
    };
  }, [setMounted]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row: { original: user } }) => <CellUserName user={user} canSeeEmailError={canSeeEmailError} />,
        meta: {
          isHeading: true,
          isMobile: true,
          loader: TextLoader,
        },
      },
      {
        accessorKey: 'role',
        header: 'Role',
        cell: ({ getValue }) => <UserRoleBadge role={getValue()} />,
        meta: {
          loader: TextLoader,
        },
      },
      {
        accessorKey: 'year',
        header: 'Year',
        cell: ({ row: { original: user } }) => user.level?.name ?? '',
        meta: {
          loader: TextLoader,
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        cell: ({ getValue }) => <Truncate testHook="hide-in-percy">{getValue()}</Truncate>,
        meta: {
          loader: TextLoader,
        },
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        cell: ({ row: { original: user } }) => (
          <UserDropdownActions
            user={user}
            isCurrentAuthenticatedUser={Boolean(user.currentAuthenticatedUserLabel)}
            canChangeStatus={canChangeStatus && !user.isDisabled}
          />
        ),
        meta: {
          isEndAligned: true,
          isMobile: true,
          loader: TextLoader,
        },
      },
    ],
    [canChangeStatus, canSeeEmailError]
  );

  const data = useMemo(
    () =>
      users.map((user: UserType) =>
        prepareUserForRow({
          canSeeEmailError,
          canSeeNoClassesWarning: !isFamilyPlan,
          currentAuthenticatedUserId,
          isDisabled: disabledUsers.includes(user.id),
          user,
        })
      ),
    [users, disabledUsers, canSeeEmailError, currentAuthenticatedUserId, isFamilyPlan]
  );

  const enableSelectionFn = useCallback((row) => !row.original?.isDisabled, []);
  const { resetSelection, selectedIds, tableProps } = useTableSelection({
    data,
    enableSelection: enableSelectionFn,
  });

  const selectedItemCount = selectedIds.length;

  const onBulkAction = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isRestoring = activeStatusFilter === userStatuses.archived;

    toggleChangeUserStatusPrompt();
    setIsProcessingUsers(true);

    try {
      if (isRestoring) {
        await restoreUsersAction(selectedIds);
      } else {
        await archiveUsersAction(selectedIds);
      }
      const bulkAction = isRestoring ? 'restored' : 'archived';
      toast.success(
        selectedItemCount > 1
          ? `The users were ${bulkAction} successfully.`
          : `The user was ${bulkAction} successfully.`
      );

      setIsProcessingUsers(false);
    } catch (error) {
      log.info('User action failed', error);
      toast.error(
        error.statusCode === 403
          ? `You don’t have permission to ${isRestoring ? 'restore' : 'archive'} one or more of these users.`
          : `There was an error when ${
              isRestoring ? 'restoring' : 'archiving'
            } one or more of these users. Please try again.`
      );

      if (mounted) {
        setIsProcessingUsers(false);
      }
    }
  };

  const onResendInvites = async (e: SyntheticEvent<>) => {
    e.preventDefault();
    setIsResendingInvites(true);

    try {
      await resendInvitesAction(selectedIds);
      toast.success(
        selectedItemCount > 1 ? 'The invitations were sent successfully.' : 'The invitation was sent successfully.'
      );
      setIsResendingInvites(false);
    } catch (err) {
      log.info('Resending invites failed', err);
      toast.error('There was an error when sending the invitations. Please try again.');
      if (mounted) {
        setIsResendingInvites(false);
      }
    }
  };

  const navigateToPendingInvites = () => {
    if (activeStatusFilter !== userStatuses.invited) {
      const invitedUsersUrl = getSubscriptionUsersUrl({
        subscriptionId,
        page: 1,
        status: userStatuses.invited,
      });
      router.push(invitedUsersUrl);
    } else {
      loadUsersAction({ page: 1, status: userStatuses.invited, forceReload: true });
    }
  };

  const onInviteSuccess = (alertSuccess?: boolean) => {
    navigateToPendingInvites();

    if (alertSuccess) {
      if (isSetupModeActive) {
        const toastId = toast.success(
          <Stack spacing="spacingSmall2X">
            <p>
              This user was invited successfully, however because your account is in setup mode invitations have not
              been sent yet.
            </p>
            <Link href={links.support.setupMode} onClick={() => toast.hide(toastId)} isExternal>
              Learn more
            </Link>
          </Stack>,
          { duration: Infinity }
        );
      } else {
        toast.success('This user was invited successfully.');
      }
    }
  };

  const canInviteUsers = !isFamilyPlan || isSuperAdmin;

  return (
    <>
      <Container paddingInline={{ base: 'spacingNone', tablet: 'spacingRoot', desktop: 'spacingLarge3X' }}>
        <Flex
          alignItems="center"
          gap="spacingSmall"
          justifyContent="space-between"
          marginBottom={{ base: 'spacingRoot', desktop: 'spacingLarge5X' }}
          paddingInline={{ base: 'spacingRoot', tablet: 'spacingNone' }}
        >
          {/** User status filters **/}
          <UserStatusFilters subscriptionId={subscriptionId} />

          {/** Invite button **/}
          {canInviteUsers && (
            <ButtonAdd assistiveText="Invite users" onClick={toggleInviteModal} testHook="button-invite-users">
              Invite users
            </ButtonAdd>
          )}
        </Flex>

        {/** Search **/}
        {!isFamilyPlan && (
          <div className={cx(styles.searchWrapper, { [styles.isEmtpy]: !hasUsers && !hasNoSearchResults })}>
            {(hasUsers || hasNoSearchResults) && (
              <SearchInput
                placeholder="Search users"
                onChange={onSearch}
                onReset={resetSearchFilter}
                initialValue={searchKeywords}
                className={styles.searchInput}
                testHook="search-input"
              />
            )}
          </div>
        )}

        <Stack spacing="spacingLarge4X">
          {/* eslint-disable-next-line no-nested-ternary */}
          {hasUsers && __CLIENT__ ? (
            <Table
              {...(enableSelection ? tableProps : {})}
              columns={isOnMobile ? columns.filter(({ meta }: { meta: ColumnMeta }) => meta.isMobile) : columns}
              data={data}
              isLoading={isLoading}
              testHook="subscription-users-table"
              isFixedLayout
            >
              <caption>
                <HideVisually>{_.upperFirst(userStatusLabels[activeStatusFilter])} users</HideVisually>
              </caption>
              <colgroup>
                {enableSelection && <col width="50px" />}
                {isOnMobile ? (
                  <>
                    <col width="90%" />
                    <col width="65rem" />
                  </>
                ) : (
                  <>
                    <col width="30%" />
                    <col width="20%" />
                    <col width="10%" />
                    <col width="40%" />
                    <col width="65rem" />
                  </>
                )}
              </colgroup>
            </Table>
          ) : hasNoSearchResults ? (
            <EmptyStateSearch contentType="users" searchTerm={searchKeywords} />
          ) : (
            <EmptyStateUsers status={activeStatusFilter} isVisible={!hasUsers} />
          )}
          {isFamilyPlan && (
            <HelpInfo testHook="help-info-family">
              You can only have <strong>1 Parent</strong> and <strong>1 Student</strong> on a Family account for now.{' '}
              <Link href={links.support.parentUsersLimit} isExternal variant="monochrome">
                Learn more.
              </Link>
            </HelpInfo>
          )}
        </Stack>
      </Container>
      <Container>
        <UsersPagination />
      </Container>
      <AlertDialogChangeUserStatus
        action={action}
        numSelectedUsers={selectedItemCount}
        isOpen={showChangeUserStatusPrompt}
        onDismiss={toggleChangeUserStatusPrompt}
        onConfirm={onBulkAction}
      />
      {enableSelection && (
        <ActionBar selectedItemCount={selectedItemCount} onClearSelection={resetSelection}>
          {activeStatusFilter === userStatuses.invited && (
            <Button onClick={onResendInvites} isLoading={isResendingInvites} isDisabled={isProcessingUsers}>
              Resend invites
            </Button>
          )}
          {canChangeStatus && (
            <Button
              onClick={toggleChangeUserStatusPrompt}
              isLoading={isProcessingUsers}
              isDisabled={isResendingInvites}
            >
              {_.capitalize(action)}
            </Button>
          )}
        </ActionBar>
      )}
      {canInviteUsers && (
        <InviteUsersDialog
          onSuccess={onInviteSuccess}
          onViewPendingInvites={navigateToPendingInvites}
          onClose={toggleInviteModal}
          showModal={showInviteModal}
        />
      )}
    </>
  );
}

export default withRouter(
  connect(mapStateToProps, {
    archiveUsersAction: archiveUsers,
    loadUsersAction: loadUsers,
    resendInvitesAction: resendInvites,
    restoreUsersAction: restoreUsers,
  })(SubscriptionUsersTable)
);
