// @flow
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useCallback } from 'react';

import type { FormPhoneNumber } from 'src/types';
import phoneNumberValidator from 'src/hooks/forms/validators/phoneNumberValidator';
import { parseValidationErrors } from 'src/api/validation';
import { trackPotentialUser } from 'src/utils/tracking';

export type Fields = {
  accountName?: string,
  phoneNumber?: FormPhoneNumber,
  position?: string,
  schoolId?: number | string,
};

export type OutboundFields = {
  accountName?: string,
  phoneNumber?: string,
  position?: string,
  schoolId?: number | string,
};

// Transform internal field data to a flat structure for the consuming component. In this case, we
// are unwrapping the nested phone number (which, from Neon.PhoneNumber, contains other properties
// such as valid and value)
const transformOutboundData = (data: Fields): OutboundFields => ({
  accountName: data.accountName,
  position: data.position,
  schoolId: data.schoolId,
  phoneNumber: data.phoneNumber?.number,
});

const labels = {
  schoolId: 'School',
  position: 'Role',
  accountName: 'Give your account a name',
  accountNameAlternate: 'Account name',
  phoneNumber: 'Phone number',
};

const resolver = yupResolver(
  yup.object().shape({
    schoolId: yup.lazy((value) => {
      if (typeof value === 'number') {
        return yup.number().required().label(labels.schoolId);
      }
      return yup.string().required().label(labels.schoolId);
    }),
    position: yup.string().trim().required().label(labels.position),
    accountName: yup.string().trim().required().label(labels.accountNameAlternate),
    phoneNumber: yup.mixed().test('isValid', '', function test(phoneNumber) {
      return phoneNumberValidator({ phoneNumber, createError: this.createError });
    }),
  })
);

export default function useTeacherRegistrationFormStep2({
  defaultValues,
  onSubmitSuccess,
  onSubmitFail,
}: {
  defaultValues: Fields,
  onSubmitFail: () => void,
  onSubmitSuccess: (data: OutboundFields) => void | Promise<void>,
}) {
  const { clearErrors, control, formState, getValues, handleSubmit, setError, setValue } = useForm({
    resolver,
    defaultValues,
  });

  const onSubmit = useCallback(
    async (e: Event) => {
      e.preventDefault();
      clearErrors('serverError');
      handleSubmit(async (data) => {
        // See "Redux in form hooks" in Forms - The Basics (README.md#redux-and-createresolver)
        try {
          await onSubmitSuccess(transformOutboundData(data));
        } catch (error) {
          setError('serverError', { type: 'manual', message: parseValidationErrors(error) });
        }
      }, onSubmitFail)(e);
    },
    [clearErrors, handleSubmit, onSubmitFail, onSubmitSuccess, setError]
  );

  const schoolIdMessage = formState.errors.schoolId?.message ?? null;
  const positionMessage = formState.errors.position?.message ?? null;
  const accountNameMessage = formState.errors.accountName?.message ?? null;
  const phoneNumberMessage = formState.errors.phoneNumber?.message ?? null;

  const { serverError } = formState.errors;

  return {
    control,
    setValue,
    serverError,
    form: {
      onSubmit,
    },
    tracking: {
      trackSchoolId: () => trackPotentialUser('schoolId', getValues('schoolId')),
      trackPosition: () => trackPotentialUser('position', getValues('position')),
      trackAccountName: () => trackPotentialUser('accountName', getValues('accountName')),
      trackPhoneNumber: () => trackPotentialUser('phoneNumber', getValues('phoneNumber')),
    },
    fields: {
      schoolId: {
        label: labels.schoolId,
        validationText: schoolIdMessage,
        errorVariant: schoolIdMessage && 'error',
      },
      position: {
        label: labels.position,
        validationText: positionMessage,
        errorVariant: positionMessage && 'error',
      },
      accountName: {
        label: labels.accountName,
        validationText: accountNameMessage,
        errorVariant: accountNameMessage && 'error',
        isRequired: true,
      },
      phoneNumber: {
        label: labels.phoneNumber,
        validationText: phoneNumberMessage,
        errorVariant: phoneNumberMessage && 'error',
        isRequired: true,
      },
    },
  };
}
