// @flow
import {
  IconCircleHelp,
  IconCirclePlay,
  IconCirclePractice,
  IconCircleRevision,
  IconCircleSolidCheck,
  IconCircleStar,
  IconCircleText,
  IconLock,
} from '@getatomi/neon';

import { isPracticeChallenge, isInteractiveLesson } from 'src/utils/challenge';
import postTypes from 'src/constants/postTypes';
import type { ChallengeTier } from 'src/types';

export type PostIconProps = {|
  challengeTier: ?ChallengeTier,
  className?: string,
  color?: string,
  isCompleted?: boolean,
  isLocked?: boolean,
  postType: $Keys<typeof postTypes>,
  size?: string | { base?: string, desktop?: string, tablet?: string, wide?: string },
  testHook?: string,
|};

export default function PostIcon(props: PostIconProps) {
  const { challengeTier, isCompleted, isLocked, postType, ...iconProps } = props;

  if (isLocked) {
    return <IconLock {...iconProps} />;
  }
  if (isCompleted) {
    return <IconCircleSolidCheck {...iconProps} />;
  }

  let Icon = IconCircleText;

  switch (postType) {
    case postTypes.challenge:
      if (challengeTier) {
        if (isPracticeChallenge(challengeTier)) {
          Icon = IconCirclePractice;
        } else if (isInteractiveLesson(challengeTier)) {
          Icon = IconCircleStar;
        } else {
          Icon = IconCircleHelp;
        }
      }
      break;
    case postTypes.revision:
      Icon = IconCircleRevision;
      break;
    case postTypes.text:
      Icon = IconCircleText;
      break;
    case postTypes.video:
      Icon = IconCirclePlay;
      break;
    default:
      break;
  }

  return <Icon {...iconProps} />;
}
