// @flow

import { Box, Stack, Text, TextField } from '@getatomi/neon';
import { useState } from 'react';

import { isValidURL } from 'src/utils/url';
import Button from 'src/components/Button/Button';
import FormMessage from 'src/components/FormMessage/FormMessage';

import SamlSsoActivateDialog, { type ConfigureType } from './SamlSsoActivateDialog/SamlSsoActivateDialog';
import XmlFileField from './XmlFileField/XmlFileField';

export default function SamlSsoFormView() {
  const [urlValidationError, setUrlValidationError] = useState<boolean>(false);
  const [emptyValidationError, setEmptyValidationError] = useState<boolean>(false);
  const [configureType, setConfigureType] = useState<ConfigureType | null>(null);
  const [xmlInputValue, setXmlInputValue] = useState<string | null>(null);
  const [urlInputValue, setUrlInputValue] = useState<string>('');

  const handleSaveXml = (xml: string) => {
    setConfigureType({ type: 'xml', xml });
  };

  const handleSaveUrl = (url: string) => {
    if (isValidURL(url)) {
      setConfigureType({ type: 'url', url });
    } else {
      setUrlValidationError(true);
    }
  };

  const clearErrors = () => {
    setUrlValidationError(false);
    setEmptyValidationError(false);
  };

  const handleOnSave = () => {
    clearErrors();
    if (urlInputValue) {
      handleSaveUrl(urlInputValue);
    } else if (xmlInputValue) {
      handleSaveXml(xmlInputValue);
    } else {
      setEmptyValidationError(true);
    }
  };

  return (
    <Box as="form" textAlign="left">
      <Stack as="fieldset" spacing="spacingLarge3X">
        <Stack spacing="spacingLarge">
          <Text as="p" variant="bodySmall1X" color="colorTextSubtle">
            SAML is a security standard for logging into applications. It enables the <strong>identity provider</strong>{' '}
            to manage user accounts and credentials.
          </Text>
          <Text as="p" variant="bodySmall1X" color="colorTextSubtle">
            Your identity provider will provide you with a <strong>Metadata URL</strong> or <strong>XML file</strong>.
            Once you have the URL or file, add it to this page and click 'Save'. Doing so will activate SAML SSO for
            your school and connect your identity provider to your Atomi account.
          </Text>
        </Stack>
        <Stack spacing="spacingLarge1X">
          {emptyValidationError && <FormMessage>Either a URL or file is required to continue.</FormMessage>}
          <Text as="legend" fontSize="fontSizeLarge">
            Enter URL OR upload file
          </Text>
          <TextField
            errorVariant={urlValidationError ? 'error' : undefined}
            validationText={urlValidationError ? 'Enter a valid URL' : undefined}
            label="URL"
            onChange={(value) => setUrlInputValue(value)}
            value={urlInputValue}
          />
          <XmlFileField label="Upload file" onChange={setXmlInputValue} />
        </Stack>
        <Button type="button" onClick={handleOnSave}>
          Save
        </Button>
      </Stack>
      <SamlSsoActivateDialog
        isOpen={Boolean(configureType)}
        configureType={configureType}
        onDone={() => {
          setConfigureType(null);
        }}
      />
    </Box>
  );
}
