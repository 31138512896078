// @flow
import { Container } from '@getatomi/neon';
import Helmet from 'react-helmet';
import { useQuery } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetRevisionOverview,
  GetRevisionOverviewVariables,
} from 'src/graphql/types/generated/GetRevisionOverview';
import { type NavigationContext } from 'src/utils/navigationContext';
import { useNavigationContext } from 'src/hooks/useNavigationContext';
import BackLink from 'src/components/BackLink/BackLink';
import { prepareNavigation } from 'src/utils/prepareNavigation';
import { getRevisionMarksUrl, getRevisionProgressReportUrl } from 'src/utils/routes';

import OverviewLoader from './Overview/OverviewLoader/OverviewLoader';
import GET_REVISION_OVERVIEW from './GetRevisionOverview.graphql';
import { getRevisionQuestionUrlFactory } from './utilities/getQuestionUrlFactory';
import { prepareRevisionOverviewData } from './utilities/prepareOverviewData/prepareOverviewData';
import { getIsTeacherReviewFeatureEnabled } from './utilities/teacherReviewFeature';
import Overview from './Overview/Overview';

export type RevisionOverviewContainerProps = {|
  params: {|
    classId: string,
    revisionId: string,
    subscriptionId: string,
  |},
|};

export function RevisionOverviewContainer(props: RevisionOverviewContainerProps) {
  const { params } = props;
  const { classId, revisionId, subscriptionId: accountId } = params;

  const [navigationContext] = useNavigationContext();

  const classTasksPageFallback: NavigationContext = {
    location: 'classTasks',
    metadata: {
      accountId,
      classId,
      title: 'Tasks',
    },
  };

  const { backUrl, backLinkUrl, backLinkLabel } = prepareNavigation(navigationContext ?? classTasksPageFallback);

  const marksUrl = getRevisionMarksUrl(accountId, classId, revisionId);

  const overviewUrl = getRevisionProgressReportUrl(accountId, classId, revisionId);

  const {
    data: rawOverviewData,
    loading,
  }: {
    data: ?GetRevisionOverview,
    loading: boolean,
  } = useQuery<GetRevisionOverview, GetRevisionOverviewVariables>(GET_REVISION_OVERVIEW, {
    variables: {
      accountId,
      classId,
      revisionId,
      filters: {
        roles: ['STUDENT'],
      },
    },
  });

  if (loading && !rawOverviewData) {
    return (
      <OverviewLoader
        heading="How’s everyone going with this revision?"
        marksUrl={marksUrl}
        overviewUrl={overviewUrl}
      />
    );
  }

  invariant(rawOverviewData, 'Query data should be defined');

  const accountData = rawOverviewData?.me?.account;

  const isTeacherReviewFeatureEnabled = accountData
    ? getIsTeacherReviewFeatureEnabled({ accountFeatures: accountData.enabledFeatures })
    : false;

  const getRevisionQuestionUrl = getRevisionQuestionUrlFactory({
    accountId,
    classId,
    challengeId: revisionId,
    returnTo: backUrl,
    isTeacherReviewFeatureEnabled,
  });

  const data = prepareRevisionOverviewData(rawOverviewData, getRevisionQuestionUrl);

  return (
    <>
      <Helmet>
        <title>{data.title}</title>
      </Helmet>
      <Container>
        <BackLink to={backLinkUrl}>{`Back to ${backLinkLabel}`}</BackLink>
      </Container>
      <Overview
        averageMark={data.averageMark}
        challengeTier={data.challengeTier}
        challengingQuestions={data.challengingQuestions}
        completetionRate={data.completetionRate}
        marksUrl={marksUrl}
        overviewUrl={overviewUrl}
        shouldHideRevisionPanel
        students={data.students}
        subheading={data.subheading}
      />
    </>
  );
}

export default RevisionOverviewContainer;
