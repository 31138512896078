// @flow
import { AlertDialog } from '@getatomi/neon';
import keyMirror from 'keymirror';
import pluralize from 'pluralize';

import { toProperCase } from 'src/utils/string';

const actions = keyMirror({
  archive: null,
  restore: null,
});

type Props = {
  action: $Keys<typeof actions>,
  numSelectedUsers?: number,
};

export default function AlertDialogChangeUserStatus(props: Props) {
  const { numSelectedUsers = 1, action, ...otherProps } = props;
  const pronoun = pluralize('this', numSelectedUsers);
  const noun = pluralize('user', numSelectedUsers);
  const isArchiveAction = action === actions.archive;
  const heading = `Are you sure you want to ${action} ${pronoun} ${noun}?`;

  return (
    <AlertDialog
      {...otherProps}
      variant={isArchiveAction ? 'danger' : undefined}
      heading={heading}
      onConfirmLabel={`${toProperCase(action)} ${noun}`}
    >
      {isArchiveAction &&
        'Archived users will no longer have access to your school account. You’ll still be able to view their profile or restore their account at anytime.'}
    </AlertDialog>
  );
}
