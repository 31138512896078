// @flow

import { type NavigationContext } from 'src/utils/navigationContext';
import { useNavigationContext } from 'src/hooks/useNavigationContext';
import { prepareNavigation } from 'src/utils/prepareNavigation';
import type { UserStatusOnTask } from 'src/domains/Tasks/types';

import TaskProgressReport from './TaskProgressReport';

type Params = {
  classId: string,
  subscriptionId: string,
  taskId: string,
};

type Props = {
  location: {
    query: {
      status?: UserStatusOnTask,
    },
  },
  params: Params,
};

export default function TaskProgressReportContainer(props: Props) {
  const {
    params: { classId, subscriptionId, taskId },
    location: { query },
  } = props;

  const [navigationContext] = useNavigationContext();

  const classTasksPageFallback: NavigationContext = {
    location: 'classTasks',
    metadata: {
      accountId: subscriptionId,
      classId,
      title: 'Tasks',
    },
  };

  const { backLinkUrl, backLinkLabel } = prepareNavigation(navigationContext ?? classTasksPageFallback);

  return (
    <TaskProgressReport
      backLinkUrl={backLinkUrl}
      backLinkLabel={backLinkLabel}
      classId={classId}
      status={query.status}
      subscriptionId={subscriptionId}
      taskId={taskId}
    />
  );
}
