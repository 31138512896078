// @flow
import keyMirror from 'keymirror';
import { Box, Container, Heading, IconAlertError, IconAlertSuccess, Stack, Text } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';
import SubscriptionLink from 'src/components/SubscriptionLink/SubscriptionLink';

const variants = keyMirror({
  error: null,
  success: null,
});

type Variant = $Keys<typeof variants>;

function ActionResultIcon(props: { variant?: Variant }) {
  const { variant } = props;
  const iconProps = {
    display: 'inline-block',
    size: 'sizeIconLarge2X',
    strokeWidth: '2',
  };

  if (variant === 'error') {
    return <IconAlertError color="colorIconDanger" {...iconProps} />;
  }

  return <IconAlertSuccess color={variant === 'success' ? 'colorIconSuccess' : 'colorIconBrand'} {...iconProps} />;
}

type ActionResultProps = {
  cta?: React.ChildrenArray<React.Element<typeof Link | typeof Button | typeof SubscriptionLink>>,
  description?: React.Node,
  heading: string,
  headingId?: string,
  isFullViewport?: boolean,
  testHook?: string,
  variant?: Variant,
};

export default function ActionResult(props: ActionResultProps) {
  const { heading, headingId, cta, description, isFullViewport = false, testHook, variant } = props;

  if (!heading) return null;

  return (
    <Box
      {...(isFullViewport
        ? {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: '100vh',
            overflowX: 'auto',
            paddingBlock: 'spacingLarge3X',
          }
        : {})}
      testHook={testHook}
    >
      <Container
        paddingInline={isFullViewport ? undefined : 'spacingNone'}
        maxWidth="sizeContainerRoot"
        textAlign="center"
      >
        <Stack spacing="spacingLarge1X">
          <ActionResultIcon variant={variant} />
          <Stack spacing="spacingLarge3X">
            <Stack>
              <Heading id={headingId} as={isFullViewport ? 'h1' : 'h2'} testHook={testHook && `${testHook}-heading`}>
                {heading}
              </Heading>
              {description && <Text variant="lead">{description}</Text>}
            </Stack>
            {cta}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
