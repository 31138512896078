// @flow
import { HideVisually, useMediaQuery } from '@getatomi/neon';

import type { LessonProgress } from 'src/types';
import CompletionCount from 'src/components/CompletionCount/CompletionCount';
import getStudentCountText from 'src/utils/getStudentCountText';

import PostStrength from './PostStrength/PostStrength';

export type PostProgressProps = {|
  color: string,
  onClick?: () => mixed,
  progress: ?LessonProgress,
  progressCount?: number,
  studentsCount?: number,
  withStrengthIndicator?: boolean,
|};

function PostProgress(props: PostProgressProps) {
  const { color, onClick, progress, progressCount, studentsCount, withStrengthIndicator } = props;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  if (!withStrengthIndicator && !progressCount) {
    return null;
  }

  return (
    <>
      {!!progressCount && !!studentsCount && onClick && (
        <CompletionCount color={color} onClick={onClick} testHook="post-progress-button">
          {isMobile
            ? progressCount
            : getStudentCountText({ count: progressCount, total: studentsCount, totalText: 'Everyone' })}
          <HideVisually>completed</HideVisually>
        </CompletionCount>
      )}
      {withStrengthIndicator && <PostStrength color={color} progress={progress} />}
    </>
  );
}

export default PostProgress;
