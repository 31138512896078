// @flow
import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Box,
  Container,
  EmptyState,
  Flex,
  HideVisually,
  Illustration,
  Table,
  Text,
  tokens,
  useMediaQuery,
} from '@getatomi/neon';
import _ from 'lodash';
import keyMirror from 'keymirror';
import invariant from 'invariant';

import Button from 'src/components/Button/Button';
import type {
  GetInsightItems,
  GetInsightItems_me_account_metrics_insightItems_edges_node as GetInsightItemsNode,
  GetInsightItemsVariables,
  InsightItemNodeTypeInput,
  InsightItemsOrderByInput,
} from 'src/graphql/types/generated/GetInsightItems';
import Avatar from 'src/components/Avatar/Avatar';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import TextLoader from 'src/components/TextLoader/TextLoader';
import insightItemNodeTypes from 'src/constants/insightItemNodeTypes';
import userAccountStatuses from 'src/constants/userAccountStatuses';
import { format } from 'src/utils/number';
import userFullName from 'src/utils/userFullName';
import sortYearLevels from 'src/utils/sortYearLevels';

import GET_INSIGHT_ITEMS from './GetInsightItems.graphql';
import styles from './InsightsTableQuery.module.scss';

const getColumnHeadersForType = (nodeType: $Keys<typeof insightItemNodeTypes>, isRetailPlan: boolean) => {
  const columnHeadersForType = {
    [insightItemNodeTypes.user]: 'Student',
    [insightItemNodeTypes.class]: isRetailPlan ? 'Subject' : 'Class',
    [insightItemNodeTypes.level]: 'Year',
    [insightItemNodeTypes.subject]: 'Subject',
    [insightItemNodeTypes.subjectGroup]: 'Faculty',
  };
  return columnHeadersForType[nodeType];
};

const selectOptionsForType = {
  [insightItemNodeTypes.user]: 'students',
  [insightItemNodeTypes.class]: 'classes',
  [insightItemNodeTypes.subject]: 'subjects',
  [insightItemNodeTypes.subjectGroup]: 'faculties',
  [insightItemNodeTypes.level]: 'years',
};
export const columnAccessors = keyMirror({
  name: null,
  questions: null,
  lessons: null,
  hours: null,
});
const orderByOptions = {
  [columnAccessors.questions]: {
    asc: 'questions_ASC',
    desc: 'questions_DESC',
  },
  [columnAccessors.lessons]: {
    asc: 'lessons_ASC',
    desc: 'lessons_DESC',
  },
  [columnAccessors.hours]: {
    asc: 'hours_ASC',
    desc: 'hours_DESC',
  },
};

function Responsive(props: { children?: React$Node, large: React$Node }) {
  const { children = null, large } = props;
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  return isMobile ? children : large;
}

function NameCell({
  isRetailPlan,
  node,
  region,
}: {
  isRetailPlan: boolean,
  node: GetInsightItemsNode,
  region: string,
}) {
  const deletedColors = {
    avatar: tokens.colorBackgroundNeutralBold,
    name: tokens.colorTextSubtler,
  };
  let avatar;
  let circleColor;
  let name;
  let meta;
  switch (node.__typename) {
    case 'AccountUser': {
      const { firstName, lastName, level } = node;
      const isArchived = node.deletedAt;
      avatar = (
        <div className={styles.nodeAvatar}>
          <Avatar
            size="sizeAvatarSmall"
            user={{
              accountStatus: userAccountStatuses.ACTIVE,
              email: node.email,
              firstName: node.firstName,
              lastName: node.lastName,
              avatar: node.avatarUrl,
              color: node.colorCode,
              deletedAt: node.deletedAt,
            }}
          />
        </div>
      );
      name = (
        <span style={isArchived ? { color: deletedColors.name } : undefined}>
          {firstName && lastName && userFullName(firstName, lastName)}
          {isArchived && ' (Archived)'}
        </span>
      );
      meta = level && level.name;
      break;
    }
    case 'Class': {
      const { subject } = node;
      const isDeleted = !!node.deletedAt;
      circleColor = isDeleted ? deletedColors.avatar : subject.color;
      name = isRetailPlan ? (
        node.subject.name
      ) : (
        <span style={isDeleted ? { color: deletedColors.name } : undefined}>
          {node.name}
          {isDeleted && ' (Deleted)'}
        </span>
      );
      meta = isRetailPlan ? (
        node.subject.levels.map((level) => level.name).join(', ')
      ) : (
        <>
          {subject.name} {node.level?.name}
        </>
      );
      break;
    }
    case 'Subject': {
      circleColor = node.color;
      name = node.name;
      meta =
        node.levels &&
        sortYearLevels(node.levels, 'asc')
          .filter(({ region: { code } }) => region === code)
          .map((level) => level.name)
          .join(', ');
      break;
    }
    case 'SubjectGroup': {
      circleColor = node.color;
      name = node.name;
      break;
    }
    case 'YearLevel': {
      name = node.name;
      break;
    }
    default:
      break;
  }

  return (
    <div className={styles.node} data-test="insights-table-name-cell">
      {avatar && <Responsive large={avatar} />}
      {circleColor && (
        <Responsive large={<span className={styles.nodeCircle} style={{ backgroundColor: circleColor }} />} />
      )}
      {name}
      {meta && <span className={styles.nodeMeta}>{meta}</span>}
    </div>
  );
}

type Props = {
  classIds: Array<string>,
  endDate: string,
  isRetailPlan: boolean,
  levelId: ?string,
  nodeType: InsightItemNodeTypeInput,
  onNodeTypeChange: (type: InsightItemNodeTypeInput) => mixed,
  onResetFilters: () => mixed,
  startDate: string,
  studentIds: Array<string>,
  subjectGroup: ?string,
  subscriptionId: string,
  userId?: string,
  withGroupingFilters?: boolean,
};

export default function InsightsTableQuery(props: Props) {
  const pageSizeDefault = 10;
  const orderByDefault = orderByOptions.questions.desc;
  const {
    nodeType,
    onNodeTypeChange,
    subscriptionId,
    startDate,
    endDate,
    isRetailPlan,
    levelId,
    studentIds,
    classIds,
    subjectGroup,
    onResetFilters,
    userId,
    withGroupingFilters = true,
  } = props;
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeDefault);
  const [orderBy, setOrderBy] = useState<InsightItemsOrderByInput>(orderByDefault);
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  // called when the the page index or page size changes
  const onPaginate = useCallback((opts) => {
    setPageIndex(opts.pageIndex);
    setPageSize(opts.pageSize);
  }, []);

  const onTypeChange = useCallback(
    (type: InsightItemNodeTypeInput) => {
      setPageIndex(0);
      setOrderBy(orderByDefault);
      onNodeTypeChange(type);
    },
    [onNodeTypeChange, orderByDefault]
  );

  const onOrderByChange = useCallback(
    (sort) => {
      const [sortBy] = sort;
      if (sortBy) {
        setOrderBy(orderByOptions[sortBy.id][sortBy.desc ? 'desc' : 'asc']);
      } else if (orderBy) {
        setOrderBy(orderByDefault);
      }
    },
    [orderBy, orderByDefault]
  );

  const insightItems = useQuery<GetInsightItems, GetInsightItemsVariables>(GET_INSIGHT_ITEMS, {
    variables: ({
      accountId: subscriptionId,
      filters: {
        classIds,
        endDate,
        levelId,
        startDate,
        subjectGroup,
        type: nodeType,
        userIds: userId ? [userId] : studentIds,
      },
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy,
    }: // manually cast to catch any typos
    $Exact<GetInsightItemsVariables>),
  });

  const { error, loading } = insightItems;

  const insightsRegion = insightItems.data?.me.account.region.code;

  if (error) throw error;

  const textLoader = <TextLoader testHook="insights-table-text-loader" />;

  const columns = [
    {
      header: getColumnHeadersForType(nodeType, isRetailPlan),
      accessorKey: columnAccessors.name,
      enableSorting: false,
      cell: ({ getValue }) => <NameCell node={getValue()} isRetailPlan={isRetailPlan} region={insightsRegion} />,
      meta: {
        // only flag the column as a header row when the data is loaded to prevent a11y violations
        isHeading: !loading,
        loader: textLoader,
      },
    },
    {
      header: <Responsive large="Questions answered">Questions</Responsive>,
      accessorKey: columnAccessors.questions,
      sortDescFirst: true,
      cell: ({ getValue }) => format(getValue()),
      meta: {
        loader: textLoader,
      },
    },
    {
      header: <Responsive large="Lessons completed">Lessons</Responsive>,
      accessorKey: columnAccessors.lessons,
      sortDescFirst: true,
      cell: ({ getValue }) => format(getValue()),
      meta: {
        loader: textLoader,
      },
    },
    {
      header: <Responsive large="Hours spent">Hours</Responsive>,
      accessorKey: columnAccessors.hours,
      sortDescFirst: true,
      cell: ({ getValue }) => format(getValue()),
      meta: {
        loader: textLoader,
      },
    },
  ];

  let count;
  let rows;
  if (loading) {
    rows = _.times(pageSize, () => ({}));
  } else {
    const insights = insightItems.data.me.account.metrics.insightItems;
    invariant(insights, 'User insight items data should be available');

    count = insights.count;
    rows = insights.edges
      .filter((row) => row.node.__typename !== 'IsNodeMissing')
      .map((row) => ({
        name: row.node,
        questions: row.questions,
        lessons: row.lessons,
        hours: row.hours,
      }));
  }

  const showEmptyState = !loading && rows && rows.length === 0;

  return (
    <>
      {!userId && withGroupingFilters && (
        <Container>
          <Box marginBottom="spacingLarge2X">
            <Flex alignItems="baseline" gap="spacingSmall1X">
              <Text variant="bodyRoot">Show me</Text>
              <Dropdown
                ariaLabel="Group insights data by"
                variant="quiet"
                items={Object.keys(selectOptionsForType).map((key) => ({
                  key,
                  name: selectOptionsForType[key],
                }))}
                selectedKey={nodeType}
                onSelectionChange={(key) => onTypeChange(key)}
              >
                {(item) => <Item>{item.name}</Item>}
              </Dropdown>
            </Flex>
          </Box>
        </Container>
      )}
      <Container
        paddingInline={{
          base: showEmptyState ? 'spacingRoot' : 'spacingNone',
          tablet: 'spacingRoot',
          desktop: 'spacingLarge3X',
        }}
      >
        {showEmptyState ? (
          <EmptyState
            media={<Illustration name="emptystate-insights" />}
            description="There are no results for your selection."
            primaryAction={
              <Button variant="text" onClick={onResetFilters}>
                Clear all filters
              </Button>
            }
          />
        ) : (
          <Table
            key={`table-${nodeType}`}
            isLoading={loading}
            columns={columns}
            data={rows}
            fetchData={onPaginate}
            indexAssistiveText="Rank"
            initialState={{ pageIndex, pageSize, sortBy: [{ id: columnAccessors.questions, desc: true }] }}
            isSortable
            toggleSortBy={onOrderByChange}
            pageCount={count ? Math.ceil(count / pageSize) : undefined}
            renderPageSize={(size) => `View ${size}`}
            renderPageLabel={(index) => `Page ${index}`}
            renderIndex={isMobile ? undefined : (index) => `${index}`.padStart(2, '0')}
            sortingAssistiveText="Sort by"
            paginationLabels={['First page', 'Previous page', 'Next page', 'Last page']}
            testHook="insights-table"
          >
            <caption>
              <HideVisually>List of {selectOptionsForType[nodeType]}</HideVisually>
            </caption>
            <colgroup>
              <Responsive large={<col />} />
              <col style={{ width: '50%' }} />
              <col style={{ width: '17%' }} />
              <col style={{ width: '17%' }} />
              <col style={{ width: '13%' }} />
            </colgroup>
          </Table>
        )}
      </Container>
    </>
  );
}
