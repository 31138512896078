// @flow
import { get } from 'lodash';

import type { AvatarSizesType, Dispatch, GetState } from 'src/types';
import { USER_ENDPOINT, USERS_ENDPOINT } from 'src/api/endpoints';
import { getUserId } from 'src/reducers/auth';
import { getAvatarsByUser, isLoadingAvatar } from 'src/reducers/avatars';
import avatarSizes from 'src/constants/avatarSizes';
import { trackingEvents } from 'src/constants/tracking';

export const UPLOAD_AVATAR = 'avatars/UPLOAD_AVATAR';
export const UPLOAD_AVATAR_SUCCESS = 'avatars/UPLOAD_AVATAR_SUCCESS';
export const UPLOAD_AVATAR_FAIL = 'avatars/UPLOAD_AVATAR_FAIL';
export const LOAD_AVATAR = 'avatars/LOAD_AVATAR';
export const LOAD_AVATAR_SUCCESS = 'avatars/LOAD_AVATAR_SUCCESS';
export const LOAD_AVATAR_FAIL = 'avatars/LOAD_AVATAR_FAIL';
export const DELETE_AVATAR = 'avatars/DELETE_AVATAR';
export const DELETE_AVATAR_SUCCESS = 'avatars/DELETE_AVATAR_SUCCESS';
export const DELETE_AVATAR_FAIL = 'avatars/DELETE_AVATAR_FAIL';

export function loadAvatar(userId: number, size: AvatarSizesType = avatarSizes.small) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    if (isLoadingAvatar(state, userId, size) || getAvatarsByUser(state, userId, size)) {
      return null;
    }

    return dispatch({
      data: { userId, size },
      request: {
        types: [LOAD_AVATAR, LOAD_AVATAR_SUCCESS, LOAD_AVATAR_FAIL],
        endpoint: {
          url: `${USERS_ENDPOINT}/${userId}/avatar?size=${size}`,
        },
      },
    });
  };
}

export function uploadUserAvatar(file: FormData) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const accessToken = get(state.auth, 'user.meta.auth.access_token');
    const userId = getUserId(state);

    return dispatch({
      data: { userId },
      request: {
        types: [UPLOAD_AVATAR, UPLOAD_AVATAR_SUCCESS, UPLOAD_AVATAR_FAIL],
        endpoint: {
          accessToken,
          contentType: null,
          method: 'post',
          url: `${USER_ENDPOINT}/avatar?size=retina-large`,
          body: file,
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.avatarUploadRequested,
        data: {
          userId,
        },
      },
    });
  };
}

export function deleteAvatar(userId: number) {
  return (dispatch: Dispatch) => {
    return dispatch({
      data: { userId },
      request: {
        types: [DELETE_AVATAR, DELETE_AVATAR_SUCCESS, DELETE_AVATAR_FAIL],
        endpoint: {
          method: 'delete',
          url: `${USERS_ENDPOINT}/${userId}/avatar`,
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.avatarDeleteRequested,
        data: {
          userId,
        },
      },
    });
  };
}
