// @flow
import _ from 'lodash';

import type { LessonMetrics } from './StudentStrength';

type LessonMetricDeltas = {|
  +class: number,
  +school: number,
  +state: number,
|};

function calculateDelta(value: number, comparitor: ?number): number {
  const precision = 4;
  return _.round(value - (comparitor ?? 0), precision);
}

function calculateMarkDeltas(value: number, metrics: LessonMetrics): LessonMetricDeltas {
  return {
    state: calculateDelta(value, metrics.regionAverageMark),
    school: calculateDelta(value, metrics.schoolAverageMark),
    class: calculateDelta(value, metrics.classAverageMark),
  };
}

export default calculateMarkDeltas;
