// @flow
import Logger from 'src/utils/Logger';
import { SOCIAL_LOGIN_ENDPOINT, PROXIED_SOCIAL_LOGIN_ENDPOINT } from 'src/api/endpoints';
import { authSchema } from 'src/api/schema';
import { redirectToDashboard } from 'src/actions/auth';
import * as trackingUtils from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';
import type { Auth, SocialLoginProvider } from 'src/types';

export const CLEAR_SOCIAL_LOGIN_ERROR = 'CLEAR_SOCIAL_LOGIN_ERROR';
export const GET_SOCIAL_LOGIN_URL = 'GET_SOCIAL_LOGIN_URL';
export const GET_SOCIAL_LOGIN_URL_SUCCESS = 'GET_SOCIAL_LOGIN_URL_SUCCESS';
export const GET_SOCIAL_LOGIN_URL_FAIL = 'GET_SOCIAL_LOGIN_URL_FAIL';
export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';
export const SOCIAL_LOGIN_SUCCESS = 'SOCIAL_LOGIN_SUCCESS';
export const SOCIAL_LOGIN_FAIL = 'SOCIAL_LOGIN_FAIL';

const log = new Logger('actions/socialLogins');

export function getSocialLoginUrl(provider: SocialLoginProvider, redirectUrl: string) {
  return {
    request: {
      types: [GET_SOCIAL_LOGIN_URL, GET_SOCIAL_LOGIN_URL_SUCCESS, GET_SOCIAL_LOGIN_URL_FAIL],
      throwErrors: true,
      endpoint: {
        method: 'get',
        sendAccessToken: false,
        url: `${SOCIAL_LOGIN_ENDPOINT}/${provider}/urls?redirect_uri=${redirectUrl}`,
      },
    },
    tracking: {
      event: trackingEvents.userSocialLoginUrlRequested,
      data: { provider },
    },
  };
}

export function socialLogin(provider: SocialLoginProvider, code: string, redirectUrl: string) {
  return {
    data: { provider },
    request: {
      types: [SOCIAL_LOGIN, SOCIAL_LOGIN_SUCCESS, SOCIAL_LOGIN_FAIL],
      schema: authSchema,
      throwErrors: true,
      endpoint: {
        method: 'post',
        sendAccessToken: false,
        url: `${PROXIED_SOCIAL_LOGIN_ENDPOINT}/${provider}/login?code=${code}&redirect_uri=${redirectUrl}&include=subscriptions.school,classes`,
        body: {
          grant_type: 'application',
        },
      },
    },
    tracking: {
      event: trackingEvents.userLoginRequested,
      data: { type: 'social', provider },
    },
  };
}

export function login(provider: SocialLoginProvider, code: string, redirectUrl: string) {
  return async (dispatch: Function, getState: Function, auth: Auth) => {
    try {
      const response = await dispatch(socialLogin(provider, code, redirectUrl));

      if (response) {
        const user = response.entities.users[response.result];

        // eslint-disable-next-line camelcase
        const { access_token } = user.meta.auth;
        log.info('social login was successful: saving access_token in cookie', user);

        trackingUtils.reset();
        auth.saveAccessToken(access_token);
        dispatch(redirectToDashboard());
      }
    } catch (err) {
      if (err.statusCode === 302) {
        log.info('redirecting legacy user');
      } else {
        log.info('social login failed: ', err);
      }
    }
  };
}

export function clearSocialLoginError() {
  return {
    type: CLEAR_SOCIAL_LOGIN_ERROR,
  };
}
