// @flow
import { connect } from 'react-redux';
import { Text } from '@getatomi/neon';

import type { CurrencyType, ReduxState } from 'src/types';
import Date from 'src/components/Date/Date';
import SubscriptionLink from 'src/components/SubscriptionLink/SubscriptionLink';
import { getRenewalEstimate } from 'src/reducers/estimates';
import { getActiveSubscriptionPlan, getPlanCurrency } from 'src/reducers/plans';
import { getActiveSubscriptionExpiryDate, getActiveSubscriptionStatus } from 'src/reducers/subscriptions';
import paymentFrequencies from 'src/constants/paymentFrequencies';
import subscriptionStatuses from 'src/constants/subscriptionStatuses';

import ActiveUserPaymentDescription from '../ActiveUserPaymentDescription/ActiveUserPaymentDescription';

type Props = {
  currency: CurrencyType,
  estimate: ?Object,
  plan: Object,
  subscriptionExpiryDate: ?typeof Date,
  subscriptionStatus: string,
};

const mapStateToProps = (state: ReduxState) => ({
  currency: getPlanCurrency(state),
  estimate: getRenewalEstimate(state),
  plan: getActiveSubscriptionPlan(state),
  subscriptionExpiryDate: getActiveSubscriptionExpiryDate(state),
  subscriptionStatus: getActiveSubscriptionStatus(state),
});

function RetailBillingEstimate(props: Props) {
  const { currency, estimate, plan, subscriptionExpiryDate, subscriptionStatus } = props;
  const isSubscriptionActive = subscriptionStatus === subscriptionStatuses.active;
  const isSubscriptionCancelled = subscriptionStatus === subscriptionStatuses.cancelled;
  const isSubscriptionFuture = subscriptionStatus === subscriptionStatuses.future;
  const isSubscriptionInTrial = subscriptionStatus === subscriptionStatuses.in_trial;
  const isSubscriptionNonRenewing = subscriptionStatus === subscriptionStatuses.non_renewing;

  if (!plan || !currency || isSubscriptionInTrial || isSubscriptionFuture) return null;

  const { billing_period: paymentFrequency } = plan;
  const isYearlyPlan = paymentFrequency === paymentFrequencies.year;
  const yearlyOrMonthy = isYearlyPlan ? 'yearly' : 'monthly';

  return (
    <>
      {isSubscriptionActive && estimate && (
        <ActiveUserPaymentDescription currency={currency} estimate={estimate} frequency={paymentFrequency} />
      )}
      {isSubscriptionNonRenewing && (
        <>
          Your {yearlyOrMonthy} subscription will expire on{' '}
          <strong>
            <Date value={subscriptionExpiryDate} />
          </strong>
          . You’ll no longer be able to access content after this date.
        </>
      )}
      {isSubscriptionCancelled && (
        <>
          Your {yearlyOrMonthy} subscription expired on{' '}
          <strong>
            <Date value={subscriptionExpiryDate} />
          </strong>
          .
        </>
      )}
      <Text as="p" variant="bodySmall1X" color="colorTextSubtler">
        {isSubscriptionActive ? (
          <>
            You’re paying {yearlyOrMonthy}. Turn off automatic-renewal to cancel your subscription
            {isYearlyPlan ? (
              '.'
            ) : (
              <>
                {' '}
                or{' '}
                <SubscriptionLink upgradeYearly testHook="switch-to-yearly-plan">
                  switch to paying yearly instead
                </SubscriptionLink>
                .
              </>
            )}
          </>
        ) : (
          <>
            Turn on automatic-renewal to continue with your {yearlyOrMonthy} subscription or{' '}
            <SubscriptionLink upgradeMonthly={isYearlyPlan} upgradeYearly={!isYearlyPlan}>
              switch to paying {isYearlyPlan ? 'monthly' : 'yearly'} instead
            </SubscriptionLink>
            .
          </>
        )}
      </Text>
    </>
  );
}

export default (connect(mapStateToProps)(RetailBillingEstimate): React.AbstractComponent<{}>);
