// @flow
import { forwardRef } from 'react';
import { Dropdown as NeonDropdown } from '@getatomi/neon';

import useIntercomWidget from 'src/hooks/useIntercomWidget';

export type DropdownProps = {|
  ariaDescribedBy?: string,
  ariaLabel?: string,
  ariaLabelledBy?: string,
  autoComplete?: string,
  autoFocus?: boolean,
  children: React.Node | ((item: any) => React.Node),
  defaultSelectedKey?: string | number | null,
  errorVariant?: 'error',
  helpText?: React.Node,
  isDisabled?: boolean,
  isLoading?: boolean,
  items?: $ReadOnlyArray<any>,
  label?: React.Node,
  name?: string,
  onBlur?: (event: SyntheticFocusEvent<>) => mixed,
  onFocus?: (event: SyntheticFocusEvent<>) => mixed,
  onOpenChange?: (isOpen: boolean, isTray: boolean) => mixed,
  onSelectionChange?: (key: any) => mixed,
  placeholder?: string,
  renderTrigger?: (item: any) => React.Node,
  secondaryLabel?: React.Node,
  selectedKey?: string | number | null,
  validationText?: string,
  variant?: 'filter' | 'quiet',
|};

export const Dropdown = forwardRef<DropdownProps, HTMLButtonElement>(function Dropdown(props, ref: any) {
  const { onOpenChange, ...otherProps } = props;
  const [showIntercom, hideIntercom] = useIntercomWidget({ showOnUnmount: true });

  return (
    <NeonDropdown
      ref={ref}
      onOpenChange={(isOpen, isTray) => {
        if (isTray) {
          if (isOpen) {
            hideIntercom();
          } else {
            showIntercom();
          }
        }
        if (onOpenChange) {
          onOpenChange(isOpen, isTray);
        }
      }}
      {...otherProps}
    />
  );
});

export { DropdownLoader, Item, ItemDescription, ItemLabel } from '@getatomi/neon';
