// @flow
import { useEffect, useRef, useState } from 'react';
import { Box } from '@getatomi/neon';

type LoadingBarInstance = {
  done: () => void,
  start: () => void,
};

/**
 * This class is used to control the loading bar from anywhere in the app.
 */
class LoadingBarController {
  loadingBar: ?LoadingBarInstance = null;

  setInstance(instance: LoadingBarInstance) {
    this.loadingBar = instance;
  }

  start() {
    if (this.loadingBar) {
      this.loadingBar.start();
    }
  }

  done() {
    if (this.loadingBar) {
      this.loadingBar.done();
    }
  }
}

// Export a singleton instance of the LoadingBarController
export const loadingBar = new LoadingBarController();

type LoadingBarProps = {
  isInverted?: boolean,
};

/**
 * Loading bar component to use in the app layouts.
 */
export function LoadingBar(props: LoadingBarProps) {
  const { isInverted = false } = props;

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const startTimeRef = useRef<number | null>(null);
  const animationFrameRef = useRef<number | null>(null);
  const isFinishingRef = useRef<boolean>(false);

  const animate = (currentTime: number) => {
    if (!startTimeRef.current) {
      startTimeRef.current = currentTime;
    }
    const elapsed = currentTime - startTimeRef.current;

    if (isFinishingRef.current) {
      setProgress(100);
      // $FlowIgnore - Flow returns an opaque type for requestAnimationFrame when it should be a number
      cancelAnimationFrame(animationFrameRef.current);
      setTimeout(() => {
        setIsVisible(false);
        setProgress(0);
      }, 300); // Delay to allow the transition to complete
    } else {
      const timeToReach90Percent = 3000;
      const timeToReach100Percent = 15000;
      let newProgress = 0;
      // Normal progress: 3 seconds to 90%, then slow to 100%
      if (elapsed < timeToReach90Percent) {
        // 3 seconds to reach 90%
        newProgress = (elapsed / timeToReach90Percent) * 90;
      } else {
        newProgress = 90 + ((elapsed - timeToReach90Percent) / timeToReach100Percent) * 10; // Additional 15 seconds to reach 100%
      }
      setProgress(newProgress);
      // $FlowIgnore
      animationFrameRef.current = requestAnimationFrame(animate);
    }
  };

  const start = () => {
    setIsVisible(true);
    setProgress(0);
    isFinishingRef.current = false;
    startTimeRef.current = performance.now();
    // $FlowIgnore
    animationFrameRef.current = requestAnimationFrame(animate);
  };

  const done = () => {
    if (!isFinishingRef.current) {
      isFinishingRef.current = true;
    }
  };

  useEffect(() => {
    loadingBar.setInstance({ start, done });
    return function cleanup() {
      // $FlowIgnore
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      pointerEvents="none"
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="zIndex5"
      role="progressbar"
      aria-label="Loading page…"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={parseInt(progress, 10)}
      aria-valuetext={`${parseInt(progress, 10)}%`}
      visibility={isVisible ? 'visible' : 'hidden'}
    >
      <Box
        backgroundColor={isInverted ? 'colorBackground' : 'colorBackgroundBrand'}
        transition="transform 0.3s ease-out, opacity 0.3s ease-out 0.2s"
        opacity={isFinishingRef.current ? '0' : '100'}
        transform="scaleX(0)"
        transformOrigin="left"
        paddingBlock="spacingSmall4X"
        // using inline styles to avoid creating new CSS classes for each progress value and improve
        // performance
        style={{
          transform: `scaleX(${progress / 100})`,
        }}
        testHook="hide-in-percy"
      />
    </Box>
  );
}
