// @flow
import { forwardRef } from 'react';
import { Menu as NeonMenu } from '@getatomi/neon';

import useIntercomWidget from 'src/hooks/useIntercomWidget';

export type MenuProps = {|
  ariaDescribedBy?: string,
  ariaLabel?: string,
  ariaLabelledBy?: string,
  children: React.Node | ((item: any) => React.Node),
  disabledKeys?: Array<string>,
  id?: string,
  isDisabled?: boolean,
  items?: $ReadOnlyArray<any>,
  minWidth?: number | string,
  onAction?: (key: string) => mixed,
  onOpenChange?: (isOpen: boolean, isTray: boolean) => mixed,
  orientation?: 'vertical',
  placement?: string,
  renderHeader?: (isTray: boolean) => React.Node,
  renderTrigger: (item: any) => React.Node,
  spacing?: 'compact' | 'cosy',
  width?: number | string,
|};

export const Menu = forwardRef<MenuProps, HTMLButtonElement>(function Menu(props, ref: any) {
  const { onOpenChange, ...otherProps } = props;
  const [showIntercom, hideIntercom] = useIntercomWidget({ showOnUnmount: true });

  return (
    <NeonMenu
      ref={ref}
      onOpenChange={(isOpen, isTray) => {
        if (isTray) {
          if (isOpen) {
            hideIntercom();
          } else {
            showIntercom();
          }
        }
        if (onOpenChange) {
          onOpenChange(isOpen, isTray);
        }
      }}
      {...otherProps}
    />
  );
});

export { Item, Section } from '@getatomi/neon';
