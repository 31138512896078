// @flow
import { connect } from 'react-redux';
import { AlertDialog, useToast } from '@getatomi/neon';

import type { ReduxState } from 'src/types';
import { deletePaymentSource } from 'src/actions/paymentSources';
import { isDeletingPaymentSource } from 'src/reducers/paymentSources';
import { isPaymentSourceRequired as isPaymentSourceRequiredSelector } from 'src/reducers/subscriptions';

export type Props = {
  deletePaymentSourceAction: (paymentSourceId: number) => Promise<void>,
  isOpen: boolean,
  isPaymentSourceRequired?: boolean,
  isRemoving?: boolean,
  onDismiss: () => mixed,
  paymentSourceId: number,
};

const mapStateToProps = (state: ReduxState) => ({
  isPaymentSourceRequired: isPaymentSourceRequiredSelector(state),
  isRemoving: isDeletingPaymentSource(state),
});

function AlertDialogRemovePaymentSource(props: Props) {
  const { deletePaymentSourceAction, isPaymentSourceRequired, isRemoving, isOpen, onDismiss, paymentSourceId } = props;
  const toast = useToast();

  const onDelete = async () => {
    try {
      await deletePaymentSourceAction(paymentSourceId);
      toast.success('Your card has been deleted successfully.');
      onDismiss();
    } catch (error) {
      onDismiss();
      toast.error('There was an error deleting your card. Please try again.');
    }
  };

  return (
    <AlertDialog
      variant={isPaymentSourceRequired ? 'danger' : undefined}
      heading="Are you sure you want to delete this card from your account?"
      isOpen={isOpen}
      isLoading={isRemoving}
      onDismiss={onDismiss}
      onConfirmLabel="Delete"
      onConfirm={onDelete}
    >
      {isPaymentSourceRequired &&
        `If you remove this card from your account we will not be able to process your next payment.
            Make sure you add a new card before your next payment date to avoid loosing access to your account.`}
    </AlertDialog>
  );
}

export default (connect(mapStateToProps, { deletePaymentSourceAction: deletePaymentSource })(
  AlertDialogRemovePaymentSource
): React.AbstractComponent<Props>);
