// @flow
import { useState } from 'react';
import { connect } from 'react-redux';
import { Stack, useToast } from '@getatomi/neon';

import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import { uploadUserAvatar, deleteAvatar } from 'src/actions/avatars';
import { isDeletingAvatar, isUploadingAvatar } from 'src/reducers/avatars';
import { parseValidationErrors } from 'src/api/validation';
import type { BoundAction, ReduxState, UserType } from 'src/types';

import AvatarUpload from './AvatarUpload/AvatarUpload';

type Props = {
  isOptional?: boolean,
  user: UserType,
  withDelete?: boolean,
};

export type InjectedProps = Props & {
  deleteAvatarAction: BoundAction<typeof deleteAvatar>,
  isDeleting?: boolean,
  isUploading?: boolean,
  uploadAvatarAction: BoundAction<typeof uploadUserAvatar>,
};

const mapStateToProps = (state: ReduxState) => ({
  isDeleting: isDeletingAvatar(state),
  isUploading: isUploadingAvatar(state),
});

function AvatarUploadContainer(props: InjectedProps) {
  const { deleteAvatarAction, isDeleting, isUploading, uploadAvatarAction, user, ...otherProps } = props;

  const [serverErrors, setServerErrors] = useState<?Array<string>>(null);
  const toast = useToast();

  if (!user) {
    return null;
  }

  const onChange = async (fileData: FormData) => {
    try {
      await uploadAvatarAction(fileData);
    } catch (error) {
      const [validationErrors] = parseValidationErrors(error);
      setServerErrors(validationErrors);

      if (!validationErrors) {
        toast.error('There was an error uploading your profile photo. Please try again.');
      }
    }
  };

  const onDelete = async () => {
    try {
      await deleteAvatarAction(user.id);
      toast.success('Your profile photo has been deleted successfully.');
    } catch (error) {
      toast.error('There was an error deleting your profile photo. Please try again.');
    }
  };

  return (
    <Stack>
      {serverErrors && <ValidationErrors errors={serverErrors} />}
      <AvatarUpload
        data={user}
        onChange={onChange}
        onDelete={onDelete}
        isDeleting={isDeleting}
        isUploading={isUploading}
        {...otherProps}
      />
    </Stack>
  );
}

export default (connect(mapStateToProps, { uploadAvatarAction: uploadUserAvatar, deleteAvatarAction: deleteAvatar })(
  AvatarUploadContainer
): React.AbstractComponent<Props>);
