// @flow
import UpgradeAccountPanel from 'src/domains/Class/UpgradeAccountPanel/UpgradeAccountPanel';
import links from 'src/constants/links';
import { trackingCtas } from 'src/constants/tracking';
import Link from 'src/components/Link/Link';

import EmptyMarkbookState from '../components/EmptyMarkbookState';

export default function renderEmptyState(
  isClassEmpty: boolean,
  isFreePlan: boolean,
  params: { classId: string, subscriptionId: string },
  region: string
): ?React.Node {
  const { classId, subscriptionId: accountId } = params;

  if (isFreePlan)
    return (
      <UpgradeAccountPanel
        videoId={region === 'CA_ON' ? '75w5wkc36y' : 'tjd13m1oab'}
        heading="Want to see how your students are performing?"
        description="Upgrade to a school account to get a clear picture of your class with mark book. Mark book provides a high-level overview of marks, subject completion and task engagement in one place, making it easier to see how your class is performing."
        descriptionLink={
          <Link href={links.support.markbook} isExternal>
            See how mark book works in more detail
          </Link>
        }
        trackingData={{
          ref: trackingCtas.lockedFeatureDialog,
        }}
      />
    );

  if (isClassEmpty) return <EmptyMarkbookState classId={classId} accountId={accountId} />;

  return null;
}
