// @flow
import pluralize from 'pluralize';
import { Box, Flex, IconCalendar, IconContract, IconExpand, IconLessons, Text } from '@getatomi/neon';

import { ActionMenu, Item } from 'src/components/ActionMenu/ActionMenu';
import type { TrackingDataType } from 'src/types';
import { trackingEvents } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';

type Props = {|
  headerContent: React.Node,
  headerSubContent?: React.Node,
  isExpanded?: boolean,
  links?: $ReadOnlyArray<{| href: string, key: string, name: string |}>,
  onExpand?: ?() => void,
  onLinkAction?: (key: string) => void,
  trackingData?: TrackingDataType,
|};

type ItemCountSubContentProps = {
  itemCount: number,
  itemLabel: 'Lesson' | 'Task',
};

export function ItemCountSubContent({ itemCount, itemLabel }: ItemCountSubContentProps) {
  return (
    <>
      {itemLabel === 'Lesson' ? (
        <IconLessons size="sizeIconSmall1X" color="colorIcon" />
      ) : (
        <IconCalendar size="sizeIconSmall1X" color="colorIcon" />
      )}
      <Text
        color="colorTextSubtle"
        marginLeft="spacingSmall1X"
        fontSize="fontSizeSmall"
        fontWeight="fontWeightRegular"
      >{`${itemCount} ${pluralize(itemLabel, itemCount)}`}</Text>
    </>
  );
}

function ColumnHeaderCell({
  isExpanded = false,
  onExpand,
  headerContent,
  headerSubContent,
  links,
  onLinkAction,
  trackingData,
}: Props) {
  const expandableButton = onExpand && (
    <Box
      as="button"
      onClick={() => {
        onExpand();
        if (!isExpanded && trackingData) {
          trackEvent(trackingEvents.ctaClicked, {
            cta: trackingData.cta,
            ...trackingData.data,
          });
        }
      }}
      padding="spacingSmall3X"
      type="button"
      color="colorIconBold"
      _hover={{ color: 'colorIconHovered' }}
    >
      {isExpanded ? (
        <IconContract size="sizeIconSmall" isDecorative={false} title="Collapse" />
      ) : (
        <IconExpand size="sizeIconSmall" isDecorative={false} title="Expand" />
      )}
    </Box>
  );

  return (
    <Box whiteSpace="initial">
      <Flex gap="spacingSmall3X" justifyContent="space-between" width="sizeFull" alignItems="start">
        {headerContent}
        <Flex alignItems="center" gap="spacingSmall2X">
          {expandableButton}
          {links && (
            <Box marginRight="spacingNegativeSmall" overflow="hidden">
              <ActionMenu ariaLabel="Links" orientation="vertical" items={links} onAction={onLinkAction}>
                {(item) => <Item href={item.href}>{item.name}</Item>}
              </ActionMenu>
            </Box>
          )}
        </Flex>
      </Flex>
      {!isExpanded && Boolean(headerSubContent) && (
        <Flex alignItems="center" marginTop="spacingSmall1X">
          {headerSubContent}
        </Flex>
      )}
    </Box>
  );
}

export default ColumnHeaderCell;
