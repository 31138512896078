// @flow
import { Box, Container, Flex, Modal, useMediaQuery } from '@getatomi/neon';

type TeacherRevisionBaseDialogProps = {|
  children: React.Node,
  isOpen: boolean,
  modalActions: React.Node,
  onClose: () => void,
  scrollRef?: any,
  sidebar: React.Node,
|};

export default function TeacherRevisionBaseDialog(props: TeacherRevisionBaseDialogProps) {
  const { children, isOpen, sidebar, modalActions, onClose, scrollRef } = props;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const isErrorScreen = sidebar === null;
  if (isErrorScreen) {
    return (
      <Modal isOpen={isOpen} heading="Create revision" actions={modalActions} onClose={onClose} size="fullscreen">
        <Container marginBlock="spacingLarge7X" maxWidth="sizeContainerRoot">
          {children}
        </Container>
      </Modal>
    );
  }

  const spacingBelowHeader = 'spacingLarge5X';
  const sidebarWidth = { base: 220, wide: 320 };
  const sidebarDivider = {
    borderLeftWidth: 'borderWidthRoot',
    borderLeftStyle: 'solid',
    borderLeftColor: 'colorBorder',
  };

  return (
    <Modal
      isOpen={isOpen}
      actions={modalActions}
      heading="Create revision"
      onClose={onClose}
      scrollRef={scrollRef}
      size="fullscreen"
    >
      {isMobile ? (
        <Container marginBlock="spacingLarge" marginBottom="spacingLarge9X" maxWidth="sizeContainerRoot">
          {children}
        </Container>
      ) : (
        <Flex position="relative" minHeight="sizeFull">
          <Box as="aside" position="fixed" width={sidebarWidth} paddingTop={spacingBelowHeader}>
            {sidebar}
          </Box>

          <Box
            {...sidebarDivider}
            width="sizeFull"
            marginLeft={sidebarWidth}
            paddingTop={spacingBelowHeader}
            paddingBottom="spacingLarge7X"
          >
            <Container maxWidth="sizeContainerRoot" paddingInline="spacingLarge3X">
              {children}
            </Container>
          </Box>
        </Flex>
      )}
    </Modal>
  );
}
