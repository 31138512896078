// @flow

import type { ChallengeTier } from 'src/types';

// Type names copied from Neon
export type IllustrationName =
  | 'main-quiz-tier-1'
  | 'main-quiz-tier-2'
  | 'main-quiz-tier-3'
  | 'main-quiz-tier-5'
  | 'main-revision';

export type TierProperties = {|
  illustrationName: IllustrationName,
  name: string,
  subHeading: string | null,
|};

export type GetTierPropertiesArgs = {|
  challengeTitle: string,
  tier: ChallengeTier,
|};

export function makeTierProperties({ tier, challengeTitle }: GetTierPropertiesArgs): TierProperties {
  switch (tier) {
    case 'TIER_1_QUIZ':
    case 1: {
      return {
        illustrationName: 'main-quiz-tier-1',
        name: 'quiz',
        subHeading: 'Get ready to check what you’ve just learned.',
      };
    }
    case 'TIER_2_PRACTICE':
    case 2: {
      return {
        illustrationName: 'main-quiz-tier-2',
        name: 'practice',
        subHeading: 'Get ready for practice questions on the content so far.',
      };
    }
    case 'TIER_3_PRACTICE':
    case 3: {
      return {
        illustrationName: 'main-quiz-tier-3',
        name: 'practice',
        subHeading: 'Get ready for harder exam-style questions on the content so far.',
      };
    }
    case 'TIER_4_REVISION':
    case 4: {
      return {
        illustrationName: 'main-revision',
        name: 'revision',
        subHeading: null,
      };
    }
    case 'TIER_5_INTERACTIVE_LESSON':
    case 5: {
      return {
        illustrationName: 'main-quiz-tier-5',
        name: 'lesson',
        subHeading: `In this lesson, we’ll take a look at ${challengeTitle}.`,
      };
    }
    default: {
      throw new Error(`Unknown tier ${tier}`);
    }
  }
}
