// @flow
import { get, omit } from 'lodash';

import type { ReduxState, SocialLoginProvider } from 'src/types';
import {
  CLEAR_SOCIAL_LOGIN_ERROR,
  GET_SOCIAL_LOGIN_URL,
  GET_SOCIAL_LOGIN_URL_SUCCESS,
  GET_SOCIAL_LOGIN_URL_FAIL,
  SOCIAL_LOGIN,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAIL,
} from 'src/actions/socialLogins';

export type SocialLoginsState = {
  isLoggedIn: boolean,
  isLoggingInWith: ?SocialLoginProvider,
  isRetrievingUrl: boolean,
  loginError: ?Object,
};

const initialState = {
  isRetrievingUrl: false,
  isLoggedIn: false,
  isLoggingInWith: null,
  loginError: null,
};

export default function socialLoginsReducer(state: SocialLoginsState = initialState, action: Object = {}) {
  switch (action.type) {
    case GET_SOCIAL_LOGIN_URL:
      return {
        ...state,
        isRetrievingUrl: true,
      };
    case GET_SOCIAL_LOGIN_URL_SUCCESS:
      return {
        ...state,
        isRetrievingUrl: false,
      };
    case GET_SOCIAL_LOGIN_URL_FAIL:
      return {
        ...state,
        isRetrievingUrl: false,
        loginError: omit(get(action.routeError, 'exception.output'), 'request'),
      };

    case SOCIAL_LOGIN:
      return {
        ...state,
        isLoggingInWith: action.data.provider,
      };
    case SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoggingInWith: null,
      };
    case SOCIAL_LOGIN_FAIL: {
      const isLegacyLogin = action.routeError.status === 302;

      return {
        ...state,
        isLoggingInWith: isLegacyLogin ? state.isLoggingInWith : null,
        loginError: isLegacyLogin ? null : omit(get(action.routeError, 'exception.output'), 'request'),
      };
    }

    case CLEAR_SOCIAL_LOGIN_ERROR:
      return {
        ...state,
        loginError: null,
      };

    default:
      return state;
  }
}

// Selectors
export const isLoggedInWithSocialAccount = (state: ReduxState) => state.socialLogins.isLoggedIn;
export const isLoggingInWith = (state: ReduxState, provider: SocialLoginProvider) =>
  state.socialLogins.isLoggingInWith === provider;
export const getSocialLoginError = (state: ReduxState) => state.socialLogins.loginError;
