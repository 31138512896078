import _ from 'lodash';

import userStatuses from 'src/constants/userStatuses';
import { getSchoolById } from 'src/reducers/schools';
import { ACTIVATE_USER_SUCCESS, REGISTRATION_SUCCESS } from 'src/actions/registration';
import { UPLOAD_AVATAR_SUCCESS, DELETE_AVATAR_SUCCESS } from 'src/actions/avatars';
import {
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_LEGACY,
  LOGIN_LEGACY_FAIL,
  CLEAR_LOGIN_ERROR,
  LOAD,
  LOAD_FAIL,
  LOAD_SUCCESS,
  LOAD_AUTH_STRATEGIES,
  LOAD_AUTH_STRATEGIES_FAIL,
  LOAD_AUTH_STRATEGIES_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAIL,
  REFRESH_TOKEN_SUCCESS,
  CLEAR_DATA,
  VALIDATE_EMAIL,
  VALIDATE_EMAIL_FAIL,
  VALIDATE_EMAIL_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  LOAD_INVITED_USER,
  LOAD_INVITED_USER_SUCCESS,
  LOAD_INVITED_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_AND_SUBJECTS,
  UPDATE_USER_AND_SUBJECTS_FAIL,
  UPDATE_USER_AND_SUBJECTS_SUCCESS,
} from 'src/actions/auth';
import errorTypes from 'src/constants/errorTypes';

const initialState = {
  isLegacyLoading: false,
  isLoading: false,
  isLoggingIn: false,
  isLoggedIn: false,
  isCheckingExistingUser: false,
  isResetingPassword: false,
  isRefreshingToken: false,
  isUpdatingUser: false,
  authStrategies: [],
  user: {},
  loginError: null,
  resetPasswordError: null,
  inviteCode: null,
  inviteError: null,
  invitingUser: null,
  authStrategiesError: null,
  routeError: null,
  validationErrors: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_SUCCESS:
    case ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        user: {
          ...state.user,
          ...action.result.entities.users[action.result.result],
        },
      };
    case LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
      };

    case LOAD_AUTH_STRATEGIES:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_AUTH_STRATEGIES_SUCCESS:
      return {
        ...state,
        authStrategies: action.result,
        authStrategiesError: null,
        isLoading: false,
      };
    case LOAD_AUTH_STRATEGIES_FAIL:
      return {
        ...state,
        authStrategiesError: action.routeError,
        isLoading: false,
      };
    case LOGIN:
      return {
        ...state,
        isLoggingIn: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        loginError: null,
        isLoggedIn: true,
        user: {
          ...action.result.entities.users[action.result.result],
        },
      };
    case LOGIN_FAIL: {
      const isLegacyLogin = action.routeError.status === 302;

      return {
        ...state,
        isLoggingIn: isLegacyLogin,
        isLoggedIn: false,
        user: {},
        loginError: isLegacyLogin ? null : _.omit(_.get(action.routeError, 'exception.output'), 'request'),
      };
    }

    case LOGIN_LEGACY: {
      return {
        ...state,
        isLegacyLoading: true,
      };
    }
    case LOGIN_LEGACY_FAIL: {
      // more info at https://github.com/hschub/carbon/blob/fe9cb0f08c2ad1a5be3e5eb0389ee864bcdeea6f/src/utils/apiFetchHandler.js#L29
      const is302 = action.routeError.status === 302;

      return {
        ...state,
        isLegacyLoading: is302,
        routeError: is302 ? null : action.routeError,
      };
    }

    case LOAD_INVITED_USER:
      return {
        ...state,
        inviteCode: action.data.inviteCode,
      };
    case LOAD_INVITED_USER_SUCCESS: {
      const {
        result: { result },
      } = action;

      return {
        ...state,
        invitingUser: result.inviting_user,
        user: {
          ...result.user,
          // meta stores the access and refresh tokens for an invited user but doesn't save them to cookies (which would log them in)
          meta: result.meta,
          school: result.school,
        },
      };
    }
    case LOAD_INVITED_USER_FAIL: {
      const { routeError } = action;
      const isTokenInvalid =
        routeError.exception.output.errorType === errorTypes.InvalidInviteTokenException ||
        routeError.exception.output.errorType === errorTypes.AcceptedInviteException;
      return {
        ...state,
        user: {},
        [isTokenInvalid ? 'inviteError' : 'routeError']: routeError,
      };
    }

    case CLEAR_LOGIN_ERROR:
      return {
        ...state,
        loginError: null,
      };

    case REFRESH_TOKEN:
      return {
        ...state,
        isRefreshingToken: true,
      };

    case REFRESH_TOKEN_SUCCESS:
    case REFRESH_TOKEN_FAIL:
      return {
        ...state,
        isRefreshingToken: false,
      };

    case REGISTRATION_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: {
          ...action.result.user,
          meta: {
            ...action.result.meta,
          },
        },
      };

    case CLEAR_DATA:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        loginError: null,
        user: {},
      };

    case VALIDATE_EMAIL:
      return {
        ...state,
        isCheckingExistingUser: true,
      };
    case VALIDATE_EMAIL_FAIL:
    case VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        isCheckingExistingUser: false,
      };

    case RESET_PASSWORD:
      return {
        ...state,
        isResetingPassword: true,
      };

    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        isResetingPassword: false,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordError: null,
        validationErrors: null,
        user: {
          ...state.user,
          meta: {
            ...action.result.meta,
          },
        },
        isResetingPassword: false,
      };

    case DELETE_AVATAR_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          avatar_url: null,
        },
      };

    case UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ..._.pick(action.result, ['avatar_url', 'avatar_mime_type']),
        },
      };

    case UPDATE_USER:
    case UPDATE_USER_AND_SUBJECTS:
      return {
        ...state,
        isUpdatingUser: true,
      };
    case UPDATE_USER_FAIL:
    case UPDATE_USER_AND_SUBJECTS_FAIL:
      return {
        ...state,
        isUpdatingUser: false,
      };
    case UPDATE_USER_SUCCESS:
    case UPDATE_USER_AND_SUBJECTS_SUCCESS: {
      return {
        ...state,
        isUpdatingUser: false,
        user: {
          ...state.user,
          ...action.result.entities.users[action.result.result],
        },
      };
    }

    default:
      return state;
  }
}

// Selectors
export const isUserLoggedIn = (state) => state.auth.isLoggedIn;
export const isCheckingExistingUser = (state) => state.auth.isCheckingExistingUser;
export const isUpdatingUser = (state) => state.auth.isUpdatingUser;
export const isResetingPassword = (state) => state.auth.isResetingPassword || state.auth.isLoading;
export const isInvitedUserLoaded = (state, inviteCode) => state.auth.inviteCode === inviteCode && state.auth.user;
export const getLoginError = (state) => state.auth.loginError;
// Don't use getAuthDetails unless the user is invited but not logged in. Use auth.getAccessToken instead for logged in users
export const getAuthDetails = (state) => _.pick(_.get(state.auth, 'user.meta.auth'), ['access_token', 'refresh_token']);
export const getAuthStrategies = (state) => state.auth.authStrategies;
export const getAuthStrategiesError = (state) => state.auth.authStrategiesError;
export const isRefreshingToken = (state) => state.auth.isRefreshingToken;
export const getResetPasswordError = (state) => state.auth.resetPasswordError;
export const getResetPasswordValidationErrors = (state) => state.auth.validationErrors;
export const isLegacyLoading = (state) => state.auth.isLegacyLoading;

// User selectors
export const getUser = (state) => _.get(state, 'auth.user');
export const getUserLevel = (state) => _.get(state, 'auth.user.level', {});
export const getUserId = (state) => _.get(state, 'auth.user.id');
export const getUserEmail = (state) => _.get(state, 'auth.user.email');
export const getUserRegion = (state) => _.get(state, 'auth.user.region_code');
export const getUserSchool = (state) => {
  const schoolId = _.get(state, 'auth.user.school');
  return getSchoolById(state, schoolId);
};
export const getUserFirstName = (state) => _.get(state, 'auth.user.first_name', '') ?? '';
export const getUserLastName = (state) => _.get(state, 'auth.user.last_name', '') ?? '';
export const getUserSchoolName = (state) => _.get(getUserSchool(state), 'name');
export const getInvitingUser = (state) => _.get(state, 'auth.invitingUser');
export const getInviteError = (state) => _.get(state, 'auth.inviteError');
export const isLoggedInAsSuperAdmin = (state) => !!getUser(state).is_admin;
export const hasPassword = (state) => (getUser(state).has_password ?? true) === true;

// User status selectors
export const isUserActive = (state) => getUser(state).status === userStatuses.active;
export const isUserInvited = (state) => getUser(state).status === userStatuses.invited;

// Error states
export const isMissingProfileData = (state) => {
  return isUserActive(state) && (getUserFirstName(state).trim() === '' || getUserLastName(state).trim() === '');
};
