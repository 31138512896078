// @flow

import { type ContextRouter, withRouter } from 'react-router';

import { getRevisionFirstItemUrl } from 'src/utils/routes';
import { strengthThresholds } from 'src/utils/config';
import { ChallengeResults } from 'src/components/ChallengeResults/ChallengeResults';
import { parseChallengeResults, type ChallengeResults as ChallengeResultsType } from 'src/utils/parseChallengeResults';
import { parseNavigationContext } from 'src/utils/navigationContext';

import { RevisionChallengeLayout } from '../RevisionChallengeLayout';
import { makeContinueLinkProps } from './makeContinueLinkProps';

export type Props = {|
  accountId: string,
  challengeId: string,
  classId: string,
  isTeacher: boolean,
  router: ContextRouter,
|};

export function RevisionChallengeResults({ accountId, challengeId, classId, isTeacher, router }: Props) {
  const { query } = router.location;

  const navigationContext = query.navigationContext ? parseNavigationContext(query.navigationContext) : null;
  const challengeResults = query.data ? parseChallengeResults(query.data) : null;

  const marks: ChallengeResultsType = challengeResults ?? { scored: 0, missed: 0, skipped: 0, strength: 0 };

  // Continue link props are used for both the "back" link and the "continue" button.
  const continueProps = makeContinueLinkProps({
    accountId,
    classId,
    isTeacher,
    navigationContext,
  });

  const challengeFirstItemUrl = getRevisionFirstItemUrl({
    accountId,
    classId,
    challengeId,
    navigationContext,
  });

  const onContinue = (): void => {
    router.push(continueProps.to);
  };

  return (
    <RevisionChallengeLayout backLinkProps={continueProps}>
      <ChallengeResults
        challengeFirstItemUrl={challengeFirstItemUrl}
        scoredMarks={marks.scored}
        missedMarks={marks.missed}
        skippedMarks={marks.skipped}
        strengthPercentage={marks.strength}
        strengthThresholds={strengthThresholds}
        onContinue={onContinue}
      />
    </RevisionChallengeLayout>
  );
}

export default withRouter(RevisionChallengeResults);
