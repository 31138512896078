import _ from 'lodash';

import Logger from 'src/utils/Logger';

const log = new Logger('utils/apiFetchHandler');

function createError(response, status, request) {
  let message;
  let errorType;
  let validationErrors;

  if (typeof response === 'string') {
    message = response;
  } else {
    message = response.message || response.statusText;
    ({ errorType } = response);
  }

  if (response.errors) {
    validationErrors = response.errors;
  }

  const error = new Error(message);
  error.statusCode = _.get(response, 'status_code') || status;
  error.output = { message, errorType, request, response, validationErrors };

  return error;
}

/**
 * This can be considered a hack to handle the legacy users login response without
 * having to handle CORS on the Legacy App.
 * the `user/login` and `user/legacy-login` endpoints return a 202 with the `Location` header
 * more details at: https://hschub.atlassian.net/browse/PROD-579?focusedCommentId=37923&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-37923
 * TODO: remove this when all Legacy users have been migrated to Nucleus
 */
export async function handleRedirect(response) {
  const locationHeader = response.headers.get('Location');

  if (response.status === 202 && locationHeader) {
    if (__CLIENT__) {
      window.location.href = locationHeader;
    }

    const error = new Error('redirect');
    error.statusCode = 302;

    // reject promise with a custom error to be handled in our auth action creator/reducer
    throw error;
  }

  return response;
}

export async function parseResponse(response) {
  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.toLowerCase().includes('application/json')) {
    // handle JSON parse error for empty responses
    return response.json().catch((error) => {
      const contentLength = response.headers.get('Content-Length');
      if (+contentLength === 0) {
        return null;
      }

      log.error('API response could not be parsed due to invalid JSON', error);
      throw error;
    });
  }
  return response.text();
}

export async function rejectOnError(request, response) {
  // API response codes with error messages
  const errorStatusCodeToParse = [400, 401, 403, 404, 500];

  // Handle server side validation errors
  if (response.status === 422) {
    const res = await response.json();
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ validationErrors: res.errors, statusCode: response.status, request });
  }

  // Handle errors with parsable response
  if (errorStatusCodeToParse.includes(response.status)) {
    const res = await parseResponse(response);
    throw createError(res, response.status, request);
  } else if (response.status >= 400 && response.status < 600) {
    throw createError(response, response.status, request);
  }

  return response;
}

/**
 * @param method - request method
 * @param url - request url
 * @param sendAccessToken - if set to false the access token won't be added to the request headers
 * @returns {*}
 */
export default (async function apiFetchHandler(request, rawCookies, auth) {
  const { method = 'get', url, body, sendAccessToken = true, contentType = 'application/json' } = request;

  try {
    log.info(`making request to ${url}`);

    const fetchOptions = {
      method,
      headers: {
        Accept: 'application/json',
      },
      credentials: 'same-origin',
      body: !contentType ? body : JSON.stringify(body),
    };

    if (contentType) {
      fetchOptions.headers['Content-Type'] = contentType;
    }

    if (rawCookies) {
      fetchOptions.headers.Cookie = rawCookies;
    }

    // Add access_token to headers
    if (sendAccessToken) {
      const accessToken = request.accessToken || auth.getAccessToken();

      if (!accessToken) {
        const error = new Error(`missing access_token for this request ${url}`);
        error.statusCode = 401;
        return Promise.reject(error);
      }

      fetchOptions.headers.Authorization = `Bearer ${accessToken}`;
    }

    const apiResponse = await fetch(url, fetchOptions);

    let result = await handleRedirect(apiResponse);
    result = await rejectOnError(request, apiResponse);

    log.info(`successful request to ${apiResponse.url}`);
    const parsedResponse = await parseResponse(result);

    return parsedResponse;
  } catch (err) {
    const { message, statusCode } = err;
    const logMethod = [401, 403, 404, 422].includes(statusCode) ? 'warn' : 'error';
    log[logMethod](statusCode ? `(${statusCode}) ${message}` : message, err);
    throw err;
  }
});
