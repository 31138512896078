// @flow
import { useCallback } from 'react';
import { Badge, Box, Flex, IconCalendar, IconUser, Text, useMediaQuery } from '@getatomi/neon';

import { ActionMenu, type ActionMenuProps, Item } from 'src/components/ActionMenu/ActionMenu';
import TaskDateTime from 'src/components/TaskDateTime/TaskDateTime';

type TaskCardHeaderProps = {|
  actions?: $PropertyType<ActionMenuProps, 'items'>,
  assignedTo?: string,
  dueDate: string,
  label: ?{|
    color: string,
    text: React.Node,
    tooltip?: React.Node,
  |},
|};

function IconWithText(props: {| children: React.Node, icon: React.Node |}) {
  const { children, icon } = props;

  return (
    <Flex alignItems="center" gap="spacingSmall1X">
      {icon}
      <Text variant="bodySmall1X" fontWeight="fontWeightMedium">
        {children}
      </Text>
    </Flex>
  );
}

export default function TaskCardHeader(props: TaskCardHeaderProps) {
  const { actions, assignedTo, dueDate, label } = props;

  const isTablet = useMediaQuery({ maxWidth: 'breakpointMedium' });

  let gridTemplateAreas = `"meta actions"`;
  let gridTemplateColumns = '1fr auto';
  if (label) {
    if (isTablet) {
      gridTemplateAreas = `
      "meta  actions"
      "label label"
    `;
    } else {
      gridTemplateAreas = `"meta label actions"`;
      gridTemplateColumns = '1fr auto auto';
    }
  }

  const handleTaskAction = useCallback(
    (key: string) => {
      if (!actions) {
        return null;
      }
      const action = actions.find((item) => item.key === key);
      action && action.onSelect();
    },
    [actions]
  );

  return (
    <Box
      display="grid"
      alignItems="center"
      gridRowGap="spacingSmall"
      gridTemplateAreas={gridTemplateAreas}
      gridTemplateColumns={gridTemplateColumns}
    >
      <Flex gridArea="meta" display="inline-flex" gap="spacingRoot">
        <IconWithText icon={<IconCalendar size="sizeIconSmall2X" color="colorIconSubtle" />}>
          <TaskDateTime prefix="Due" value={new Date(dueDate)} />
        </IconWithText>
        {assignedTo && (
          <IconWithText icon={<IconUser size="sizeIconSmall2X" color="colorIconSubtle" />}>
            <Text fontWeight="fontWeightMedium" variant="bodySmall1X">
              {assignedTo}
            </Text>
          </IconWithText>
        )}
      </Flex>

      {label && (
        <Box gridArea="label" display="inline-flex">
          <Badge fillColor={label.color} variant="filled" tooltip={label.tooltip}>
            {label.text}
          </Badge>
        </Box>
      )}

      {actions && (
        <Box
          gridArea="actions"
          lineHeight="lineHeightNone"
          marginLeft="spacingSmall1X"
          marginRight="spacingNegativeSmall1X"
        >
          <ActionMenu
            ariaLabel="Task actions"
            orientation="vertical"
            items={actions}
            onAction={handleTaskAction}
            testHook="task-list-card-actions-menu"
          >
            {(item) => <Item>{item.label}</Item>}
          </ActionMenu>
        </Box>
      )}
    </Box>
  );
}
