// @flow
import { Card, Diff, Flex, Heading, ProgressCircle, Text, useMediaQuery } from '@getatomi/neon';

import { formatPercentageAsString } from 'src/utils/formatPercentage';
import { UnstyledLink } from 'src/components/Link/Link';

type Deltas = {|
  school: number,
  state: number,
|};

export type ChallengeAverageMark = {|
  deltas?: Deltas,
  value: number,
|};

type AverageMarkMetricProps = {|
  ...ChallengeAverageMark,
  to: string,
|};

const label = 'View all marks';

export default function AverageMarkMetric(props: AverageMarkMetricProps) {
  const { to, deltas, value } = props;
  const formattedPercentage = formatPercentageAsString(value);

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  return (
    <Card
      as={UnstyledLink}
      to={to}
      variant="rounded"
      padding={{ base: 'spacingRoot', tablet: 'spacingLarge' }}
      aria-label={label}
      height="sizeFull"
      testHook="average-mark-metric"
    >
      <Flex direction="column" gap="spacingLarge2X" height="sizeFull">
        <div>
          <Heading as="h2" variant="small">
            Average mark
          </Heading>
          {deltas && (
            <Flex
              as="span"
              display="inline-flex"
              alignItems={{ tablet: 'center' }}
              gap={{ tablet: 'spacingSmall2X' }}
              direction={{ base: 'column', tablet: 'row' }}
              color="colorTextSubtle"
            >
              <Diff value={deltas.school} insertAfter="vs School" testHook="average-mark-metric-school-delta" />
              <Text
                as="span"
                display={{ base: 'none', tablet: 'inline-block' }}
                color="colorTextSubtle"
                variant="bodySmall1X"
              >
                •
              </Text>
              <Diff value={deltas.state} insertAfter="vs State" testHook="average-mark-metric-state-delta" />
            </Flex>
          )}
        </div>
        <Flex grow="1" justifyContent="center" alignItems="flex-end" aria-hidden>
          <ProgressCircle
            ariaLabel={`The average mark is ${formattedPercentage}`}
            value={value}
            size={isMobile ? undefined : 'large1X'}
            testHook="average-mark-metric-progress-circle"
          >
            {formattedPercentage}
          </ProgressCircle>
        </Flex>
        <Text color="colorTextLink">{label}</Text>
      </Flex>
    </Card>
  );
}
