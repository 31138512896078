//@flow
import { Text } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import links from 'src/constants/links';

type Props = {
  schoolName?: string,
};

export default function TermsLine(props: Props) {
  const { schoolName } = props;

  return (
    <Text as="p" fontSize="fontSizeSmall1X" color="colorTextSubtler" textAlign="center">
      {schoolName && (
        <>
          This account was created and paid for by your school, <strong>{schoolName}</strong>.{' '}
        </>
      )}
      By clicking on 'Start using Atomi' you agree to our{' '}
      <Link href={links.subscriptionAgreement} isExternal>
        subscription agreement
      </Link>
      ,{' '}
      <Link href={links.terms} isExternal>
        terms and conditions
      </Link>
      , and to Atomi contacting you.
    </Text>
  );
}
