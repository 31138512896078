// @flow
import { get, omit, sumBy } from 'lodash';

import {
  LOAD_PLAN_UPDATE_ESTIMATE,
  LOAD_PLAN_UPDATE_ESTIMATE_SUCCESS,
  LOAD_PLAN_UPDATE_ESTIMATE_FAIL,
  LOAD_PLAN_RENEWAL_ESTIMATE,
  LOAD_PLAN_RENEWAL_ESTIMATE_SUCCESS,
  LOAD_PLAN_RENEWAL_ESTIMATE_FAIL,
} from 'src/actions/estimates';

type EstimatesStateType = {
  isLoadingRenewalEstimate: boolean,
  isRenewalEstimateLoaded: boolean,
  plans: Object,
  renewal: ?Object,
};
type StateType = {
  estimates: EstimatesStateType,
};

const initialState = {
  isLoadingRenewalEstimate: false,
  isRenewalEstimateLoaded: false,
  plans: {},
  renewal: null,
};

const calculateCredits = (data: Object) => {
  const promotionalCredits = sumBy(data.discounts, 'amount');
  const refundableCredits = data.credits_applied;

  return {
    promotionalCredits,
    refundableCredits,
    totalCredits: promotionalCredits + refundableCredits,
  };
};

export default function reducer(state: EstimatesStateType = initialState, action: Object = {}) {
  switch (action.type) {
    case LOAD_PLAN_UPDATE_ESTIMATE: {
      const { planCode } = action.data;
      return {
        ...state,
        plans: {
          ...state.plans,
          [planCode]: {
            ...state.plans[planCode],
            isLoading: true,
            isLoaded: false,
          },
        },
      };
    }

    case LOAD_PLAN_UPDATE_ESTIMATE_SUCCESS: {
      const { planCode } = action.data;
      return {
        ...state,
        plans: {
          ...state.plans,
          [planCode]: {
            ...state.plans[planCode],
            ...action.result,
            ...calculateCredits(action.result),
            isLoading: false,
            isLoaded: true,
          },
        },
      };
    }

    case LOAD_PLAN_UPDATE_ESTIMATE_FAIL: {
      const { planCode } = action.data;
      return {
        ...state,
        plans: omit(state.plans, planCode),
        routeError: action.routeError,
      };
    }

    case LOAD_PLAN_RENEWAL_ESTIMATE:
      return {
        ...state,
        isLoadingRenewalEstimate: true,
      };

    case LOAD_PLAN_RENEWAL_ESTIMATE_SUCCESS:
      return {
        ...state,
        isLoadingRenewalEstimate: false,
        isRenewalEstimateLoaded: true,
        renewal: { ...state.renewal, ...action.result, ...calculateCredits(action.result) },
      };

    case LOAD_PLAN_RENEWAL_ESTIMATE_FAIL:
      return {
        ...state,
        isLoadingRenewalEstimate: false,
        isRenewalEstimateLoaded: false,
      };

    default:
      return state;
  }
}

// Update estimate selectors
export const isPlanUpdateEstimateLoaded = (state: StateType, planCode: string) =>
  get(state.estimates.plans, `${planCode}.isLoaded`);
export const getAllPlanUpdateEstimates = (state: StateType) => state.estimates.plans;
export const getPlanUpdateEstimate = (state: StateType, planCode: string) => state.estimates.plans[planCode];
// Renewal estimate selectors
export const isLoadingRenewalEstimate = (state: StateType) => state.estimates.isLoadingRenewalEstimate;
export const isRenewalEstimateLoaded = (state: StateType) => state.estimates.isRenewalEstimateLoaded;
export const getRenewalEstimate = (state: StateType) => state.estimates.renewal;
