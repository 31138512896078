export default {
  plainCharacters: {
    pattern: `^[a-zA-Z'\\-\\s]*$`,
    patternMismatchErrorMessage: `Use only letters, spaces, apostrophes ('), or dashes (-)`,
  },
  email: {
    pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    patternMismatchErrorMessage: 'Enter a valid email address',
  },
  tel: {
    pattern: /^0[0-8]\d{8}$/,
    patternMismatchErrorMessage: `Enter a valid phone number`,
  },
};
