// @flow
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type Fields = {
  password: string,
};

const passwordLabel = 'Your password';

export default function usePasswordRequiredDialogForm({
  context,
  defaultValues,
  onSubmitSuccess,
}: {
  context: {
    hasPassword?: boolean,
  },
  defaultValues: Fields,
  onSubmitSuccess: (data: Fields) => void,
}) {
  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        password: yup.mixed().when('$hasPassword', {
          is: true,
          then: yup.string().label(passwordLabel).required(),
        }),
      })
    ),
    defaultValues,
    context,
  });

  const onSubmit = (e: Event) => {
    e.preventDefault();
    handleSubmit((data) => onSubmitSuccess(data))(e);
  };

  const passwordMessage = formState.errors.password?.message ?? null;

  return {
    control,
    form: {
      onSubmit,
    },
    fields: {
      password: {
        validationText: passwordMessage,
        errorVariant: passwordMessage && 'error',
        label: passwordLabel,
        isRequired: true,
      },
    },
  };
}
