// @flow
import { find } from 'lodash';

import { REGIONS_ENDPOINT } from 'src/api/endpoints';
import { planListSchema } from 'src/api/schema';
import { loadPlanUpdateEstimate } from 'src/actions/estimates';
import { getPaidPlansByCustomerType, isPlansLoaded } from 'src/reducers/plans';
import { getActiveSubscriptionRegion } from 'src/reducers/subscriptions';

export const LOAD_PLANS = 'LOAD_PLANS';
export const LOAD_PLANS_SUCCESS = 'LOAD_PLANS_SUCCESS';
export const LOAD_PLANS_FAIL = 'LOAD_PLANS_FAIL';

export function loadPlansByRegion(region: string) {
  return {
    request: {
      types: [LOAD_PLANS, LOAD_PLANS_SUCCESS, LOAD_PLANS_FAIL],
      schema: planListSchema,
      endpoint: {
        url: `${REGIONS_ENDPOINT}/${region}/plans`,
      },
    },
  };
}

export function loadPlans() {
  return (dispatch: Function, getState: Function) => {
    const region = getActiveSubscriptionRegion(getState());
    return dispatch(loadPlansByRegion(region));
  };
}

export function loadPlansAndEstimate(customerType: string, paymentFrequency: string) {
  return async (dispatch: Function, getState: Function) => {
    if (!isPlansLoaded(getState())) {
      // load all plans
      await dispatch(loadPlans());
    }

    // find plan to update to
    const plan = find(getPaidPlansByCustomerType(getState(), customerType), { billing_period: paymentFrequency });
    if (plan) {
      // load update estimate for this plan
      return dispatch(loadPlanUpdateEstimate(plan.code));
    }
  };
}
