// @flow
import { Avatar as NeonAvatar, Truncate } from '@getatomi/neon';

import userAccountStatuses from 'src/constants/userAccountStatuses';
import type { UserAccountStatusType } from 'src/types';
import userFullName from 'src/utils/userFullName';
import AvatarWithText from 'src/components/AvatarWithText/AvatarWithText';

// A list of colours that pass AA contrast ratio requirements for the default and muted variants.
const colorMap = [
  ['colorBackgroundAccentSky', 'colorBackgroundAccentSkySubtle'],
  ['colorBackgroundAccentCyan', 'colorBackgroundAccentCyanSubtle'],
  ['colorBackgroundAccentTeal', 'colorBackgroundAccentTealSubtle'],
  ['colorBackgroundAccentMint', 'colorBackgroundAccentMintSubtle'],
  ['colorBackgroundAccentMatcha', 'colorBackgroundAccentMatchaSubtle'],
  ['colorBackgroundAccentYellow', 'colorBackgroundAccentYellowSubtle'],
  ['colorBackgroundAccentTangerine', 'colorBackgroundAccentTangerineSubtle'],
  ['colorBackgroundAccentStrawberry', 'colorBackgroundAccentStrawberrySubtle'],
  ['colorBackgroundAccentPink', 'colorBackgroundAccentPinkSubtle'],
  ['colorBackgroundAccentMagenta', 'colorBackgroundAccentMagentaSubtle'],
  ['colorBackgroundAccentOrchid', 'colorBackgroundAccentOrchidSubtle'],
  ['colorBackgroundAccentPurple', 'colorBackgroundAccentPurpleSubtle'],
];
/**
 * Returns the background color for the avatar based on the user's status and color index.
 */
function getBackgroundColor({
  isMuted,
  isUserInactive,
  colorIndex,
}: {
  colorIndex: number,
  isMuted: boolean,
  isUserInactive: boolean,
}) {
  // use the default neon Avatar colours if the user is inactive
  if (isUserInactive) {
    return undefined;
  }

  const [color, colorMuted] = colorMap[colorIndex];

  return isMuted ? colorMuted : color;
}

export type AvatarSize = $PropertyType<React.ElementProps<typeof NeonAvatar>, 'size'>;

export type User = {
  accountStatus: UserAccountStatusType,
  avatar: ?string,
  color: number,
  deletedAt?: ?string,
  email: string,
  firstName: ?string,
  lastName: ?string,
};

type Props = {
  isDecorative?: boolean,
  size?: AvatarSize,
  textTestHook?: string,
  user: $ReadOnly<User>,
  variant?: 'muted',
  withText?: boolean,
  withTooltip?: boolean,
  withTruncatedFirstName?: boolean,
};

export default function Avatar(props: Props) {
  const {
    user,
    size,
    withText,
    variant,
    withTooltip,
    isDecorative = true,
    withTruncatedFirstName,
    textTestHook,
    ...otherProps
  } = props;
  if (!user) return null;

  const { firstName: originalFirstName, lastName, email, accountStatus } = user;

  const firstName = withTruncatedFirstName && originalFirstName ? `${originalFirstName.charAt(0)}.` : originalFirstName;

  const name = userFullName(firstName, lastName) ?? email;
  const isArchived = Boolean(user.deletedAt);
  const imgSrc = !isArchived ? user.avatar : undefined;
  const backgroundColor = getBackgroundColor({
    colorIndex: +user.color - 1,
    isMuted: variant === 'muted',
    isUserInactive: isArchived || accountStatus !== userAccountStatuses.ACTIVE,
  });

  const avatar = (
    <NeonAvatar
      {...otherProps}
      name={!isArchived ? name : undefined}
      src={imgSrc}
      backgroundColor={backgroundColor}
      size={size}
      variant={variant}
      tooltip={withTooltip ? name : undefined}
      isDecorative={isDecorative}
    />
  );

  return withText ? (
    <AvatarWithText avatar={avatar} text={<Truncate testHook={textTestHook}>{name}</Truncate>} />
  ) : (
    avatar
  );
}
