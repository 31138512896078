// @flow
import _ from 'lodash';
import pluralize from 'pluralize';
import { Card, Heading, Stack, Text } from '@getatomi/neon';

import { successLimits } from 'src/constants/progress';
import Avatar from 'src/components/Avatar/Avatar';
import AvatarGroup from 'src/components/AvatarGroup/AvatarGroup';
import { UnstyledLink } from 'src/components/Link/Link';
import EmptyAvatarGroup from 'src/components/EmptyAvatarGroup/EmptyAvatarGroup';
import type { OverviewStudent } from 'src/domains/ViewingQuizResults/types';

import getHeadingText from './getHeadingText';

type StudentClassificationMetricProps = {
  students: $ReadOnlyArray<OverviewStudent>,
  to: string,
  variant: 'needHelp' | 'goingWell',
};

export default function StudentClassificationMetric(props: StudentClassificationMetricProps) {
  const { students = [], to, variant = 'goingWell' } = props;

  const numOfStudents = students.length;
  const isEmpty = numOfStudents === 0;
  const heading = getHeadingText(variant, successLimits);

  return (
    <Card
      as={UnstyledLink}
      to={to}
      aria-label={heading}
      variant="rounded"
      padding={{ base: 'spacingRoot', tablet: 'spacingLarge' }}
      height="sizeFull"
      testHook={`student-classification-metric-${_.kebabCase(variant)}`}
    >
      <Heading as="h2" variant="small" marginBottom="spacingSmall3X">
        {heading}
      </Heading>
      <Stack>
        <Text variant="bodySmall1X" color="colorTextSubtle">
          {`${numOfStudents} ${pluralize('Student', numOfStudents)}`}
        </Text>
        {isEmpty ? (
          <EmptyAvatarGroup />
        ) : (
          <AvatarGroup>
            {students.map((user, index) => (
              <Avatar key={index} user={user} size="sizeAvatarSmall" withTooltip isDecorative={false} />
            ))}
          </AvatarGroup>
        )}

        <Text color="colorTextLink">View group</Text>
      </Stack>
    </Card>
  );
}
