// @flow
import { Alert } from '@getatomi/neon';
import pluralize from 'pluralize';

import Link from 'src/components/Link/Link';

import type { TaskFilters } from './useClassTaskListFilters';

export type useTaskListAlertsProps = {
  filteredTaskCount: number,
  filters: TaskFilters,
  pathname: string,
};

function useTaskListAlerts(props: useTaskListAlertsProps) {
  const {
    filters: { postId },
    pathname,
    filteredTaskCount,
  } = props;

  const isFiltered = Boolean(postId);

  const alert = isFiltered ? (
    <Alert variant="info" testHook="task-list-alert">
      {!filteredTaskCount
        ? 'There are no tasks'
        : `There ${pluralize('is', filteredTaskCount)} ${pluralize('task', filteredTaskCount, true)}`}{' '}
      containing the selected lesson. To view all tasks{' '}
      <Link to={{ pathname, state: { scrollTop: false } }} variant="monochrome" testHook="clear-filter-button">
        clear this filter
      </Link>
      .
    </Alert>
  ) : undefined;

  return alert;
}

export default useTaskListAlerts;
