// @flow
import { Text, HideVisually } from '@getatomi/neon';

type Props = {
  percentageValue: number,
  type: 'lesson' | 'task',
};

function ProgressCell({ percentageValue, type }: Props) {
  const percentageString = `${percentageValue}%`;

  let backgroundColor;
  if (percentageValue === 100) {
    backgroundColor = 'colorBackgroundSuccess';
  } else if (percentageValue < 80 && type === 'task') {
    backgroundColor = 'colorBackgroundDanger';
  } else {
    backgroundColor = 'colorBackgroundBrandSubtle';
  }

  return (
    <Text
      backgroundColor={backgroundColor}
      borderRadius="borderRadiusLarge1X"
      display="inline-block"
      paddingBlock="spacingSmall2X"
      paddingInline="spacingSmall"
      variant="bodySmall1X"
      minWidth={percentageString}
      testHook="progress-cell"
    >
      {percentageString} <HideVisually>{` of ${type}s completed.`}</HideVisually>
    </Text>
  );
}

export default ProgressCell;
