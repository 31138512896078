// @flow
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { type ContextRouter, withRouter } from 'react-router';
import { Box, Container, EmptyState, Illustration, Text } from '@getatomi/neon';
import keyMirror from 'keymirror';

import MasterLayout from 'src/layouts/shared/MasterLayout/MasterLayout';
import Button from 'src/components/Button/Button';
import Link, { UnstyledLink } from 'src/components/Link/Link';
import { getUser, isUserLoggedIn } from 'src/reducers/auth';
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import links from 'src/constants/links';
import Auth from 'src/utils/Auth';
import { getDashboardUrl, getInviteUrl } from 'src/utils/routes';

export const errorCodes = keyMirror({
  '403': null,
  '404': null,
  '500': null,
});

const errorCodeText = {
  [errorCodes['403']]: {
    heading: 'Access forbidden!',
    description: `We’re sorry but you don’t have permission to access this page.`,
  },
  [errorCodes['404']]: {
    heading: 'Oops, sorry!',
    description: `We can’t seem to find the page you’re looking for.`,
  },
  [errorCodes['500']]: {
    heading: 'Sorry, something went wrong on our end.',
    description: `We’re working hard to get it fixed.`,
  },
};

const mapStateToProps = (state) => {
  const currentSubscriptionId = getActiveSubscriptionId(state);
  const user = getUser(state);

  let isUserSubscription;
  if (user && user.subscriptions) {
    isUserSubscription = user.subscriptions.includes(currentSubscriptionId);
  }

  return {
    isLoggedIn: isUserLoggedIn(state),
    isUserSubscription,
    subscriptionId: currentSubscriptionId,
  };
};

type Props = {
  code?: $Keys<typeof errorCodes>,
  customCta?: $PropertyType<React.ElementProps<typeof EmptyState>, 'customCta'>,
  description?: React.Node,
  eventId?: string,
  routeParams?: {
    errorCode?: string,
  },
  withFeedbackLink?: boolean,
};

type InjectedProps = Props & {
  isLoggedIn: boolean,
  isUserSubscription?: boolean,
  location: {
    query: {
      [string]: string,
    },
  },
  router: ContextRouter,
  subscriptionId: number,
};

function ErrorPage(props: InjectedProps) {
  const {
    code = errorCodes[404],
    customCta,
    eventId,
    isLoggedIn,
    isUserSubscription,
    location: {
      query: { inviteCode },
    },
    routeParams,
    router,
    subscriptionId,
    withFeedbackLink,
  } = props;
  const auth = new Auth();
  const codeFromRoute = routeParams?.errorCode;
  const statusCode = codeFromRoute && Object.keys(errorCodes).includes(codeFromRoute) ? codeFromRoute : code;
  const { heading, description } = errorCodeText[statusCode];
  const isOnDashboard = subscriptionId && router.getCurrentLocation().pathname === getDashboardUrl(subscriptionId);
  // If a 403 happens on the user dashboard, on their subscription, then only show the logout
  // button
  const withLogoutOnly = isOnDashboard && statusCode === errorCodes[403] && isUserSubscription;

  // the user is trying to view a post with a subscription where they have an invited user status
  const inviteCta = inviteCode ? (
    <Box marginBlock="spacingLarge2X">
      <Button as={UnstyledLink} href={getInviteUrl(inviteCode)}>
        Accept invite
      </Button>
    </Box>
  ) : null;

  const onSendFeedback = () => {
    if (window.Sentry) {
      window.Sentry.showReportDialog({ eventId });
    }
  };

  return (
    <MasterLayout removeSpacingBetweenHeaderAndMainContent="all" withFooter={false} withSecondaryBackgroundColor>
      <Helmet>
        <title>{`${statusCode}: ${heading}`}</title>
      </Helmet>
      <Container>
        <EmptyState
          media={<Illustration name="main-browser" />}
          heading={heading}
          headingProps={{ as: 'h1' }}
          description={props.description || description}
          primaryAction={
            <>
              {withLogoutOnly ? <Button onClick={() => auth.logoutAndRedirect()}>Logout</Button> : undefined}
              {inviteCta ?? customCta}
              {!withLogoutOnly && (
                <Text as="p" variant="bodyRoot">
                  <Link href="/">Take me back to the home page</Link>
                  {isLoggedIn && (
                    <>
                      {' '}
                      or{' '}
                      <Button variant="text" size="large" onClick={() => auth.logoutAndRedirect()}>
                        log out
                      </Button>
                    </>
                  )}
                  .
                </Text>
              )}
              {withFeedbackLink && (
                <Box marginTop="spacingLarge4X">
                  <Button onClick={onSendFeedback}>Send us your feedback</Button>
                </Box>
              )}
            </>
          }
          helpInfo={
            <>
              Having trouble?{' '}
              <Link href={links.support.home} isExternal variant="monochrome">
                Find some useful information in our Help Centre
              </Link>
              .
            </>
          }
          testHook="error-page"
        />
      </Container>
    </MasterLayout>
  );
}

export default withRouter(connect(mapStateToProps, {})(ErrorPage));
