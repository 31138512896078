/**
 * Creates a chain of functions that is intended to run asynchronously when a route is about to be
 * rendered. It's the same concept as Redux/Express middleware.
 *
 * Each function in the chain receives three params:
 *   - `routerState`: the router state for the next route
 *     https://github.com/reactjs/react-router/blob/v2.0.0/docs/Glossary.md#routerstate
 *   - `replace`: in case the middleware wants to transition to a different url
 *     https://github.com/reactjs/react-router/blob/v2.0.0/docs/Glossary.md#redirectfunction
 *   - `callback`: the function to call when the middleware decides that the transition should proceed.
 *
 * @see https://github.com/reactjs/react-router/blob/v2.0.0/docs/Glossary.md#enterhook
 */
const createRouterMiddleware =
  (...middlewares) =>
  (store, auth) => {
    const middlewaresWithStore = middlewares.map((middleware) => middleware(store, auth));
    return (nextState, replace, callback) => {
      const chain = middlewaresWithStore.reduceRight(
        (next, middleware) => () => {
          try {
            middleware(nextState, replace, (error) => {
              if (error) {
                // abort the chain early
                callback(error);
              } else {
                // continue successfully
                next();
              }
            });
          } catch (error) {
            // abort the chain
            callback(error);
          }
        },
        () => callback()
      );
      chain();
    };
  };

export default createRouterMiddleware;
