// @flow
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Container, Pagination } from '@getatomi/neon';

import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import { getUsersPagination, getSearchKeywords, getStatusFilter } from 'src/reducers/users';
import { getSubscriptionUsersUrl } from 'src/utils/routes';

type Props = {
  keywords?: string,
  pagination?: Object,
  status?: string,
  subscriptionId: number,
};

const mapStateToProps = (state) => ({
  pagination: getUsersPagination(state),
  subscriptionId: getActiveSubscriptionId(state),
  status: getStatusFilter(state),
  keywords: getSearchKeywords(state),
});

class UsersPagination extends React.Component<Props> {
  loadPage = (page: number) => {
    const { subscriptionId, keywords, status } = this.props;

    browserHistory.push(getSubscriptionUsersUrl({ subscriptionId, page, status, keywords }));
  };

  render() {
    const { pagination } = this.props;

    if (!pagination || pagination.totalPages <= 1) {
      return null;
    }

    return (
      <Container marginTop="spacingLarge1X" paddingInline={{ base: 'spacingRoot', tablet: 'spacingNone' }}>
        <Pagination pageCount={pagination.totalPages} currentPage={pagination.currentPage} gotoPage={this.loadPage} />
      </Container>
    );
  }
}

export default (connect(mapStateToProps)(UsersPagination): React.AbstractComponent<{}>);
