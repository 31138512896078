import { browserHistory } from 'react-router';
import _ from 'lodash';

import { authSchema } from 'src/api/schema';
import { getAuthDetails } from 'src/reducers/auth';
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import { PROXIED_SUBSCRIPTIONS_ENDPOINT, SUBSCRIPTIONS_ENDPOINT, USER_ENDPOINT } from 'src/api/endpoints';
import customerTypes from 'src/constants/authCustomerTypes';
import { trackingEvents } from 'src/constants/tracking';
import { getAnonymousId } from 'src/utils/tracking';
import { getDashboardUrl, getRegistrationWelcomeUrl } from 'src/utils/routes';

export const ACTIVATE_USER = 'registration/ACTIVATE_USER';
export const ACTIVATE_USER_SUCCESS = 'registration/ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAIL = 'registration/ACTIVATE_USER_FAIL';
export const UPDATE_REGISTRATION = 'registration/update';
export const REGISTRATION_START = 'registration/REGISTRATION_START';
export const REGISTRATION_SUCCESS = 'registration/REGISTRATION_SUCCESS';
export const REGISTRATION_FAIL = 'registration/REGISTRATION_FAIL';
export const SAVE_SUBJECTS = 'registration/SAVE_SUBJECTS';
export const SAVE_SUBJECTS_SUCCESS = 'registration/SAVE_SUBJECTS_SUCCESS';
export const SAVE_SUBJECTS_FAIL = 'registration/SAVE_SUBJECTS_FAIL';
export const SAVE_INVITED_USER = 'registration/SAVE_INVITED_USER';
export const SAVE_INVITED_USER_SUCCESS = 'registration/SAVE_INVITED_USER_SUCCESS';
export const SAVE_INVITED_USER_FAIL = 'registration/SAVE_INVITED_USER_FAIL';

export const filterTrackingData = (data) => ({
  ..._.omit(data.user, 'password'),
  ..._.omit(data, ['grant_type', 'password', 'user']),
});

export function updateRegistrationDetails(data) {
  return {
    type: UPDATE_REGISTRATION,
    data,
  };
}

// Update the invited user to a logged in user, using the tokens stored in the redux store and saving them to cookies
export function activateUser(inviteToken, accessToken) {
  return async (dispatch, getState, auth) => {
    await dispatch({
      request: {
        throwErrors: true,
        types: [ACTIVATE_USER, ACTIVATE_USER_SUCCESS, ACTIVATE_USER_FAIL],
        schema: authSchema,
        endpoint: {
          accessToken,
          method: 'post',
          url: `${USER_ENDPOINT}/activate?include=subscriptions`,
          body: { invite_token: inviteToken },
        },
      },
      tracking: {
        event: trackingEvents.userInvitedActivationRequested,
      },
    });

    if (accessToken) auth.saveAccessToken(accessToken);
  };
}

export function register({ region, customerType }) {
  return async (dispatch, getState, auth) => {
    const { registration } = getState();
    const isFamilyCustomer = customerType === customerTypes.family;
    const isSchoolCustomer = customerType === customerTypes.school;
    const isStudentCustomer = customerType === customerTypes.student;

    // account name is only entered by the user when registering as a teacher
    let { accountName } = registration;
    // and set by us for parents and retail students
    if (isFamilyCustomer || isStudentCustomer) {
      accountName = `${registration.firstName} ${registration.lastName}`;
    }

    const body = {
      grant_type: 'application',
      // account name is only entered by the user when registering as a teacher
      name: accountName,
      customer_type: customerType,
      region_code: region,
      ...(isFamilyCustomer
        ? {}
        : // if the school is string then it is a new school (name)
          { [_.isString(registration.schoolId) ? 'school_name' : 'school_id']: registration.schoolId }),
      user: {
        first_name: registration.firstName,
        last_name: registration.lastName,
        email: registration.email,
        password: registration.password,
        registration_intents: registration.intent ?? [],
        segment_identifier: getAnonymousId(),
        // eslint-disable-next-line no-nested-ternary
        ...(isSchoolCustomer
          ? { position: registration.position, phone_number: registration.phoneNumber }
          : isStudentCustomer
          ? { level_id: registration.levelId, referral: registration.referral }
          : {}),
      },
      ...(isFamilyCustomer && {
        students: [
          {
            first_name: registration.childFirstName,
            last_name: registration.childLastName,
            email: registration.childEmail,
            level_id: registration.childLevelId,
          },
        ],
      }),
    };

    const res = await dispatch({
      request: {
        types: [REGISTRATION_START, REGISTRATION_SUCCESS, REGISTRATION_FAIL],
        endpoint: {
          method: 'post',
          sendAccessToken: false,
          url: PROXIED_SUBSCRIPTIONS_ENDPOINT,
          body,
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.userRegistrationRequested,
        data: {
          ...filterTrackingData(body),
          registration_intent: (registration.intent ?? []).join(','),
        },
      },
    });

    auth.saveAccessToken(res.meta.auth.access_token);
    // redirect to the welcome page
    const subscriptionId = getActiveSubscriptionId(getState());
    browserHistory.push(getRegistrationWelcomeUrl(subscriptionId));
  };
}

export function saveFreeRetailStudentSubjects(subjects) {
  return async (dispatch, getState) => {
    const subscriptionId = getActiveSubscriptionId(getState());

    await dispatch({
      data: { subscriptionId },
      request: {
        types: [SAVE_SUBJECTS, SAVE_SUBJECTS_SUCCESS, SAVE_SUBJECTS_FAIL],
        endpoint: {
          method: 'post',
          url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/bulk-add-subjects`,
          body: { subjects },
        },
        throwErrors: true,
      },
      tracking: {
        event: trackingEvents.userSubjectsSaveRequested,
        data: { subjects },
      },
    });
  };
}

export function saveInvitedUser(inviteToken) {
  return async (dispatch, getState, auth) => {
    const state = getState();
    const { registration } = state;
    const { access_token: accessToken } = getAuthDetails(state);
    const body = {
      first_name: registration.firstName,
      last_name: registration.lastName,
      level_id: registration.levelId,
      position: registration.position,
      password: registration.password,
      // if the school is string then it is a new school (name)
      ...(registration.schoolId
        ? { [_.isString(registration.schoolId) ? 'school_name' : 'school_id']: registration.schoolId }
        : {}),
      invite_token: inviteToken,
    };

    await dispatch({
      request: {
        throwErrors: true,
        types: [SAVE_INVITED_USER, SAVE_INVITED_USER_SUCCESS, SAVE_INVITED_USER_FAIL],
        endpoint: {
          accessToken,
          method: 'put',
          url: `${USER_ENDPOINT}`,
          body,
        },
      },
      tracking: {
        event: trackingEvents.userInvitedRegistrationRequested,
        data: filterTrackingData(body),
      },
    });

    // save access token in cookie
    auth.saveAccessToken(accessToken);

    // activate user
    await dispatch(activateUser(inviteToken));

    // redirect to dashboard
    const subscriptionId = getActiveSubscriptionId(getState());
    browserHistory.replace(getDashboardUrl(subscriptionId));
  };
}
