// @flow
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export type SortBy = {
  desc: boolean,
  id: string,
};

const defaults = {
  sortBy: { id: 'rowMeta', desc: false },
};

export const initialTableState = {
  sortBy: [defaults.sortBy],
};

function useSortedStudents<T>(unsortedData: $ReadOnlyArray<T>): {
  setSortBy: (value: Array<SortBy>) => void,
  sortedData: $ReadOnlyArray<T>,
} {
  const [sortBy, setSortBy] = useState<SortBy>(defaults.sortBy);
  const direction = sortBy.desc ? 'desc' : 'asc';

  const sortedData = useMemo(() => {
    const [activeRows, invitedRows] = _.partition(unsortedData, (rowData) =>
      // $FlowIgnore - Flow complains that rowData of type `object` is not compatible with `T`
      Boolean(_.get(rowData, 'rowMeta.lastName'))
    );
    // react-table changes . to _ when setting the column id for sorting, so change it back
    // https://github.com/TanStack/table/blob/main/packages/table-core/src/core/column.ts#L39C1-L39C65
    const sortById = sortBy.id.replace('_', '.');
    const sortByKey = `${sortById}.sortByValue`;
    const [rowsWithValue, rowsWithoutValue] = _.partition(
      activeRows,
      // $FlowIgnore - Flow complains that rowData of type `object` is not compatible with `T`
      (rowData) => _.get(rowData, sortByKey) != null
    );
    const rowMetaDirection = sortBy.id === 'rowMeta' ? direction : 'asc';
    return [
      ..._.orderBy(rowsWithValue, [sortByKey, 'rowMeta.lastName'], [direction]),
      ..._.orderBy(rowsWithoutValue, ['rowMeta.lastName'], ['asc']),
      ..._.orderBy(invitedRows, ['rowMeta.email'], [rowMetaDirection]),
    ];
  }, [unsortedData, sortBy.id, direction]);

  const setSortCallback = useCallback((sortByArray: Array<SortBy>) => {
    const [sortByObj] = sortByArray;

    if (sortByObj?.id == null) {
      return setSortBy(defaults.sortBy);
    }
    return setSortBy(sortByObj);
  }, []);

  return { sortedData, setSortBy: setSortCallback };
}

export default useSortedStudents;
