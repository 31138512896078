// @flow

import { encode, decode } from 'universal-base64';

// Object is treated as `any` but using the type here to document that
// it's an actual object rather than some other data type.
function parseJSON(json: string): Object | null {
  try {
    const value = JSON.parse(json);
    if (typeof value !== 'object') {
      return null;
    }
    return value;
  } catch (error) {
    return null;
  }
}

function getAsNumber(data: Object, key: string): number {
  const value = data[key];
  const valueAsNumber = value ? Number(value) : 0;
  return Number.isNaN(valueAsNumber) ? 0 : valueAsNumber;
}

export type ChallengeResults = {
  missed: number,
  scored: number,
  skipped: number,
  strength: number,
};

export function parseChallengeResults(value: string): ChallengeResults | null {
  const data = parseJSON(decode(value));
  if (!data) {
    return null;
  }

  const scored = getAsNumber(data, 'scored');
  const missed = getAsNumber(data, 'missed');
  const skipped = getAsNumber(data, 'skipped');
  const strength = getAsNumber(data, 'strength');

  return { scored, missed, skipped, strength };
}

export function serializeChallengeResults(challengeResults: ChallengeResults): string {
  return encode(JSON.stringify(challengeResults));
}
