// @flow
import { Box, AvatarGroup } from '@getatomi/neon';

function AvatarSkeleton() {
  return (
    <Box
      backgroundColor="colorBackgroundNeutralBold"
      borderRadius="borderRadiusCircle"
      size="sizeAvatarSmall"
      borderColor="colorBorderInverted"
      borderWidth={2}
      borderStyle="solid"
    />
  );
}

function EmptyAvatarGroup() {
  return (
    <AvatarGroup>
      <AvatarSkeleton />
      <AvatarSkeleton />
      <AvatarSkeleton />
    </AvatarGroup>
  );
}

export default EmptyAvatarGroup;
