// @flow
import type { FoundContent } from '../../useFindContentDialog';
import type { Output as UseBreadcrumbsOutput, SelectorScreen } from '../../hooks/useBreadcrumbs';
import SelectYear from '../SelectYear/SelectYear';
import SelectSubject from '../SelectSubject/SelectSubject';
import SelectModule from '../SelectModule/SelectModule';
import SelectLessons from '../SelectLessons/SelectLessons';

type Props = {|
  addBreadcrumb: $PropertyType<UseBreadcrumbsOutput, 'addBreadcrumb'>,
  classId: string,
  context: 'subject' | 'task',
  isStudent: boolean,
  onAssignContent: ($ReadOnlyArray<FoundContent>) => void,
  selector: SelectorScreen,
  showUpgradeModal: () => void,
|};

export default function ContentCatalog(props: Props) {
  const { addBreadcrumb, classId, context, isStudent, onAssignContent, selector, showUpgradeModal } = props;

  switch (selector.screen) {
    case 'select-year': {
      return (
        <SelectYear
          addBreadcrumb={addBreadcrumb}
          queryVariables={selector.queryVariables}
          showUpgradeModal={showUpgradeModal}
        />
      );
    }
    case 'select-subject': {
      return (
        <SelectSubject
          addBreadcrumb={addBreadcrumb}
          queryVariables={selector.queryVariables}
          showUpgradeModal={showUpgradeModal}
        />
      );
    }
    case 'select-module': {
      return <SelectModule addBreadcrumb={addBreadcrumb} queryVariables={selector.queryVariables} />;
    }
    case 'select-lessons': {
      return (
        <SelectLessons
          queryVariables={selector.queryVariables}
          classId={classId}
          context={context}
          isStudent={isStudent}
          onAssignContent={onAssignContent}
          showUpgradeModal={showUpgradeModal}
        />
      );
    }
    default: {
      throw new Error(`Unknown selector screen: ${selector.screen}`);
    }
  }
}
