// @flow
import { useMemo, useState } from 'react';
import { useMediaQuery } from '@getatomi/neon';

import useSortedStudents from 'src/hooks/useSortedStudents';

import type { ClassAverageRow, TasksData, TransformedTasksData, Column as TaskColumn } from './tasksTransformer';
import type { TableColumn } from './prepareTasksColumns';
import MarkbookTable from '../components/MarkbookTable';
import MarkbookControls from '../components/MarkbookControls';
import prepareTasksColumns from './prepareTasksColumns';

type TasksViewProps = {
  expandedColumnState: $Call<typeof useState>,
  params: { classId: string, subscriptionId: string },
  preparedData: TransformedTasksData,
};

export default function TasksView(props: TasksViewProps) {
  const { preparedData, params, expandedColumnState } = props;
  const { classAverageRow, tasksData, className, columnList } = preparedData;
  const { classId, subscriptionId } = params;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointSmall' });

  const { sortedData: sortedTasksData, setSortBy } = useSortedStudents(tasksData);

  const tasksColumns = useMemo(
    (): $ReadOnlyArray<TableColumn> =>
      prepareTasksColumns(isMobile, columnList, expandedColumnState, subscriptionId, classId),
    [isMobile, columnList, expandedColumnState, subscriptionId, classId]
  );

  const rowData = useMemo(
    (): $ReadOnlyArray<ClassAverageRow | TasksData> => [classAverageRow, ...sortedTasksData],
    [classAverageRow, sortedTasksData]
  );

  const downloadColumns = ([
    {
      assignedCount: 1,
      id: 'overall',
      name: 'Overall completion',
    },
    {
      assignedCount: 1,
      id: 'total',
      name: 'Total tasks assigned',
    },
    ...columnList,
  ]: $ReadOnlyArray<TaskColumn>);

  return (
    <>
      <MarkbookControls
        activeLink="tasks"
        className={className}
        params={params}
        columnList={downloadColumns}
        tableData={rowData}
      />
      <MarkbookTable
        isMobile={false}
        caption="The list of students and their task completion data."
        columns={tasksColumns}
        data={rowData}
        isSortable
        toggleSortBy={setSortBy}
        subscriptionId={subscriptionId}
        classId={classId}
      />
    </>
  );
}
