// @flow
import React from 'react';
import _ from 'lodash';
import { Accordion, Badge, Checkbox, Flex, Label } from '@getatomi/neon';

import type { Module } from './types';
import { getChallenges, getQuestionsAvailable } from './utils';

export function Topic({
  labels,
  isIndeterminate,
  isSelected,
  isDisabled,
  onToggle,
  children,
}: {
  children: React$Node,
  isDisabled?: boolean,
  isIndeterminate: boolean,
  isSelected: boolean,
  labels: Array<string>,
  onToggle: (value: boolean) => mixed,
}) {
  return (
    <Flex as="label" alignItems="center" gap="spacingSmall">
      <Checkbox
        as="div"
        isSelected={isSelected}
        isIndeterminate={isIndeterminate}
        isDisabled={isDisabled}
        onChange={onToggle}
        ariaLabel={children}
      />
      {children}
      {labels &&
        labels.map((label) => (
          <Badge key={label} variant="chunky">
            {label}
          </Badge>
        ))}
    </Flex>
  );
}

export default function SelectTopics({
  subjectName,
  subjectTree,
  selectedTopics,
  onChange,
}: {
  onChange: (value: Array<string>) => mixed,
  selectedTopics: Array<string>,
  subjectName: string,
  subjectTree: $ReadOnlyArray<Module>,
}) {
  const renderContent = (category) => {
    const isModule = category.hasOwnProperty('topics');
    let isSelected = false;
    let isIndeterminate = false;
    const questionCount = getQuestionsAvailable(
      // $FlowIgnore flow fails to refine the types based on isModule
      getChallenges({ topics: isModule ? _.flatten(category.topics) : [category] })
    ).length;
    if (isModule) {
      isSelected =
        // $FlowIgnore flow fails to refine the types based on isModule
        category.topics.every((topic) => selectedTopics.includes(topic.id));
      isIndeterminate =
        !isSelected &&
        // $FlowIgnore flow fails to refine the types based on isModule
        category.topics.some((topic) => selectedTopics.includes(topic.id));
    } else {
      isSelected = selectedTopics.includes(category.id);
    }
    return (
      <Accordion.Content
        key={category.id}
        info={`${questionCount} questions available`}
        testHook={`accordion-content-${category.id}`}
      >
        <Topic
          labels={_.map(category.labels, 'name')}
          isSelected={isSelected}
          isIndeterminate={isIndeterminate}
          isDisabled={questionCount === 0}
          onToggle={(isTogglingOn: boolean) => {
            const newSelectedPosts = new Set(selectedTopics);
            if (isModule) {
              // $FlowIgnore flow fails to refine the types based on isModule
              category.topics.forEach((topic) =>
                isTogglingOn ? newSelectedPosts.add(topic.id) : newSelectedPosts.delete(topic.id)
              );
            } else {
              isTogglingOn ? newSelectedPosts.add(category.id) : newSelectedPosts.delete(category.id);
            }
            onChange([...newSelectedPosts]);
          }}
          testHook={`topic-${category.id}`}
        >
          {category.name}
        </Topic>
      </Accordion.Content>
    );
  };

  return (
    <fieldset>
      <Label as="legend">Select topics for {subjectName}</Label>
      <Accordion>
        {subjectTree.map((module) => (
          <Accordion.Item
            key={module.id}
            assistiveText={`Expand topic "${module.name}"`}
            headingLevel="3"
            content={renderContent(module)}
            testHook={`accordion-item-${module.id}`}
          >
            {module.topics.map((topic) => renderContent(topic))}
          </Accordion.Item>
        ))}
      </Accordion>
    </fieldset>
  );
}
