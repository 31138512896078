// @flow
/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */
/* eslint-disable max-classes-per-file */
import moment from 'moment';

import type { DateString } from 'src/types';

export interface DateRange {
  /* eslint-disable flowtype/sort-keys */
  description: string;
  startDate: DateString | moment;
  endDate: DateString | moment;
  comparisonStartDate: DateString | moment;
  comparisonEndDate: DateString | moment;
  /**
   * Date format used by BigQuery to group by and sum rows in the database
   */
  keyFormat: string;
  /**
   * Date format used by Moment and must match `keyFormat`
   */
  keyFormatMoment: string;
  /**
   * Returns the steps to be used for the X axis. Used in conjunction with `formatKey` to render
   * labels on the X axis.
   */
  keysInRange(): Generator<DateString, void, void>;
  /**
   * Formats keys to be displayed on the X axis
   */
  formatKey(key: DateString): string;
  /**
   * Function used to group "comparison" and "current" keys
   */
  keyBy(key: DateString): string;
  /**
   * Determines if a key is in the "comparison" range. Else it's in the "current" range.
   */
  isComparison(key: DateString): boolean;
  /**
   * Determines if a key should be null (not plotted on the graph)
   */
  isNull(key: DateString | moment): boolean;
  /**
   * Determines if the corresponding "comparison" key of the input ("current") key should be null
   * (not plotted on the graph)
   */
  isNullComparison(key: DateString): boolean;
  /* eslint-enable flowtype/sort-keys */
}

function lastDays(numDays) {
  const endDate = moment().subtract(1, 'day');
  // the start and end date are included in the interval -- we'll exclude the end date
  const startDate = moment(endDate).subtract(numDays - 1, 'days');
  return {
    description: `from previous ${numDays} days`,
    startDate,
    endDate,
    comparisonStartDate: moment(startDate).subtract(numDays, 'days'),
    comparisonEndDate: moment(endDate).subtract(numDays, 'days'),
    isNullComparison(key: DateString) {
      return this.isNull(moment(key).subtract(numDays, 'days'));
    },
  };
}

function ordinalKeyBy() {
  return {
    keyBy(key: DateString) {
      const rangeStartDate = this.isComparison(key) ? this.comparisonStartDate : this.startDate;
      const daysInYear = moment(rangeStartDate).endOf('year').dayOfYear();
      // when the start date is close to the beginning of the year the difference is negative
      return this._padKey((moment(key).dayOfYear() - moment(rangeStartDate).dayOfYear() + daysInYear) % daysInYear);
    },
  };
}

class BaseDateRange implements DateRange {
  /*::
  // use Flow comment syntax so the methods aren't overwritten
  subscriptionStartDate: DateString | moment;
  description: $PropertyType<DateRange, 'description'>;
  startDate: $PropertyType<DateRange, 'startDate'>;
  endDate: $PropertyType<DateRange, 'endDate'>;
  comparisonStartDate: $PropertyType<DateRange, 'comparisonStartDate'>;
  comparisonEndDate: $PropertyType<DateRange, 'comparisonEndDate'>;
  keyFormat: $PropertyType<DateRange, 'keyFormat'>;
  keyFormatMoment: $PropertyType<DateRange, 'keyFormatMoment'>;
  +keysInRange: $PropertyType<DateRange, 'keysInRange'>;
  +formatKey: $PropertyType<DateRange, 'formatKey'>;
  +keyBy: $PropertyType<DateRange, 'keyBy'>;
  +isNullComparison: $PropertyType<DateRange, 'isNullComparison'>;
  */

  constructor(subscriptionStartDate: DateString) {
    this.subscriptionStartDate = subscriptionStartDate;
  }

  isComparison(key: DateString) {
    const startDateKey = moment(this.startDate).format(this.keyFormatMoment);
    return moment(key).isBefore(startDateKey);
  }

  isNull(key: DateString | moment) {
    const subscriptionStartDateKey = moment(this.subscriptionStartDate).format(this.keyFormatMoment);
    return moment(key).isBefore(subscriptionStartDateKey);
  }

  _padKey(key: string | number) {
    return String(key).padStart(2, '0');
  }
}

export class YearToDate extends BaseDateRange {
  description = 'from previous year to date';
  startDate = moment().startOf('year');
  endDate = moment().startOf('day');
  comparisonStartDate = moment(this.startDate).subtract(1, 'year');
  comparisonEndDate = moment(this.endDate).subtract(1, 'year');
  keyFormat = '%Y-%m';
  keyFormatMoment = 'YYYY-MM';

  *keysInRange(): $Call<$PropertyType<DateRange, 'keysInRange'>> {
    const cursor = moment(this.startDate);
    while (cursor.isSameOrBefore(this.endDate)) {
      const value = cursor.format(this.keyFormatMoment);
      cursor.add(1, 'month');
      yield value;
    }
  }

  keyBy(key: DateString) {
    return this._padKey(moment(key).month());
  }

  formatKey(key: DateString) {
    return moment(key).format('MMM YY');
  }

  isNullComparison(key: DateString) {
    return this.isNull(moment(key).subtract(1, 'year'));
  }
}

export class LastYear extends YearToDate {
  description = 'from previous year';
  startDate = moment().subtract(1, 'year').startOf('year');
  endDate = moment(this.startDate).endOf('year');
  comparisonStartDate = moment(this.startDate).subtract(1, 'year');
  comparisonEndDate = moment(this.comparisonStartDate).endOf('year');
}

export class QuarterToDate extends BaseDateRange {
  description = 'from previous quarter to date';
  startDate = moment().startOf('quarter');
  endDate = moment().subtract(1, 'day');
  comparisonStartDate = moment(this.startDate).subtract(1, 'quarter');
  comparisonEndDate = moment(this.endDate).subtract(1, 'quarter');
  // https://en.wikipedia.org/wiki/ISO_week_date
  keyFormat = '%G-W%V';
  // when working with weeks use GGGG instead of YYYY
  // https://github.com/moment/moment/issues/4938#issuecomment-453134762
  keyFormatMoment = 'GGGG-[W]WW';

  *keysInRange(): $Call<$PropertyType<DateRange, 'keysInRange'>> {
    const cursor = moment(this.startDate).startOf('isoWeek');
    while (cursor.isSameOrBefore(this.endDate, 'isoWeek')) {
      const value = cursor.format(this.keyFormatMoment);
      cursor.add(1, 'week');
      yield value;
    }
  }

  keyBy(key: DateString) {
    const startDate = this.isComparison(key) ? this.comparisonStartDate : this.startDate;
    const totalWeeks = moment(startDate).isoWeeksInYear();
    // if startDate is 1 January and falls on Friday..Sunday that counts as week 53
    // and the difference ends up being negative
    // https://en.wikipedia.org/wiki/ISO_week_date#First_week
    return this._padKey((moment(key).isoWeek() - moment(startDate).isoWeek() + totalWeeks) % totalWeeks);
  }

  formatKey(key: DateString) {
    return moment(key).format('D MMM');
  }

  isNullComparison(key: DateString) {
    return this.isNull(moment(key).subtract(1, 'quarter'));
  }
}

export class LastQuarter extends QuarterToDate {
  description = 'from previous quarter';
  startDate = moment().subtract(1, 'quarter').startOf('quarter');
  endDate = moment(this.startDate).endOf('quarter');
  comparisonStartDate = moment(this.startDate).subtract(1, 'quarter');
  comparisonEndDate = moment(this.comparisonStartDate).endOf('quarter');
}

export class Last90Days extends QuarterToDate {
  /*::
  // make these writable so we can use mixins
  isNullComparison: $PropertyType<DateRange, 'isNullComparison'>;
  */

  constructor(subscriptionStartDate: DateString) {
    super(subscriptionStartDate);
    Object.assign(this, lastDays(90));
  }
}

export class MonthToDate extends BaseDateRange {
  description = 'from previous month to date';
  startDate = moment().startOf('month');
  endDate = moment().isSame(this.startDate, 'day') ? moment() : moment().subtract(1, 'day');
  comparisonStartDate = moment(this.startDate).subtract(1, 'month');
  comparisonEndDate = moment(this.endDate).subtract(1, 'month');
  // https://en.wikipedia.org/wiki/Ordinal_date
  keyFormat = '%Y-%j';
  keyFormatMoment = 'YYYY-DDDD';

  *keysInRange(): $Call<$PropertyType<DateRange, 'keysInRange'>> {
    const cursor = moment(this.startDate);
    while (cursor.isSameOrBefore(this.endDate)) {
      const value = cursor.format(this.keyFormatMoment);
      cursor.add(1, 'day');
      yield value;
    }
  }

  keyBy(key: DateString) {
    // ensure they key starts at 0
    return this._padKey(moment(key).date() - 1);
  }

  formatKey(key: DateString) {
    return moment(key).format('D MMM');
  }

  isNullComparison(key: DateString) {
    return this.isNull(moment(key).subtract(1, 'month'));
  }
}

export class LastMonth extends MonthToDate {
  description = 'from previous month';
  startDate = moment().subtract(1, 'month').startOf('month');
  endDate = moment(this.startDate).endOf('month');
  comparisonStartDate = moment(this.startDate).subtract(1, 'month');
  comparisonEndDate = moment(this.comparisonStartDate).endOf('month');
}

export class Last30Days extends MonthToDate {
  /*::
  // make these writable so we can use mixins
  keyBy: $PropertyType<DateRange, 'keyBy'>;
  isNullComparison: $PropertyType<DateRange, 'isNullComparison'>;
  */

  constructor(subscriptionStartDate: DateString) {
    super(subscriptionStartDate);
    Object.assign(this, lastDays(30), ordinalKeyBy());
  }
}

export class WeekToDate extends BaseDateRange {
  description = 'from previous week to date';
  startDate = moment().startOf('isoWeek');
  endDate = (moment().isSame(this.startDate, 'day') ? moment() : moment().subtract(1, 'day')).startOf('day');
  comparisonStartDate = moment(this.startDate).subtract(1, 'week');
  comparisonEndDate = moment(this.endDate).subtract(1, 'week');
  // https://en.wikipedia.org/wiki/Ordinal_date
  keyFormat = '%Y-%j';
  keyFormatMoment = 'YYYY-DDDD';

  *keysInRange(): $Call<$PropertyType<DateRange, 'keysInRange'>> {
    const cursor = moment(this.startDate);
    while (cursor.isSameOrBefore(this.endDate)) {
      const value = cursor.format(this.keyFormatMoment);
      cursor.add(1, 'day');
      yield value;
    }
  }

  keyBy(key: DateString) {
    // ensure they key starts at 0
    return this._padKey(moment(key).isoWeekday() - 1);
  }

  formatKey(key: DateString) {
    return moment(key).format('D MMM');
  }

  isNullComparison(key: DateString) {
    return this.isNull(moment(key).subtract(1, 'week'));
  }
}

export class LastWeek extends WeekToDate {
  description = 'from previous week';
  startDate = moment().subtract(1, 'week').startOf('isoWeek');
  endDate = moment(this.startDate).endOf('isoWeek');
  comparisonStartDate = moment(this.startDate).subtract(1, 'week');
  comparisonEndDate = moment(this.comparisonStartDate).endOf('week');
}

export class Last7Days extends WeekToDate {
  /*::
  // make these writable so we can use mixins
  keyBy: $PropertyType<DateRange, 'keyBy'>;
  isNullComparison: $PropertyType<DateRange, 'isNullComparison'>;
  */

  constructor(subscriptionStartDate: DateString) {
    super(subscriptionStartDate);
    Object.assign(this, lastDays(7), ordinalKeyBy());
  }
}
