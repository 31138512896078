// @flow
import { DropdownLoader, KebabList } from '@getatomi/neon';
import _ from 'lodash';

import { ButtonAddLoader } from 'src/components/ButtonAdd/ButtonAdd';
import FilterGroup from 'src/components/FilterGroup/FilterGroup';

type Props = {
  numFilters: number,
  withAddTaskCta?: boolean,
};
function TaskListLoader(props: Props) {
  return (
    <KebabList
      filters={
        <FilterGroup>
          {_.times(props.numFilters, (i) => (
            <DropdownLoader key={i} variant="filter" />
          ))}
        </FilterGroup>
      }
      cta={props.withAddTaskCta ? <ButtonAddLoader /> : undefined}
    >
      <KebabList.Loader withTitle count={3} height={120} testHook="task-list-loader" />
    </KebabList>
  );
}

export default TaskListLoader;
