// @flow
import type { GetLessonProgress_me_account_class as ClassData } from 'src/graphql/types/generated/GetLessonProgress';

export type StudentWithProgress = {
  cumulativeCompletionCount: number,
  firstName: string,
  id: string,
  lastCompletedAt: ?string,
  lastName: string,
};

export type ClassPostProgressData = {
  students: Array<StudentWithProgress>,
};

export default function transformLessonProgress(data: ?ClassData): ClassPostProgressData {
  if (data) {
    const { students, lesson } = data;
    const studentsWithProgress = students.map((student) => {
      // $FlowIgnore metrics is defined for text and video lessons
      const studentProgress = lesson.metrics.progress.students.find(
        (progressRecord) => progressRecord.studentId === student.id
      );
      return {
        ...student,
        lastCompletedAt: studentProgress?.lastCompletedAt,
        cumulativeCompletionCount: studentProgress?.cumulativeCompletionCount,
      };
    });

    return {
      // $FlowIgnore firstName and lastName will be defined for active students
      students: studentsWithProgress,
    };
  }
  // Loading state
  return {
    students: [],
  };
}
