// @flow
import { Divider, Flex, OptionGroupLoader, Skeleton, Stack, TextLoader } from '@getatomi/neon';

export default function RevisionSelectorsLoader() {
  return (
    <Stack spacing="spacingLarge5X" testHook="revision-dialog-loading">
      <Stack>
        <TextLoader width="7ch" />
        <OptionGroupLoader count={3} />
      </Stack>
      <Divider />
      <Stack>
        <TextLoader width="13ch" />
        <OptionGroupLoader count={3} />
      </Stack>
      <Divider />
      <Stack>
        <TextLoader width="13ch" />
        <OptionGroupLoader count={3} variant="square" />
      </Stack>
    </Stack>
  );
}

export function RevisionDialogSidebarLoader() {
  return (
    <Stack spacing="spacingLarge4X">
      {Array.from({ length: 2 }).map((_val, index) => (
        <Flex key={index} alignItems="center" gap="spacingRoot" paddingLeft="spacingLarge3X">
          <Skeleton animation="wave" width={20} height={20} borderRadius="borderRadiusCircle" />
          <Stack>
            <TextLoader width={50} height={14} />
            <TextLoader width={100} height={14} />
          </Stack>
        </Flex>
      ))}
    </Stack>
  );
}
