// @flow
import type { UserType } from 'src/types';
import userRoles from 'src/constants/userRoles';
import userAccountStatuses from 'src/constants/userAccountStatuses';

type Props = {|
  canSeeEmailError: boolean,
  canSeeNoClassesWarning: boolean,
  currentAuthenticatedUserId: number,
  isDisabled: boolean,
  user: UserType,
|};

export type SubscriptionUserRowOutput = {
  ...UserType,
  currentAuthenticatedUserLabel: string | null,
  emailFailureReason: string | null,
  isActive: boolean,
  isArchived: boolean,
  isDisabled: boolean,
  shouldDisplayNoClassesTooltip: boolean,
};

export default function prepareUserForRow(props: Props): SubscriptionUserRowOutput {
  const { user, canSeeEmailError, canSeeNoClassesWarning, isDisabled, currentAuthenticatedUserId } = props;

  let emailFailureReason = null;
  const isActive = user.subscription_status === userAccountStatuses.legacy.active;
  const isArchived = user.subscription_status === userAccountStatuses.legacy.archived;
  const currentAuthenticatedUserLabel = currentAuthenticatedUserId === user.id ? '(Me)' : null;

  if (user.email_failure_at) {
    emailFailureReason =
      canSeeEmailError && user.email_failure_reason
        ? `Emails sent to this user have failed permanently. ${user.email_failure_reason}`
        : 'Emails sent to this user have failed permanently. This email address may be incorrect or no longer valid.';
  }

  const shouldDisplayNoClassesTooltip =
    canSeeNoClassesWarning && !isArchived && user.role === userRoles.student && !user.class_count;

  return {
    ...user,
    isDisabled,
    isActive,
    isArchived,
    currentAuthenticatedUserLabel,
    emailFailureReason,
    shouldDisplayNoClassesTooltip,
  };
}
