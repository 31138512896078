// @flow
import { Card, Flex, Text, Stack } from '@getatomi/neon';

import draftColorRange from 'src/domains/Tasks/draftColorRange';
import getStudentCountText from 'src/utils/getStudentCountText';
import Avatar from 'src/components/Avatar/Avatar';
import Date from 'src/components/Date/Date';
import TaskDateTime from 'src/components/TaskDateTime/TaskDateTime';
import TaskCardHeader from 'src/domains/Tasks/components/TaskCardHeader/TaskCardHeader';
import { type SubjectColors } from 'src/utils/getSubjectColors';

import type { ClassTask, CurrentClass } from './useGetClassTask';

type ClassTaskCardProps = {|
  currentClass: CurrentClass,
  isDraft: boolean,
  isScheduled: boolean,
  subjectColorRange: SubjectColors,
  taskData: ClassTask,
|};
function ClassTaskCard(props: ClassTaskCardProps) {
  const { currentClass, isDraft, isScheduled, subjectColorRange, taskData } = props;

  const taskCardColors = isDraft ? draftColorRange : subjectColorRange;

  let taskCardLabel;
  if (isScheduled && taskData.scheduledFor) {
    taskCardLabel = <TaskDateTime prefix="Scheduled for" value={taskData.scheduledFor} />;
  } else if (isDraft) {
    taskCardLabel = 'Draft';
  }

  const taskCardMetaLabel =
    taskData.updatedAt && taskData.updatedByFullName ? (
      <>
        Last updated <Date format="DD MMM, YYYY [at] h:mm a" value={taskData.updatedAt} testHook="hide-in-percy" /> by{' '}
        {taskData.updatedByFullName}
      </>
    ) : (
      <>
        Created <Date format="DD MMM, YYYY [at] h:mm a" value={taskData.createdAt} testHook="hide-in-percy" />
      </>
    );

  return (
    <Card as="article" padding="spacingRoot" variant="elevated" withHover={false} testHook="task-list-card">
      <Stack spacing="spacingLarge">
        <Flex
          alignItems="center"
          gap="spacingSmall"
          maxWidth="sizeFull"
          minWidth="sizeNone"
          testHook="taskCard-meta-wrapper"
        >
          <Avatar isDecorative={false} user={taskData.author} withTooltip />
          <Text color="colorTextSubtle" variant="bodySmall1X">
            {taskCardMetaLabel}
          </Text>
        </Flex>

        <TaskCardHeader
          assignedTo={getStudentCountText({
            count: taskData.countStudents,
            total: currentClass.studentCount,
            totalText: 'All students',
          })}
          dueDate={taskData.dueDate}
          label={
            taskCardLabel
              ? {
                  color: taskCardColors.background,
                  text: taskCardLabel,
                }
              : undefined
          }
        />
      </Stack>
    </Card>
  );
}

export default ClassTaskCard;
