// @flow
import { Text } from '@getatomi/neon';

import Price from 'src/components/Price/Price';
import type { CurrencyType } from 'src/types';

import styles from './BillingEstimateRow.module.scss';

type Props = {
  amount: number,
  currency: CurrencyType,
  description?: React.Node,
  heading: React.Node,
  isDiscount?: boolean,
  withCurrency?: boolean,
};

export default function BillingEstimateRow(props: Props) {
  const { amount, currency, description, heading, isDiscount, withCurrency } = props;

  return (
    <tr className={styles.root}>
      <td>
        {heading}
        {description && (
          <Text
            color="colorTextSubtler"
            fontWeight="fontWeightRegular"
            lineHeight="lineHeightLarge"
            variant="bodySmall1X"
          >
            {description}
          </Text>
        )}
      </td>
      <td className={styles.amount}>
        {isDiscount && '('}
        <Price currency={currency} value={amount} />
        {isDiscount && ')'}{' '}
        {withCurrency && (
          <span className={styles.currency} aria-hidden="true">
            {currency}
          </span>
        )}
      </td>
    </tr>
  );
}
