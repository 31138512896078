// @flow
import { TokenRefreshLink } from 'apollo-link-token-refresh';

import { HttpError } from 'src/utils/httpClient';
import Logger from 'src/utils/Logger';
import Auth from 'src/utils/Auth';

const log = new Logger('src/graphql/client');

export const makeRefreshAuthTokenLink = (auth: Auth) =>
  new TokenRefreshLink({
    isTokenValidOrUndefined: () => {
      const accessToken = auth.getAccessToken();

      if (!accessToken) {
        log.warn('[TokenRefreshLink]: No access token found');
        return false;
      }
      return !auth.isTokenExpired(accessToken);
    },
    fetchAccessToken: () => {
      log.info('[TokenRefreshLink]: Fetching new access token');
      return auth.getFreshAccessToken();
    },
    handleResponse: () => async (accessToken) => {
      return { access_token: accessToken };
    },
    handleError: (error) => {
      if (error instanceof HttpError && error.status === 401) {
        log.warn('[TokenRefreshLink]: 401 error, logging out and redirecting');
        return auth.logoutAndRedirect(window.location.pathname);
      }
      log.error(`[GraphQL error]: Message: ${error.message}`, error);

      throw error;
    },
  });
