// @flow
import Avatar from 'src/components/Avatar/Avatar';
import type { CellValue } from 'src/utils/createCellValue';

import type { Student } from './classInsightsTransformer';

export type AverageMarkCell = CellValue<?string, ?number>;
export type LessonsCompletedCell = CellValue<number, number>;
export type QuizzesCompletedCell = CellValue<number, number>;

type Cell = {
  // eslint-disable-next-line react/no-unused-prop-types
  getValue: () => AverageMarkCell | LessonsCompletedCell | QuizzesCompletedCell,
  row: {
    original: { rowMeta: Student },
  },
};

type ColumnMeta = {|
  isCenterAligned?: boolean,
  isHeading?: boolean,
|};

export type Column = {
  accessorKey: string,
  cell: (cell: Cell) => ?React.Node,
  header: () => React.Node,
  meta?: ColumnMeta,
  sortDescFirst?: boolean,
};

export default function prepareStudentTableColumns(isMobile: boolean = false): $ReadOnlyArray<Column> {
  return [
    {
      header: () => 'Students',
      accessorKey: 'rowMeta',
      cell: ({ row }: Cell) => {
        return <Avatar user={row.original.rowMeta} withText withTruncatedFirstName={isMobile} />;
      },
      sortDescFirst: false,
      meta: {
        isHeading: true,
      },
    },
    {
      header: () => 'Lessons completed',
      accessorKey: 'lessonsCompleted',
      cell: ({ getValue }: Cell) => getValue().displayValue || '',
    },
    {
      header: () => 'Quizzes completed',
      accessorKey: 'quizzesCompleted',
      cell: ({ getValue }: Cell) => getValue().displayValue || '',
    },
    {
      header: () => 'Mark',
      accessorKey: 'averageMarkPercentage',
      cell: ({ getValue }: Cell) => getValue().displayValue ?? '',
    },
  ];
}
