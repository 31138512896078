// @flow
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { type ContextRouter, withRouter } from 'react-router';
import { Container, EmptyState, Illustration } from '@getatomi/neon';

import type { BoundAction } from 'src/types';
import MasterLayout from 'src/layouts/shared/MasterLayout/MasterLayout';
import Link from 'src/components/Link/Link';
import { logout } from 'src/actions/auth';
import Auth from 'src/utils/Auth';
import { getLoginUrlWithRedirect } from 'src/utils/routes';
import links from 'src/constants/links';

type Props = {
  router: ContextRouter,
};

type InjectedProps = Props & {
  logoutAction: BoundAction<typeof logout>,
};

type State = {
  isSimultaneousLogin: boolean,
};

class Unauthorised extends React.Component<InjectedProps, State> {
  state = {
    isSimultaneousLogin: false,
  };

  componentDidMount() {
    const auth = new Auth();
    const token = auth.getAccessToken();

    // we can't dispatch the logout action because it needs the token for the API call and we don't
    // have it: remove cookies and redirect to the login page.
    if (!token) return auth.logout(true);

    // if token has expired then redirect to login page
    const redirect = auth.isTokenExpired();

    const redirectUrl = getLoginUrlWithRedirect(this.props.router.location);

    this.props.logoutAction(redirect, redirectUrl);

    if (!redirect) {
      this.setState({ isSimultaneousLogin: true });
    }
  }

  render() {
    const { isSimultaneousLogin } = this.state;

    if (!isSimultaneousLogin) return null;

    const headingText = 'Simultaneous Login';

    return (
      <MasterLayout removeSpacingBetweenHeaderAndMainContent="all" withFooter={false} withSecondaryBackgroundColor>
        <Helmet>
          <title>{headingText}</title>
        </Helmet>
        <Container>
          <EmptyState
            media={<Illustration name="main-browser" />}
            heading="Simultaneous Login"
            headingProps={{ as: 'h1' }}
            description={
              <>
                It looks like you’ve logged onto Atomi on another device so to keep your account secure we’ve logged you
                out automatically. If this isn’t the case or you continue to see this message, we’d recommend you change
                your password.
              </>
            }
            primaryAction={<Link href={getLoginUrlWithRedirect(this.props.router.location)}>Log back in</Link>}
            helpInfo={
              <>
                Having trouble?{' '}
                <Link href={links.support.home} isExternal variant="monochrome">
                  Find some useful information in our Help Centre
                </Link>
                .
              </>
            }
          />
        </Container>
      </MasterLayout>
    );
  }
}

export default withRouter(connect(null, { logoutAction: logout })(Unauthorised));
