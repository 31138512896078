// @flow
import { ApolloClient, InMemoryCache } from '@apollo/client';

import possibleTypes from '../generated/possibleTypes';
import apolloTypePolicies from '../apolloTypePolicies';
import { createLinks } from './createLinks';

// TODO: This is causing an intermittent "Cannot convert object to primitive value" error
// when viewing challenges, so commenting out for now. See PROD-7031.
//
// import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
//
// if (__DEVELOPMENT__) {
//   loadDevMessages();
//   loadErrorMessages();
// }

export const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: apolloTypePolicies(),
});

const client = new ApolloClient<any>({
  name: '@app/carbon',
  version: process.env.RUNTIME_APPLICATION_VERSION,
  link: createLinks({ uri: process.env.GATEWAY_GRAPHQL_URI }),
  cache,
  assumeImmutableResults: true,
  connectToDevTools: process.env.ENV_NAME === 'local',
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default client;
