// @flow
import { connect } from 'react-redux';

import type { ReduxState } from 'src/types';
import { NavTabs, NavTabsLink } from 'src/components/NavTabs/NavTabs';
import { isLoggedInAsSuperAdmin } from 'src/reducers/auth';
import {
  canManageBilling as canManageBillingSelector,
  getActiveSubscriptionId,
  isLoggedInAsParent,
  isSchoolPlan as isSchoolPlanSelector,
  isAdminOrAbove as isAdminOrAboveSelector,
  isRetailPlan as isRetailPlanSelector,
} from 'src/reducers/subscriptions';
import { getSettingsUrl } from 'src/utils/routes';

const mapStateToProps = (state: ReduxState) => {
  return {
    canManageBilling: canManageBillingSelector(state),
    isAdminOrAbove: isAdminOrAboveSelector(state),
    isParent: isLoggedInAsParent(state),
    isRetailPlan: isRetailPlanSelector(state),
    isSchoolPlan: isSchoolPlanSelector(state),
    isSuperAdmin: isLoggedInAsSuperAdmin(state),
    settingsUrl: getSettingsUrl(getActiveSubscriptionId(state)),
  };
};

type InjectedProps = $Call<typeof mapStateToProps, ReduxState, {}>;

export function SettingsNav(props: InjectedProps) {
  const { canManageBilling, isAdminOrAbove, isParent, isRetailPlan, isSchoolPlan, isSuperAdmin, settingsUrl } = props;

  return (
    <NavTabs ariaLabel="Settings navigation">
      <NavTabsLink to={`${settingsUrl}/profile`}>Profile</NavTabsLink>
      {isRetailPlan && !isParent && <NavTabsLink to={`${settingsUrl}/subjects`}>Subjects</NavTabsLink>}
      {!isSuperAdmin && <NavTabsLink to={`${settingsUrl}/password`}>Password</NavTabsLink>}
      {isSchoolPlan && <NavTabsLink to={`${settingsUrl}/school`}>School Account</NavTabsLink>}
      {(isAdminOrAbove || isParent) && <NavTabsLink to={`${settingsUrl}/users`}>Users</NavTabsLink>}
      {canManageBilling && <NavTabsLink to={`${settingsUrl}/billing`}>Billing</NavTabsLink>}
      {isSuperAdmin && isSchoolPlan && <NavTabsLink to={`${settingsUrl}/wonde`}>Wonde</NavTabsLink>}
      {isSuperAdmin && isSchoolPlan && <NavTabsLink to={`${settingsUrl}/saml-sso`}>SAML SSO</NavTabsLink>}
    </NavTabs>
  );
}

export default (connect(mapStateToProps, {})(SettingsNav): React.AbstractComponent<{}>);
