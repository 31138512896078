// @flow
import classnames from 'classnames/bind';
import { debounce, isEqual, noop, size, sortBy, uniqBy } from 'lodash';
import { Box, HelpText, Text } from '@getatomi/neon';

import TextArea from 'src/components/TextArea/TextArea';
import validators from 'src/utils/validators';
import { isMobileOrTablet } from 'src/utils/userAgent';

import styles from './MultipleEmailsInput.module.scss';

const cx = classnames.bind(styles);

type Props = {
  info?: React.Node,
  label: string,
  max: number,
  onBlur: Function,
  onChange?: Function,
  placeholder?: string,
  validateOnChange: boolean,
};

type State = {
  bindOnChangeEvent: boolean,
  emails: Array<{
    isValid: boolean,
    value: string,
  }>,
  hasInvalidEmails: boolean,
  showFakeTextArea: boolean,
};

export default class MultipleEmailsInput extends React.Component<Props, State> {
  state = {
    bindOnChangeEvent: false,
    hasInvalidEmails: false,
    showFakeTextArea: false,
    emails: [],
  };

  static defaultProps = {
    validateOnChange: false,
    placeholder:
      'Copy and paste a list of the email addresses of the users that you would like to add here, each in a new line.',
    onChange: noop,
  };

  textArea = null;

  // eslint-disable-next-line react/no-unused-class-component-methods
  validate = () => {
    if (this.textArea) {
      return this.textArea.validate();
    }
  };

  // eslint-disable-next-line class-methods-use-this
  prepareListOfEmails = (rawEmails: string): Object => {
    const invalidEmails = [];
    const validEmails = [];

    const emails = rawEmails.replace(/,\s*$/, '').replace(/\n/g, ',').split(',').filter(Boolean);

    emails.forEach((value) => {
      const email = value.trim();

      if (validators.email.pattern.test(email)) {
        validEmails.push({ value: email, isValid: true });
      } else {
        invalidEmails.push({ value: email, isValid: false });
      }
    });

    return {
      hasInvalidEmails: size(invalidEmails) > 0,
      validEmails: uniqBy(validEmails, 'value'),
      emails: uniqBy([...sortBy(invalidEmails, ['value']), ...validEmails], 'value'),
    };
  };

  onEmailsBlur = (name: string, value: string) => {
    const { emails, hasInvalidEmails, validEmails } = this.prepareListOfEmails(value);

    this.setState({
      emails,
      hasInvalidEmails,
      showFakeTextArea: hasInvalidEmails && size(validEmails) <= this.props.max,
    });

    this.props.onBlur(emails, size(validEmails));
  };

  debouncedonEmailsBlur = debounce(this.onEmailsBlur, 1000);

  onEmailsChange = (name: string, value: string) => {
    const { onChange } = this.props;

    if (onChange) {
      const { validEmails } = this.prepareListOfEmails(value);
      onChange(size(validEmails));
    }
  };

  debouncedOnEmailsChange = debounce(this.onEmailsChange, 200);

  onValidateEmails = (value: string) => {
    const { max } = this.props;
    const { validEmails } = this.prepareListOfEmails(value);
    const isValid = size(validEmails) <= max;

    return {
      isValid,
      errorMessage: `You can only import a maximum of ${max} emails at a time.`,
    };
  };

  toggleFakeTextArea = () => {
    this.setState(
      (state) => ({ showFakeTextArea: !state.showFakeTextArea }),
      () => {
        if (!this.state.showFakeTextArea && this.textArea && this.textArea.inputField) {
          this.textArea.inputField.focus();
        }
      }
    );
  };

  componentDidMount() {
    if (!isMobileOrTablet && this.props.validateOnChange) {
      this.setState({ bindOnChangeEvent: true });
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.state, nextState);
  }

  render() {
    const { info } = this.props;
    const { bindOnChangeEvent, emails, hasInvalidEmails, showFakeTextArea } = this.state;

    return (
      <div>
        <Text
          as="label"
          color="colorTextSubtle"
          fontFamily="fontFamilySystem"
          fontSize="fontSizeSmall1X"
          fontWeight="fontWeightMedium"
          display="inline-block"
          htmlFor="emails"
          marginBottom="spacingSmall1X"
        >
          {this.props.label}
        </Text>

        {showFakeTextArea && (
          <div className={styles.fakeTextArea}>
            <Box
              as="ul"
              borderColor="colorBorderInput"
              borderStyle="solid"
              borderWidth="borderWidthRoot"
              borderRadius="borderRadiusSmall"
              color="colorText"
              fontFamily="fontFamilySystem"
              height="150px"
              lineHeight="lineHeightSmall"
              marginBottom="spacingRoot"
              overflowY="scroll"
              padding="spacingSmall1X spacingSmall"
              width="sizeFull"
              onClick={this.toggleFakeTextArea}
            >
              {emails.map(({ value, isValid }, i) => (
                <li key={`email-${i}`} className={cx({ fakeTextAreaLineInvalid: !isValid })}>
                  {value}{' '}
                  <Text as="span" color="colorTextSubtler" marginLeft="spacingSmall1X" variant="bodySmall1X">
                    {!isValid && 'Not valid'}
                  </Text>
                </li>
              ))}
            </Box>
            <HelpText>{info}</HelpText>
            {hasInvalidEmails && (
              <HelpText variant="error">Oops! Some email addresses are invalid. Please fix the errors.</HelpText>
            )}
          </div>
        )}

        <TextArea
          ref={(el) => {
            this.textArea = el;
          }}
          name="emails"
          id="emails"
          placeholder={this.props.placeholder}
          info={info}
          value={emails && size(emails) > 0 ? emails.map(({ value }) => value).join('\n') : ''}
          onChange={bindOnChangeEvent ? this.debouncedonEmailsBlur : this.debouncedOnEmailsChange}
          onBlur={this.onEmailsBlur}
          autoCapitalize="off"
          isMandatory
          onValidate={this.onValidateEmails}
          className={cx('textArea', { hideTextArea: showFakeTextArea })}
        />
      </div>
    );
  }
}
