// @flow
import { ApolloError } from '@apollo/client';

import GraphQLError from 'src/components/GraphQLError/GraphQLError';

type Props = {
  children: React.Node,
};
type State = {
  error: ?Error,
};

export default class InsightsErrorBoundary extends React.Component<Props, State> {
  state: State = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    return {
      error: error instanceof ApolloError ? error : null,
    };
  }

  render() {
    if (this.state.error)
      return (
        <GraphQLError
          error={(this.state.error: $FlowSuppressAny)}
          description="We couldn’t load insights for this subscription."
        />
      );

    return this.props.children;
  }
}
