// @flow
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';

import GraphQLProvider from 'src/components/GraphQLProvider/GraphQLProvider';
import getRoutes from 'src/routes';

type Props = {
  store: Object,
};

function ClientRoot(props: Props) {
  const { store } = props;

  return (
    <CookiesProvider>
      <GraphQLProvider>
        <Provider store={store}>
          <Router history={browserHistory} routes={getRoutes(store)} />
        </Provider>
      </GraphQLProvider>
    </CookiesProvider>
  );
}

export default ClientRoot;
