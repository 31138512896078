// @flow
import { connect } from 'react-redux';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { get } from 'lodash';
import { Box, Container, Divider, PhoneField, Stack, TextField, useToast } from '@getatomi/neon';

import { getAllLevelsForSelect } from 'src/reducers/levels';
import { getRegionCountryISO } from 'src/reducers/regions';
import { isUpdatingUser as isUpdatingUserSelector, getUserSchoolName } from 'src/reducers/auth';
import { preferredCountries } from 'src/constants/regions';
import { updateUser } from 'src/actions/auth';
import ComboboxSchool from 'src/components/ComboboxSchool/ComboboxSchool';
import AvatarUploadContainer from 'src/components/AvatarUploadContainer/AvatarUploadContainer';
import Button from 'src/components/Button/Button';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import getPositionsByRegion from 'src/utils/getPositionsByRegion';
import scrollToActiveElement from 'src/utils/scrollToActiveElement';
import useProfileForm, { type OutboundFields } from 'src/hooks/forms/useProfileForm';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import type { BoundAction, Level, RegistrationRegionCode, ReduxState, SelectOptionOf, UserType } from 'src/types';

import DownloadDataDialog from '../DownloadDataDialog/DownloadDataDialog';
import DeleteAccountDialog from '../DeleteAccountDialog/DeleteAccountDialog';

type Props = {
  isAccountOwner: boolean,
  isParent: boolean,
  isSchoolPlan: boolean,
  isStudent: boolean,
  user: UserType,
};

type InjectedProps = Props & {
  countryISO: string,
  isUpdatingUser: boolean,
  levels: Array<SelectOptionOf<Level>>,
  region: RegistrationRegionCode,
  schoolName: string,
  updateUserAction: BoundAction<typeof updateUser>,
};

const mapStateToProps = (state: ReduxState, ownProps: Props) => {
  const region = ownProps.user.region_code;

  return {
    countryISO: getRegionCountryISO(state, region),
    levels: getAllLevelsForSelect(state, true),
    region,
    schoolName: getUserSchoolName(state),
    isUpdatingUser: isUpdatingUserSelector(state),
  };
};

function ProfileForm(props: InjectedProps) {
  const {
    countryISO,
    user,
    isAccountOwner,
    isParent,
    isStudent,
    isSchoolPlan,
    schoolName,
    levels,
    isUpdatingUser,
    updateUserAction,
    region,
  } = props;

  const toast = useToast();
  const [isDeleteAccountDialogVisible, setIsDeleteAccountDialogVisible] = useState<boolean>(false);
  const [isDownloadDataDialogVisible, setIsDownloadDataDialogVisible] = useState<boolean>(false);

  const regionCode = user.region_code;
  const isSchoolIdFieldPresent = !isSchoolPlan && !isParent;
  const isLevelIdFieldPresent = isStudent;
  const isPositionFieldPresent = !isParent && !isStudent;
  const isPhoneFieldPresent = !isParent && !isStudent;

  const { control, fields, form, serverError } = useProfileForm({
    defaultValues: {
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      levelId: get(user, 'level.id'),
      phoneNumber: {
        isValid: null,
        number: '',
        value: user.phone_number ?? '',
      },
      position: user.position,

      // TODO: change schoolName to schoolId when ComboboxSchool is refactored to accept school id's as default inputs in PROD-3623
      schoolId: schoolName,
    },
    onSubmitSuccess: async (data: OutboundFields) => {
      await updateUserAction(data);
      toast.success('Your profile has been updated successfully.');
    },
    onSubmitFail: () => {
      scrollToActiveElement();
    },
    context: {
      isSchoolIdFieldPresent,
      isLevelIdFieldPresent,
      isPositionFieldPresent,
      isPhoneFieldPresent,
    },
  });

  return (
    <Container textAlign="center" maxWidth="sizeContainerSmall">
      <Stack spacing="spacingLarge1X">
        <form {...form}>
          <Stack spacing="spacingLarge5X">
            <Stack spacing="spacingLarge1X">
              <AvatarUploadContainer user={user} withDelete key="avatarUpload" />
              <Divider />
              {serverError && <ValidationErrors errors={serverError.message} />}
              <Box display="grid" gridGap="spacingRoot" gridTemplateColumns={{ mobile: 'repeat(2, 1fr)' }}>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      {...fields.firstName}
                      autoComplete="given-name"
                      autoCorrect="off"
                      testHook="profile-input-firstname"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="lastName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      {...fields.lastName}
                      autoComplete="family-name"
                      autoCorrect="off"
                      testHook="profile-input-lastname"
                    />
                  )}
                />
              </Box>
              <Box testHook="hide-in-percy">
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextField {...field} {...fields.email} type="email" autoComplete="username" autoCorrect="off" />
                  )}
                />
              </Box>
              {isSchoolIdFieldPresent && (
                <Controller
                  control={control}
                  name="schoolId"
                  render={({ field }) => (
                    <ComboboxSchool
                      {...field}
                      {...fields.schoolId}
                      region={regionCode}
                      schoolId={field.value}
                      schoolName={schoolName}
                    />
                  )}
                />
              )}
              {isLevelIdFieldPresent && (
                <Controller
                  control={control}
                  name="levelId"
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      {...fields.levelId}
                      items={levels}
                      selectedKey={field.value ? String(field.value) : null}
                      onSelectionChange={field.onChange}
                    >
                      {(item) => <Item key={item.value}>{item.label}</Item>}
                    </Dropdown>
                  )}
                />
              )}
              {isPositionFieldPresent && (
                <Controller
                  control={control}
                  name="position"
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      {...fields.position}
                      items={getPositionsByRegion(region)}
                      selectedKey={field.value}
                      onSelectionChange={field.onChange}
                    >
                      {(item) => <Item>{item.label}</Item>}
                    </Dropdown>
                  )}
                />
              )}
              {isPhoneFieldPresent && (
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <PhoneField
                      {...field}
                      {...fields.phoneNumber}
                      autoComplete="tel"
                      helpText="We’ll only use your phone number for setup assistance and support."
                      initialCountry={!user.phone_number ? countryISO : undefined}
                      preferredCountries={preferredCountries}
                      value={field.value.value}
                    />
                  )}
                />
              )}
            </Stack>
            <Button isLoading={isUpdatingUser} type="submit">
              Update profile
            </Button>
          </Stack>
        </form>
        <div>
          <Button
            onClick={() => setIsDeleteAccountDialogVisible(true)}
            variant="text"
            testHook="delete-account-button"
            size="small"
          >
            Delete my account.
          </Button>{' '}
          <Button
            onClick={() => setIsDownloadDataDialogVisible(true)}
            variant="text"
            testHook="download-data-button"
            size="small"
          >
            Download my data.
          </Button>
        </div>
        {isDeleteAccountDialogVisible && (
          <DeleteAccountDialog
            user={user}
            isAccountOwner={isAccountOwner}
            onClose={() => setIsDeleteAccountDialogVisible(false)}
          />
        )}
        {isDownloadDataDialogVisible && (
          <DownloadDataDialog user={user} onClose={() => setIsDownloadDataDialogVisible(false)} />
        )}
      </Stack>
    </Container>
  );
}

export default (connect(mapStateToProps, { updateUserAction: updateUser })(
  ProfileForm
): React.AbstractComponent<Props>);
