// @flow

// Allow dynamic importing of non-component files (e.g. hooks) on the client.
// To be used within a <Suspense> component - see src/components/Editor/Editor.js
// https://stackoverflow.com/questions/63126355/loading-react-hooks-using-dynamic-imports
const cache = {};
const errorsCache = {};
// <Suspense> catches the thrown promise
// and rerenders children when promise resolves
export default function useSuspense(importPromise: Promise<any>, cacheKey: string) {
  const cachedModule = cache[cacheKey];
  // already loaded previously
  if (cachedModule) return cachedModule;

  //prevents import() loop on failed imports
  if (errorsCache[cacheKey]) throw errorsCache[cacheKey];

  // gets caught by Suspense
  throw importPromise
    .then((mod) => (cache[cacheKey] = mod))
    .catch((err) => {
      errorsCache[cacheKey] = err;
    });
}
