// @flow
import 'core-js';

import 'src/styles/vendor/index.scss';
import 'src/styles/index.scss';

import 'cross-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { browserHistory } from 'react-router';
import yn from 'yn';
import { Workbox } from 'workbox-window';

import Logger from 'src/utils/Logger';
import createStore from 'src/store/create';
import { loadingBar } from 'src/components/LoadingBar/LoadingBar';
import * as tracking from 'src/utils/tracking';
import 'src/utils/validation';
import client from 'src/graphql/client';

import ClientRoot from './ClientRoot';

const log = new Logger('client/index');

export const APP_UPDATE_AVAILABLE = 'appUpdateAvailable';

// Disable service worker in Cypress https://github.com/cypress-io/cypress/issues/702
if (
  'serviceWorker' in navigator &&
  !__DEVELOPMENT__ &&
  !yn(config.RUNTIME_CYPRESS) &&
  yn(config.RUNTIME_SERVICE_WORKER)
) {
  // Configure service worker
  const wb = new Workbox('/sw.js');

  wb.addEventListener('controlling', (event) => {
    log.info(`SW Event: controlling ${event.isUpdate ? '(update)' : '(first time)'}`);
    if (event.isUpdate) {
      // this flag is checked in the transition hook below to trigger a page refresh on navigation
      // set it globally so we can toggle it in development
      window[APP_UPDATE_AVAILABLE] = true;
    }
  });
  wb.register();

  browserHistory.listenBefore((location, callback) => {
    // handle only internal transitions so we don't interfere with other routers (e.g. we don't want
    // to reload in the middle of a quiz)
    log.info('SW Event: check for update');
    // check for a service worker update on every navigation
    wb.update();
    if (window[APP_UPDATE_AVAILABLE]) {
      log.info('SW Event: reload page');
      // $FlowIgnore - clearStore is defined
      client.clearStore();
      loadingBar.start();
      window.location.assign(browserHistory.createHref(location));
      return false;
    }
    callback(true);
  });
}

const store = createStore({ data: window.__data });

tracking.subscribeToStoreChange(store);
tracking.subscribeToRouteChange();

ReactDOM.hydrate(
  <ClientRoot store={store} />,
  // $FlowIgnore: #root exists in the document
  document.getElementById('root')
);

// react-hot-loader docs recommends removing this however it doesn't work well
// with our UI components and utils (it hard refreshes the page)
// so we also enable HMR on all the code
if (module.hot) {
  module.hot.accept();
}
