// @flow
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { browserHistory } from 'react-router';
import type { LocationShape } from 'react-router';
import { find, get, has } from 'lodash';
import { Box, Container, Flex, IconLock, Stack, Switch, Text } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import Logger from 'src/utils/Logger';
import connectData from 'src/decorators/connectData';
import CardDetails from 'src/components/CardDetails/CardDetails';
import CardTerms from 'src/components/CardDetails/CardTerms/CardTerms';
import { loadPlanUpdateEstimate } from 'src/actions/estimates';
import { loadPaymentSources } from 'src/actions/paymentSources';
import { loadPlansAndEstimate } from 'src/actions/plans';
import {
  createPaymentIntent,
  setUpgradeSubscriptionStatus,
  clearUpgradeError,
  upgradeSubscription,
} from 'src/actions/subscriptions';
import { getAllPlanUpdateEstimates } from 'src/reducers/estimates';
import { getPaidPlansByCustomerType } from 'src/reducers/plans';
import {
  getPrimaryPaymentSource,
  isPrimaryPaymentSourceExpired,
  isPaymentSourcesLoaded,
} from 'src/reducers/paymentSources';
import {
  getActiveSubscriptionPlanCode,
  getActiveSubscriptionPlanCustomerType,
  isUpgradingSubscription as isUpgradingSubscriptionSelector,
  isLoggedInAsParent as isLoggedInAsParentSelector,
} from 'src/reducers/subscriptions';
import type { Dispatch, BoundAction, GetState, PaymentFrequencyType, ReduxState } from 'src/types';
import paymentFrequencies from 'src/constants/paymentFrequencies';
import { getUpgradeSuccessUrl } from 'src/utils/routes';
import { trackEvent } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';
import PaymentSourceForm from 'src/components/PaymentSourceForm/PaymentSourceForm';

import UpgradeCredits from './UpgradeCredits/UpgradeCredits';
import UpgradeHeader from './UpgradeHeader/UpgradeHeader';
import TotalCost from './TotalCost/TotalCost';
import PlanCostDetails from './PlanCostDetails/PlanCostDetails';

const log = new Logger('domains/Upgrade');
const defaultPaymentFrequency = paymentFrequencies.month;

type Params = {
  paymentFrequency?: PaymentFrequencyType,
  subscriptionId: string,
};

const fetchData = (getState: GetState, dispatch: Dispatch, location: LocationShape, params: Params) => {
  const customerType = getActiveSubscriptionPlanCustomerType(getState());
  return Promise.all([
    !isPaymentSourcesLoaded(getState()) && dispatch(loadPaymentSources()),
    dispatch(loadPlansAndEstimate(customerType, params.paymentFrequency || defaultPaymentFrequency)),
  ]);
};

const mapStateToProps = (state) => {
  const customerType = getActiveSubscriptionPlanCustomerType(state);
  const plans = getPaidPlansByCustomerType(state, customerType);
  const monthlyPlan = (find(plans, { billing_period: paymentFrequencies.month }): $FlowSuppressAny);
  const yearlyPlan = (find(plans, { billing_period: paymentFrequencies.year }): $FlowSuppressAny);
  const yearlyDiscount = Math.round(
    ((monthlyPlan.pricing.yearly - yearlyPlan.pricing.yearly) * 100) / monthlyPlan.pricing.yearly
  );

  return {
    currentPlanCode: getActiveSubscriptionPlanCode(state),
    isUpgradingSubscription: isUpgradingSubscriptionSelector(state),
    upgradeError: state.subscriptions.upgradeError,
    yearlyDiscount,
    plans: {
      [paymentFrequencies.month]: monthlyPlan,
      [paymentFrequencies.year]: yearlyPlan,
    },
    estimates: getAllPlanUpdateEstimates(state),
    paymentSource: getPrimaryPaymentSource(state),
    isPrimaryPaymentSourceExpired: isPrimaryPaymentSourceExpired(state),
    isLoggedInAsParent: isLoggedInAsParentSelector(state),
  };
};

type Props = {|
  ...$Call<typeof mapStateToProps, ReduxState>,
  clearUpgradeErrorAction: BoundAction<typeof clearUpgradeError>,
  createPaymentIntentAction: BoundAction<typeof createPaymentIntent>,
  isLoggedInAsParent: boolean,
  loadPaymentSourcesAction: BoundAction<typeof loadPaymentSources>,
  loadPlanUpdateEstimateAction: BoundAction<typeof loadPlanUpdateEstimate>,
  params: Params,
  setUpgradeSubscriptionStatusAction: BoundAction<typeof setUpgradeSubscriptionStatus>,
  upgradeSubscriptionAction: BoundAction<typeof upgradeSubscription>,
|};

function SwitchLabel({
  label,
  paymentFrequency,
}: {
  label: PaymentFrequencyType,
  paymentFrequency: PaymentFrequencyType,
}) {
  return (
    <Text
      aria-hidden="true"
      color={paymentFrequency === label ? 'colorTextLink' : 'colorTextSubtler'}
      fontWeight="fontWeightSemiBold"
      textTransform="uppercase"
      variant="bodySmall2X"
    >
      {`Pay ${label}ly`}
    </Text>
  );
}

function Upgrade(props: Props) {
  const { plans, estimates, isLoggedInAsParent, loadPlanUpdateEstimateAction } = props;

  const [paymentFrequency, setPaymentFrequency] = useState<PaymentFrequencyType>(
    get(props, 'params.paymentFrequency') || defaultPaymentFrequency
  );
  const [plan, setPlan] = useState(plans[paymentFrequency]);

  useEffect(() => {
    setPlan(plans[paymentFrequency]);
  }, [plans, paymentFrequency]);

  useEffect(() => {
    if (!estimates[plan.code]) {
      loadPlanUpdateEstimateAction(plan.code);
    }
  }, [plan, estimates, loadPlanUpdateEstimateAction]);

  const switchToYearlyPlan = (e: SyntheticEvent<>) => {
    e.preventDefault();
    setPaymentFrequency(paymentFrequencies.year);
  };

  const onPaymentSuccess = async (paymentIntentId?: string) => {
    const { currentPlanCode, paymentSource, params, loadPaymentSourcesAction, upgradeSubscriptionAction } = props;
    const estimate = estimates[plan.code];
    const revenue = estimate.amount_due / 100;

    try {
      await upgradeSubscriptionAction({
        planCode: plan.code,
        paymentIntentId,
      });

      // https://hschub.atlassian.net/browse/PROD-1825
      trackEvent(trackingEvents.subscriptionRetailUpgradeConfirmed, {
        planCodeFrom: currentPlanCode,
        planCodeTo: plan.code,
        revenue,
        currency: plan.pricing.currency,
        value: revenue,
      });

      if (!paymentSource) {
        loadPaymentSourcesAction();
      }
      // redirect to success route
      browserHistory.replace(getUpgradeSuccessUrl(params.subscriptionId, params.paymentFrequency));
    } catch (error) {
      log.warn('Form submission: upgrade subscription failed', error);
    }
  };

  const { yearlyDiscount, paymentSource, upgradeError, createPaymentIntentAction, setUpgradeSubscriptionStatusAction } =
    props;

  const estimate = estimates[plan.code];
  const isExistingPaidSubscription = has(props, 'params.paymentFrequency');
  const submitButtonLabel = isExistingPaidSubscription
    ? `Pay ${paymentFrequency === 'month' ? 'monthly' : 'yearly'}`
    : 'Upgrade to unlimited';
  const currency = get(plan, 'pricing.currency');

  const PaymentForm = paymentSource ? PaymentSourceForm : CardDetails;
  const formProps =
    PaymentForm === PaymentSourceForm ? { paymentSource, shouldCreateIntent: () => estimate.amount_due > 0 } : {};

  return (
    <>
      <Helmet>
        <title>
          {isExistingPaidSubscription
            ? `Upgrade to a ${paymentFrequency === 'month' ? 'monthly' : 'yearly'} plan`
            : 'Upgrade to unlimited access'}
        </title>
      </Helmet>
      <section>
        <UpgradeHeader
          isLoggedInAsParent={isLoggedInAsParent}
          isExistingPaidSubscription={isExistingPaidSubscription}
          paymentFrequency={paymentFrequency}
          yearlyDiscount={yearlyDiscount}
        />
        <Container maxWidth="sizeContainerSmall">
          {/** Payment frequency toggle switch **/}
          {!isExistingPaidSubscription && (
            <Box
              borderColor={{ base: 'colorBorder', tablet: 'colorBorderInverted' }}
              borderStyle="solid"
              borderWidth={1}
              marginBottom="spacingLarge4X"
              paddingBlock={{ base: 'spacingRoot', tablet: 0 }}
              testHook="upgrade-payment-frequency-switch"
            >
              <Box as={Flex} alignItems="center" gap="spacingRoot" marginInline="auto" width="max-content">
                <SwitchLabel label="month" paymentFrequency={paymentFrequency} />
                <Switch
                  ariaLabel="Payment frequency. Switch on to pay yearly or switch off to pay monthly."
                  onChange={() => {
                    setPaymentFrequency((prevFrequency) => (prevFrequency === 'month' ? 'year' : 'month'));
                  }}
                />
                <SwitchLabel label="year" paymentFrequency={paymentFrequency} />
              </Box>
            </Box>
          )}

          <PlanCostDetails plan={plan} paymentFrequency={paymentFrequency} yearlyDiscount={yearlyDiscount} />

          <Box marginBottom="spacingLarge3X">
            <UpgradeCredits currency={currency} estimate={estimate} />
          </Box>
          <Stack spacing="spacingLarge2X">
            {/** Existing payment sources or new card form **/}
            <PaymentForm
              {...formProps}
              error={upgradeError}
              createIntent={(paymentMethodId, other) =>
                createPaymentIntentAction({
                  paymentMethodId,
                  amount: estimate.amount_due,
                  currencyCode: currency,
                  ...other,
                })
              }
              onRequest={() => setUpgradeSubscriptionStatusAction(true)}
              onError={() => setUpgradeSubscriptionStatusAction(false)}
              onSuccess={onPaymentSuccess}
            >
              <Box marginTop="spacingLarge2X" textAlign="center">
                <TotalCost
                  estimate={estimate}
                  currency={currency}
                  paymentFrequency={paymentFrequency}
                  yearlyDiscount={yearlyDiscount}
                  onSwitchToYearlyPlan={!isExistingPaidSubscription ? switchToYearlyPlan : undefined}
                />
                <Stack spacing="spacingLarge2X">
                  <Text as="p" variant="bodySmall1X" color="colorTextSubtler" align="center">
                    <strong>The plan will automatically renew each {paymentFrequency} until cancelled.</strong> To avoid
                    charges for the next {paymentFrequency} cancel before the renewal date.
                  </Text>
                  <Button
                    type="submit"
                    isLoading={props.isUpgradingSubscription}
                    isDisabled={props.isPrimaryPaymentSourceExpired}
                    size="large"
                    testHook="upgrade-submit-button"
                  >
                    {submitButtonLabel}
                  </Button>
                </Stack>
              </Box>
            </PaymentForm>
            <Flex
              gap="spacingSmall"
              direction={{ base: 'column', tablet: 'row' }}
              alignItems="center"
              justifyContent="center"
            >
              <IconLock size="sizeIconSmall" color="colorIconBrand" />
              <Text as="p" variant="bodySmall1X" color="colorTextSubtler">
                This is a secure 256-bit SSL encrypted payment.
              </Text>
            </Flex>
            <CardTerms
              isLoggedInAsParent={isLoggedInAsParent}
              currency={currency}
              buttonLabel={submitButtonLabel}
              taxCode={get(plan, 'tax_code')}
            />
          </Stack>
        </Container>
      </section>
    </>
  );
}

export default connectData(fetchData)(
  connect(mapStateToProps, {
    loadPaymentSourcesAction: loadPaymentSources,
    createPaymentIntentAction: createPaymentIntent,
    loadPlanUpdateEstimateAction: loadPlanUpdateEstimate,
    upgradeSubscriptionAction: upgradeSubscription,
    clearUpgradeErrorAction: clearUpgradeError,
    setUpgradeSubscriptionStatusAction: setUpgradeSubscriptionStatus,
  })(Upgrade)
);
