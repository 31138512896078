// @flow
import { useEffect, useState } from 'react';

/**
 * This hook observes the html element for changes in the style attribute to detect when the
 * scrollbar is automatically hidden by the react-aria hooks used Neon picker components such as the
 * Dropdown, Menu etc. The returned scrollbarWidth should be applied to any fixed element to prevent
 * any layout shifts when the scrollbar is hidden. This is a workaround for an issue in react-aria:
 * https://github.com/adobe/react-spectrum/issues/4782#issuecomment-1641065755
 */
export default function usePreventScrollObserver(): { scrollbarWidth: ?string } {
  const [scrollbarWidth, setScrollbarWidth] = useState<?string>();

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        const root = document.scrollingElement ?? document.documentElement;
        setScrollbarWidth(window.getComputedStyle(root).paddingRight);
      });
    });

    const htmlElement = document.querySelector('html');
    if (htmlElement) {
      observer.observe(htmlElement, { attributes: true, attributeFilter: ['style'] });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return { scrollbarWidth };
}
