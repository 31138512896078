// @flow
import { forwardRef } from 'react';
import { Combobox as NeonCombobox } from '@getatomi/neon';

import useIntercomWidget from 'src/hooks/useIntercomWidget';

export type ComboboxProps = {|
  allowsCustomValue?: boolean,
  ariaDescribedBy?: string,
  ariaLabel?: string,
  ariaLabelledBy?: string,
  autoFocus?: boolean,
  children?: React.Node | ((item: any) => React.Node),
  defaultInputValue?: string,
  defaultItems?: $ReadOnlyArray<any>,
  defaultSelectedKey?: string | null,
  errorVariant?: 'error',
  helpText?: React.Node,
  inputValue?: string,
  isDisabled?: boolean,
  isLoading?: boolean,
  isReadOnly?: boolean,
  isRequired?: boolean,
  items?: $ReadOnlyArray<any>,
  label?: React.Node,
  name?: string,
  onBlur?: (event: SyntheticFocusEvent<>) => mixed,
  onFocus?: (event: SyntheticFocusEvent<>) => mixed,
  onInputChange?: (value: string) => mixed,
  onOpenChange?: (isOpen: boolean, isTray: boolean) => mixed,
  onSelectionChange: (key: any) => mixed,
  placeholder?: string,
  secondaryLabel?: React.Node,
  selectedKey?: string | null,
  validationText?: string,
|};

export const Combobox = forwardRef<ComboboxProps, HTMLInputElement>(function Combobox(props, ref) {
  const { onOpenChange, ...otherProps } = props;
  const [showIntercom, hideIntercom] = useIntercomWidget({ showOnUnmount: true });

  return (
    <NeonCombobox
      ref={ref}
      onOpenChange={(isOpen, isTray) => {
        if (isTray) {
          if (isOpen) {
            hideIntercom();
          } else {
            showIntercom();
          }
        }
        if (onOpenChange) {
          onOpenChange(isOpen, isTray);
        }
      }}
      {...otherProps}
    />
  );
});

export { Item, ItemDescription, ItemLabel } from '@getatomi/neon';
