// @flow
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Badge, Box, Container } from '@getatomi/neon';
import { useState } from 'react';
import invariant from 'invariant';

import type { ReduxState } from 'src/types';
import { getUserId } from 'src/reducers/auth';
import {
  isAdminOrAbove,
  isLoggedInAsStudent as isLoggedInAsStudentSelector,
  isRetailPlan as isRetailPlanSelector,
} from 'src/reducers/subscriptions';
import { NavTabs, NavTabsLink } from 'src/components/NavTabs/NavTabs';
import { getClassUrl } from 'src/utils/routes';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';

import EditClassDialog from './EditClassDialog/EditClassDialog';
import SubjectHeader, { SubjectHeaderLoader } from './SubjectHeader/SubjectHeader';
import useGetClass from './useGetClass';

type Props = {
  params: {
    classId: string,
    subscriptionId: string,
  },
};

type InjectedProps = Props & {
  canEditClass: boolean,
  children: React.Node,
  isLoggedInAsStudent: boolean,
  isRetailPlan: boolean,
};

const mapStateToProps = (state: ReduxState) => {
  return {
    canEditClass: isAdminOrAbove(state),
    isLoggedInAsStudent: isLoggedInAsStudentSelector(state),
    isRetailPlan: isRetailPlanSelector(state),
    userId: getUserId(state),
  };
};

function Class(props: InjectedProps) {
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const toggleEditModal = () => setIsEditModalVisible((isVisible) => !isVisible);

  const { canEditClass, children, isLoggedInAsStudent, isRetailPlan, params } = props;
  const { classId, subscriptionId } = params;

  const { class: classData, error, loading } = useGetClass({ classId, isLoggedInAsStudent, subscriptionId });

  if (error) {
    return (
      <>
        <Helmet>
          <title>Oops, sorry!</title>
        </Helmet>
        <GraphQLError
          error={error}
          description="We couldn’t load your class."
          customMessages={{
            NOT_FOUND: {
              description: 'We can’t seem to find the page you’re looking for.',
              heading: 'Oops, sorry!',
            },
          }}
        />
      </>
    );
  }

  if (!loading) {
    invariant(classData, 'Class data should be defined');
  }

  const classUrl = getClassUrl(subscriptionId, classId);
  const pageTitle = classData ? `${classData.name} | ${classData.subject.name}` : 'Class';

  return (
    <div>
      <Helmet titleTemplate={`%s | ${pageTitle}`} defaultTitle={pageTitle} />
      <Container>
        {classData ? (
          <SubjectHeader
            className={classData.name}
            isStudent={isLoggedInAsStudent}
            levels={classData.levels}
            subject={classData.subject}
            showSubjectTag={!isRetailPlan}
            toggleEditModal={canEditClass ? toggleEditModal : undefined}
          />
        ) : (
          <SubjectHeaderLoader isStudent={isLoggedInAsStudent} />
        )}
        {!isLoggedInAsStudent && (
          <NavTabs ariaLabel="Class navigation">
            <NavTabsLink to={classUrl}>Subject</NavTabsLink>
            <NavTabsLink to={`${classUrl}/tasks`}>Tasks</NavTabsLink>
            <NavTabsLink
              activeMatcher={({ pathname }) => Boolean(pathname.match('markbook'))}
              to={`${classUrl}/markbook-marks`}
            >
              Mark Book
            </NavTabsLink>
            <NavTabsLink
              activeMatcher={({ pathname }) => Boolean(pathname.match('insights'))}
              to={`${classUrl}/insights`}
            >
              Class Insights
            </NavTabsLink>
            <NavTabsLink to={`${classUrl}/users/students`}>
              <Box as="span" marginRight="spacingSmall1X">
                Students
              </Box>
              <Badge variant="counter">{classData?.studentCount}</Badge>
            </NavTabsLink>
            <NavTabsLink to={`${classUrl}/users/teachers`}>
              <Box as="span" marginRight="spacingSmall1X">
                Teachers
              </Box>
              <Badge variant="counter">{classData?.teacherCount}</Badge>
            </NavTabsLink>
          </NavTabs>
        )}
      </Container>
      {children}
      {canEditClass && classData && (
        <EditClassDialog
          currentClass={classData}
          onClose={toggleEditModal}
          showModal={isEditModalVisible}
          subscriptionId={subscriptionId}
        />
      )}
    </div>
  );
}

export default (connect(mapStateToProps, null)(Class): React.AbstractComponent<Props>);
