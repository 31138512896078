// @flow

import invariant from 'invariant';

import { ChallengeStart } from 'src/components/ChallengeStart/ChallengeStart';

import type { Lesson } from '../useGetLesson';

export type PostChallengeStartContainerProps = {|
  badges: React.Node,
  challengeFirstItemUrl: string,
  lesson: Lesson,
  secondaryActions: React.Node,
|};

export default function PostChallengeStartContainer({
  secondaryActions,
  challengeFirstItemUrl,
  badges,
  lesson,
}: PostChallengeStartContainerProps): React.Node {
  const { id, challenge, duration, tier } = lesson;

  invariant(challenge, `The lesson with id ${id} is missing the challenge.`);
  invariant(tier, `The lesson with id ${id} is missing the tier.`);

  const { title, questionsCount, totalMarks } = challenge;

  return (
    <ChallengeStart
      secondaryActions={secondaryActions}
      challengeFirstItemUrl={challengeFirstItemUrl}
      duration={duration}
      badges={badges}
      questionsCount={questionsCount}
      tier={tier}
      title={title}
      totalMarks={totalMarks}
    />
  );
}
