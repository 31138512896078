// @flow
import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useToast } from '@getatomi/neon';

import type { BoundAction, ReduxState, UserGroupType } from 'src/types';
import { ActionMenu, Item } from 'src/components/ActionMenu/ActionMenu';
import ActionMenuUserHeader from 'src/components/ActionMenu/ActionMenuUserHeader';
import RemoveUserFromClassDialog from 'src/domains/Class/UsersTableWithActions/RemoveUserFromClassDialog/RemoveUserFromClassDialog';
import { sendInvitationToClass } from 'src/actions/classes';
import { getUserId } from 'src/reducers/auth';
import { isActiveSubscriptionInSetupMode as isActiveSubscriptionInSetupModeSelector } from 'src/reducers/subscriptions';
import type { ClassUser } from 'src/domains/Class/useGetClassUsers';
import useDeleteClassUsers from 'src/domains/Class/useDeleteClassUsers';

type Props = {
  classId: string,
  user: ClassUser,
  userType: UserGroupType,
};

type InjectedProps = Props & {
  isActiveSubscriptionInSetupMode: boolean,
  isLoggedInUser: boolean,
  sendInvitationToClassAction: BoundAction<typeof sendInvitationToClass>,
};

const mapStateToProps = (state: ReduxState, props: Props) => {
  const userId = props.user.id;
  return {
    isActiveSubscriptionInSetupMode: isActiveSubscriptionInSetupModeSelector(state),
    isLoggedInUser: getUserId(state) === userId,
  };
};

function UserRowActions(props: InjectedProps) {
  const { classId, user, userType, isActiveSubscriptionInSetupMode, isLoggedInUser, sendInvitationToClassAction } =
    props;
  const { deleteClassUsers, isDeletingClassUsers } = useDeleteClassUsers(userType);
  const isConfirmed = user.accountStatus !== 'INVITED';
  const canResendInvite = !isConfirmed && !isLoggedInUser && !isActiveSubscriptionInSetupMode;

  const toast = useToast();
  const [showRemovePrompt, setShowRemovePrompt] = useState<boolean>(false);

  const toggleRemovePrompt = () => {
    setShowRemovePrompt((prevShowRemovePrompt) => !prevShowRemovePrompt);
  };

  const onRemoveHandler = async () => {
    await deleteClassUsers({ classId, userIds: [user.id] });
    toggleRemovePrompt();
  };

  const onResendInvite = useCallback(async () => {
    try {
      await sendInvitationToClassAction(classId, user.id);
      toast.success('The invitation has been sent successfully.');
    } catch (error) {
      toast.error('There was an error sending the invitation. Please try again.');
    }
  }, [classId, sendInvitationToClassAction, toast, user.id]);

  const handleAction = useCallback(
    (key) => {
      if (key === 'resent-invite') {
        onResendInvite();
      }
      if (key === 'remove') {
        toggleRemovePrompt();
      }
    },
    [onResendInvite]
  );

  const reduxFormatUser = ({
    id: Number(user.id),
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    avatar_url: user.avatar,
    color: user.color,
    last_logged_in_at: user.lastLoggedInAt,
    subscription_status: user.accountStatus.toLowerCase(),
  }: $FlowSuppressAny);

  return (
    <>
      <ActionMenu
        renderHeader={(isTray) => isTray && <ActionMenuUserHeader user={reduxFormatUser} />}
        onAction={handleAction}
        testHook="user-row-actions-menu"
      >
        {canResendInvite && <Item key="resent-invite">Resend invite</Item>}
        <Item key="remove">Remove from class</Item>
      </ActionMenu>
      <RemoveUserFromClassDialog
        userType={userType}
        selectedUsers={[user.id]}
        isDeletingClassUsers={isDeletingClassUsers}
        isOpen={showRemovePrompt}
        onDismiss={toggleRemovePrompt}
        onConfirm={onRemoveHandler}
      />
    </>
  );
}

export default (connect(mapStateToProps, {
  sendInvitationToClassAction: sendInvitationToClass,
})(UserRowActions): React.AbstractComponent<Props>);
