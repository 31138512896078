// @flow
import { useMemo } from 'react';
import { Box, Text, Tooltip as NeonTooltip, tokens, useMediaQuery } from '@getatomi/neon';
import {
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  Text as RechartsText,
  XAxis,
  YAxis,
} from 'recharts';

import styles from './InsightGraph.module.scss';

type TooltipPayload = {
  color: string,
  dataKey: 'y' | 'yComparison',
  name: string,
  value: number,
};
type TooltipPayloadTuple = [?TooltipPayload, ?TooltipPayload];
const toPair = (
  group: Array<TooltipPayload>,
  comparisonFunction: (arg0: TooltipPayload) => boolean
): TooltipPayloadTuple =>
  // eslint-disable-next-line no-nested-ternary
  group.length === 1 ? (comparisonFunction(group[0]) ? [group[0], null] : [null, group[0]]) : [group[0], group[1]];
type SeriesData = $ReadOnlyArray<?{
  x: string | number,
  y: number,
}>;
export type Series = {
  data: SeriesData,
  id: string,
};
export type TooltipContentRenderer = (args: {
  label?: string,
  payload: TooltipPayloadTuple,
}) => React.Node;

function useMinMaxLabelContent(
  data: SeriesData,
  minMax: 'min' | 'max' | 'minmax',
  renderValue: (arg0: number) => React.Node
) {
  return useMemo(() => {
    // find the indexes of the minimum and/or maximum value in the data
    // TODO-TS: typeguard preferred `!` users before .y - a typeguard would be better
    const [min, max] = data.reduce(
      ([prevMin, prevMax], point, index) => [
        !data[prevMin] || (point && point.y < data[prevMin].y) ? index : prevMin,
        !data[prevMax] || (point && point.y > data[prevMax].y) ? index : prevMax,
      ],
      [0, 0]
    );

    return function labelContent({ value, index }: { index: number, value: number }) {
      if ((minMax.includes('min') && index === min) || (minMax.includes('max') && index === max)) {
        return renderValue(value);
      }

      return null;
    };
  }, [data, minMax, renderValue]);
}

type CustomTooltipProps = {
  active?: boolean,
  label?: string,
  payload?: Array<TooltipPayload>,
  renderTooltipContent: TooltipContentRenderer,
};
export function CustomTooltip({ active, payload, label, renderTooltipContent }: CustomTooltipProps) {
  if (!payload || payload.length === 0) return null;

  return (
    <NeonTooltip
      animation="fade"
      arrow={false}
      content={renderTooltipContent({
        label,
        payload: toPair(payload, (p) => p?.dataKey !== 'y'),
      })}
      inertia={false}
      isTextOnly={false}
      placement="top"
      theme="light-border"
      triggerTarget={document.querySelector('.recharts-tooltip-cursor')}
      visible={active}
    >
      <span tabIndex={-1} />
    </NeonTooltip>
  );
}

export type PartialGraphProps = {
  renderTooltipContent: TooltipContentRenderer,
  renderValue: (arg0: number) => React.Node,
  xAxisProps?: React.ElementProps<typeof XAxis>,
};

export type InsightGraphProps = PartialGraphProps & {
  data: [Series, Series],
  isEmbedded?: boolean,
  testHook?: string,
};

export default function InsightGraph(props: InsightGraphProps) {
  const {
    data: [comparison, current],
    isEmbedded,
    renderValue,
    renderTooltipContent,
    testHook,
    xAxisProps,
  } = props;
  const isMobile = useMediaQuery({ maxWidth: 'breakpointSmall' });
  const data = current.data.map((point, i) => ({
    x: point?.x || comparison.data[i]?.x,
    y: point?.y,
    yComparison: comparison.data[i]?.y,
  }));

  const currentColor = tokens.colorChartInfo;
  const comparisonColor = tokens.colorChartInfoSubtler;
  const currentLabelContent = useMinMaxLabelContent(current.data, 'max', renderValue);
  const comparisonLabelContent = useMinMaxLabelContent(comparison.data, 'min', renderValue);
  const axisProps = {
    axisLine: false,
    interval: 'preserveStartEnd',
    minTickGap: 0,
    tickLine: false,
  };

  const lineProps = (color: string) => ({
    activeDot: {
      stroke: color,
    },
    animationDuration: 300,
    connectNulls: true,
    stroke: color,
    strokeWidth: 2,
    type: 'monotone',
  });

  const dotProps = {
    r: 4,
  };

  return (
    <Box
      className={styles.root}
      color="colorTextSubtle"
      fontFamily="fontFamilySystem"
      fontSize="fontSizeSmall2X"
      height={isEmbedded ? 'sizeFull' : 300}
      testHook={testHook}
    >
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <CartesianGrid stroke={tokens.colorBorder} horizontal vertical />

          {!isMobile && (
            <Legend
              align="right"
              verticalAlign="top"
              formatter={(value: React.Node) => (
                <Text as="span" variant="bodySmall2X" color="colorTextSubtle">
                  {value}
                </Text>
              )}
            />
          )}

          {!isMobile && (
            <Tooltip
              content={<CustomTooltip renderTooltipContent={renderTooltipContent} />}
              formatter={renderValue}
              offset={0} // minimum size so the tooltip can properly calculate its position
              wrapperStyle={{
                width: 1,
                height: 1,
              }}
              isAnimationActive={false}
            />
          )}

          <Line {...lineProps(comparisonColor)} dataKey="yComparison" name={comparison.id} dot={!isMobile && dotProps}>
            {isMobile && (
              <LabelList
                content={comparisonLabelContent}
                fill={tokens.colorBackgroundNeutralBolder}
                position="bottom"
              />
            )}
          </Line>

          <Line {...lineProps(currentColor)} dataKey="y" name={current.id} dot={!isMobile && dotProps}>
            {isMobile && <LabelList content={currentLabelContent} fill={currentColor} position="top" />}
          </Line>

          <XAxis
            {...axisProps} // these props make all the ticks render below the breakpoint
            interval={isMobile ? 0 : axisProps.interval} // make all ticks visible
            tick={!isMobile || <RechartsText />} // render empty tick labels
            mirror={isMobile} // flip the axis so the chart fills the container
            {...(!isMobile ? xAxisProps : {})}
            dataKey="x"
          />

          <YAxis
            {...axisProps}
            tickCount={5}
            tickFormatter={(value: number) => (!isMobile && value ? renderValue(value) : '')}
            mirror
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}
