// @flow
import { useMutation } from '@apollo/client';

import type { CreateClass, CreateClassVariables, CreateClassInput } from 'src/graphql/types/generated/CreateClass';
import { trackEvent } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';

import CREATE_CLASS from './CreateClass.graphql';

type Params = {|
  classData: CreateClassInput,
  userId: string,
|};

type MutationResponse = {|
  data: {
    createClass: {
      id: string,
    },
  },
|};

type Output = {|
  createClass: (params: Params) => Promise<MutationResponse>,
  isCreatingClass: boolean,
|};

type TrackingData = {|
  level_id: string,
  name: string,
  subject_code: string,
  user_id: string,
|};

export default function useCreateClass(): Output {
  const [mutation, { loading }] = useMutation<CreateClass, CreateClassVariables>(CREATE_CLASS, {
    refetchQueries: ['GetDashboardClasses'],
    awaitRefetchQueries: true,
  });

  const createClass = async (params: Params): Promise<MutationResponse> => {
    const { classData, userId } = params;

    const trackingData: TrackingData = {
      level_id: classData.levelId,
      name: classData.name,
      subject_code: classData.subjectCode,
      user_id: userId,
    };
    trackEvent(trackingEvents.classCreateRequested, trackingData);

    const createdClass = await mutation({
      variables: {
        input: classData,
      },
    });

    return createdClass;
  };

  return {
    createClass,
    isCreatingClass: loading,
  };
}
