// @flow
import { ApolloError, useQuery } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetAddUsersDialog,
  GetAddUsersDialogVariables,
  GetAddUsersDialog_me_account_allStudents as GqlAccountStudent,
  GetAddUsersDialog_me_account_allTeachers as GqlAccountTeacher,
} from 'src/graphql/types/generated/GetAddUsersDialog';
import type { UserAccountStatusType, UserGroupType } from 'src/types';

import GET_ADD_USERS_DIALOG from './GetAddUsersDialog.graphql';

type Props = {|
  accountId: string,
  classId: string,
  userType: UserGroupType,
|};

export type ClassUser = {|
  accountStatus: UserAccountStatusType,
  avatar: ?string,
  color: number,
  email: string,
  firstName: ?string,
  id: string,
  lastName: ?string,
  level: ?{| name: string |},
|};

type TransformedData = {|
  allUsersEmails: $ReadOnlyArray<string>,
  isSetupModeActive: boolean,
  usersNotInClass: $ReadOnlyArray<ClassUser>,
|};

type Output = {|
  data: ?TransformedData,
  error: ?ApolloError,
  loading: boolean,
|};

function transformUser(gqlUser: GqlAccountStudent | GqlAccountTeacher): ClassUser {
  return {
    accountStatus: gqlUser.accountStatus,
    avatar: gqlUser.avatar,
    color: gqlUser.color,
    email: gqlUser.email,
    firstName: gqlUser.firstName,
    id: gqlUser.id,
    lastName: gqlUser.lastName,
    level: gqlUser.level ? { name: gqlUser.level.name } : null,
  };
}

export default function useGetAddUsersDialog({ classId, accountId, userType }: Props): Output {
  const { data, error, loading } = useQuery<GetAddUsersDialog, GetAddUsersDialogVariables>(GET_ADD_USERS_DIALOG, {
    variables: {
      accountId,
      classId,
      addingStudents: userType === 'students',
    },
  });

  if (error) {
    return {
      error,
      loading: false,
      data: null,
    };
  }

  if (loading && !data) {
    return {
      error: null,
      loading: true,
      data: null,
    };
  }

  const accountData = data?.me?.account;
  const classData = accountData?.class;
  invariant(accountData && classData, 'Add users dialog data should be defined');

  const allUsersInAccount = accountData.allStudents ?? accountData.allTeachers;
  const allUsersInClass = classData.students ?? classData.teachers;
  const usersNotInClass = allUsersInAccount.filter(
    (accountUser) => !allUsersInClass.some((classUser) => classUser.id === accountUser.id)
  );

  return {
    data: {
      usersNotInClass: usersNotInClass.map(transformUser),
      isSetupModeActive: accountData.enabledFeatures.includes('SETUP_MODE'),
      allUsersEmails: allUsersInAccount.map((user) => user.email),
    },
    error: null,
    loading: false,
  };
}
