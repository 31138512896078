// @flow
const addURLArgs = (url: string) => `${url}${/\?/.exec(url) ? `&` : '?'}`;

export const buildURLWithPaginationAndSearch = ({
  keywords,
  page,
  pageSize,
  status,
  url,
}: {
  keywords?: string,
  page?: number,
  pageSize?: number,
  status?: string,
  url: string,
} = {}) => {
  let finalUrl = url;

  if (page && Number.isFinite(page) && page > 0 && pageSize && Number.isFinite(pageSize)) {
    finalUrl = `${addURLArgs(finalUrl)}offset=${page - 1}&limit=${pageSize}`;
  }

  if (keywords) {
    finalUrl = `${addURLArgs(finalUrl)}query=${keywords}`;
  }

  if (status) {
    finalUrl = `${addURLArgs(finalUrl)}filter=subscription_status:${status}`;
  }

  return finalUrl;
};
