// @flow
import TimeDifference from './TimeDifference/TimeDifference';

type Props = {
  date: string,
};

export default function DaysFromNow(props: Props) {
  const { date } = props;

  return (
    <TimeDifference date={date}>
      {(diff, output) => (
        <span>
          {diff === 0 ? (
            <time dateTime={date}>today</time>
          ) : (
            <span>
              in <time dateTime={date}>{output}</time>
            </span>
          )}
        </span>
      )}
    </TimeDifference>
  );
}
