// @flow
import { Container, Heading, Stack } from '@getatomi/neon';
import { connect } from 'react-redux';
import { Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { withRouter, type ContextRouter, type Location } from 'react-router';
import Helmet from 'react-helmet';

import { loadRegion } from 'src/actions/regions';
import { updateRegistrationDetails, register } from 'src/actions/registration';
import Button from 'src/components/Button/Button';
import ComboboxSchool from 'src/components/ComboboxSchool/ComboboxSchool';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import customerTypes from 'src/constants/authCustomerTypes';
import referralItems from 'src/constants/referral';
import connectData from 'src/decorators/connectData';
import useIsClientLoaded, { clientRequiredAttribute, loadingMessage } from 'src/hooks/useIsClientLoaded';
import useStudentRegistrationFormStep2, { type Fields } from 'src/hooks/forms/useStudentRegistrationFormStep2';
import NonAuthenticatedLayout from 'src/layouts/NonAuthenticatedLayout/NonAuthenticatedLayout';
import { getAllLevelsForSelect } from 'src/reducers/levels';
import { isRegionsLoaded } from 'src/reducers/regions';
import { isRegistering as isRegisteringSelector, type RegistrationState } from 'src/reducers/registration';
import scrollToActiveElement from 'src/utils/scrollToActiveElement';
import type { BoundAction, RegistrationRegionCode, ReduxState, GetState, Dispatch } from 'src/types';

import TermsLine from '../TermsLine/TermsLine';
import MarketingPanel from '../MarketingPanel/MarketingPanel';

type Params = {
  region: RegistrationRegionCode,
};

type Props = {
  params: Params,
};

type InjectedProps = Props & {
  isRegistering: boolean,
  levels: Array<{
    label: string,
    value: number,
  }>,
  loadRegion: BoundAction<typeof loadRegion>,
  registerAction: BoundAction<typeof register>,
  registrationDetails: RegistrationState,
  router: ContextRouter,
  updateRegistrationDetailsAction: BoundAction<typeof updateRegistrationDetails>,
};

export const fetchData = (getState: GetState, dispatch: Dispatch, location: Location, params: Params) => {
  const state = getState();

  if (!isRegionsLoaded(state)) {
    return dispatch(loadRegion({ region: params.region, withLevels: true }));
  }
};

const mapStateToProps = (state: ReduxState) => ({
  levels: getAllLevelsForSelect(state),
  registrationDetails: state.registration,
  isRegistering: isRegisteringSelector(state),
});

function RegistrationStudentStep2(props: InjectedProps) {
  const {
    isRegistering,
    levels,
    params: { region },
    registerAction,
    registrationDetails,
    router,
    updateRegistrationDetailsAction,
  } = props;
  const isClientLoaded = useIsClientLoaded();

  const { control, fields, form, tracking, serverError } = useStudentRegistrationFormStep2({
    defaultValues: {
      levelId: registrationDetails.levelId,
      schoolId: registrationDetails.schoolId,
      referral: registrationDetails.referral,
    },
    onSubmitSuccess: async (data: Fields) => {
      updateRegistrationDetailsAction(data);
      await registerAction({ region, customerType: customerTypes.student });
    },
    onSubmitFail: () => {
      scrollToActiveElement();
    },
  });

  useEffect(() => {
    if (!registrationDetails?.firstName) {
      router.push(`/register/student/${region}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NonAuthenticatedLayout sidePanel={<MarketingPanel />}>
      <Helmet>
        <title>Student Registration</title>
      </Helmet>
      <Stack spacing="spacingLarge1X">
        <Container textAlign="center" maxWidth="sizeContainerSmall">
          <form {...form} {...clientRequiredAttribute}>
            <Stack spacing="spacingLarge5X">
              <Heading as="h1">One last step to get you started!</Heading>
              <Stack spacing="spacingLarge1X">
                {serverError && <ValidationErrors errors={serverError.message} />}
                <div data-test="hide-in-percy">
                  <Controller
                    control={control}
                    name="schoolId"
                    render={({ field }) => (
                      <ComboboxSchool
                        {...field}
                        {...fields.schoolId}
                        region={region}
                        schoolId={field.value}
                        onSelectionChange={(option) => {
                          field.onChange(option.value ? parseInt(option.value, 10) : option.label);
                          tracking.trackSchoolId();
                        }}
                      />
                    )}
                  />
                </div>
                <Controller
                  control={control}
                  name="levelId"
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      {...fields.levelId}
                      items={levels}
                      selectedKey={field.value ? String(field.value) : null}
                      onSelectionChange={(event) => {
                        field.onChange(event);
                        tracking.trackLevelId();
                      }}
                    >
                      {(item) => <Item key={item.value}>{item.label}</Item>}
                    </Dropdown>
                  )}
                />
                <Controller
                  control={control}
                  name="referral"
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      {...fields.referral}
                      items={referralItems}
                      selectedKey={field.value}
                      onSelectionChange={(event) => {
                        field.onChange(event);
                        tracking.trackReferral();
                      }}
                      secondaryLabel="Optional"
                    >
                      {(item) => <Item>{item.label}</Item>}
                    </Dropdown>
                  )}
                />
              </Stack>
              <Button
                variant="legacy"
                type="submit"
                isLoading={isRegistering}
                isDisabled={!isClientLoaded}
                title={isClientLoaded ? undefined : loadingMessage}
              >
                Start using Atomi
              </Button>
            </Stack>
          </form>
        </Container>
        <Container>
          <TermsLine />
        </Container>
      </Stack>
    </NonAuthenticatedLayout>
  );
}

export default connectData(fetchData)(
  withRouter(
    connect(mapStateToProps, {
      registerAction: register,
      updateRegistrationDetailsAction: updateRegistrationDetails,
    })(RegistrationStudentStep2)
  )
);
