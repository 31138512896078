// @flow
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import { SUBSCRIPTIONS_ENDPOINT } from 'src/api/endpoints';

export const LOAD_CREDITS = 'LOAD_CREDITS';
export const LOAD_CREDITS_SUCCESS = 'LOAD_CREDITS_SUCCESS';
export const LOAD_CREDITS_FAIL = 'LOAD_CREDITS_FAIL';

export function loadCreditsBySubscription(subscriptionId: number) {
  return {
    request: {
      types: [LOAD_CREDITS, LOAD_CREDITS_SUCCESS, LOAD_CREDITS_FAIL],
      endpoint: {
        url: `${SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/credits`,
      },
    },
  };
}

/**
 * This is used on the billing page only.
 */
export function loadCredits() {
  return (dispatch: Function, getState: Function) => {
    const subscriptionId = getActiveSubscriptionId(getState());
    return dispatch(loadCreditsBySubscription(subscriptionId));
  };
}
