// @flow
import _ from 'lodash';

import type { ApiError } from 'src/types';

type WithValidationErrors = {
  validationErrors: {
    [key: string]: Array<string>,
  },
};

export const parseValidationErrors = (error: ApiError | { exception: WithValidationErrors }) =>
  _.values(_.get(error, 'exception.validationErrors') || _.get(error, 'validationErrors'));

export const processErrors = (error: ApiError) => {
  const validationErrors = parseValidationErrors(error);
  const routeError = validationErrors.length === 0 ? error : null;

  return {
    validationErrors,
    routeError,
  };
};
