// @flow
import { useState } from 'react';
import { connect } from 'react-redux';
import { AlertDialog, useToast } from '@getatomi/neon';

import PasswordRequiredDialog from 'src/components/PasswordRequiredDialog/PasswordRequiredDialog';
import type { BoundAction, ReduxState, UserType } from 'src/types';
import Link from 'src/components/Link/Link';
import { downloadData } from 'src/actions/users';
import { hasPassword as hasPasswordSelector } from 'src/reducers/auth';
import { parseValidationErrors } from 'src/api/validation';
import links from 'src/constants/links';

type Props = {
  onClose: () => void,
  user: UserType,
};

type InjectedProps = Props & {
  downloadDataAction: BoundAction<typeof downloadData>,
  hasPassword: boolean,
};

function DownloadDataDialog(props: InjectedProps) {
  const {
    hasPassword,
    downloadDataAction,
    onClose,
    user: { email },
    user,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccessPrompt, setShowSuccessPrompt] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<?string>(null);
  const toast = useToast();

  const onDownloadData = async (password: ?string) => {
    setIsLoading(true);
    try {
      await downloadDataAction(user.id, password);
      setShowSuccessPrompt(true);
    } catch (error) {
      setIsLoading(false);
      const errors = parseValidationErrors(error);
      setServerErrors(errors.join('<br />'));

      if (errors.length === 0) {
        onClose();
        toast.error('There was an error downloading your data. Please try again.');
      }
    }
  };

  return (
    <>
      <AlertDialog
        variant="success"
        heading="All done, we’re preparing your data."
        onConfirm={onClose}
        onConfirmLabel="OK thanks"
        autoFocusButton="confirm"
        isOpen={showSuccessPrompt}
      >
        We’ll send an email to {email} with a link to download your user data when it’s ready.
      </AlertDialog>

      <PasswordRequiredDialog
        isVisible={!showSuccessPrompt}
        heading="Download my data"
        description={
          <p>
            <strong>How does it work?</strong> We’ll send an email to {email} with a link to download your data. This
            might take up to an hour to process.
          </p>
        }
        helpInfo={
          <Link href={links.support.downloadData} isExternal variant="monochrome">
            Learn more about downloading your data in the Help Centre.
          </Link>
        }
        inputInfo="Enter your password to confirm the request to download your data."
        onClose={onClose}
        ctaIsLoading={isLoading}
        ctaText="Download my data"
        ctaOnClick={onDownloadData}
        hasPassword={hasPassword}
        error={serverErrors}
      />
    </>
  );
}

export default (connect((state: ReduxState) => ({ hasPassword: hasPasswordSelector(state) }), {
  downloadDataAction: downloadData,
})(DownloadDataDialog): React.AbstractComponent<Props>);
