// @flow
import { Flex } from '@getatomi/neon';

type Props = {
  avatar: React.Node,
  text: React.Node,
};

export default function AvatarWithText(props: Props) {
  const { avatar, text } = props;
  return (
    <Flex gap="spacingSmall1X" alignItems="center" minWidth="sizeNone" maxWidth="sizeFull">
      {avatar}
      {text}
    </Flex>
  );
}
