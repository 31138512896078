// @flow

import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import { AlertDialog, useToast } from '@getatomi/neon';

import type { ReduxState } from 'src/types';
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import type { CreateSamlConfig, CreateSamlConfigVariables } from 'src/graphql/types/generated/CreateSamlConfig';

import CREATE_SAML_CONFIG from './CreateSamlConfig.graphql';

export type ConfigureType = {| type: 'xml', xml: string |} | {| type: 'url', url: string |};

type Props = {
  configureType: ConfigureType | null,
  isOpen: boolean,
  onDone: () => void,
};

export type InjectedProps = Props & {
  accountId: number,
};

function mapStateToProps(state: ReduxState) {
  const accountId: number = getActiveSubscriptionId(state);
  return { accountId };
}

function SamlSsoActivateDialog(props: InjectedProps) {
  const { accountId, isOpen, onDone, configureType } = props;
  const toast = useToast();

  const onError = () => {
    toast.error('There was an error configuring SAML SSO. Please try again.');
    onDone();
  };

  const onCompleted = () => {
    toast.success('You have successfully configured SAML SSO.');
    onDone();
  };

  const [createSamlConfig, createSamlConfigResult] = useMutation<CreateSamlConfig, CreateSamlConfigVariables>(
    CREATE_SAML_CONFIG,
    {
      refetchQueries: ['GetSamlConfig'],
      onCompleted,
      onError,
    }
  );

  const onConfirm = () => {
    if (!configureType) return;

    const variables: CreateSamlConfigVariables =
      configureType.type === 'url'
        ? { input: { accountId: String(accountId), url: configureType.url } }
        : { input: { accountId: String(accountId), xml: configureType.xml } };

    // Ignore errors thrown here.
    // Instead errors will be handled by the `onError` callback above in `useMutation()`.
    // Note that it's important to catch this error to prevent unhandled promise rejection warnings.
    createSamlConfig({ variables }).catch(() => {});
  };

  return (
    <AlertDialog
      heading="Activate SAML SSO"
      onConfirmLabel="Activate"
      onConfirm={onConfirm}
      onDismiss={onDone}
      isOpen={isOpen}
      isLoading={createSamlConfigResult.loading}
    >
      Activating SAML SSO will require all users to log in with their school email address. Users will receive an email
      notifying them of this change.
    </AlertDialog>
  );
}

export default (connect(mapStateToProps)(SamlSsoActivateDialog): React.AbstractComponent<Props>);
