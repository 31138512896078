// @flow
import { taskAttachmentTypes } from 'src/constants/taskAttachmentTypes';

import type { TaskAttachmentWithFreshness } from '../hooks/useAttachments';

type MarkdownContentItem = {
  node: {
    attrs: {
      postId: string,
    },
  },
};

// Dispatches a call to remove any attached atomi content items that are no longer present in the task body
export function trimContents(
  markdownContentItems: $ReadOnlyArray<MarkdownContentItem>,
  contents: $ReadOnlyArray<{ id: string }>,
  dispatchContents: ({ id: string, type: 'remove' }) => void
) {
  if (markdownContentItems.length < contents.length) {
    const markdownIds = markdownContentItems.map(({ node }) => node.attrs.postId);
    contents
      .filter((content) => !markdownIds.includes(content.id))
      .forEach((removedContent) => {
        dispatchContents({ type: 'remove', id: removedContent.id });
      });
  }
}

type MarkdownAttachment = {
  node: {
    attrs: {
      attachmentId: string,
      url?: ?string,
    },
  },
};

// Dispatches a call to remove any attachments that are no longer present in the task body
export function trimAttachments(
  markdownFiles: $ReadOnlyArray<MarkdownAttachment>,
  markdownLinks: $ReadOnlyArray<MarkdownAttachment>,
  attachments: $ReadOnlyArray<TaskAttachmentWithFreshness>,
  dispatchAttachments: ({ id: string, type: 'remove' }) => void
) {
  if (markdownFiles.length + markdownLinks.length < attachments.length) {
    const markdownFileIds = markdownFiles.map(({ node }) => node.attrs.attachmentId);
    const markdownLinkUrls = markdownLinks.map(({ node }) => node.attrs.url);
    attachments
      .filter((attachment) => {
        if (attachment.type === taskAttachmentTypes.FILE && attachment.externalId) {
          return !markdownFileIds.includes(attachment.externalId.toString());
        }
        return !markdownLinkUrls.includes(attachment.url);
      })
      .forEach((removedAttachment) => {
        dispatchAttachments({ type: 'remove', id: removedAttachment.externalId });
      });
  }
}
