// @flow
import { Alert } from '@getatomi/neon';
import moment from 'moment';

import type { DateString } from 'src/types';
import { UnstyledLink } from 'src/components/Link/Link';
import { getBillingUrl } from 'src/utils/routes';
import userRoles, { roleTypes } from 'src/constants/userRoles';
import anchors from 'src/constants/billingAnchors';

const accountAdminDiffThreshold = 30;

type Props = {|
  isAccountOverdueSince?: DateString,
  subscriptionId: number,
  userRole: $Keys<typeof roleTypes>,
|};

export default function PaymentOverdueAlert(props: Props) {
  const { isAccountOverdueSince, subscriptionId, userRole } = props;
  const diffInDays = moment().diff(moment(isAccountOverdueSince), 'days');

  if (userRole === userRoles.accountAdmin && diffInDays > accountAdminDiffThreshold) {
    return (
      <Alert isBanner variant="warning" testHook="global-alert-overdue-invoice">
        Your latest payment has not been received and your account is 30 days overdue. To avoid cancellation please
        contact your school.
      </Alert>
    );
  }

  if (
    [userRoles.student, userRoles.familyAccountOwner, userRoles.accountOwner, userRoles.accountManager].includes(
      userRole
    )
  ) {
    return (
      <Alert
        isBanner
        variant="danger"
        buttonProps={{
          children: 'Go to my billing settings',
          to: getBillingUrl(subscriptionId, userRole === userRoles.student ? anchors.paymentDetails : anchors.invoices),
          as: UnstyledLink,
        }}
        testHook="global-alert-overdue-invoice"
      >
        {userRole === userRoles.student
          ? 'There was a problem charging your card and your payment is overdue.'
          : 'Your latest payment has not been received and your account is overdue.'}
      </Alert>
    );
  }

  return null;
}
