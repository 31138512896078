// @flow
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { AlertDialog, useToast } from '@getatomi/neon';

import type { DeleteClassTask, DeleteClassTaskVariables } from 'src/graphql/types/generated/DeleteClassTask';
import { trackEvent } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';

import DELETE_TASK from './DeleteClassTask.graphql';

export type DeleteTaskAlertDialogProps = {|
  isDraft: boolean,
  onConfirm: () => mixed,
  onDismiss: () => mixed,
  taskId: string,
|};

export default function DeleteTaskAlertDialog(props: DeleteTaskAlertDialogProps) {
  const { isDraft, onConfirm, onDismiss, taskId } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const taskOrDraftLabel = isDraft ? 'draft' : 'task';

  const [deleteTask] = useMutation<DeleteClassTask, DeleteClassTaskVariables>(DELETE_TASK, {
    variables: ({ input: { id: taskId } }: $Exact<DeleteClassTaskVariables>),
    refetchQueries: ['GetClassTasks'],
    awaitRefetchQueries: true,
    onCompleted() {
      onConfirm();
      toast.success(`Your ${taskOrDraftLabel} has been deleted.`);
    },
    onError() {
      onDismiss();
      toast.error('There was an error deleting your task. Please try again.');
    },
  });

  const confirm = async () => {
    setIsLoading(true);
    trackEvent(trackingEvents.taskDeleteRequested, { id: taskId });
    await deleteTask();
  };

  return (
    <AlertDialog
      variant="danger"
      heading={`Are you sure you want to delete this ${taskOrDraftLabel}?`}
      onConfirm={confirm}
      onConfirmLabel={`Delete ${taskOrDraftLabel}`}
      onDismiss={onDismiss}
      isOpen
      isLoading={isLoading}
    />
  );
}
