// @flow
/* eslint-disable camelcase */
import { encode, decode } from 'universal-base64';

export type NavigationContext_ClassTasks = {|
  location: 'classTasks',
  metadata: {|
    accountId: string,
    classId: string,
    title: string,
  |},
|};

export type NavigationContext_ClassTopicInsights = {|
  location: 'classTopicInsights',
  metadata: {|
    accountId: string,
    classId: string,
    title: string,
    topicId: string,
  |},
|};

export type NavigationContext_MarkbookMarks = {|
  location: 'markbookMarks',
  metadata: {|
    accountId: string,
    classId: string,
    title: string,
  |},
|};

export type NavigationContext_Module = {|
  location: 'module',
  metadata: {|
    accountId: string,
    classId: string,
    moduleId: string,
    title: string,
  |},
|};

export type NavigationContext_Post = {|
  location: 'post',
  metadata: {|
    accountId: string,
    classId: string,
    lessonId: string,
    moduleId: string,
    title: string,
  |},
|};

export type NavigationContext_StudentRevisionPage = {|
  location: 'studentRevisionPage',
  metadata: {|
    accountId: string,
    title: string,
  |},
|};

export type NavigationContext_StudentTasks = {|
  location: 'studentTasks',
  metadata: {|
    accountId: string,
    title: string,
  |},
|};

export type NavigationContext_Task = {|
  location: 'task',
  metadata: {|
    accountId: string,
    classId: string,
    taskId: string,
    title: string,
  |},
|};

export type NavigationContext =
  | NavigationContext_ClassTasks
  | NavigationContext_ClassTopicInsights
  | NavigationContext_MarkbookMarks
  | NavigationContext_Module
  | NavigationContext_Post
  | NavigationContext_StudentRevisionPage
  | NavigationContext_StudentTasks
  | NavigationContext_Task;

export function serializeNavigationContext(navigationContext: NavigationContext): string {
  return encode(JSON.stringify(navigationContext));
}

// Not type safe.
// We make an assumption that the input value here will only ever be called
// with the value created by the serialize function above.
export function parseNavigationContext(value: string): NavigationContext | null {
  try {
    return JSON.parse(decode(value));
  } catch (error) {
    return null;
  }
}
