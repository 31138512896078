// @flow
import { Button, IconArrowFront, IconActionFlip, Stack, Text, useToast } from '@getatomi/neon';
import { connect } from 'react-redux';
import { useState } from 'react';

import type { ReduxState, ExternalDataSourceType, BoundAction } from 'src/types';
import Link from 'src/components/Link/Link';
import {
  updateEDSConfigFromGoogleSheet,
  writeEDSClassesToGoogleSheet,
  loadEDSDetails,
} from 'src/actions/subscriptions';
import {
  getActiveSubscriptionId,
  isEDSMappingProcessing as isEDSMappingProcessingSelector,
  isEDSMappingCreated as isEDSMappingCreatedSelector,
  isEDSMappingValidating as isEDSMappingValidatingSelector,
  isEDSMappingValid as isEDSMappingValidSelector,
} from 'src/reducers/subscriptions';
import usePoll from 'src/hooks/usePoll';

import CardMappingDetails from '../CardMappingDetails/CardMappingDetails';

type Props = {
  externalDataSourceDetails: ExternalDataSourceType,
};

type InjectedProps = Props & {
  isEDSMappingCreated: boolean,
  isEDSMappingProcessing: boolean,
  isEDSMappingValid: boolean,
  isEDSMappingValidating: boolean,
  loadEDSDetailsAction: BoundAction<typeof loadEDSDetails>,
  subscriptionId: string,
  updateEDSConfigFromGoogleSheetAction: BoundAction<typeof updateEDSConfigFromGoogleSheet>,
  writeEDSClassesToGoogleSheetAction: BoundAction<typeof writeEDSClassesToGoogleSheet>,
};

const mapStateToProps = (state: ReduxState) => {
  return {
    subscriptionId: getActiveSubscriptionId(state),
    isEDSMappingProcessing: isEDSMappingProcessingSelector(state),
    isEDSMappingCreated: isEDSMappingCreatedSelector(state),
    isEDSMappingValidating: isEDSMappingValidatingSelector(state),
    isEDSMappingValid: isEDSMappingValidSelector(state),
  };
};

const pollFrequency = 5000;

function Mapping(props: InjectedProps) {
  const {
    externalDataSourceDetails,
    subscriptionId,
    updateEDSConfigFromGoogleSheetAction,
    writeEDSClassesToGoogleSheetAction,
    loadEDSDetailsAction,
    isEDSMappingProcessing,
    isEDSMappingCreated,
    isEDSMappingValidating,
    isEDSMappingValid,
  } = props;

  const [isLoading, setIsLoading] = useState<?boolean>(null);
  const toast = useToast();

  const sheetLink =
    externalDataSourceDetails && externalDataSourceDetails.config_google_sheet_id
      ? `https://docs.google.com/spreadsheets/d/${externalDataSourceDetails.config_google_sheet_id}`
      : null;

  const trigger = isEDSMappingProcessing || isEDSMappingValidating;
  const onPoll = async () => {
    await loadEDSDetailsAction({
      externalDataSourceId: externalDataSourceDetails.id,
      subscriptionId,
    });
  };
  const isPollComplete = !isEDSMappingProcessing && !isEDSMappingValidating;
  const onPollComplete = () => {
    if (!sheetLink) {
      return;
    }

    if (isEDSMappingCreated) {
      toast.success(
        <Stack spacing="spacingSmall2X">
          <p>Exported successfully to Google Sheets.</p>
          <Link href={sheetLink} isExternal>
            View in Google Sheets.
          </Link>
        </Stack>
      );
    } else if (isEDSMappingValid) {
      toast.success('Synced successfully from Google Sheets.');
    }
  };

  const [isPolling] = usePoll(trigger, onPoll, isPollComplete, onPollComplete, pollFrequency);

  const handleClick = async (action) => {
    try {
      setIsLoading(true);
      await action({
        subscriptionId,
        externalDataSourceId: externalDataSourceDetails.id,
      });
      await loadEDSDetailsAction({
        externalDataSourceId: externalDataSourceDetails.id,
        subscriptionId,
      });
      setIsLoading(null);
    } catch {
      setIsLoading(false);
      toast.error(`There was an error when syncing from Google Sheets. Please try again.`, {
        id: 'wonde-mapping-error',
      });
    }
  };

  return (
    <Stack spacing="spacingLarge2X">
      <Text fontSize="fontSizeLarge">Step 2: Mapping</Text>
      <Text fontFamily="fontFamilySystem" fontSize="fontSizeSmall1X" color="colorTextSubtle">
        The mapping configuration controls which classes should be synced as well as the subject and year level each
        class will be matched to.
      </Text>
      <Stack spacing="spacingSmall1X">
        <Text
          fontFamily="fontFamilySystem"
          fontWeight="fontWeightMedium"
          fontSize="fontSizeSmall1X"
          color="colorTextSubtle"
        >
          Export to Google Sheets
        </Text>
        <Text fontFamily="fontFamilySystem" fontSize="fontSizeSmall2X" color="colorTextSubtle">
          This process will get the relevant data configuration and map it to a Google Sheet.
        </Text>
      </Stack>
      {externalDataSourceDetails?.config_google_sheet_id ? (
        <>
          <CardMappingDetails
            subscriptionId={subscriptionId}
            externalDataSourceId={externalDataSourceDetails.id}
            sheetLink={sheetLink}
            onConfirm={() => handleClick(writeEDSClassesToGoogleSheetAction)}
            updatedAt={externalDataSourceDetails.updated_at}
          />
          <Button
            isLoading={isLoading ?? isPolling}
            onClick={() => handleClick(updateEDSConfigFromGoogleSheetAction)}
            iconBefore={<IconActionFlip />}
            variant="tertiary"
          >
            Sync from Google Sheets
          </Button>
        </>
      ) : (
        <Button
          isLoading={isLoading ?? isPolling}
          onClick={() => handleClick(writeEDSClassesToGoogleSheetAction)}
          iconBefore={<IconArrowFront />}
          variant="tertiary"
        >
          Export
        </Button>
      )}
    </Stack>
  );
}

export default (connect(mapStateToProps, {
  updateEDSConfigFromGoogleSheetAction: updateEDSConfigFromGoogleSheet,
  writeEDSClassesToGoogleSheetAction: writeEDSClassesToGoogleSheet,
  loadEDSDetailsAction: loadEDSDetails,
})(Mapping): React.AbstractComponent<Props>);
