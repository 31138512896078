// @flow

export type CellValue<DisplayType, SortByType> = {
  displayValue: DisplayType,
  sortByValue: DisplayType | SortByType,
};

function createCellValue<DisplayType, SortByType>(
  displayValue: DisplayType,
  sortByValue?: SortByType
): CellValue<DisplayType, SortByType> {
  const cellValue = {
    displayValue,
    sortByValue: sortByValue ?? displayValue,
  };
  return cellValue;
}

export default createCellValue;
