// @flow
import { extname } from 'path';

import type { SignedResponse } from 'src/types';

export const uploadFile = async ({ signedResponse, file }: { file: File, signedResponse: SignedResponse }) => {
  const body = new FormData();
  body.append('acl', 'private');
  body.append('Content-Type', file.type);
  body.append('key', signedResponse.key);
  body.append('policy', signedResponse.policy);
  body.append('x-amz-algorithm', signedResponse.algorithm);
  body.append('x-amz-credential', signedResponse.credential);
  body.append('x-amz-date', signedResponse.date);
  body.append('x-amz-signature', signedResponse.signature);
  // file has to be last
  body.append('file', file);

  await fetch(signedResponse.url, {
    method: 'post',
    body,
  });
};
export const getFileExtension = (fileName: string) => extname(fileName).replace(/^\./, '');
export const getSimilarExtension = (extension: string) => (extension === 'jpeg' ? 'jpg' : extension);
export const convertSizeToMB = (fileSizeInBytes: number) => `${(fileSizeInBytes / 1024 ** 2).toFixed(1)}MB`;
