// @flow
import { useEffect } from 'react';
import { type ContextRouter } from 'react-router';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Box, CompactPagination, Container } from '@getatomi/neon';

import { trackEvent } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';
import type { ReduxState, UserType } from 'src/types';
import UserName from 'src/components/UserName/UserName';
import { isLoggedInAsSuperAdmin as isLoggedInAsSuperAdminSelector } from 'src/reducers/auth';
import {
  isSchoolPlan as isSchoolPlanSelector,
  isAdminOrAbove as isAdminOrAboveSelector,
  isLoggedInAsStudent as isLoggedInAsStudentSelector,
} from 'src/reducers/subscriptions';
import { subscribeToOwnProgress } from 'src/utils/pusher';
import { getDashboardUrl } from 'src/utils/routes';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import classesSourceFilters from 'src/constants/classesSourceFilters';
import useClassesSourceTabs from 'src/components/useClassesSourceTabs/useClassesSourceTabs';

import DashboardNonStudent from './DashboardNonStudent/DashboardNonStudent';
import DashboardStudent from './DashboardStudent';
import useGetClasses from './useGetClasses/useGetClasses';

type Params = {|
  subscriptionId: string,
|};

type Props = {
  isAdminOrAbove: boolean,
  isLoggedInAsStudent: boolean,
  isLoggedInAsSuperAdmin: boolean,
  isSchoolPlan: boolean,
  location: {
    query: {
      [string]: string,
    },
  },
  params: Params,
  router: ContextRouter,
  user: UserType,
};

export const mapStateToProps = (state: ReduxState) => ({
  user: state.auth.user,
  isLoggedInAsStudent: isLoggedInAsStudentSelector(state),
  isSchoolPlan: isSchoolPlanSelector(state),
  isAdminOrAbove: isAdminOrAboveSelector(state),
  isLoggedInAsSuperAdmin: isLoggedInAsSuperAdminSelector(state),
});

function Dashboard(props: Props) {
  const { isLoggedInAsSuperAdmin, isAdminOrAbove, isLoggedInAsStudent, isSchoolPlan, location, params, router, user } =
    props;
  const { after, before, source, keywords } = location.query;
  const { source: classesSource } = useClassesSourceTabs({
    initialSource: classesSourceFilters[source],
  });
  const { subscriptionId } = params;

  function updatePageLocation(query) {
    router.push({
      pathname: getDashboardUrl(subscriptionId),
      query,
    });
  }

  const { classes, error, goToNextPage, goToPreviousPage, loading, refetch, userId } = useGetClasses({
    classesSource,
    isLoggedInAsStudent,
    isLoggedInAsSuperAdmin,
    keywords,
    paginationCursor: { after, before },
    subscriptionId,
    updatePageLocation,
  });

  const classCount = classes?.length ?? 0;

  // Handle the subscription as a separate effect, as its a separate side effect with its own
  // dependencies
  useEffect(() => {
    if (isLoggedInAsStudent) {
      return subscribeToOwnProgress(user.id, () => refetch());
    }
  }, [isLoggedInAsStudent, refetch, user.id]);

  useEffect(() => {
    if (!loading && !isLoggedInAsStudent && classesSource === 'user') {
      // This event is used to potentially start the Intercom's Product Tour
      trackEvent(trackingEvents.myClassesLoaded, { classCount });
    }
  }, [isLoggedInAsStudent, classCount, loading, classesSource]);

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load your classes." />;
  }

  // Note: loading state is delegated to child components

  return (
    <>
      <Helmet>
        <title>{isSchoolPlan ? 'Classes' : 'Subjects'}</title>
      </Helmet>
      <Container>
        <Box
          as="p"
          display={{
            base: 'none',
            tablet: 'block',
          }}
          marginBottom={{
            base: 'spacingNone',
            tablet: 'spacingLarge3X',
            desktop: 'spacingLarge7X',
          }}
          testHook="dashboard-welcome-message"
        >
          Hey <UserName firstName={user.first_name} />. Welcome! Let’s get you started.
        </Box>
      </Container>

      {isLoggedInAsStudent ? (
        <DashboardStudent
          classes={classes}
          isSchoolPlan={isSchoolPlan}
          isLoading={loading}
          subscriptionId={subscriptionId}
        />
      ) : (
        <DashboardNonStudent
          classes={classes}
          isAdminOrAbove={isAdminOrAbove}
          isLoading={loading}
          isLoggedInAsSuperAdmin
          searchKeywords={keywords}
          subscriptionId={subscriptionId}
          userId={userId}
        />
      )}
      {(goToNextPage || goToPreviousPage) && (
        <Container marginTop="spacingLarge6X">
          <CompactPagination onPreviousClick={goToPreviousPage} onNextClick={goToNextPage} />
        </Container>
      )}
    </>
  );
}

export default (connect(mapStateToProps)(Dashboard): React.AbstractComponent<Props>);
