import _ from 'lodash';

import { checkAccessToken } from 'src/actions/auth';
import { loadingBar } from 'src/components/LoadingBar/LoadingBar';
import Logger from 'src/utils/Logger';
import { getDataDependencies, getRoutePath } from 'src/decorators/connectData';

const log = new Logger('middleware/fetchDataMiddleware');
const cachedRoutes = new Set();

export default (store, auth) =>
  async ({ routes, location, params }, replace, callback) => {
    // On initial render react-router will  render `null` if the middleware doesn't execute synchronously.
    // So we shunt the middleware on initial render (the app will use the state from the server).
    if (__CLIENT__) {
      if (window.__initial) {
        window.__initial = false;
        callback();
        return;
      }
    }

    // get new access token if expired (before making any API request)
    try {
      await store.dispatch(checkAccessToken());
    } catch (error) {
      log.error(error);
      auth.logoutAndRedirect();
    }

    const components = _.map(routes, 'component');
    // Create a promise that performs the data fetching
    const dataPromises = getDataDependencies(store, components, location, params, cachedRoutes);

    // No data to fetch on that route, navigate to page
    if (!dataPromises.length) {
      callback();
      return;
    }

    // current route url
    const routePath = getRoutePath(location);
    const isCached = cachedRoutes.has(routePath);

    if (isCached) {
      log.info(`Data has already been loaded for ${routePath}, navigate now and load data in background.`);
      callback();
    } else if (__CLIENT__) {
      log.info(`Data hasn't been loaded for ${routePath}, block navigation until data is loaded.`);
      loadingBar.start();
    }

    return Promise.all(dataPromises)
      .then(() => {
        if (__CLIENT__) {
          // when running our e2e tests, the DOM might have been removed before this resolves
          if (!document.body) return;
          cachedRoutes.add(routePath);
          loadingBar.done();
        }

        if (!isCached) callback();
      })
      .catch((error) => {
        if (__CLIENT__) {
          if (!document.body) return;
          loadingBar.done();
        }
        log.warn('Warning: Error in fetchData', error.stack || error);
        callback(error);
      });
  };
