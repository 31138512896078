// @flow
import validateEmail, { isBlocking } from 'src/utils/validateEmail';
import type { BoundAction } from 'src/types';
import errorMessageVariants from 'src/constants/errorMessageVariants';
import { checkExistingUser as checkExistingUserAction } from 'src/actions/auth';

export default async function emailValidator({
  email,
  setEmailWarning,
  checkExistingUser,
}: {
  checkExistingUser?: BoundAction<typeof checkExistingUserAction>,
  email: string,
  setEmailWarning: (message: string) => void,
}) {
  // There are two async checks performed when validating an email. We only trigger the second if
  // the first passes to minimise requests.
  // 1. legitimate email according to Mailgun
  // 2. new email in Nucleus (`checkExistingUser`, from our Redux store)
  const { level, message } = (await validateEmail(email)) ?? { level: errorMessageVariants.warning, message: '' };

  if (isBlocking(level)) {
    return message;
  }

  // warnings shouldn't block form submission but displayed below the email field
  setEmailWarning(message);

  if (!checkExistingUser) {
    return null;
  }

  const { isValid, errorMessage } = await checkExistingUser(email);
  if (!isValid) {
    return errorMessage;
  }

  return null;
}
