// @flow
import useFindContentDialog, { type FoundContent } from 'src/components/useFindContentDialog/useFindContentDialog';
import type { OpenTaskDialogParams } from 'src/components/useTaskDialog/useTaskDialog';
import { getPostUrl } from 'src/utils/routes';

import type { Subject } from '../useGetClassSubject/useGetClassSubject';
import SubjectMetrics from './SubjectMetrics/SubjectMetrics';
import FindContentDialogTrigger from './FindContentDialogTrigger';

type Props = {
  classId: string,
  openTaskDialog: (OpenTaskDialogParams) => void,
  subject: Subject,
  subscriptionId: string,
};

export default function SubjectMeta(props: Props) {
  const { classId, openTaskDialog, subject, subscriptionId } = props;

  const { openFindContentDialog, findContentDialog } = useFindContentDialog({
    context: 'subject',
    classId,
    heading: 'Browse Atomi Content',
    onAssignContent: (contents: $ReadOnlyArray<FoundContent>) => {
      openTaskDialog({
        contents: contents.map((content) => ({
          duration: content.duration,
          id: content.id,
          moduleId: content.moduleId,
          name: content.name,
          subjectCode: content.subjectCode,
          tier: content.tier,
          type: content.type,
          url: getPostUrl(subscriptionId, classId, content.moduleId, content.id),
        })),
      });
    },
    subscriptionId,
  });

  return (
    <SubjectMetrics count={subject.contentCount} duration={subject.totalDuration}>
      <FindContentDialogTrigger onClick={openFindContentDialog} />
      {findContentDialog}
    </SubjectMetrics>
  );
}
