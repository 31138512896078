// @flow
import { get } from 'lodash';

import type { ApiError } from 'src/types';
import Link from 'src/components/Link/Link';
import errorTypes from 'src/constants/errorTypes';
import links from 'src/constants/links';

type Props = {
  error: ApiError,
  useAPIMessage?: boolean,
};

export default function ErrorTypeMessage(props: Props) {
  const { error, useAPIMessage = false } = props;

  if (!error) {
    return null;
  }

  if (useAPIMessage) {
    // render API error message
    return <span>{error.message}</span>;
  }

  // render custom error message
  let errorMessage = '';
  const errorType = get(error, 'errorType');

  switch (errorType) {
    case errorTypes.LoginFailException:
      errorMessage = 'Oops! That email or password is invalid.';
      break;
    case errorTypes.SocialLoginFailException:
      errorMessage = `Oops! We couldn’t find a user with that email.`;
      break;
    case errorTypes.PasswordResetRequestException:
      errorMessage = `We can’t find an account with that email address.`;
      break;
    case errorTypes.LegacyLoginFailException:
    case errorTypes.LegacyUserPasswordResetFailException:
    case errorTypes.SamlLoginRequiredException:
      errorMessage = error.message;
      break;
    case errorTypes.LegacyUserInactiveException:
      errorMessage = (
        <>
          You’ve been invited by your school to the all-new Atomi platform. To log in you’ll need to accept the email
          invite. Confused? Here’s a{' '}
          <Link href={links.support.legacyLogin} isExternal>
            help article
          </Link>{' '}
          to explain.
        </>
      );
      break;
    case errorTypes.UserInactiveException:
      errorMessage = (
        <>
          Looks like you haven’t activated your account yet. Click the link in your activation email to get started.
          Can’t find the email?{' '}
          <Link href={links.support.validatingEmail} isExternal>
            Get help here
          </Link>
          .
        </>
      );
      break;
    case errorTypes.SAMLPasswordRequestException:
      errorMessage = (
        <>
          We can’t reset your password as it is managed by your school login.{' '}
          <Link href={links.support.samlLogin} isExternal>
            Learn more
          </Link>
          .
        </>
      );
      break;
    case errorTypes.PasswordResetFailureException:
      errorMessage = `This reset password link has expired. Please check your emails for a more recent one.`;
      break;
    case errorTypes.RoleCollisionException:
      errorMessage = 'The user already exists on this subscription with a different role.';
      break;
    case errorTypes.SubscriptionInvalidStatusException:
      errorMessage =
        'Hmm. It looks like automatic-renewal has already been turned off on your account. Try refreshing this page to make sure all the info displayed is up to date.';
      break;
    case errorTypes.UserHasNoSubscriptionsException:
      errorMessage = `Your school has removed you from their Atomi account. If you'd like access to Atomi again you'll need to be invited by a school or sign up for a personal account with a different email address.`;
      break;
    case errorTypes.PasswordNotSetRequestException:
      errorMessage = (
        <>
          You haven’t set a password on your account yet. Please reset your password to log in.{' '}
          <Link to="/forgot-password">Reset my password</Link>.
        </>
      );
      break;
    default:
      errorMessage = 'Oops something went wrong. Please try again.';
      break;
  }

  return errorMessage;
}
