// @flow
import {
  Box,
  Button,
  Container,
  EmptyState,
  Flex,
  HorizontalScroll,
  Illustration,
  Stack,
  Table,
  useTableSelection,
  useMediaQuery,
} from '@getatomi/neon';
import { useMemo } from 'react';
import { browserHistory } from 'react-router';

import { getChallengeLabel } from 'src/utils/challenge';
import ActionBar from 'src/components/ActionBar/ActionBar';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import useTaskDialog from 'src/components/useTaskDialog/useTaskDialog';
import type { NewAttachedContent } from 'src/domains/Tasks/types';
import type { ChallengeTier } from 'src/types';

import { cohorts } from '../utilities/studentCohorts';
import prepareMarksColumns from '../utilities/prepareMarksColumns/prepareMarksColumns';
import useFilteredStudents from './hooks/useFilteredStudents';
import type { FilterBy } from './hooks/useFilteredStudents';
import Header, { HeaderLoader } from '../Header/Header';
import Tabs from '../Tabs/Tabs';
import type { MarksStudent } from '../types';
import type { LessonMetrics } from './StudentStrength/StudentStrength';

export type MarksProps = {|
  attachedContent?: NewAttachedContent,
  averageMarkMetrics?: LessonMetrics,
  challengeTier?: ChallengeTier,
  classId: string,
  isLoading: boolean,
  isRevision?: boolean,
  isTeacherReviewFeatureEnabled?: boolean,
  marksUrl: string,
  overviewUrl: string,
  sequence?: $ReadOnlyArray<string>,
  students?: $ReadOnlyArray<MarksStudent>,
  subheading?: string,
  subscriptionId: string,
|};

// For loading state of <Table />
const visibleLoadingRows = 4;
const placeholderData = Array.from({ length: visibleLoadingRows }, () => ({}));

export default function Marks(props: MarksProps) {
  const {
    challengeTier,
    classId,
    isLoading,
    isRevision = false,
    averageMarkMetrics = {
      regionAverageMark: 0,
      schoolAverageMark: 0,
      classAverageMark: 0,
    },
    marksUrl,
    overviewUrl,
    attachedContent,
    sequence,
    students = [],
    subscriptionId,
    subheading = '',
    isTeacherReviewFeatureEnabled,
  } = props;

  const Filtering = useFilteredStudents(students, isRevision ? [cohorts.NEEDS_REVISION] : []);
  const tableRows = Filtering.students;

  const { resetSelection, selectedIds, tableProps } = useTableSelection({
    data: tableRows,
  });

  function appendFilterToUrl(filterBy) {
    const location = browserHistory.getCurrentLocation();
    location.query = { filter: filterBy };
    browserHistory.replace(location);
  }

  function handleFilterChange(filterBy: FilterBy) {
    Filtering.setFilterBy(filterBy);
    appendFilterToUrl(filterBy);
  }

  const [taskDialog, { openTaskDialog }] = useTaskDialog({
    subscriptionId,
    classId,
    onSubmit: resetSelection,
  });

  const isMobile = useMediaQuery({ maxWidth: 'breakpointSmall' });

  const columns = useMemo(() => {
    return prepareMarksColumns({
      sequence,
      averageMarkMetrics,
      shouldTruncateFirstName: isMobile,
      shouldHideStrength: isRevision,
      isTeacherReviewFeatureEnabled,
    });
  }, [sequence, averageMarkMetrics, isMobile, isRevision, isTeacherReviewFeatureEnabled]);

  const isEmptyStateVisible = !isLoading && Filtering.students.length === 0;

  return (
    <Box paddingBottom={{ base: 'spacingRoot', tablet: 'spacingLarge3X' }}>
      <Stack spacing="spacingLarge5X">
        {isLoading ? (
          <HeaderLoader heading={`How’s everyone going with this ${isRevision ? 'revision' : 'quiz'}?`} />
        ) : (
          <Header
            heading={`How’s everyone going with this ${getChallengeLabel(challengeTier)}?`}
            subheading={subheading}
          />
        )}
        <Tabs overviewUrl={overviewUrl} marksUrl={marksUrl} />
      </Stack>
      <Flex
        direction="row"
        justifyContent="flex-end"
        paddingInline={{ base: 'spacingRoot', tablet: 'spacingLarge3X' }}
        marginBottom="spacingLarge2X"
      >
        <Dropdown
          variant="filter"
          ariaLabel="Filter by"
          items={Filtering.options}
          selectedKey={Filtering.filterBy}
          onSelectionChange={handleFilterChange}
        >
          {(item) => <Item>{item.label}</Item>}
        </Dropdown>
      </Flex>

      {isEmptyStateVisible ? (
        <Container>
          <EmptyState
            media={<Illustration name="emptystate-add-users" />}
            description="There aren’t any students in this group"
            primaryAction={
              <Button variant="text" onClick={() => handleFilterChange('all')}>
                Clear filter
              </Button>
            }
          />
        </Container>
      ) : (
        <Box
          position="relative"
          overflow="hidden"
          flexBasis="60px"
          flexShrink="0"
          flexGrow={isEmptyStateVisible ? '0' : '1'}
        >
          <HorizontalScroll forwardScrollPosition>
            <Table
              {...tableProps}
              isLoading={isLoading}
              columns={columns}
              data={isLoading ? placeholderData : tableRows}
              isStickyHeadings
              testHook="marks-table"
              isSortable
              toggleSortBy={Filtering.setSortBy}
            >
              <colgroup>
                <col style={{ width: '50px' }} />
                {columns.map((column) => (
                  <col key={column.accessorKey} />
                ))}
              </colgroup>
            </Table>
          </HorizontalScroll>
          <ActionBar selectedItemCount={selectedIds.length} onClearSelection={resetSelection}>
            <Button
              onClick={() =>
                openTaskDialog({ contents: attachedContent ? [attachedContent] : undefined, students: selectedIds })
              }
            >
              Create task
            </Button>
          </ActionBar>
        </Box>
      )}
      {taskDialog}
    </Box>
  );
}
