// @flow
import { useCookies } from 'react-cookie';
import { Tab, Tabs, TabList } from '@getatomi/neon';

import { CLASSES_SOURCE_COOKIE } from 'src/constants/cookies';
import { getCookieOptions } from 'src/utils/cookies';
import classesSourceFilters from 'src/constants/classesSourceFilters';

type SourceOptions = $Keys<typeof classesSourceFilters>;

type Props = {
  initialSource?: SourceOptions,
  onChange?: (source: SourceOptions) => void,
};

type Output = {
  source: SourceOptions,
  tabs: React$Node,
};

export default function useClassesSourceTabs({ initialSource, onChange }: Props): Output {
  const [cookies, setCookie] = useCookies([CLASSES_SOURCE_COOKIE]);

  const sources = [classesSourceFilters.user, classesSourceFilters.all];

  const source = initialSource || cookies[CLASSES_SOURCE_COOKIE] || classesSourceFilters.user;

  const setSource = (newSource: SourceOptions) => {
    setCookie(CLASSES_SOURCE_COOKIE, newSource, getCookieOptions());
    if (onChange) onChange(newSource);
  };

  const tabs = (
    <Tabs index={sources.indexOf(source)} onChange={(index) => setSource(sources[index])}>
      <TabList ariaLabel="Select class list">
        {/* Set aria-contols as undefined since there is no corresponding TabPanel */}
        <Tab aria-controls={undefined}>My classes</Tab>
        <Tab aria-controls={undefined}>All classes</Tab>
      </TabList>
    </Tabs>
  );

  return {
    source,
    tabs,
  };
}
