// @flow
import authCustomerTypes from 'src/constants/authCustomerTypes';

type Props = {|
  enforceDefaultName?: boolean,
  name: ?string,
  planType: string,
|};

export default function SubscriptionName(props: Props) {
  const { enforceDefaultName, name, planType } = props;

  if (!name) {
    return null;
  }

  if (!enforceDefaultName) {
    if (planType === authCustomerTypes.family) {
      return 'Family account';
    }
    if (planType === authCustomerTypes.student) {
      return 'Personal account';
    }
  }

  return name;
}
