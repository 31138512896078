// @flow
import { useRef } from 'react';
import _ from 'lodash';
import { Divider, Stack } from '@getatomi/neon';

import type { ContentTypeFilter, Module, QuestionTypeFilter, SessionLengthFilter } from './types';
import { contentTypes } from './constants';
import SelectContent from './SelectContent';
import SelectTopics from './SelectTopics';
import SelectSessionLength from './SelectSessionLength';
import SelectQuestionTypes from './SelectQuestionTypes';
import TimeLimitField from './TimeLimitField';

type SelectContentProps = {|
  contentError: ?string,
  contentFieldRef: $Call<typeof useRef>,
  contentType: ContentTypeFilter,
  setContentType: (newContentType: ContentTypeFilter) => void,
|};

type SelectTopicsProps = {|
  coveredTopics?: Array<string>,
  selectAllTopics: () => void,
  selectCoveredTopics?: () => void,
  selectedTopics: Array<string>,
  setSelectedTopics: (newSelectedTopics: Array<string>) => void,
  subjectName: string,
  subjectTree: $ReadOnlyArray<Module>,
|};

type SelectQuestionTypesProps = {|
  availableQuestionTypes: { [string]: boolean },
  questionType: Array<QuestionTypeFilter>,
  questionTypeError: ?string,
  questionTypesFieldRef: $Call<typeof useRef>,
  setQuestionType: (newQuestionType: Array<QuestionTypeFilter>) => void,
|};

type SelectSessionLengthProps = {|
  availableDurations: { [string]: boolean },
  sessionLength: SessionLengthFilter,
  setSessionLength: (newSessionLength: SessionLengthFilter) => void,
|};

type TimeLimitProps = {|
  isTimeLimitEnabled: boolean,
  setTimeLimitMinutes: (value: string) => void,
  timeLimitError: ?string,
  timeLimitFieldRef: $Call<typeof useRef>,
  timeLimitMinutes: string,
  toggleIsTimeLimitEnabled: () => void,
|};

type Props = {|
  clearValidationErrors: () => void,
  isTeacher: boolean,
  selectContentProps: SelectContentProps,
  selectQuestionTypesProps: SelectQuestionTypesProps,
  selectSessionLengthProps: SelectSessionLengthProps,
  selectTopicsProps: SelectTopicsProps,
  timeLimitProps: TimeLimitProps,
|};

export default function RevisionSelectors(props: Props) {
  const {
    clearValidationErrors,
    isTeacher,
    selectContentProps,
    selectTopicsProps,
    selectQuestionTypesProps,
    selectSessionLengthProps,
    timeLimitProps,
  } = props;

  const { contentType, contentError, setContentType, contentFieldRef } = selectContentProps;

  const {
    subjectName,
    subjectTree,
    selectAllTopics,
    selectCoveredTopics,
    coveredTopics,
    selectedTopics,
    setSelectedTopics,
  } = selectTopicsProps;

  const { availableQuestionTypes, questionType, setQuestionType, questionTypesFieldRef, questionTypeError } =
    selectQuestionTypesProps;

  const { availableDurations, sessionLength, setSessionLength } = selectSessionLengthProps;

  return (
    <Stack spacing="spacingLarge5X">
      <SelectContent
        contentError={contentError}
        hasCovered={typeof coveredTopics !== 'undefined' && coveredTopics.length > 0}
        isTeacher={isTeacher}
        value={contentType}
        onChange={(newContentType) => {
          clearValidationErrors();
          setContentType(newContentType);

          if (newContentType === 'covered' && selectCoveredTopics) {
            selectCoveredTopics();
          } else {
            selectAllTopics();
          }
        }}
        ref={contentFieldRef}
        testHook="revision-dialog-content-type"
      />

      {contentType === contentTypes.custom && (
        <SelectTopics
          subjectName={subjectName}
          subjectTree={subjectTree}
          selectedTopics={selectedTopics}
          onChange={(newSelectedTopics) => {
            clearValidationErrors();
            setSelectedTopics(newSelectedTopics);
          }}
          testHook="revision-dialog-selected-topics"
        />
      )}

      <Divider />

      <SelectQuestionTypes
        disabledValues={_.mapValues(availableQuestionTypes, (value) => !value)}
        value={questionType}
        onChange={(newQuestionType) => {
          clearValidationErrors();
          setQuestionType(newQuestionType);
        }}
        questionTypeError={questionTypeError}
        ref={questionTypesFieldRef}
        testHook="revision-dialog-question-types"
      />

      <Divider />

      <SelectSessionLength
        disabledValues={_.mapValues(availableDurations, (value) => !value)}
        isTeacher={isTeacher}
        value={sessionLength}
        onChange={setSessionLength}
      />

      <Divider />

      <TimeLimitField
        clearValidationErrors={clearValidationErrors}
        isTeacher={isTeacher}
        isTimeLimitEnabled={timeLimitProps.isTimeLimitEnabled}
        setTimeLimitMinutes={timeLimitProps.setTimeLimitMinutes}
        toggleIsTimeLimitEnabled={timeLimitProps.toggleIsTimeLimitEnabled}
        timeLimitError={timeLimitProps.timeLimitError}
        timeLimitFieldRef={timeLimitProps.timeLimitFieldRef}
        timeLimitMinutes={timeLimitProps.timeLimitMinutes}
      />
    </Stack>
  );
}
