// @flow
import { useMutation } from '@apollo/client';

import type {
  DuplicateClassRevision,
  DuplicateClassRevisionVariables,
} from 'src/graphql/types/generated/DuplicateClassRevision';
import type { AttachedContent } from 'src/domains/Tasks/types';
import { getRevisionUrl } from 'src/utils/routes';

import DUPLICATE_REVISION from './DuplicateRevision.graphql';

type Props = {
  body: ?string,
  classId: string,
  contents: $ReadOnlyArray<AttachedContent>,
  subscriptionId: string,
};

export default function useReplaceRevisions(props: Props): () => Promise<{
  body: ?string,
  contents: $ReadOnlyArray<AttachedContent>,
}> {
  const { body, classId, contents, subscriptionId } = props;
  const [duplicateRevision] = useMutation<DuplicateClassRevision, DuplicateClassRevisionVariables>(DUPLICATE_REVISION);

  function replaceRevisionInMarkdown(markdown: string, oldRevisionId: string, newRevisionId: string) {
    return markdown.replace(oldRevisionId, newRevisionId);
  }

  async function duplicateAndReplaceRevisions() {
    let newBody = body ?? '';
    const newContents = await Promise.all(
      contents.map(async (content) => {
        if (content.type === 'revision') {
          const { id } = content;
          try {
            const response = await duplicateRevision({
              variables: {
                input: {
                  classId,
                  id,
                },
              },
            });
            if (response.data) {
              const newRevisionId = response.data.duplicateClassRevision.id;
              newBody = replaceRevisionInMarkdown(newBody, id, newRevisionId);
              return {
                ...content,
                id: newRevisionId,
                url: getRevisionUrl(subscriptionId, classId, newRevisionId),
              };
            }
            // Failed to duplicate revision, removing old record
            return null;
          } catch (error) {
            return null;
          }
        }
        return content;
      })
    );

    return {
      body: newBody,
      contents: newContents.filter(Boolean),
    };
  }

  return duplicateAndReplaceRevisions;
}
