// @flow
import keyMirror from 'keymirror';

export default keyMirror({
  AcceptedInviteException: null,
  ExpiredAccessTokenException: null,
  ExpiredTokenException: null,
  InvalidInviteTokenException: null,
  InvalidRoleException: null,
  LegacyLoginFailException: null,
  LegacyUserInactiveException: null,
  LegacyUserPasswordResetFailException: null,
  LoginFailException: null,
  PasswordResetFailureException: null,
  RoleCollisionException: null,
  SocialLoginFailException: null,
  SubscriptionInvalidStatusException: null,
  SubscriptionMismatchException: null,
  UserInactiveException: null,
  UserHasNoSubscriptionsException: null,
  PasswordResetRequestException: null,
  SAMLPasswordRequestException: null,
  PasswordNotSetRequestException: null,
  SamlLoginRequiredException: null,
});
