// @flow

import { getRevisionFirstItemUrl } from 'src/utils/routes';
import { useNavigationContext } from 'src/hooks/useNavigationContext';
import { ChallengeStart } from 'src/components/ChallengeStart/ChallengeStart';

import type { Subject } from '../useGetRevision';
import { makeBackLinkProps } from '../makeBackLinkProps';
import { RevisionChallengeLayout } from '../RevisionChallengeLayout';
import { RevisionChallengeBadges } from '../RevisionChallengeBadges';

export type Props = {|
  accountId: string,
  challengeId: string,
  classId: string,
  duration: number,
  questionsCount: number,
  subject: Subject,
  timeLimitInSeconds?: number,
  title: string,
  totalMarks: number,
|};

export default function RevisionChallengeStart({
  accountId,
  challengeId,
  classId,
  duration,
  questionsCount,
  subject,
  timeLimitInSeconds,
  title,
  totalMarks,
}: Props): React.Node {
  const [navigationContext] = useNavigationContext();

  const backLinkProps = navigationContext ? makeBackLinkProps(navigationContext) : null;

  const challengeFirstItemUrl = getRevisionFirstItemUrl({
    accountId,
    classId,
    challengeId,
    navigationContext: navigationContext ?? null,
  });

  return (
    <RevisionChallengeLayout backLinkProps={backLinkProps}>
      <ChallengeStart
        secondaryActions={null}
        challengeFirstItemUrl={challengeFirstItemUrl}
        duration={duration}
        badges={<RevisionChallengeBadges subject={subject} />}
        questionsCount={questionsCount}
        tier="TIER_4_REVISION"
        timeLimitInSeconds={timeLimitInSeconds}
        title={title}
        totalMarks={totalMarks}
      />
    </RevisionChallengeLayout>
  );
}
