// @flow
import { Badge, Box, Button, Card, Heading, HideVisually, ProgressBar, Stack, Text } from '@getatomi/neon';

import { UnstyledLink } from 'src/components/Link/Link';
import { getClassModuleUrl } from 'src/utils/routes';
import getSubjectColors from 'src/utils/getSubjectColors';

import type { Module, Subject } from '../useGetClassSubject/useGetClassSubject';

type Props = {|
  classId: string,
  modules: $ReadOnlyArray<Module>,
  subject: Subject,
  subscriptionId: string,
|};

const tooltips = {
  elective: `An elective is an optional course of study, chosen by your school. Check with your teacher which electives you'll be covering this year!`,
  option: `This is an optional course of study, chosen by your school. Check with your teacher which options you'll be covering this year!`,
};

const cardConnector = {
  _after: {
    borderColor: 'colorBorder',
    borderStyle: 'solid',
    borderWidth: 'borderWidthNone',
    borderLeftWidth: 'borderWidthRoot',
    content: '""',
    display: 'block',
    left: '50%',
    paddingTop: 'spacingLarge2X',
    position: 'absolute',
    top: '100%',
  },
};

export default function SubjectModules(props: Props) {
  const { classId, modules, subject, subscriptionId } = props;
  const subjectColorRange = getSubjectColors(subject.groupCode, subject.color);
  const testHook = 'subject-modules';

  return (
    <ul data-test={testHook}>
      {modules.map((module, i) => {
        const isModuleComingSoon = module.kind === 'COMING_SOON';
        return (
          <Box
            as="li"
            key={i}
            marginBottom="spacingLarge2X"
            position="relative"
            {...(i + 1 < modules.length ? cardConnector : {})}
            testHook={testHook && `${testHook}-${i}`}
          >
            <Box
              as={isModuleComingSoon ? 'div' : UnstyledLink}
              to={isModuleComingSoon ? undefined : getClassModuleUrl(subscriptionId, classId, module.id)}
              display="block"
            >
              <Card withHover={!isModuleComingSoon}>
                <Box
                  color="colorText"
                  padding={{ base: 'spacingRoot', tablet: 'spacingLarge1X' }}
                  paddingTop={{ base: 'spacingSmall', tablet: 'spacingRoot' }}
                >
                  <Stack spacing="spacingSmall1X">
                    {module.labels.length > 0 && (
                      <ul>
                        {module.labels.map((moduleLabel) => (
                          <Box as="li" key={moduleLabel} display="inline" marginRight="spacingSmall1X">
                            <Badge
                              variant={isModuleComingSoon ? 'dashed' : 'chunky'}
                              tooltip={tooltips[moduleLabel.toLowerCase()]}
                            >
                              {moduleLabel}
                            </Badge>
                          </Box>
                        ))}
                      </ul>
                    )}

                    <Heading as="h2" variant="small">
                      {module.name}
                      {/* https://www.filamentgroup.com/lab/a11y-links.html#use-punctuation */}
                      <HideVisually>.</HideVisually>
                    </Heading>

                    {module.kind !== 'COMING_SOON' && (
                      <Box>
                        <Box marginBottom="spacingLarge1X">
                          <Text color="colorTextSubtler" testHook={testHook && `${testHook}-text`}>
                            {module.contentCountLabel}
                            <HideVisually>.</HideVisually>
                          </Text>

                          {module.isPartiallyComplete && module.completionRate && (
                            // the progress summary is read as part of the "text"
                            // above (e.g. "1 of 21 lessons") making the progress bar
                            // redundant when read by a screen reader */}
                            <Box paddingTop="spacingSmall2X" aria-hidden>
                              <ProgressBar
                                ariaLabel="Progress on this module"
                                value={module.completionRate}
                                max={1}
                                variant="chunky"
                                color={subjectColorRange.chart}
                              />
                            </Box>
                          )}
                        </Box>

                        <Button
                          as="span"
                          size="small"
                          variant="tertiary"
                          testHook={testHook && `${testHook}-button`}
                          aria-hidden
                        >
                          {module.isPartiallyComplete ? 'Continue' : 'View'}
                        </Button>

                        {module.isPartiallyComplete && module.timeRemaining && (
                          <Box display={{ base: 'none', tablet: 'inline-block' }} marginLeft="spacingSmall">
                            <Text
                              as="span"
                              fontSize="fontSizeSmall1X"
                              color="colorTextSubtler"
                              testHook={testHook && `${testHook}-time-remaining`}
                            >
                              {module.timeRemaining}
                            </Text>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Stack>
                </Box>
              </Card>
            </Box>
          </Box>
        );
      })}
    </ul>
  );
}
