// @flow
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Container, EmptyState, Illustration } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import type { ReduxState } from 'src/types';
import Link from 'src/components/Link/Link';
import RequestCallback from 'src/components/RequestCallback/RequestCallback';
import {
  getActiveSubscriptionId,
  getUserRole,
  isSchoolPlan as isSchoolPlanSelector,
  isAdminOrAbove as isAdminOrAboveSelector,
} from 'src/reducers/subscriptions';
import { getBillingUrl } from 'src/utils/routes';
import links from 'src/constants/links';
import userRoles from 'src/constants/userRoles';

type Props = {
  isAdminOrAbove: boolean,
  isSchoolPlan: boolean,
  subscriptionId: number,
  userRole: string,
};

const mapStateToProps = (state: ReduxState) => ({
  isSchoolPlan: isSchoolPlanSelector(state),
  subscriptionId: getActiveSubscriptionId(state),
  userRole: getUserRole(state),
  isAdminOrAbove: isAdminOrAboveSelector(state),
});

function Expired(props: Props) {
  const { isSchoolPlan, subscriptionId, userRole, isAdminOrAbove } = props;
  const isRetailStudent = !isSchoolPlan && userRole === userRoles.student;

  let description = '';
  if (isAdminOrAbove) {
    description =
      'Want to keep using Atomi? Our school partnership team will be in touch to discuss the next steps. Have any questions in the meantime?';
  } else if (userRole === userRoles.teacher) {
    description = 'If you’d like to keep using Atomi let your school know you loved it!';
  } else if (isSchoolPlan && userRole === userRoles.student) {
    description = 'Want to keep using Atomi? Let your teachers know how much you loved it!';
  } else if (isRetailStudent) {
    description =
      'You’ll need to reactivate your subscription to get full access to all content, anytime, and on any device.';
  }

  let cta;
  if (isRetailStudent) {
    cta = (
      <Button size="large" to={getBillingUrl(subscriptionId)} testHook="expired-settings-button">
        Go to settings
      </Button>
    );
  } else if (isAdminOrAbove) {
    cta = (
      <RequestCallback isGeneralRequest>
        {(onClick) => (
          <Button size="large" onClick={onClick} testHook="expired-contact-us-button">
            Contact us
          </Button>
        )}
      </RequestCallback>
    );
  }

  return (
    <>
      <Helmet>
        <title>Your account has expired</title>
      </Helmet>
      <Container maxWidth="sizeContainerRoot">
        <EmptyState
          media={<Illustration name={isSchoolPlan ? 'main-characters' : 'main-payment'} />}
          heading="Looks like your account has expired."
          headingProps={{ as: 'h1' }}
          description={description}
          primaryAction={cta}
          helpInfo={
            isRetailStudent ? (
              <Link href={links.support.upgrading.school} isExternal variant="monochrome">
                Learn more about upgrading in the Help Centre.
              </Link>
            ) : undefined
          }
        />
      </Container>
    </>
  );
}

export default (connect(mapStateToProps)(Expired): React.AbstractComponent<Props>);
