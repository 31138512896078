// @flow
import { Box, Container, EmptyState, Illustration, KebabList } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import ButtonAdd from 'src/components/ButtonAdd/ButtonAdd';
import Link from 'src/components/Link/Link';

type Props = {
  buttonAddTask: React.Element<typeof ButtonAdd>,
  filters: React.Element<*>,
  helpInfo: React.Element<typeof Link>,
  onClearFilters: () => mixed,
  openTaskDialog: () => void,
  totalTaskCount: number,
};

export default function ClassTasksListEmptyState(props: Props) {
  const { filters, buttonAddTask, totalTaskCount, onClearFilters, openTaskDialog, helpInfo } = props;

  if (totalTaskCount === 0) {
    return (
      <Container>
        <EmptyState
          media={<Illustration name="emptystate-tasks" />}
          heading="You have not created any tasks yet."
          description="When you create a new task or start assigning lessons as tasks, they’ll show up here!"
          primaryAction={<Button onClick={openTaskDialog}>Create task</Button>}
          helpInfo={helpInfo}
        />
      </Container>
    );
  }

  return (
    <KebabList filters={filters} cta={buttonAddTask}>
      <KebabList.Empty>
        <Box marginTop="spacingLarge3X">
          <EmptyState
            media={<Illustration name="emptystate-tasks" />}
            description="There are no results for your selection."
            primaryAction={
              <Button variant="text" onClick={onClearFilters}>
                Clear all filters
              </Button>
            }
          />
        </Box>
      </KebabList.Empty>
    </KebabList>
  );
}
