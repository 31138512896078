import scrollTo from './scrollTo';

export const ADJUSTMENT = -100;

// A failed form submission will focus the first invalid element, so all we need to do is scroll up
// to it. If there is a general API error, and not one particular field that is invalid, we simply
// scroll to the top
export default function scrollToActiveElement() {
  setTimeout(() => {
    if (document.activeElement === document.body) {
      scrollTo(0, 0);
    } else {
      scrollTo(document.activeElement, ADJUSTMENT);
    }
  }, 0);
}
