// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetFindContentDialog,
  GetFindContentDialogVariables,
} from 'src/graphql/types/generated/GetFindContentDialog';
import { getPostUrl } from 'src/utils/routes';

import GET_FIND_CONTENT_DIALOG from './GetFindContentDialog.graphql';

type Props = {|
  classId: string,
  subscriptionId: string,
|};

type OutputData = {|
  getLessonUrl: (args: {| lessonId: string, moduleId: string |}) => string,
  isFreePlan: boolean,
  isRetailPlan: boolean,
  isSchoolPlan: boolean,
  isStudent: boolean,
  subjectCode: string,
  subjectName: string,
  yearLevelId?: string,
  yearLevelName?: string,
|};

type Output = {|
  data: ?OutputData,
  error: ?ApolloError,
  loading: boolean,
  refetch: () => mixed,
|};

export default function useGetFindContentDialog(props: Props): Output {
  const { subscriptionId, classId } = props;

  const { data, error, loading, refetch } = useQuery<GetFindContentDialog, GetFindContentDialogVariables>(
    GET_FIND_CONTENT_DIALOG,
    {
      variables: {
        accountId: subscriptionId,
        classId,
      },
    }
  );

  if (!data && loading) {
    return {
      loading: true,
      error: null,
      data: null,
      refetch,
    };
  }

  if (error) {
    return {
      error,
      loading: false,
      data: null,
      refetch,
    };
  }

  const accountData = data?.me?.account;
  const classData = accountData?.class;
  const subjectData = classData?.subject;
  const levelData = classData?.level;

  invariant(accountData && subjectData, 'Account and subject data should be defined');

  const getLessonUrl = ({ moduleId, lessonId }: {| lessonId: string, moduleId: string |}): string => {
    return getPostUrl(subscriptionId, classId, moduleId, lessonId);
  };

  const transformedData = {
    isFreePlan: accountData.plan.isFree,
    isRetailPlan:
      accountData.plan.customerType === 'RETAIL_FAMILY' || accountData.plan.customerType === 'RETAIL_STUDENT',
    isSchoolPlan: accountData.plan.customerType === 'SCHOOL',
    isStudent: accountData.role === 'STUDENT',
    subjectCode: subjectData.code,
    subjectName: subjectData.shortName,
    yearLevelId: levelData?.id,
    yearLevelName: levelData?.name,
    getLessonUrl,
  };

  return {
    data: transformedData,
    error: null,
    loading: false,
    refetch,
  };
}
