// @flow
import { Box, Card, Flex, Heading, Stack, Text } from '@getatomi/neon';

import { UnstyledLink } from 'src/components/Link/Link';
import PostDuration from 'src/components/PostDuration/PostDuration';
import PostIcon from 'src/components/PostIcon/PostIcon';
import postTypes from 'src/constants/postTypes';
import { getChallengeProgressReportUrl, getPostUrl } from 'src/utils/routes';
import useProgressReportDialog from 'src/hooks/components/useProgressReportDialog';

import type { MostEngagedContentType } from './topicInsightsTransformer';

type ContentCardProps = {
  icon: React.Node,
  lessonLink: string,
  primaryLabel: string,
  secondaryLabel: React.Node,
  trailingContent: React.Node,
};

type ContentListProps = {
  contentList: $ReadOnlyArray<ContentCardProps>,
};

type MostEngagedContentProps = {
  accountId: string,
  classId: string,
  mostEngagedContent: $ReadOnlyArray<MostEngagedContentType>,
};

function PrimaryLabel(props: { label: React.Node }) {
  const { label } = props;

  return (
    <Text
      as="p"
      display={{ base: 'block', tablet: 'inline' }}
      fontSize="fontSizeSmall"
      lineHeight="lineHeightSmall2X"
      marginRight={{ tablet: 'spacingSmall1X' }}
      paddingBottom="spacingSmall3X"
    >
      {label}
    </Text>
  );
}

function SecondaryLabel(props: { label: React.Node }) {
  const { label } = props;

  return (
    <Text
      as="span"
      color="colorTextSubtler"
      display={{ base: 'block', tablet: 'inline' }}
      fontFamily="fontFamilySystem"
      fontSize={{ base: 'fontSizeSmall3X', tablet: 'fontSizeSmall2X' }}
      letterSpacing={{ base: 'letterSpacingSmall1X', tablet: 'letterSpacingNone' }}
      lineHeight="lineHeightSmall2X"
    >
      {label}
    </Text>
  );
}

function TrailingContent(props: {
  accountId: string,
  classId: string,
  item: MostEngagedContentType,
  openProgressDialog: () => void,
}) {
  const { accountId, classId, item, openProgressDialog } = props;
  const { content, stats } = item;

  if (!stats.length) {
    return null;
  }

  const shouldRenderVqr = content.type === postTypes.challenge;
  const shouldRenderProgressDialog = content.type === postTypes.text || content.type === postTypes.video;

  const formattedStats = stats.join(' • ');

  if (shouldRenderVqr) {
    return (
      <Text
        as={UnstyledLink}
        to={getChallengeProgressReportUrl(accountId, classId, content.moduleId, content.lessonId)}
        color="colorTextSubtle"
        variant="bodySmall"
        _hoverAndFocus={{
          textDecoration: 'underline',
        }}
      >
        {formattedStats}
      </Text>
    );
  }

  if (shouldRenderProgressDialog) {
    return (
      <Text
        as="button"
        color="colorTextSubtle"
        onClick={() => openProgressDialog()}
        type="button"
        variant="bodySmall"
        _hoverAndFocus={{
          textDecoration: 'underline',
        }}
      >
        {formattedStats}
      </Text>
    );
  }

  return null;
}

function ContentCard(props: ContentCardProps) {
  const { icon, lessonLink, primaryLabel, secondaryLabel, trailingContent } = props;

  return (
    <Card padding="spacingRoot">
      <Flex alignItems="center" gap="spacingRoot" justifyContent="space-between">
        <Flex as={UnstyledLink} to={lessonLink} alignItems="center" flex={1} gap="spacingSmall">
          {icon}
          <Box lineHeight="lineHeightSmall2X" paddingBottom={{ tablet: 'spacingSmall2X' }}>
            <PrimaryLabel label={primaryLabel} />
            <SecondaryLabel label={secondaryLabel} />
          </Box>
        </Flex>

        <Box flexShrink={0}>{trailingContent}</Box>
      </Flex>
    </Card>
  );
}

function ContentList(props: ContentListProps) {
  const { contentList } = props;

  return (
    <Stack as="ol" spacing="spacingNegativeSmall4X">
      {contentList.map((content, index) => (
        <ContentCard key={index} {...content} />
      ))}
    </Stack>
  );
}

export default function MostEngagedContent(props: MostEngagedContentProps) {
  const { accountId, classId, mostEngagedContent } = props;

  const [progressReportDialog, { openProgressReportDialog }] = useProgressReportDialog({
    subscriptionId: accountId,
    classId,
  });

  const contentList = mostEngagedContent.map((item) => {
    const { content } = item;
    const lessonLink = getPostUrl(accountId, classId, content.moduleId, content.lessonId);
    const primaryLabel = content.name;
    const secondaryLabel = (
      <PostDuration challengeTier={content.challengeTier} duration={content.duration} type={content.type} />
    );

    return {
      icon: (
        <PostIcon
          postType={content.type}
          challengeTier={content.challengeTier}
          color="colorIconBrand"
          size={{ base: 'sizeIconSmall', tablet: 'sizeIconRoot' }}
        />
      ),
      lessonLink,
      primaryLabel,
      secondaryLabel,
      trailingContent: (
        <TrailingContent
          accountId={accountId}
          classId={classId}
          item={item}
          openProgressDialog={() =>
            openProgressReportDialog({
              post: { id: content.lessonId, name: content.name, type: content.type },
              moduleId: content.moduleId,
            })
          }
        />
      ),
    };
  });

  return (
    <Stack as="section" spacing="spacingLarge5X">
      <Heading as="h3" variant="large">
        Most engaged content
      </Heading>

      <ContentList contentList={contentList} />
      {progressReportDialog}
    </Stack>
  );
}
