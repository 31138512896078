// @flow
import { useState } from 'react';
import { HideVisually, TextLoader } from '@getatomi/neon';

import { trackingCtaTypes, trackingEvents, trackingCtas } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';

import ProgressCell from '../components/ProgressCell';
import RowMetaCell from '../components/RowMetaCell';
import ColumnHeaderCell, { ItemCountSubContent } from '../components/ColumnHeaderCell';
import type { Module, ClassAverage, CompletionData, SubjectCompletionCellValue } from './completionTransformer';
import type { Student } from '../utils/prepareStudentData';

type Cell<T> = {
  // False positive - Even if Cell() gets called, the props that are only used inside the component
  // are not be considered "used" by Cell().
  // eg: `cell: ({ value }: Cell<MarksQuestion>)`
  // https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/no-unused-prop-types.md#known-issueslimitations
  // eslint-disable-next-line
  getValue: () => T,
  row: {
    original: CompletionData,
  },
};

type ColumnMeta = {
  headerTemplate?: (headerContent: React.Node) => React.Node,
  isHeading?: boolean,
  width?: number,
};

export type Column = {
  accessorKey: string,
  cell: (cell: Cell<any>) => React.Node,
  columns?: Array<Column>,
  enableSorting?: boolean,
  header: () => React.Node,
  meta?: ColumnMeta,
};

const loadingCell = ({ row }) => {
  // $FlowIgnore index is defined by react-table
  return <TextLoader maxWidth={row.index % 2 ? '50%' : 'sizeFull'} testHook="loading-cell" />;
};

const renderCompletionCell = ({ getValue }: Cell<SubjectCompletionCellValue>) => {
  const completion = getValue()?.displayValue;

  if (!completion) {
    return 'Not started';
  }

  return <ProgressCell percentageValue={completion} type="lesson" />;
};

export function prepareCompletionColumns({
  isMobile,
  columnList,
  lessonCount,
  expandedModuleState,
  classId,
}: {|
  classId: string,
  columnList: $ReadOnlyArray<Module>,
  expandedModuleState: $Call<typeof useState>,
  isMobile: boolean,
  lessonCount: number,
|}): $ReadOnlyArray<Column> {
  const [expandedModuleId, setExpandedModule] = expandedModuleState;
  return [
    {
      header: () => 'Students',
      accessorKey: 'rowMeta',
      cell: ({ getValue }: Cell<Student | ClassAverage>) => <RowMetaCell value={getValue()} isMobile={isMobile} />,
      sortDescFirst: false,
      meta: {
        isHeading: true,
      },
    },
    {
      header: () => 'Overall completion',
      accessorKey: 'column-overall',
      cell: renderCompletionCell,
      meta: {
        headerTemplate: (headerContent: React.Node) => (
          <ColumnHeaderCell
            headerContent={headerContent}
            headerSubContent={<ItemCountSubContent itemCount={lessonCount} itemLabel="Lesson" />}
          />
        ),
      },
    },
    ...columnList
      .filter((module) => module.lessonCount > 0)
      .map((module) => {
        const isExpanded = expandedModuleId === module.id;
        return {
          accessorKey: `column-${module.id}`,
          enableSorting: !isExpanded,
          header: () => module.name,
          cell: renderCompletionCell,
          meta: {
            headerTemplate: (headerContent: React.Node) => {
              return (
                <ColumnHeaderCell
                  headerContent={headerContent}
                  headerSubContent={<ItemCountSubContent itemCount={module.lessonCount} itemLabel="Lesson" />}
                  isExpanded={isExpanded}
                  onExpand={module.categoryCount ? () => setExpandedModule(isExpanded ? null : module.id) : undefined}
                  links={[
                    {
                      key: 'view-module',
                      name: 'View module',
                      // $FlowIgnore - we know moduleUrl is defined for module columns
                      href: module.moduleUrl,
                    },
                  ]}
                  onLinkAction={(key: string) => {
                    if (key === 'view-module') {
                      trackEvent(trackingEvents.ctaClicked, {
                        cta: trackingCtas.viewModule,
                        type: trackingCtaTypes.button,
                        classId,
                      });
                    }
                  }}
                  trackingData={{
                    cta: trackingCtas.expandColumnModule,
                    data: { classId },
                  }}
                />
              );
            },
          },
          columns:
            module.topics &&
            module.topics
              .filter((topic) => {
                const loading = !topic.name;

                return loading || topic.lessonCount > 0;
              })
              .map((topic) => {
                const loading = !topic.name;

                return {
                  header: () => topic.name,
                  accessorKey: `column-${topic.id}`,
                  cell: loading ? loadingCell : renderCompletionCell,
                  meta: {
                    headerTemplate: (headerContent: React.Node) => {
                      if (loading) {
                        return <HideVisually>Loading…</HideVisually>;
                      }
                      return (
                        <ColumnHeaderCell
                          headerContent={headerContent}
                          headerSubContent={<ItemCountSubContent itemCount={topic.lessonCount} itemLabel="Lesson" />}
                          links={[{ key: 'view-insights', name: 'View insights', href: topic.insightsUrl }]}
                          onLinkAction={(key: string) => {
                            if (key === 'view-insights') {
                              trackEvent(trackingEvents.ctaClicked, {
                                classId,
                                cta: trackingCtas.viewTopicInsights,
                                type: trackingCtaTypes.button,
                              });
                            }
                          }}
                        />
                      );
                    },
                  },
                };
              }),
        };
      }),
  ];
}

export default prepareCompletionColumns;
