// @flow
import { connect } from 'react-redux';
import { Box } from '@getatomi/neon';

import { getRenewalEstimate } from 'src/reducers/estimates';
import { getActiveSubscriptionPlan, getPlanCurrency } from 'src/reducers/plans';
import type { CurrencyType } from 'src/types';

import BillingEstimate from './BillingEstimate/BillingEstimate';

type SchoolBillingEstimateProps = {|
  currency: CurrencyType,
  estimate: ?Object,
  plan: Object,
|};

const mapStateToProps = (state): SchoolBillingEstimateProps => ({
  currency: getPlanCurrency(state),
  estimate: getRenewalEstimate(state),
  plan: getActiveSubscriptionPlan(state),
});

function SchoolBillingEstimate(props: SchoolBillingEstimateProps) {
  const { currency, estimate, plan } = props;

  if (!estimate || !plan || !currency) return null;

  return (
    <Box maxWidth="sizeContainerSmall">
      <BillingEstimate currency={currency} estimate={estimate} plan={plan} />
    </Box>
  );
}

export default (connect(mapStateToProps)(SchoolBillingEstimate): React.AbstractComponent<{}>);
