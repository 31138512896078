// @flow
import { MultiSelect as NeonMultiSelect } from '@getatomi/neon';

import useIntercomWidget from 'src/hooks/useIntercomWidget';

export type MultiSelectProps = {|
  ariaLabel: string,
  checkAllLabel: React.Node,
  doneLabel: string,
  id?: string,
  isDisabled?: string,
  onChange: (selectedOptions: $ReadOnlyArray<any>) => void,
  onClose?: (selectedOptions: $ReadOnlyArray<any>) => void,
  onOpenChange?: (isOpen: boolean, isTray: boolean) => void,
  options: $ReadOnlyArray<any>,
  searchEmptyState: string,
  searchPlaceholder: string,
  testHook?: string,
  trigger: React.Node,
  uncheckAllLabel: string,
  variant?: 'compact',
|};

export default function MultiSelect(props: MultiSelectProps) {
  const { onClose, onOpenChange, ...otherProps } = props;
  const [showIntercom, hideIntercom] = useIntercomWidget({ showOnUnmount: true });

  return (
    <NeonMultiSelect
      onOpenChange={(isOpen, isTray) => {
        if (isTray) {
          if (isOpen) {
            hideIntercom();
          } else {
            showIntercom();
          }
        }
        if (onOpenChange) {
          onOpenChange(isOpen, isTray);
        }
      }}
      onClose={(selectedOptions) => {
        if (onClose) {
          onClose(selectedOptions);
        }
      }}
      {...otherProps}
    />
  );
}

MultiSelect.Trigger = NeonMultiSelect.Trigger;
