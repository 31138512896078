// @flow
import { Alert } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import { getSettingsUrl } from 'src/utils/routes';

type Props = {
  subscriptionId: number,
};

export default function MissingProfileDataAlert(props: Props) {
  const { subscriptionId } = props;

  return (
    <Alert isBanner variant="danger" testHook="alert-missing-profile-data">
      Looks like we’re missing your first and last name information.{' '}
      <Link variant="monochrome" testHook="alert-missing-profile-data-link" to={getSettingsUrl(subscriptionId)}>
        Please click here to update your profile.
      </Link>
    </Alert>
  );
}
