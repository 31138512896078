// @flow
import {
  Alert,
  AttachmentList,
  IconAttachment,
  IconFileDoc,
  IconFileEps,
  IconFileGif,
  IconFileJpg,
  IconFileMov,
  IconFileMp3,
  IconFilePdf,
  IconFilePng,
  IconFilePpt,
  IconFilePsd,
  IconFileRaw,
  IconFileSvg,
  IconFileTif,
  IconFileTxt,
  IconFileWmv,
  IconFileXls,
  IconFileZip,
  IconLink,
  Stack,
} from '@getatomi/neon';
import pluralize from 'pluralize';

import type { TaskAttachment } from 'src/domains/Tasks/types';
import { UnstyledLink } from 'src/components/Link/Link';
import { getFileExtension, getSimilarExtension } from 'src/utils/file';
import { taskAttachmentTypes } from 'src/constants/taskAttachmentTypes';
import useAlertDialog from 'src/hooks/components/useAlertDialog';

type TaskAttachmentSupertype = $ReadOnly<TaskAttachment>;

const iconsMapping = {
  doc: IconFileDoc,
  eps: IconFileEps,
  gif: IconFileGif,
  jpg: IconFileJpg,
  mov: IconFileMov,
  mp3: IconFileMp3,
  pdf: IconFilePdf,
  png: IconFilePng,
  ppt: IconFilePpt,
  psd: IconFilePsd,
  raw: IconFileRaw,
  svg: IconFileSvg,
  tif: IconFileTif,
  txt: IconFileTxt,
  wmv: IconFileWmv,
  xls: IconFileXls,
  zip: IconFileZip,
};

function ExternalContentIcon(props: {| fileExtension: string |}) {
  const { fileExtension } = props;
  const Icon = iconsMapping[fileExtension];
  const size = { base: 'sizeIconSmall', tablet: 'sizeIconRoot' };

  return Icon ? <Icon color="colorIcon" size={size} /> : <IconAttachment color="colorIcon" size={size} />;
}

export function externalContent(attachment: TaskAttachmentSupertype, onClick: () => mixed, testHook?: string) {
  let icon = <IconLink color="colorIcon" />;
  let meta = 'Link';
  let name = attachment.url;

  if (attachment.type === taskAttachmentTypes.FILE && attachment.fileName) {
    const fileExtension = getSimilarExtension(getFileExtension(attachment.fileName));
    icon = <ExternalContentIcon fileExtension={fileExtension} />;
    meta = fileExtension.toUpperCase();
    name = attachment.fileName;
  }

  const linkProps = attachment.isInfected
    ? { as: UnstyledLink, onClick }
    : { as: UnstyledLink, href: attachment.url, isExternal: true };

  return (
    <AttachmentList.ExternalContent
      icon={icon}
      linkProps={linkProps}
      name={name}
      meta={meta}
      testHook={testHook}
      isDisabled={attachment.isInfected}
    />
  );
}

function getAlertMessage(attachments: $ReadOnlyArray<TaskAttachmentSupertype>) {
  const infectedFiles = attachments.filter((attachment) => attachment.isInfected);
  if (infectedFiles.length === 0) return;

  return (
    <>
      The {pluralize('file', infectedFiles.length)}{' '}
      {infectedFiles.map((attachment, i) => (
        <strong key={i}>
          {i > 0 && ', '}
          {attachment.fileName}
        </strong>
      ))}{' '}
      did not pass the virus security scan and can’t be downloaded.
    </>
  );
}

type Props = {
  attachments: $ReadOnlyArray<TaskAttachmentSupertype>,
};

export default function TaskAttachmentList(props: Props) {
  const { attachments } = props;
  const alertMessage = getAlertMessage(attachments);
  const [infectedPrompt, showInfectedPrompt] = useAlertDialog({
    closeOnConfirm: true,
    heading: 'This file did not pass the virus security scan and can’t be downloaded.',
    variant: 'danger',
    onConfirmLabel: 'Ok, thanks',
    isDismissable: false,
  });

  return (
    <>
      <Stack>
        {alertMessage && <Alert variant="danger">{alertMessage}</Alert>}
        <AttachmentList>
          {attachments.map((attachment, i) =>
            attachment.isUploading ? (
              <AttachmentList.Attachment key={`loader-${i}`} isFullWidth={attachments.length === 1}>
                <AttachmentList.Loader />
              </AttachmentList.Attachment>
            ) : (
              <AttachmentList.Attachment
                key={`attachment-${attachment.externalId}`}
                removeAssistiveText="Remove attachment"
                testHook={`task-attachment-${i}`}
                isFullWidth={attachments.length === 1}
              >
                {externalContent(attachment, showInfectedPrompt, `task-attachment-${i}-external-content`)}
              </AttachmentList.Attachment>
            )
          )}
        </AttachmentList>
      </Stack>
      {infectedPrompt}
    </>
  );
}
