// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetAccountUsage,
  GetAccountUsageVariables,
  GetAccountUsage_me_account_billing_addons as GqlAddon,
  GetAccountUsage_me_account_metrics_usage_perLevel as GqlLevel,
  GetAccountUsage_me_account_metrics_usage_perLevelAndSubject as GqlSubject,
} from 'src/graphql/types/generated/GetAccountUsage';
import createCellValue, { type CellValue } from 'src/utils/createCellValue';
import sortYearLevels from 'src/utils/sortYearLevels';

import GET_ACCOUNT_USAGE from './GetAccountUsage.graphql';

type Props = {|
  accountId: string,
|};

export type YearLevel = {|
  id: string,
  name: CellValue<string, number>,
  purchasedSeats: CellValue<string, number>,
  usedSeats: CellValue<number, number>,
  utilisation: CellValue<string, number>,
|};

export type Subject = {|
  id: string,
  levelName: string,
  name: CellValue<string, string>,
  purchasedSeats: CellValue<string, number>,
  usedSeats: CellValue<number, number>,
  utilisation: CellValue<string, number>,
|};

type TransformedData = {|
  accountType: string,
  subjectFilters: $ReadOnlyArray<string>,
  subjects: $ReadOnlyArray<Subject>,
  yearLevels: $ReadOnlyArray<YearLevel>,
|};

export type Output = {|
  data: TransformedData | null,
  error: ?ApolloError,
  loading: boolean,
|};

function isLegacyPlan(addons: $ReadOnlyArray<GqlAddon>): boolean {
  // A legacy plan has no add-ons
  return !addons.length;
}

function isAllSubjectsPlan(addons: $ReadOnlyArray<GqlAddon>): boolean {
  return addons.every((addon) => addon.__typename === 'AccountBillingYearLevelAddon');
}

function isPerSubjectPlan(addons: $ReadOnlyArray<GqlAddon>): boolean {
  return addons.every((addon) => addon.__typename === 'AccountBillingSubjectAddon');
}

function getPerLevelDeal(purchasedAddons: $ReadOnlyArray<GqlAddon>, levelId: string): ?GqlAddon {
  return purchasedAddons.find(
    (addon) => addon.__typename === 'AccountBillingYearLevelAddon' && addon.level.id === levelId
  );
}

function getPerSubjectDeal(
  purchasedAddons: $ReadOnlyArray<GqlAddon>,
  levelId: string,
  subjectCode?: string
): ?GqlAddon {
  return purchasedAddons.find(
    (addon) =>
      addon.__typename === 'AccountBillingSubjectAddon' &&
      addon.level.id === levelId &&
      (subjectCode ? subjectCode === addon.subject.code : true)
  );
}

function getAccountType(addons: $ReadOnlyArray<GqlAddon>, regionCode: string): string {
  if (isLegacyPlan(addons) || isAllSubjectsPlan(addons)) {
    return `All subjects (${regionCode})`;
  }
  if (isPerSubjectPlan(addons)) {
    return `Per subject (${regionCode})`;
  }
  return `Mixed licenses (${regionCode})`;
}

function getPurchasedSeatsPerLevel(
  levelId: string,
  purchasedAddons: $ReadOnlyArray<GqlAddon>
): {
  purchasedSeatsLabel: string,
  purchasedSeatsValue: number,
} {
  const perLevelDeal = getPerLevelDeal(purchasedAddons, levelId);
  if (perLevelDeal) {
    return {
      purchasedSeatsLabel: String(perLevelDeal.quantityPurchased),
      purchasedSeatsValue: perLevelDeal.quantityPurchased,
    };
  }

  const perSubjectDeal = getPerSubjectDeal(purchasedAddons, levelId);
  if (perSubjectDeal) {
    return {
      purchasedSeatsLabel: 'Per subject',
      purchasedSeatsValue: Infinity,
    };
  }

  return {
    purchasedSeatsLabel: '-',
    purchasedSeatsValue: 0,
  };
}

function getPurchasedSeatsPerSubject({
  levelId,
  levelName,
  purchasedAddons,
  subjectCode,
}: {
  levelId: string,
  levelName: string,
  purchasedAddons: $ReadOnlyArray<GqlAddon>,
  subjectCode: string,
}): {
  purchasedSeatsLabel: string,
  purchasedSeatsValue: number,
} {
  const subjectDeal = getPerSubjectDeal(purchasedAddons, levelId, subjectCode);
  if (subjectDeal) {
    return {
      purchasedSeatsLabel: String(subjectDeal.quantityPurchased),
      purchasedSeatsValue: subjectDeal.quantityPurchased,
    };
  }

  const perLevelDeal = getPerLevelDeal(purchasedAddons, levelId);
  if (perLevelDeal) {
    return {
      purchasedSeatsLabel: `All subjects - ${levelName}`,
      purchasedSeatsValue: Infinity,
    };
  }

  return {
    purchasedSeatsLabel: '-',
    purchasedSeatsValue: 0,
  };
}

export function getUtilisation({
  usedSeats,
  purchasedSeats,
  hasUnlimitedSeats,
}: {
  hasUnlimitedSeats: boolean,
  purchasedSeats: number,
  usedSeats: number,
}): CellValue<string, number> {
  if (hasUnlimitedSeats) {
    return createCellValue('-', 0);
  }

  if (!purchasedSeats) {
    return createCellValue('No license', Infinity);
  }

  const utilisationPercentage = Math.round((usedSeats / purchasedSeats) * 100);
  return createCellValue(`${utilisationPercentage}%`, utilisationPercentage);
}

function getYearLevelOrdering(gqlLevels: $ReadOnlyArray<GqlLevel>): (yearName: string) => number {
  // Not all year names follow the format "Year N" e.g. "Year 11 (GCSE)" and "Secondary 3 (O Level)".
  // Year levels don't have an explicit "rank" value we can use for sorting, so we need to guess.
  // We guess based on the ordering determined by the `sortYearLevels` helper.

  const unsortedLevels = gqlLevels.map((gqlLevel) => gqlLevel.level);
  const sortedLevels = sortYearLevels(unsortedLevels, 'asc');

  const getImplicitLevelRank = (yearName: string): number => {
    return sortedLevels.findIndex((level) => level.name === yearName);
  };
  return getImplicitLevelRank;
}

function transformYearLevels(
  gqlLevels: $ReadOnlyArray<GqlLevel>,
  addons: $ReadOnlyArray<GqlAddon>
): $ReadOnlyArray<YearLevel> {
  const getImplicitLevelRank = getYearLevelOrdering(gqlLevels);

  return gqlLevels.map((gqlLevel) => {
    const levelId = gqlLevel.level.id;

    const yearName = gqlLevel.level.name;
    const implicitLevelRank = getImplicitLevelRank(yearName);
    const name = createCellValue(yearName, implicitLevelRank);

    const usedSeats = createCellValue(gqlLevel.totalStudentsCount);

    const { purchasedSeatsLabel, purchasedSeatsValue } = getPurchasedSeatsPerLevel(levelId, addons);
    const purchasedSeats = createCellValue(purchasedSeatsLabel, purchasedSeatsValue);

    const utilisation = getUtilisation({
      usedSeats: gqlLevel.totalStudentsCount,
      purchasedSeats: purchasedSeatsValue,
      hasUnlimitedSeats: isLegacyPlan(addons) || Boolean(getPerSubjectDeal(addons, levelId)),
    });

    return {
      name,
      id: levelId,
      purchasedSeats,
      usedSeats,
      utilisation,
    };
  });
}

function transformSubjects(
  gqlSubjects: $ReadOnlyArray<GqlSubject>,
  addons: $ReadOnlyArray<GqlAddon>
): $ReadOnlyArray<Subject> {
  return gqlSubjects.map((gqlSubject) => {
    const levelId = gqlSubject.level.id;
    const levelName = gqlSubject.level.name;
    const subjectCode = gqlSubject.subject.code;

    const name = createCellValue(`${gqlSubject.subject.name} - ${gqlSubject.level.name}`);

    const usedSeats = createCellValue(gqlSubject.totalStudentsCount);

    const { purchasedSeatsLabel, purchasedSeatsValue } = getPurchasedSeatsPerSubject({
      levelId,
      levelName,
      purchasedAddons: addons,
      subjectCode,
    });
    const purchasedSeats = createCellValue(purchasedSeatsLabel, purchasedSeatsValue);

    const utilisation = getUtilisation({
      usedSeats: gqlSubject.totalStudentsCount,
      purchasedSeats: purchasedSeatsValue,
      hasUnlimitedSeats: isLegacyPlan(addons) || Boolean(getPerLevelDeal(addons, levelId)),
    });

    return {
      name,
      levelName,
      id: levelId,
      purchasedSeats,
      usedSeats,
      utilisation,
    };
  });
}

export function getSubjectFilters(gqlSubjects: $ReadOnlyArray<GqlSubject>): $ReadOnlyArray<string> {
  const levels = [];

  for (const subject of gqlSubjects) {
    const levelExists = levels.find((level) => level.id === subject.level.id);
    if (!levelExists) {
      levels.push(subject.level);
    }
  }

  return sortYearLevels([...levels], 'asc').map(({ name }) => name);
}

export default function useGetAccountUsage(props: Props): Output {
  const { accountId } = props;

  const { data, error, loading } = useQuery<GetAccountUsage, GetAccountUsageVariables>(GET_ACCOUNT_USAGE, {
    variables: {
      accountId,
    },
  });

  if (loading && !data) {
    return {
      data: null,
      error: null,
      loading: true,
    };
  }

  if (error) {
    return {
      data: null,
      error,
      loading: false,
    };
  }

  const accountData = data?.me?.account;
  invariant(accountData, 'Account data should be defined');

  const billingData = accountData?.billing;
  invariant(billingData, 'Billing data should be defined');

  const usageMetricsData = accountData?.metrics?.usage;
  invariant(usageMetricsData, 'Usage metrics data should be defined');

  const accountType = getAccountType(billingData.addons, accountData.region.code);

  const yearLevels = transformYearLevels(usageMetricsData.perLevel, billingData.addons);

  const subjects = transformSubjects(usageMetricsData.perLevelAndSubject, billingData.addons);

  const subjectFilters = getSubjectFilters(usageMetricsData.perLevelAndSubject);

  return {
    data: {
      accountType,
      yearLevels,
      subjectFilters,
      subjects,
    },
    error,
    loading,
  };
}
