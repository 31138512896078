// @flow
import Helmet from 'react-helmet';
import { Container, Heading, Divider, Stack } from '@getatomi/neon';
import { connect } from 'react-redux';

import { loadEDSDetails, loadSubscription } from 'src/actions/subscriptions';
import { getActiveSubscription, getEDSDetails } from 'src/reducers/subscriptions';
import connectData from 'src/decorators/connectData';
import type { GetState, Dispatch, ReduxState, ExternalDataSourceType } from 'src/types';

import ConfigurationForm from './ConfigurationForm/ConfigurationForm';
import Mapping from './Mapping/Mapping';
import Sync from './Sync/Sync';

type Props = {
  externalDataSourceDetails: ExternalDataSourceType,
};

export const fetchData = async (getState: GetState, dispatch: Dispatch) => {
  await dispatch(loadSubscription());

  const state = getState();
  const subscription = getActiveSubscription(state);

  if (subscription.external_data_source_id) {
    return dispatch(
      loadEDSDetails({
        externalDataSourceId: subscription.external_data_source_id,
        subscriptionId: subscription.id,
      })
    );
  }
};

const mapStateToProps = (state: ReduxState) => {
  return {
    externalDataSourceDetails: getEDSDetails(state),
  };
};

function Wonde(props: Props) {
  const { externalDataSourceDetails } = props;

  return (
    <Container maxWidth="sizeContainerSmall">
      <Helmet>
        <title>Wonde</title>
      </Helmet>
      <Stack spacing="spacingLarge3X">
        <Container textAlign="center">
          <Heading as="h2">Integrate Wonde</Heading>
        </Container>
        <Divider />
        <ConfigurationForm externalDataSourceDetails={externalDataSourceDetails} />
        <Divider />
        <Mapping externalDataSourceDetails={externalDataSourceDetails} />
        <Divider />
        <Sync
          externalDataSourceId={externalDataSourceDetails?.id}
          isExternalDataSourceActivated={externalDataSourceDetails?.status === 'active'}
        />
      </Stack>
    </Container>
  );
}

export default connectData(fetchData)(connect(mapStateToProps)(Wonde));
