// @flow
import { Container, Heading } from '@getatomi/neon';

import SettingsNav from './SettingsNav/SettingsNav';

type Props = {
  children: React.Node,
};

export default function Settings(props: Props) {
  return (
    <>
      <Container>
        <Heading as="h1" marginBottom="spacingLarge3X">
          Settings
        </Heading>
        <SettingsNav />
      </Container>
      {props.children}
    </>
  );
}
