import { isFunction } from 'lodash';

/**
 * Note:
 * When this decorator is used, it MUST be the first (outermost) decorator.
 * Otherwise, we cannot find and call the `fetchData` method.
 */
export default function connectData(fetchData, refreshCacheOnEnter = true) {
  return function wrapWithFetchData(WrappedComponent) {
    /* eslint-disable no-param-reassign */
    WrappedComponent.fetchData = fetchData;
    WrappedComponent.refreshCacheOnEnter = refreshCacheOnEnter;
    /* eslint-enable no-param-reassign */

    return WrappedComponent;
  };
}

export function getRoutePath(location) {
  return `${location.pathname}${location.search}`;
}

/**
 * 1. Skip holes in route component chain and only consider components that implement `fetchData`
 * 2. Exclude routes which have already been visited and have the refreshCacheOnEnter returning true
 * 2. Pull out fetch data method
 * 3. Call fetch data methods and gather promises
 */
export function getDataDependencies(store, components, location, params, cachedRoutes) {
  const { getState, dispatch } = store;
  const routePath = getRoutePath(location);

  return components
    .filter((component) => component && component.fetchData)
    .filter((component) => {
      const { refreshCacheOnEnter } = component;
      const refreshCache = isFunction(refreshCacheOnEnter) ? refreshCacheOnEnter(getState) : refreshCacheOnEnter;
      const isRouteCached = cachedRoutes.has(routePath);

      return !(isRouteCached && !refreshCache);
    })
    .map((component) => component.fetchData(getState, dispatch, location, params))
    .filter((promise) => promise);
}
