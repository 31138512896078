// @flow
import { Container, Heading, HelpInfo, IconArrowFront, Panel, Stack, Text, useMediaQuery } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import type { UserType, OwnerDetails } from 'src/types';
import Link from 'src/components/Link/Link';
import VideoPlayer from 'src/components/VideoPlayer/VideoPlayer';
import userFullName from 'src/utils/userFullName';
import { trackingCtas } from 'src/constants/tracking';

type Props = {
  accountName?: string,
  ctaOnClick?: (e: SyntheticEvent<>) => mixed,
  ctaUrl?: string,
  isChild?: boolean,
  isFreeSchoolPlan?: boolean,
  isInvited?: boolean,
  isLoading?: boolean,
  isParent?: boolean,
  isStudent?: boolean,
  ownerDetails?: OwnerDetails,
  schoolName?: string,
  testHook?: string,
  user: UserType,
};

export default function WelcomeBox(props: Props) {
  const {
    accountName,
    ctaUrl,
    isChild,
    isFreeSchoolPlan,
    isInvited,
    isStudent,
    isParent,
    ownerDetails,
    schoolName,
    user,
    testHook,
  } = props;
  const isMobileOrTablet = useMediaQuery({ maxWidth: 'breakpointLarge' });
  const Wrapper = isMobileOrTablet ? 'div' : Panel;

  let greeting;

  if (isInvited) {
    if (isStudent && !isChild) {
      greeting =
        'As a student, you’ll get access to thousands of video lessons, interactive quizzes and even personalised revision recommendations powered by AI, all thanks to your school!';
    } else if (isFreeSchoolPlan) {
      greeting =
        'As a teacher on a free trial, you’ll be able to access a range of engaging video, text and interactive lessons, and learn more about our features for schools.';
    } else if (isChild) {
      greeting = `Hello ${user.first_name}! Let’s start with a quick intro video to step you through what Atomi is and how it can help. Happy watching!`;
    } else {
      greeting =
        'As a teacher, you’ll get access to engaging video, text and interactive content, intelligent testing and powerful analytics, all thanks to your school!';
    }
  } else {
    greeting = isParent
      ? `Hello ${user.first_name}! Let’s start with a quick intro video to step you through what Atomi is and how it can help your child. Happy watching!`
      : `Hello ${user.first_name}! To kick things off here’s a quick intro video to step you through what Atomi is and how it can help.`;
  }

  return (
    <Stack spacing={{ base: 'spacingLarge2X', desktop: 'spacingRoot' }} testHook={testHook}>
      <Wrapper>
        <Stack spacing="spacingLarge3X">
          <Heading as="h1" variant="large">
            Welcome to Atomi
          </Heading>
          <Container paddingInline="spacingNone">
            <VideoPlayer videoId={isStudent || isParent ? 't2soub935j' : 'o9tw0s1s3i'} testHook="hide-in-percy" />
          </Container>
          <Container maxWidth="sizeContainerRoot">
            <Text as="p" variant="bodyRoot">
              {user && isInvited && !isChild && (
                <>
                  {isStudent ? <>Hi {user.first_name || 'there'}! 😄</> : <>Hello {user.first_name || 'there'}!</>} Your
                  school <strong>{schoolName}</strong> has invited you to join their account by signing up below.
                  {'  '}
                </>
              )}
              {greeting}
            </Text>
          </Container>
          <Button
            size="large"
            iconAfter={<IconArrowFront size="sizeIconSmall1X" strokeWidth="2" />}
            isLoading={props.isLoading}
            onClick={props.ctaOnClick}
            to={ctaUrl}
            trackingData={!isInvited ? { cta: trackingCtas.userOnboardingVideoClosed } : undefined}
            testHook={testHook && `${testHook}-continue-button`}
          >
            Continue
          </Button>
        </Stack>
      </Wrapper>
      {isInvited && (
        <Container textAlign="center">
          <HelpInfo>
            {isChild ? (
              <>
                You’re joining <strong>{userFullName(ownerDetails?.first_name, ownerDetails?.last_name)}’s</strong>{' '}
                family account on Atomi. If that doesn’t seem right, talk to them or email us at{' '}
                <Link href="mailto:support@getatomi.com" variant="monochrome">
                  support@getatomi.com
                </Link>
              </>
            ) : (
              <>
                You are joining{' '}
                {isFreeSchoolPlan ? (
                  <>
                    a free trial for <strong>{accountName}</strong>
                  </>
                ) : (
                  <strong>{schoolName}</strong>
                )}{' '}
                on Atomi. If that doesn’t seem right, talk to your {isFreeSchoolPlan ? 'colleague' : 'school'} or email
                us at{' '}
                <Link href="mailto:support@getatomi.com" variant="monochrome">
                  support@getatomi.com
                </Link>
                .
              </>
            )}
          </HelpInfo>
        </Container>
      )}
    </Stack>
  );
}
