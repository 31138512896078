// @flow
import type { CookieSetOptions } from 'universal-cookie';

import { COOKIE_MAX_AGE } from 'src/constants/cookies';

export function getCookieOptions(): CookieSetOptions {
  return {
    path: '/',
    maxAge: COOKIE_MAX_AGE,
    secure: true,
    sameSite: 'none',
  };
}
