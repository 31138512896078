// @flow
import useSubscriptions from 'src/hooks/useSubscriptions';
import broadcasting from 'src/constants/broadcasting';

const studentEvents = [
  broadcasting.events.TASKS.UPDATED,
  broadcasting.events.TASKS.DELETED,
  broadcasting.events.USER_PROGRESS.UPDATED,
];
const teacherEvents = [
  broadcasting.events.CLASS_TASKS.UPDATED,
  broadcasting.events.CLASS_TASKS.DELETED,
  broadcasting.events.CLASS_USER_PROGRESS.UPDATED,
];

type Props = {
  audience: 'teacher' | 'student',
  classId: string,
  userId: string,
};

export default function useTaskSubscriptions({ audience, classId, userId }: Props) {
  const events = audience === 'teacher' ? teacherEvents : studentEvents;
  const subscriptions = events.map((event) => ({
    channel: audience === 'teacher' ? `class.${classId}` : `user.${userId}`,
    event,
  }));
  useSubscriptions(subscriptions);
}
