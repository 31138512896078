// @flow
import { Box, Spinner, Text } from '@getatomi/neon';

import type { CurrencyType, PaymentFrequencyType } from 'src/types';
import Button from 'src/components/Button/Button';
import Price from 'src/components/Price/Price';
import paymentFrequencies from 'src/constants/paymentFrequencies';

import styles from './TotalCost.module.scss';

type Props = {
  currency: CurrencyType,
  estimate: Object,
  onSwitchToYearlyPlan?: ?(event: SyntheticEvent<>) => mixed,
  paymentFrequency: PaymentFrequencyType,
  yearlyDiscount: number,
};

export default function TotalCost(props: Props) {
  const { currency, estimate, onSwitchToYearlyPlan, paymentFrequency } = props;

  if (!estimate) return null;

  const isLoadingEstimate = estimate.isLoading;
  const { refundableCredits, promotionalCredits } = estimate;
  const hasCredit = refundableCredits > 0 || promotionalCredits > 0;

  return (
    <dl className={styles.root}>
      {/** Plan cost and credit line if any **/}
      {hasCredit && (
        <>
          <div className={styles.lineItemPayment}>
            <dt>
              Payment{' '}
              <Text
                as="span"
                color="colorTextSubtler"
                fontFamily="fontFamilyRoot"
                lineHeight="lineHeightLarge1X"
                marginLeft="spacingSmall2X"
                variant="bodySmall1X"
              >
                per {paymentFrequency}
              </Text>
            </dt>
            <dd>
              <Price currency={currency} value={estimate.sub_total} />
            </dd>
          </div>
          {refundableCredits > 0 && (
            <div className={styles.lineItemCredit}>
              <Text
                as="dt"
                color="colorTextSubtler"
                fontFamily="fontFamilyRoot"
                lineHeight="lineHeightLarge1X"
                variant="bodySmall1X"
              >
                minus one-time pro-rata credit
              </Text>
              <dd>
                <Price currency={currency} value={refundableCredits} />
              </dd>
            </div>
          )}
          {promotionalCredits > 0 && (
            <div className={styles.lineItemCredit}>
              <Text
                as="dt"
                color="colorTextSubtler"
                fontFamily="fontFamilyRoot"
                lineHeight="lineHeightLarge1X"
                variant="bodySmall1X"
              >
                minus one-time promo credits
              </Text>
              <dd>
                <Price currency={currency} value={promotionalCredits} />
              </dd>
            </div>
          )}
        </>
      )}

      {/** Total due **/}
      <div className={styles.lineItem}>
        <Text as="dt" fontFamily="fontFamilySystem">
          Total payment{' '}
          <Text
            as="span"
            color="colorTextSubtler"
            lineHeight="lineHeightLarge1X"
            marginLeft="spacingSmall2X"
            variant="bodySmall1X"
          >
            {hasCredit ? 'due now' : `per ${paymentFrequency}`}
          </Text>{' '}
          {paymentFrequency === paymentFrequencies.month && onSwitchToYearlyPlan && (
            <Box display={{ base: 'none', tablet: 'inline-block' }} marginLeft="spacingSmall2X">
              <Button variant="text" size="small" onClick={onSwitchToYearlyPlan}>
                or save {props.yearlyDiscount}% by paying yearly
              </Button>
            </Box>
          )}
        </Text>
        {isLoadingEstimate ? (
          <dd className={styles.spinner}>
            <Spinner size="sizeIconSmall" testHook="hide-in-percy" />
          </dd>
        ) : (
          <dd>
            <Price
              currency={currency}
              value={estimate.amount_due}
              fontFamily="fontFamilySystem"
              fontWeight="fontWeightBold"
              withCurrencyCode
            />
          </dd>
        )}
      </div>
    </dl>
  );
}
