// @flow
import { Box } from '@getatomi/neon';
import invariant from 'invariant';

import type { Output as UseBreadcrumbsOutput } from 'src/components/useFindContentDialog/hooks/useBreadcrumbs';
import DividerStack, { DividerStackLoader } from 'src/components/DividerStack/DividerStack';
import { trackingEvents } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';

import ContentCatalogError from '../ContentCatalog/ContentCatalogError';
import useGetLevelSubjects, { type SelectSubjectVariables } from './useGetLevelSubjects';

type Props = {|
  addBreadcrumb: $PropertyType<UseBreadcrumbsOutput, 'addBreadcrumb'>,
  queryVariables: SelectSubjectVariables,
  showUpgradeModal: () => void,
|};

export default function SelectSubject(props: Props) {
  const { addBreadcrumb, queryVariables, showUpgradeModal } = props;

  const { loading, error, subjects } = useGetLevelSubjects(queryVariables);
  if (error) {
    return (
      <Box marginTop="spacingSmall">
        <ContentCatalogError content="subjects" />
      </Box>
    );
  }

  if (loading) {
    return <DividerStackLoader />;
  }

  invariant(subjects, `Subjects should be defined`);

  return (
    <DividerStack
      items={subjects.map((subject) => ({
        isLocked: subject.isLocked,
        heading: subject.name,
        onClick: () => {
          if (subject.isLocked) {
            showUpgradeModal();
            trackEvent(trackingEvents.upgradePromptTriggered, {
              cta: 'Find Content Dialog',
              source: 'browse',
            });
          } else {
            addBreadcrumb({
              screen: 'select-module',
              label: subject.name,
              queryVariables: {
                ...queryVariables,
                subjectCode: subject.code,
              },
            });
          }
        },
      }))}
    />
  );
}
