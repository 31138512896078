// @flow
import { useState, useCallback } from 'react';
import _ from 'lodash';

export type SelectedItems = {
  clear: () => void,
  isAllSelected: () => boolean,
  isSelected: (id: string) => boolean,
  itemIds: Array<string>,
  select: (id: string) => void,
  selectAll: () => void,
};

export default function useSelectedItems(initialValues: Array<string> = []): SelectedItems {
  const [itemIds, setSelected] = useState([]);

  const clear = useCallback((): void => {
    setSelected([]);
  }, []);

  const isSelected = useCallback(
    (id: string): boolean => {
      return itemIds.includes(id);
    },
    [itemIds]
  );

  const isAllSelected = useCallback((): boolean => {
    return initialValues.length > 0 && itemIds.length === initialValues.length;
  }, [initialValues.length, itemIds.length]);

  const select = useCallback(
    (id: string): void => {
      if (!initialValues.includes(id)) {
        return;
      }
      setSelected((state) => _.xor(state, [id]));
    },
    [initialValues]
  );

  const selectAll = useCallback((): void => {
    if (itemIds.length < initialValues.length) {
      setSelected(initialValues);
    } else {
      clear();
    }
  }, [clear, initialValues, itemIds.length]);

  return { itemIds, select, selectAll, clear, isSelected, isAllSelected };
}
