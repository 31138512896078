// @flow
import { useCallback, useState } from 'react';
import { SelectTrigger, Truncate } from '@getatomi/neon';

import StudentPicker, { type Props } from 'src/components/StudentPicker/StudentPicker';

export default function StudentFilter(props: Props) {
  const { onClose, onChange, students } = props;
  const [key, setKey] = useState<number>(0);
  const renderTrigger = (studentOptions, selectedStudentIds) => {
    let label;
    switch (selectedStudentIds.length) {
      case studentOptions.length:
        label = 'All students';
        break;
      case 0:
        label = 'All students';
        break;
      case 1:
        const selectedStudent = studentOptions.find((option) => option.studentId === selectedStudentIds[0]);
        // $FlowIgnore - student triggerLabel is defined
        label = selectedStudent.triggerLabel;
        break;
      default:
        label = `${selectedStudentIds.length} students`;
        break;
    }

    return (
      <SelectTrigger variant="filter">
        <Truncate>{label}</Truncate>
      </SelectTrigger>
    );
  };

  const handleOnChange = useCallback(
    (selectedValues) => {
      const filterValues = selectedValues.length === students.length ? [] : selectedValues;
      onChange(filterValues);
    },
    [students, onChange]
  );

  const handleFilterOnClose = useCallback(
    (selectedValues) => {
      if (selectedValues.length === 0) {
        // if nothing is selected we want to re-render the dropdown when closing
        // so that when we re-open the filter all options are selected
        setKey(key + 1);
      }

      if (onClose) {
        const filterValues = selectedValues.length === students.length ? [] : selectedValues;
        onClose(filterValues);
      }
    },
    [key, onClose, students]
  );

  return (
    <StudentPicker
      {...props}
      key={key}
      renderTrigger={renderTrigger}
      onClose={handleFilterOnClose}
      onChange={handleOnChange}
    />
  );
}
