// @flow
import { useEffect, useState } from 'react';

import type { TableData, ColumnList } from '../components/MarkbookControls';
import type { ClassAverage } from '../Completion/completionTransformer';
import type { Student } from '../utils/prepareStudentData';

export const transformCSVData = (data: TableData, columnList: ColumnList): string => {
  const csvRows = [];

  const headers = ['First name', 'Last name', ...columnList.map((column) => column.name)];

  csvRows.push(headers.map((header) => `"${header}"`));

  const renderStudentName = (rowMeta: Student | ClassAverage, header: string) => {
    const renderFullName = (firstName, lastName) => {
      if (header === 'First name') return `"${firstName}"`;
      if (header === 'Last name') return `"${lastName}"`;
    };
    if (rowMeta.type === 'ClassAverage') return renderFullName('Class', '');

    return renderFullName(rowMeta.firstName ?? '', rowMeta.lastName ?? '');
  };

  const renderCSVCell = (row, header: string) => {
    if (header === 'First name' || header === 'Last name') return renderStudentName(row.rowMeta, header);
    const currentColumn = columnList.find((column) => column.name === header);
    const value = row[`column-${currentColumn?.id ?? ''}`];
    if (value == null) {
      return '';
    }
    return value.displayValue;
  };

  const rowData = data
    .map((row) => {
      return headers.map((header) => renderCSVCell(row, header)).join(',');
    })
    .join('\n');

  csvRows.push(rowData);

  return csvRows.join('\n');
};

export default function useDownloadUrl(tableData?: TableData, columnList?: ColumnList): ?string {
  const [url, setUrl] = useState<?string>();

  useEffect(() => {
    if (!tableData || !columnList) return;
    const data = transformCSVData(tableData, columnList);
    const blob = new Blob([data], { type: 'text/csv' });
    const objectUrl = window.URL.createObjectURL(blob);
    setUrl(objectUrl);

    return () => window.URL.revokeObjectURL(objectUrl);
  }, [tableData, columnList]);

  return url;
}
