// @flow
import invariant from 'invariant';
import { useQuery, type ApolloError } from '@apollo/client';

import postTypes from 'src/constants/postTypes';
import { getLtiClassModuleLessonUrl } from 'src/utils/routes';
import type {
  GetModuleLessons,
  GetModuleLessonsVariables,
  GetModuleLessons_me_account_class_module as GqlModule,
  GetModuleLessons_me_account_class_module_topics as GqlTopic,
  GetModuleLessons_me_account_class_module_topics_ClassCategory_lessons as GqlTopicLesson,
  GetModuleLessons_me_account_class_module_topics_ClassCategory_subtopics as GqlSubtopic,
  GetModuleLessons_me_account_class_module_topics_ClassCategory_subtopics_ClassCategory_lessons as GqlSubtopicLesson,
} from 'src/graphql/types/generated/GetModuleLessons';
import type { LessonType, SubtopicType, TopicType } from 'src/components/LessonList/LessonList';

import GET_MODULE_LESSONS from './GetModuleLessons.graphql';

type Props = {|
  accountId: string,
  classId: string,
  moduleId: string,
|};

type Module = {|
  name: string,
  topics: $ReadOnlyArray<TopicType>,
|};

type Output = {|
  error: ?ApolloError,
  loading: boolean,
  module: ?Module,
|};

function transformLesson(rawLesson: GqlTopicLesson | GqlSubtopicLesson, getPreviewUrl): LessonType {
  let type;
  switch (rawLesson.__typename) {
    case 'ClassTextLesson': {
      type = postTypes.text;
      break;
    }
    case 'ClassVideoLesson': {
      type = postTypes.video;
      break;
    }
    default: {
      type = postTypes.challenge;
      break;
    }
  }

  const challengeTier = rawLesson.__typename === 'ClassChallengeLesson' ? rawLesson.tier : null;

  return {
    id: rawLesson.id,
    duration: rawLesson.duration,
    name: rawLesson.name,
    previewUrl: getPreviewUrl(rawLesson.id),
    challengeTier,
    type,
  };
}

function transformSubtopic(subtopic: GqlSubtopic, getPreviewUrl): ?SubtopicType {
  if (subtopic.__typename === 'ClassCategory') {
    return {
      name: subtopic.name,
      lessons: subtopic.lessons.map((lesson) => transformLesson(lesson, getPreviewUrl)),
    };
  }
}

function transformTopic(topic: GqlTopic, getPreviewUrl): ?TopicType {
  if (topic.__typename === 'ClassCategory') {
    return {
      name: topic.name,
      subtopics: topic.subtopics.map((subtopic) => transformSubtopic(subtopic, getPreviewUrl)).filter(Boolean),
      lessons: topic.lessons.map((topicLesson) => transformLesson(topicLesson, getPreviewUrl)),
    };
  }
}

function transformModule(module: GqlModule, getPreviewUrl): Module {
  return {
    name: module.name,
    topics: module.topics.map((topic) => transformTopic(topic, getPreviewUrl)).filter(Boolean),
  };
}

export default function useGetModuleLessons(props: Props): Output {
  const { accountId, classId, moduleId } = props;

  const {
    data: queryData,
    error,
    loading,
  } = useQuery<GetModuleLessons, GetModuleLessonsVariables>(GET_MODULE_LESSONS, {
    variables: {
      accountId,
      classId,
      moduleId,
    },
  });

  if (error || (loading && !queryData)) {
    return { module: undefined, error, loading };
  }

  const moduleData = queryData?.me?.account?.class?.module;
  invariant(moduleData, 'Module data should be defined');

  const getPreviewUrl = (lessonId: string): string => {
    return getLtiClassModuleLessonUrl(accountId, classId, moduleId, lessonId);
  };

  const module = transformModule(moduleData, getPreviewUrl);

  return {
    module,
    error: null,
    loading: false,
  };
}
