// @flow
import {
  Box,
  Checkbox,
  DatePicker,
  Flex,
  IconClose,
  IconSchedule,
  InfoTip,
  Label,
  Skeleton,
  Stack,
  Text,
} from '@getatomi/neon';

import TaskDateTime from 'src/components/TaskDateTime/TaskDateTime';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import taskPublicationStatuses from 'src/constants/taskPublicationStatuses';
import StudentPicker, { type StudentProp } from 'src/components/StudentPicker/StudentPicker';

import TimePicker from './TimePicker';

export function SidebarLoader() {
  return (
    <Box
      paddingBlock="spacingLarge1X"
      paddingInline={{
        base: 'spacingRoot',
        tablet: 'spacingLarge3X',
        desktop: 'spacingLarge3X',
      }}
    >
      <Stack spacing="spacingLarge1X">
        {Array.from({ length: 3 }).map((v, i) => (
          <Stack key={i} spacing="spacingSmall1X">
            <Skeleton animation="wave" height={21} width={60} />
            <Skeleton animation="wave" height={42} width="sizeFull" />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}

export function ScheduledDateTime(props: {
  dismissDuplicateContentToast: () => void,
  scheduledFor: moment$Moment,
  setIsScheduleDialogOpen: (state: boolean) => void,
  setStatus: (state: $Keys<typeof taskPublicationStatuses>) => void,
}) {
  const { dismissDuplicateContentToast, scheduledFor, setIsScheduleDialogOpen, setStatus } = props;

  return (
    <Box
      alignItems="center"
      backgroundColor="colorBackgroundNeutral"
      display="flex"
      fontSize="fontSizeSmall1X"
      gap="spacingSmall1X"
      justifyContent="space-between"
      paddingBlock="spacingSmall"
      paddingInline={{
        base: 'spacingRoot',
        tablet: 'spacingLarge3X',
        desktop: 'spacingLarge3X',
      }}
    >
      <Flex
        alignItems="center"
        as="button"
        color="colorTextSubtle"
        gap="spacingSmall1X"
        onClick={() => {
          dismissDuplicateContentToast();
          setIsScheduleDialogOpen(true);
        }}
        _hoverAndFocus={{
          textDecoration: 'underline',
        }}
      >
        <IconSchedule size="sizeIconSmall1X" />
        <Text as="span" variant="bodySmall1X" color="colorTextSubtle">
          Scheduled for <TaskDateTime value={scheduledFor} />
        </Text>
      </Flex>
      <Box as="button" type="button" onClick={() => setStatus('draft')}>
        <IconClose size="sizeIconSmall2X" isDecorative={false} title="Remove scheduling" />
      </Box>
    </Box>
  );
}

export function SidebarControls(props: {
  classStudentsCount: number,
  dueDate: moment$Moment,
  dueDateValidationError: ?string,
  handleSelectStudentsOnClose: () => void,
  hasPosts: boolean,
  isAutoComplete: boolean,
  onDueDateChange: (date: moment$Moment) => void,
  onDueTimeChange: (value: string) => void,
  selectStudentsOnChange: (selectedStudentIds: $ReadOnlyArray<string>) => void,
  students: Array<StudentProp>,
  studentsValidationError: ?string,
  toggleAutoComplete: (value: boolean) => void,
  userIds: $ReadOnlyArray<string>,
}) {
  const {
    classStudentsCount,
    dueDate,
    dueDateValidationError,
    handleSelectStudentsOnClose,
    hasPosts,
    isAutoComplete,
    onDueDateChange,
    onDueTimeChange,
    selectStudentsOnChange,
    students,
    studentsValidationError,
    toggleAutoComplete,
    userIds,
  } = props;

  const studentPickerId = 'student-picker-id';

  return (
    <Box
      paddingBlock="spacingLarge1X"
      paddingInline={{
        base: 'spacingRoot',
        tablet: 'spacingLarge3X',
        desktop: 'spacingLarge3X',
      }}
    >
      <Stack spacing="spacingLarge1X">
        {(dueDateValidationError || studentsValidationError) && (
          <ValidationErrors errors={[dueDateValidationError, studentsValidationError].filter(Boolean)} />
        )}
        <DatePicker
          label="Date due"
          dateFormats={{ ...DatePicker.DATE_FORMATS, today: '[Today]', tomorrow: '[Tomorrow]' }}
          value={dueDate}
          onChange={onDueDateChange}
        />
        <TimePicker label="Time due" selectedKey={dueDate.format('H:mm')} onSelectionChange={onDueTimeChange} />
        <Flex direction="column">
          <Label htmlFor={studentPickerId}>For</Label>
          <StudentPicker
            id={studentPickerId}
            isDisabled={classStudentsCount === 0}
            initialStudentIds={userIds}
            students={students}
            onChange={selectStudentsOnChange}
            onClose={handleSelectStudentsOnClose}
            testHook="task-dialog-students"
            variant="compact"
          />
        </Flex>
        <Checkbox isSelected={hasPosts && isAutoComplete} onChange={toggleAutoComplete} isDisabled={!hasPosts}>
          Mark this task as done if a student completes all the included Atomi content.
          <InfoTip
            content="Deselect this option if you want students to mark the task as done instead. Students should be able to mark tasks as done so you can keep track of their progress if it includes custom instructions or work."
            iconSize="sizeIconSmall2X"
            appendTo="parent"
          />
        </Checkbox>
      </Stack>
    </Box>
  );
}
