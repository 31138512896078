// @flow
import { EmptyState, Illustration, Stack } from '@getatomi/neon';

import type { ChallengingQuestion as ChallengingQuestionData } from 'src/domains/ViewingQuizResults/types';

import ChallengingQuestion from '../ChallengingQuestion/ChallengingQuestion';

type ChallengingQuestionListProps = {|
  data: $ReadOnlyArray<ChallengingQuestionData>,
|};

export default function ChallengingQuestionList(props: ChallengingQuestionListProps) {
  const { data } = props;

  if (data.length === 0) {
    return (
      <EmptyState
        media={<Illustration name="emptystate-add-users" />}
        description="There aren’t any challenging questions yet."
        testHook="empty-challenging-question-list"
      />
    );
  }

  return (
    <Stack as="ol" spacing="spacingNegativeSmall4X" testHook="challenging-question-list">
      {data.map((question) => (
        <ChallengingQuestion key={question.id} question={question} />
      ))}
    </Stack>
  );
}
