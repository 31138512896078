// @flow
import keyMirror from 'keymirror';

// https://apidocs.chargebee.com/docs/api/invoices#invoice_status
export default keyMirror({
  not_paid: null,
  paid: null,
  payment_due: null,
  pending: null,
  posted: null,
  voided: null,
});
