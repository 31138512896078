// @flow
// More info about classes, subjects and sections: https://hschub.atlassian.net/wiki/pages/viewpage.action?pageId=60392693
import { schema } from 'normalizr';
import _ from 'lodash';

import type {
  AccessLevel,
  AuthCustomerType,
  CurrencyType,
  DateString,
  PaymentFrequencyType,
  PaymentSourceStatus,
  PlanType,
  PostType,
  RegionCode,
  SubscriptionStatusType,
  UserRoles,
  UserStatusType,
  UserAccountStatusType,
} from 'src/types';
import type { UserStatusOnTask } from 'src/domains/Tasks/types';

// Student
export const studentSchema = new schema.Entity('students');
export const studentListSchema = [studentSchema];

// Teacher
export const teacherSchema = new schema.Entity('teachers');
export const teacherListSchema = [teacherSchema];

// Subject
export type Subject = {
  code: string,
  color: string,
  content_count: number,
  content_duration: number,
  icon: string,
  id: number,
  name: string,
  short_name: string,
  subject_group_code: string,
};
export const subjectSchema = new schema.Entity('subjects', {}, { idAttribute: 'code' });
export const subjectListSchema = [subjectSchema];
export const subjectsResponseSchema = { items: new schema.Array(subjectSchema) };

// Level
export type Level = {
  graduation_year: number,
  id: number,
  name: string,
  subjects?: Array<Subject>,
};
export const levelSchema = new schema.Entity('levels', {
  subjects: subjectListSchema,
});
export const levelListSchema = [levelSchema];
export const levelsResponseSchema = { items: new schema.Array(levelSchema) };

// Allocation
export type Allocation = {
  class_id: number,
  due_date: DateString,
  id: number,
  message: string,
  post_id: number,
};
export const allocationSchema = new schema.Entity('allocations');

export type Task = {
  due_date: DateString,
  user_status?: UserStatusOnTask,
};

export type ChallengeTier =
  | 1
  | 'TIER_1_QUIZ'
  | 2
  | 'TIER_2_PRACTICE'
  | 3
  | 'TIER_3_PRACTICE'
  | 4
  | 'TIER_4_REVISION'
  | 5
  | 'TIER_5_INTERACTIVE_LESSON';

// Post
export type Post = {
  access_level: AccessLevel,
  allocation: number,
  ancestors: Array<{
    depth: number,
    post: number,
  }>,
  challenge?: {
    asset_id: string,
    description: ?string,
    duration: number,
    tier: ChallengeTier,
  },
  children?: Array<Post>,
  content_count?: number,
  content_duration?: number,
  descendants: Array<number>,
  description: string,
  id: number,
  labels: Array<{
    id: number,
    name: string,
  }>,
  media: {
    duration: number,
  },
  name: string,
  nextPostId?: number, // computed in the posts reducer
  parentModuleId?: number, // computed from the post ancestors in the posts reducer
  previousPostId?: ?number, // computed in the posts reducer
  subject_code: string,
  tasks: Array<Task>,
  type: PostType,
};
const postProcessStrategy = (value, parent) => {
  // custom process strategy for post to retrieve post descendants as an array of post ids
  const { descendants } = parent;

  if (!descendants) {
    return value;
  }

  return { ...value, descendants: _.map(descendants, (d) => d.post.id) };
};
const postSchema = new schema.Entity(
  'posts',
  { allocation: allocationSchema },
  { processStrategy: postProcessStrategy }
);
export const postListSchema = [postSchema];

// Class
export type ClassType = {
  count_students: number,
  count_teachers: number,
  id: number,
  levels: Array<number>,
  name: string,
  posts: Array<Post>,
  students: Array<number>,
  subject: Subject,
  subject_code: string,
  subscription_id: number,
  teachers?: Array<number>,
};
export const classSchema = new schema.Entity('classes', {
  students: studentListSchema,
  teachers: teacherListSchema,
  subject: subjectSchema,
  levels: levelListSchema,
  posts: postListSchema,
});
export const classesResponseSchema = { items: new schema.Array(classSchema) };

// User
export type User = {
  avatar_mime_type: ?string,
  avatar_url: ?string,
  class_count?: number,
  color: number,
  deleted_at?: ?DateString,
  email: string,
  email_failure_at: ?DateString,
  email_failure_reason: ?string,
  first_name: string,
  id: number,
  last_active_subscription_id: number,
  last_name: string,
  level?: Level,
  phone_number: ?string,
  position: string,
  region_code: RegionCode,
  role: UserRoles,
  status: UserStatusType,
  subscription_status: UserAccountStatusType,
  subscriptions: Array<number>,
  updated_at: DateString,
};
export const userSchema = new schema.Entity('users', {
  classes: new schema.Array(classSchema),
});
export const userListSchema = [userSchema];
export const usersResponseSchema = { items: new schema.Array(userSchema) };

// Plans
export type Plan = {
  billing_period: PaymentFrequencyType,
  code: string,
  description: string,
  name: string,
  plan_type: PlanType,
  pricing: {
    currency: CurrencyType,
    yearly: number,
  },
  tax_code: string,
};
export const planSchema = new schema.Entity('plans', {}, { idAttribute: 'code' });
export const planListSchema = [planSchema];

// School
export type School = {
  created_at: string,
  id: number,
  name: string,
  region_code: RegionCode,
  status: string,
  subscription_id: ?number,
  updated_at: string,
};
export const schoolSchema = new schema.Entity('schools');
export const schoolListSchema = [schoolSchema];
export const schoolResponseSchema = { items: new schema.Array(schoolSchema) };

export type SubscriptionOnwerDetails = {
  email: string,
  first_name?: string,
  last_name?: string,
};

// Subscription
export type Subscription = {
  active_students_count: number,
  active_teachers_count: number,
  auto_collection: boolean,
  due_invoices_count: number,
  due_since: ?DateString,
  enabled_features: Array<string>,
  ends_at: DateString,
  external_data_source_id: ?string,
  has_payment_source?: boolean,
  has_saml_auth: boolean,
  id: number,
  intercom_id: ?string,
  name: string,
  owner?: SubscriptionOnwerDetails,
  payment_sources: Array<PaymentSource>, // eslint-disable-line no-use-before-define
  plan_code: string,
  plan_customer_type: AuthCustomerType,
  quantity: number,
  quantity_used: number,
  region_code: RegionCode,
  school?: School,
  school_id: number,
  starts_at: {
    date: DateString,
  },
  status: SubscriptionStatusType,
  total_users_count: number,
  trial_ends_at?: DateString,
  user: {
    class_count: number,
    role: string,
    subscription_status: UserAccountStatusType,
  },
};

export const subscriptionSchema = new schema.Entity('subscriptions', {
  users: userListSchema,
  plan: planSchema,
  school: schoolSchema,
});
export const subscriptionsListSchema = { items: new schema.Array(subscriptionSchema) };

// Auth user
export const authSchema = new schema.Entity('users', {
  subscriptions: [subscriptionSchema],
  classes: [classSchema],
  school: schoolSchema,
});

// Invited user schema
export const invitedUserSchema = new schema.Object({
  subscription: subscriptionSchema,
  school: schoolSchema,
});

// Region
export type Region = {
  code: string,
  country_iso: string,
  levels?: Array<Level>,
  locale: string,
  name: string,
  tax_code: string,
};
export const regionSchema = new schema.Entity(
  'region',
  {
    levels: [levelSchema],
    schools: schoolListSchema,
  },
  { idAttribute: 'code' }
);

export const video = new schema.Entity('posts');

// Invoices
export type Invoice = {
  amount_due: number,
  amount_paid: number,
  created_at: DateString,
  currency_code: CurrencyType,
  download_url: string,
  due_at: DateString,
  id: number,
  isLoaded: boolean,
  isLoadingDownloadUrl: boolean, // added by reducer
  status: string,
  tax: number,
  total: number,
  updated_at: DateString,
  valid_till: DateString,
};
export const invoiceSchema = new schema.Entity('invoices');
export const invoiceListSchema = { items: new schema.Array(invoiceSchema) };

// Payment sources
export type PaymentSource = {
  card_brand: string,
  card_expiry_month: number,
  card_expiry_year: number,
  card_last_four: string,
  gateway_customer_id: string,
  gateway_payment_method_id: string,
  hasErrors: boolean, // added by reducer
  id: number,
  status: PaymentSourceStatus,
};
export const paymentSourcesSchema = new schema.Entity('paymentSources');
export const paymentSourcesListSchema = { items: new schema.Array(paymentSourcesSchema) };

// Retrieves the ancestors ids from the post.ancestor object
const getModuleIds = (obj, acc = []) => (obj.ancestor ? getModuleIds(obj.ancestor, acc.concat(obj.ancestor.id)) : acc);

const revisionsProcessStrategy = (value, parent) => {
  return { ...value, moduleId: _.nth(getModuleIds(parent), -2) };
};
export const revisionSchema = new schema.Entity(
  'revisions',
  {},
  { idAttribute: 'subject_code', processStrategy: revisionsProcessStrategy }
);
