// @flow
import cx from 'classnames';
import { Container } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import type { RegistrationRegionCode } from 'src/types';
import { trackingCtas, trackingLocations } from 'src/constants/tracking';
import logoWhite from 'src/assets/images/atomi-logo-white.svg';
import logoTextOnlyBlue from 'src/assets/images/atomi-logo-text-only-blue.svg';
import { LoadingBar } from 'src/components/LoadingBar/LoadingBar';

import LoginRegistrationSwitcher from '../LoginRegistrationSwitcher/LoginRegistrationSwitcher';
import styles from './Header.module.scss';

type Props = {
  isRegistration?: boolean,
  regionCode?: RegistrationRegionCode,
  showSwitcher?: boolean,
};

export default function Header(props: Props) {
  const { isRegistration = true, regionCode, showSwitcher = true } = props;
  const trackingLocation = trackingLocations.header;
  const trackingCtaLoginRegistrationSwitcher = isRegistration ? 'userRegistration' : 'userLogin';

  return (
    <header className={cx(styles.root, { [styles.showSwitcher]: showSwitcher })}>
      <LoadingBar isInverted />
      <Container maxWidth="sizeFull">
        <div className={styles.rootInner}>
          <Link
            className={styles.logo}
            to="/"
            trackingData={{
              cta: trackingCtas.navigateToHome,
              location: trackingLocation,
            }}
          >
            <img
              src={isRegistration ? logoTextOnlyBlue : logoWhite}
              alt="Atomi logo"
              width={isRegistration ? '60' : '92'}
            />
          </Link>

          {showSwitcher && (
            <LoginRegistrationSwitcher
              regionCode={regionCode}
              trackingData={{
                cta: trackingCtas[trackingCtaLoginRegistrationSwitcher],
                location: trackingLocation,
              }}
              isRegistration={isRegistration}
            />
          )}
        </div>
      </Container>
    </header>
  );
}
