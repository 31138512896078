// @flow
import Helmet from 'react-helmet';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import type { LocationShape } from 'react-router';
import { Container, Box } from '@getatomi/neon';

import { getAllocatedCountryCode } from 'src/reducers/regions';
import NonAuthenticatedLayout from 'src/layouts/NonAuthenticatedLayout/NonAuthenticatedLayout';
import type { ReduxState, RegistrationRegionCode } from 'src/types';
import { errorMessages } from 'src/constants/saml';
import client from 'src/graphql/client';
import { isLtiUrl } from 'src/utils/routes';

import LoginForm from './LoginForm/LoginForm';
import EmbeddedLogin from './EmbeddedLogin/EmbeddedLogin';

type Props = {
  location: LocationShape,
};

type InjectedProps = Props & {
  regionCode: RegistrationRegionCode,
};

const mapStateToProps = (state: ReduxState) => ({
  regionCode: getAllocatedCountryCode(state),
});

function Login(props: InjectedProps) {
  const { regionCode, location } = props;
  const { redirect: redirectUrl, samlErrorCode } = location.query;

  const targetUrl = decodeURIComponent(redirectUrl ?? '');

  const isEmbed = targetUrl.includes('isEmbed=true') || isLtiUrl(targetUrl);

  const samlErrorMessage =
    samlErrorCode && errorMessages.hasOwnProperty(samlErrorCode) ? errorMessages[samlErrorCode] : undefined;

  useEffect(() => {
    // $FlowIgnore - clearStore is defined
    client.clearStore();
  }, []);

  return (
    <NonAuthenticatedLayout isRegistration={false} regionCode={regionCode}>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Log in to Atomi here. Not an Atomi user? Sign up for free." />
      </Helmet>
      {isEmbed ? (
        <EmbeddedLogin redirectUrl={targetUrl} />
      ) : (
        <Container maxWidth="sizeContainerSmall2X" textAlign="center">
          <Box marginTop="spacingLarge9X">
            <LoginForm redirectUrl={redirectUrl} samlErrorMessage={samlErrorMessage} />
          </Box>
        </Container>
      )}
    </NonAuthenticatedLayout>
  );
}

export default (connect(mapStateToProps, {})(Login): React.AbstractComponent<Props>);
