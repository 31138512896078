// @flow
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, TextField, useMediaQuery, Modal } from '@getatomi/neon';

import { isValidURL, sanitiseURL } from 'src/utils/url';
import { taskAttachmentTypes } from 'src/constants/taskAttachmentTypes';

export type TaskAttachedLink = {
  externalId: string,
  type: 'LINK',
  url: string,
};

export default function InsertLinkDialog(props: {
  isOpen: boolean,
  onClose: () => mixed,
  onSubmit: (link: TaskAttachedLink) => mixed,
}) {
  const { onClose, onSubmit, isOpen } = props;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const [link, setLink] = useState<?string>(null);
  const [validationError, setValidationError] = useState<?string>(null);

  const inputOnChange = (value: string) => {
    setValidationError(null);
    setLink(value);
  };

  const handleOnClose = () => {
    setLink(null);
    setValidationError(null);
    onClose();
  };

  const handleOnSubmit = (e: SyntheticEvent<>) => {
    e.preventDefault();
    if (!link) {
      return setValidationError('This field is required');
    }
    if (!isValidURL(link)) {
      return setValidationError('Enter a valid URL');
    }

    onSubmit({ externalId: uuidv4(), type: taskAttachmentTypes.LINK, url: sanitiseURL(link) });
    handleOnClose();
  };

  return (
    <Modal
      ariaLabel="Insert link"
      isOpen={isOpen}
      onClose={onClose}
      size="small"
      actions={
        <>
          <Button isFullWidth={isMobile} size="small" variant="text" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button isFullWidth={isMobile} size="small" onClick={handleOnSubmit}>
            Ok
          </Button>
        </>
      }
    >
      <form onSubmit={handleOnSubmit} noValidate>
        <TextField
          label="Insert link"
          type="url"
          isRequired
          errorVariant={validationError ? 'error' : undefined}
          validationText={validationError}
          onChange={inputOnChange}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </form>
    </Modal>
  );
}
