// @flow

import { useRef, useState } from 'react';
import { AttachmentList, Flex, HideVisually, IconAttachment, IconFileTxt, Label, Text } from '@getatomi/neon';

import { getFileExtension } from 'src/utils/file';
import Button from 'src/components/Button/Button';

type Props = {|
  label: string,
  onChange: (string | null) => void,
|};

export default function XmlFileField(props: Props) {
  const { label, onChange } = props;
  const inputRef = useRef<null | HTMLInputElement>(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const onChangeHandler = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files.length === 0) {
      return;
    }

    const file = files[0];
    const fileExtension = getFileExtension(file.name).toLowerCase();

    if (fileExtension !== 'xml') {
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      if (typeof text === 'string') {
        onChange(text);
        setSelectedFile(file);
      }
    };
    reader.readAsText(file);
  };

  const onRemoveHandler = () => {
    setSelectedFile(null);
    onChange(null);
  };

  const id = 'xml-file-field';

  if (selectedFile) {
    return (
      <>
        <Label>{label}</Label>
        <AttachmentList.Attachment removeAssistiveText="Remove file" onRemove={onRemoveHandler} isFullWidth>
          <Flex padding="spacingRoot" alignItems="center" justifyContent="left" gap="spacingRoot">
            <IconFileTxt />
            <Flex direction="column">
              <Text color="colorText" variant="bodySmall1X">
                {selectedFile.name}
              </Text>
              <Text color="colorTextSubtler" variant="bodySmall3X">
                XML
              </Text>
            </Flex>
          </Flex>
        </AttachmentList.Attachment>
      </>
    );
  }

  return (
    <>
      <Label htmlFor={id}>{label}</Label>
      <HideVisually>
        <input id={id} type="file" accept=".xml" ref={inputRef} onChange={onChangeHandler} />
      </HideVisually>
      <Button
        type="button"
        iconBefore={<IconAttachment size="sizeIconSmall1X" />}
        variant="tertiary"
        onClick={() => inputRef.current && inputRef.current.click()}
      >
        Upload file
      </Button>
    </>
  );
}
