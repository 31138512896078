// @flow
import { useCallback } from 'react';
import cx from 'classnames';
import { Box, Card, PostSummary } from '@getatomi/neon';

import type { ChallengeTier, LessonProgress, PostType } from 'src/types';
import type { Params as SubscriptionLinkParams } from 'src/components/SubscriptionLink/SubscriptionLink';
import { ActionMenu, type ActionMenuProps, Item } from 'src/components/ActionMenu/ActionMenu';
import SubscriptionLink from 'src/components/SubscriptionLink/SubscriptionLink';
import PostDuration from 'src/components/PostDuration/PostDuration';
import PostIcon from 'src/components/PostIcon/PostIcon';
import PostName from 'src/components/PostName/PostName';
import PostProgress from 'src/components/PostProgress/PostProgress';
import postTypes from 'src/constants/postTypes';
import PostUpgradePrompt from 'src/components/PostUpgradePrompt/PostUpgradePrompt';

import styles from './PostListItemLink.module.scss';

export function PostListItemContent(props: {
  challengeTier: ?ChallengeTier,
  color: string,
  dueDate?: ?React.Node,
  duration?: number,
  isCompleted?: boolean,
  isLocked: boolean,
  name: string,
  onProgressClick?: () => mixed,
  progress?: ?LessonProgress,
  progressCount?: number,
  studentsCount?: number,
  testHook?: string,
  type: PostType,
  withStrengthIndicator?: boolean,
  wrapper?: React.Element<$FlowSuppressAny>,
}) {
  const {
    challengeTier,
    color,
    duration,
    name,
    type,
    isLocked,
    isCompleted,
    dueDate,
    progress,
    testHook,
    onProgressClick,
    progressCount,
    studentsCount,
    withStrengthIndicator,
    wrapper,
  } = props;

  return (
    <PostSummary
      isCompleted={isCompleted}
      icon={
        <PostIcon
          postType={type}
          challengeTier={challengeTier}
          isCompleted={isCompleted}
          isLocked={isLocked}
          color={isLocked ? 'colorIconSubtle' : color}
          testHook={testHook && isCompleted ? `${testHook}-icon-completed` : undefined}
          className={styles.icon}
        />
      }
      name={
        <span className={cx(styles.postName, { [styles.followedByDueDate]: dueDate })}>
          <PostName name={name} type={type} challengeTier={challengeTier} />
        </span>
      }
      meta={
        <span className={styles.postMeta}>
          {duration ? <PostDuration duration={duration} type={type} challengeTier={challengeTier} /> : undefined}
        </span>
      }
      dueDate={
        dueDate ? (
          <span className={styles.dueDate} data-test={testHook && `${testHook}-due-date`}>
            {dueDate}
          </span>
        ) : undefined
      }
      progress={
        withStrengthIndicator || progressCount ? (
          <PostProgress
            color={color}
            progress={progress}
            onClick={onProgressClick}
            progressCount={progressCount}
            studentsCount={studentsCount}
            withStrengthIndicator={withStrengthIndicator}
          />
        ) : undefined
      }
      wrapper={wrapper}
      testHook={testHook}
    />
  );
}

export function PostListItemActions(props: {
  actions?: $PropertyType<ActionMenuProps, 'items'>,
  challengeTier: ?ChallengeTier,
  className?: string,
  color: string,
  duration?: number,
  isLocked?: boolean,
  name: string,
  testHook?: string,
  type: PostType,
}) {
  const { actions, challengeTier, className, color, isLocked = false, testHook, type, name, duration } = props;

  const handleAction = useCallback(
    (key: string) => {
      const action = actions && actions.find((a) => a.key === key);
      // $FlowIgnore (Flow does not yet support method calls in optional chains)
      action?.onSelect();
    },
    [actions]
  );

  return (
    <div className={cx(styles.actions, className)}>
      <ActionMenu
        renderHeader={(isTray) =>
          isTray && (
            <Box
              padding="spacingRoot"
              borderBottomWidth="borderWidthRoot"
              borderBottomStyle="solid"
              borderBottomColor="colorBorder"
            >
              <PostListItemContent
                challengeTier={challengeTier}
                color={color}
                type={type}
                name={name}
                duration={duration}
                isLocked={isLocked}
              />
            </Box>
          )
        }
        items={actions}
        onAction={handleAction}
        orientation="vertical"
        testHook={testHook}
      >
        {(item) => <Item>{item.label}</Item>}
      </ActionMenu>
    </div>
  );
}

type Props = {
  actions?: $PropertyType<ActionMenuProps, 'items'>,
  challengeTier: ?ChallengeTier,
  color: string,
  dueDate?: React.Node,
  duration: number,
  isLocked?: boolean,
  name: string,
  onProgressClick?: () => mixed,
  progress?: ?LessonProgress,
  progressCount?: number,
  routeParams: SubscriptionLinkParams,
  studentsCount?: number,
  testHook?: string,
  type: PostType,
  upgradePromptProps?: React.ElementProps<typeof PostUpgradePrompt>,
  withStrengthIndicator?: boolean,
};

export default function PostListItemLink(props: Props) {
  const {
    actions,
    challengeTier,
    color,
    duration,
    name,
    onProgressClick,
    routeParams,
    type,
    upgradePromptProps,
    isLocked = false,
    dueDate,
    progress,
    progressCount,
    studentsCount,
    testHook,
    withStrengthIndicator,
  } = props;

  if (!duration && !name && !routeParams && !type && !upgradePromptProps) return null;

  if (type === postTypes.section) return null;

  const isCompleted = !!progress;
  const post = (
    <PostListItemContent
      challengeTier={challengeTier}
      color={color}
      type={type}
      name={name}
      duration={duration}
      isLocked={isLocked}
      isCompleted={isCompleted}
      progress={progress}
      dueDate={dueDate}
      progressCount={progressCount}
      studentsCount={studentsCount}
      onProgressClick={onProgressClick}
      withStrengthIndicator={withStrengthIndicator}
      testHook={testHook}
      wrapper={
        isLocked ? (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button type="button" className={styles.postSummaryLink} />
        ) : (
          // $FlowIgnore children is set by the PostSummary component
          <SubscriptionLink
            {...routeParams}
            isUnstyled
            className={styles.postSummaryLink}
            testHook={testHook ? `${testHook}-link` : undefined}
          />
        )
      }
    />
  );

  return (
    <Card>
      <div
        className={cx(styles.root, {
          [styles.withProgress]: withStrengthIndicator || progressCount,
        })}
      >
        {isLocked ? (
          <PostUpgradePrompt {...upgradePromptProps}>
            <div className={styles.postSummary}>{post}</div>
          </PostUpgradePrompt>
        ) : (
          <div className={styles.postSummary}>{post}</div>
        )}

        {actions && (
          <PostListItemActions
            challengeTier={challengeTier}
            color={color}
            type={type}
            name={name}
            duration={duration}
            isLocked={isLocked}
            actions={actions}
            testHook={testHook && `${testHook}-actions-menu`}
          />
        )}
      </div>
    </Card>
  );
}
