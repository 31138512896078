// @flow
import { useEffect } from 'react';

export default function useBodyClass(className: string, isActive: boolean) {
  useEffect(() => {
    // effects run only on the client where we have a body
    const body = ((document.body: $FlowSuppressAny): HTMLBodyElement);
    body.classList.toggle(className, isActive);
    return () => {
      body.classList.remove(className);
    };
  }, [className, isActive]);
}
