// @flow
import cx from 'classnames';
import { Box, HelpText } from '@getatomi/neon';

import Logger from 'src/utils/Logger';
import type { BaseInputProps } from 'src/components/Input/Input';

import styles from './TextArea.module.scss';

const log = new Logger('components/TextArea');

const defaultErrorMessage = 'This field is required';

type Props = BaseInputProps & {
  id?: string,
  info?: React.Node,
  onValidate?: (value: string) => { errorMessage: string, isValid: boolean },
  rows?: number,
  value?: string,
  withFixedHeight: boolean,
};

type State = {
  errorMessage?: string,
  hasError: boolean,
  value?: string,
};

export default class TextArea extends React.Component<Props, State> {
  inputField: ?HTMLTextAreaElement;

  static defaultProps = {
    isMandatory: false,
    withFixedHeight: true,
  };

  state = {
    value: this.props.initialValue || '',
    hasError: false,
    errorMessage: defaultErrorMessage,
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({
      value: nextProps.value,
    });
  }

  onChange = (e: SyntheticInputEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    const { onChange } = this.props;
    let { hasError } = this.state;

    // hide errors when the user starts typing
    if (hasError && value.trim()) {
      hasError = false;
    }

    this.setState({ value, hasError });

    if (onChange) {
      onChange(this.props.name, value);
    }
  };

  onBlur = (e: SyntheticInputEvent<HTMLTextAreaElement>) => {
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur(this.props.name, e.currentTarget.value);
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  validate = () => {
    if (!this.inputField) return null;

    let isValid = true;
    let errorMessage = defaultErrorMessage;
    const value = this.inputField.value.trim();

    if (this.props.isMandatory) {
      isValid = !!value;
    }

    const { minLength } = this.props;

    if (isValid && minLength && value.length < minLength) {
      // minLength validation doesn't work cross browsers, do custom one instead
      isValid = false;
      errorMessage = `Length should be minimum ${minLength} characters`;
    } else if (this.props.pattern && this.inputField && !this.inputField.checkValidity()) {
      isValid = false;
      errorMessage = this.props.patternMismatchErrorMessage;
    }

    // do custom validation provided by the onValidate prop
    if (isValid && this.props.onValidate) {
      const validationOutput = this.props.onValidate(value);
      log.info(`Custom validation: IsValid ${this.props.name} ? ${validationOutput.isValid.toString()}`);
      this.setState({ hasError: !validationOutput.isValid, errorMessage: validationOutput.errorMessage });
      return validationOutput.isValid;
    }

    log.info(`IsValid ${this.props.name} ? ${isValid.toString()}`);
    this.setState({ hasError: !isValid, errorMessage });
    return isValid;
  };

  render() {
    const {
      autoCapitalize,
      className,
      id,
      info,
      maxLength,
      minLength,
      name,
      pattern,
      placeholder,
      testHook,
      title,
      rows,
      withFixedHeight,
    } = this.props;
    const { errorMessage, hasError, value } = this.state;

    const styleProps = {
      borderColor: hasError ? 'colorBorderDanger' : 'colorBorderInput',
      borderStyle: 'solid',
      borderWidth: 'borderWidthRoot',
      borderRadius: 'borderRadiusSmall',
      color: 'colorText',
      fontFamily: 'fontFamilySystem',
      height: withFixedHeight ? '150px' : 'auto',
      lineHeight: 'lineHeightSmall',
      padding: 'spacingSmall1X spacingSmall',
      paddingRight: hasError ? '35px' : undefined,
      width: 'sizeFull',
      _focusWithin: {
        borderColor: 'colorBorderFocused',
        boxShadow: 'none',
      },
    };

    return (
      <div className={cx(styles.root, className)} data-test={testHook}>
        <span className={styles.inputWrapper}>
          <Box
            as="textarea"
            {...styleProps}
            ref={(input) => {
              this.inputField = input;
            }}
            id={id}
            name={name}
            minLength={minLength}
            maxLength={maxLength}
            pattern={pattern}
            placeholder={placeholder}
            title={title}
            value={value}
            rows={rows}
            autoCapitalize={autoCapitalize}
            onChange={this.onChange}
            onBlur={this.onBlur}
            data-test={testHook && `${testHook}-textarea`}
          />
        </span>
        {info && <HelpText>{info}</HelpText>}
        {hasError && <HelpText variant="error">{errorMessage}</HelpText>}
      </div>
    );
  }
}
