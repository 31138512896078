// @flow
import { useState } from 'react';
import { HideVisually, TextLoader, Truncate } from '@getatomi/neon';

import { trackingCtaTypes, trackingEvents, trackingCtas } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';
import SuccessRate from 'src/components/SuccessRate/SuccessRate';

import type { Assessment, ClassAverage, Module, MarkCellValue, MarksData, Topic } from './marksTransformer';
import type { Student } from '../utils/prepareStudentData';
import ColumnHeaderCell, { ItemCountSubContent } from '../components/ColumnHeaderCell';
import RowMetaCell from '../components/RowMetaCell';

type Cell<T> = {
  // False positive - Even if Cell() gets called, the props that are only used inside the component
  // are not be considered "used" by Cell().
  // eg: `cell: ({ value }: Cell<MarksQuestion>)`
  // https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/no-unused-prop-types.md#known-issueslimitations
  // eslint-disable-next-line
  getValue: () => T,
  row: {
    original: MarksData,
  },
};

type ColumnMeta = {
  headerTemplate?: (headerContent: React.Node) => React.Node,
  isHeading?: boolean,
  width?: number,
};

export type Column = {
  accessorKey: string,
  cell: (cell: Cell<any>) => React.Node,
  columns?: ?$ReadOnlyArray<Column>,
  enableSorting?: boolean,
  header: () => React.Node,
  meta?: ColumnMeta,
};

const loadingCell = ({ row }) => {
  // $FlowIgnore - index is missing
  return <TextLoader maxWidth={row.index % 2 ? '50%' : 'sizeFull'} testHook="loading-cell" />;
};

const renderCompletionCell = () =>
  function render({ getValue }: Cell<?MarkCellValue>) {
    const mark = getValue();

    if (mark == null) {
      return 'Not started';
    }

    return <SuccessRate successRate={mark.displayValue} />;
  };

const renderAssessmentCell = ({ getValue }: Cell<?MarkCellValue>) => {
  const mark = getValue();

  if (mark == null) {
    return '-';
  }

  return <SuccessRate successRate={mark.displayValue} />;
};

function prepareAssessmentColumns(assessments: ?$ReadOnlyArray<Assessment>, classId: string) {
  return (
    assessments &&
    assessments.map((assessment) => {
      const loadingAssessment = !assessment.name;
      return {
        accessorKey: `column-assessment-${assessment.id}`,
        header: () => {
          return <Truncate>{assessment.name}</Truncate>;
        },
        cell: loadingAssessment ? loadingCell : renderAssessmentCell,
        meta: {
          // If there is only one assessment, we get clipping issues if we set width to 100px
          width: assessments && assessments.length > 1 ? 100 : undefined,
          headerTemplate: (headerContent: React.Node) => {
            if (loadingAssessment) {
              return <HideVisually>Loading…</HideVisually>;
            }
            return (
              <ColumnHeaderCell
                headerContent={headerContent}
                links={[
                  { key: 'view-lesson', name: 'View lesson', href: assessment.lessonUrl },
                  { key: 'view-progress-report', name: 'View progress report', href: assessment.progressReportUrl },
                ]}
                onLinkAction={(key: string) => {
                  if (key === 'view-lesson') {
                    trackEvent(trackingEvents.ctaClicked, {
                      classId,
                      cta: trackingCtas.viewLesson,
                      type: trackingCtaTypes.button,
                    });
                  } else if (key === 'view-progress-report') {
                    trackEvent(trackingEvents.ctaClicked, {
                      classId,
                      cta: trackingCtas.classProgressOpenDialog,
                      type: trackingCtaTypes.button,
                    });
                  }
                }}
              />
            );
          },
        },
      };
    })
  );
}

function prepareTopicColumns(
  topics: ?$ReadOnlyArray<Topic>,
  expandedTopicId: ?string,
  setExpandedTopic: (?string) => void,
  classId: string
): ?$ReadOnlyArray<Column> {
  return (
    topics &&
    topics
      .filter((topic) => {
        const loading = !topic.name;

        return loading || topic.lessonCount > 0;
      })
      .map((topic) => {
        const isExpanded = expandedTopicId === topic.id;
        const loading = !topic.name;

        return {
          header: () => topic.name,
          enableSorting: !isExpanded,
          accessorKey: `column-${topic.id}`,
          cell: loading ? loadingCell : renderCompletionCell(),
          meta: {
            headerTemplate: (headerContent: React.Node) => {
              if (loading) {
                return <HideVisually>Loading…</HideVisually>;
              }
              return (
                <ColumnHeaderCell
                  isExpanded={isExpanded}
                  onExpand={() => setExpandedTopic(isExpanded ? null : topic.id)}
                  headerContent={headerContent}
                  headerSubContent={<ItemCountSubContent itemCount={topic.lessonCount} itemLabel="Lesson" />}
                  trackingData={{
                    cta: trackingCtas.expandColumnTopic,
                    data: { classId },
                  }}
                  links={[{ key: 'view-insights', name: 'View insights', href: topic.insightsUrl }]}
                  onLinkAction={(key: string) => {
                    if (key === 'view-insights') {
                      trackEvent(trackingEvents.ctaClicked, {
                        classId,
                        cta: trackingCtas.viewTopicInsights,
                        type: trackingCtaTypes.button,
                      });
                    }
                  }}
                />
              );
            },
          },
          columns: prepareAssessmentColumns(topic.assessments, classId),
        };
      })
  );
}

export function prepareMarksColumns({
  isMobile,
  columnList,
  lessonCount,
  expandedModuleState,
  expandedTopicState,
  classId,
}: {
  classId: string,
  columnList: $ReadOnlyArray<Module>,
  expandedModuleState: $Call<typeof useState>,
  expandedTopicState: $Call<typeof useState>,
  isMobile: boolean,
  lessonCount: number,
}): $ReadOnlyArray<Column> {
  const [expandedModuleId, setExpandedModule] = expandedModuleState;
  const [expandedTopicId, setExpandedTopic] = expandedTopicState;
  return [
    {
      header: () => 'Students',
      accessorKey: 'rowMeta',
      cell: ({ getValue }: Cell<Student | ClassAverage>) => <RowMetaCell value={getValue()} isMobile={isMobile} />,
      sortDescFirst: false,
      meta: {
        isHeading: true,
      },
    },
    {
      header: () => 'Overall average',
      accessorKey: 'column-overall',
      cell: renderCompletionCell(),
      meta: {
        headerTemplate: (headerContent: React.Node) => (
          <ColumnHeaderCell
            headerContent={headerContent}
            headerSubContent={<ItemCountSubContent itemCount={lessonCount} itemLabel="Lesson" />}
          />
        ),
      },
    },
    ...columnList
      .filter((module) => module.lessonCount > 0)
      .map((module) => {
        const isExpanded = expandedModuleId === module.id;
        return {
          accessorKey: `column-${module.id}`,
          enableSorting: !isExpanded,
          header: () => module.name,
          cell: renderCompletionCell(),
          meta: {
            headerTemplate: (headerContent: React.Node) => {
              return (
                <ColumnHeaderCell
                  headerContent={headerContent}
                  headerSubContent={<ItemCountSubContent itemCount={module.lessonCount} itemLabel="Lesson" />}
                  isExpanded={isExpanded}
                  onExpand={
                    module.categoryCount
                      ? () => {
                          setExpandedModule(isExpanded ? null : module.id);
                          setExpandedTopic(null);
                        }
                      : undefined
                  }
                  links={[
                    {
                      key: 'view-module',
                      name: 'View module',
                      // $FlowIgnore - we know moduleUrl is defined for module columns
                      href: module.moduleUrl,
                    },
                  ]}
                  onLinkAction={(key: string) => {
                    if (key === 'view-module') {
                      trackEvent(trackingEvents.ctaClicked, {
                        cta: trackingCtas.viewModule,
                        type: trackingCtaTypes.button,
                        classId,
                      });
                    }
                  }}
                  trackingData={{
                    cta: trackingCtas.expandColumnModule,
                    data: { classId },
                  }}
                />
              );
            },
          },
          columns: prepareTopicColumns(module.topics, expandedTopicId, setExpandedTopic, classId),
        };
      }),
  ];
}

export default prepareMarksColumns;
