// @flow
import { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import _ from 'lodash';
import { Flex } from '@getatomi/neon';

import type { ApiError } from 'src/types';
import Logger from 'src/utils/Logger';
import ErrorTypeMessage from 'src/components/ErrorTypeMessage/ErrorTypeMessage';
import FormMessage from 'src/components/FormMessage/FormMessage';
import { type CreateIntent, initCardPayment } from 'src/utils/payments';

import CardInput from '../CardInput/CardInput';

const log = new Logger('components/CardDetails/CardForm');

export type CardFormProps = {
  children?: React.Node,
  createIntent: CreateIntent,
  formId?: string,
  onError: (error: ApiError) => mixed,
  onRequest: () => mixed,
  onSuccess: (intentId: string) => mixed,
  serverError?: ?ApiError,
};

export default function CardForm(props: CardFormProps) {
  const { children, formId, createIntent, onError, onRequest, onSuccess, serverError } = props;

  const [error, setError] = useState<?string>(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e: SyntheticEvent<>) => {
    e.preventDefault();
    setError(null);

    // throttle onRequest to prevent onRequest and onError to be called at almost the same time
    // which would create "jumpy" UI i.e. the submit button state switching from loading back to
    // normal state in a very short period of time
    const throttledRequest = _.throttle(onRequest, 100, { leading: false });
    throttledRequest();

    const cardElement = elements.getElement(CardElement);

    try {
      const intentId = await initCardPayment(stripe, cardElement, createIntent);
      onSuccess(intentId);
    } catch (err) {
      log.warn('Error initiating card payment', err);
      throttledRequest.cancel();
      setError(err.message);
      onError(err);
    }
  };

  return (
    <form method="post" onSubmit={handleSubmit} id={formId} data-test="card-form">
      {serverError && (
        <FormMessage>
          <ErrorTypeMessage error={serverError} useAPIMessage />
        </FormMessage>
      )}
      <Flex alignItems="center" direction="column">
        <CardInput error={error} />
      </Flex>
      {children}
    </form>
  );
}
