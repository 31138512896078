// @flow
import _ from 'lodash';
import invariant from 'invariant';

import type {
  GetMarkbookTasks_me_account as GraphqlAccount,
  GetMarkbookTasks_me_account_class as GraphqlClass,
  GetMarkbookTasks_me_account_class_metrics_ClassMetrics_tasks as GraphqlClassTaskMetrics,
  GetMarkbookTasks_me_account_class_metrics_ClassMetrics_tasks_students_timeseries as GraphqlStudentsTasksMetricsByPeriod,
  GetMarkbookTasks_me_account_class_metrics_ClassMetrics_tasks_timeseries as GraphqlClassTaskMetricsByPeriod,
} from 'src/graphql/types/generated/GetMarkbookTasks';
import type {
  DateRangeInput,
  GetMarkbookExpandedTasks_me_account_class_tasks as ExpandedTask,
} from 'src/graphql/types/generated/GetMarkbookExpandedTasks';
import taskPublicationStatuses from 'src/constants/taskPublicationStatuses';
import taskProgressStatuses, { type TaskProgressStatus } from 'src/constants/taskProgressStatuses';
import createCellValue, { type CellValue } from 'src/utils/createCellValue';
import { getTaskUrl } from 'src/utils/routes';
import { taskProgressStatusMap } from 'src/components/TaskProgressStatus/TaskProgressStatus';

import getPercentageValue from '../utils/getPercentageValue';
import prepareStudentData, { type Student } from '../utils/prepareStudentData';

export type TaskCompletionCellValue = CellValue<number, number>;

export type TaskProgressCellValue = CellValue<number | ?TaskProgressStatus, ?number>;

type TransformedExpandedTask = {
  dueDate: string,
  id: string,
  studentCount: number,
  title: ?string,
  url: string,
};

export type Column = {
  assignedCount: number,
  id: string,
  name: string,
  periodRange?: DateRangeInput,
  tasks?: ?$ReadOnlyArray<TransformedExpandedTask>,
};

export type ExpandedColumn = {
  periodRange: ?DateRangeInput,
  tasks: ?$ReadOnlyArray<ExpandedTask>,
};

export type ClassAverage = {
  type: 'ClassAverage',
};

export type ClassAverageRow = {
  'column-overall': ?TaskCompletionCellValue,
  'column-total': TaskCompletionCellValue,
  isInactive: boolean,
  rowMeta: ClassAverage,
  [PeriodKey: string]: TaskCompletionCellValue,
};

export type TasksData = {
  'column-overall': ?TaskCompletionCellValue,
  'column-total': TaskCompletionCellValue,
  isInactive: boolean,
  rowMeta: Student,
  [PeriodKey: string]: TaskCompletionCellValue,
};

export type TransformedTasksData = {
  classAverageRow: ClassAverageRow,
  className: string,
  columnList: $ReadOnlyArray<Column>,
  isClassEmpty: boolean,
  isDataExportEnabled: boolean,
  isFreePlan: boolean,
  region: string,
  tasksData: $ReadOnlyArray<TasksData>,
};

type LoadingTask = {
  dueAt: string,
  id: string,
  status: typeof taskPublicationStatuses.published,
  students: [],
  title: string,
};

function getloadingTasks(count: number): $ReadOnlyArray<LoadingTask> {
  return Array.from({ length: count }).map((v, i) => ({
    id: `loading-task-${i}`,
    dueAt: '',
    status: taskPublicationStatuses.published,
    title: '',
    students: [],
  }));
}

function isExpandedPeriod(thisPeriod, expandedColumn): boolean {
  if (!expandedColumn || !expandedColumn.periodRange) {
    return false;
  }
  return (
    // $FlowIgnore - we know `periodRange` is defined
    expandedColumn.periodRange.from === thisPeriod.startsAt &&
    // $FlowIgnore - we know `periodRange` is defined
    expandedColumn.periodRange.to === thisPeriod.endsAt
  );
}

function prepareExpandedTasks(
  tasks: ?$ReadOnlyArray<ExpandedTask | LoadingTask>,
  classId: string,
  accountId: string
): $ReadOnlyArray<TransformedExpandedTask> {
  if (!tasks) {
    return [];
  }

  const publishedTasks = tasks.filter((task) => task.status === taskPublicationStatuses.published);

  return publishedTasks.map((task) => ({
    id: task.id,
    title: task.title,
    dueDate: task.dueAt,
    studentCount: task.students.length,
    url: getTaskUrl(accountId, classId, task.id),
  }));
}

export function getPeriodName(dateISO: string): string {
  return new Date(dateISO).toLocaleDateString('en-AU', { month: 'long' });
}

function getPeriodId(dateISO: string): string {
  return new Date(dateISO).toLocaleDateString('en-AU', { month: 'long', year: 'numeric' });
}

function preparePeriodList(
  tasks: GraphqlClassTaskMetrics,
  expandedColumn: ExpandedColumn,
  classId: string,
  accountId: string
): $ReadOnlyArray<Column> {
  const periodsWithNames = tasks.timeseries.map((period) => {
    const periodId = getPeriodId(period.startsAt);
    const periodName = getPeriodName(period.startsAt);
    return {
      assignedCount: period.assignedCount,
      id: periodId,
      name: periodName,
      periodRange: {
        from: period.startsAt,
        to: period.endsAt,
      },
      tasks: isExpandedPeriod(period, expandedColumn)
        ? prepareExpandedTasks(expandedColumn.tasks ?? getloadingTasks(period.assignedCount), classId, accountId)
        : null,
    };
  });

  return periodsWithNames;
}

function transformTimeseries(
  timeseries: $ReadOnlyArray<GraphqlClassTaskMetricsByPeriod | GraphqlStudentsTasksMetricsByPeriod>
): { [PeriodColumn: string]: TaskCompletionCellValue } {
  const periodData = {};
  for (const period of timeseries) {
    const periodId = getPeriodId(period.startsAt);
    periodData[`column-${periodId}`] = period.assignedCount
      ? createCellValue(getPercentageValue(period.completionRate, 1))
      : undefined;
  }
  return periodData;
}

function transformExpandedColumnsForStudent(
  studentId: string,
  expandedTasks: ?$ReadOnlyArray<ExpandedTask>
): { [string]: string } {
  const expandedColumns = {};

  if (!expandedTasks) {
    return expandedColumns;
  }

  for (const task of expandedTasks) {
    const student = task.students.find((taskStudent) => taskStudent.id === studentId);
    if (student) {
      let renderStatus;
      switch (student.progress.status) {
        case 'COMPLETE': {
          renderStatus = taskProgressStatuses.done;
          break;
        }
        case 'PENDING':
        case 'IN_PROGRESS': {
          const isOverdue = new Date() > new Date(task.dueAt);
          if (isOverdue) {
            renderStatus = taskProgressStatuses.overdue;
          } else {
            renderStatus =
              student.progress.status === 'PENDING' ? taskProgressStatuses.toDo : taskProgressStatuses.inProgress;
          }
          break;
        }
        default: {
          renderStatus = taskProgressStatuses.notAssigned;
        }
      }
      expandedColumns[`expanded-column-${task.id}`] = createCellValue(
        renderStatus,
        taskProgressStatusMap[renderStatus].rank
      );
    }
  }

  return expandedColumns;
}

function transformExpandedColumnsForClass(
  totalStudents: number,
  expandedTasks: ?$ReadOnlyArray<ExpandedTask>
): { [string]: number } {
  const expandedColumns = {};

  if (!totalStudents || !expandedTasks) {
    return expandedColumns;
  }

  for (const task of expandedTasks) {
    const totalStudentsCompleted = task.students.filter((student) => student.progress.status === 'COMPLETE').length;
    const taskCompletionRate = _.round((totalStudentsCompleted / totalStudents) * 100, 0);
    expandedColumns[`expanded-column-${task.id}`] = createCellValue(taskCompletionRate);
  }

  return expandedColumns;
}

export function transformTasksData({
  accountData,
  classData,
  expandedColumn,
}: {
  accountData: GraphqlAccount,
  classData: GraphqlClass,
  expandedColumn: ExpandedColumn,
}) {
  const { id: accountId, enabledFeatures, plan, region } = accountData;
  const { id: classId, metrics, name: className, students } = classData;

  invariant(metrics.__typename === 'ClassMetrics', 'Tasks class metrics type should be ClassMetrics');
  const taskMetrics = metrics.tasks;

  const isDataExportEnabled = !enabledFeatures.includes('DENY_STUDENT_DATA_EXPORT');
  const isFreePlan = plan.isFree;
  const isClassEmpty = metrics.studentCount === 0;

  const classAverageRow = {
    rowMeta: {
      type: 'ClassAverage',
    },
    isInactive: _.sum(taskMetrics.timeseries.map((period) => period.assignedCount)) === 0,
    'column-total': createCellValue(taskMetrics.assignedCount),
    'column-overall': taskMetrics.assignedCount
      ? createCellValue(getPercentageValue(taskMetrics.completionRate, 1))
      : undefined,
    ...transformTimeseries(taskMetrics.timeseries),
    ...transformExpandedColumnsForClass(students.length, expandedColumn.tasks),
  };

  const tasksData = taskMetrics.students
    .map((student) => {
      const isInactive = getPercentageValue(student.completionRate, 1) === 0;

      const rowMeta = prepareStudentData(student.studentId, students, isInactive);
      if (!rowMeta) return null;

      return {
        rowMeta,
        isInactive,
        'column-total': createCellValue(student.assignedCount),
        'column-overall': student.assignedCount
          ? createCellValue(getPercentageValue(student.completionRate, 1))
          : undefined,
        ...transformTimeseries(student.timeseries),
        ...transformExpandedColumnsForStudent(student.studentId, expandedColumn.tasks),
      };
    })
    .filter(Boolean);

  return {
    classAverageRow,
    className,
    tasksData,
    columnList: preparePeriodList(taskMetrics, expandedColumn, classId, accountId),
    isClassEmpty,
    isDataExportEnabled,
    isFreePlan,
    region: region.code,
  };
}
