// @flow
import keyMirror from 'keymirror';

import type { SessionDuration, SessionLengthFilter } from './types';
import { getDurationRange } from './utils';

export const contentTypes = keyMirror({
  all: null,
  covered: null,
  custom: null,
});

export const sessionLengths = keyMirror({
  short: null,
  medium: null,
  long: null,
});

export const questionTypes = keyMirror({
  multiChoice: null,
  dragAndDrop: null,
  exactAnswer: null,
  shortAnswer: null,
  extendedResponse: null,
});

const minutesToSeconds = (durationMinutes: number): number => {
  return durationMinutes * 60;
};

export const sessionLengthMinutes: {
  [key: SessionLengthFilter]: number,
} = {
  [sessionLengths.short]: 10,
  [sessionLengths.medium]: 30,
  [sessionLengths.long]: 60,
};

export const teacherSessionDurations: {
  [key: SessionLengthFilter]: SessionDuration,
} = {
  [sessionLengths.short]: {
    ...getDurationRange(minutesToSeconds(sessionLengthMinutes[sessionLengths.short])),
    displayLabel: '10 mins',
  },
  [sessionLengths.medium]: {
    ...getDurationRange(minutesToSeconds(sessionLengthMinutes[sessionLengths.medium])),
    displayLabel: '30 mins',
  },
  [sessionLengths.long]: {
    ...getDurationRange(minutesToSeconds(sessionLengthMinutes[sessionLengths.long])),
    displayLabel: '1 hour',
  },
};

export const studentSessionDurations: {
  [key: SessionLengthFilter]: SessionDuration,
} = {
  [sessionLengths.short]: {
    ...getDurationRange(minutesToSeconds(sessionLengthMinutes[sessionLengths.short])),
    displayLabel: '10 mins',
  },
  [sessionLengths.medium]: {
    ...getDurationRange(minutesToSeconds(sessionLengthMinutes[sessionLengths.medium])),
    displayLabel: '30 mins',
  },
  [sessionLengths.long]: {
    ...getDurationRange(minutesToSeconds(sessionLengthMinutes[sessionLengths.long])),
    displayLabel: '1 hour',
  },
};
