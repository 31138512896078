// @flow
import { useState } from 'react';
import { Box, Flex, HideVisually, IconCalendar, TextLoader, Truncate } from '@getatomi/neon';

import { trackingCtaTypes, trackingEvents, trackingCtas } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';
import { getTaskProgressReportUrl } from 'src/utils/routes';
import taskProgressStatuses from 'src/constants/taskProgressStatuses';
import TaskProgressStatus from 'src/components/TaskProgressStatus/TaskProgressStatus';

import type {
  ClassAverage,
  Column,
  TaskCompletionCellValue,
  TasksData,
  TaskProgressCellValue,
} from './tasksTransformer';
import type { Student } from '../utils/prepareStudentData';
import ColumnHeaderCell from '../components/ColumnHeaderCell';
import ProgressCell from '../components/ProgressCell';
import RowMetaCell from '../components/RowMetaCell';

type Cell<T> = {
  // False positive - Even if Cell() gets called, the props that are only used inside the component
  // are not be considered "used" by Cell().
  // eg: `cell: ({ value }: Cell<MarksQuestion>)`
  // https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/no-unused-prop-types.md#known-issueslimitations
  // eslint-disable-next-line
  getValue: () => T,
  row: {
    original: TasksData,
  },
};

type ColumnMeta = {
  headerTemplate?: (headerContent: React.Node) => React.Node,
  isHeading?: boolean,
  width?: number,
};

export type TableColumn = {
  accessorKey: string,
  cell?: (cell: Cell<any>) => React.Node,
  enableSorting?: boolean,
  header: React.Node | (() => React.Node),
  meta?: ColumnMeta,
};

function prepareTasksColumns(
  isMobile: boolean,
  columnList: $ReadOnlyArray<Column>,
  expandedColumnState: $Call<typeof useState>,
  subscriptionId: string,
  classId: string
) {
  const [, setExpandedPeriodRange] = expandedColumnState;

  return [
    {
      header: () => 'Students',
      accessorKey: 'rowMeta',
      cell: ({ getValue }: Cell<Student | ClassAverage>) => <RowMetaCell value={getValue()} isMobile={isMobile} />,
      sortDescFirst: false,
      meta: {
        isHeading: true,
      },
    },
    {
      header: () => 'Overall completion',
      accessorKey: 'column-overall',
      cell: ({ getValue, row }: Cell<TaskCompletionCellValue>) => {
        if (row.original['column-total'].displayValue === 0) {
          return 'No tasks';
        }

        const overallCompletion = getValue().displayValue;
        return <ProgressCell percentageValue={overallCompletion} type="task" />;
      },
    },
    {
      header: () => 'Total tasks assigned',
      accessorKey: 'column-total',
      cell: ({ getValue }: Cell<TaskCompletionCellValue>) => getValue().displayValue,
    },
    ...columnList.map((column) => {
      const isExpanded = Boolean(column.tasks && column.tasks.length > 0);

      return {
        header: () => column.name,
        accessorKey: `column-${column.id}`,
        enableSorting: !isExpanded,
        cell: ({ getValue }: Cell<?TaskCompletionCellValue>) => {
          const completion = getValue();

          if (completion == null) {
            return 'No tasks';
          }

          return <ProgressCell percentageValue={completion.displayValue} type="task" />;
        },
        meta: {
          headerTemplate: (headerContent: React.Node) => {
            return (
              <ColumnHeaderCell
                headerContent={headerContent}
                isExpanded={isExpanded}
                onExpand={
                  // `assignedCount` only counts published tasks, and not draft/scheduled tasks
                  column.assignedCount > 0
                    ? () => setExpandedPeriodRange(isExpanded ? null : column.periodRange)
                    : undefined
                }
                trackingData={{
                  cta: trackingCtas.expandColumnMonth,
                  data: { classId },
                }}
              />
            );
          },
        },
        columns:
          column.tasks &&
          (column.tasks.map((task) => {
            const loading = !task.title;

            return {
              header: <Truncate>{task.title}</Truncate>,
              accessorKey: `expanded-column-${task.id}`,
              cell: ({ getValue, row }: Cell<TaskProgressCellValue>) => {
                if (loading) {
                  // $FlowIgnore index is defined by react-table
                  return <TextLoader maxWidth={row.index % 2 ? '50%' : 'sizeFull'} testHook="loading-cell" />;
                }

                const value = getValue();

                if (typeof value?.displayValue === 'number') {
                  // $FlowIgnore - we know `displayValue` is a number
                  return <ProgressCell percentageValue={value.displayValue} type="task" />;
                }

                // $FlowIgnore - we know `displayValue` is a TaskProgressStatus
                return <TaskProgressStatus status={value?.displayValue ?? taskProgressStatuses.notAssigned} />;
              },
              meta: {
                headerTemplate: (headerContent: React.Node) => {
                  if (loading) {
                    return <HideVisually>Loading…</HideVisually>;
                  }

                  const formattedDueDate = new Date(task.dueDate)
                    .toLocaleDateString('en-AU', {
                      weekday: 'short',
                      day: 'numeric',
                      month: 'short',
                    })
                    .replace(',', '');

                  return (
                    <ColumnHeaderCell
                      headerContent={headerContent}
                      headerSubContent={
                        <Flex alignItems="center" gap="spacingSmall1X">
                          <IconCalendar size="sizeIconSmall1X" color="colorIcon" />
                          <Box as="p" color="colorTextSubtle" fontWeight="fontWeightRegular">
                            {`Due ${formattedDueDate}`}
                          </Box>
                        </Flex>
                      }
                      links={[
                        {
                          key: 'view-task',
                          name: 'View task',
                          href: task.url,
                        },
                        {
                          key: 'view-task-progress',
                          name: 'View task progress',
                          href: getTaskProgressReportUrl(subscriptionId, classId, task.id),
                        },
                      ]}
                      onLinkAction={(key: string) => {
                        if (key === 'view-task') {
                          trackEvent(trackingEvents.ctaClicked, {
                            classId,
                            cta: trackingCtas.viewTask,
                            type: trackingCtaTypes.button,
                          });
                        } else if (key === 'view-task-progress') {
                          trackEvent(trackingEvents.ctaClicked, {
                            classId,
                            cta: trackingCtas.viewTaskProgressReport,
                            type: trackingCtaTypes.button,
                          });
                        }
                      }}
                    />
                  );
                },
              },
            };
          }): $ReadOnlyArray<TableColumn>),
      };
    }),
  ];
}

export default prepareTasksColumns;
