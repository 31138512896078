// @flow
import { connect } from 'react-redux';
import { Switch } from '@getatomi/neon';

import { isSubscriptionActive as isSubscriptionActiveSelector } from 'src/reducers/subscriptions';

import TurnOffAutoRenewalDialog from '../TurnOffAutoRenewalDialog/TurnOffAutoRenewalDialog';
import TurnOnAutoRenewalDialog from '../TurnOnAutoRenewalDialog/TurnOnAutoRenewalDialog';

type Props = {
  isSubscriptionActive?: boolean,
};

type State = {
  isModalVisible: boolean,
};

const mapStateToProps = (state): Props => ({
  isSubscriptionActive: isSubscriptionActiveSelector(state),
});

export default
@connect(mapStateToProps)
class ToggleAutoRenewal extends React.Component<Props, State> {
  state = {
    isModalVisible: false,
  };

  onToggleModal = () => {
    this.setState((prevState) => ({ isModalVisible: !prevState.isModalVisible }));
  };

  shouldComponentUpdate(nextProps: Props) {
    return nextProps.isSubscriptionActive === this.props.isSubscriptionActive;
  }

  render() {
    const { isSubscriptionActive } = this.props;
    const { isModalVisible } = this.state;

    const Modal = isSubscriptionActive ? TurnOffAutoRenewalDialog : TurnOnAutoRenewalDialog;

    return (
      <>
        <Switch label="Automatic-renewal" onChange={this.onToggleModal} isSelected={isSubscriptionActive} />
        <Modal isVisible={isModalVisible} onClose={this.onToggleModal} />
      </>
    );
  }
}
