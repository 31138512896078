// @flow
import cx from 'classnames';
import { Box, Container } from '@getatomi/neon';

import type { Subscription, UserType } from 'src/types';

import MobileDialogTrigger from './MobileDialogTrigger/MobileDialogTrigger';
import Nav from '../Nav/Nav';
import SettingsLink from '../SettingsLink/SettingsLink';
import SubscriptionsList from '../SubscriptionsList/SubscriptionsList';
import UpgradeCta from '../UpgradeCta/UpgradeCta';
import UserNameAvatar from '../UserNameAvatar/UserNameAvatar';
import TrialExpiryBadge from '../TrialExpiryBadge/TrialExpiryBadge';
import styles from './MobileDialog.module.scss';

type Props = {
  canViewInsights: boolean,
  isFreeFamilyPlan: boolean,
  isFreeSchoolPlan: boolean,
  isFreeStudentPlan: boolean,
  isLoggedInAsAccountManager: boolean,
  isLoggedInAsChild: boolean,
  isLoggedInAsParent: boolean,
  isLoggedInAsStudent: boolean,
  isLoggedInAsSuperAdmin: boolean,
  isOnTrial: boolean,
  isOpen: boolean,
  isRetailPlan: boolean,
  isSubscriptionCancelled: boolean,
  onLogout: () => mixed,
  onNavigate: () => mixed,
  onToggle: () => mixed,
  otherSubscriptions: Array<Subscription>,
  subscription: Subscription,
  trialEndDate: ?string,
  user: UserType,
  withUpgradeCta?: boolean,
};

export default function MobileDialog(props: Props) {
  const {
    otherSubscriptions,
    isFreeSchoolPlan,
    isFreeStudentPlan,
    canViewInsights,
    isLoggedInAsAccountManager,
    isLoggedInAsChild,
    isLoggedInAsParent,
    isLoggedInAsStudent,
    isLoggedInAsSuperAdmin,
    isOnTrial,
    isOpen,
    isFreeFamilyPlan,
    isRetailPlan,
    isSubscriptionCancelled,
    onLogout,
    onNavigate,
    onToggle,
    subscription,
    user,
    trialEndDate,
    withUpgradeCta = true,
  } = props;

  if (
    !isFreeSchoolPlan &&
    !isFreeStudentPlan &&
    !isLoggedInAsAccountManager &&
    !isOnTrial &&
    !isOpen &&
    !isRetailPlan &&
    !isSubscriptionCancelled &&
    !onNavigate &&
    !onToggle &&
    !subscription &&
    !user
  )
    return null;

  const hasMultipleSubscriptions = otherSubscriptions.length > 0;
  const isFreePlan = isFreeStudentPlan || isFreeFamilyPlan || isFreeSchoolPlan;

  return (
    <>
      <MobileDialogTrigger onClick={onToggle} isActive={isOpen} />
      <div
        className={cx(styles.root, {
          [styles.isOpen]: isOpen,
        })}
      >
        <Container maxWidth="sizeContainerSmall">
          {/** User name and avatar **/}
          <Box marginBottom="spacingLarge">
            <UserNameAvatar
              avatarSize="sizeAvatarLarge"
              isLoggedInAsAccountManager={isLoggedInAsAccountManager}
              subscription={subscription}
              user={user}
            />
          </Box>
          {/** Settings link and upgrade CTA **/}
          <Box marginBottom="spacingLarge3X">
            <div className={styles.settingsLinkUpgradeCta}>
              <SettingsLink
                onNavigate={onNavigate}
                subscriptionId={subscription.id}
                isLoggedInAsAccountManager={isLoggedInAsAccountManager}
              />

              {/** Upgrade CTA **/}
              {withUpgradeCta && isFreePlan && (
                <UpgradeCta
                  isChild={isLoggedInAsChild}
                  isRetail={isFreeStudentPlan || isFreeFamilyPlan}
                  onNavigate={onNavigate}
                />
              )}
            </div>
          </Box>
          {/** Trial expiry tag **/}
          <Box marginBottom="spacingRoot">
            {isOnTrial && trialEndDate && !isSubscriptionCancelled && (
              <TrialExpiryBadge trialEndDate={trialEndDate} textAlign="center" />
            )}
          </Box>
          {/** Nav **/}
          <Nav
            isLoggedInAsParent={isLoggedInAsParent}
            isLoggedInAsStudent={isLoggedInAsStudent}
            isRetailPlan={isRetailPlan}
            onLogout={onLogout}
            onNavigate={onNavigate}
            canViewInsights={canViewInsights}
          />
        </Container>

        {/** Subscriptions list **/}
        {hasMultipleSubscriptions && (
          <Box marginTop="spacingLarge">
            <SubscriptionsList
              isFreeFamilyPlan={isFreeFamilyPlan}
              isFreeSchoolPlan={isFreeSchoolPlan}
              isFreeStudentPlan={isFreeStudentPlan}
              isLoggedInAsAccountManager={isLoggedInAsAccountManager}
              isLoggedInAsChild={isLoggedInAsChild}
              isLoggedInAsParent={isLoggedInAsParent}
              isLoggedInAsSuperAdmin={isLoggedInAsSuperAdmin}
              otherSubscriptions={otherSubscriptions}
              subscription={subscription}
              user={user}
            />
          </Box>
        )}
      </div>
    </>
  );
}
