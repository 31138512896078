// @flow
import { useMutation } from '@apollo/client';
import { useToast } from '@getatomi/neon';

import type { EditClassTask, EditClassTaskVariables } from 'src/graphql/types/generated/EditClassTask';

import EDIT_CLASS_TASK from '../EditClassTask.graphql';

export const showToast = (data: EditClassTask, toast: $Call<typeof useToast>) => {
  if (!data.editClassTask) return;

  const draftOrTaskLabel = data.editClassTask.status === 'draft' ? 'draft' : 'task';
  toast.success(`Your ${draftOrTaskLabel} has been updated.`);
};

export default function useEditTask() {
  const toast = useToast();

  const [editTask] = useMutation<EditClassTask, EditClassTaskVariables>(EDIT_CLASS_TASK, {
    refetchQueries: ['GetClassTasks', 'GetClassTask'],
    awaitRefetchQueries: true,
    onCompleted: (data) => showToast(data, toast),
  });

  return editTask;
}
