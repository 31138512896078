// @flow
import invariant from 'invariant';

import type {
  GetClassCompletion_me_account as QueryCompletionAccount,
  GetClassCompletion_me_account_class as QueryCompletionClass,
  GetClassCompletion_me_account_class_modules as CompletionModules,
} from 'src/graphql/types/generated/GetClassCompletion';
import type { GetClassTopicsCompletion_me_account_class_topics as CompletionTopics } from 'src/graphql/types/generated/GetClassTopicsCompletion';
import createCellValue, { type CellValue } from 'src/utils/createCellValue';
import { getClassModuleUrl, getClassTopicInsightsUrl } from 'src/utils/routes';

import getPercentageValue from '../utils/getPercentageValue';
import prepareStudentData, { type Student } from '../utils/prepareStudentData';

export type SubjectCompletionCellValue = CellValue<number, number>;

export type ClassAverage = {
  type: 'ClassAverage',
};

type Topic = {
  id: string,
  insightsUrl: string,
  lessonCount: number,
  name: string,
};

export type Module = {
  categoryCount: number,
  id: string,
  lessonCount: number,
  moduleUrL?: string, // not required for overall modules
  name: string,
  topics?: $ReadOnlyArray<Topic>,
};

export type CompletionData = {
  'column-overall': SubjectCompletionCellValue,
  isInactive: boolean,
  // `column-${category.id}`
  [CategoryKey: string]: SubjectCompletionCellValue,
  rowMeta: Student,
};

export type ClassAverageRow = {
  'column-overall': ?SubjectCompletionCellValue,
  isInactive: boolean,
  rowMeta: ClassAverage,
  [CategoryKey: string]: SubjectCompletionCellValue,
};

export type TransformedCompletionData = {
  classAverageRow: ClassAverageRow,
  className: string,
  columnList: $ReadOnlyArray<Module>,
  completionData: $ReadOnlyArray<CompletionData>,
  isClassEmpty: boolean,
  isDataExportEnabled: boolean,
  isFreePlan: boolean,
  lessonCount: number,
  region: string,
};

type LoadingTopic = {
  id: string,
  insightsUrl: string,
  metrics: {
    contentCount: number,
  },
  name: string,
};

function getloadingTopics(count: number): $ReadOnlyArray<LoadingTopic> {
  return Array.from({ length: count }).map((v, i) => ({
    id: `loading-topic-${i}`,
    insightsUrl: '',
    name: '',
    metrics: {
      contentCount: 0,
    },
  }));
}

function processTopics(
  topics: $ReadOnlyArray<CompletionTopics | LoadingTopic>,
  accountId: string,
  classId: string
): $ReadOnlyArray<Topic> {
  return topics.map((topic) => ({
    id: topic.id,
    insightsUrl: getClassTopicInsightsUrl(accountId, classId, topic.id),
    lessonCount: topic.__typename === 'ClassCategory' ? topic.metrics.contentCount : 0,
    name: topic.name,
  }));
}

function prepareColumnList(
  modules: $ReadOnlyArray<CompletionModules>,
  topics: ?$ReadOnlyArray<CompletionTopics>,
  expandedModuleId: ?string,
  accountId: string,
  classId: string
): $ReadOnlyArray<Module> {
  const columns = [];

  for (const module of modules) {
    if (module.__typename === 'ClassCategory') {
      const { childCategoryCount: categoryCount } = module.metrics;
      const lessonCount = module.metrics.contentCount;
      columns.push({
        id: module.id,
        categoryCount,
        lessonCount,
        moduleUrl: getClassModuleUrl(accountId, classId, module.id),
        name: module.name,
        topics:
          module.id === expandedModuleId
            ? processTopics(topics ?? getloadingTopics(categoryCount), accountId, classId)
            : undefined,
      });
    }
  }

  return columns;
}

function transformCategories(
  categories: $ReadOnlyArray<CompletionModules | CompletionTopics>,
  studentId?: string
): ?{ [CategoryKey: string]: SubjectCompletionCellValue } {
  const transformedCategories = {};

  for (const category of categories) {
    if (category.__typename === 'ClassCategory') {
      const categoryMetrics = category.metrics.progress;
      if (studentId) {
        const { students } = categoryMetrics;
        const studentMetrics = students.find((s) => s.studentId === studentId);
        if (studentMetrics) {
          transformedCategories[`column-${category.id}`] = createCellValue(
            getPercentageValue(studentMetrics.completionRate, 1)
          );
        }
      } else {
        transformedCategories[`column-${category.id}`] = createCellValue(
          getPercentageValue(categoryMetrics.completionRate, 1)
        );
      }
    }
  }

  return transformedCategories;
}

export function transformCompletionData({
  accountData,
  classData,
  expandedModuleId,
  topics,
}: {
  accountData: QueryCompletionAccount,
  classData: QueryCompletionClass,
  expandedModuleId: ?string,
  topics: ?$ReadOnlyArray<CompletionTopics>,
}): TransformedCompletionData {
  const { id: accountId, enabledFeatures, plan, region } = accountData;
  const { id: classId, metrics, modules, name: className, students } = classData;

  invariant(metrics.__typename === 'ClassMetrics', 'Completion class metrics type should be ClassMetrics');
  const { progress, contentCount, studentCount } = metrics;

  const categories = [...modules];
  if (topics) {
    categories.push(...topics);
  }

  const classAverageRow = {
    rowMeta: {
      type: 'ClassAverage',
    },
    isInactive: progress.completedCount === 0,
    'column-overall': createCellValue(progress.completedCount ? getPercentageValue(progress.completionRate, 1) : 0),
    ...transformCategories(categories),
  };

  const isDataExportEnabled = !enabledFeatures.includes('DENY_STUDENT_DATA_EXPORT');
  const isFreePlan = plan.isFree;
  const isClassEmpty = studentCount === 0;

  const completionData = progress.students
    .map((student) => {
      const isInactive = getPercentageValue(student.completionRate, 1) === 0;

      const rowMeta = prepareStudentData(student.studentId, students, isInactive);
      if (!rowMeta) return null;

      return {
        rowMeta,
        isInactive,
        'column-overall': createCellValue(student.completedCount ? getPercentageValue(student.completionRate, 1) : 0),
        ...transformCategories(categories, student.studentId),
      };
    })
    .filter(Boolean);

  const columnList = prepareColumnList(modules, topics, expandedModuleId, accountId, classId);

  return {
    completionData,
    columnList,
    classAverageRow,
    className,
    lessonCount: contentCount,
    isClassEmpty,
    isDataExportEnabled,
    isFreePlan,
    region: region.code,
  };
}
