// @flow
import { Box, Text, Stack, MarkdownPreview, HideVisually, useId, useHover } from '@getatomi/neon';

import { UnstyledLink } from 'src/components/Link/Link';

type TaskCardBodyProps = {|
  body: ?string,
  taskUrl: string,
  title: string,
|};

export default function TaskCardBody(props: TaskCardBodyProps) {
  const { taskUrl, title, body } = props;

  const { hoverProps, isHovered } = useHover({});

  const headingId = useId();

  return (
    <Box
      backgroundColor={isHovered ? 'colorBackgroundHovered' : undefined}
      marginInline="spacingNegativeRoot"
      paddingInline="spacingRoot"
      paddingBlock="spacingSmall"
    >
      <Box
        aria-labelledby={headingId}
        as={UnstyledLink}
        display="block"
        textDecoration="none"
        to={taskUrl}
        {...hoverProps}
      >
        <Stack spacing="spacingSmall">
          <Text as="h2" id={headingId} variant="bodySmall1X" fontWeight="fontWeightBold" color="colorTextSubtle">
            {title}
            {/* https://www.filamentgroup.com/lab/a11y-links.html#use-punctuation */}
            <HideVisually>.</HideVisually>
          </Text>

          {body && (
            <Text variant="bodySmall1X" color="colorTextSubtle" whiteSpace="pre-line" testHook="task-list-card-body">
              <MarkdownPreview>{body}</MarkdownPreview>
            </Text>
          )}
        </Stack>
      </Box>
    </Box>
  );
}
