// @flow
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

import type { ReduxState, UserType } from 'src/types';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout/LogoOnlyLayout';
import { getUser } from 'src/reducers/auth';
import { getActiveSubscriptionId, isLoggedInAsStudent, isLoggedInAsParent } from 'src/reducers/subscriptions';
import { getDashboardUrl, getSubjectsSelectionUrl } from 'src/utils/routes';

import WelcomeBox from '../WelcomeBox/WelcomeBox';

type InjectedProps = {
  isParent: boolean,
  isStudent: boolean,
  subscriptionId: number,
  user: UserType,
};

function RegistrationWelcome(props: InjectedProps) {
  const { isStudent, isParent, subscriptionId, user } = props;
  return (
    <LogoOnlyLayout withContainer withGreyBg hideLogoOnMobile>
      <Helmet title="Welcome to Atomi!" />
      <WelcomeBox
        user={user}
        isParent={isParent}
        isStudent={isStudent}
        ctaUrl={isStudent ? getSubjectsSelectionUrl(subscriptionId) : getDashboardUrl(subscriptionId)}
        testHook="welcome-box"
      />
    </LogoOnlyLayout>
  );
}

export default (connect((state: ReduxState) => ({
  isParent: isLoggedInAsParent(state),
  isStudent: isLoggedInAsStudent(state),
  subscriptionId: getActiveSubscriptionId(state),
  user: getUser(state),
}))(RegistrationWelcome): React.AbstractComponent<{}>);
