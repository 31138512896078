// @flow
import { connect } from 'react-redux';

import SubscriptionLink from 'src/components/SubscriptionLink/SubscriptionLink';
import { isPerSubjectPlan as isPerSubjectPlanSelector } from 'src/reducers/plans';
import { getActiveSubscription } from 'src/reducers/subscriptions';

type Props = {
  withRemovalMessage?: boolean,
};

type InjectedProps = Props & {
  isPerSubjectPlan: boolean,
  numSeats: number,
  numSeatsUsed: number,
};

const mapStateToProps = (state) => {
  const subscription = getActiveSubscription(state);

  return {
    isPerSubjectPlan: isPerSubjectPlanSelector(state),
    numSeats: subscription.quantity,
    numSeatsUsed: subscription.quantity_used,
  };
};

function PlanSeatsLimit(props: InjectedProps) {
  const { withRemovalMessage, isPerSubjectPlan, numSeats, numSeatsUsed } = props;

  return isPerSubjectPlan ? (
    `You’ve added more users to classes than you have seats available.${
      withRemovalMessage
        ? ` Please remove ${numSeatsUsed - numSeats} users from classes or get in touch to add more seats.`
        : ''
    }`
  ) : (
    <>
      You’ve added more users than you have seats available.
      {withRemovalMessage && (
        <>
          {' '}
          <SubscriptionLink variant="monochrome" users>
            Archive users
          </SubscriptionLink>{' '}
          or get in touch to add more seats.
        </>
      )}
    </>
  );
}

export default (connect(mapStateToProps)(PlanSeatsLimit): React.AbstractComponent<Props>);
