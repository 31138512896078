// @flow

import { prepareNavigation } from 'src/utils/prepareNavigation';
import { type NavigationContext } from 'src/utils/navigationContext';

import { type LinkProps } from './types';

export function makeBackLinkProps(navigationContext: NavigationContext): LinkProps {
  const { backLinkUrl, backLinkLabel } = prepareNavigation(navigationContext);
  return { to: backLinkUrl, label: backLinkLabel };
}
