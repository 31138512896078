// @flow
import { Box, Container, Heading, Spinner, Stack, TextField } from '@getatomi/neon';
import { connect } from 'react-redux';
import { type ContextRouter, withRouter } from 'react-router';
import Helmet from 'react-helmet';
import { Controller } from 'react-hook-form';
import keyMirror from 'keymirror';

import { checkExistingUser } from 'src/actions/auth';
import { updateRegistrationDetails } from 'src/actions/registration';
import roleTypes, { rolesPaths } from 'src/constants/userRoles';
import scrollToActiveElement from 'src/utils/scrollToActiveElement';
import Button from 'src/components/Button/Button';
import PasswordField from 'src/components/PasswordField/PasswordField';
import useIsClientLoaded, { clientRequiredAttribute, loadingMessage } from 'src/hooks/useIsClientLoaded';
import useRegistrationFormStep1, { type Fields } from 'src/hooks/forms/useRegistrationFormStep1';
import NonAuthenticatedLayout from 'src/layouts/NonAuthenticatedLayout/NonAuthenticatedLayout';
import { type RegistrationState } from 'src/reducers/registration';
import type { BoundAction, ReduxState, RegistrationRegionCode } from 'src/types';

import MarketingPanel from '../MarketingPanel/MarketingPanel';

// Map family_account_owner back to the user facing "parent" value for the url path
const allowedRoles = keyMirror({
  [rolesPaths[roleTypes.student]]: null,
  [rolesPaths[roleTypes.teacher]]: null,
  [rolesPaths[roleTypes.familyAccountOwner]]: null,
});

const labels = {
  [rolesPaths[roleTypes.familyAccountOwner]]: {
    continueButton: 'Continue to your child’s details',
    heading: 'First up, tell us about yourself',
    title: 'Parent registration',
  },
  [rolesPaths[roleTypes.student]]: {
    continueButton: 'Sign up',
    heading: 'Let’s get this party started!',
    title: 'Student Registration',
  },
  [rolesPaths[roleTypes.teacher]]: {
    continueButton: 'Continue to school details',
    heading: 'First up, tell us about yourself',
    title: 'Teacher registration',
  },
};

type Props = {
  params: {
    region: RegistrationRegionCode,
  },
  route: {
    role: $Keys<typeof allowedRoles>,
  },
};

type InjectedProps = Props & {
  checkExistingUserAction: BoundAction<typeof checkExistingUser>,
  registrationDetails: RegistrationState,
  router: ContextRouter,
  updateRegistrationDetailsAction: BoundAction<typeof updateRegistrationDetails>,
};

const mapStateToProps = (state: ReduxState) => ({
  registrationDetails: state.registration,
});

function RegistrationStep1(props: InjectedProps) {
  const {
    checkExistingUserAction,
    params: { region },
    route: { role },
    registrationDetails,
    router,
    updateRegistrationDetailsAction,
  } = props;
  const isClientLoaded = useIsClientLoaded();

  const { control, fields, form, isLoading } = useRegistrationFormStep1({
    defaultValues: {
      email: registrationDetails.email,
      firstName: registrationDetails.firstName,
      lastName: registrationDetails.lastName,
      password: registrationDetails.password,
    },
    onSubmitSuccess: (data: Fields) => {
      updateRegistrationDetailsAction(data);
      router.push(`/register/${role}/step-2/${region}`);
    },
    onSubmitFail: () => {
      scrollToActiveElement();
    },
    checkExistingUser: checkExistingUserAction,
  });

  const { isLoading: isEmailLoading, ...emailField } = fields.email;

  return (
    <NonAuthenticatedLayout sidePanel={<MarketingPanel />}>
      <Helmet>
        <title>{labels[role].title}</title>
      </Helmet>
      <Container maxWidth="sizeContainerSmall" textAlign="center">
        <form method="post" {...form} {...clientRequiredAttribute}>
          <Stack spacing="spacingLarge5X">
            <Heading as="h1">{labels[role].heading}</Heading>
            <Stack spacing="spacingLarge1X">
              <Box display="grid" gridGap="spacingRoot" gridTemplateColumns={{ mobile: 'repeat(2, 1fr)' }}>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <TextField {...field} {...fields.firstName} autoComplete="given-name" autoCorrect="off" />
                  )}
                />
                <Controller
                  control={control}
                  name="lastName"
                  render={({ field }) => (
                    <TextField {...field} {...fields.lastName} autoComplete="family-name" autoCorrect="off" />
                  )}
                />
              </Box>
              <div data-test="hide-in-percy">
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      {...emailField}
                      onChange={(value: string) => {
                        emailField.onChange();
                        field.onChange(value);
                      }}
                      type="email"
                      autoComplete="email"
                      autoCorrect="off"
                      helpText={role !== allowedRoles.student && 'You’ll use this to log in.'}
                      insertAfter={
                        isEmailLoading && <Spinner size="sizeIconSmall" testHook="registration-input-email-spinner" />
                      }
                    />
                  )}
                />
              </div>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <PasswordField
                    {...field}
                    {...fields.password}
                    autoComplete="new-password"
                    helpText={`Must contain at least ${process.env.VALIDATION_PASSWORD_MIN_LENGTH} characters.`}
                  />
                )}
              />
            </Stack>
            <Button
              variant="legacy"
              type="submit"
              isLoading={isLoading}
              isDisabled={!isClientLoaded}
              title={isClientLoaded ? undefined : loadingMessage}
            >
              {labels[role].continueButton}
            </Button>
          </Stack>
        </form>
      </Container>
    </NonAuthenticatedLayout>
  );
}

export default withRouter(
  (connect(mapStateToProps, {
    checkExistingUserAction: checkExistingUser,
    updateRegistrationDetailsAction: updateRegistrationDetails,
  })(RegistrationStep1): React.AbstractComponent<Props>)
);
