// @flow
import { useRef } from 'react';
import { Box, RadioGroup, Radio, Stack, TextField } from '@getatomi/neon';

type Props = {|
  clearValidationErrors: () => void,
  isTeacher: boolean,
  isTimeLimitEnabled: boolean,
  setTimeLimitMinutes: (value: string) => void,
  timeLimitError: ?string,
  timeLimitFieldRef: $Call<typeof useRef>,
  timeLimitMinutes: string,
  toggleIsTimeLimitEnabled: () => void,
|};

export default function TimeLimitField(props: Props) {
  const {
    clearValidationErrors,
    isTeacher,
    isTimeLimitEnabled,
    toggleIsTimeLimitEnabled,
    setTimeLimitMinutes,
    timeLimitError,
    timeLimitFieldRef,
    timeLimitMinutes,
  } = props;

  const validationErrorProps = timeLimitError
    ? {
        errorVariant: 'error',
        validationText: timeLimitError,
      }
    : undefined;

  return (
    <Stack spacing="spacingLarge1X">
      <RadioGroup
        label="Time limit"
        name="timeLimit"
        helpText={
          isTeacher
            ? 'Add a time limit to the student’s challenge. If a student hasn’t completed the challenge within the time allowed, their partial progress will be submitted.'
            : 'Add a time limit to your revision session. If you don’t complete the session within the time allowed, your partial progress will be submitted.'
        }
        onChange={() => {
          clearValidationErrors();
          toggleIsTimeLimitEnabled();
        }}
        value={isTimeLimitEnabled ? 'on' : 'off'}
      >
        <Radio value="off">Off</Radio>
        <Radio value="on">On</Radio>
      </RadioGroup>

      {isTimeLimitEnabled && (
        <Box
          display="grid"
          gridTemplateColumns={{
            base: undefined,
            mobile: 'repeat(3, 1fr)',
          }}
          paddingBottom={{
            // Additional padding is needed to prevent virtual keyboards from
            // obstructing the minutes input when scrolling the validation
            // message into view.
            base: '40vh',
            tablet: 0,
          }}
        >
          <TextField
            isRequired
            label="Minutes"
            name="minutes"
            onChange={(value) => {
              clearValidationErrors();
              setTimeLimitMinutes(value);
            }}
            value={timeLimitMinutes}
            ref={timeLimitFieldRef}
            {...validationErrorProps}
          />
        </Box>
      )}
    </Stack>
  );
}
