// @flow
import * as React from 'react';
import { Container, Heading, useMediaQuery } from '@getatomi/neon';

import ClassCards from './ClassCards/ClassCards';
import ClassCardsLoader from './ClassCards/ClassCardsLoader';
import ClassList from './ClassList/ClassList';
import ClassListLoader from './ClassList/ClassListLoader';
import EmptyStateClass from './EmptyStateClass/EmptyStateClass';
import type { ClassData } from './useGetClasses/useGetClasses';

type Props = {|
  classes: ?$ReadOnlyArray<ClassData>,
  isLoading: boolean,
  isSchoolPlan: boolean,
  subscriptionId: string,
|};

export default function DashboardStudent({ classes, isSchoolPlan, isLoading, subscriptionId }: Props) {
  const isDesktop = useMediaQuery({ minWidth: 'breakpointLarge' });
  const shouldRenderGrid = !__CLIENT__ || isDesktop;

  const renderView = () => {
    if (isLoading || classes == null) {
      return shouldRenderGrid ? <ClassCardsLoader /> : <ClassListLoader />;
    }

    if (isSchoolPlan && classes.length === 0) {
      return <EmptyStateClass isStudent />;
    }

    if (shouldRenderGrid) {
      return <ClassCards classes={classes} withSubjectTag={isSchoolPlan} />;
    }
    return <ClassList classes={classes} subscriptionId={subscriptionId} withSubjectTag={isSchoolPlan} isMobileOnly />;
  };

  return (
    <>
      <Container marginBottom={{ base: 'spacingRoot', tablet: 'spacingLarge2X' }}>
        <Heading as="h1" variant="large">
          {isSchoolPlan ? 'My Classes' : 'Subjects'}
        </Heading>
      </Container>
      {renderView()}
    </>
  );
}
