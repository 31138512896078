// @flow
import { Container, EmptyState, Illustration } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';
import links from 'src/constants/links';

type EmptyStateClassProps = {|
  isStudent: boolean,
  onAddClassClick?: () => mixed,
|};

export default function EmptyStateClass(props: EmptyStateClassProps) {
  const { onAddClassClick, isStudent } = props;

  const canAddClass = !!onAddClassClick;
  let heading;
  let description;
  let helpInfo;
  let primaryAction;

  if (isStudent) {
    heading = 'You have not been added to any classes, yet.';
    description = `When your teachers add you to classes they'll show up here. From your classes you'll be able to view all our lessons and collaborate with your school. In the meantime, why not ask your teachers to start adding you!`;
  } else if (canAddClass) {
    heading = 'You have no classes, yet.';
    description = `From your classes you'll be able to view all our lessons and collaborate with colleagues. Let's set up your first class now.`;
    primaryAction = <Button onClick={onAddClassClick}>Add a class</Button>;
  } else {
    heading = 'You have not been added to any classes, yet.';
    description = `From your classes you'll be able to view lessons and collaborate with your students. In the meantime, why not ask your school to start adding you!`;
    helpInfo = (
      <>
        Why can’t I create a class?{' '}
        <Link href={links.support.schoolRolesAndPermissions} isExternal variant="monochrome">
          Learn more about user roles and permissions in our Help Centre.
        </Link>
      </>
    );
  }

  return (
    <Container marginTop="spacingLarge5X" maxWidth="sizeContainerRoot">
      <EmptyState
        media={<Illustration name="emptystate-classes" />}
        heading={heading}
        description={description}
        primaryAction={primaryAction}
        helpInfo={helpInfo}
      />
    </Container>
  );
}
