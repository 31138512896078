// @flow
import { Box, Container, Flex, Skeleton } from '@getatomi/neon';

import CardGrid from './CardGrid/CardGrid';

export default function ClassCardsLoader() {
  return (
    <Container>
      <CardGrid>
        {Array.from({ length: 8 }).map((_, index) => (
          <Box key={index} borderWidth="borderWidthRoot" borderStyle="solid" borderColor="colorBorderNeutral">
            <Flex direction="column" alignItems="center">
              <Skeleton height={120} width="sizeFull" animation="wave" borderRadius="borderRadiusNone" />

              <Box alignItems="center" display="flex" flexDirection="column" height={184} padding="spacingRoot">
                <Skeleton width={150} height={26} animation="wave" marginBottom="spacingRoot" />
                <Skeleton
                  width={120}
                  height={16}
                  animation="wave"
                  marginBottom="spacingSmall1X"
                  borderRadius="borderRadiusLarge"
                />
                <Skeleton width={60} height={16} animation="wave" borderRadius="borderRadiusLarge" />
              </Box>
            </Flex>
          </Box>
        ))}
      </CardGrid>
    </Container>
  );
}
