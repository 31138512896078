// @flow
import _ from 'lodash';

import type { ApiError, Invoice, ReduxState } from 'src/types';
import {
  LOAD_INVOICES,
  LOAD_INVOICES_SUCCESS,
  LOAD_INVOICES_FAIL,
  LOAD_INVOICE_DOWNLOAD_URL,
  LOAD_INVOICE_DOWNLOAD_URL_SUCCESS,
  LOAD_INVOICE_DOWNLOAD_URL_FAIL,
} from 'src/actions/invoices';
import { UPGRADE_SUBSCRIPTION_SUCCESS } from 'src/actions/subscriptions';
import invoiceStatuses from 'src/constants/invoiceStatuses';

export type InvoicesState = {
  entities: {
    [invoiceId: number]: Invoice,
  },
  isLoaded: boolean,
  isLoading: boolean,
  routeError: ?ApiError,
};

const initialState = {
  entities: {},
  isLoading: false,
  isLoaded: false,
  routeError: null,
};

export default function invoicesReducer(state: InvoicesState = initialState, action: Object = {}) {
  switch (action.type) {
    case LOAD_INVOICES:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_INVOICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        routeError: null,
        entities: _.merge({}, state.entities, action.result.entities.invoices),
      };
    case LOAD_INVOICES_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        routeError: action.routeError,
      };

    case LOAD_INVOICE_DOWNLOAD_URL:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.data.invoiceId]: {
            ...state.entities[action.data.invoiceId],
            isLoadingDownloadUrl: true,
            isLoaded: false,
          },
        },
      };
    case LOAD_INVOICE_DOWNLOAD_URL_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.data.invoiceId]: {
            ...state.entities[action.data.invoiceId],
            isLoadingDownloadUrl: false,
            isLoaded: true,
            ...action.result,
          },
        },
      };
    case LOAD_INVOICE_DOWNLOAD_URL_FAIL:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.data.invoiceId]: {
            ...state.entities[action.data.invoiceId],
            isLoadingDownloadUrl: false,
            isLoaded: false,
          },
        },
        routeError: action.routeError,
      };

    case UPGRADE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoaded: false,
      };

    default:
      return state;
  }
}

// Selectors
export const isInvoicesLoaded = (state: ReduxState) => state.invoices.isLoaded;
export const getAllInvoices = (state: ReduxState) => _.values(state.invoices.entities);
export const getOverdueInvoice = (state: ReduxState) =>
  getAllInvoices(state).find(
    ({ status }) => status === invoiceStatuses.payment_due || status === invoiceStatuses.not_paid
  );
