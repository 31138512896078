// @flow
import keyMirror from 'keymirror';

export default keyMirror({
  class: null,
  level: null,
  subject: null,
  subjectGroup: null,
  user: null,
});
