// @flow
import { Button, Flex, Modal, Text, Stack, Switch, useId, useMediaQuery, useToast } from '@getatomi/neon';
import { connect } from 'react-redux';
import { useState } from 'react';

import type { BoundAction, ReduxState } from 'src/types';
import { runEDSImport } from 'src/actions/subscriptions';
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';

type Props = {
  externalDataSourceId: string,
  setShowRunManualImportDialog: (boolean) => mixed,
};

type InjectedProps = Props & {
  runEDSImportAction: BoundAction<typeof runEDSImport>,
  subscriptionId: string,
};

function mapStateToProps(state: ReduxState) {
  return {
    subscriptionId: getActiveSubscriptionId(state),
  };
}

function RunManualImportDialog(props: InjectedProps) {
  const { subscriptionId, runEDSImportAction, setShowRunManualImportDialog, externalDataSourceId } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDryRunSelected, setIsDryRunSelected] = useState<boolean>(false);
  const [isSinceLastImportSelected, setIsSinceLastImportSelected] = useState<boolean>(false);
  const [isNotifyOnSlackSelected, setIsNotifyOnSlackSelected] = useState<boolean>(false);
  const dryRunLabelId = useId();
  const dryRunHelpTextId = useId();
  const sinceLastImportLabelId = useId();
  const sinceLastImportHelpTextId = useId();
  const notifyOnSlackLabelId = useId();
  const notifyOnSlackHelpTextId = useId();
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });
  const toast = useToast();

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      await runEDSImportAction({
        subscriptionId,
        externalDataSourceId,
        isDryRun: isDryRunSelected,
        isSinceLastImport: isSinceLastImportSelected,
        isNotifyOnSlack: isNotifyOnSlackSelected,
      });
      setIsLoading(false);
      toast.success('The manual import ran successfully.');
      setShowRunManualImportDialog(false);
    } catch {
      setIsLoading(false);
      toast.error(`There was an error when running the manual import. Please try again.`);
    }
  };

  return (
    <Modal
      actions={
        <>
          <Button
            isFullWidth={isMobile}
            onClick={() => setShowRunManualImportDialog(false)}
            size={isMobile ? 'small' : undefined}
            variant={isMobile ? 'tertiary' : 'text'}
          >
            Cancel
          </Button>
          <Button isFullWidth={isMobile} size="small" onClick={onSubmit} isLoading={isLoading}>
            Run import
          </Button>
        </>
      }
      heading="Run manual import"
      isOpen
      onClose={() => setShowRunManualImportDialog(false)}
    >
      <form onSubmit={onSubmit}>
        <Stack as="fieldset" spacing="spacingLarge1X">
          <Text as="p" variant="bodyRoot">
            Running a manual import will synchronise the users and classes on this account with the school’s Wonde data.
            Please double-check the config is set up correctly, as a misconfiguration can result in users losing access
            to Atomi and their classes.
          </Text>
          <Stack spacing="spacingSmall3X">
            <Text as="p" variant="bodySmall" fontWeight="fontWeightMedium" id={dryRunLabelId}>
              Run as a test
            </Text>
            <Flex gap="spacingSmall" justifyContent="space-between">
              <Text as="p" variant="bodySmall2X" color="colorTextSubtler" id={dryRunHelpTextId}>
                If active this will run this import as a test run, and will not update classes or users on this account.
              </Text>
              <Switch
                ariaLabelledBy={dryRunLabelId}
                ariaDescribedBy={dryRunHelpTextId}
                onChange={(isSelected) => setIsDryRunSelected(isSelected)}
              />
            </Flex>
          </Stack>
          <Stack spacing="spacingSmall3X">
            <Text as="p" variant="bodySmall" fontWeight="fontWeightMedium" id={sinceLastImportLabelId}>
              Only fetch new data
            </Text>
            <Flex gap="spacingSmall1X" justifyContent="space-between">
              <Text as="p" variant="bodySmall2X" color="colorTextSubtler" id={sinceLastImportHelpTextId}>
                If active the import will only sync new data since the last import was run.
              </Text>
              <Switch
                ariaLabelledBy={sinceLastImportLabelId}
                ariaDescribedBy={sinceLastImportHelpTextId}
                onChange={(isSelected) => setIsSinceLastImportSelected(isSelected)}
              />
            </Flex>
          </Stack>
          <Stack spacing="spacingSmall3X">
            <Text as="p" variant="bodySmall" fontWeight="fontWeightMedium" id={notifyOnSlackLabelId}>
              Send a notification to Slack
            </Text>
            <Flex gap="spacingSmall" justifyContent="space-between">
              <Text as="p" variant="bodySmall2X" color="colorTextSubtler" id={notifyOnSlackHelpTextId}>
                If active a notification will be posted in the #provisioning-feed slack channel when the import
                completes.
              </Text>
              <Switch
                ariaLabelledBy={notifyOnSlackLabelId}
                ariaDescribedBy={notifyOnSlackHelpTextId}
                onChange={(isSelected) => setIsNotifyOnSlackSelected(isSelected)}
              />
            </Flex>
          </Stack>
        </Stack>
      </form>
    </Modal>
  );
}

export default (connect(mapStateToProps, {
  runEDSImportAction: runEDSImport,
})(RunManualImportDialog): React.AbstractComponent<Props>);
