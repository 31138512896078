// @flow
import { useState } from 'react';
import { connect } from 'react-redux';
import type Cookies from 'universal-cookie';
import _ from 'lodash';
import { AlertDialog, Box, Button, Container, useToast } from '@getatomi/neon';
import { type ContextRouter, type LocationShape, withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import invariant from 'invariant';

import { authStrategyTypes } from 'src/constants/authStrategyTypes';
import plans from 'src/constants/plans';
import roles from 'src/constants/userRoles';
import userAccountStatuses from 'src/constants/userAccountStatuses';
import type { BoundAction, ReduxState, Subscription, UserType } from 'src/types';
import Logger from 'src/utils/Logger';
import SubscriptionName from 'src/components/SubscriptionName/SubscriptionName';
import UserName from 'src/components/UserName/UserName';
import ReduxAvatarContainer from 'src/components/ReduxAvatarContainer/ReduxAvatarContainer';
import { removeUserFromSubscription, setActiveSubscription } from 'src/actions/subscriptions';
import { loginWithSaml, loadAuthStrategies } from 'src/actions/auth';
import { getUser, isLoggedInAsSuperAdmin as isLoggedInAsSuperAdminSelector } from 'src/reducers/auth';
import { getActiveSubscriptionId, getActiveAndPendingSubscriptions } from 'src/reducers/subscriptions';
import { getDashboardUrl, getLoginUrl } from 'src/utils/routes';
import Auth from 'src/utils/Auth';
import useAlertDialog from 'src/hooks/components/useAlertDialog';
import Choice from 'src/components/Choice/Choice';

const log = new Logger('components/SubscriptionList');

type Props = {
  cookies: Cookies,
  currentSubscriptionId: number,
  isLoggedInAsSuperAdmin: boolean,
  loadAuthStrategiesAction: BoundAction<typeof loadAuthStrategies>,
  location: LocationShape,
  loginWithSamlAction: BoundAction<typeof loginWithSaml>,
  removeUserFromSubscriptionAction: BoundAction<typeof removeUserFromSubscription>,
  router: ContextRouter,
  setActiveSubscriptionAction: BoundAction<typeof setActiveSubscription>,
  subscriptions: Array<Subscription>,
  user: UserType,
};

const mapStateToProps = (state: ReduxState) => {
  const isLoggedInAsSuperAdmin = isLoggedInAsSuperAdminSelector(state);
  return {
    currentSubscriptionId: getActiveSubscriptionId(state),
    isLoggedInAsSuperAdmin,
    subscriptions: getActiveAndPendingSubscriptions(state, {
      includeSchool: isLoggedInAsSuperAdmin,
    }),
    user: getUser(state),
  };
};

function SubscriptionList(props: Props) {
  const {
    currentSubscriptionId,
    isLoggedInAsSuperAdmin,
    removeUserFromSubscriptionAction,
    setActiveSubscriptionAction,
    subscriptions,
    user,
    loadAuthStrategiesAction,
    location: {
      query: { redirect },
    },
    loginWithSamlAction,
    cookies,
    router,
  } = props;
  const [isLeavingSubscription, setIsLeavingSubscription] = useState(false);
  const [subscriptionIdToLeave, setSubscriptionIdToLeave] = useState(null);
  const [reAuthSubscriptionId, setReAuthSubscriptionId] = useState(null);
  const toast = useToast();

  const auth = new Auth(cookies);
  const authorizedSubscriptions = auth.getAuthorizedSubscriptions();
  const authorizedSubscriptionIds = authorizedSubscriptions.map(({ id }) => id);

  const getRedirectUrl = (subscriptionId, customerType) =>
    redirect ? `/subscriptions/${subscriptionId}${redirect}` : getDashboardUrl(subscriptionId, { customerType });

  const onConfirmReAuth = async () => {
    auth.logout();
    auth.setReAuthUser(user.email);

    invariant(
      typeof reAuthSubscriptionId === 'number',
      'expected reAuthSubscriptionId to have been defined as a number'
    );
    const selected = subscriptions.find((sub) => sub.id === reAuthSubscriptionId);
    router.push(
      `${getLoginUrl()}?redirect=${encodeURIComponent(
        getRedirectUrl(reAuthSubscriptionId, selected?.plan_customer_type)
      )}`
    );
  };

  const [reAuthPrompt, showReAuthPrompt] = useAlertDialog({
    onConfirmLabel: 'Continue',
    closeOnConfirm: false,
    heading: 'Re-authenticate',
    children:
      'To help keep your account secure, we need to verify it’s you. You’ll need to log in again to continue to the selected account on Atomi.',
    onConfirm: onConfirmReAuth,
  });

  const onSwitch = async (subscriptionId: number) => {
    const authStrategies = await loadAuthStrategiesAction(user.email);
    const authStrategy = authStrategies.find(({ subscription }) => subscription.id === subscriptionId);

    if (authStrategy.type === authStrategyTypes.saml) {
      auth.logout();
      await loginWithSamlAction({
        email: user.email,
        redirectUrl: getRedirectUrl(subscriptionId, authStrategy.subscription.plan_customer_type),
        subscriptionId,
      });
    } else {
      setReAuthSubscriptionId(subscriptionId);
      showReAuthPrompt();
    }
  };

  const getTags = (subscription: Subscription) => {
    const { quantity, quantity_used: quantityUsed } = subscription;
    const isSchoolPlan = _.has(_.merge({}, plans.free.school, plans.school), subscription.plan_code);
    const tags = [
      { children: subscription.status.replace(/_/g, ' ') },
      { children: subscription.plan_code.replace(/_/g, ' ') },
    ];

    if (isSchoolPlan) {
      const activationRate =
        (subscription.active_students_count + subscription.active_teachers_count) / subscription.total_users_count;
      const usageRate = quantityUsed / quantity;
      let activationColor;

      if (activationRate < 0.5) {
        activationColor = 'colorTextDanger';
      } else if (activationRate >= 0.75) {
        activationColor = 'colorTextSuccess';
      }

      tags.push(
        {
          children: `${Math.round(activationRate * 100)}% activated`,
          color: activationColor,
        },
        {
          children: `${Math.round((quantityUsed / quantity) * 100)}% utilised`,
          color: usageRate > 1 ? 'colorTextDanger' : 'colorTextSuccess',
        }
      );
    }

    return tags;
  };

  const onConfirmLeaveSubscription = async () => {
    if (subscriptionIdToLeave) {
      setIsLeavingSubscription(true);
      try {
        // if the user is leaving the active subscription, switch to one of the other available
        // subscriptions first in order to avoid errors when rerendering the header
        if (subscriptionIdToLeave === currentSubscriptionId) {
          const [newActiveSubscription] = subscriptions.filter(({ id }) => id !== currentSubscriptionId);
          setActiveSubscriptionAction(newActiveSubscription.id);
        }
        await removeUserFromSubscriptionAction(subscriptionIdToLeave, user.id);
        toast.success('You’ve left this subscription successfully.');
      } catch (error) {
        log.info('Leaving subscription failed', error);
        setSubscriptionIdToLeave(null);
        toast.error('There was an error leaving this subscription. Please try again.');
      }
      setSubscriptionIdToLeave(null);
      setIsLeavingSubscription(false);
    }
  };

  return (
    <>
      <Container maxWidth="sizeContainerSmall">
        <Box as="ul" testHook="subscription-list">
          {subscriptions.map((subscription, i) => {
            const withAccountDetails =
              isLoggedInAsSuperAdmin && subscription && subscription.user.role === roles.accountManager;
            const subscriptionName = (
              <SubscriptionName name={subscription.name} planType={subscription.plan_customer_type} />
            );
            const isPendingConfirmation = subscription.user.subscription_status === userAccountStatuses.legacy.invited;
            const isSubscriptionInAuthScope =
              isLoggedInAsSuperAdmin || authorizedSubscriptionIds.includes(subscription.id);

            const getDescriptionWithAuthState = (content) => {
              if (isPendingConfirmation || isSubscriptionInAuthScope) {
                return content;
              }
              return (
                <>
                  {content} <span style={{ whiteSpace: 'nowrap' }}>(Logged out)</span>
                </>
              );
            };

            let itemProps = {};
            if (!isPendingConfirmation) {
              if (isSubscriptionInAuthScope) {
                itemProps = {
                  // using `href` as we want a full page refresh when clicking the link so we can clear the subscription in the state
                  href: getRedirectUrl(subscription.id, subscription.plan_customer_type),
                };
              } else {
                itemProps = {
                  onClick: () => onSwitch(subscription.id),
                };
              }
            }

            if (withAccountDetails) {
              itemProps = {
                ...itemProps,
                title: subscriptionName,
                description: getDescriptionWithAuthState(subscription.school?.name),
                secondaryAction:
                  subscriptions.length > 1 ? (
                    <Button
                      marginTop="spacingRoot"
                      onClick={(e) => {
                        e.preventDefault();
                        setSubscriptionIdToLeave(subscription.id);
                      }}
                      size="small"
                      variant="text"
                    >
                      Leave account
                    </Button>
                  ) : undefined,
                tags: getTags(subscription),
              };
            } else {
              itemProps = {
                ...itemProps,
                avatar: <ReduxAvatarContainer user={user} size="sizeAvatarLarge" isDisabled={isPendingConfirmation} />,
                title: <UserName firstName={user.first_name} lastName={user.last_name} />,
                description: getDescriptionWithAuthState(subscriptionName),
                tags: isPendingConfirmation
                  ? [
                      {
                        children: 'Activation pending',
                        color: 'colorTextDanger',
                        variant: 'dashed',
                        tooltip:
                          'You need to accept the email invitation to your school account. Search your email for “Atomi” to find it, or ask your school to resend your invite.',
                        testHook: 'activation-pending-tag',
                      },
                    ]
                  : undefined,
              };
            }

            return <Choice key={subscription.id} testHook={`subscription-list-item-${i}`} {...itemProps} />;
          })}
        </Box>
      </Container>
      <AlertDialog
        variant="danger"
        heading="Are you sure you want to leave this account?"
        onDismiss={() => setSubscriptionIdToLeave(null)}
        onConfirm={onConfirmLeaveSubscription}
        onConfirmLabel="Leave"
        isLoading={isLeavingSubscription}
        isOpen={!!subscriptionIdToLeave}
      >
        You will no longer be an account manager on this subscription.
      </AlertDialog>
      {reAuthPrompt}
    </>
  );
}

export default withRouter(
  withCookies(
    connect(mapStateToProps, {
      loginWithSamlAction: loginWithSaml,
      loadAuthStrategiesAction: loadAuthStrategies,
      removeUserFromSubscriptionAction: removeUserFromSubscription,
      setActiveSubscriptionAction: setActiveSubscription,
    })(SubscriptionList)
  )
);
