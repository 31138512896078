// @flow
const itemTypeMap = Object.freeze({
  DragAndDropQuestion: 'DRAG_AND_DROP',
  ExactAnswerQuestion: 'EXACT_ANSWER',
  MultipleChoiceQuestion: 'MULTIPLE_CHOICE',
  SelfMarkedQuestion: 'SELF_MARKED',
  SelfMarkedLiteQuestion: 'SELF_MARKED_LITE',
  TextSnippet: 'TEXT',
  VideoSnippet: 'VIDEO',
});

export type ContentItemType = $Keys<typeof itemTypeMap>;

export default itemTypeMap;
