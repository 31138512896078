// @flow
import Link from 'src/components/Link/Link';
import logo from 'src/assets/images/atomi-logo-text-only.svg';
import links from 'src/constants/links';

import styles from './Footer.module.scss';

export default function Footer() {
  return (
    <footer className={styles.root} role="contentinfo">
      <img src={logo} width="56" alt="Atomi logo" />
      <ul className={styles.links}>
        <li>
          <Link variant="monochrome" href={links.support.home} isExternal>
            Get support.
          </Link>
        </li>
        <li>
          <Link variant="monochrome" href={links.blog}>
            Visit our Blog.
          </Link>
        </li>
        <li>
          <Link variant="monochrome" href={links.careers}>
            We’re hiring!
          </Link>
        </li>
      </ul>
    </footer>
  );
}
