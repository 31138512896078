// @flow
import moment from 'moment';
import pluralize from 'pluralize';

import Logger from 'src/utils/Logger';

const log = new Logger('components/TimeDifference');

type Props = {
  children?: Function,
  date: string,
  now?: Date,
  unit?: string,
};

export default function TimeDifference(props: Props) {
  if (!props.date) {
    log.warn('TimeDifference expects a date prop');
    return null;
  }

  const { date, unit = 'days', now = moment() } = props;
  const diff = moment(date).diff(now, unit);
  const output = `${diff} ${pluralize(unit, diff)}`;

  if (props.children) {
    return props.children(diff, output);
  }

  return <span>{output}</span>;
}
