// @flow
import { NavTabs, NavTabsLink } from 'src/components/NavTabs/NavTabs';
import { getMarkbookCompletionUrl, getMarkbookMarksUrl, getMarkbookTasksUrl } from 'src/utils/routes';

type MarkbookSubNavProps = {|
  params: {
    classId: string,
    subscriptionId: string,
  },
|};

export type MarkbookLinks = 'completion' | 'marks' | 'tasks';

function MarkbookSubNav({ params }: MarkbookSubNavProps) {
  const { classId, subscriptionId } = params;

  const marksUrl = getMarkbookMarksUrl(subscriptionId, classId);
  const completionUrl = getMarkbookCompletionUrl(subscriptionId, classId);
  const tasksUrl = getMarkbookTasksUrl(subscriptionId, classId);

  return (
    <NavTabs variant="secondary" ariaLabel="Mark book navigation">
      <NavTabsLink to={marksUrl}>Marks</NavTabsLink>
      <NavTabsLink to={tasksUrl}>Tasks</NavTabsLink>
      <NavTabsLink to={completionUrl}>Subject completion</NavTabsLink>
    </NavTabs>
  );
}

export default MarkbookSubNav;
