// @flow
import classnames from 'classnames/bind';
import { HideVisually } from '@getatomi/neon';

import styles from './MobileDialogTrigger.module.scss';

const cx = classnames.bind(styles);

type Props = {
  isActive: boolean,
  onClick: Function,
};

export default function MobileDialogTrigger(props: Props) {
  const { isActive, onClick } = props;

  if (!isActive && !onClick) return null;

  return (
    <button onClick={onClick} aria-expanded={isActive} className={cx('root', { isActive })} type="button">
      <HideVisually>{isActive ? 'Close' : 'Open'} dialog</HideVisually>
      <span aria-hidden="true" className={styles.bar} />
      <span aria-hidden="true" className={styles.bar} />
      <span aria-hidden="true" className={styles.bar} />
    </button>
  );
}
