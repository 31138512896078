// @flow
import { Badge, Box, Flex, IconCalendar, Text, useMediaQuery } from '@getatomi/neon';

import Avatar from 'src/components/Avatar/Avatar';
import TaskDateTime from 'src/components/TaskDateTime/TaskDateTime';
import { type Author } from 'src/domains/Tasks/Task/taskTransformer';

type StudentTaskCardHeaderProps = {|
  author: Author,
  dueDate: string,
  subjectColor: string,
  subjectName: string,
  withStatus?: boolean,
|};

export default function StudentTaskCardHeader(props: StudentTaskCardHeaderProps) {
  const { author, dueDate, subjectColor, subjectName, withStatus } = props;

  const isTablet = useMediaQuery({ maxWidth: 'breakpointMedium' });
  const gridTemplateAreas = isTablet
    ? `
      "dueDate avatar"
      "label label"
    `
    : `"dueDate label avatar"`;
  const gridTemplateColumns = isTablet ? '1fr auto auto' : '1fr auto';

  return (
    <Box
      display="grid"
      alignItems="center"
      gridRowGap="spacingSmall"
      gridTemplateAreas={gridTemplateAreas}
      gridTemplateColumns={gridTemplateColumns}
    >
      <Box gridArea="dueDate">
        <Flex alignItems="center" gap="spacingSmall1X">
          <IconCalendar size="sizeIconSmall2X" color="colorIconSubtle" />
          <Text variant="bodySmall1X" fontWeight="fontWeightMedium">
            <TaskDateTime prefix="Due" value={dueDate} withStatus={withStatus} />
          </Text>
        </Flex>
      </Box>

      <Box gridArea="label" display="inline-flex">
        <Badge fillColor={subjectColor} variant="filled">
          {subjectName}
        </Badge>
      </Box>

      <Box gridArea="avatar" marginLeft="spacingSmall1X">
        <Avatar isDecorative={false} size="sizeAvatarSmall" user={author} withTooltip />
      </Box>
    </Box>
  );
}
