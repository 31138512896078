// @flow
import { from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import Auth from 'src/utils/Auth';

import { makeRefreshAuthTokenLink } from './links/makeRefreshAuthTokenLink';
import { makeErrorLink } from './links/makeErrorLink';
import { makeRetryLink } from './links/makeRetryLink';
import { makeAuthLink } from './links/makeAuthLink';
import { makePreviewLink } from './links/makePreviewLink';
import { makeHttpLink } from './links/makeHttpLink';

type LinkOptions = {
  uri?: string,
};

export const createLinks = (options?: LinkOptions = {}) => {
  const { uri } = options;
  const auth = new Auth();

  const refreshAuthTokenLink = makeRefreshAuthTokenLink(auth);
  const errorLink = onError(makeErrorLink(auth));
  const retryLink = makeRetryLink();
  const authLink = setContext(makeAuthLink(auth));
  const previewLink = setContext(makePreviewLink());
  const httpLink = makeHttpLink(uri);

  return from([refreshAuthTokenLink, errorLink, retryLink, authLink, previewLink, httpLink]);
};
