// @flow
import { type ContextRouter, withRouter } from 'react-router';
import { Box, Tabs as NeonTabs, TabLink as NeonTabLink, TabList } from '@getatomi/neon';

import { UnstyledLink, type LinkProps } from 'src/components/Link/Link';

// The ref is required for the keyboard left/right arrows navigation to work properly on the
// NeonTabs.
// $FlowIgnoreLibDef
const LinkWithRef = React.forwardRef((props, ref) => <UnstyledLink innerRef={ref} {...props} />);

type NavTabsLinkProps = LinkProps & {
  activeMatcher?: ({ pathname: string, search: string }) => boolean,
};
export function NavTabsLink(props: NavTabsLinkProps) {
  const { to, activeMatcher, ...otherProps } = props;
  const routeState = { refocus: false, scrollTop: false };

  return (
    <NeonTabLink
      as={LinkWithRef}
      to={
        to instanceof Object
          ? { ...to, routeState }
          : {
              pathname: to,
              state: routeState,
            }
      }
      {...otherProps}
    />
  );
}

type NavTabsProps = {
  ariaLabel: string,
  children: Array<React.Element<typeof NavTabsLink> | boolean>,
  router: ContextRouter,
  variant?: 'centered' | 'secondary',
};

function NavTabsBase(props: NavTabsProps) {
  const { ariaLabel, children, router, variant } = props;
  const pathnames = React.Children.toArray(children)
    .filter(Boolean)
    .map(({ props: { to, activeMatcher } }) => ({
      activeMatcher,
      to,
    }));
  const currentPathname = router.location.pathname;
  const currentTabIndex = pathnames.findIndex(({ to, activeMatcher }) => {
    const exactMatch = currentPathname === to;
    const customMatch = activeMatcher
      ? activeMatcher({ pathname: currentPathname, search: router.location.search })
      : false;
    return customMatch || exactMatch;
  });

  return (
    <Box
      marginBottom={variant === 'secondary' ? undefined : { base: 'spacingLarge1X', tablet: 'spacingLarge3X' }}
      // the minWidth is required for the tabs horizontal scroll to work properly when used in a flex context
      minWidth="sizeNone"
    >
      <NeonTabs key={currentTabIndex} defaultIndex={currentTabIndex} variant={variant} keyboardActivation="manual">
        <TabList ariaLabel={ariaLabel}>{children}</TabList>
      </NeonTabs>
    </Box>
  );
}

export const NavTabs = withRouter(NavTabsBase);
