// @flow
import type { ContextRouter } from 'react-router';
import { useToast } from '@getatomi/neon';

import useTaskDialog from 'src/components/useTaskDialog/useTaskDialog';
import useProgressReportDialog from 'src/hooks/components/useProgressReportDialog';
import { getTaskUrl, getTaskProgressReportUrl } from 'src/utils/routes';
import { trackEvent } from 'src/utils/tracking';
import { trackingCtas, trackingCtaTypes, trackingEvents } from 'src/constants/tracking';

import type { ClassTask, CurrentClass } from './useGetClassTasks';
import type { TaskFilterStatus } from './useClassTaskListFilters';
import useDeleteTaskAlertDialog from '../components/useDeleteTaskAlertDialog/useDeleteTaskAlertDialog';
import getTaskActionsById from './utils/getTaskActionsById';
import useDuplicateTaskDialog from '../components/useDuplicateTaskDialog/useDuplicateTaskDialog';
import ClassTasksList from './ClassTasksList';

export type ClassTasksProps = {|
  currentClass: CurrentClass,
  isFreePlan: boolean,
  pathname: string,
  postId?: string,
  region: string,
  router: ContextRouter,
  setStatusFilter: (TaskFilterStatus) => void,
  statusFilter: TaskFilterStatus,
  subscriptionId: string,
  tasksData: $ReadOnlyArray<ClassTask>,
  totalTaskCount: number,
|};

export default function ClassTasks(props: ClassTasksProps) {
  const {
    currentClass,
    isFreePlan,
    subscriptionId,
    tasksData,
    totalTaskCount,
    region,
    router,
    statusFilter,
    setStatusFilter,
    postId,
    pathname,
  } = props;
  const { id: classId } = currentClass;

  const toast = useToast();

  const copyTaskUrlToClipboard = async (taskId: string) => {
    const url = `${window.location.origin}${getTaskUrl(subscriptionId, classId, taskId)}`;
    try {
      await navigator.clipboard.writeText(url);
      toast.success('Link copied to the clipboard.');
      trackEvent(trackingEvents.ctaClicked, {
        cta: trackingCtas.copyLink,
        type: trackingCtaTypes.button,
        url,
        taskId,
        subject_code: currentClass.subject.code,
      });
    } catch (error) {
      toast.error('There was an error copying the link to the clipboard. Please try again.');
    }
  };

  const [taskDialog, { openTaskDialog }] = useTaskDialog({
    subscriptionId,
    classId,
  });

  const [progressReportDialog, { openProgressReportDialog }] = useProgressReportDialog({
    classId,
    isFreePlan,
    region,
    subscriptionId,
  });

  const [deleteTaskAlertDialog, { open: openDeleteTaskAlertDialog }] = useDeleteTaskAlertDialog();

  const onDuplicateTaskSubmit = ({ duplicateTask, duplicateClassId }) => {
    openTaskDialog({ task: duplicateTask, classId: duplicateClassId });
  };

  const [duplicateTaskDialog, { open: openDuplicateTaskModal }] = useDuplicateTaskDialog({
    currentClass,
    onSubmit: onDuplicateTaskSubmit,
  });

  const taskProgressReportUrlFactory = (taskId: string) => getTaskProgressReportUrl(subscriptionId, classId, taskId);

  const getTaskActions = getTaskActionsById({
    tasks: tasksData,
    onTaskSelect: (id) => router.push(getTaskUrl(subscriptionId, classId, id)),
    onProgressSelect: (task) => router.push(getTaskProgressReportUrl(subscriptionId, classId, task.id)),
    onEditTaskSelect: (task) => openTaskDialog({ task }),
    onCopyLinkSelect: (id) => copyTaskUrlToClipboard(id),
    onDeleteTaskSelect: (task) => openDeleteTaskAlertDialog(task.id, task.status),
    onDuplicateTaskSelect: (task) => openDuplicateTaskModal(task),
  });

  return (
    <>
      <ClassTasksList
        openTaskDialog={openTaskDialog}
        tasksData={tasksData}
        getTaskActions={getTaskActions}
        studentCount={currentClass.studentCount}
        onStudentCountClick={(post) => openProgressReportDialog({ post, moduleId: post.moduleId })}
        onStatusFilterChange={setStatusFilter}
        filters={{ statusFilter, postId }}
        pathname={pathname}
        taskProgressReportUrlFactory={taskProgressReportUrlFactory}
        totalTaskCount={totalTaskCount}
      />
      {progressReportDialog}
      {taskDialog}
      {deleteTaskAlertDialog}
      {duplicateTaskDialog}
    </>
  );
}
