// @flow
import yn from 'yn';
import _ from 'lodash';
import { extraErrorDataIntegration, rewriteFramesIntegration } from '@sentry/react';

import type { UserType, Subscription } from 'src/types';

const Sentry = __CLIENT__ ? window.Sentry : require('@sentry/node');

export default Sentry;

const subscriptionPropsToSend = ['intercom_id', 'name', 'plan_code', 'region_code', 'school_id', 'status', 'user'];

export function isSentryLoaded() {
  return !!(Sentry && Sentry.SDK_VERSION);
}

export function trackUser(user: UserType, activeSubscription: Subscription) {
  const userObj = {
    id: user.id,
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    subscription: _.pick(activeSubscription, subscriptionPropsToSend),
  };

  if (isSentryLoaded()) {
    Sentry.configureScope((scope) => {
      scope.setUser(userObj);
    });
  }
}

export function getSentryConfig({ isServer }: { isServer: boolean } = {}) {
  // eslint-disable-next-line flowtype/no-weak-types
  const config: Object = {
    enabled: yn(process.env.SENTRY_ENABLED),
    dsn: `https://${process.env.SENTRY_PUBLIC_KEY}@sentry.io/${process.env.SENTRY_PROJECT_ID}`,
    environment: process.env.ENV_NAME,
    attachStacktrace: true,
    // By default Sentry SDKs normalize any context to a depth of three meaning that an object with
    // more than three levels of nesting won't be sent completely to Sentry (increasing it to more
    // than 4 would include entities and increase the chance of the event limit to be reached.).
    // See: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#normalization-depth
    normalizeDepth: 4,
    denyUrls: [/mixpanel.com/, /wistia.com/],
    ignoreErrors: [
      // https://github.com/NekR/offline-plugin/blob/master/docs/troubleshooting.md#fetching-the-serviceworker-results-in-an-error
      /Failed to update a ServiceWorker: An unknown error occurred when fetching the script/,
      /Failed to update a ServiceWorker: Timed out while trying to start the Service Worker/,
      // ¯\_(ツ)_/¯
      /Failed to register a ServiceWorker: The user denied permission to use Service Worker/,
      // likely behind a proxy or something to do with Google Classroom
      /Failed to update a ServiceWorker: The script resource is behind a redirect/,
      /Failed to update a ServiceWorker: The script has an unsupported MIME type/,
      /Failed to update a ServiceWorker: Operation has been aborted/,
      /Failed to update a ServiceWorker: The request to fetch the script was interrupted/,
      // can happen because of network issues or if the user navigates to a different page while an
      // API request is still in progress
      /The network connection was lost/,
      // Non-actionable events https://github.com/getsentry/sentry-javascript/issues/7941#issuecomment-1815927662
      /Event `Event` (type=error) captured as exception/,
      /Non-Error promise rejection captured/,
    ],
    integrations: isServer
      ? // https://docs.sentry.io/platforms/javascript/guides/node/configuration/integrations/#adding-an-integration
        (integrations) => {
          return integrations
            .filter((integration) => integration.name !== 'Console')
            .concat([rewriteFramesIntegration(), extraErrorDataIntegration()]);
        }
      : // This function will be serialized by `serialize-javascript` so it needs to be
        // self-contained (not reference any outside variable). Also, `Sentry.Integrations` will
        // be populated at runtime which is why we are using the function form over a static array
        (integrations) => {
          const withoutBreadcrumbs = integrations.filter((integration) => integration.name !== 'Breadcrumbs');
          return [
            ...withoutBreadcrumbs,
            new Sentry.BrowserTracing({
              tracePropagationTargets: [/^https:\/\/[a-z]+\.getatomi\.com/],
            }),
            new Sentry.Integrations.ExtraErrorData(),
            new Sentry.Integrations.Breadcrumbs({
              console: false,
            }),
          ];
        },
    beforeBreadcrumb: (event) => {
      if (
        event.type === 'http' &&
        [/api.mixpanel.com/, /litix.io/, /embedwistia-a.akamaihd.net/].some((matcher) => matcher.test(event.data.url))
      ) {
        return false;
      }
      return event;
    },
    beforeSend: (event) => {
      // Filter out any events from google webcrawlers
      if (
        typeof navigator !== 'undefined' &&
        (navigator.userAgent.includes('Google-Read-Aloud') || navigator.userAgent.includes('Google Web Preview'))
      ) {
        return null;
      }
      return event;
    },
    tracesSampler: (samplingContext) => {
      const isLessonNavigation = samplingContext.transactionContext.name.match(/posts\/[0-9]+\/./);
      if (isLessonNavigation) {
        // There are a lot of these and they're very simple pseudo-navigations that should always be fast
        return 0.01 / 100;
      }
      return 0.5 / 100;
    },
  };

  if (yn(process.env.SENTRY_CREATE_RELEASE_ON_BUILD)) {
    Object.assign(config, {
      release: GIT_VERSION,
    });
  }

  return config;
}
