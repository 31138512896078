// @flow

import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import { AlertDialog, useToast } from '@getatomi/neon';

import type { ReduxState } from 'src/types';
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import type { DeleteSamlConfig, DeleteSamlConfigVariables } from 'src/graphql/types/generated/DeleteSamlConfig';

import DELETE_SAML_CONFIG from './DeleteSamlConfig.graphql';

type Props = {
  isOpen: boolean,
  onDone: () => void,
};

export type InjectedProps = Props & {
  accountId: number,
};

function mapStateToProps(state: ReduxState) {
  const accountId: number = getActiveSubscriptionId(state);
  return { accountId };
}

function SamlSsoDeleteDialog(props: InjectedProps) {
  const { accountId, isOpen, onDone } = props;
  const toast = useToast();

  const [deleteSamlConfig, { loading }] = useMutation<DeleteSamlConfig, DeleteSamlConfigVariables>(DELETE_SAML_CONFIG, {
    refetchQueries: ['GetSamlConfig'],
    variables: {
      input: {
        accountId: String(accountId),
      },
    },
    onCompleted: () => {
      toast.success('You have successfully deleted the SAML SSO configuration.');
      onDone();
    },
    onError: () => {
      toast.error('There was an error deleting the SAML SSO configuration. Please try again.');
    },
  });

  const onConfirm = () => {
    deleteSamlConfig().catch(() => {
      // Ignore errors thrown here.
      // Instead errors will be handled by the `onError` callback above in `useMutation()`.
      // Note that it's important to catch this error to prevent unhandled promise rejection warnings.
    });
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      variant="danger"
      heading="Delete configuration"
      onConfirmLabel="Delete configuration"
      onConfirm={onConfirm}
      onDismiss={onDone}
      isLoading={loading}
    >
      Users will not be able to access Atomi with SAML SSO until you set up a new configuration.
    </AlertDialog>
  );
}

export default (connect(mapStateToProps)(SamlSsoDeleteDialog): React.AbstractComponent<Props>);
