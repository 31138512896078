// @flow
import { Box, Container, Heading, HelpInfo, Spinner, Stack, Text, TextField } from '@getatomi/neon';
import { connect } from 'react-redux';
import { Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { withRouter, type ContextRouter } from 'react-router';
import Helmet from 'react-helmet';

import { loadRegion } from 'src/actions/regions';
import { updateRegistrationDetails } from 'src/actions/registration';
import Button from 'src/components/Button/Button';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import Link from 'src/components/Link/Link';
import links from 'src/constants/links';
import connectData from 'src/decorators/connectData';
import useIsClientLoaded, { clientRequiredAttribute, loadingMessage } from 'src/hooks/useIsClientLoaded';
import useParentRegistrationFormStep2, { type ChildFields } from 'src/hooks/forms/useParentRegistrationFormStep2';
import NonAuthenticatedLayout from 'src/layouts/NonAuthenticatedLayout/NonAuthenticatedLayout';
import { getAllLevelsForSelect } from 'src/reducers/levels';
import { isRegionsLoaded } from 'src/reducers/regions';
import scrollToActiveElement from 'src/utils/scrollToActiveElement';
import { type RegistrationState } from 'src/reducers/registration';
import type { BoundAction, RegistrationRegionCode, ReduxState } from 'src/types';

import MarketingPanel from '../MarketingPanel/MarketingPanel';

type Props = {
  params: {
    region: RegistrationRegionCode,
  },
};

type InjectedProps = Props & {
  levels: Array<{
    label: string,
    value: number,
  }>,
  registrationDetails: RegistrationState,
  router: ContextRouter,
  updateRegistrationDetailsAction: BoundAction<typeof updateRegistrationDetails>,
};

const fetchData = (getState, dispatch, location, params) => {
  const state = getState();

  if (!isRegionsLoaded(state)) {
    return dispatch(loadRegion({ region: params.region, withLevels: true }));
  }
};

const mapStateToProps = (state: ReduxState) => ({
  levels: getAllLevelsForSelect(state),
  registrationDetails: state.registration,
});

function RegistrationParentStep2(props: InjectedProps) {
  const {
    levels,
    params: { region },
    registrationDetails,
    router,
    updateRegistrationDetailsAction,
  } = props;
  const isClientLoaded = useIsClientLoaded();

  const { control, fields, form, isLoading } = useParentRegistrationFormStep2({
    defaultValues: {
      email: registrationDetails.childEmail,
      firstName: registrationDetails.childFirstName,
      lastName: registrationDetails.childLastName,
      levelId: registrationDetails.childLevelId,
    },
    onSubmitSuccess: (data: ChildFields) => {
      updateRegistrationDetailsAction({
        childEmail: data.email,
        childFirstName: data.firstName,
        childLastName: data.lastName,
        childLevelId: data.levelId,
      });
      router.push(`/register/parent/step-3/${region}`);
    },
    onSubmitFail: () => {
      scrollToActiveElement();
    },
    context: {
      parentEmail: registrationDetails?.email ?? '',
    },
  });

  const { isLoading: isEmailLoading, ...emailField } = fields.email;

  useEffect(() => {
    if (!registrationDetails?.firstName) {
      router.replace(`/register/parent/${region}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NonAuthenticatedLayout sidePanel={<MarketingPanel />}>
      <Helmet>
        <title>Parent registration</title>
      </Helmet>
      <Container textAlign="center" maxWidth="sizeContainerSmall">
        <Stack spacing="spacingLarge1X">
          <form {...form} {...clientRequiredAttribute}>
            <Stack spacing="spacingLarge5X">
              <div>
                <Heading as="h1">Let’s invite your child</Heading>
                <Text as="p" variant="lead">
                  We’ll email them an invite link to join the free trial on your Family account.
                </Text>
              </div>
              <Stack spacing="spacingLarge1X">
                <Box display="grid" gridGap="spacingRoot" gridTemplateColumns={{ mobile: 'repeat(2, 1fr)' }}>
                  <Controller
                    control={control}
                    name="firstName"
                    render={({ field }) => (
                      <TextField {...field} {...fields.firstName} autoComplete="off" autoCorrect="off" />
                    )}
                  />
                  <Controller
                    control={control}
                    name="lastName"
                    render={({ field }) => (
                      <TextField {...field} {...fields.lastName} autoComplete="off" autoCorrect="off" />
                    )}
                  />
                </Box>
                <div data-test="hide-in-percy">
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        {...emailField}
                        onChange={(value: string) => {
                          emailField.onChange();
                          field.onChange(value);
                        }}
                        type="email"
                        autoComplete="off"
                        autoCorrect="off"
                        helpText="They’ll use this to log in."
                        insertAfter={
                          isEmailLoading && <Spinner size="sizeIconSmall" testHook="registration-input-email-spinner" />
                        }
                      />
                    )}
                  />
                </div>
                <Controller
                  render={({ field }) => (
                    <Dropdown
                      ref={field.ref}
                      name="levelId"
                      items={levels}
                      selectedKey={String(field.value)}
                      onSelectionChange={field.onChange}
                      onBlur={field.onBlur}
                      {...fields.levelId}
                    >
                      {(item) => <Item key={item.value}>{item.label}</Item>}
                    </Dropdown>
                  )}
                  control={control}
                  name="levelId"
                />
              </Stack>
              <Button
                variant="legacy"
                type="submit"
                isLoading={isLoading}
                isDisabled={!isClientLoaded}
                title={isClientLoaded ? undefined : loadingMessage}
              >
                Invite them and continue
              </Button>
            </Stack>
          </form>
          <HelpInfo>
            You can only add one student to your family account for now.{' '}
            <Link href={links.support.parentUsersLimit} isExternal variant="monochrome">
              Learn more.
            </Link>
          </HelpInfo>
        </Stack>
      </Container>
    </NonAuthenticatedLayout>
  );
}

export default connectData(fetchData)(
  withRouter(
    connect(mapStateToProps, {
      updateRegistrationDetailsAction: updateRegistrationDetails,
    })(RegistrationParentStep2)
  )
);
