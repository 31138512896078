// @flow
import { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Box, Container, Divider, Flex, HideVisually, InfoTip, Stack, Text, TextField, useToast } from '@getatomi/neon';
import flattenChildren from 'react-flatten-children';

import type { BoundAction, ReduxState, RegionCode } from 'src/types';
import Button from 'src/components/Button/Button';
import ComboboxSchool from 'src/components/ComboboxSchool/ComboboxSchool';
import Link from 'src/components/Link/Link';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import { isLoggedInAsSuperAdmin } from 'src/reducers/auth';
import { getSchoolById } from 'src/reducers/schools';
import {
  getActiveSubscription,
  isUpdatingSubscription as isUpdatingSubscriptionSelector,
  isLoggedInAsAccountManager,
  isAdminOrAbove as isAdminOrAboveSelector,
  isLoggedInAsStudent,
  isLoggedInAsTeacher,
} from 'src/reducers/subscriptions';
import { updateSubscription } from 'src/actions/subscriptions';
import links from 'src/constants/links';
import featureFlags from 'src/constants/featureFlags';
import scrollToActiveElement from 'src/utils/scrollToActiveElement';

import ToggleFeatureFlag from './ToggleFeatureFlag/ToggleFeatureFlag';
import ToggleSetupMode from './ToggleSetupMode/ToggleSetupMode';
import useSchoolAccountForm, { type OutboundFields } from './useSchoolAccountForm';

function Toggles({ children }: { children: React.Node }) {
  const toggles = flattenChildren(children).filter(Boolean);

  if (toggles.length === 0) return null;

  return (
    <Box marginBottom="spacingLarge6X">
      <Stack spacing="spacingLarge2X">
        {toggles.map((toggle, i) => (
          <Fragment key={i}>
            {toggle}
            <Divider />
          </Fragment>
        ))}
      </Stack>
    </Box>
  );
}

export type SchoolAccountProps = {
  accountName: string,
  isAccountManager: boolean,
  isAdminOrAbove: boolean,
  isStudent: boolean,
  isSuperAdmin: boolean,
  isTeacher: boolean,
  isUpdatingSubscription: boolean,
  region: RegionCode,
  schoolId: number,
  schoolName: string,
  updateSubscriptionAction: BoundAction<typeof updateSubscription>,
};

const mapStateToProps = (state: ReduxState) => {
  const subscription = getActiveSubscription(state);

  return {
    accountName: subscription.name,
    isAdminOrAbove: isAdminOrAboveSelector(state),
    isAccountManager: isLoggedInAsAccountManager(state),
    isStudent: isLoggedInAsStudent(state),
    isSuperAdmin: isLoggedInAsSuperAdmin(state),
    isTeacher: isLoggedInAsTeacher(state),
    isUpdatingSubscription: isUpdatingSubscriptionSelector(state),
    region: subscription.region_code,
    schoolId: subscription.school_id,
    schoolName: getSchoolById(state, subscription.school_id)?.name,
  };
};

function SchoolAccount(props: SchoolAccountProps) {
  const {
    accountName,
    isAccountManager,
    isAdminOrAbove,
    isStudent,
    isSuperAdmin,
    isTeacher,
    isUpdatingSubscription,
    region,
    schoolId,
    schoolName,
    updateSubscriptionAction,
  } = props;
  const isReadOnly = isStudent || isTeacher;

  const toast = useToast();

  const { control, fields, form, serverError } = useSchoolAccountForm({
    isReadOnly,
    defaultValues: {
      name: accountName,
      schoolId,
      schoolName,
    },
    onSubmitSuccess: async (data: OutboundFields) => {
      await updateSubscriptionAction(data);
      toast.success('Your school account has been updated successfully.');
    },
    onSubmitFail: () => {
      scrollToActiveElement();
    },
    context: {
      canUpdateSchoolName: isAccountManager,
    },
  });

  const pageTitle = 'Change your school details';

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <HideVisually>
        <h2>{pageTitle}</h2>
      </HideVisually>
      <Container maxWidth="sizeContainerSmall" textAlign="center">
        <Toggles testHook="toggles">
          {isSuperAdmin && (
            <>
              <ToggleFeatureFlag
                flag={featureFlags.allowCrossSubjectContentSharing}
                label="Cross-share from all subjects"
                tooltip="Allow the sharing of content from all subjects/levels regardless of subscription. Seek manager's approval before use."
              />
              <ToggleFeatureFlag flag={featureFlags.insights} label="Insights" tooltip="Toggle the insights feature." />
            </>
          )}
          {(isAccountManager || isAdminOrAbove || isSuperAdmin) && (
            <ToggleFeatureFlag
              flag={featureFlags.denyStudentDataExport}
              label="Mark book download"
              tooltip="Allow teachers to download class data from the mark book, including student names."
              inverseFlag
            />
          )}
          {!isStudent && <ToggleSetupMode />}
        </Toggles>
      </Container>
      <Container maxWidth="sizeContainerSmall">
        <form {...form}>
          <Stack spacing="spacingLarge5X">
            <Stack spacing="spacingLarge1X">
              {serverError && <ValidationErrors errors={serverError.message} />}
              <Controller
                control={control}
                name="name"
                render={({ field }) => <TextField {...field} {...fields.name} />}
              />
              <Controller
                control={control}
                name="schoolName"
                render={({ field }) =>
                  isAccountManager ? (
                    <ComboboxSchool
                      region={region}
                      {...field}
                      {...fields.schoolName}
                      schoolName={schoolName}
                      schoolId={schoolId}
                      showPendingSchools
                    />
                  ) : (
                    // render as a read-only text field for non-account managers
                    <TextField
                      {...field}
                      {...fields.schoolName}
                      label={
                        <Flex gap="spacingSmall2X" alignItems="center">
                          School{' '}
                          {isAdminOrAbove && (
                            <InfoTip
                              iconSize="sizeIconSmall2X"
                              content="Changing your school is kind of a big deal, so you'll need to ask our support team to do it for you."
                            />
                          )}
                        </Flex>
                      }
                    />
                  )
                }
              />
            </Stack>
            {!isReadOnly && (
              <Flex justifyContent="center">
                <Button type="submit" isDisabled={isReadOnly} isLoading={isUpdatingSubscription}>
                  Update school account
                </Button>
              </Flex>
            )}
          </Stack>
        </form>
        {isReadOnly && (
          <Text as="p" variant="bodySmall1X" color="colorTextSubtler" textAlign="center" marginTop="spacingLarge2X">
            Your account is managed by your school. If you need to change something, get in touch with{' '}
            {isStudent ? 'your teacher' : 'one of your account admins'} or{' '}
            <Link href={links.contact} isExternal>
              say hi to our support team
            </Link>
            .
          </Text>
        )}
      </Container>
    </>
  );
}

export default (connect(mapStateToProps, { updateSubscriptionAction: updateSubscription })(
  SchoolAccount
): React.AbstractComponent<{}>);
