// @flow
import * as React from 'react';
import _ from 'lodash';

import type { ApiError } from 'src/types/index';
import FormMessage from 'src/components/FormMessage/FormMessage';

type Props = {
  error: ApiError,
};

const parseValidationErrors = (error) => {
  // TODO: this is not pretty but we will refactor everything shorlty (nucleus and carbon)
  const errorPrefix = 'emails.';
  return _.map(error.validationErrors, (value: string, key: string) =>
    key.startsWith(errorPrefix) ? `${key.replace(errorPrefix, '')}: ${value}` : value
  );
};

export default function UsersValidationErrors(props: Props) {
  const formattedErrors = parseValidationErrors(props.error);

  if (formattedErrors.length === 0) return null;

  return (
    <FormMessage>
      {formattedErrors.map((error, key) => (
        <React.Fragment key={key}>
          {error}
          <br />
        </React.Fragment>
      ))}
    </FormMessage>
  );
}
