// @flow
/**
 * Pagination higher order reducer and selectors
 */
import { get, map, merge, pick } from 'lodash';

export type PaginationType = {
  currentPage: number,
  pages: Object,
  pageSize: number,
  totalItems: number,
  totalPages: number,
};

export type PaginationStateType = {
  entities: Object,
  isAllEntitiesLoaded: boolean,
  isLoading: boolean,
  pagination: PaginationType,
  routeError: ?Object,
  searchKeywords: string,
};

export const reducer = (name: string, reducerFunction?: Function) => {
  const initialPagination = {
    pageSize: config[`RUNTIME_PAGINATION_PAGE_SIZE_${name && name.toUpperCase()}`],
    currentPage: 0,
    totalPages: 0,
    totalItems: 0,
    pages: {
      // 1: [1, 2, 3],
    },
  };

  const initialState = {
    isLoading: false,
    entities: {},
    isAllEntitiesLoaded: false,
    pagination: {
      ...initialPagination,
    },
    routeError: null,
    searchKeywords: '',
  };

  return function paginationReducer(
    state: PaginationStateType = initialState,
    action: Object = {}
  ): PaginationStateType {
    // This is not one of the higher order reducer function
    // Return provided reducer
    if (action.name !== 'pagination' && reducerFunction) {
      return reducerFunction(state, action, initialPagination);
    }

    switch (action.type) {
      case `LOAD_${name.toUpperCase()}`:
        return {
          ...state,
          isLoading: true,
        };
      case `LOAD_${name.toUpperCase()}_SUCCESS`: {
        const { items: newIds, meta } = action.result.result;

        let newPagination = { ...state.pagination };

        // the response is paginated
        if (meta) {
          const { pagination } = meta;
          newPagination = {
            ...state.pagination,
            currentPage: pagination.current_page,
            totalPages: pagination.total_pages,
            totalItems: pagination.total,
            pages: {
              ...state.pagination.pages,
              [pagination.current_page]: newIds,
            },
          };
        }

        return {
          ...state,
          entities: merge({}, state.entities, get(action.result, 'entities.users')),
          isLoading: false,
          pagination: newPagination,
          isAllEntitiesLoaded: !meta ? true : state.isAllEntitiesLoaded,
        };
      }
      case `LOAD_${name.toUpperCase()}_FAIL`:
        return {
          ...state,
          isLoading: false,
          routeError: action.routeError,
        };

      case `UPDATE_${name.toUpperCase()}_CURRENT_PAGE`: {
        return {
          ...state,
          source: action.source,
          pagination: {
            ...state.pagination,
            currentPage: action.page,
          },
        };
      }

      case `CLEAR_${name.toUpperCase()}_PAGINATION`:
        return {
          ...state,
          pagination: {
            ...state.pagination,
            pages: {
              ...pick(state.pagination.pages, action.currentPage),
            },
          },
        };

      case `UPDATE_${name.toUpperCase()}_SEARCH_KEYWORDS`:
        return {
          ...state,
          searchKeywords: action.keywords,
        };

      default:
        return state;
    }
  };
};

// Selectors
export const isLoading = (state: Object, name: string) => state[name].isLoading;
export const isAllEntitiesLoaded = (state: Object, name: string) => state[name].isAllEntitiesLoaded;

export const getPagination = (state: Object, name: string) => state[name].pagination;
export const getPageSize = (state: Object, name: string) => +getPagination(state, name).pageSize;
export const getTotalEntities = (state: Object, name: string) => getPagination(state, name).totalItems;
export const isPageLoaded = (state: Object, page: number, name: string) => !!getPagination(state, name).pages[page];

export const getEntityById = (state: Object, id: number, name: string) => state[name].entities[id];
export const getEntitiesByIds = (state: Object, ids: Array<number>, name: string) =>
  map(ids, (id) => getEntityById(state, id, name)).filter(Boolean);
export const getAllEntitiesForCurrentPage = (state: Object, name: string) => {
  const pagination = getPagination(state, name);
  const userIds = pagination.pages[pagination.currentPage];

  if (!userIds) return [];

  return userIds.map((id) => getEntityById(state, id, name));
};

export const getSearchKeywords = (state: Object, name: string) => state[name].searchKeywords;
