// @flow
import { Alert } from '@getatomi/neon';

import Link, { UnstyledLink } from 'src/components/Link/Link';
import { getBillingUrl } from 'src/utils/routes';

type Props = {
  subscriptionId: number,
};

export default function MissingPaymentSourceAlert(props: Props) {
  const billingUrl = `${getBillingUrl(props.subscriptionId)}#paymentDetails`;

  return (
    <Alert
      isBanner
      variant="danger"
      buttonProps={{ children: 'Update payment details', to: billingUrl, as: UnstyledLink }}
      testHook="alert-missing-payment-source"
    >
      You have no payment method on your account. Please{' '}
      <Link to={billingUrl} variant="monochrome">
        update your payment details
      </Link>{' '}
      to keep your subscription active.
    </Alert>
  );
}
