// @flow
import { useRef } from 'react';

import useAlertDialog from 'src/hooks/components/useAlertDialog';
import type { TaskStatus } from 'src/domains/Tasks/ClassTasks/classTasksTransformer';

type Props = {
  handleSubmit: (taskStatus: TaskStatus) => mixed,
  onClose: () => mixed,
  taskStatus: TaskStatus,
};

function useUnsavedChangesPrompt({ handleSubmit, onClose, taskStatus }: Props) {
  const shouldShowUnsavedChangesPrompt = useRef(false);
  const setShouldShowUnsavedChangesPrompt = () => {
    shouldShowUnsavedChangesPrompt.current = true;
  };

  const shouldSaveAsDraft = taskStatus === 'draft';

  const [unsavedChangesPrompt, showUnsavedChangesPrompt] = useAlertDialog({
    closeOnConfirm: true,
    heading: 'Closing this page will discard all unsaved changes',
    onConfirmLabel: 'Close and discard',
    onConfirm: async () => {
      onClose();
    },
    onDismissLabel: 'Save changes',
    onDismiss: () => {
      if (shouldSaveAsDraft) {
        handleSubmit('draft');
      } else {
        handleSubmit(taskStatus === 'scheduled' ? 'scheduled' : 'published');
      }
    },
  });

  return [
    unsavedChangesPrompt,
    showUnsavedChangesPrompt,
    shouldShowUnsavedChangesPrompt.current,
    setShouldShowUnsavedChangesPrompt,
  ];
}

export default useUnsavedChangesPrompt;
