// @flow
import { forwardRef } from 'react';
import {
  IconDragAndDrop,
  IconExactAnswer,
  IconExtendedResponse,
  IconMultiChoice,
  IconShortAnswer,
  OptionGroup,
  OptionGroupItem,
} from '@getatomi/neon';

import type { QuestionTypeFilter } from './types';
import { questionTypes } from './constants';
import { disabledProps } from './utils';

type SelectQuestionTypesProps = {
  disabledValues: { [key: QuestionTypeFilter]: boolean },
  onChange: (value: Array<QuestionTypeFilter>) => mixed,
  questionTypeError?: ?string,
  value: Array<QuestionTypeFilter>,
};

export default forwardRef<SelectQuestionTypesProps, React.Node>(function SelectQuestionTypes(
  props: SelectQuestionTypesProps,
  ref
) {
  const { disabledValues, value, onChange, questionTypeError } = props;

  const errorProps = questionTypeError
    ? {
        errorVariant: 'error',
        validationText: questionTypeError,
      }
    : undefined;

  return (
    <OptionGroup
      selectionMode="multiple"
      label="Question types"
      value={value}
      onChange={onChange}
      variant="square"
      ref={ref}
      testHook="revision-dialog-question-types-group"
      {...errorProps}
    >
      <OptionGroupItem
        icon={<IconMultiChoice />}
        value={questionTypes.multiChoice}
        {...disabledProps(disabledValues[questionTypes.multiChoice])}
        testHook="revision-dialog-question-types-multi-choice"
      >
        Multi-choice
      </OptionGroupItem>
      <OptionGroupItem
        icon={<IconDragAndDrop />}
        value={questionTypes.dragAndDrop}
        {...disabledProps(disabledValues[questionTypes.dragAndDrop])}
        testHook="revision-dialog-question-types-drag-and-drop"
      >
        Drag and drop
      </OptionGroupItem>
      <OptionGroupItem
        icon={<IconExactAnswer />}
        value={questionTypes.exactAnswer}
        {...disabledProps(disabledValues[questionTypes.exactAnswer])}
        testHook="revision-dialog-question-types-exact-answer"
      >
        Exact answer
      </OptionGroupItem>
      <OptionGroupItem
        icon={<IconShortAnswer />}
        value={questionTypes.shortAnswer}
        {...disabledProps(disabledValues[questionTypes.shortAnswer])}
        testHook="revision-dialog-question-types-short-answer"
      >
        Short answer
      </OptionGroupItem>
      <OptionGroupItem
        icon={<IconExtendedResponse />}
        value={questionTypes.extendedResponse}
        {...disabledProps(disabledValues[questionTypes.extendedResponse])}
        testHook="revision-dialog-question-types-extended-response"
      >
        Extended response
      </OptionGroupItem>
    </OptionGroup>
  );
});
