// @flow
import keyMirror from 'keymirror';

// userStatuses represents the status of the user e.g. the status is active when
// the email is verified, profile updated etc.

// it's returned by the redux store and the federated graph under user.status
const userStatuses = keyMirror({
  active: null,
  archived: null,
  invited: null,
});

export default userStatuses;

export const userStatusLabels = {
  [userStatuses.active]: 'active',
  [userStatuses.archived]: 'archived',
  [userStatuses.invited]: 'pending',
};
