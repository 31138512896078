// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import { useState, useRef, useEffect } from 'react';
import {
  Box,
  Container,
  Divider,
  Heading,
  Item,
  ItemDescription,
  ItemLabel,
  Modal,
  PickList,
  Stack,
  StatusLight,
  Text,
  Truncate,
  useId,
  useMediaQuery,
  useToast,
} from '@getatomi/neon';

import type { BoundAction, ReduxState } from 'src/types';
import Button from 'src/components/Button/Button';
import FormMessage from 'src/components/FormMessage/FormMessage';
import Input from 'src/components/Input/Input';
import ModalActions from 'src/components/deprecated/ModalActions/ModalActions';
import Select from 'src/components/deprecated/Select/Select';
import Logger from 'src/utils/Logger';
import { loadCurrentSubscriptionClasses } from 'src/actions/classes';
import { inviteUserAndReloadSubscription, bulkInviteUsersAndReloadSubscription } from 'src/actions/users';
import { getNonPaginatedClasses, isLoadingClasses as isLoadingClassesSelector } from 'src/reducers/classes';
import { getActiveSubscriptionName, getUserRole, isActiveSubscriptionInSetupMode } from 'src/reducers/subscriptions';
import { isInvitingUsers as isInvitingUsersSelector } from 'src/reducers/users';
import { getRolesForInvite } from 'src/utils/roles';
import { trackingCtas } from 'src/constants/tracking';
import { parseValidationErrors } from 'src/api/validation';

import BulkInviteUsersDialog from '../BulkInviteUsersDialog/BulkInviteUsersDialog';

const log = new Logger('domains/Settings/Users/InviteUsersDialog');

type Props = {
  onClose: () => mixed,
  onSuccess: (alert: boolean) => mixed,
  onViewPendingInvites: () => mixed,
  showModal: boolean,
};

type InjectedProps = Props & {
  accountName: string,
  bulkInviteUsersAction: BoundAction<typeof bulkInviteUsersAndReloadSubscription>,
  classes: Array<Object>,
  inviteUserAction: BoundAction<typeof loadCurrentSubscriptionClasses>,
  isInvitingUsers: boolean,
  isLoadingClasses: boolean,
  isSetupModeActive: boolean,
  loadAllClassesAction: BoundAction<typeof loadCurrentSubscriptionClasses>,
  roles: Array<{
    disabled: boolean,
    label: string,
    value: string,
  }>,
};

const mapStateToProps = (state: ReduxState) => {
  const classes = getNonPaginatedClasses(state, { incSubject: true, incLevels: true });

  return {
    roles: getRolesForInvite(getUserRole(state)),
    classes: _.map(classes, (c) => ({
      color: c.subject.color,
      level: c.levels ? c.levels[0] : null,
      ...c,
    })),
    isInvitingUsers: isInvitingUsersSelector(state),
    isLoadingClasses: isLoadingClassesSelector(state),
    isSetupModeActive: isActiveSubscriptionInSetupMode(state),
    accountName: getActiveSubscriptionName(state),
  };
};

function InviteUsersDialog(props: InjectedProps) {
  const {
    onViewPendingInvites,
    inviteUserAction,
    onSuccess,
    classes,
    loadAllClassesAction,
    isLoadingClasses,
    roles,
    showModal,
    accountName,
    isSetupModeActive,
    isInvitingUsers,
    bulkInviteUsersAction,
    onClose,
  } = props;

  const [isBulkInviting, setIsBulkInviting] = useState<boolean>(false);
  const [email, setEmail] = useState<?string>(null);
  const [role, setRole] = useState<?string>(null);
  const [firstName, setFirstName] = useState<?string>(null);
  const [lastName, setLastName] = useState<?string>(null);
  const [invitedClasses, setInvitedClasses] = useState<Set<string>>(new Set([]));
  const [serverErrors, setServerErrors] = useState<Array<string>>([]);

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const inputs = useRef({});
  const toast = useToast();

  const onFieldChange = (name, value) => {
    switch (name) {
      case 'role':
        return setRole(value);
      case 'firstName':
        return setFirstName(value);
      case 'lastName':
        return setLastName(value);
      default:
        return setEmail(value);
    }
  };

  const onClassesChange = (selectedClasses: Set<string>) => {
    setInvitedClasses(selectedClasses);
  };

  const onCloseHandler = () => {
    onClose();

    setInvitedClasses(new Set([]));
    setEmail(null);
    setRole(null);
    setFirstName(null);
    setLastName(null);
    setIsBulkInviting(false);
  };

  const viewPendingInvites = (e: Event) => {
    e.preventDefault();
    onViewPendingInvites();
    onCloseHandler();
  };

  const onSubmit = async (e: SyntheticEvent<>) => {
    e.preventDefault();

    const alertSuccess = true;
    const invalidInput = _.find(
      _.map(inputs.current, (value, key: string) => {
        return { key, isValid: value.validate() };
      }),
      { isValid: false }
    );

    if (invalidInput && inputs.current) {
      const invalidElement = inputs.current[invalidInput.key];
      invalidElement.inputField.scrollIntoView(true);
    } else if (inviteUserAction) {
      try {
        await inviteUserAction({
          classes: Array.from(invitedClasses),
          email,
          role,
          firstName,
          lastName,
          isBulkInviting,
        });

        onSuccess(alertSuccess);
        onCloseHandler();
      } catch (error) {
        log.warn('Form submission: invite users to subscription failed', error);
        const errors = parseValidationErrors(error);
        setServerErrors(errors);

        if (errors.length === 0) {
          onClose();
          toast.error('There was an error inviting this user. Please try again.');
        }
      }
    }
  };

  const loadClasses = () => {
    if (!isLoadingClasses && classes && classes.length === 0) {
      loadAllClassesAction({ withRelatedEntities: false });
    }
  };

  const toggleBulkInviteForm = (e: Event) => {
    e.preventDefault();
    setIsBulkInviting((value) => !value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadClasses(), []);

  const headingId = useId(`InviteUsersDialogHeading`);

  return (
    <Modal ariaLabelledBy={headingId} variant="view" size="fullscreen" isOpen={showModal} onClose={onCloseHandler}>
      {isBulkInviting ? (
        <BulkInviteUsersDialog
          roles={roles}
          accountName={accountName}
          isSetupModeActive={isSetupModeActive}
          isInvitingUsers={isInvitingUsers}
          bulkInviteUsers={bulkInviteUsersAction}
          onClose={onCloseHandler}
          onSuccess={onSuccess}
          onGoBack={toggleBulkInviteForm}
          headingId={headingId}
        />
      ) : (
        <Box as="form" testHook="add-users-modal-form" paddingBottom="spacingLarge5X">
          <Stack spacing="spacingLarge3X">
            <Container maxWidth="sizeContainerRoot" textAlign="center">
              <Stack spacing="spacingLarge2X">
                <Heading as="h1" id={headingId}>
                  Invite users
                </Heading>
                {!isMobile && (
                  <Text variant="lead" as="p">
                    Invite new users to join your school account!
                  </Text>
                )}
              </Stack>
            </Container>
            <Container maxWidth="sizeContainerSmall">
              {!isMobile && (
                <Box marginBottom="spacingLarge3X">
                  <Divider />
                </Box>
              )}
              {serverErrors.length > 0 && <FormMessage>{serverErrors.join('<br />')}</FormMessage>}
              <Box display="grid" gridTemplateColumns={{ tablet: '1fr 180px' }} columnGap="spacingRoot">
                <Input
                  ref={(el) => {
                    if (inputs.current) inputs.current.email = el;
                  }}
                  type="email"
                  name="email"
                  isMandatory
                  label="Email address"
                  initialValue={email}
                  autoComplete="off"
                  onChange={onFieldChange}
                  testHook="email"
                />
                <Select
                  ref={(el) => {
                    if (inputs.current) inputs.current.role = el;
                  }}
                  label="Role"
                  name="role"
                  options={roles}
                  isMandatory
                  initialValue={role}
                  onChange={onFieldChange}
                  testHook="role-select"
                />
              </Box>
              <Box display="grid" gridTemplateColumns={{ tablet: '1fr 1fr' }} columnGap="spacingRoot">
                <Input
                  ref={(el) => {
                    if (inputs.current) inputs.current.firstName = el;
                  }}
                  label="First name"
                  name="firstName"
                  placeholder="Optional"
                  maxLength={process.env.VALIDATION_MAX_LENGTH_FIRSTNAME}
                  autoComplete="off"
                  onChange={onFieldChange}
                />
                <Input
                  ref={(el) => {
                    if (inputs.current) inputs.current.lastName = el;
                  }}
                  label="Last name"
                  name="lastName"
                  placeholder="Optional"
                  maxLength={process.env.VALIDATION_MAX_LENGTH_LASTNAME}
                  autoComplete="off"
                  onChange={onFieldChange}
                />
              </Box>
              {classes.length > 0 && (
                <PickList
                  label="Want to add them to some classes?"
                  inputPlaceholder="Search by class or subject name"
                  emptyState="None of the classes match your search."
                  items={classes}
                  selectedKeys={invitedClasses}
                  onSelectionChange={onClassesChange}
                  filter={(item, text) => {
                    const searchText = text.toLowerCase().trim();
                    return (
                      item.name.toLowerCase().includes(searchText) ||
                      item.subject.name.toLowerCase().includes(searchText)
                    );
                  }}
                  renderTag={(item) => (
                    <Item textValue={item.name}>
                      <StatusLight color={item.color} />
                      <Truncate>{item.name}</Truncate>
                    </Item>
                  )}
                >
                  {(item) => (
                    <Item textValue={item.name}>
                      <StatusLight color={item.color} />
                      <Box>
                        <ItemLabel>
                          <Text variant="bodySmall" lineHeight="lineHeightSmall2X" color="colorText">
                            {item.name}
                          </Text>
                        </ItemLabel>
                        {item.subject && item.level && (
                          <ItemDescription>
                            <Text variant="bodySmall1X" color="colorTextSubtler">
                              {item.subject.name}, {item.level.name}
                            </Text>
                          </ItemDescription>
                        )}
                      </Box>
                    </Item>
                  )}
                </PickList>
              )}
            </Container>
            <ModalActions
              isStacked
              submitLabel="Invite Person"
              isLoading={isInvitingUsers}
              onSubmit={onSubmit}
              onCancel={onCloseHandler}
              trackingData={{
                cta: trackingCtas.subscriptionInviteUser,
                userType: role,
              }}
            />
            <Text as="p" variant="bodySmall1X" textAlign="center" marginTop="spacingLarge6X">
              Need to invite many more?{' '}
              <Button size="small" variant="text" onClick={toggleBulkInviteForm} testHook="button-bulk-import">
                Bulk import users instead.
              </Button>
              <br />
              <Button size="small" variant="text" onClick={viewPendingInvites}>
                View pending invites.
              </Button>
            </Text>
          </Stack>
        </Box>
      )}
    </Modal>
  );
}

export default (connect(mapStateToProps, {
  inviteUserAction: inviteUserAndReloadSubscription,
  bulkInviteUsersAction: bulkInviteUsersAndReloadSubscription,
  loadAllClassesAction: loadCurrentSubscriptionClasses,
})(InviteUsersDialog): React.AbstractComponent<Props>);
