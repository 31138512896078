// @flow
import { Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import { Box, Button, Container, Divider, Flex, Heading, Modal, Stack, Text, Textarea, useId } from '@getatomi/neon';

import type { BoundAction, ReduxState } from 'src/types';
import Date from 'src/components/Date/Date';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import { cancelSubscription } from 'src/actions/subscriptions';
import { getPlanPaymentFrequency } from 'src/reducers/plans';
import {
  getActiveSubscriptionExpiryDate,
  isCancellingSubscription as isCancellingSubscriptionSelector,
  isFamilyPlan as isFamilyPlanSelector,
} from 'src/reducers/subscriptions';

import useTurnOffAutoRenewalForm from './useTurnOffAutoRenewalForm';

type Props = {
  isVisible: boolean,
  onClose: () => mixed,
};

export type InjectedProps = Props & {
  cancelSubscriptionAction: BoundAction<typeof cancelSubscription>,
  isCancellingSubscription: boolean,
  isFamilyPlan: boolean,
  paymentFrequency: string,
  subscriptionExpiryDate: string,
};

const mapStateToProps = (state: ReduxState) => ({
  isCancellingSubscription: isCancellingSubscriptionSelector(state),
  isFamilyPlan: isFamilyPlanSelector(state),
  paymentFrequency: getPlanPaymentFrequency(state),
  subscriptionExpiryDate: getActiveSubscriptionExpiryDate(state),
});

function TurnOffAutoRenewalDialog(props: InjectedProps) {
  const {
    cancelSubscriptionAction,
    isCancellingSubscription,
    isFamilyPlan,
    isVisible,
    onClose,
    paymentFrequency,
    subscriptionExpiryDate,
  } = props;

  const headingId = useId();
  const formId = useId();
  const { control, fields, onSubmit } = useTurnOffAutoRenewalForm({
    onSubmitSuccess: async (data) => {
      await cancelSubscriptionAction(data.reason, data.details);
      onClose();
    },
    onSubmitError: () => {
      onClose();
    },
  });

  const cancellationReasons = [
    { value: 'cost', label: 'It’s too expensive' },
    { value: 'graduating', label: isFamilyPlan ? 'My child is graduating' : 'I’m graduating!' },
    {
      value: 'lackContent',
      label: isFamilyPlan
        ? 'There’s not enough content for my child’s subjects'
        : 'There’s not enough content for my subjects',
    },
    { value: 'notHelpful', label: isFamilyPlan ? 'My child didn’t find it helpful' : 'I didn’t find it helpful' },
    { value: 'notNeeded', label: isFamilyPlan ? 'My child isn’t using it enough' : 'I don’t need it right now' },
    { value: 'other', label: 'Other' },
  ];

  return (
    <Modal ariaLabelledBy={headingId} isOpen={isVisible} onClose={onClose} size="fullscreen" variant="view">
      <Container maxWidth="sizeContainerRoot" textAlign="center">
        <Stack spacing={{ base: 'spacingLarge', desktop: 'spacingLarge2X' }}>
          <Heading as="h1" id={headingId} variant="large1X">
            Turn off automatic-renewal
          </Heading>
          <Text as="p" variant="lead">
            By turning off automatic renewal, your Unlimited {isFamilyPlan && 'Family '}
            {paymentFrequency === 'year' ? 'Yearly ' : 'Monthly '}
            subscription will be cancelled and you {isFamilyPlan && 'and your child '}
            will no longer have access to Atomi once it expires. You can always turn it back on if you change your mind!
          </Text>
          <Container maxWidth="sizeContainerSmall" paddingInline="spacingNone">
            <Divider />
          </Container>

          <Box as="form" id={formId} marginBottom="spacingLarge4X" textAlign="left">
            <Container maxWidth="sizeContainerSmall" paddingInline="spacingNone">
              <Box as="p" marginBottom="spacingLarge5X" textAlign="center" testHook="expiry-date">
                Your <strong>{paymentFrequency === 'year' ? 'yearly' : 'monthly'} subscription</strong> will expire on{' '}
                <strong>
                  <Date value={subscriptionExpiryDate} />
                </strong>{' '}
                if you turn it off.
              </Box>
              <Stack spacing="spacingLarge1X">
                <Controller
                  control={control}
                  name="reason"
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      {...fields.reason}
                      items={cancellationReasons}
                      selectedKey={field.value ? String(field.value) : null}
                      onSelectionChange={(value) => {
                        field.onChange(value);
                      }}
                    >
                      {(item) => <Item key={item.value}>{item.label}</Item>}
                    </Dropdown>
                  )}
                />
                <Controller
                  control={control}
                  name="details"
                  render={({ field }) => <Textarea {...field} {...fields.details} />}
                />
                <Flex direction="column" justifyContent="center" gap="spacingLarge1X" alignItems="center">
                  <Button
                    isLoading={isCancellingSubscription}
                    onClick={onSubmit}
                    form={formId}
                    type="submit"
                    variant="danger"
                  >
                    I understand, turn it off
                  </Button>
                  <Button onClick={onClose} variant="text">
                    No, take me back to safety
                  </Button>
                </Flex>
              </Stack>
            </Container>
          </Box>
        </Stack>
      </Container>
    </Modal>
  );
}

export default (connect(mapStateToProps, {
  cancelSubscriptionAction: cancelSubscription,
})(TurnOffAutoRenewalDialog): React.AbstractComponent<Props>);
