// @flow
import { Box } from '@getatomi/neon';

type Props = {|
  children: React.Node,
|};

export default function CardGrid(props: Props) {
  const { children } = props;

  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        base: `repeat(3, minmax(0, 1fr))`,
        desktop: `repeat(4, minmax(0, 1fr))`,
      }}
      gridColumnGap="spacingLarge3X"
      gridRowGap="spacingLarge6X"
    >
      {children}
    </Box>
  );
}
