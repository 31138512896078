// @flow
import { Badge } from '@getatomi/neon';

import type { UserRoles } from 'src/types';
import { rolesLabels, roleTypes as userRoles } from 'src/constants/userRoles';

type UserRoleBadgeProps = {|
  role: UserRoles,
|};

export default function UserRoleBadge(props: UserRoleBadgeProps) {
  const { role } = props;
  const isStudent = role === userRoles.student;
  const isTeacher = role === userRoles.teacher;

  let tooltipContent;
  if (role === userRoles.account_owner) {
    tooltipContent =
      'The account owner manages the highest-level of administrative settings: payments and billing, team authentication and so on. They can transfer the account ownership to another admin user.';
  } else if (role === userRoles.account_administrator) {
    tooltipContent = 'Admins are able to manage users and user roles, the school account settings and integrations.';
  }

  return (
    <Badge
      variant={isStudent ? 'dashed' : undefined}
      color={!isStudent && !isTeacher ? 'colorTextBrand' : undefined}
      tooltip={tooltipContent}
    >
      {rolesLabels[role]}
    </Badge>
  );
}
