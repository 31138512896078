// @flow
import { Flex, Truncate, Stack, Text, InfoTip, useMediaQuery } from '@getatomi/neon';

import UserName from 'src/components/UserName/UserName';

import { type SubscriptionUserRowOutput } from '../utilities/prepareUserForRow';

type Props = {|
  canSeeEmailError: boolean,
  user: SubscriptionUserRowOutput,
|};

function Tooltips({ user, canSeeEmailError }: Props) {
  const { emailFailureReason, shouldDisplayNoClassesTooltip } = user;
  return (
    <>
      {shouldDisplayNoClassesTooltip && (
        <InfoTip
          content="This student is not in any classes. We recommend either adding them to classes or archiving them."
          variant="warning"
          testHook="tooltip-no-class"
        />
      )}
      {emailFailureReason && (
        <InfoTip
          content={emailFailureReason}
          variant="warning"
          {...(canSeeEmailError ? { size: 'large', isTextOnly: false } : {})}
          testHook="tooltip-email-failure"
        />
      )}
    </>
  );
}

export default function CellUserName({ user, canSeeEmailError }: Props) {
  const { first_name: firstName, last_name: lastName, currentAuthenticatedUserLabel: authLabel, email } = user;
  const hasFullName = Boolean(firstName && lastName);
  const shouldRenderName = hasFullName || authLabel;
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  // eslint-disable-next-line no-nested-ternary
  return isMobile ? (
    shouldRenderName ? (
      <Stack spacing="spacingSmall1X">
        <Flex alignItems="center">
          <>
            {firstName && lastName && <UserName firstName={firstName} lastName={lastName} />} {authLabel}
          </>
          <Tooltips user={user} canSeeEmailError={canSeeEmailError} />
        </Flex>
        <Truncate>
          <Text as="span" fontSize="fontSizeSmall1X" color="colorTextSubtler">
            {user.email}
          </Text>
        </Truncate>
      </Stack>
    ) : (
      <Flex alignItems="center">
        <Truncate>{email}</Truncate>
        <Tooltips user={user} canSeeEmailError={canSeeEmailError} />
      </Flex>
    )
  ) : (
    <Flex alignItems="center">
      {hasFullName ? (
        <>
          {firstName && lastName && <UserName firstName={firstName} lastName={lastName} />} {authLabel}
        </>
      ) : (
        <Truncate>
          {authLabel} {email}
        </Truncate>
      )}
      <Tooltips user={user} canSeeEmailError={canSeeEmailError} />
    </Flex>
  );
}
