// @flow
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Container, EmptyState, Illustration } from '@getatomi/neon';

import type { ReduxState } from 'src/types';
import MasterLayout from 'src/layouts/shared/MasterLayout/MasterLayout';
import Link from 'src/components/Link/Link';
import links from 'src/constants/links';

import BrowsersList from './BrowsersList/BrowsersList';
import styles from './UnsupportedBrowser.module.scss';

// $FlowIgnore - redux state can't be exact, otherwise it is impossible to mutate
const mapStateToProps = (state: ReduxState) => state.browser;

type Props = $Call<typeof mapStateToProps, ReduxState>;

function UnsupportedBrowser(props: Props) {
  const { current, supported } = props;
  const isBrowserOutOfDate = !!supported;
  const isIOS = current?.family === 'iOS';

  return (
    <div className={styles.root}>
      <Helmet htmlAttributes={{ className: 'no-js' }}>
        <title>Your web browser is not supported</title>
      </Helmet>
      <MasterLayout removeSpacingBetweenHeaderAndMainContent="all" withFooter={false} withSecondaryBackgroundColor>
        <Container maxWidth="sizeContainerRoot">
          <EmptyState
            media={<Illustration name="main-browser" />}
            heading={
              isBrowserOutOfDate
                ? `Oops, your ${isIOS ? 'device’s software' : 'web browser'} is out of date!`
                : 'Oops, your web browser is not supported!'
            }
            headingProps={{ as: 'h1' }}
            description={
              <>
                {current && isBrowserOutOfDate && (
                  <>
                    You’re currently using {current.family} version <strong>{current.version}</strong>.
                    <br />
                    Atomi currently supports version <strong>{supported}</strong> and above.
                    <br />
                    <br />
                  </>
                )}
                {isIOS ? (
                  <>
                    We’ve built Atomi using the latest technologies so it’s faster and easier to use. Unfortunately your
                    iOS version doesn’t support these technologies. Download a more recent{' '}
                    <strong>Software Update</strong> and you’ll be on your way.
                  </>
                ) : (
                  <>
                    We’ve built Atomi using the latest technologies so it’s faster and easier to use. Unfortunately your
                    browser doesn’t support these technologies. Download the <strong>latest version</strong> of one of
                    these great browsers and you’ll be on your way:
                  </>
                )}
              </>
            }
            primaryAction={!isIOS ? <BrowsersList /> : undefined}
            helpInfo={
              <Link href={links.support.technologyRequirements} isExternal variant="monochrome">
                Learn more about the technical requirements to use Atomi in our Help Centre.
              </Link>
            }
          />
        </Container>
      </MasterLayout>
    </div>
  );
}

export default (connect(mapStateToProps)(UnsupportedBrowser): React.AbstractComponent<Props>);
