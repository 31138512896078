// @flow
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { type ContextRouter, withRouter } from 'react-router';
import Helmet from 'react-helmet';
import { HideVisually, useToast } from '@getatomi/neon';

import type { BoundAction } from 'src/types';
import Loader from 'src/components/Loader/Loader';
import { confirmUser } from 'src/actions/auth';

export type ConfirmUserProps = {
  confirmUserAction: BoundAction<typeof confirmUser>,
  params: {
    code: string,
  },
  router: ContextRouter,
};

function ConfirmUser(props: ConfirmUserProps) {
  const {
    confirmUserAction,
    params: { code },
    router,
  } = props;

  const toast = useToast();

  useEffect(() => {
    const confirmUserOnLoad = async () => {
      try {
        const email = await confirmUserAction(code);
        toast.success(`Your email ${email} has been validated successfully.`);
      } catch (error) {
        toast.error(error.statusCode === 404 ? error.message : 'There was an error confirming your account.');
      }
      router.replace('/');
    };
    confirmUserOnLoad();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const title = 'Validating your account…';

  return (
    <main id="main">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <HideVisually>
        <h1>{title}</h1>
      </HideVisually>
      <Loader />
    </main>
  );
}

export default withRouter(connect(null, { confirmUserAction: confirmUser })(ConfirmUser));
