// @flow
import keyMirror from 'keymirror';
import { flatten, flow, orderBy, partition } from 'lodash/fp';
import { useCallback, useState, useMemo } from 'react';

import { successLimits } from 'src/constants/progress';
import getURLParam from 'src/utils/getURLParam';
import type { MarksStudent } from 'src/domains/ViewingQuizResults/types';
import { cohorts, isPartOfCohort } from 'src/domains/ViewingQuizResults/utilities/studentCohorts';

const values = keyMirror({
  all: null,
  [cohorts.GOING_WELL]: null,
  [cohorts.NEEDS_HELP]: null,
  [cohorts.NEEDS_REVISION]: null,
  [cohorts.NOT_COMPLETED]: null,
});

export type FilterBy = $Keys<typeof values>;

export type FilterOption = {
  key: FilterBy,
  label: string,
};

export const options: $ReadOnlyArray<FilterOption> = [
  { label: 'All students', key: 'all' },
  { label: `Scoring above ${(successLimits.goingWellThreshold * 100).toFixed(0)}%`, key: cohorts.GOING_WELL },
  { label: `Scoring less than ${(successLimits.needsHelpThreshold * 100).toFixed(0)}%`, key: cohorts.NEEDS_HELP },
  { label: 'Need to revise', key: cohorts.NEEDS_REVISION },
  { label: 'Not completed', key: cohorts.NOT_COMPLETED },
];

export type SortBy = {
  desc: boolean,
  id: 'hasAttempt' | 'fullName' | 'mark' | 'duration' | 'lastAttempt' | 'strength',
};

const defaults = {
  sortBy: { id: 'lastAttempt', desc: false },
  filterBy: 'all',
};

function getFilterURLParam(): FilterBy {
  const value = getURLParam('filter') ?? defaults.filterBy;
  return values[value] ?? defaults.filterBy;
}

type FilteredStudents = {
  filterBy: FilterBy,
  options: $ReadOnlyArray<FilterOption>,
  setFilterBy: (value: FilterBy) => void,
  setSortBy: (value: $ReadOnlyArray<SortBy>) => void,
  sortBy: SortBy,
  studentIds: $ReadOnlyArray<string>,
  students: $ReadOnlyArray<MarksStudent>,
};

function useFilteredStudents(
  students: $ReadOnlyArray<MarksStudent>,
  excludedOptionKeys: $ReadOnlyArray<FilterBy> = []
): FilteredStudents {
  const [sortBy, setSortBy] = useState<SortBy>(defaults.sortBy);
  const [filterBy, setFilterBy] = useState<FilterBy>(() => getFilterURLParam());

  const filtered = useMemo(() => {
    const filterExists = Object.values(cohorts).includes(filterBy);
    if (filterBy === 'all' || !filterExists) {
      return students;
    }

    return students.filter((student) => isPartOfCohort(student, filterBy));
  }, [filterBy, students]);

  const filteredAndSorted = useMemo(() => {
    const direction = sortBy.desc ? 'desc' : 'asc';
    // A secondary desc sort on `hasPartialAttempt` ensures that
    // complete attempts are prioritised over partial attempts, and
    // similarly, partial attempts are prioritised over empty attempts.
    return flow(
      orderBy('hasPartialAttempt', 'desc'),
      orderBy(sortBy.id, direction),
      partition((field) => field[sortBy.id] != null),
      flatten
    )(filtered);
  }, [filtered, sortBy.desc, sortBy.id]);

  const setSortCallback = useCallback((sortByArray: $ReadOnlyArray<SortBy>) => {
    const sortByObj = sortByArray[0] ?? defaults.sortBy;
    setSortBy({ id: sortByObj.id, desc: sortByObj.desc });
  }, []);

  const studentIds: $ReadOnlyArray<string> = filteredAndSorted.map((student) => student.id.toString());

  const filteredOptions = options.filter((option) => !excludedOptionKeys.includes(option.key));

  return {
    students: filteredAndSorted,
    studentIds,
    filterBy,
    setFilterBy,
    sortBy,
    setSortBy: setSortCallback,
    options: filteredOptions,
  };
}

export default useFilteredStudents;
