// @flow
import { Component } from 'react';

type Props = {
  height: number,
  onClose?: Function,
  url: string,
  width: number,
};

const watchInterval = 200;

export default class Popup extends Component<Props> {
  popup = null;

  popupWatcher = null;

  static defaultProps = {
    height: 600,
    width: 600,
  };

  componentDidMount() {
    if (!window) return;

    const { height, width } = this.props;
    const top = (window.innerHeight - height) / 2 + window.screenY;
    const left = (window.innerWidth - width) / 2 + window.screenX;

    this.popup = window.open(
      this.props.url,
      '',
      `status=no,location=no,toolbar=no,menubar=no,width=${width},height=${height},left=${left},top=${top}`
    );

    this.popupWatcher = setInterval(() => {
      if (!this.popup || !this.popup.closed) return;

      if (this.popupWatcher) {
        clearInterval(this.popupWatcher);
      }

      const { onClose } = this.props;

      if (onClose) {
        onClose();
      }
    }, watchInterval);
  }

  render() {
    return null;
  }
}
