// @flow
import { useState, useCallback } from 'react';

export default function useRenderKey(prefix?: string = '') {
  const [counter, setCounter] = useState(0);

  const key = prefix ? `${prefix}-${counter}` : `${counter}`;
  const update = useCallback(() => setCounter((value) => value + 1), []);

  return [key, update];
}
