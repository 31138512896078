// @flow
// $FlowIgnoreLibDef (only ReactSelect is defined in the the flow-typed def)
import ReactSelect, { Async, AsyncCreatable, Creatable } from 'react-select';
import 'react-select/dist/react-select.css';
import classnames from 'classnames/bind';
import { get, isString, noop } from 'lodash';
import { HelpText, Text } from '@getatomi/neon';

import { isMobileOrTablet } from 'src/utils/userAgent';

import NativeSelect from './NativeSelect/NativeSelect';
import styles from './Select.module.scss';

const cx = classnames.bind(styles);

export type OptionOf<Other: Object> = {
  ...Other,
  disabled?: boolean,
  label: string,
  title?: string,
  value: string,
};
export type Option = OptionOf<{}>;

type SimpleValue = string | number;
type ComplexValue = SimpleValue | OptionOf<any>; // good luck fixing `any`
type Props = {
  canCreateOption?: boolean,
  classNames: {
    native?: string,
    root?: string,
    select?: string,
  },
  disabled?: boolean,
  hasError: boolean,
  info?: React.Node,
  initialValue?: ?SimpleValue,
  isMandatory?: boolean,
  isNativeOnMobile: boolean,
  label?: React.Node,
  labelKey?: string,
  loadOptions?: Function,
  name: string,
  onChange?: Function,
  options?: Array<any>, // good luck fixing `any`
  placeholder?: string,
  simpleValue?: boolean,
  testHook?: string,
  value?: ?ComplexValue,
};
type State = {
  hasError: boolean,
  selectedValue: ComplexValue,
  showNativeSelect: boolean,
};

export default class Select extends React.Component<Props, State> {
  static defaultProps = {
    classNames: {},
    disabled: false,
    hasError: false,
    isNativeOnMobile: true,
    onChange: noop,
    placeholder: 'Please select...',
  };

  state: State = {
    hasError: false,
    selectedValue: this.props.initialValue || this.props.value || '',
    showNativeSelect: this.props.isNativeOnMobile,
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({
      hasError: false,
      selectedValue: nextProps.initialValue || nextProps.value || '',
    });
  }

  onChange = (selectedOption: Option) => {
    const { onChange } = this.props;
    let { hasError } = this.state;

    if (hasError && selectedOption) {
      hasError = false;
    }

    const selectedValue =
      this.props.simpleValue || this.props.loadOptions ? selectedOption : get(selectedOption, 'value', '');

    this.setState({ selectedValue, hasError });

    if (onChange) {
      onChange(this.props.name, selectedValue);
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  validate = () => {
    const { selectedValue } = this.state;
    let isValid = true;
    let value = selectedValue;

    if (isString(selectedValue)) value = selectedValue.trim();
    if (Array.isArray(selectedValue)) value = selectedValue.join();

    if (this.props.isMandatory) {
      isValid = !!value;
    }

    this.setState({ hasError: !isValid });
    return isValid;
  };

  componentDidMount() {
    if (!isMobileOrTablet) {
      this.setState({ showNativeSelect: false });
    }
  }

  render() {
    const {
      canCreateOption,
      classNames,
      disabled,
      info,
      label,
      labelKey,
      loadOptions,
      name,
      placeholder,
      options,
      testHook,
      // unused props for ReactSelect
      hasError: unusedHasError,
      initialValue,
      isMandatory,
      isNativeOnMobile,
      ...otherProps
    } = this.props;
    const { selectedValue, showNativeSelect } = this.state;
    const hasError = this.state.hasError || this.props.hasError;
    // use appropriate react-select component
    let CustomSelect = ReactSelect;

    if (loadOptions) {
      if (canCreateOption) {
        CustomSelect = AsyncCreatable;
      } else {
        CustomSelect = Async;
      }
    } else if (canCreateOption) {
      CustomSelect = Creatable;
    }

    return (
      <div className={cx('root', classNames.root, { error: hasError })} data-test={testHook}>
        {label && (
          <Text
            as="label"
            color="colorTextSubtle"
            data-test={testHook ? `${testHook}-label` : undefined}
            fontFamily="fontFamilySystem"
            fontSize="fontSizeSmall1X"
            fontWeight="fontWeightMedium"
            display="inline-block"
            htmlFor={name}
            marginBottom="spacingSmall1X"
          >
            {label}
          </Text>
        )}
        {showNativeSelect && options ? (
          <NativeSelect
            disabled={disabled}
            hasError={hasError}
            name={name}
            options={options}
            value={selectedValue}
            simpleValue={this.props.simpleValue}
            onChange={this.onChange}
            className={classNames.native}
          />
        ) : (
          // $FlowIgnore `loadOptions` is only valid for async flavours of ReactSelect
          <CustomSelect
            {...otherProps}
            openOnFocus
            onChange={this.onChange}
            name={name}
            placeholder={placeholder}
            options={options}
            disabled={disabled}
            loadOptions={loadOptions}
            value={selectedValue}
            className={cx('select', classNames.select)}
            labelKey={labelKey}
          />
        )}
        {info && <HelpText>{info}</HelpText>}
        {hasError && <HelpText variant="error">This field is required</HelpText>}
      </div>
    );
  }
}
