// @flow
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Stack, useToast } from '@getatomi/neon';

import errorTypes from 'src/constants/errorTypes';

type OutboundFields = {|
  details: string,
  reason: string,
|};

const labels = {
  reason: 'Why are you cancelling your subscription?',
  details: 'Overall, what was your experience like on Atomi?',
};

const resolver = yupResolver(
  yup.object().shape({
    reason: yup.string().required().label('A reason'),
    details: yup.string().required().label('Your feedback'),
  })
);

export default function useTurnOffAutoRenewalForm({
  onSubmitError,
  onSubmitSuccess,
}: {
  onSubmitError: () => void,
  onSubmitSuccess: (data: OutboundFields) => void | Promise<void>,
}) {
  const { control, formState, handleSubmit } = useForm({
    resolver,
    defaultValues: {
      details: '',
    },
  });
  const toast = useToast();

  const onSubmit = async (event: Event) => {
    event.preventDefault();
    handleSubmit(async (data) => {
      try {
        await onSubmitSuccess(data);
        toast.success('Automatic-renewal has been turned off successfully.');
      } catch (error) {
        onSubmitError();
        if (error?.output?.errorType === errorTypes.SubscriptionInvalidStatusException) {
          toast.error(
            <Stack spacing="spacingSmall2X">
              <p>
                It looks like automatic-renewal has already been turned off on your account. Refresh this page to make
                sure all the info displayed is up to date.
              </p>
              <Button variant="text" onClick={() => window.location.reload(true)}>
                Refresh the page
              </Button>
            </Stack>
          );
        } else {
          toast.error('There was an error turning off automatic-renewal. Please try again.');
        }
      }
    })(event);
  };

  const detailsMessage = formState.errors.details?.message ?? null;
  const reasonMessage = formState.errors.reason?.message ?? null;

  return {
    control,
    onSubmit,
    fields: {
      reason: {
        label: labels.reason,
        validationText: reasonMessage,
        errorVariant: reasonMessage && 'error',
      },
      details: {
        label: labels.details,
        validationText: detailsMessage,
        errorVariant: detailsMessage && 'error',
        placeholder: 'Please leave us some feedback',
        isRequired: true,
        maxLength: 500,
      },
    },
  };
}
