// @flow
import { StatusLight } from '@getatomi/neon';

import taskProgressStatuses, { type TaskProgressStatus as Status } from 'src/constants/taskProgressStatuses';

export const taskProgressStatusMap: { [string]: { colorCode: ?string, label: string, rank: ?number } } = {
  [taskProgressStatuses.toDo]: {
    label: 'To do',
    colorCode: 'colorIconSubtle',
    rank: 1,
  },
  [taskProgressStatuses.inProgress]: {
    label: 'In progress',
    colorCode: 'colorIconBrand',
    rank: 2,
  },
  [taskProgressStatuses.overdue]: {
    label: 'Overdue',
    colorCode: 'colorIconDanger',
    rank: 2,
  },
  [taskProgressStatuses.done]: {
    label: 'Done',
    colorCode: 'colorIconSuccess',
    rank: 3,
  },
  [taskProgressStatuses.notAssigned]: {
    label: 'Not assigned',
    colorCode: null,
    rank: null,
  },
};

type Props = {|
  status: Status,
|};

function TaskProgressStatus({ status }: Props) {
  const { label, colorCode } = taskProgressStatusMap[status];

  if (!colorCode) {
    return label;
  }

  return (
    <StatusLight color={colorCode} testHook="task-progress-status-cell-icon">
      {label}
    </StatusLight>
  );
}

export default TaskProgressStatus;
