// @flow
import { tokens } from '@getatomi/neon';

export default Object.freeze({
  creative_arts: {
    background: tokens.colorBackgroundAccentPurple,
    backgroundBold: tokens.colorBackgroundAccentPurpleBold,
    backgroundSubtle: tokens.colorBackgroundAccentPurpleSubtle,
    icon: tokens.colorIconAccentPurple,
    text: tokens.colorTextAccentPurple,
  },
  biology: {
    background: tokens.colorBackgroundAccentMint,
    backgroundBold: tokens.colorBackgroundAccentMintBold,
    backgroundSubtle: tokens.colorBackgroundAccentMintSubtle,
    chart: tokens.colorChartAccentMint,
    icon: tokens.colorIconAccentMint,
    text: tokens.colorTextAccentMint,
  },
  business: {
    background: tokens.colorBackgroundAccentCyan,
    backgroundBold: tokens.colorBackgroundAccentCyanBold,
    backgroundSubtle: tokens.colorBackgroundAccentCyanSubtle,
    chart: tokens.colorChartAccentCyan,
    icon: tokens.colorIconAccentCyan,
    text: tokens.colorTextAccentCyan,
  },
  english: {
    background: tokens.colorBackgroundAccentOrchid,
    backgroundBold: tokens.colorBackgroundAccentOrchidBold,
    backgroundSubtle: tokens.colorBackgroundAccentOrchidSubtle,
    chart: tokens.colorChartAccentOrchid,
    icon: tokens.colorIconAccentOrchid,
    text: tokens.colorTextAccentOrchid,
  },
  generic: {
    background: tokens.colorBackgroundBrand,
    backgroundBold: tokens.colorBackgroundBrandBold,
    backgroundSubtle: tokens.colorBackgroundBrandSubtle,
    chart: tokens.colorChartBrand,
    icon: tokens.colorIconBrand,
    text: tokens.colorTextBrand,
  },
  hsie: {
    background: tokens.colorBackgroundAccentTangerine,
    backgroundBold: tokens.colorBackgroundAccentTangerineBold,
    backgroundSubtle: tokens.colorBackgroundAccentTangerineSubtle,
    chart: tokens.colorChartAccentTangerine,
    icon: tokens.colorIconAccentTangerine,
    text: tokens.colorTextAccentTangerine,
  },
  life_skills: {
    background: tokens.colorBackgroundAccentPink,
    backgroundBold: tokens.colorBackgroundAccentPinkBold,
    backgroundSubtle: tokens.colorBackgroundAccentPinkSubtle,
    chart: tokens.colorChartAccentPink,
    icon: tokens.colorIconAccentPink,
    text: tokens.colorTextAccentPink,
  },
  maths: {
    background: tokens.colorBackgroundAccentStrawberry,
    backgroundBold: tokens.colorBackgroundAccentStrawberryBold,
    backgroundSubtle: tokens.colorBackgroundAccentStrawberrySubtle,
    chart: tokens.colorChartAccentStrawberry,
    icon: tokens.colorIconAccentStrawberry,
    text: tokens.colorTextAccentStrawberry,
  },
  pdhpe: {
    background: tokens.colorBackgroundAccentMagenta,
    backgroundBold: tokens.colorBackgroundAccentMagentaBold,
    backgroundSubtle: tokens.colorBackgroundAccentMagentaSubtle,
    chart: tokens.colorChartAccentMagenta,
    icon: tokens.colorIconAccentMagenta,
    text: tokens.colorTextAccentMagenta,
  },
  science: {
    background: tokens.colorBackgroundAccentMint,
    backgroundBold: tokens.colorBackgroundAccentMintBold,
    backgroundSubtle: tokens.colorBackgroundAccentMintSubtle,
    chart: tokens.colorChartAccentMint,
    icon: tokens.colorIconAccentMint,
    text: tokens.colorTextAccentMint,
  },
  vet: {
    background: tokens.colorBackgroundAccentTeal,
    backgroundBold: tokens.colorBackgroundAccentTealBold,
    backgroundSubtle: tokens.colorBackgroundAccentTealSubtle,
    chart: tokens.colorChartAccentTeal,
    icon: tokens.colorIconAccentTeal,
    text: tokens.colorTextAccentTeal,
  },
  technologies: {
    background: tokens.colorBackgroundAccentSky,
    backgroundBold: tokens.colorBackgroundAccentSkyBold,
    backgroundSubtle: tokens.colorBackgroundAccentSkySubtle,
    chart: tokens.colorChartAccentSky,
    icon: tokens.colorIconAccentSky,
    text: tokens.colorTextAccentSky,
  },
});
