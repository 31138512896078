// @flow
import { Flex } from '@getatomi/neon';

import type { ClassAverageRow as MarksClassAverage, Module as MarksColumn, MarksData } from '../Marks/marksTransformer';
import type {
  ClassAverageRow as CompletionClassAverage,
  Module as CompletionColumn,
  CompletionData,
} from '../Completion/completionTransformer';
import type { ClassAverageRow as TasksClassAverage, Column as TaskColumn, TasksData } from '../Tasks/tasksTransformer';
import MarkbookSubNav, { type MarkbookLinks } from './MarkbookSubNav';
import MarkbookDownloadButton from './MarkbookDownloadButton';

export type TableData = $ReadOnlyArray<
  MarksClassAverage | CompletionClassAverage | TasksClassAverage | CompletionData | MarksData | TasksData
>;
export type ColumnList = $ReadOnlyArray<CompletionColumn | TaskColumn | MarksColumn>;
type Props = {
  activeLink: MarkbookLinks,
  className?: string,
  columnList?: ColumnList,
  // TODO - make required
  isDataExportEnabled?: boolean,
  isErrorState?: boolean,
  isLoading?: boolean,
  params: {
    classId: string,
    subscriptionId: string,
  },
  tableData?: TableData,
};

function MarkbookControls(props: Props) {
  const {
    isLoading,
    params,
    activeLink,
    isErrorState,
    tableData,
    columnList,
    className = '',
    // TODO - remove default value
    isDataExportEnabled = true,
  } = props;
  return (
    <Flex
      alignItems="center"
      gap="spacingSmall"
      justifyContent="space-between"
      marginBlock={{ base: 'spacingRoot', desktop: 'spacingLarge1X' }}
      marginInline={{ base: 'spacingSmall1X', tablet: 'spacingRoot', desktop: 'spacingLarge3X' }}
    >
      <MarkbookSubNav params={params} />
      {isDataExportEnabled && (
        <MarkbookDownloadButton
          activeLink={activeLink}
          className={className}
          columnList={columnList}
          tableData={tableData}
          isLoading={isLoading}
          isErrorState={isErrorState}
        />
      )}
    </Flex>
  );
}

export default MarkbookControls;
