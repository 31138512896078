// @flow
import { useRef, useState } from 'react';
import pluralize from 'pluralize';
import _ from 'lodash';
import { Box, Container, Divider, Heading, IconAlertError, Stack, Text, useToast } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';
import ActionResult from 'src/components/ActionResult/ActionResult';
import Select from 'src/components/deprecated/Select/Select';
import Logger from 'src/utils/Logger';
import { trackingCtas } from 'src/constants/tracking';
import links from 'src/constants/links';

import MultipleEmailsInput from './MultipleEmailsInput/MultipleEmailsInput';
import styles from './BulkInviteUsersDialog.module.scss';

const log = new Logger('domains/Settings/Users/BulkInviteUsersDialog');
const MAX_NUM_EMAILS = 200;

export type BulkInviteUsersDialogProps = {
  accountName: string,
  bulkInviteUsers: Function,
  headingId: string,
  isInvitingUsers?: boolean,
  isSetupModeActive: boolean,
  onClose: Function,
  onGoBack: Function,
  onSuccess: Function,
  roles: Array<Object>,
};

export default function BulkInviteUsersDialog(props: BulkInviteUsersDialogProps) {
  const {
    accountName,
    bulkInviteUsers,
    isInvitingUsers,
    isSetupModeActive,
    headingId,
    onClose,
    onGoBack,
    onSuccess,
    roles,
  } = props;

  const toast = useToast();
  const inputs = useRef({});
  const [role, setRole] = useState<?string>(null);
  const [emails, setEmails] = useState<Array<{ value: string }>>([]);
  const [areEmailsValid, setAreEmailsValid] = useState<boolean>(true);
  const [countValidEmails, setCountValidEmails] = useState<?number>(null);
  const [serverErrors, setServerErrors] = useState<Array<Object>>([]);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

  const onEmailsBlur = (newEmails: Array<{ value: string }>, numValidEmails: number) => {
    setEmails(newEmails);
    setCountValidEmails(numValidEmails);
    setAreEmailsValid(_.size(newEmails) === numValidEmails);
  };

  const onSubmit = async (e: SyntheticEvent<>) => {
    e.preventDefault();

    const invalidInput = _.find(
      _.map(inputs.current, (value, key: string) => {
        return { key, isValid: value.validate() };
      }),
      { isValid: false }
    );

    if (invalidInput && inputs.current) {
      const invalidElement = inputs.current[invalidInput.key];
      invalidElement.inputField.scrollIntoView(true);
    }

    if (!areEmailsValid) return;

    try {
      const validationErrors = await bulkInviteUsers(
        role,
        emails.map(({ value }) => value)
      );

      if (_.size(validationErrors) > 0) {
        setIsSuccessful(false);
        setServerErrors(validationErrors);
      } else {
        setIsSuccessful(true);
        setServerErrors([]);
      }
    } catch (error) {
      log.error(`inviting users to subscription failed`, error);
      toast.error(
        error.statusCode === 504
          ? 'This request took too long to process. We recommend you give it another go.'
          : 'There was an error inviting users to your subscription. Please try again.'
      );
    }
  };

  const reset = () => {
    setRole(null);
    setEmails([]);
    setAreEmailsValid(true);
    setCountValidEmails(null);
    setServerErrors([]);
    setIsSuccessful(false);
  };

  const onCloseHandler = () => {
    reset();
    onClose();
  };

  const onCloseAfterSuccess = () => {
    onCloseHandler();
    onSuccess();
  };

  const getButtonLabel = () => {
    let buttonLabel = 'Invite users';
    if (countValidEmails && countValidEmails > 0) {
      buttonLabel = `Invite ${countValidEmails} ${pluralize('user', countValidEmails)}`;
    }

    return buttonLabel;
  };

  const numEmailsEntered = emails.length;
  const numErrors = _.size(serverErrors);

  if (isSuccessful) {
    return (
      <Stack>
        <ActionResult
          heading={
            isSetupModeActive
              ? 'Success!'
              : `Your ${pluralize('invitation', numEmailsEntered)} ${pluralize('has', numEmailsEntered)} been sent!`
          }
          description={
            isSetupModeActive ? (
              <>
                You’ve added {numEmailsEntered} {pluralize('user', numEmailsEntered)} to {accountName}, however because
                your account is in setup mode invitations have not yet been sent yet.{' '}
                <Link href={links.support.setupMode} isExternal>
                  Learn more
                </Link>
                .
              </>
            ) : (
              <>
                You’ve invited{' '}
                <strong>
                  {numEmailsEntered} {pluralize('user', numEmailsEntered)}
                </strong>{' '}
                to {accountName}.
              </>
            )
          }
          variant="success"
        />
        <Container maxWidth="sizeContainerSmall" textAlign="center">
          <Box marginBottom="spacingLarge3X">
            <Divider />
          </Box>
          <Button variant="tertiary" onClick={reset} className={styles.buttonTryAgain}>
            Invite more people
          </Button>
          <Button variant="tertiary" onClick={onCloseAfterSuccess}>
            Done
          </Button>
        </Container>
      </Stack>
    );
  }

  if (numErrors > 0) {
    return (
      <Stack spacing="spacingLarge3X">
        <Container maxWidth="sizeContainerRoot" textAlign="center">
          <Stack spacing="spacingLarge1X">
            <Heading as="h2">
              {numErrors === numEmailsEntered ? `That didn’t work!` : 'That was only partially successful'}
            </Heading>
            <Text as="p" variant="lead">
              You’ve {isSetupModeActive ? 'added' : 'invited'}{' '}
              <strong>
                {numEmailsEntered} {pluralize('users', numEmailsEntered)}
              </strong>{' '}
              to {accountName}, but{' '}
              <strong>
                {numErrors} {pluralize('invitations', numErrors)}
              </strong>{' '}
              didn’t send. <br />
              Please review the errors below.
            </Text>
          </Stack>
        </Container>
        <Container maxWidth="sizeContainerSmall">
          <Box marginBottom="spacingLarge3X">
            <Divider />
          </Box>
          <ul className={styles.errorsList}>
            {
              // $FlowIgnore
              _.map(serverErrors, (error, key) => (
                <li key={key}>
                  <Text
                    as="span"
                    color="colorTextSubtle"
                    fontFamily="fontFamilySystem"
                    fontSize="fontSizeSmall1X"
                    fontWeight="fontWeightMedium"
                    display="block"
                    marginBottom="spacingSmall1X"
                  >
                    Email address
                  </Text>
                  <span className={styles.errorEmail}>
                    {key} <IconAlertError className={styles.errorIcon} />
                  </span>
                  <span className={styles.errorMessage}>{error[0].message[0]}</span>
                </li>
              ))
            }
          </ul>
        </Container>
        <Box textAlign="center">
          <Button variant="tertiary" onClick={numErrors === numEmailsEntered ? onCloseHandler : onCloseAfterSuccess}>
            Done
          </Button>
        </Box>
      </Stack>
    );
  }

  return (
    <Box as="form" paddingBottom="spacingLarge4X" testHook="bulk-add-users-form">
      <Container maxWidth="sizeContainerRoot" textAlign="center" marginBottom="spacingLarge4X">
        <Stack spacing="spacingLarge2X">
          <Heading as="h1" id={headingId}>
            Bulk import users
          </Heading>
          <Text as="p" variant="lead">
            Import users using a list of email addresses and they’ll be invited to join your school account all in one
            go. Happy days!
          </Text>
        </Stack>
      </Container>
      <Container maxWidth="sizeContainerSmall">
        <Stack spacing="spacingLarge4X">
          <Divider />
          <Select
            ref={(el) => {
              if (inputs.current) inputs.current.roleSelect = el;
            }}
            label="I am bulk importing"
            name="role"
            options={roles.map((r) => ({
              ...r,
              label: `${r.label}s`,
            }))}
            isMandatory
            initialValue={role}
            onChange={(name, value) => setRole(value)}
            testHook="bulk-add-users-role-select"
          />
          <MultipleEmailsInput
            ref={(el) => {
              if (inputs.current) inputs.current.emails = el;
            }}
            max={MAX_NUM_EMAILS}
            label="Enter multiple email addresses"
            info={[
              'Not sure what to do? See ',
              <Link key="link" href={links.support.bulkImportUsers} isExternal>
                Bulk importing users to your school
              </Link>,
              ' in our Help Centre.',
            ]}
            onBlur={onEmailsBlur}
            onChange={(count: number) => setCountValidEmails(count)}
          />
          <Box textAlign="center">
            <Button
              type="submit"
              isLoading={isInvitingUsers}
              marginBottom="spacingLarge3X"
              onClick={onSubmit}
              trackingData={{
                cta: trackingCtas.subscriptionInviteUsers,
                userType: role,
              }}
            >
              {getButtonLabel()}
            </Button>
            <Text variant="bodySmall1X" color="colorTextSubtler">
              or{' '}
              <Button variant="text" size="small" onClick={onGoBack}>
                Go back.
              </Button>
            </Text>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
