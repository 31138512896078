// @flow
import { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import pluralize from 'pluralize';
import { ActionBar, Box, Table, Text, useTableSelection, useToast } from '@getatomi/neon';

import type { RestoreClasses, RestoreClassesVariables } from 'src/graphql/types/generated/RestoreClasses';
import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';
import { getClassUrl } from 'src/utils/routes';

import RESTORE_CLASSES from './RestoreClasses.graphql';

type DeletedClassesTableProps = {
  classes: $ReadOnlyArray<{
    +deletedAt: ?string,
    +deletedBy: ?string,
    +id: string,
    +name: string,
    +subject: {
      +code: string,
      +name: string,
    },
  }>,
};

export default function DeletedClassesTable(props: DeletedClassesTableProps) {
  const { classes } = props;
  const toast = useToast();

  const [restoreClasses, { loading: isRestoring }] = useMutation<RestoreClasses, RestoreClassesVariables>(
    RESTORE_CLASSES,
    {
      refetchQueries: ['GetDeletedClasses'],
      awaitRefetchQueries: true,
    }
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'class',
        header: () => 'Class name',
        meta: {
          isHeading: true,
        },
      },
      {
        accessorKey: 'subject',
        header: () => 'Subject name',
      },
      {
        accessorKey: 'subjectCode',
        header: () => 'Subject code',
      },
      {
        accessorKey: 'deletedAt',
        header: () => 'Deleted at',
      },
      {
        accessorKey: 'deletedBy',
        header: () => 'Deleted by',
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      classes.map((row) => ({
        ...row,
        class: row.name,
        subject: row.subject.name,
        subjectCode: row.subject.code,
        // $FlowIgnore deletedAt will be defined
        deletedAt: new Date(row.deletedAt).toLocaleString(),
        deletedBy: row.deletedBy,
      })),
    [classes]
  );

  const { resetSelection, selectedIds, tableProps } = useTableSelection({ data });

  const onRestore = async () => {
    try {
      // $FlowIgnore I'm handling the case of empty data coming back below
      const response = await restoreClasses({
        variables: {
          input: {
            classIds: selectedIds,
          },
        },
      });

      const restoredClasses = response.data?.restoreClasses ?? [];
      const classCount = restoredClasses.length;
      toast.success(
        <>
          {classCount} {pluralize('class', classCount)} successfully restored. View classes:
          <ul>
            {restoredClasses.map((restoredClass) => (
              <li key={restoredClass.id}>
                <Link to={getClassUrl(restoredClass.account.id, restoredClass.id)}>{restoredClass.name}</Link>
              </li>
            ))}
          </ul>
        </>,
        { duration: Infinity }
      );
    } catch (restoreError) {
      throw new Error(`There was an error restoring classes ${selectedIds.toString()}: ${restoreError}`);
    }
  };

  return (
    <>
      <Box as="section">
        {classes.length === 0 ? (
          <Text variant="bodySmall">No deleted classes found for this account.</Text>
        ) : (
          <Table {...tableProps} columns={columns} data={data} />
        )}
      </Box>
      <ActionBar selectedItemCount={selectedIds.length} onClearSelection={resetSelection}>
        <Button onClick={() => onRestore()} isLoading={isRestoring}>
          Restore
        </Button>
      </ActionBar>
    </>
  );
}
