// @flow
import cx from 'classnames';
import { Box, Text } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import type { PaymentSource as PaymentSourceType } from 'src/types';

import PaymentSourceDetails from './PaymentSourceDetails/PaymentSourceDetails';
import styles from './PaymentSource.module.scss';

export type PaymentSourceProps = {|
  className?: string,
  data: ?PaymentSourceType,
  isLarge?: boolean,
  onDelete?: () => mixed,
  onUpdate?: () => mixed,
|};

export default function PaymentSource(props: PaymentSourceProps) {
  const { isLarge, data, onUpdate, onDelete } = props;

  if (!data) return null;

  return (
    <div
      className={cx(styles.root, { [styles.isLarge]: isLarge, [styles.hasErrors]: data.hasErrors }, props.className)}
    >
      <PaymentSourceDetails data={data} />

      {(onUpdate || onDelete) && (
        <Box marginTop="spacingLarge5X">
          {onUpdate && (
            <Button variant="text" size="small" onClick={onUpdate} testHook="button-update-card">
              Update
            </Button>
          )}
          {onUpdate && onDelete && (
            <Text as="span" variant="bodySmall1X" color="colorTextSubtler">
              {' '}
              or{' '}
            </Text>
          )}
          {onDelete && (
            <Button variant="text" size="small" onClick={onDelete} testHook="button-delete-card">{`${
              onUpdate ? 'delete' : 'Delete'
            } card`}</Button>
          )}
        </Box>
      )}
    </div>
  );
}
