// @flow
import { useMemo, useEffect } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import type { ContextRouter, LocationShape } from 'react-router';
import { Alert, Box, Container, EmptyState, Illustration, Stack, Text, TextPost, Heading } from '@getatomi/neon';
import invariant from 'invariant';

import type { ReduxState } from 'src/types';
import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';
import PostBackLink from 'src/components/PostBackLink/PostBackLink';
import PostDuration from 'src/components/PostDuration/PostDuration';
import postTypes from 'src/constants/postTypes';
import links from 'src/constants/links';
import challengeTiers from 'src/constants/challengeTiers';
import { getUserId } from 'src/reducers/auth';
import {
  isFreePlan as isFreePlanSelector,
  isRetailPlan as isRetailPlanSelector,
  isSchoolPlan as isSchoolPlanSelector,
  isLoggedInAsChild as isLoggedInAsChildSelector,
  isLoggedInAsStudent as isLoggedInAsStudentSelector,
} from 'src/reducers/subscriptions';
import Auth from 'src/utils/Auth';
import { subscribeToClassProgress, subscribeToOwnProgress } from 'src/utils/pusher';
import { getPostUrl as getPostUrlImported, getLtiClassModuleLessonUrl, isLtiUrl } from 'src/utils/routes';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import { useNavigationContext } from 'src/hooks/useNavigationContext';

import PostActions from './PostActions/PostActions';
import PostPagination from './PostPagination/PostPagination';
import PostVideoPlayer from './PostVideoPlayer/PostVideoPlayer';
import CurriculumDescriptorTrigger from './PostChallenge/CurriculumDescriptorTrigger';
import PostChallenge from './PostChallenge/PostChallenge';
import PostBreadcrumbs from './PostBreadcrumbs/PostBreadcrumbs';
import TextPostProgress from './TextPostProgress/TextPostProgress';
import EmbedButtonPortal from './EmbedButtonPortal/EmbedButtonPortal';
import LessonLoader from './LessonLoader';
import useGetLesson, { type Lesson, type CurrentClass } from './useGetLesson';

export type Ancestor = {
  id: string,
  name: string,
  parentId: ?string,
};

type Params = {
  classId: number,
  moduleId: number,
  postId: number,
  splat: string | void,
  subscriptionId: number,
};

type RouterProps = {
  location: LocationShape,
  params: Params,
  router: ContextRouter,
};

type InjectedProps = {
  isFreePlan: boolean,
  isLoggedInAsChild: boolean,
  isLoggedInAsStudent: boolean,
  isRetailPlan: boolean,
  isSchoolPlan: boolean,
  userId: number,
};

type PostQueryProps = InjectedProps & RouterProps;

export type PostProps = PostQueryProps & {
  currentClass: CurrentClass,
  isCrossSubject: boolean,
  lesson: Lesson,
  nextLesson: ?Lesson,
  previousLesson: ?Lesson,
  refetch: () => Promise<any>,
  region: string,
};

export const mapStateToProps = (state: ReduxState) => {
  const isLoggedInAsStudent = isLoggedInAsStudentSelector(state);
  const isLoggedInAsChild = isLoggedInAsChildSelector(state);
  const isRetailPlan = isRetailPlanSelector(state);

  return {
    isFreePlan: isFreePlanSelector(state),
    isLoggedInAsChild,
    isLoggedInAsStudent,
    isRetailPlan,
    isSchoolPlan: isSchoolPlanSelector(state),
    userId: getUserId(state),
  };
};

export function Post(props: PostProps) {
  const {
    lesson,
    isCrossSubject,
    refetch,
    region,
    nextLesson,
    previousLesson,
    currentClass,
    isFreePlan,
    isRetailPlan,
    isSchoolPlan,
    isLoggedInAsChild,
    isLoggedInAsStudent,
    location,
    userId,
    params,
    router,
  } = props;

  const { classId, moduleId, subscriptionId } = params;

  const isTextPost = lesson.type === postTypes.text;
  const isVideoPost = lesson.type === postTypes.video;
  const isChallenge = lesson.type === postTypes.challenge;

  const { isEmbed } = location.query;
  const isPostEmbedded = Boolean(isEmbed);
  const isLti = isLtiUrl(location.pathname);
  const showNavigation = !isPostEmbedded && !isLti && !isCrossSubject;
  const showPagination = showNavigation && (isChallenge || isTextPost);
  const showPostActions = !isPostEmbedded && !isLti && !isLoggedInAsStudent;

  const [navigationContext, setNavigationContext] = useNavigationContext();

  const getSiblingLessonUrl = (targetLesson: Lesson): string => {
    if (isLti) {
      return getLtiClassModuleLessonUrl(subscriptionId, classId, moduleId, targetLesson.id);
    }
    return getPostUrlImported(subscriptionId, classId, moduleId, targetLesson.id);
  };

  const crossSubjectAlert = (
    <Alert variant="info">
      This lesson lives outside your class. You can share it via tasks but results won’t appear in mark book or class
      insights.{' '}
      <Link
        isExternal
        variant="monochrome"
        href="http://support.getatomi.com/en/articles/9206833-sharing-content-across-subjects-and-levels"
      >
        Find out more
      </Link>
    </Alert>
  );

  // we're using memoization here so it doesn't cause a rerender where actions are displayed
  const postActionsMemoized = useMemo(() => {
    return (
      lesson &&
      currentClass && (
        <>
          {isCrossSubject &&
            !isLoggedInAsStudent &&
            (isChallenge ? (
              <Container maxWidth="sizeContainerSmall" marginBottom="spacingRoot">
                {crossSubjectAlert}
              </Container>
            ) : (
              crossSubjectAlert
            ))}

          <PostActions
            isCrossSubject={isCrossSubject}
            isFreePlan={isFreePlan}
            subscriptionId={String(subscriptionId)}
            currentClass={currentClass}
            moduleId={String(moduleId)}
            lesson={lesson}
            progressCount={lesson.completionCount}
            onSubmitTask={() => refetch()}
            onViewTasks={(url) => router.push(url)}
            region={region}
          />
        </>
      )
    );
  }, [lesson?.id, lesson?.completionCount, lesson?.hasTasks]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // If we have no navigation context set, set it to the parent module (for use by the ContextualMobilePostBackLink)
    if (lesson && !navigationContext && params.moduleId) {
      setNavigationContext({
        location: 'module',
        metadata: {
          accountId: String(subscriptionId),
          classId: String(classId),
          moduleId: String(params.moduleId),
          title: lesson.categories[0].name,
        },
      });
    }

    // Subscribe to own progress updates so that the latest progress is retrieved upon completion.
    // This is required as the progress data is sent as part of the challenge tracking events.
    if (isLoggedInAsStudent && lesson?.type === postTypes.challenge) {
      return subscribeToOwnProgress(userId, () => refetch());
    }
    // Subscribe to class progress updates so the completion count in PostActions is kept up to date
    if (!isLoggedInAsStudent) {
      return subscribeToClassProgress(classId, () => refetch());
    }
  }, [lesson, refetch]); // eslint-disable-line react-hooks/exhaustive-deps

  const actions = showPostActions ? postActionsMemoized : undefined;

  const pagination = showNavigation && (
    <PostPagination
      getLessonUrl={getSiblingLessonUrl}
      isInline={isVideoPost}
      nextLesson={nextLesson}
      prevLesson={previousLesson}
      upgradePromptProps={{ isSchoolPlan, isLoggedInAsChild, subscriptionId }}
      testHook="post-pagination"
    />
  );
  const headingId = 'post-heading';

  // Our current route structure includes an optional "splat" parameter on the end:
  // classes/:classId/modules/:moduleId/posts/:postId(/**)
  // When viewing a challenge post we use a splat value of "results" to indicate
  // that we want to show the challenge results page rather than the challenge start page.
  const hasResultsSplat = params.splat === 'results';

  // Moodle doesn't respect the max-width of our embed iframe, so we need to limit the size of
  // the container to make things fit.
  const embedWidth = 700;
  const maximumContainerSize = isPostEmbedded ? embedWidth : 'sizeContainerRoot';

  const content = (
    <>
      {isTextPost && isLoggedInAsStudent && (
        // This needs to be a direct child of the enclosing container otherwise position:
        // sticky won't work. The built-in `key` attribute is used to tell React to create a
        // new instance of this component when rendering a different post (e.g. navigating to
        // the next/previous post)
        // https://reactjs.org/docs/reconciliation.html#keys
        //
        <TextPostProgress
          key={lesson.id}
          classId={classId}
          levelId={currentClass.level?.id}
          lesson={lesson}
          subject={currentClass.subject}
          subscriptionId={subscriptionId}
        />
      )}

      <Box marginBottom="spacingLarge5X">
        {isChallenge ? (
          <Container textAlign="center">
            <Box marginBottom="spacingLarge1X" marginTop="spacingLarge3X">
              <PostChallenge
                accountId={String(subscriptionId)}
                actions={actions ?? null}
                classId={String(classId)}
                isEmbedded={isPostEmbedded}
                isLoggedInAsChild={isLoggedInAsChild}
                isLti={isLti}
                isSchoolPlan={isSchoolPlan}
                lesson={lesson}
                labels={null}
                moduleId={String(moduleId)}
                nextLessonProps={
                  nextLesson ? { isLocked: nextLesson.isLocked, url: getSiblingLessonUrl(nextLesson) } : null
                }
                view={hasResultsSplat ? 'Results' : 'Start'}
              />
            </Box>
            <CurriculumDescriptorTrigger
              description={lesson.description}
              isInteractiveLesson={lesson.tier === challengeTiers.TIER_5_INTERACTIVE_LESSON}
              isLoggedInAsStudent={isLoggedInAsStudent}
            />
          </Container>
        ) : (
          <Stack spacing="spacingLarge">
            {isVideoPost && (
              <Container
                maxWidth={isPostEmbedded ? embedWidth : undefined}
                paddingInline={{ base: 'spacingNone', tablet: 'spacingRoot' }}
              >
                <PostVideoPlayer
                  classId={classId}
                  pagination={pagination}
                  lesson={lesson}
                  levelId={currentClass.level?.id}
                  subject={currentClass.subject}
                  isEmbed={isPostEmbedded || isLti}
                  subscriptionId={subscriptionId}
                />
              </Container>
            )}
            {/* Post header */}

            <Container maxWidth={maximumContainerSize}>
              <Heading as="h1" id={headingId} marginBottom="spacingSmall">
                <Box as="span" marginRight="spacingSmall">
                  {lesson.name}
                </Box>
                <Box
                  as="span"
                  display="inline-block"
                  fontSize="fontSizeLarge"
                  color="colorTextSubtler"
                  fontWeight="fontWeightLight"
                >
                  <PostDuration duration={lesson.duration} type={lesson.type} />
                </Box>
              </Heading>

              {(showNavigation || actions) && (
                <Stack spacing="spacingLarge1X">
                  {showNavigation && (
                    <PostBreadcrumbs
                      ancestors={lesson.categories}
                      currentClass={currentClass}
                      isRetailPlan={isRetailPlan}
                      subscriptionId={subscriptionId}
                    />
                  )}
                  {actions}
                </Stack>
              )}
            </Container>
          </Stack>
        )}
      </Box>

      {/* Text post content and Video post description */}
      {!isChallenge && (
        <Container maxWidth={maximumContainerSize} marginBottom="spacingLarge8X">
          <TextPost ariaLabelledBy={headingId} linkComponent={Link} testHook="post-long-form-copy">
            {lesson.description}
          </TextPost>
        </Container>
      )}
      {/* Text and challenge post pagination */}
      {showPagination && <Container>{pagination}</Container>}
    </>
  );

  const embedButton = isLti && <EmbedButtonPortal postId={lesson.id} postName={lesson.name} />;

  return (
    <>
      <Helmet>
        <title>{lesson.name}</title>
      </Helmet>

      {showNavigation && <BackLink isVideoPost={isVideoPost} />}
      {content}
      {embedButton}
    </>
  );
}

function BackLink({ isVideoPost }: { isVideoPost: boolean }) {
  return (
    <Box display={{ base: 'none', tablet: 'block' }} marginBottom={isVideoPost ? 'spacingRoot' : 'spacingLarge3X'}>
      <Container>
        {/* $FlowIgnore https://stackoverflow.com/questions/50219814/flow-error-props-is-incompatible-with-empty-when-instantiating-a-component-wit */}
        <PostBackLink testHook="post-back-link" renderText={(context) => `Back to ${context}`} />
      </Container>
    </Box>
  );
}

function LockedLesson() {
  const auth = new Auth();
  return (
    <>
      <Helmet>
        <title>Access forbidden!</title>
      </Helmet>
      <EmptyState
        media={<Illustration name="main-browser" />}
        heading="Access forbidden!"
        headingProps={{ as: 'h1' }}
        description="We’re sorry but you don’t have permission to access this page."
        primaryAction={
          <Text as="p" variant="bodyRoot">
            <Link href="/">Take me back to the home page</Link> or{' '}
            <Button variant="text" size="large" onClick={() => auth.logoutAndRedirect()}>
              log out
            </Button>
            .
          </Text>
        }
        helpInfo={
          <>
            Having trouble?{' '}
            <Link href={links.support.home} isExternal variant="monochrome">
              Find some useful information in our Help Centre
            </Link>
            .
          </>
        }
        testHook="error-page"
      />
    </>
  );
}

function PostQuery(props: PostQueryProps) {
  const { isLoggedInAsStudent, params } = props;

  const { classId, postId, subscriptionId } = params;

  const { currentClass, error, isCrossSubject, lesson, loading, nextLesson, previousLesson, refetch, region } =
    useGetLesson({
      classId: String(classId),
      isStudent: isLoggedInAsStudent,
      lessonId: String(postId),
      subscriptionId: String(subscriptionId),
    });

  if (error) {
    return (
      <>
        <Helmet>
          <title>Oops, sorry!</title>
        </Helmet>
        <GraphQLError
          error={error}
          description="We couldn’t load the lesson page."
          customMessages={{
            NOT_FOUND: {
              description: 'We can’t seem to find the page you’re looking for.',
              heading: 'Oops, sorry!',
            },
          }}
        />
      </>
    );
  }

  if (loading) {
    return <LessonLoader />;
  }

  invariant(lesson && currentClass && region, 'Failed to load lesson data');

  if (lesson.isLocked) {
    return <LockedLesson />;
  }

  return (
    <Post
      lesson={lesson}
      isCrossSubject={isCrossSubject}
      refetch={refetch}
      region={region}
      nextLesson={nextLesson}
      previousLesson={previousLesson}
      currentClass={currentClass}
      {...props}
    />
  );
}

export default (connect(mapStateToProps, {})(PostQuery): React.AbstractComponent<RouterProps>);
