// @flow
import Helmet from 'react-helmet';
import { Container, Heading, Skeleton, Box } from '@getatomi/neon';
import { connect } from 'react-redux';
import { useQuery, type QueryResult } from '@apollo/client';

import type { ReduxState } from 'src/types';
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import type { GetSamlConfig, GetSamlConfigVariables } from 'src/graphql/types/generated/GetSamlConfig';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import userFullName from 'src/utils/userFullName';

import GET_SAML_CONFIG from './GetSamlConfig.graphql';
import SamlSsoFormView from './SamlSsoFormView/SamlSsoFormView';
import SamlSsoConfiguredView from './SamlSsoConfiguredView/SamlSsoConfiguredView';

type GetSamlQueryResult = QueryResult<GetSamlConfig, GetSamlConfigVariables>;

export type InjectedProps = {
  accountId: number,
};

function mapStateToProps(state: ReduxState) {
  const accountId: number = getActiveSubscriptionId(state);
  return { accountId };
}

const heading = 'SAML SSO Configuration';

function SamlSsoContainer({ children }: { children: React.Node }) {
  return (
    <Container maxWidth="sizeContainerSmall">
      <Helmet>
        <title>{heading}</title>
      </Helmet>
      <Box marginBottom="spacingLarge5X" textAlign="center">
        <Heading as="h2" variant="large">
          {heading}
        </Heading>
      </Box>
      {children}
    </Container>
  );
}

export function SamlSso(props: InjectedProps) {
  const { accountId } = props;

  const { data, error, loading }: GetSamlQueryResult = useQuery<GetSamlConfig, GetSamlConfigVariables>(
    GET_SAML_CONFIG,
    {
      variables: {
        id: String(accountId),
      },
    }
  );

  if (loading) {
    return (
      <SamlSsoContainer>
        <Skeleton width="100%" height={100} animation="wave" testHook="saml-config-loader" />
      </SamlSsoContainer>
    );
  }

  if (error) {
    return (
      <SamlSsoContainer>
        <GraphQLError error={error} description="An error occurred getting the current SAML SSO configuration." />
      </SamlSsoContainer>
    );
  }

  if (data && data.me && data.me.account && data.me.account.samlConfig) {
    const {
      createdAt,
      createdBy,
      metadata: { url },
    } = data.me.account.samlConfig;
    const { firstName, lastName } = createdBy;
    const fullName = userFullName(firstName, lastName) || '';
    return (
      <SamlSsoContainer>
        <SamlSsoConfiguredView name={fullName} date={createdAt} url={url} />
      </SamlSsoContainer>
    );
  }

  return (
    <SamlSsoContainer>
      <SamlSsoFormView />
    </SamlSsoContainer>
  );
}

export default (connect(mapStateToProps)(SamlSso): React.AbstractComponent<{}>);
