// @flow
import { Box, Container, Modal, Stack } from '@getatomi/neon';

import Button from 'src/components/Button/Button';

export type ProgressDialogProps = {|
  ariaLabelledBy: string,
  children: React$Node,
  heading?: React$Node,
  isLoaded?: boolean,
  isOpen: boolean,
  onClose: () => mixed,
  testHook: string,
|};

export default function ProgressDialog(props: ProgressDialogProps) {
  const { ariaLabelledBy, heading, children, isLoaded, isOpen, onClose, testHook } = props;

  return (
    <Modal
      size="fullscreen"
      variant="view"
      isOpen={isOpen}
      onClose={onClose}
      testHook={testHook}
      ariaLabelledBy={ariaLabelledBy}
    >
      <Container textAlign="center" maxWidth="sizeContainerLarge">
        <Box paddingBottom="spacingLarge1X">
          <Stack spacing="spacingLarge1X">
            {heading}
            {children}
            {isLoaded && (
              <Button onClick={onClose} testHook={`${testHook}-close-button`}>
                Ok, thanks!
              </Button>
            )}
          </Stack>
        </Box>
      </Container>
    </Modal>
  );
}
