// @flow

import { UnstyledLink } from 'src/components/Link/Link';
import SubscriptionLink from 'src/components/SubscriptionLink/SubscriptionLink';
import links from 'src/constants/links';

import styles from './Nav.module.scss';

type Props = {
  canViewInsights?: boolean,
  isLoggedInAsParent: boolean,
  isLoggedInAsStudent: boolean,
  isMobile?: boolean,
  isRetailPlan: boolean,
  onLogout: () => mixed,
  onNavigate: () => mixed,
};

export default function Nav(props: Props) {
  const { isRetailPlan, isLoggedInAsParent, isLoggedInAsStudent, canViewInsights, isMobile = true, onNavigate } = props;

  if (!isRetailPlan && !onNavigate) return null;

  const linkProps = {
    className: styles.link,
    activeClassName: styles.isActive,
  };
  const onLogout = (e: SyntheticEvent<>) => {
    e.stopPropagation();
    e.preventDefault();
    props.onLogout();
  };

  return (
    <nav className={isMobile ? styles.isMobile : styles.isNonMobile} data-test="nav">
      <ul>
        {!isLoggedInAsParent && (
          <li className={styles.navListItem}>
            <SubscriptionLink isUnstyled onClick={onNavigate} {...linkProps} testHook="nav-classes">
              {isRetailPlan ? 'Subjects' : 'Classes'}
            </SubscriptionLink>
          </li>
        )}
        {isLoggedInAsStudent && !isRetailPlan && (
          <li className={styles.navListItem}>
            <SubscriptionLink isUnstyled tasks onClick={onNavigate} {...linkProps} testHook="nav-tasks">
              Tasks
            </SubscriptionLink>
          </li>
        )}
        {isLoggedInAsStudent && (
          <li className={styles.navListItem}>
            <SubscriptionLink isUnstyled revision onClick={onNavigate} {...linkProps} testHook="nav-revision">
              Revision
            </SubscriptionLink>
          </li>
        )}
        {canViewInsights && (
          <li className={styles.navListItem}>
            <SubscriptionLink isUnstyled insights onClick={onNavigate} {...linkProps} testHook="nav-insights">
              Insights
            </SubscriptionLink>
          </li>
        )}
        {isMobile && (
          <>
            <li>
              <UnstyledLink onClick={onNavigate} href={links.support.home} isExternal {...linkProps}>
                Get support
              </UnstyledLink>
            </li>
            <li>
              <UnstyledLink onClick={onLogout} {...linkProps}>
                Logout
              </UnstyledLink>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}
