// @flow
import { Controller } from 'react-hook-form';
import { Modal, Stack, TextField, Box, Button, useToast, useMediaQuery } from '@getatomi/neon';
import { connect } from 'react-redux';

import useEditUserDialogForm, { type Fields } from 'src/hooks/forms/useEditUserDialogForm';
import type { BoundAction, UserType, ReduxState } from 'src/types';
import { getAllLevelsForSelect } from 'src/reducers/levels';
import userRoles from 'src/constants/userRoles';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import { updateUser } from 'src/actions/users';
import { trackEvent } from 'src/utils/tracking';
import { trackingCtas, trackingCtaTypes, trackingEvents } from 'src/constants/tracking';

type Props = {
  isVisible: boolean,
  onClose: () => void,
  user: UserType,
};

type InjectedProps = Props & {
  levels: Array<{
    label: string,
    value: number | string,
  }>,
  updateUserAction: BoundAction<typeof updateUser>,
};

const mapStateToProps = (state: ReduxState) => {
  return {
    levels: getAllLevelsForSelect(state),
  };
};

function EditUserDialog(props: InjectedProps) {
  const { isVisible, onClose, levels, user, updateUserAction } = props;
  const toast = useToast();

  const isLevelIdFieldPresent = user.role === userRoles.student;

  const { control, serverError, fields, form, isLoading } = useEditUserDialogForm({
    defaultValues: {
      email: user.email ?? '',
      firstName: user.first_name ?? '',
      lastName: user.last_name ?? '',
      levelId: user.level?.id ?? '',
    },
    onSubmitSuccess: async (data: Fields) => {
      await updateUserAction(user.id, data);
      trackEvent(trackingEvents.ctaClicked, {
        cta: trackingCtas.userUpdate,
        type: trackingCtaTypes.button,
        data,
      });
      onClose();
      toast.success('The user has been updated successfully.');
    },
    context: {
      isLevelIdFieldPresent,
    },
  });

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  return (
    <Modal
      isOpen={isVisible}
      onClose={onClose}
      heading="Edit user"
      actions={
        <>
          <Button
            variant={isMobile ? 'tertiary' : 'text'}
            isFullWidth={isMobile}
            size={isMobile ? 'small' : undefined}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button size="small" isFullWidth={isMobile} isLoading={isLoading} onClick={form.onSubmit} type="submit">
            Save
          </Button>
        </>
      }
    >
      <Box as="form">
        <Stack spacing="spacingLarge1X">
          {serverError && <ValidationErrors errors={serverError.message} />}
          <Controller
            control={control}
            name="firstName"
            render={({ field }) => <TextField {...field} {...fields.firstName} autoComplete="off" />}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field }) => <TextField {...field} {...fields.lastName} autoComplete="off" />}
          />
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                {...field}
                {...fields.email}
                onChange={(value: string) => {
                  fields.email.onChange();
                  field.onChange(value);
                }}
                type="email"
                autoComplete="off"
              />
            )}
          />
          {isLevelIdFieldPresent && (
            <Controller
              control={control}
              name="levelId"
              render={({ field }) => (
                <Dropdown
                  {...field}
                  {...fields.levelId}
                  items={levels}
                  selectedKey={field.value ? String(field.value) : null}
                  onSelectionChange={field.onChange}
                >
                  {(item) => <Item key={item.value}>{item.label}</Item>}
                </Dropdown>
              )}
            />
          )}
        </Stack>
      </Box>
    </Modal>
  );
}

export default (connect(mapStateToProps, { updateUserAction: updateUser })(
  EditUserDialog
): React.AbstractComponent<Props>);
