// @flow

import { Box, Button, DatePicker, Modal, Stack, Text, useMediaQuery } from '@getatomi/neon';
import moment from 'moment';
import { useState } from 'react';

import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';

import TimePicker from './TimePicker';

type TaskScheduleDialogProps = {|
  dueDate: moment$Moment,
  isOpen: boolean,
  onCancel: () => void,
  onClose: () => void,
  onSubmit: () => void,
  scheduledFor: moment$Moment,
  setScheduledFor: (value: moment$Moment) => void,
|};

type DateTimeProps = {
  value: moment$Moment,
};

export function DateTime(props: DateTimeProps) {
  const { value } = props;
  const dateFormat = 'ddd D MMM';

  const calendarFormat = {
    sameDay: '[today]',
    nextDay: '[tomorrow]',
    nextWeek: dateFormat,
    lastWeek: dateFormat,
    sameElse: dateFormat,
  };

  const timeFormat = 'h a';

  const date = moment(value);

  const dateString = date.calendar(null, calendarFormat);
  const timeString = date.format(timeFormat);

  const tomorrow = moment().add(2, 'days').startOf('day');

  const isDateAfterTomorrow = date.isAfter(tomorrow);

  return (
    <>
      {isDateAfterTomorrow && 'on '}
      <Text as="strong">{dateString}</Text> at <Text as="strong">{timeString}</Text>
    </>
  );
}

export const validateScheduledDate = (scheduledDate: moment$Moment, dueDate: moment$Moment): ?string => {
  const isScheduledDateAfterDueDate = moment(scheduledDate).isAfter(dueDate);
  const isScheduledDatePassed = moment(scheduledDate).isBefore();

  if (isScheduledDateAfterDueDate) return `Tasks must be scheduled before they’re due.`;
  if (isScheduledDatePassed) return `The scheduled date and time must not be in the past.`;

  return null;
};

export default function TaskScheduleDialog(props: TaskScheduleDialogProps) {
  const { isOpen, onCancel, onSubmit, onClose, dueDate } = props;

  const [dateValidationError, setDateValidationError] = useState<?string>(null);
  const [scheduledFor, setScheduleFor] = useState<moment$Moment>(props.scheduledFor.clone());

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const validate = (scheduledDate) => {
    const validationMessage = validateScheduledDate(scheduledDate, dueDate);
    setDateValidationError(validationMessage);
    const isScheduledDateValid = !validationMessage;

    setScheduleFor(scheduledDate);
    if (isScheduledDateValid) props.setScheduledFor(scheduledDate);
  };

  const onDateChange = (date: moment$Moment) => {
    const newDate = scheduledFor.clone().set({
      date: date.get('date'),
      month: date.get('month'),
      year: date.get('year'),
    });
    validate(newDate);
  };

  const onTimeChange = (value: string) => {
    const time = moment(value, 'H:mm');
    const newDate = scheduledFor.clone().set({
      hour: time.get('hour'),
      minute: time.get('minute'),
    });
    validate(newDate);
  };

  const handleSubmit = () => {
    const validationMessage = validateScheduledDate(scheduledFor, dueDate);
    if (validationMessage) setDateValidationError(validationMessage);
    const isScheduledDateValid = !validationMessage && !dateValidationError;

    if (isScheduledDateValid) onSubmit();
  };

  return (
    <Modal
      actions={
        <>
          <Button
            onClick={() => onCancel()}
            isFullWidth={isMobile}
            size="small"
            variant={isMobile ? 'tertiary' : 'text'}
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} isFullWidth={isMobile} size="small">
            Schedule
          </Button>
        </>
      }
      heading="Schedule task"
      scroll="page"
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <Stack spacing="spacingLarge2X">
        {dateValidationError && <ValidationErrors errors={[dateValidationError]} />}
        <Text variant="bodyRoot" as="p">
          When a task reaches its scheduled date and time, it will become visible to students. This task must be
          scheduled before it’s due <DateTime value={dueDate} />.
        </Text>
        <Box display="grid" gridTemplateColumns={{ mobile: 'repeat(2, 1fr)' }} gridGap="spacingRoot">
          <DatePicker
            label="Date"
            dateFormats={{ ...DatePicker.DATE_FORMATS, today: '[Today]', tomorrow: '[Tomorrow]' }}
            value={scheduledFor}
            onChange={onDateChange}
          />
          <TimePicker label="Time" selectedKey={scheduledFor.format('H:mm')} onSelectionChange={onTimeChange} />
        </Box>
      </Stack>
    </Modal>
  );
}
