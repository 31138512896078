import { filter, get, groupBy, map, merge, pick, sortBy, values } from 'lodash';

import { LOAD_SUCCESS as LOAD_LEVELS_SUCCESS, LOAD_LEVEL_SUCCESS } from 'src/actions/levels';
import { getAllLevels } from 'src/reducers/levels';
import { LOAD_CLASSES_SUCCESS, LOAD_SUBSCRIPTION_CLASSES_SUCCESS } from 'src/actions/classes';

const initialState = {
  loading: false,
  loaded: false,
  entities: {},
};

export default function subjectsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_LEVELS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        // we use merge here because subjects response sometimes as a section array we need to keep
        entities: merge({}, state.entities, get(action.result, 'entities.subjects')),
      };

    case LOAD_CLASSES_SUCCESS:
    case LOAD_SUBSCRIPTION_CLASSES_SUCCESS:
    case LOAD_LEVEL_SUCCESS:
      return {
        ...state,
        entities: merge({}, state.entities, get(action.result, 'entities.subjects')),
      };

    default:
      return state;
  }
}

// Selectors
export const isSubjectsLoaded = (state) => state.subjects.loaded;
export const getAllSubjects = (state) => values(state.subjects.entities);
export const getAllSubjectsOrderedByGroupCode = (state) => groupBy(getAllSubjects(state), 'subject_group_code');
export const getAllSubjectsWithLevels = (state) => {
  const subjects = getAllSubjects(state);
  const levels = getAllLevels(state);

  return subjects.map((subject) => ({
    key: subject.code,
    code: subject.code,
    color: subject.color,
    name: subject.name,
    subject_group_code: subject.subject_group_code,
    levels: sortBy(
      map(
        filter(levels, (level) => level.subjects.includes(subject.code)),
        (level) => pick(level, ['id', 'name'])
      ),
      'name'
    ),
  }));
};
export const getSubjectById = (state, id) => state.subjects.entities[id];
export const getSubjectByIds = (state, ids) => ids.map((id) => state.subjects.entities[id]);
